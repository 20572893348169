'use strict';

angular.module('angus.controllers').controller('adeptPerformanceAnalysisController', [
    '$rootScope', '$scope', 'adeptPerformanceAnalysisService', 'constantsService', 'hierarchyService',
    function($rootScope, $scope, adeptPerformanceAnalysisService, constantsService, hierarchyService) {

        $scope.subscriberId = $rootScope.user.subscriberId;
        const subscriberKey = $rootScope.user.subscriberAbosKey;

        /* Initialize Month and Year data */
        const currentDate = new Date(); 
        $scope.widget.instance.settings.month = currentDate.getMonth() + 1; /* Our Months are 1 based. Date's months are 0 based. So this will give us the current month */
        $scope.widget.instance.settings.year = currentDate.getFullYear();

        $scope.months = constantsService.months.enums;
        $scope.years = adeptPerformanceAnalysisService.getYears(5);

        $scope.reloadWidget = () => loadWidget();

        function loadWidget() {
            const includeExcludeOnlyOptions = constantsService.includeExcludeOnlyOptions;
            
            $scope.widget.noContentFlag = true;
            const settings = $scope.widget.instance.settings;

            const timePeriod = settings.timePeriod.value;
            const month = settings.month;
            const year = settings.year;
            const containerTypeId = settings.containerTypeId.value;
            const includeMonitoredTanks = includeExcludeOnlyOptions[settings.includeMonitoredTanks.value] || includeExcludeOnlyOptions.include;
            const includeGroupOptimizedTransactions = includeExcludeOnlyOptions[settings.includeGroupOptimizedTransactions.value] || includeExcludeOnlyOptions.include;


            let adeptGroupIds = [];
            let divisionIdsParam = [];
            let productCategoryIds = [];
            let deliveryTypeIds = [];

            if (settings.useAdeptGroup.value) {
                adeptGroupIds = settings.adeptGroupIds.value;
            }
            else {
                const divisionIds = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, settings.hierarchyNodeId.value);
                divisionIdsParam = divisionIds;
                productCategoryIds = settings.productCategoryIds.value;
                deliveryTypeIds = settings.deliveryTypeIds.value;
            }
            
            

            const promise = adeptPerformanceAnalysisService.getSummary(subscriberKey, timePeriod, month, year, adeptGroupIds, divisionIdsParam, productCategoryIds, deliveryTypeIds, containerTypeId, includeMonitoredTanks, includeGroupOptimizedTransactions)
                .then(function(data){
                    $scope.data = data;

                    /* Consider the widget to have no content if the sum of all units is 0. The Units property is the sum directly from the Database. */
                    if (data.units > 0 ) {
                        $scope.widget.noContentFlag = false;
                    }
                });

            return $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(loadWidget);    

        $scope.$on('widgetSettingsChanged', function() {
            loadWidget();
        });


    }
]);