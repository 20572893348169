function tankExclusionInquiryController($q, $rootScope, $scope, adeptInquiryService, constantsService, subscriberService, adeptService, containerService, deliveryTypeService, divisionService, productsService) {
    var $ctrl = this;

    /*** PARAMS  **
     * subscriberId
     * customerKey
     * containerKey
     * 
    */

    const yesNoOnlyDisplay = constantsService.yesNoOnlyDisplay;

    $ctrl.settings = {};

    $ctrl.handleSubscriberChanged = handleSubscriberChanged;

    $ctrl.handleCustomerSelected = handleCustomerSelected;
    $ctrl.handleCustomerInputChanged = handleCustomerInputChanged;

    $ctrl.handleContainerSelected = handleContainerSelected;

    $ctrl.getTabIndicatorStyle = getTabIndicatorStyle;
    $ctrl.getTabIndicatorClass = getTabIndicatorClass;

    $ctrl.getIndicatorStyle = getIndicatorStyle;
    $ctrl.getIndicatorClass = getIndicatorClass;

    $ctrl.isDivisionExpanded = false;

    $scope.$on('adeptInquiryActiveTabChanged',
        function(event, newActiveTab, params) {
            if (newActiveTab !== adeptInquiryService.tabs.tankInquiry || !params){
                return;
            } 
                 
            if (!$ctrl.isSysAdmin || $ctrl.settings.selectedSubscriberId == params.subscriberId) {
                /* Should never be able to change Subscriber for non-sysadmins */
                $ctrl.settings.customerKey = params.customerKey;
                $ctrl.settings.containerKey = params.containerKey;
            } else {
                $ctrl.settings.selectedSubscriberId = params.subscriberId;
                loadSubscriberData()
                    .then(function(){
                        $ctrl.settings.customerKey = params.customerKey;
                        $ctrl.settings.containerKey = params.containerKey;
                    })
            }       
        });


    $ctrl.$onInit = function () {
        $ctrl.isSysAdmin = !!$rootScope.user.isSysAdmin;

        /* If we're sysadmin then we may have a SubscriberId passed in. Not SysAdmin then always use the User's subscriber. */
        if ($ctrl.isSysAdmin) {
            loadSubscribers();
        } else {
            $ctrl.settings.selectedSubscriberId = $rootScope.user.subscriberId;
            loadSubscriberData();
        }
    }

    function loadSubscribers() {
        $ctrl.areSubscribersLoading = true;

        return subscriberService.getAdeptSubscribers()
            .then(function (subscribers) {
                $ctrl.subscribers = subscribers;
                $ctrl.areSubscribersLoading = false;
            });
    }

    function loadExclusionData() {
        $ctrl.isTankExclusionDataLoading = true;

        const subscriberId = $ctrl.settings.selectedSubscriberId;
        const containerKey = $ctrl.settings.containerKey;

        adeptService.getTankExclusions(subscriberId, containerKey)
            .then(exclusionData => {
                /* Enhance Data specific to UI */
                exclusionData.isTankMonitored = exclusionData.isTankMonitored === 1 ? true : false;
                exclusionData.isTmsTankMonitored = exclusionData.isTmsTankMonitored === 1 ? true : false;
                exclusionData.isDeliveryHold = exclusionData.isDeliveryHold === 1 ? true : false;
                exclusionData.isMultiTankDeliveryGroup = exclusionData.isMultiTankDeliveryGroup === 1 ? true : false;
                exclusionData.isSourceActive = exclusionData.isSourceActive === 1 ? true : false;
                exclusionData.isInternalActive = exclusionData.isInternalActive === 1 ? true : false;

                /* Adept Groups */
                exclusionData.adeptGroupExclusions.forEach(function (adeptGroupExclusion) {
                    adeptGroupExclusion.divisions = getDivisionsToDisplay(adeptGroupExclusion.divisionKeys);
                    adeptGroupExclusion.products = getProductsToDisplay(adeptGroupExclusion.productKeys);
                    adeptGroupExclusion.deliveryTypes = getDeliveryTypesToDisplay(adeptGroupExclusion.deliveryTypeKeys);

                    if (adeptGroupExclusion.isOptimizationEnabled) {
                        adeptGroupExclusion.containerTypes = getContainerTypesToDisplay(adeptGroupExclusion.containerTypeIds);
                    }

                    adeptGroupExclusion.includeInternalMonitoredTanks = yesNoOnlyDisplay[adeptGroupExclusion.includeInternalMonitoredTanks];
                    adeptGroupExclusion.includeMonitoredTanks = yesNoOnlyDisplay[adeptGroupExclusion.includeMonitoredTanks];

                    adeptGroupExclusion.includeCustomersOnHold = adeptGroupExclusion.includeCustomersOnHold === 1 ? true : false;
                    adeptGroupExclusion.includeMultiTankDeliveryGroup = adeptGroupExclusion.includeMultiTankDeliveryGroup === 1 ? true : false;
                });


                $ctrl.exclusionData = exclusionData;
                $ctrl.activeTab = exclusionData.adeptGroupExclusions[0].dynamicReserveSettingsKey;

                $ctrl.isTankExclusionDataLoading = false;
            });
    }


    function handleSubscriberChanged() {
        clearCustomerData();
        $ctrl.exclusionData = null;

        loadSubscriberData();
    }

    function loadSubscriberData() {
        $ctrl.isSubscriberDataLoading = true;

        return $q.all([
            loadContainerTypes(),
            loadDivisions(),
            loadProducts(),
            loadDeliveryTypes()
        ])
            .then(function () {
                $ctrl.isSubscriberDataLoading = false;
            });
    }

    function clearCustomerData() {
        $ctrl.settings.customerKey = null;
        clearContainerData();
    }

    function clearContainerData() {
        $ctrl.settings.containerKey = null;
    }

    function handleCustomerSelected(newCustomer) {
        if (newCustomer) {
            $ctrl.settings.customerKey = newCustomer.customerKey;
        }
    }

    function handleCustomerInputChanged() {
        clearCustomerData();
    }

    function handleContainerSelected(newContainer) {
        if (newContainer) {
            $ctrl.settings.containerKey = newContainer.containerKey;
        }

        loadExclusionData();
    }

    function getTabIndicatorStyle(adeptGroupExclusion) {
        if (adeptGroupExclusion.isTargetAdeptGroup && !!adeptGroupExclusion.isAdeptGroupExclusion) {
            /* Warning */
            return { color: '#efa200' };
        } else if (adeptGroupExclusion.isAdeptGroupExclusion) {
            /* Error */
            return { color: 'red' };
        } else {
            /* Success */
            return { color: 'green' };
        }
    }

    function getTabIndicatorClass(adeptGroupExclusion) {
        if (adeptGroupExclusion.isTargetAdeptGroup && !!adeptGroupExclusion.isAdeptGroupExclusion) {
            /* Warning */
            return "fa-exclamation-triangle";
        } else if (adeptGroupExclusion.isAdeptGroupExclusion) {
            /* Error */
            return "fa-times-circle";
        } else {
            /* Success */
            return "fa-check-circle";
        }
    }

    function getIndicatorStyle(isExclusion) {
        if (!!isExclusion) {
            /* Fail */
            return { color: 'red' };
        }

        /* Success */
        return { color: 'green' };
    }

    function getIndicatorClass(isExclusion) {
        if (!!isExclusion) {
            /* Fail */
            return "fa-times-circle";
        }

        /* Success */
        return "fa-check-circle";
    }

    function loadDeliveryTypes() {
        $ctrl.deliveryTypes = [];

        return deliveryTypeService.getDeliveryTypes($ctrl.settings.selectedSubscriberId)
            .then(function (deliveryTypes) {
                $ctrl.deliveryTypes = deliveryTypes;
            });
    }

    function loadContainerTypes() {
        $ctrl.areContainerTypesLoading = true;

        $ctrl.containerTypes = [];

        return containerService.getContainerTypes($ctrl.settings.selectedSubscriberId)
            .then(function (containerTypes) {
                $ctrl.containerTypes = containerTypes;
                $ctrl.areContainerTypesLoading = false;

            })
    }

    function loadDivisions() {
        $ctrl.areDivisionsLoading = true;

        $ctrl.divisions = [];

        return divisionService.getDivisions($ctrl.settings.selectedSubscriberId, false, false)
            .then(function (divisions) {
                $ctrl.divisions = divisions;
                $ctrl.areDivisionsLoading = false;
            });
    }

    function loadProducts() {
        $ctrl.productions = [];

        return productsService.getBriteProducts($ctrl.settings.selectedSubscriberId)
            .then(function (products) {
                $ctrl.products = products;
            });
    }

    function getDivisionsToDisplay(divisionKeys) {
        const filteredDivisions = $ctrl.divisions.filter(function (division) {
            return divisionKeys.some(function (divisionKey) {
                return divisionKey === division.divisionKey;
            });
        });


        /* Convert Division object to a Display Text */
        const divisionDisplays = filteredDivisions.map(function (division) {
            return `${division.divisionName ? division.divisionName + ' ' : ''}(${division.divisionId})`;
        });

        return divisionDisplays && divisionDisplays.sort().join(', ');
    }

    function getProductsToDisplay(productKeys) {
        const filteredProducts = $ctrl.products.filter(function (product) {
            return productKeys.some(function (productKey) {
                return productKey === product.id;
            });
        });

        const productNames = filteredProducts.map(function (product) {
            return product.name;
        });

        return productNames && productNames.sort().join(', ');
    }

    function getDeliveryTypesToDisplay(deliveryTypeKeys) {
        const filteredDeliveryTypes = $ctrl.deliveryTypes.filter(function (deliveryType) {
            return deliveryTypeKeys.some(function (deliveryTypeKey) {
                return deliveryTypeKey === deliveryType.id;
            });
        });

        const deliveryTypeNames = filteredDeliveryTypes.map(function (deliveryType) {
            return deliveryType.name;
        });

        return deliveryTypeNames && deliveryTypeNames.sort().join(', ');
    }

    function getContainerTypesToDisplay(containerTypeIds) {
        const filteredContainerTypes = $ctrl.containerTypes.filter(function (containerType) {
            return containerTypeIds.some(function (containerTypeId) {
                return containerTypeId == containerType.id;
            });
        });

        const containerTypeNames = filteredContainerTypes.map(function (containerType) {
            return containerType.name;
        });

        return containerTypeNames && containerTypeNames.sort().join(', ');
    }
}



angular
    .module('angus.components')
    .component('tankExclusionInquiry', {
        templateUrl: 'templates/adept/inquiry/tankExclusionInquiry/tankExclusionInquiry.component.html',
        controller: tankExclusionInquiryController,
        bindings: {}
    });