'use strict';

angular.module('angus.controllers').controller('tmsInstallSummeryCtrl', [
    '$scope', '$rootScope', '$http', 'modalService', 'dateToString', 'productsService', 'periodIndicators', '$q', 'hierarchyService', 'actionViewTemplates', '_',
    'constantsService', 'moment', 'datePickerService', 'lastInvoiceDateService', 'dateCalculator', 'restrictedAccessService',
    function($scope, $rootScope, $http, modalService, dateToString, productsService, periodIndicators, $q, hierarchyService, actionViewTemplates, _,
        constantsService, moment, datePickerService, lastInvoiceDateService, dateCalculator, restrictedAccessService) {

        var divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);

  
        
        function init() {
          var products = $scope.widget.instance.settings.products;
          $scope.widget.instance.settings.endDate = $scope.widget.instance.settings.endDate || moment().format('MMM DD, YYYY')
          $scope.widget.instance.settings.startDate = $scope.widget.instance.settings.startDate || moment().subtract(7, 'days').format('MMM DD, YYYY')
          var params = {
            subscriberId : $rootScope.user.subscriberId,
            startDate: $scope.widget.instance.settings.startDate,
            endDate: $scope.widget.instance.settings.endDate,
            products:products,
            divisions:$scope.widget.instance.settings.hierarchyNodeId
        }
        return $http.post('api/monitors/summary', params)
        .then(function(res){ 
            $scope.monitors = res.data
        })
      }
      
       $scope.widget.setDefaultInitialization(init);
        $scope.$on('widgetSettingsChanged', function() {

            init();
        });
    }
]);
