angular.module('angus.services').factory('backOfficeSystemService', [
    '$http',
    function($http) {

        function getBackOfficeSystems() {
            return queryBackOfficeSystems()
                .then(function (res) {
                    return res.data;
                });
        }

        function queryBackOfficeSystems() {
            return $http.get('api/backOfficeSystems');
        }

        return {
            getBackOfficeSystems: getBackOfficeSystems
        }
    }
]);