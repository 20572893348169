angular.module('angus.controllers').controller('driverPerfReportContainerCtrl', [
    '$scope', '$q', '$rootScope', '$http',  '_', 'constantsService','drillService',  'moment', 'gridService2', 'nodeType', 'gridState', 'modalService', 'actionViewTemplates', 'restrictedAccessService',
    function($scope, $q, $rootScope, $http,  _, constantsService, drillService, moment, gridService2, nodeType, gridState, modalService, actionViewTemplates, restrictedAccessService) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        if($scope.settings.customDate) {
            $scope.dates = {
                startDate: moment.utc($scope.settings.customDate.startDate).format('L') || moment(),
                endDate: moment.utc($scope.settings.customDate.endDate).format('L') || moment()
            }
        } else {
            $scope.dates = {
                startDate: $scope.model.periodStartDate || moment(),
                endDate: $scope.model.lastDeliveryDate || moment(),
                lastDeliveryDate: $scope.model.lastDeliveryDate || moment()
            }
        }

        $scope.filter =  {
            productIds: $scope.settings.productIds.value,
            hierarchyNodeId: $scope.settings.hierarchyNodeId,
            metricIds:  [],
            green: ($scope.model && $scope.model.metricCategoryId) ? $scope.model.includeGreenIndicator : true,
            yellow: true,
            red: true,
            driverIds: [],
            excludeDriverIds: $scope.settings.excludeDriverIds.value,
            transportationCharge: $scope.settings.transportationCharge
        };

        function openBatchDeliveriesReport(batch) {
            var scope = {
                widgetName: 'Batch Deliveries',
                widgetIcon: 'car',
                model: {
                    deliveryBatchKey: batch.deliveryBatchKey,
                    date: batch.deliveryDate,
                    batchID: batch.batchID,
                    deliveryCenter: batch.deliveryCenterName,
                    driver: batch.driverName,
                    truckNumber:  batch.truckNumber
                }
            };

            modalService
                .openActionView(actionViewTemplates.batchDeliveriesDefault, scope, 'batchDeliveries');
        }

        $scope.getDriverPerformance = function() {
            var params = {
                deliveryCenterId    : $scope.filter.hierarchyNodeId,
                p                   : $scope.filter.productIds,
                s                   : moment($scope.dates.startDate).format(),
                e                   : moment($scope.dates.endDate).format(),
                gi                  : $scope.filter.green,
                yi                  : $scope.filter.yellow,
                ri                  : $scope.filter.red,
                dr                  : $scope.filter.driverIds,
                ed                  : $scope.filter.excludeDriverIds,
                m                   : $scope.filter.metricIds,
                transportationCharge: $scope.filter.transportationCharge
            };

            var rowPromise = $http({
                    url: ('api/subscribers/{0}/batches').format($rootScope.user.subscriberId),
                    method: 'POST',
                    data: params
            }).then(function(result) {

                const data = result.data;

                data.forEach(row => {
					/* A bug with AG Grid appears to force us to use the same ColId and Field names. So duplicate the Date field here. */
					row.deliveryDateMonth = row.deliveryDate;
					row.deliveryDateYear = row.deliveryDate;
				});

                return data;
            });

            var promise = $scope.grid.setRows(rowPromise);

            //$scope.promiseMonitor = new PromiseMonitor(promise);
            return promise;
        };


        function saveBatch(deliveryBatchKey, hours, miles){
            var data = {};

            if(miles !== null) {
                data.miles = miles;
            }

            if(hours !== null) {
                data.hours = hours;
            }

            return $http({
                url: ('api/subscribers/{0}/batches/{1}').format($rootScope.user.subscriberId, deliveryBatchKey),
                method: 'PUT',
                data: data
            }).then(function(){
                $scope.grid.recomputeAggregates();
            });
        }

        function getColClicks(){
            return {
                batchID   : {
                    onClick : function(row){ if(row.deliveryBatchKey) openBatchDeliveriesReport(row); }
                }
            };
        }

        function ratioValueGetter(dividend, divisor){
            return function(params){
                if(!params.data) return null;
                return params.data[divisor] ? params.data[dividend] / params.data[divisor] : null;
            }
        }

        function newHours(params){
            if(isNaN(params.newValue) || params.newValue == params.oldValue) {
                params.data.hours = Number(params.oldValue);
            } else {
                params.data.hours = Number(params.newValue);
                saveBatch(params.data.deliveryBatchKey, params.newValue, null);
            }

            if(params.newValue == 0 || !params.newValue || params.newValue == '' || params.newValue == ' ') {
                params.newValue = 0;
                params.data.hours = Number(params.data.hoursSource);
                saveBatch(params.data.deliveryBatchKey, params.data.hoursSource, null)
            }
        }

        function newMiles(params){
            if(isNaN(params.newValue) || params.newValue == params.oldValue)
                params.data.miles = Number(params.oldValue);
            else{
                params.data.miles = Number(params.newValue);
                saveBatch(params.data.deliveryBatchKey, null, params.newValue);
            }

            if(params.newValue == 0 || !params.newValue) {
                params.data.miles = Number(params.data.milesSource);
                saveBatch(params.data.deliveryBatchKey, null, params.data.milesSource)
            }
        }

        function getColDefs(){

            var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];

            colDefs.push(gridService2.colDef.createText('driverName','Driver','driverName', { rowGroupIndex : nextIndex, hide : true }));

            colDefs.push(gridService2.colDef.createDate('deliveryDate','Date','deliveryDate', { sort: 'asc' }));
            colDefs.push(gridService2.colDef.createMonth('deliveryDateMonth','Month','deliveryDateMonth', { hide: true }));
            colDefs.push(gridService2.colDef.createYear('deliveryDateYear','Year','deliveryDateYear', { hide: true }));
            colDefs.push(gridService2.colDef.createText('batchID','Batch Number','batchID', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('truckNumber','Truck Number','truckNumber', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('truckName','Truck Name','truckName', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('truckDesc','Truck Description','truckDesc', { cellClass : ['center']} ));

            colDefs.push(gridService2.colDef.createNumber('units','Units','units', {aggFunc : 'sum'}, {decimalPlaces: 1 }));

            var milesColDef   = gridService2.colDef.createNumber('miles','Miles','miles', {aggFunc : 'sum'}, {decimalPlaces: 2 });
            var hoursColDef   = gridService2.colDef.createNumber('hours','Hours','hours', {aggFunc : 'sum'}, {decimalPlaces: 2 });

            if(!$scope.restrictedAccess.inlineBatchEditing){
                milesColDef.editable =true
                milesColDef.newValueHandler = newMiles;
                hoursColDef.editable =true;
                hoursColDef.newValueHandler = newHours;
            }

            colDefs.push(milesColDef);
            colDefs.push(hoursColDef);
            colDefs.push(gridService2.colDef.createNumber('mph','MPH','mph', { valueGetter : ratioValueGetter('miles', 'hours')}, {decimalPlaces: 2 }));


            colDefs.push(gridService2.colDef.createNumber('deliveryCount','Stops','deliveryCount', {aggFunc : 'sum'}, {decimalPlaces: 0 }));

            if(!$scope.restrictedAccess.costAndMargin)
                colDefs.push(gridService2.colDef.createCurrency('grossMargin','Gross Margin','grossMargin', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

            colDefs.push(gridService2.colDef.createNumber('gps','Units/Stop','gps', { valueGetter : ratioValueGetter('units', 'deliveryCount') }, {decimalPlaces: 0 }));
            colDefs.push(gridService2.colDef.createNumber('mps','Miles/Stop','mps', { valueGetter : ratioValueGetter('miles', 'deliveryCount')}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createNumber('sph','Stops/Hour','sph', { valueGetter : ratioValueGetter('deliveryCount', 'hours')}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createNumber('gph','Units/Hour','gph', { valueGetter : ratioValueGetter('units', 'hours')}, {decimalPlaces: 0 }));
            // added units per mile
            colDefs.push(gridService2.colDef.createNumber('upm','Units/Mile','upm', { valueGetter : ratioValueGetter('units', 'miles')}, {decimalPlaces: 2 }))

            if(!$scope.restrictedAccess.costAndMargin)
                colDefs.push(gridService2.colDef.createCurrency('gmph','Gross Margin/Hour','gmph', { valueGetter : ratioValueGetter('grossMargin', 'hours')}, {decimalPlaces: 2 }));

            return colDefs;
        }

        var nodeColDefs;

        $q.all([
            restrictedAccessService.getAccess(),
            nodeType.getDeliveryCenterColDefs(subscriberId)
        ])
        .spread(function(access, colDefs){
            $scope.restrictedAccess = access.restrictedAccess
            nodeColDefs = colDefs;
            var gridParams = {
                gridState     : gridState(subscriberId, $scope.widgetCode),
                defs          : getColDefs(),
                clicks        : getColClicks(),
                exportOptions : { fileName: 'Driver Performance' }
            };
            return gridService2.createGrid(gridParams);
        })
        .then(function(grid){
            $scope.grid = grid;
            $scope.getDriverPerformance();
        });

    }
]);
