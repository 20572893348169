angular.module('angus.services')
    .factory('modules', [
        '$http', function($http) {
            return {
                getModules: function() {
                    $http.get('/api/modules/')
                        .success(function(data) {
                            return data;
                        }).error(function() {
                            return [];
                        });
                }
            };
        }
    ]);