angular.module('angus.services').factory('orderService', [
    '$http', function ($http) { 

        const getOrderHeader = function(riderKey, riderOrderKey, subscriberKey, includeDeleted) {   
            let url =  'api/tankMonitors/orders/orderHeaders?'; 
            if (riderKey) {
                url += 'riderKey=' + riderKey + '&';
            }
            if (riderOrderKey) {
                url += 'riderOrderKey=' + riderOrderKey + '&';
            } 
            if (subscriberKey) {
                url += 'subscriberKey=' + subscriberKey + '&';
            } 
            const includeDeletedValue = includeDeleted ? 1 : 0; 
            url += 'includeDeleted=' + includeDeletedValue;  
            
            return $http.get(url)
               .then(function (res) {
               return res.data;
           });
        };  
 
        const getOrder = function(riderKey, riderOrderKey, includeDeleted) { 
            let url =  'api/tankMonitors/orders?'; 
            if (riderKey) {
                url += 'riderKey=' + riderKey + '&';
            }
            if (riderOrderKey) {
                url += 'riderOrderKey=' + riderOrderKey + '&';
            } 
            const includeDeletedValue = includeDeleted ? 1 : 0; 
            url += 'includeDeleted=' + includeDeletedValue; 

            return $http.get((url))
            .then(function (res) { 
                return res.data;
            });
        };   
         
         const postOrder = function(data) {
            return $http.post(('api/tankMonitors/orders'), data, {headers: {'Content-Type': 'application/json'}})
                   .then(function (res) {
                       return res.data;
                   });
        };

        return { 
            getOrderHeader,
            getOrder,
            postOrder
         };
     }
 ]);
 
 