angular.module('angus.controllers')
    .controller('weatherHDDSettingsCtrl', ['$scope', '$rootScope', '$http', '_', 'constantsService', 'degreeDayService',
      function ($scope, $rootScope, $http, _, constantsService, degreeDayService) {

          'use strict';

          if($scope.widget) $scope.widget.instance.settings.selectedItem = { source: {} };
          $scope.widget.instance.settings.selectedSeasonMonth = $scope.widget.instance.settings.selectedSeasonMonth || 7;

          degreeDayService.getDegreeDaySources($rootScope.user.subscriberId)
            .then(function(sources){
                $scope.sources = sources;
            });

          $scope.averageperiods = [
              { id: 3, name: '3-Yr Avg' },
              { id: 5, name: '5-Yr Avg' },
              { id: 10, name: '10-Yr Avg' }
          ];
          $scope.months = constantsService.months.enums;
    }
]);
