angular.module('angus.services').service('adeptPerformanceAnalysisService', [
    '$http', 'constantsService',
    function($http, constantsService) {

        const includeExcludeOnlyOptions = constantsService.includeExcludeOnlyOptions;
        const includeExcludeOnlyOptionsNextGen = constantsService.includeExcludeOnlyOptionsNextGen;
        
        function getTimePeriods() {
            return {
                month: 1,
                year: 2
            }
        }

        function getYears(numberOfYears){
            const currentDate = new Date(); 

             /* Build Years array 5 years back */
            const years = [currentDate.getFullYear()];
            
            for(let i = 1; i < numberOfYears; i++) {
                years.push(years[i-1] - 1);
            }

            return years;
        }      
        

        function getSummary(subscriberKey, timePeriod, month, year, adeptGroupIds, divisionIds, productCategoryIds, deliveryTypeIds, containerTypeId, includeMonitoredTanks, includeGroupOptimizedTransactions){
            /* Compute Start and End Date */
            const timePeriods = getTimePeriods();
            let startDate, endDate;
            

            if (timePeriod == timePeriods.month) {
                startDate = new Date(year, month - 1, 1);
                endDate = new Date(year, month, 0);
            }
            else {
                startDate = new Date(year - 1, month - 1, 1);
                endDate = new Date(year, month, 0);
            }

            const includeMonitoredTanksNextGen = includeExcludeOnlyOptions.toNextGenValues(includeMonitoredTanks) || includeExcludeOnlyOptionsNextGen.include;
            const includeGroupOptimized = includeExcludeOnlyOptions.toNextGenValues(includeGroupOptimizedTransactions) || includeExcludeOnlyOptionsNextGen.include;
            
            const requestDto = {
                startDeliveryDate: startDate ? startDate.toISOString().split('T')[0]: null,
                endDeliveryDate: endDate ? endDate.toISOString().split('T')[0]: null,
                dynamicReserveSettingsKeys: adeptGroupIds,
                divisionKeys: divisionIds,
                productCategoryReportKeys: productCategoryIds,
                deliveryTypeKeys: deliveryTypeIds,
                containerCapacities: [containerTypeId],
                includeMonitoredTanks: includeMonitoredTanksNextGen,
                includeGroupOptimized: includeGroupOptimized                    
            };
              
            return $http.post(`api/subscriber/${subscriberKey}/adept/widget/adept-performance-analysis`, requestDto, {apiV2: true})
                .then(function(res) {
                    return res.data;
                });
        }

        function getDetail(subscriberKey, month, year, adeptGroupIds, divisionIds, productCategoryIds, deliveryTypeIds, containerTypeId, includeMonitoredTanks, includeGroupOptimizedTransactions){

             /* Compute Start and End Date */
            const startDate = new Date(year - 1, month - 1, 1);
            const endDate = new Date(year, month, 0);

            const includeMonitoredTanksNextGen = includeExcludeOnlyOptions.toNextGenValues(includeMonitoredTanks) || includeExcludeOnlyOptionsNextGen.include;
            const includeGroupOptimized = includeExcludeOnlyOptions.toNextGenValues(includeGroupOptimizedTransactions) || includeExcludeOnlyOptionsNextGen.include;

            const requestDto = {
                startDeliveryDate: startDate ? startDate.toISOString().split('T')[0]: null,
                endDeliveryDate: endDate ? endDate.toISOString().split('T')[0]: null,
                dynamicReserveSettingsKeys: adeptGroupIds,
                divisionKeys: divisionIds,
                productCategoryReportKeys: productCategoryIds,
                deliveryTypeKeys: deliveryTypeIds,
                containerCapacities: [containerTypeId],
                includeMonitoredTanks: includeMonitoredTanksNextGen,
                includeGroupOptimized: includeGroupOptimized                    
            };

            return $http.post(`api/subscriber/${subscriberKey}/adept/report/adept-performance-analysis`, requestDto, {apiV2: true})
                .then(function(res) {
                    return res.data;
                });
        }
           
        return {
            getTimePeriods: getTimePeriods,
            getYears: getYears,
            getSummary: getSummary,
            getDetail: getDetail
        };
    }
]);