angular.module('angus.controllers').controller('avgGalByTankSizeSubRegSettingsCtrl', [
    '$scope', 'productsService', 'promiseMonitor', 'tradeClassService', 'constantsService', 'deliveryTypeService', '$q',
    function($scope, productsService, promiseMonitor, tradeClassService, constantsService, deliveryTypeService, $q) {

        $scope.promiseMonitor = new promiseMonitor();

        $scope.periodIndicators = constantsService.periodIndicators.enums;

        var promise = $q.all([productsService.getProducts($scope.subscriberId)
            .then(function(products) {
                $scope.products = products;
            }),
            deliveryTypeService.getDeliveryTypes($scope.subscriberId)
            .then(function(deliveryTypes) {
                $scope.deliveryTypes = deliveryTypes;
            }),
            tradeClassService.getTradeClasses($scope.subscriberId)
            .then(function(classes) {
                $scope.tradeClasses = classes;
            })
        ]);

        $scope.promiseMonitor.monitor(promise);
    }
]);