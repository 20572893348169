angular.module('angus.directives')
    .directive("widgetFooter", [
        function() {
            return {
                link: function(scope, element, attrs) {
                    element.addClass('widgetFooter');
                    var widgetBody = $(element).siblings('.widgetBody')[0];

                    scope.$watch(function() {
                        return !$(element).is(":visible");
                    }, function(isHidden) {
                        if (isHidden) {
                            $(widgetBody).removeClass('withFooter');
                        } else {
                            $(widgetBody).addClass('withFooter');
                        }
                    });
                }
            };
        }
    ]);