angular.module('angus.controllers').controller('dashboardCtrl', [
    '$mdDialog', '$scope', '$rootScope', 'dashboardsService', 'modalService', '_', '$http', 'actionViewTemplates', '$timeout',
    function($mdDialog, $scope, $rootScope, dashboardsService, modalService, _, $http, actionViewTemplates, $timeout) {

        $scope.isOpen = false;

        $scope.dashFab = {
            showTooltip: false
        };

        $scope.$watch('isOpen', function(isOpen) {
            if (isOpen) {
                $timeout(function() {
                    $scope.dashFab.showTooltip = true;
                }, 600);
            } else {
                $scope.dashFab.showTooltip = false;
            }
        });

        $scope.gridsterOptions = {
            columns: 12, // the width of the grid, in columns
            pushing: true, // whether to push other items out of the way o they stack (you can temporarily disable if you are adding unsorted items with ng-repeat)
            swapping: false, // whether or no to have items of the same size swon move or resize
            floating: true, // whether to automatically float  up sitch places instead of pushing down if they are the same size
            width: 'auto', // can be an integer or 'auto'. 'autoitems' scales gridster to be the full width of its containing element
            colWidth: 'auto', // can be an integer or 'auto'.  'auto' uses the pixel width of the element divided by 'columns'
            rowHeight: 'match', // can be an intteger or 'match'.  Match uses the colWidth, giving you square widgets.
            margins: [20, 20], // the pixel distance between each widget
            outerMargin: true, // whether margins apply to outer edges of the grid
            isMobile: false, // stacks the grid items if delete
            mobileBreakPoint: 800, // if the screen is not wider that this, remove the grid layout and stack the items
            mobileModeEnabled: true, // whether or not to toggle mobile mode when screen width is less than mobileBreakPoint
            minColumns: 1, // the minimum columns the grid must have
            minRows: 2, // the minimum height of the grid, in rows
            maxRows: 100,
            defaultSizeX: 2, // the default width of a gridster item, if not specifed
            defaultSizeY: 1, // the default height of a gridster item, if not specified
            minSizeX: 2, // minimum column width of an item
            maxSizeX: null, // maximum column width of an item
            minSizeY: 2, // minumum row height of an item
            maxSizeY: null, // maximum row height of an item
            resizable: {
                enabled: true,
                handles: ['sw', 'se'],
                start: resizeStart, // optional callback fired when resize is started,
                // resize            : function(event, $element, widget) {}, // optional callback fired when item is resized,
                stop: resizeStop // optional callback fired when item is finished resizing
            },
            draggable: {
                enabled: true, // whether dragging items is supported
                handle: '.widget-handle', // optional selector for resize handle
                start: dragStart, // optional callback fired when drag is started,
                // drag              : function(event, $element, widget) {}, // optional callback fired when item is moved,
                stop: dragStop // optional callback fired when item is finished dragging
            }
        };


        var originalCol, originalRow;

        function dragStart(event, $element, widget) {
            originalCol = widget.col;
            originalRow = widget.row;
        }

        function dragStop(event, $element, widget) {
            dashboardsService
                .updateWidgets($scope.activeDashboard)
                .then(function() {
                    originalCol = undefined;
                    originalRow = undefined;
                });
        }

        var originalX, originalY;

        function resizeStart(event, $element, widget) {
            originalX = widget.sizeX;
            originalY = widget.sizeY;
        }

        function resizeStop(event, $element, widget) {
            dashboardsService
                .updateWidgets($scope.activeDashboard)
                .then(function() {
                    originalX = undefined;
                    originalY = undefined;
                });
        }


        $scope.customItemMap = {
            sizeX: 'widget.instance.size.columns',
            sizeY: 'widget.instance.size.rows',
            row: 'widget.instance.location.row',
            col: 'widget.instance.location.column'
        };

     
        $scope.$on('widgetSettingsUpdated', function(event, widgetId, settings, widgetCode) {
            dashboardsService.updateWidgetSettings($scope.activeDashboard, widgetId, settings, widgetCode);
        });

        $scope.$on('widgetWantsToBeDeleted', function(event, widgetId) {
            dashboardsService.deleteWidget($scope.activeDashboard, widgetId);
        });

        function init() {
            $scope.options = {
                draggingWidgetInstanceId: null,
                allowDrag: true
            };

            $scope.widgetLibraryUrl = 'templates/widgetLibrary/html/widgetLibraryModalContainer.html';
            $scope.editProfileUrl = 'templates/widgetLibrary/html/widgetLibraryModalContainer.html';

            dashboardsService.init()
                .then(function (dashboardInit){
                    $scope.dashboards = dashboardInit.dashboards;
                    $scope.switchDashboards(dashboardInit.activeDashboard);
                    $scope.dashboardLoaded = true;

                    return dashboardsService.getDefaultDashboards($rootScope.user.subscriberId, $rootScope.user.securityGroupId, true);
                })
            .then(function(dashboards) {
                    $scope.defaultDashboards = dashboards;
                });
        }

        if ($rootScope.user)
            init();

        $scope.openWidgetLibrary = function() {
            modalService
                .openFullPageModal($scope.widgetLibraryUrl);
        };


        $scope.switchDashboards = function(dashboard) {
            dashboardsService.switchDashboard(dashboard)
                .then(function(dashboard) {
                    $scope.activeDashboard = dashboard;
                });
        }

        /* Active Dashboard can be changed using the Side Menu. */ 
        $scope.$watch(
            function () {
                return dashboardsService.activeDashboard;
            },
            function (newActiveDashboard, oldActiveDashboard) {
                $scope.activeDashboard = newActiveDashboard;
            }
        );


        $scope.addDashboard = function(event) {
            //If there are default dashboards available then load up the modal
            if ($scope.defaultDashboards && $scope.defaultDashboards.length > 0) {
                var scope = {
                    dashboards: $scope.defaultDashboards,
                    selectedDashboards: [],
                    dashboardName: 'New Dashboard'
                };
                actionViewTemplates.newDashboard
                return modalService
                    .openTopBarModal(actionViewTemplates.newDashboard, scope)
                    .spread(function(ret) {
                      if(!ret) return;

                        if (typeof ret === 'string') {
                            dashboardsService
                                .addDashboard(ret)
                                .then(function(newDashboard) {
                                    $scope.activeDashboard = dashboardsService.setActiveDashboard(newDashboard[0]);
                                });
                        } else if (typeof ret === 'object') {
                            //NOTE: ret is an array of dashboards.  Need to submit all
                            dashboardsService
                                .addDashboard(null, ret)
                                .then(function(newDashboard) {
                                    $scope.activeDashboard = dashboardsService.setActiveDashboard(newDashboard[0]);
                                });
                        }
                    });
            } else {
                $mdDialog.show({
                    controller: function ($scope, $mdDialog) {
                        $scope.add = function (name) {
                            $mdDialog.hide(name);
                        };

                        $scope.cancel = function () {
                            $mdDialog.cancel();
                        };
                    },
                    templateUrl: 'templates/widgetLibrary/html/addDashboard.html',
                    parent: angular.element(document.body),
                    targetEvent: event,
                    clickOutsideToClose: true
                })
                    .then(function (name) {
                        if (!name) {
                            return;
                        }

                        dashboardsService.addDashboard(name)
                            .then(function (newDashboard) {
                                $scope.activeDashboard = dashboardsService.setActiveDashboard(newDashboard[0]);
                            });
                    })

            }
        };


        $scope.onTabsInit = function(selector) {
          var sortable = {};

          sortable.items = "md-tab-item";
          sortable.axis  = "x";
          sortable.delay = 150;

          sortable.start = function(event, ui) {
            ui.item.start = ui.item.index();
          };

          sortable.stop = function(event, ui) {
            if(ui.item.start !== ui.item.index()) {
                $scope.dashboards = dashboardsService.reorderDashboard(ui.item.start, ui.item.index());
            }
          };

          $(selector).sortable(sortable);
        };

        (function () {
            $http.get(("api/subscribers/{0}").format($rootScope.user.subscriberId))
                .then(function (response) {
                    $scope.subscriber = response.data
                });
        })();

     

    }
]);
