'use strict';

angular.module('angus.controllers').controller('subRegPostingCodesCtrl', [
	'$scope', '$http', '_', 'Paging', 'modalService', 'actionViewTemplates',
	function($scope, $http, _, Paging, modalService, actionViewTemplates) {

		$scope.pageSize           = 25;
		$scope.paging             = new Paging($scope.pageSize);
		$scope.sortProp           = 'id';
		$scope.sortDir            = 'asc';
		$scope.stagedChangesCount = 0;

		var stagedLiquidChanges  = [];
		var stagedPaymentChanges = [];

		var existingLiquidCodes = [];
		var existingPaymentCodes = [];

    $scope.$watch(function() {
      return $scope.activeSourceSystem;
    }, function(newVal) {
      if (newVal) {
        getPostingCodes();
      }
    }, true);

		function getPostingCodes(pageNumber){
			var params = {
				sortDir            : $scope.sortDir,
				sortProp           : $scope.sortProp,
				stagedLiquidCodes  : stagedLiquidChanges.join(','),
				stagedPaymentCodes : stagedPaymentChanges.join(',')
			};

			$scope.codesLoaded = false;

			$scope.paging.getPage(('api/subscribers/{0}/postingCodes/' + $scope.activeSourceSystem.id).format($scope.subscriberId),
				pageNumber,
				params)
					.then(function(postingCodes) {
							$scope.postingCodes = postingCodes.items;
							existingLiquidCodes = postingCodes.extraInfo.existingLiquidCodes ? postingCodes.extraInfo.existingLiquidCodes.split(',') : [];
							existingPaymentCodes = postingCodes.extraInfo.existingPaymentCodes ? postingCodes.extraInfo.existingPaymentCodes.split(',') : [];
							syncWithStagedChanges();
							$scope.codesLoaded  = true;
					});
		}

		$scope.getPage = function(pageNumber) {
			getPostingCodes(pageNumber);
		};

		$scope.sort = function(propName) {
			if (propName != $scope.sortProp)
				$scope.sortDir = 'asc';
			else
				$scope.sortDir = $scope.sortDir == 'asc' ?
									'desc' : 'asc';

			$scope.sortProp = propName;
			getPostingCodes($scope.paging.currentPage);
		};

		function syncWithStagedChanges(){
			_.forEach($scope.postingCodes, function(code){
					code.isLiquid  = code.isLiquidLocked || _.includes(stagedLiquidChanges, code.id);
					code.isPayment = code.isPaymentLocked || _.includes(stagedPaymentChanges, code.id);
				});
		}


		function updateArray(array, value){
			if(!_.includes(array, value))
				array.push(value);
			else
				array.splice(array.indexOf(value), 1);
		}

		function updateStagedValues(){
			$scope.stagedChangesCount = stagedLiquidChanges.length +
										stagedPaymentChanges.length;
		}

		$scope.updateStagedLiquidChanges = function(id){
			updateArray(stagedLiquidChanges, id);
			updateStagedValues();
		};

		$scope.updateStagedPaymentChanges = function(id){
			updateArray(stagedPaymentChanges, id);
			updateStagedValues();
		};

		$scope.updatePostingCodes = function() {

			var confirmScope = {
					title: 'Confirm Save?',
					message: 'Posting codes cannot be deselected after they have been saved! Please confirm you have made the necessary changes. ' +
						'If selections need to be changed you will need to contact your system administrator.'
				};

			return modalService
				.openTopBarModal(actionViewTemplates.confirmDialog, confirmScope)
				.then(function(response) {
					if(response){

						$scope.updating = true;

						stagedLiquidChanges  = _.union(stagedLiquidChanges, existingLiquidCodes);
						stagedPaymentChanges = _.union(stagedPaymentChanges, existingPaymentCodes);

						$http.post(('api/subscribers/{0}/postingCodes/' + $scope.activeSourceSystem.id).format($scope.subscriberId),
							{ postingCodes: stagedLiquidChanges, paymentCodes: stagedPaymentChanges })
							.then(function() {
									getPostingCodes();

									$scope.updating           = false;
									$scope.stagedChangesCount = 0;

									stagedLiquidChanges       = [];
									stagedPaymentChanges      = [];
								});
					}
			});
		};


		var headingCenter = 'text-center clickable';

		$scope.headings = [{
					name: 'Code',
					isHidable: false,
					propClass: headingCenter,
					propName: 'id',
					isSortable: true
				}, {
					name: 'Short Description',
					isHidable: false,
					propClass: headingCenter,
					propName: 'code',
					isSortable: true
				}, {
					name: 'Description',
					isHidable: false,
					propClass: headingCenter,
					propName: 'desc',
					isSortable: true
				}, {
					name: 'Liquid Product',
					isHidable: false,
					propClass: headingCenter,
					propName: 'liquid',
					isSortable: true
				}, {
					name: 'Payment Product',
					isHidable: false,
					propClass: headingCenter,
					propName: 'payment',
					isSortable: true
				}
			];

		getPostingCodes();
	}
]);
