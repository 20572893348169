angular.module('angus.controllers').controller('subRegListSettingsCtrl', [
    '$q', '$scope', 'backOfficeSystemService', 'promiseMonitor', 'registrationStatusService',
    function ($q, $scope, backOfficeSystemService, promiseMonitor, registrationStatusService) {
        $scope.promiseMonitor = new promiseMonitor();

        var promise = $q.all([
            backOfficeSystemService.getBackOfficeSystems(),
            registrationStatusService.getRegistrationStatuses()
        ])
            .spread(function (backOfficeSystems, registrationStatuses) {
                $scope.backOfficeSystems = backOfficeSystems;
                $scope.registrationStatuses = registrationStatuses;
            });

        $scope.promiseMonitor.monitor(promise);
    }
]);