angular.module('angus.controllers').controller('growthToBudgetDetailViewController', [
    '$scope', '$rootScope',  '$http',  '$q', 'drillService', 'moment',  '_',  'promiseMonitor', 'gridService2', 'gridState', 'nodeType',
    function ($scope, $rootScope,  $http, $q, drillService, moment, _,  PromiseMonitor, gridService2, gridState, nodeType) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.filter = {
            productIds: [],
            tradeClassIds: [],
            hierarchyNodeId: $scope.settings.hierarchyNodeId,
            meteredTanks: "include",
            deliveryTypes: "include",
            salesPersonIds: [],
            minTankSize: null,
            maxTankSize: null,
            deliveryTypeIds: []
        };

        $scope.dates = {
            startDate: moment.utc($scope.settings.startDate).format('L'),
            endDate: moment.utc($scope.settings.endDate).format('L'),
            lastDeliveryDate: moment.utc($scope.settings.endDate).format('L'),
        };

        $scope.getTankGainLoss = function() {
            var params = {
                hierarchyNodeId: $scope.filter.hierarchyNodeId,
                productIds: $scope.filter.productIds,
                tradeClassIds: $scope.filter.tradeClassIds,
                startDate: $scope.dates.startDate,
                endDate: $scope.dates.endDate.format(),
                meteredTanks: $scope.filter.meteredTanks,
                deliveryTypes: $scope.filter.deliveryTypes,
                salesPersonIds: $scope.filter.salesPersonIds,
                maxTankSize: $scope.filter.maxTankSize,
                minTankSize: $scope.filter.minTankSize,
                deliveryTypeIds: $scope.filter.deliveryTypeIds
            };

            var rowPromise = $http({
                url: ('api/subscribers/{0}/customerAccounts/gainsLosses/ByTank').format($rootScope.user.subscriberId),
                method: 'POST',
                data: params
            }).then(function(result) {
                result.data.forEach(row => {
                    /* A bug with AG Grid appears to force us to use the same ColId and Field names. So duplicate the Date field here. */
                    row.changeDateMonth = row.changeDate;
                    row.changeDateYear = row.changeDate;
                })

                return result.data;
            });

            var promise = $scope.grid.setRows(rowPromise);

            $scope.promiseMonitor = new PromiseMonitor(promise);
            return promise;
        };

        function getColClicks() {
            return {
                accountNumber   : {
                    onClick : function(row){ drillService.openAccountInfo(row); }
                },
                tankNumber    : {
                    onClick : function(row){ drillService.openTankInfo(row, drillService.context.delivery); }
                },
            };
        }

        function getColDefs() {
            var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];

            colDefs.push(gridService2.colDef.createNumber('gainLossCount','Number of Tanks','gainLossCount',{ aggFunc : 'sum'}, {decimalPlaces: 0,  center : true}));
            colDefs.push(gridService2.colDef.createText('productName','Product','productName', { rowGroupIndex : nextIndex, hide : true }));
            colDefs.push(gridService2.colDef.createText('gainLossTypeName','Gain / Loss Type','gainLossTypeName', { rowGroupIndex : nextIndex + 1, hide : true }));
            colDefs.push(gridService2.colDef.createText('sourceReason','Source / Reason','sourceReason', { rowGroupIndex : nextIndex + 2, hide : true }));
            colDefs.push(gridService2.colDef.createDate('changeDate','Change Date','changeDate', { sort: 'asc' }));
            colDefs.push(gridService2.colDef.createMonth('changeDateMonth','Month','changeDateMonth', { sort: 'asc' }));
            colDefs.push(gridService2.colDef.createYear('changeDateYear','Year','changeDateYear', { sort: 'asc' }));
            colDefs.push(gridService2.colDef.createText('accountName','Customer Name','accountName'));
            colDefs.push(gridService2.colDef.createText('accountNumber','Account Number','accountNumber', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('tankNumber','Tank Number','tankNumber', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createNumber('capacity','Capacity','capacity'));
            colDefs.push(gridService2.colDef.createText('salesRep','Sales Rep','salesRep'));
            colDefs.push(gridService2.colDef.createNumber('projectedLossUnits','Projected / Lost Units','projectedLossUnits',{ aggFunc : 'sum'}, {decimalPlaces: 1, colorPositive: true}));
            colDefs.push(gridService2.colDef.createCurrency('projectedLossMargin','Projected / Lost Margin','projectedLossMargin',{aggFunc : 'sum'}, {decimalPlaces: 2, colorPositive: true} ));
            colDefs.push(gridService2.colDef.createText('deliveryType','Brite Delivery Type','deliveryType'));
            colDefs.push(gridService2.colDef.createText('sourceDeliveryType','Source Delivery Type','sourceDeliveryType'));
            colDefs.push(gridService2.colDef.createBoolean('activeOrInactive','Active', 'activeOrInactive'));


            return colDefs;
        }

        var nodeColDefs;

        nodeType
            .getDivisionColDefs(subscriberId)
            .then(function(colDefs) {
                nodeColDefs = colDefs;
                var gridParams = {
                    gridState     : gridState(subscriberId, $scope.widgetCode),
                    defs          : getColDefs(),
                    clicks        : getColClicks(),
                    exportOptions : { fileName: 'Growth To Budget' }
                };
                return gridService2.createGrid(gridParams);
            })
            .then(function(grid){
                $scope.grid = grid;
                $scope.getTankGainLoss()
            });
    }
]);
