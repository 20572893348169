angular.module('angus.controllers')
.controller('legalCtrl', [
  '$scope', '$state', 'brandingService',
  function($scope, $state, brandingService) {
    'use strict';
 
    $scope.date = new Date();
    $scope.branding = brandingService;

    $scope.links = (function(base, links) {

      links.privacy = base + $state.href("legal.privacy");
      links.tos     = base + $state.href("legal.tos");
      links.eula    = base + $state.href("legal.eula");

      return links;
    })(window.location.origin + '/#', {});

  }
]);
