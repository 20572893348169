angular.module('angus.directives')
    .directive('terminationReasonSelect', [function(){
    	'use strict';

        return {
            restrict: 'A',
            scope: {
                ngModel: '=',
                subscriberId: '='
            },
            controller : ['$scope', 'terminationReasonService', function($scope, terminationReasonService){            	
            	terminationReasonService
					.getTerminationReason($scope.subscriberId)            
            		.then(function(terminationReason) {
                		$scope.terminationReason = terminationReason;
            		});            	
            }],            
            template : '<div multi-select-ok-wrapped="terminationReason" ng-model="ngModel" display-prop="\'name\'" selection-prop="\'id\'"></div>',            
        };
    }]);
