angular.module('angus.services')
    .factory('cssAnimations', ['$timeout', function($timeout) {
        return function(baseClass, classNames) {
            if (arguments.length == 3) {
                var a = classNames;
                var b = arguments[2];
                classNames = {
                    enter: a,
                    move: a,
                    leave: b,
                    show: a,
                    hide: b,
                    addClass: a,
                    removeClass: b
                };
            }
            var timeoutKey = '$$animate.css-timer';
            var animateCSSStart = function(element, className, delay, done) {
                element.addClass(className);
                element.addClass('animated');
                var timer = $timeout(done, delay || 500, false);
                element.data(timeoutKey, timer);
            };
            var animateCSSEnd = function(element, className) {
                return function(cancelled) {
                    var timer = element.data(timeoutKey);
                    if (timer) {
                        $timeout.cancel(timer);
                        element.removeData(timeoutKey);
                    }
                    element.removeClass(className);
                    element.removeClass('animated');
                };
            };
            return {
                enter: function(element, done) {
                    animateCSSStart(element, classNames.enter, classNames.delay, done);
                    return animateCSSEnd(element, classNames.enter);
                },
                leave: function(element, done) {
                    animateCSSStart(element, classNames.leave, classNames.delay, done);
                    return animateCSSEnd(element, classNames.leave);
                },
                move: function(element, done) {
                    animateCSSStart(element, classNames.move, classNames.delay, done);
                    return animateCSSEnd(element, classNames.move);
                },
                beforeAddClass: function(element, className, done) {
                    var klass = className == 'ng-hide' &&
                        (classNames.hide ||
                            (angular.isFunction(classNames.addClass) ?
                                classNames.addClass(className) :
                                classNames.addClass));
                    if (klass) {
                        animateCSSStart(element, klass, classNames.delay, done);
                        return animateCSSEnd(element, klass);
                    }
                    done();
                },
                addClass: function(element, className, done) {
                    var klass = className != 'ng-hide' &&
                        (angular.isFunction(classNames.addClass) ?
                            classNames.addClass(className) :
                            classNames.addClass);
                    if (klass) {
                        animateCSSStart(element, klass, classNames.delay, done);
                        return animateCSSEnd(element, klass);
                    }
                    done();
                },
                removeClass: function(element, className, done) {
                    var klass = (className == 'ng-hide' && classNames.show) ||
                        (angular.isFunction(classNames.removeClass) ?
                            classNames.removeClass(className) :
                            classNames.removeClass);
                    if (klass) {
                        animateCSSStart(element, klass, classNames.delay, done);
                        return animateCSSEnd(element, klass);
                    }
                    done();
                }
            };
        };
    }])


//
// Flip cssAnimations
//
.animation('.animate-flip-x', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-flip-x', 'flipInX', 'flipOutX');
}])

.animation('.animate-flip-y', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-flip-y', 'flipInY', 'flipOutY');
}])

//
// Fade cssAnimations
//
.animation('.animate-fade', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-fade', 'fadeIn', 'fadeOut');
}])

.animation('.animate-fade-up', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-fade-up', 'fadeInUp', 'fadeOutUp');
}])

.animation('.animate-fade-down', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-fade-down', 'fadeInDown', 'fadeOutDown');
}])

.animation('.animate-fade-left', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-fade-left', 'fadeInLeft', 'fadeOutLeft');
}])

.animation('.animate-fade-right', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-fade-right', 'fadeInRight', 'fadeOutRight');
}])

.animation('.animate-fade-up-big', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-fade-up-big', 'fadeInUpBig', 'fadeOutUpBig');
}])

.animation('.animate-fade-down-big', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-fade-down-big', 'fadeInDownBig', 'fadeOutDownBig');
}])

.animation('.animate-fade-left-big', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-fade-left-big', 'fadeInLeftBig', 'fadeOutLeftBig');
}])

.animation('.animate-fade-right-big', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-fade-right-big', 'fadeInRightBig', 'fadeOutRightBig');
}])


//
// Bounce cssAnimations
//
.animation('.animate-bounce', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-bounce', 'bounceIn', 'bounceOut');
}])

.animation('.animate-bounce-up', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-bounce-up', 'bounceInUp', 'bounceOutUp');
}])

.animation('.animate-bounce-down', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-bounce-down', 'bounceInDown', 'bounceOutDown');
}])

.animation('.animate-bounce-left', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-bounce-left', 'bounceInLeft', 'bounceOutLeft');
}])

.animation('.animate-bounce-right', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-bounce-right', 'bounceInRight', 'bounceOutRight');
}])

//
// Rotate cssAnimations
//
.animation('.animate-rotate', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-rotate', 'rotateIn', 'rotateOut');
}])

.animation('.animate-rotate-up-left', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-rotate-up-left', 'rotateInUpLeft', 'rotateOutUpLeft');
}])

.animation('.animate-rotate-down-left', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-rotate-down-left', 'rotateInDownLeft', 'rotateOutDownLeft');
}])

.animation('.animate-rotate-up-right', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-rotate-up-right', 'rotateInUpRight', 'rotateOutUpRight');
}])

.animation('.animate-rotate-down-right', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-rotate-down-right', 'rotateInDownRight', 'rotateOutDownRight');
}])

//
// Other cssAnimations
//
.animation('.animate-lightspeed', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-lightspeed', 'lightSpeedIn', 'lightSpeedOut');
}])

.animation('.animate-roll', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-roll', 'rollIn', 'rollOut');
}])

.animation('.animate-hinge', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-hinge', 'fadeIn', 'hinge');
}])

.animation('.animate-swing', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-swing', 'swing', 'swing');
}])


.animation('.animate-pulse', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-pulse', 'pulse', 'pulse');
}])

.animation('.animate-tada', ['cssAnimations', function(cssAnimations) {
    return cssAnimations('animate-tada', 'tada', '');
}]);
