angular.module('angus.controllers').controller('salesReportContainerController', [
    '$scope', '$rootScope', '$q', '$http', 'moment', 'hierarchyService', 'promiseMonitor', 'gridService2', 'gridState', 'restrictedAccessService', 'nodeType', '_',
    function($scope, $rootScope, $q, $http, moment, hierarchyService, PromiseMonitor, gridService2, gridState, restrictedAccessService, nodeType, _) {
        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.dates = {
            startDate: moment.utc($scope.settings.startDate).format('L') || moment(),
            endDate: moment.utc($scope.settings.endDate).format('L') || moment(),
            lastInvoiceDate: moment.utc($scope.settings.endDate).format('L')
        };

        $scope.salesItems = [];

        $scope.categoryNames = [];
        $scope.subCategoryNames = [];

        $scope.categories = {};
        $scope.category = {
            data: []
        };

        $scope.categoriesContainer = [];

        _.forEach($scope.settings.items, function(item) {
            var categoryId = item.categoryKey;
            var subCategoryId = item.subCategoryKey;

            $scope.categoriesContainer.push([categoryId, subCategoryId]);
        });

        function init() {
            return $http.get(('api/subscribers/{0}/margins/categories').format($scope.subscriberId || $rootScope.user.subscriberId))
                .then(function(categories) {
                    _.forEach(categories.data, function(category) {
                        $scope.salesItems.push({
                            categoryKey: category.categoryKey,
                            categoryName: category.categoryName,
                            subCategoryKey: category.subCategoryKey,
                            subCategoryName: category.subCategoryName
                        });

                        if($scope.categoryNames.indexOf(category.categoryName) == -1) {
                            $scope.categoryNames.push(category.categoryName);
                        }

                        if($scope.subCategoryNames.indexOf(category.subCategoryName) == -1) {
                            $scope.subCategoryNames.push(category.subCategoryName);
                        }

                        _.forEach($scope.salesItems, function(item) {
                            _.forEach($scope.categoryNames, function(name) {
                                if(name == item.categoryName) {
                                    $scope.categories[item.categoryName] = [];
                                }
                            });
                        });

                        _.forEach($scope.salesItems, function(item) {
                            _.forEach($scope.subCategoryNames, function(subCatNames) {
                                if(item.subCategoryName == subCatNames) {
                                    $scope.categories[item.categoryName].push({
                                        subCategoryKey: item.subCategoryKey,
                                        subCategoryName: subCatNames,
                                        categoryName: item.categoryName,
                                        categoryKey: item.categoryKey
                                    });
                                }
                            });
                        });
                    });

                    $scope.categoryData = [];

                    _.forIn($scope.categories, function(value, key) {
                        $scope.categoryData.push({
                            name: '<strong>' + key + '</strong>',
                            categoryGroup: true
                        });

                        _.forEach(value, function(item) {
                            $scope.categoryData.push({
                                name: item.subCategoryName,
                                categoryKey: item.categoryKey,
                                subCategoryKey: item.subCategoryKey,
                                ticked: _.some($scope.settings.items, function(setting) {
                                            return (setting.categoryKey == item.categoryKey) && (setting.subCategoryKey == item.subCategoryKey);
                                        })
                            });
                        });

                        $scope.categoryData.push({ categoryGroup: false });
                    });
                });
        }

        $scope.settings.startDate = new Date($scope.settings.startDate) || null;
        $scope.settings.endDate = new Date($scope.settings.endDate) || null;

        $scope.startDate = moment($scope.settings.startDate).format('l');
        $scope.endDate = moment($scope.settings.endDate).format('l');

        init();

        $scope.filter = {
            hierarchyNodeId: $scope.settings.hierarchyNodeId,
            categoryId: $scope.categoriesContainer
        };

        $scope.getSalesReportData = function() {
            $scope.grid.setColDefs(getColDefs());

            var params = {
                divisionId: $scope.filter.hierarchyNodeId,
                startDate: $scope.dates.startDate.format(),
                endDate: $scope.dates.endDate.format(),
            };

            var body = {
                categoryId: $scope.categoryData ?
                    $scope.categoryData
                        .filter(function(category) { return category.ticked })
                        .map(function(category) { return [category.categoryKey, category.subCategoryKey] }) : []
            };

            var rowPromise = $http({
                url: ('api/subscribers/{0}/margins/categories/salesReportDetail').format($rootScope.user.subscriberId),
                method: 'POST',
                params: params,
                data: JSON.stringify(body)
            })
            .then(function(response) {
                response.data.sort(function(a, b) {
                    return (moment(a.date) - moment(b.date));
                });

                return response.data;
            });

            var promise = $scope.grid.setRows(rowPromise);
            // $scope.variancePromises = new PromiseMonitor(promise);
            return promise;
        };

        function getColDefs() {
            var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];

            colDefs.push(gridService2.colDef.createText('invoiceItemCategoryName', 'Category Name', 'invoiceItemCategoryName', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createText('invoiceItemSubcategoryName', 'Subcategory Name', 'invoiceItemSubcategoryName', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createDate('date', 'Date', 'date', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createText('customerId', 'Account Number', 'customerId', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createText('customerName', 'Customer Name', 'customerName', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createNumber('units', 'Quantity', 'units', {}, { decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createText('invoiceItemId', 'Item Number', 'invoiceItemId', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createText('invoiceItemDesc', 'Description', 'invoiceItemDesc', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createText('manufacturer', 'Manufacturer', 'manufacturer', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createCurrency('unitPrice', 'Unit Price', 'unitPrice', {}, { decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createCurrency('unitCost', 'Unit Cost', 'unitCost', {}, { decimalPlaces: 4 }));
            colDefs.push(gridService2.colDef.createCurrency('unitMargin', 'Unit Margin', 'unitMargin', {}, { decimalPlaces: 4 }));
            colDefs.push(gridService2.colDef.createCurrency('extendedPrice', 'Extended Price', 'extendedPrice', {}, { decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createCurrency('extendedCost', 'Extended Cost', 'extendedCost', {}, { decimalPlaces: 4 }));
            colDefs.push(gridService2.colDef.createCurrency('margin', 'Margin', 'margin', {}, { decimalPlaces: 4 }));
            colDefs.push(gridService2.colDef.createDate('acquisitionDateCustomer', 'Customer Acquisition Date', 'acquisitionDateCustomer', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createDate('acquisitionDateTank', 'Tank Acquisition Date', 'acquisitionDateTank', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createText('tradeClassNameCustomer', 'Customer Trade Class', 'tradeClassNameCustomer', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createText('tradeClassNameTank', 'Tank Trade Class', 'tradeClassNameTank', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createText('transactionNumber', 'Transaction Number', 'transactionNumber', { cellClass: ['center'] }));

            return colDefs;
        }

        var nodeColDefs;

        $q.all([
            restrictedAccessService.getAccess(),
            nodeType.getDivisionColDefs(subscriberId)
        ])
        .spread(function(access, colDefs) {
            $scope.restrictedAccess = access.restrictedAccess;
            nodeColDefs = colDefs;
            var gridParams = {
                gridOptions: {
                    groupAggFunction: function(rows) {
                        return _.reduce(rows, function(result, row) {
                            var data = row.data;

                            result.units += data.units;
                            result.extendedPrice += data.extendedPrice;
                            result.extendedCost += data.extendedCost;

                            result.unitPrice = result.units ? result.extendedPrice / result.units : null;
                            result.unitCost = result.units ? result.extendedCost / result.units : null;
                            result.unitMargin = result.units ? (result.extendedPrice - result.extendedCost) / result.units : null;
                            result.margin = result.units ? result.extendedPrice - result.extendedCost : null;

                            return result;
                        }, {
                            units: 0,
                            unitCost: 0,
                            unitMargin: 0,
                            extendedPrice: 0,
                            extendedCost: 0,
                            unitPrice: 0,
                            margin: 0
                        });
                    }
                },
                gridState: gridState(subscriberId, $scope.widgetCode),
                defs: getColDefs(),
                exportOptions: {fileName: 'Sales Report'}
            };

            return gridService2.createGrid(gridParams);
        })
        .then(function(grid) {
            $scope.grid = grid;
            $scope.getSalesReportData();
        });
    }
]);
