angular.module('angus.controllers').controller('fdsServiceMarginCtrl', [
    '$scope', '$rootScope', 'fluentRest', '$q', '_', 'constantsService', 'lastInvoiceDateService', 'dateCalculator', 'moment', 'datePickerService', 'fiscalYearService',
    function($scope, $rootScope, fluentRest, $q, _, constantsService, lastInvoiceDateService, dateCalculator, moment, datePickerService, fiscalYearService) {
        'use strict';

        $scope.periodIndicators = constantsService.servicePeriodIndicators.enums;
        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        function getStuff() {
            var params = {
                startDate: $scope.dates.periodStartDate ? $scope.dates.periodStartDate.format() : null,
                endDate: $scope.dates.lastPostingDate.format(),
                divisionId: $scope.widget.instance.settings.hierarchyNodeId,
                summary: 1
            };

            $scope.widget.setDefaultActionModel($scope.dates);

            return fluentRest
                .api()
                .subscribers($rootScope.user.subscriberId)
                .service()
                .margin()
                .get(params)
                .then(function(summary) {
                    summary.parts.cost = summary.parts.sales / 2;

                    $scope.summary = summary;
                    $scope.total = _.reduce($scope.summary, function(result, item) {
                        result.numberOfInvoices += item.numberOfInvoices;
                        result.sales += item.sales;
                        result.cost += item.cost;
                        result.margin += item.margin;
                        return result;
                    }, {
                        numberOfInvoices: 0,
                        sales: 0,
                        cost: 0,
                        margin: 0
                    });
                });
        }

        var lastPostingDate;

        function getDates() {
            $scope.dates = {};

            var promise = !lastPostingDate
                ? lastInvoiceDateService.getLastInvoiceDateByNodeId($scope.widget.instance.settings.hierarchyNodeId)
                : $q.when(lastPostingDate);

            return promise
                .then(function(date) {
                    lastPostingDate = date;
                    var dates = dateCalculator.calculatePeriod($scope.dates.periodStartDate, lastPostingDate || moment.utc(), $scope.widget.instance.settings.periodIndicatorId.value);

                    $scope.dates.periodStartDate = !$scope.widget.instance.settings.customDate ? dates.startDate : $scope.widget.instance.settings.customDate.startDate;
                    $scope.dates.lastPostingDate = !$scope.widget.instance.settings.customDate ? dates.endDate : $scope.widget.instance.settings.customDate.endDate;
                });
        }

        function init() {
            var promise = getDates().then(getStuff);

            if($scope.widget.instance.settings.periodIndicatorId && $scope.widget.instance.settings.periodIndicatorId.value == 'yearToDate') {
                $scope.dates.periodStartDate = moment().startOf('year');
                $scope.dates.lastPostingDate = moment.utc();
            }

            if($scope.widget.instance.settings.periodIndicatorId && $scope.widget.instance.settings.periodIndicatorId.value == 'fiscalYearToDate') {
                fiscalYearService
                    .getFiscalYearStartMonth(subscriberId)
                    .then(function(fiscalYearStartMonth) {
                        var startingFiscalMonth = fiscalYearStartMonth;
                        var currentMonth = moment().month();

                        if(currentMonth >= startingFiscalMonth) {
                            $scope.dates.periodStartDate = moment().month(startingFiscalMonth).startOf('month');
                            $scope.dates.lastPostingDate = moment.utc();
                        } else {
                            $scope.dates.periodStartDate = moment().month(startingFiscalMonth).startOf('month').subtract(1, 'year');
                            $scope.dates.lastPostingDate = moment.utc();
                        }
                        return $scope.widget.promises.monitor(promise);
                    });
            }

            $scope.widget.instance.settings.displayDate = moment($scope.dates.periodStartDate).format('MMM DD, YYYY') + ' to ' + moment($scope.dates.lastPostingDate).format('MMM DD, YYYY');

            return $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(init);

        $scope.$on('widgetSettingsChanged', function() {
            delete $scope.dates;
            init();
        });

        $scope.openDatePicker = function() {
            return datePickerService
                .openDatePickerModal($scope.dates.periodStartDate, $scope.dates.lastPostingDate, lastPostingDate)
                .then(function(resultDates) {
                    if(resultDates) {
                        $scope.dates.periodStartDate = moment(resultDates[0]).isValid() ? resultDates[0] : resultDates[1];
                        $scope.dates.lastPostingDate = resultDates[1];

                        if($scope.widget.instance.settings.periodIndicatorId.value == constantsService.servicePeriodIndicators.customDateRange) {
                            $scope.periodIndicator = constantsService.servicePeriodIndicators.customDateRange;
                            $scope.widget.instance.settings.customDate = null;
                            $scope.widget.instance.settings.displayDate = moment($scope.dates.periodStartDate).format('MMM DD, YYYY') + ' to ' + moment.utc($scope.dates.lastPostingDate).format('MMM DD, YYYY');
                        }

                        getStuff();
                    }
                });
        };

        function setDateTooltipText() {
            $scope.widget.instance.settings.displayDate =
                $scope.widget.instance.settings.customDate ?
                    moment($scope.widget.instance.settings.customDate.startDate).format('MMM DD, YYYY') + ' to ' + moment.utc($scope.widget.instance.settings.customDate.endDate).format('MMM DD, YYYY') :
                    moment($scope.dates.periodStartDate).format('MMM DD, YYYY') + ' to ' + moment.utc($scope.dates.lastPostingDate).format('MMM DD, YYYY');
        }
    }
]);