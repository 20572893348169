angular.module( 'angus.directives' ).directive( 'appDeliveryEff', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		return {
			scope: {
				model: "=actionModel",
				settings: "=deliveryEffWidgetSettings",
				widgetCode : "="
			},
			templateUrl: actionViewTemplates.deliveryEffReportContainer
		};
	}
] );