(function(_) {

    'use strict';


    Array.prototype.isArray = true;

    String.prototype.format = function() {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function(match, number) {
            return typeof args[number] !== 'undefined' ? (args[number] || '') : match;
        });
    };


    String.prototype.capitalizeFirstLetter = function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }

    String.prototype.camelize = function() {
        return this.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
            if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
            return index == '0' ? match.toLowerCase() : match.toUpperCase();
        });
    };

    var multiGroupBy = function(seq, keys) {
        if (!keys.length)
            return seq;
        var first = keys[0];
        var rest = keys.slice(1);
        return _.mapValues(_.groupBy(seq, first), function(value) {
            return multiGroupBy(value, rest);
        });
    };
    _.mixin({
        'multiGroupBy': multiGroupBy
    });



    _.mixin({
        shallowDiff: function(a, b) {
            return _.omit(a, function(v, k) {
                return b[k] === v; })
        },
        diff: function(a, b) {
            var r = {};
            _.each(a, function(v, k) {
                if (b[k] === v) return;
                // but what if it returns an empty object? still attach?
                r[k] = _.isObject(v) ? _.diff(v, b[k]) : v;
            });
            return r;
        }
    });

})(window._)
