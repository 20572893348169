angular.module('angus.controllers').controller('deliveryForecastingSettingsCtrl', [
    '$scope', 'productsService', 'tradeClassService',
    function ($scope, productsService, tradeClassService) {

        productsService.getProducts()
            .then(function (products) {
                $scope.products = products;
            });

        tradeClassService.getTradeClasses()
            .then(function (classes) {
                $scope.tradeClasses = classes;
            });
    }
]);