angular.module( 'angus.directives' ).directive( 'growthToBudget', [
    'actionViewTemplates',
    function(actionViewTemplates) {
        'use strict';

        return {
            scope: {
                model: '=actionModel',
                settings: '=growthToBudgetWidgetSettings',
                widgetCode : '='
            },
            templateUrl: actionViewTemplates.growthToBudgetDetailView
        };
    }
]).directive('growthToBudgetBarChart', [
    'moment', '_',
    function(moment, _) {
        return {
            scope: {
                ngModel: '='
            },
            link: function(scope, element, attrs) {
                'use strict';

                function generateRandomRGBColor() {
                    var r = Math.floor(Math.random() * 240) + 35,
                        g = Math.floor(Math.random() * 240) + 25,
                        b = Math.floor(Math.random() * 240) + 15;

                    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
                }

                var colors = [
                    'rgb(2, 117, 216)',
                    'rgb(91, 192, 222)',
                    'rgb(92, 184, 92)',
                    'rgb(240, 173, 78)',
                    'rgb(230, 125, 74)'
                ];

                function plot(budgetData) {
                    var chartHeight = 365;
                    var barHeight = chartHeight / (budgetData.budgets.length + 5);
                    var workingDayPercentage = ((budgetData.dates.workingDays.elapsed / budgetData.dates.workingDays.total) * 100);

                    while(budgetData > colors.length) colors.push(generateRandomRGBColor());

                    _.forEach(colors, function(color, index) { budgetData.budgets[index] ? budgetData.budgets[index].color = color : null });

                    var seriesField = 'netUnits.' + budgetData.dates.month;
                    
                    var largestTGLBudgetValueArr = [];
                    var largestValueArr = [];

                    _.forEach(budgetData.budgets, function(budgets) {
                        largestTGLBudgetValueArr.push(budgets.netUnits[budgetData.dates.month]);
                    });

                    largestValueArr = [].concat(largestTGLBudgetValueArr);
                    var largestValue = Math.max.apply(Math, largestValueArr);

                    $(element).kendoChart({
                        theme: 'bootstrap',
                        title: {
                            text: 'Net Tank Gains & Losses through ' + moment(budgetData.dates.dataThroughDate).format('MMMM Do, YYYY'),
                            font: 'bold 20px Helvetica Neue, Helvetica, Arial, sans-serif'
                        },

                        series: [{
                            type: 'bar',
                            border: { width: 0 },
                            data: budgetData.budgets,
                            field: seriesField,
                            visual: function(event) {
                                var changeBarHeight = event.createVisual();
                                changeBarHeight.transform(kendo.geometry.transform().scale(1, barHeight / event.rect.size.height, event.rect.center() ));

                                return changeBarHeight;
                            },
                            overlay: { gradient: 'glass' },
                            labels: {
                                visible: true,
                                template: function(event) { return event.dataItem.productCategory },
                                position: 'center',
                                color: 'rgb(0, 0, 0)',
                                font: 'bold 17px Helvetica Neue, Helvetica, Arial, sans-serif; text-shadow: 0px 0px #222',
                                margin: { left: 100 }
                            }
                        }],

                        valueAxis: {
                            max: largestValue < 100 ? 100 : largestValue + (largestValue - 100),
                            line: {
                                visible: true,
                                color: 'rgb(0, 0, 0)',
                                width: 7
                            },
                            majorGridLines: {
                                visible: true,
                                width: 2
                            },
                            majorTicks: {
                                size: 14,
                                width: 5,
                                visible: true,
                                color: 'rgb(0, 0, 0)'
                            },
                            minorTicks: {
                                size: 0,
                                visible: false
                            },
                            labels: {
                                format: '{0}%',
                                font: 'bold 15px Helvetica Neue, Helvetica, Arial, sans-serif',
                                color: '#000',
                            },
                            plotBands: [{
                                from: workingDayPercentage - 1,
                                to: workingDayPercentage + 1,
                                color: 'rgb(255, 0, 0)'
                            }]
                        },

                        categoryAxis: {
                            line: {
                                visible: true,
                                color: 'rgb(0, 0, 0)',
                                width: 7
                            },
                            majorGridLines: { visible: false },
                            majorTicks: {
                                size: 0,
                                visible: false
                            },
                            minorTicks: {
                                size: 0,
                                visible: false
                            }
                        },

                        chartArea: {
                            height: chartHeight
                        },

                        tooltip: {
                            visible: true,
                            template: function(event) {
                                var net = (event.dataItem.netTankGainsLosses.net).toLocaleString(undefined, { maximumFractionDigits: 1 });
                                var units = (event.dataItem.units[budgetData.dates.month]).toLocaleString(undefined, { maximumFractionDigits: 1 });
                                var netUnits = (event.dataItem.netUnits[budgetData.dates.month]).toLocaleString(undefined, { maximumFractionDigits: 1 });

                                return  '<span class="tooltip-text">Net TGL Units: ' + net + '</span>' +
                                    '<br>' +
                                    '<span class="tooltip-text">Budgeted Units: ' + units + '</span>' +
                                    '<br>' +
                                    '<span class="tooltip-text">Total Net: ' + netUnits + '</span>' + '%';
                            }
                        }
                    });

                    $('.widget').on('resize', function() {
                        kendo.resize($('.chart-wrapper'));
                    });
                }

                scope.$watch(function() {
                    return scope.ngModel;
                }, function(newVal) {
                    if(newVal) plot(newVal);
                });
            }
        };
    }
]);