angular.module('angus.controllers').controller('closingQuotesSettingsCtrl', [
    '$scope', '$rootScope', '$http',
    function($scope, $rootScope, $http) {
        'use strict';

        var subscriberId = $scope.susbcriberId = $rootScope.user.subscriberId;

        return $http({
            url: ('api/subscribers/{0}/costing/indices').format(subscriberId),
            method: 'GET'
        })
        .then(function(response) {
            $scope.commodities = response.data;

            return response;
        });
    }
]);