angular.module('angus.controllers').controller('firmwareUpdateController', [
    '$scope', '$rootScope', 'firmwareUpdateService', 'moment', 'Paging', '_', 'promiseMonitor',
    function($scope, $rootScope, firmwareUpdateService, moment, Paging, _, promiseMonitor) {

        'use strict';  
 
        $scope.loading = false; 
        $scope.cancelHandlerLoading = false; 
        $scope.onFirmwareHandlerLoading = false; 
        $scope.offFirmwareHandlerLoading = false;  
        $scope.csv = {};
        $scope.alerts = [];
        $scope.validData = true;
        $scope.errorCount = 0;
 
        $scope.firmwareUpgradeHandler = function(csv, flag) {  
            var updatedData = []; 
            $scope.errorCount = 0;
            _.forEach(csv.content, function(item) {    
                var cleaned = item["Serial Number"];
                updatedData.push(cleaned)
            }); 
            var firmwareVersionEvaluationFlag = 1;
            if (flag == 'on') {
                $scope.onFirmwareHandlerLoading = true; 
                firmwareVersionEvaluationFlag = 1;
            }
            if (flag == 'off') {
                $scope.offFirmwareHandlerLoading = true; 
                firmwareVersionEvaluationFlag = 0;
            }        
            var data = {
                serialNumbers: updatedData,
                firmwareVersionEvaluationFlag: firmwareVersionEvaluationFlag,
                updateUser: $rootScope.user.username
            } 
            firmwareUpdateService.postFirmware(data) 
            .then(function(result) { 
                if (result && result.status == 200) {
                    $scope.alerts.push({type: 'success', msg: 'Monitor data updated'}); 
                }
                else {
                    $scope.alerts.push({type: 'danger', msg: 'Failed to update the Monitor data!'}); 
                }
            });  
        } 
        
        $scope.checkData = function(data) {  
            var plusRegex = /\+/;
            var result = plusRegex.test(data["Serial Number"]);   
            if (result == true) {
                $scope.validData = false; 
                if ($scope.errorCount === 0) {
                    $scope.alerts.push({type: 'danger', msg: 'Found invalid data, please convert serial number column to "Number Format" to fix this issue'}); 
                    $scope.errorCount += 1;
                }
                return data["Serial Number"] + ' //Not Valid'; 
            }
            else {
                return data["Serial Number"];
            }
        }

        $scope.cancelHandler = function() {  
            $scope.csv = null;
            $scope.errorCount = 0;
            angular.element("input[type='file']").val(null);
        } 
        
        $scope.closeAlert = function (index) {
            $scope.alerts.splice(index, 1);
        }   
    }
]);