angular.module('angus.controllers').controller('pricePlanProfAnalysisSettingsCtrl', [
    '$scope', 'hierarchyService', 'productsService',
    function($scope, hierarchyService, productsService) {

        'use strict';

        productsService.getProducts()
            .then(function(products) {
                $scope.products = products;
            });

        $scope.openDivisionHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };
    }
]);
