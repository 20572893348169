angular.module('angus.controllers').controller('excessCallsReportContainerCtrl', [
  '$scope', '$rootScope', '$http',  '_', 'constantsService', 'drillService', 'moment', 'gridService2', 'nodeType', 'gridState',
  function($scope, $rootScope, $http,  _, constantsService,  drillService, moment, gridService2, nodeType, gridState) {

    'use strict';

    var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

      if($scope.settings.customDate) {
          $scope.dates = {
              startDate: moment($scope.settings.customDate.startDate).format('L') || moment(),
              endDate: moment.utc($scope.settings.customDate.endDate).format('L') || moment()
          }
      } else {
          $scope.dates = {
              startDate: $scope.model.periodStartDate || moment(),
              endDate: $scope.model.lastPostingDate || moment(),
              lastPostingDate: $scope.model.lastPostingDate || moment()
          }
      }


      $scope.filter =  {
            hierarchyNodeId : $scope.settings.hierarchyNodeId,
            minNumberCalls  : $scope.settings.minNumberCalls.value,
            nonContract     : _.find(constantsService.includeExcludeOnly.enums, function(ieo){
                return ieo.value.id == $scope.settings.nonContract.value;
            }).key            
      };    

      $scope.getExcessCalls = function(){  
                    
          var params = {
                divisionId : $scope.filter.hierarchyNodeId,
                sd: $scope.dates.startDate.format(),
                ed: $scope.dates.endDate.format(),
                mc: $scope.filter.minNumberCalls,
                nc: $scope.filter.nonContract,                
          };

        var rowPromise = $http({        
                url: 'api/subscribers/{0}/service/excessCalls/list'.format($rootScope.user.subscriberId),
                method: 'POST',
                data: params
          }).then(function(result){
              return result.data;
          });        

          var promise = $scope.grid.setRows(rowPromise);

        //$scope.promiseMonitor = new PromiseMonitor(promise);
        return promise;
      }



     function getColClicks(){        
          return {
              accountNumber   : { 
                onClick : function(row){ drillService.openAccountInfo(row); }
              },                       
              locationNumber    : { 
                onClick : function(row){ drillService.openServiceLocationInfo(row); }
              } 
          };
      }

      function getColDefs(){      


        function addressValueGetter(params){
            if(!params.data || !params.data.address1) return;
            return params.data.address1 + (params.data.address2 ? (' ' + params.data.address2) : '') + ' ' + params.data.city + ', ' + params.data.stateProvince + ' ' +params.data.postalCode
        }

          var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];                 
          colDefs.push(gridService2.colDef.createNumber('callNumber','Number Of Calls','callNumber', {aggFunc : 'sum'}));      
          colDefs.push(gridService2.colDef.createText('accountNumber','Account Number','accountNumber', { cellClass : ['center']} ));    
          colDefs.push(gridService2.colDef.createText('customerName','Customer Name','customerName'));
          colDefs.push(gridService2.colDef.createText('locationNumber','Service Location','locationNumber', { cellClass : ['center']} ));    
          colDefs.push(gridService2.colDef.createText('address','Address','address', { cellClass : ['center'], valueGetter : addressValueGetter} ));    
          colDefs.push(gridService2.colDef.createText('contractDescription','Current Contract Description','contractDescription', { cellClass : ['center']} ));    
             
          colDefs.push(gridService2.colDef.createDate('contractRenewalDate','Current Contract Renewal Date','contractRenewalDate'));         
          colDefs.push(gridService2.colDef.createDate('lastCall','Most Recent Call','lastCall'));         
          colDefs.push(gridService2.colDef.createText('equipmentName','Equipment Name','equipmentName', { cellClass : ['center']} ));    

          return colDefs;                
      } 

      var nodeColDefs;
      
      nodeType
        .getDivisionColDefs(subscriberId)         
        .then(function(colDefs){
            nodeColDefs = colDefs;          
            var gridParams = {
                gridState     : gridState(subscriberId, $scope.widgetCode),                    
                defs          : getColDefs(),
                clicks        : getColClicks(),
                exportOptions : { fileName: 'Service Revenue' }
            };
            return gridService2.createGrid(gridParams);
        })
        .then(function(grid){     
            $scope.grid = grid;        
            $scope.getExcessCalls();
        });        

  }
]);