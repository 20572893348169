angular.module('angus.controllers').controller('tmsDeliveryEfficiencySettingsCtrl', [
  '$scope', 'constantsService', 'productsService', '$rootScope', 'fluentRest',
  function($scope, constantsService, productsService, $rootScope, fluentRest) {
    'use strict';

    $scope.periodIndicators = constantsService.paygoPeriodIndicators.enums;

    if (typeof $scope.widget.instance.settings.periodIndicatorId === 'undefined'){
      $scope.widget.instance.settings.periodIndicatorId = {value: constantsService.paygoPeriodIndicators.priorMonth.key};
    }

    if (typeof $scope.widget.instance.settings.rangeIncrement === 'undefined'){
        $scope.widget.instance.settings.rangeIncrement = 5;
    }

    if (typeof $scope.widget.instance.settings.paygoProductIds === 'undefined'){
      $scope.widget.instance.settings.paygoProductIds = {};
    }

    $scope.rangeIncrements = [5, 10, 20, 25];

    fluentRest
      .api()
      .subscribers($rootScope.user.subscriberId)
      .dealers($rootScope.user.hierarchy.dealer.nodeId)
      .get()
      .then(function(dealers){
        $scope.dealers = dealers;
      });


    productsService.getPaygoProducts()
      .then(function(paygoProducts){
        $scope.paygoProducts = paygoProducts;
  });
}
]);
