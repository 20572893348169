angular.module('angus.controllers').controller('acctGainLossCtrl', [
    '$scope', '$rootScope', '$http', 'modalService', 'periodIndicators', '$q', 'actionViewTemplates', '_', 'constantsService', 'hierarchyService', 'dateCalculator', 'moment', 'datePickerService', 'fluentRest', 'lastGainLossDateService', 'fiscalYearService',
    function($scope, $rootScope, $http, modalService, periodIndicators, $q, actionViewTemplates, _, constantsService, hierarchyService, dateCalculator, moment, datePickerService, fluentRest, lastGainLossDateService, fiscalYearService) {
        'use strict';

        $scope.Math = Math;

        var dcSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.periodIndicators = constantsService.marginPeriodIndicators.enums;
        $scope.periodIndicator = _.find(constantsService.marginPeriodIndicators, function(indicator) {
            return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
        });

        function getCounts() {

            var params = {
                divisionIds: hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId),
                salesperson: $scope.widget.instance.settings.salesperson ? $scope.widget.instance.settings.salesperson.value : null,
                endDate: $scope.dates.lastDeliveryDate.format(),
                startDate: $scope.dates.periodStartDate ? $scope.dates.periodStartDate.format() : null,
                sourceAcquisitionSourceKeys: $scope.widget.instance.settings.acquisitionSourceIds, 
                sourceTerminationReasonKeys: $scope.widget.instance.settings.terminationReasonIds,
                tradeClassKeys: $scope.widget.instance.settings.tradeClassIds
            };

            return $http({
                url: 'api/subscribers/{0}/customerAccounts/gainsLosses/summary'.format(subscriberId),
                method: 'POST',
                data: params
            }).then(function (res) {
                return res.data;
            })
        }

        function loadWidget() {
            var promise = lastGainLossDateService
                .getLastContainerAcquisitionOrTermiationDate(dcSetting)
                .then(function(lastDeliveryDate) {
                    if(!$scope.dates) {
                        $scope.dates = {};
                        var dates = dateCalculator.calculatePeriod($scope.dates.periodStartDate, lastDeliveryDate || moment.utc(), $scope.widget.instance.settings.periodIndicatorId.value, true);

                        if($scope.widget.instance.settings.periodIndicatorId.value === 'fiscalYearToDate')
                            fiscalYearService
                                .getFiscalYearStartMonth(subscriberId)
                                .then(function(fiscalMonth) {
                                    if(moment().month() >= fiscalMonth)
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month');
                                    else
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month').subtract(1, 'year');

                                    $scope.widget.defaultInitialization();
                                });

                        $scope.dates.periodStartDate = !$scope.widget.instance.settings.customDate ? dates.startDate : $scope.widget.instance.settings.customDate.startDate;
                        $scope.dates.lastDeliveryDate = !$scope.widget.instance.settings.customDate ? dates.endDate : $scope.widget.instance.settings.customDate.endDate;
                    }
                    
                    return getCounts();
                })
                .then(function(response) {
                    $scope.counts = response;
                    $scope.widget.setDefaultActionModel({ dates: $scope.dates });
                });

            return $scope.widget.promises.monitor(promise);
        }

        function reloadWidget() {
            $scope.widget.noContentFlag = false;

            var promise = getCounts()
                .then(function(response) {
                    if(!response) return;
                    $scope.counts = response;
                    $scope.widget.setDefaultActionModel({ dates: $scope.dates });
                });
            $scope.widget.promises.monitor(promise);
        }

        $scope.getDateTooltipText = function() {
            var start = $scope.dates.periodStartDate ? $scope.dates.periodStartDate.format('L') : '';
            var end = $scope.dates.lastDeliveryDate ? $scope.dates.lastDeliveryDate.format('L') : '';
            return start + (!!start && !!end ? ' - ' : '') + end;
        };

        $scope.widget.setDefaultInitialization(loadWidget);

        $scope.$on('widgetSettingsChanged', function() {
            dcSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
            $scope.periodIndicator = _.find(constantsService.marginPeriodIndicators, function(indicator) {
                return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
            });
            delete $scope.dates;
            loadWidget();
        });

        $scope.openDatePicker = function() {
            return lastGainLossDateService
                .getLastContainerAcquisitionOrTermiationDate(dcSetting)
                .then(function(lastDeliveryDate) {
                    return datePickerService
                        .openDatePickerModal(
                            $scope.dates.periodStartDate,
                            $scope.dates.lastDeliveryDate,
                            lastDeliveryDate
                        );
                })
                .then(function(resultDates) {
                    if(resultDates) {
                        $scope.dates.periodStartDate = moment(resultDates[0]).isValid() ? resultDates[0] : resultDates[1];
                        $scope.dates.lastDeliveryDate = resultDates[1];

                        reloadWidget();

                        $scope.periodIndicator = ($scope.widget.instance.settings.periodIndicatorId.value == constantsService.periodIndicators.customDate ||
                            $scope.widget.instance.settings.periodIndicatorId.value == constantsService.periodIndicators.lastDeliveryDate) ?
                            constantsService.periodIndicators.customDate : constantsService.periodIndicators.customDateRange;
                    }
                });
        };

        $scope.openActionView = function(isGains) {
            $scope.widget.openDetailView(actionViewTemplates.acctGainLossDefault, { isGains: !!isGains });
        };

        $scope.openGraphView = function() {
            var periodStartDate = moment($scope.dates.periodStartDate).subtract(1, 'years');

            var scope = {
                propertyName: 'netGain',
                checkProp: 'isGain',
                getGraphValues: function() {
                    return $http({
                        method: 'POST',
                        url: ('api/subscribers/{0}/customerAccounts/gainsLosses/summary/history').format($rootScope.user.subscriberId),
                        data: {
                            //"productIds"  : $scope.widget.instance.settings.productIds.value,
                            divisionIds: hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId),
                            endDate: $scope.dates.lastDeliveryDate.format(),
                            startDate: periodStartDate.format(),
                            acquisitionTermination: [],
                            terminationReason: []
                        }
                    });
                }
            };

            modalService
                .openTopBarModal(actionViewTemplates.modalGraphContainer, scope);
        };
    }
]);