'use strict';

angular.module('angus.directives')
    .directive('multiSelect', [
        '$document', '$',
        function($document, $) {
            return {
                scope: {
                    ngModel: '=',
                    reinit: '=multiSelect'
                },
                controller: [
                    '$scope',
                    function($scope) {
                        this.reselect = function() {
                            if ($scope.resetSelect2) $scope.resetSelect2();
                        };
                    }
                ],
                link: function(scope, element) {

                    element.addClass('form-control');
                    $(element).select2();

                    scope.resetSelect2 = function() {
                        resetSelect2();
                    };

                    function resetSelect2() {
                        $(element).select2('val', scope.ngModel);
                    }

                    scope.$watch(function() {
                        return scope.reinit;
                    }, function(newVal) {
                        if (newVal) resetSelect2(scope.ngModel);
                    }, true);

                }
            };
        }
    ])
    .directive('multiSelectRepeatRenderFinish', [
        function() {
            return {
                restrict: 'A',
                require: '^multiSelect',
                link: function(scope, element, attr, multiSelectCtrl) {

                    scope.$watch(function() {
                        return scope.$last;
                    }, function() {
                        multiSelectCtrl.reselect();
                    });
                }
            };
        }
    ]);
