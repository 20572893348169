angular.module('angus.controllers').controller('acctGainLossCustomersReportContentCtrl', [
	'$scope', '$rootScope', 'hierarchyService', 'actionViewTemplates', 'csvService', 'drillService',  '_', 'datePickerService', 'moment',
	function($scope, $rootScope, hierarchyService, actionViewTemplates, csvService, drillService,  _, datePickerService, moment) {

		$scope.renderComplete = false;

		$scope.popover = {
			deliveryPopover        : actionViewTemplates.deliveryPopover,
			customerPopover        : actionViewTemplates.customerPopover,
			tankPopover            : actionViewTemplates.tankPopover,
			adjustedPercentPopover : actionViewTemplates.adjustedPercentPopover,
			kFactorPopover         : actionViewTemplates.kFactorPopover
		};

		$scope.retrieveAccountInfo         = drillService.retrieveAccountInfo;
		$scope.retrieveTankInfo            = drillService.retrieveTankInfo;
		$scope.retrieveDeliveryInfo        = drillService.retrieveDeliveryInfo;
		$scope.retrieveAdjustedPercentInfo = drillService.retrieveAdjustedPercentInfo;
		$scope.retrieveKFactorInfo         = drillService.retrieveKFactorInfo;
		$scope.retrieveCustomerTanks       = drillService.retrieveCustomerTanks;
		$scope.openDeliveryHistoryReport   = drillService.openDeliveryHistoryReport;


		$scope.$watch(function() {
			return $scope.activeItem;
		}, function(newVal) {
			$scope.renderComplete = false;

			if (!newVal.report) {
				$scope.getData();
			} else {
				$scope.renderComplete = true;
			}
		});

		$scope.openDatePicker = function() {
	 		datePickerService.openDatePickerModal(
					$scope.activeItem.dates.periodStartDate,
					$scope.activeItem.dates.lastDeliveryDate,
					moment())
			.then(function(resultDates) {
				if (resultDates) {
					$scope.activeItem.dates.periodStartDate = resultDates[0];
					$scope.activeItem.dates.lastDeliveryDate = resultDates[1];

					$scope.getData($scope.activeItem.paging.currentPage);
				}
			});
		};

		$scope.getData = function(pageNumber){
			$scope.renderComplete = false;
			$scope.reinit = false;

			delete $scope.activeItem.report;

			var params = {
				d            : $scope.activeItem.params.divisionIds,
				startDate    : $scope.activeItem.dates.periodStartDate.format(),
				endDate      : $scope.activeItem.dates.lastDeliveryDate.format(),
				sortProp     : $scope.activeItem.sorting.sortProp,
				sortDir      : $scope.activeItem.sorting.sortDir,
				s            : $scope.activeItem.params.keys,
				gainLossType : $scope.activeItem.params.gainLossType
			};

			$scope.activeItem.paging
				.postGetPage(('api/subscribers/{0}/customerAccounts/gainsLosses/details')
					.format($rootScope.user.subscriberId), pageNumber || $scope.activeItem.paging.currentPage, params)
				.then(function(accounts){
					$scope.activeItem.report = accounts.items;
					$scope.renderComplete    = true;
				});
		};

	 	$scope.sort = function(propName) {
            if (propName != $scope.activeItem.sorting.sortProp)
                $scope.activeItem.sorting.sortDir = 'asc';
            else
                $scope.activeItem.sorting.sortDir = $scope.activeItem.sorting.sortDir == 'asc' ? 'desc' : 'asc';

            $scope.activeItem.sorting.sortProp = propName;
            $scope.getData($scope.activeItem.paging.currentPage);
        };        

		$scope.downloadInProgress = function(){
            return csvService.downloadInProgress();
        };

        $scope.getCsv = function() {

        	var params = {
				d            : $scope.activeItem.params.divisionIds,
				startDate    : $scope.activeItem.dates.periodStartDate.format(),
				endDate      : $scope.activeItem.dates.lastDeliveryDate.format(),
				sortProp     : $scope.activeItem.sorting.sortProp,
				sortDir      : $scope.activeItem.sorting.sortDir,
				s            : $scope.activeItem.params.keys,
				gainLossType : $scope.activeItem.params.gainLossType
			};

            csvService.postGetCsv(('api/subscribers/{0}/customerAccounts/gainsLosses/details/toCsv').format($rootScope.user.subscriberId), 
            	params, 'Account Gains & Lossses - Customers');            
        };

        $scope.reinitComplete = function(){
        	$scope.reinit = true;
        };

        var headingCenterClass = "text-center clickable";

		$scope.tableHeadings = [{
				name: "Change Date",
				isHidable: false,
				propClass: headingCenterClass,
				propName: 'changeDate',
				isSortable: true
			},  {
				name: "Account Number",
				isHidable: true,
				propClass: headingCenterClass,
				propName: 'accountNumber',
				isSortable: true
			}, {
				name: "Customer Name",
				isHidable: true,
				propClass: headingCenterClass,
				propName: 'accountName',
				isSortable: true
			}, {
				name: "Product",
				isHidable: true,
				propClass: headingCenterClass,
				propName: 'productName',
				isSortable: true
			}, {
				name: $scope.activeItem.params.isLoss ? "Reason" : "Source",
				isHidable: true,
				propClass: headingCenterClass,
				propName: 'sourceReason',
				isSortable: true
			}, {
				name: "Service Only",
				isHidable: true,
				propClass: headingCenterClass,
				propName: 'serviceOnlyFlag',
				isSortable: true
			}, {
				name: "Sales Rep",
				isHidable: true,
				propClass: headingCenterClass,
				propName: 'salesRep',
				isSortable: true
			},  {
				name: $scope.activeItem.params.isLoss ? "Lost Units" : "Projected Units",
				isHidable: true,
				propClass: headingCenterClass,
				propName: 'projectedLossUnits',
				isSortable: true
			}, {
				name: $scope.activeItem.params.isLoss ? "Lost Margin" : "Projected Margin",
				isHidable: true,
				propClass: headingCenterClass,
				propName: 'projectedLossMargin',
				isSortable: true
			}];
	}
]);