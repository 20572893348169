
angular.module('angus.services', ['ngStorage']).factory('geocodingService', [
    '$localStorage', '$q', '$timeout', '$rootScope', '$http', function($localStorage, $q, $timeout, $rootScope, $http) {

        var TaskType = { suggest: 0, geocode: 1, geocodeKey: 2 };
        var SupportedCountries = { Usa: 0, Canada: 1 };
        var geoStorage = $localStorage.geoStorage ? JSON.parse($localStorage.geoStorage) : {};

        var queue = [];

        function handleError(task, message) {
            queue.shift();
            task.d.reject({
                type: 'invalid',
                message: message
            });
        }

        var dequeueAndExecute = function() {
            var task = queue[0];
            if (task) {

                switch (task.type) {
                case TaskType.suggest:
                    {
                        $http({
                                url: ('api/geocoding/suggest'),
                                method: "GET",
                                params: {
                                    q: task.location,
                                    country: task.country
                                }
                            })
                            .success(function(result) {
                                //TODO: we should probably cache the results, however if we're implementing typeahead functionality this could be quite a bit of cached data.  I'll probably do it serverside
                                queue.shift();
                                task.d.resolve(result);
                            })
                            .error(function(err) {
                                handleError(task, 'Invalid request for location suggestions: status=' + err.message + ', location=' + task.location);
                            });
                    }
                    break;
                case TaskType.geocode:
                    {
                        $http({
                                url: ('api/geocoding'),
                                method: "GET",
                                params: {
                                    q: task.location,
                                    country: task.country
                                }
                            })
                            .success(function(result) {
                                geoStorage[task.location] = result;
                                $localStorage.geoStorage = JSON.stringify(geoStorage);
                                queue.shift();
                                task.d.resolve(result);
                            })
                            .error(function(err) {
                                handleError(task, 'Invalid request for geocoding: status=' + err.message + ', location=' + task.location);
                            });
                    }
                    break;
                case TaskType.geocodeKey:
                    {
                        $http({
                                url: ('api/geocoding'),
                                method: "GET",
                                params: {
                                    q: task.location,
                                    key: task.key
                                }
                            })
                            .success(function(result) {
                                geoStorage[task.location] = result;
                                $localStorage.geoStorage = JSON.stringify(geoStorage);
                                queue.shift();
                                task.d.resolve(result);
                            })
                            .error(function(err) {
                                handleError(task, 'Invalid request for geocoding: status=' + err.message + ', location=' + task.location);
                            });
                    }
                    break;

                default:
                    handleError(task, 'Invalid task type=' + task.type);
                }
            }

            //Fire off the next task in the queue
            if (queue.length) $timeout(dequeueAndExecute, 0);

            //Prevent error if $digest is already in progress
            if ($rootScope && !$rootScope.$$phase) {
                $rootScope.$apply();
            }
        };

        return {
            //Expose the list of supported countries
            SupportedCountries: SupportedCountries,

            //Retrieve a list of location suggestions for a given partial location name/address
            suggestLocations: function(location, country) {
                var d = $q.defer();

                if (!location || location == "") {
                    d.resolve(null);
                } else {
                    queue.push({
                        location: location,
                        country: country,
                        d: d,
                        type: TaskType.suggest
                    });

                    //manually execute if this is the first item on the request queue
                    if (queue.length === 1) dequeueAndExecute();
                }

                return d.promise;
            },

            //Geocode a location
            geocodeLocation: function(location, country, suggestionKey) {
                var d = $q.defer();

                //check local storage before adding a request to the queue
                if (_(geoStorage).has(location)) {
                    d.resolve(geoStorage[location]);
                } else {

                    if (suggestionKey) {
                        queue.push({
                            location: location,
                            key: suggestionKey,
                            d: d,
                            type: TaskType.geocodeKey
                        });
                    } else {
                        queue.push({
                            location: location,
                            country: country,
                            d: d,
                            type: TaskType.geocode
                        });
                    }


                    //manually execute if this is the first item on the request queue
                    if (queue.length === 1) dequeueAndExecute();
                }

                return d.promise;
            }
        };
    }
]);