'use strict';

angular.module('fluentRest', [])
	.provider('fluentRestConfig', [function () {

		var config = {
				strictMode: false
			},
			relationships = [];

		//Prototypes
		function Api(getMethod, postMethod, putMethod, deleteMethod) {

			var url = config.baseUrl;

			this.appendUrl = function (toAppend) {
				url += ('/{0}').format(toAppend);
				// console.log('appendUrl:', url);
			};
			this.toString = function () {
				// console.log('toString:', url);
				return url;
			};

			this.get = getMethod;
			this.put = putMethod;
			this.post = postMethod;
			this.delete = deleteMethod;

			if (config.strictMode)
				this._lastMethodKey = '';

			return this;
		}


		function EndPoint(url, children, key) {
			if (!url)
				throw new Error('An endpoint needs a url!');

			this.url = url;
			this.children = children ? addEndPoints(children) : [];
			this.key = key || url;
		}

		function getNewApi(getMethod, postMethod, putMethod, deleteMethod) {
			return new Api(getMethod, postMethod, putMethod, deleteMethod);
		}


		function addRelationship(childEndPointKey, parentEndPointKey) {
			if (config.strictMode)
				relationships.push((parentEndPointKey || '') + '/' + childEndPointKey);
		}

		function addEndPoints(endPoints, parentEndPointKey) {
			var retVal = [];

			for (var i = 0; i < endPoints.length; i++) {
				var endPoint = endPoints[i];

				if (!(endPoint instanceof EndPoint))
					throw new Error('Could not create endpoint. Impropper format!');

				addEndPoint(endPoint, parentEndPointKey);

				if (endPoint.children)
					endPoint.children = addEndPoints(endPoint.children, endPoint.key);

				retVal.push(endPoint);
			}
			return retVal;
		}

		function addEndPoint(endPoint, parentEndPointKey) {

			if (!Api.prototype[endPoint.key]) {
				Api.prototype[endPoint.key] = function () {

					if (config.strictMode && relationships.indexOf(this._lastMethodKey + '/' + endPoint.key) == -1)
						throw new Error(('The method \'{0}\' is inaccessible from \'{1}\'.').format(endPoint.key, parentEndPointKey));

					this.appendUrl(endPoint.url);
					var that = this;
					if (arguments.length > 0)
						_.forEach(arguments, function(identifier){
							if(identifier)
								that.appendUrl(identifier);	
						});
						

					if (config.strictMode)
						this._lastMethodKey = endPoint.key;

					return this;
				};
			}

			relationships.push(endPoint.key, parentEndPointKey);
		}



		return {
			setStrictMode: function (strictMode) {
				config.strictMode = true;
			},
			setBaseUrl: function (baseUrl) {
				config.baseUrl = baseUrl;
			},
			createEndPoint: function (url, children, key) {
				return new EndPoint(url, children, key);
			},
			setEndPoints: function (endPoints) {
				config.endPoints = addEndPoints(endPoints);
			},
			$get: function () {
				return config.endPoints ? {
					api: getNewApi,
					config: config
				} : null;
			}
		};
  }])
	.factory('fluentRest', ['fluentRestConfig', '$http', '$q',
	function (fluentRestConfig, $http, $q) {

			if (!fluentRestConfig.config)
				throw new Error('No configuration provided to FluentRest!');

			function getMethod(params) {
        var deferred = $q.defer();

				$http({
          method: 'GET',
          url   : this.toString(),
          params: params
				})
				.then(function (result) {
					deferred.resolve(result.data);
				}, function(err){
					deferred.reject(err.data);
				});

        return deferred.promise;
			}

			function postMethod(data, params) {
        var deferred = $q.defer();

				$http({
            method: 'POST',
            url   : this.toString(),
            params: params,
            data  : data
					})
					.then(function (result) {
						deferred.resolve(result.data);
					}, function(err){
						deferred.reject(err.data);
					});

        return deferred.promise;
			}

			function putMethod(data, params) {
        var deferred = $q.defer();

				$http({
          method: 'PUT',
          url   : this.toString(),
          params: params,
          data  : data
				})
				.then(function (result) {
					deferred.resolve(result.data);
				}, function(err){
					deferred.reject(err.data);
				});

        return deferred.promise;
			}

			function deleteMethod(params) {
        var deferred = $q.defer();

				$http({
          method: 'DELETE',
          url   : this.toString(),
          params: params
				})
				.then(function (result) {
					deferred.resolve(result.data);
				}, function(err){
          deferred.reject(err.data);
				});

        return deferred.promise;
			}


			return {
				api: function () {
					return fluentRestConfig.api(getMethod, postMethod, putMethod, deleteMethod);
				}

			};
	}
]);