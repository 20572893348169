angular.module('angus.services').factory('salesService', [
    '$http', function ($http) {
 
         const getSalesRepresentatives = function() { 
             let url =  'api/tankMonitors/sales'; 
             return $http.get((url))
                .then(function (res) {
                return res.data;
            });
         }; 
         
         return { 
            getSalesRepresentatives
         };
     }
 ]);
 
 