angular.module('angus')
    .config([
        '$httpProvider', '$urlRouterProvider', '$stateProvider', '$locationProvider', '_',
        function ($httpProvider, $urlRouterProvider, $stateProvider, $locationProvider, _) {

            'use strict';

            const index = '/';
            const signIn = '/SignIn';
            const forgotPassword = '/ForgotPassword';
            const confirmForgotPassword = '/ConfirmForgotPassword';
            const resetPassword = '/ResetPassword';
                // consumerResetPassword = '/Consumer/ResetPassword',
            const confirmResetPassword = '/ConfirmResetPassword';
            const impersonate = '/Impersonate';
            const privacy = '/Privacy';
            const eula = '/Eula';
            const tos = '/Tos';
            const deleteConsumerUser = '/DeleteConsumerUser';


            const systemPaths = [index, signIn, forgotPassword, confirmForgotPassword, resetPassword, confirmResetPassword,/* consumerResetPassword,*/ impersonate, privacy, eula, tos, deleteConsumerUser];

            $httpProvider.defaults.headers.patch = {
                'Content-Type': 'application/json;charset=utf-8'
            };

            $urlRouterProvider
                .rule(function ($injector, $location) {
                    var path = $location.path().toLowerCase();

                    _(systemPaths).forEach(function (systemPath) {
                        if (path.toLowerCase().indexOf(systemPath.toLowerCase()) === 0) { //starts with
                            var beginning = path.substring(0, systemPath.length);
                            path = path.replace(beginning, systemPath);
                        }
                    });
                    $location.replace().path(path);
                })
                .otherwise(signIn);


            $stateProvider
                .state('legal', {
                    abstract: true,
                    controller: 'legalCtrl',
                    templateUrl: 'templates/global/html/legal.html'
                })
                .state('legal.eula', {
                    url: eula,
                    controller: 'legalCtrl',
                    templateUrl: 'templates/global/html/legal/eula.html'
                })
                .state('legal.privacy', {
                    url: privacy,
                    controller: 'legalCtrl',
                    templateUrl: 'templates/global/html/legal/privacy.html'
                })
                .state('legal.tos', {
                    url: tos,
                    controller: 'legalCtrl',
                    templateUrl: 'templates/global/html/legal/tos.html'
                })
                .state('deleteConsumerUser', {
                url: deleteConsumerUser,
                controller: 'deleteConsumerUserCtrl',
                templateUrl: 'templates/global/html/consumer/consumerUserDelete.html'
            })
                .state('signIn', {
                    url: signIn,
                    templateUrl: 'templates/auth/signIn/signIn.html',
                    controller: 'signInCtrl',
                    onEnter: function($state, authenticationService) {

                        if (authenticationService.hasToken()) {
                            $state.go('authenticated')
                        }
                    }
                })
                .state('forgotPassword', {
                    templateUrl: 'templates/auth/forgotPassword/forgotPassword.html',
                    url: forgotPassword,
                    controller: 'forgotPasswordCtrl'
                })
                .state('confirmForgotPassword', {
                    templateUrl: 'templates/unauthenticated/html/confirmForgotPassword.html',
                    url: confirmForgotPassword
                })
                .state('resetPassword', {
                    templateUrl: 'templates/auth/resetPassword/resetPassword.html',
                    url: ('{0}?token').format(resetPassword),
                    controller: 'resetPasswordCtrl'
                })
                .state('confirmResetPassword', {
                    templateUrl: 'templates/unauthenticated/html/confirmResetPassword.html',
                    url: confirmResetPassword
                })
                .state('authenticated', {
                //     abstract: true,
                //     // templateUrl: 'templates/global/html/authenticated.html',
                //     templateUrl: 'templates/global/authenticated/authenticated.html',
                // })
                // .state('authenticated.dashboard', {
                    // templateUrl: 'templates/global/authenticated/dashboard/dashboard.html',
                    templateUrl: 'templates/authenticated/authenticated.html',
                    url: index,
                    onEnter: function($state, authenticationService) {
                        if (!authenticationService.hasToken()) {
                            $state.go('signIn')
                        }
                    },
                    controller: ['$scope', '$rootScope', '$window', 'fluentRest', 'promiseMonitor', '$state', 'authenticationService', '$http', 'modalService', '_', 'dashboardsService',
                        function ($scope, $rootScope, $window, fluentRest, promiseMonitor, $state, authenticationService, $http, modalService, _, dashboardsService) {                                            
                            dashboardsService.init();
                            var promises = new promiseMonitor();

                            var modalInstance = null;
                            var modalOpened = false;

                            function closeExpirationModal(stayLoggedIn) {
                                if (stayLoggedIn)
                                    resetToken();
                            };

                            function openExpirationModal(timeExpiresIn) {
                                modalOpened = true;
                                var scope = $rootScope.$new();

                                function getTime(timeExpiresInSeconds) {
                                    return {
                                        minutes: Math.floor(timeExpiresInSeconds / 60),
                                        seconds: Math.floor(timeExpiresInSeconds % 60)
                                    }
                                }

                                scope.timeExpiresIn = getTime(timeExpiresIn);

                                scope.promises = promises;

                                scope.$on('tokenExpiresSoon', function (event, timeExpiresIn) {
                                    scope.$evalAsync(function () {
                                        scope.timeExpiresIn = getTime(timeExpiresIn);
                                    });
                                });

                                scope.closeExpirationModal = closeExpirationModal;

                                modalInstance = modalService.open({
                                    templateUrl: "templates/global/html/expires.html",
                                    scope: scope
                                }, modalService.types.topBar);
                            };


                            function resetToken() {
                                if (promises.isPending())
                                    return;

                                var promise = $http
                                    .get('identity/refresh-token-legacy', {apiV2: true})
                                    .success(function (data) {
                                        authenticationService.setUserInfo(data.user, data.token, data.expires);
                                    });

                                promises.monitor(promise);
                            }


                            $rootScope.$on('tokenExpiresSoon', function (event, timeExpiresIn) {
                                if (!modalOpened)
                                    openExpirationModal(timeExpiresIn);
                            });

                            $rootScope.$on('tokenReset', function () {
                                if (modalInstance) modalInstance.close(true);
                                modalOpened = false;
                            });

                            $rootScope.$on('tokenExpired', function () {
                                var startsWith = $state.current.name.toLowerCase().indexOf('unauthenticated') === 0;

                                if (!startsWith) {
                                    authenticationService.clearUserInfo('Signed out due to inactivity.');
                                    $window.location.href = '/';
                                }
                            });

                            $rootScope.$on('resetToken', function (event, data) {
                                resetToken();
                            });

                        }
                    ]
                })
                .state('impersonate', {
                    template: `
                        <div class="dashboard-loading">
                            <div class="dashboard-loading-content" >
                                <div layout="row" layout-sm="column" layout-align="space-around">
                                    <md-progress-circular md-diameter="60" md-mode="indeterminate">Impersonating User...</md-progress-circular>
                                </div>
                            </div>
                        </div>`,
                    url: impersonate + '?subscriberId&userId',
                    controller: ['$rootScope', '$http', '$q', 'fluentRest', '$state', '$stateParams', 'authenticationService', '$window', function ($rootScope, $http, $q, fluentRest, $state, $stateParams, authenticationService, $window) {
                        
                        if (!authenticationService.hasImpersonatedUser())
                        {
                            return $http.get(`identity/impersonate-legacy/${$stateParams.userId}`, {apiV2: true})
                            .then(function (res) {
                                const user = res.data;

                                $rootScope.user = authenticationService.impersonateUser($rootScope.user.username, user);
                            })
                            .then(function() {
                                $window.location.reload(true);
                            })
                            .catch(function(err){
                                console.log('fail!: ', err);
                            });
                        }
                        else $state.transitionTo('authenticated');
                    }]
                })

            //$locationProvider.html5Mode(true);
        }
    ]).run([
    '$rootScope', '$location', 'authenticationService', '_',
    function ($rootScope, $location, authenticationService, _) {

        var index = '/',
            signIn = '/SignIn',
            forgotPassword = '/ForgotPassword',
            confirmForgotPassword = '/ConfirmForgotPassword',
            resetPassword = '/ResetPassword',
            // consumerResetPassword = '/Consumer/ResetPassword',
            confirmResetPassword = '/ConfirmResetPassword',
            // impersonate = '/Impersonate',
            eula = '/Eula',
            privacy = '/Privacy',
            tos = '/Tos',
            deleteConsumerUser = '/DeleteConsumerUser';
        // enumerate routes that don't need authentication
        var routesThatDontRequireAuth = [signIn, forgotPassword, resetPassword,/* consumerResetPassword*/ confirmForgotPassword, confirmResetPassword];

        // enumerate routes that ignore the auth state
        var routesThatIgnoreAuth = [eula, privacy, tos,deleteConsumerUser];

        // check if current location matches route
        var unauthenticatedRoute = function (route) {
            return _.find(routesThatDontRequireAuth,
                function (noAuthRoute) {

                    return route.indexOf(noAuthRoute) === 0;
                });
        };
        var authlessRoute = function (route) {
            return _.find(routesThatIgnoreAuth,
                function (noAuthRoute) {
                    return route.indexOf(noAuthRoute) === 0;
                });
        };

        $rootScope.$on('$stateChangeStart', function (e) {

            var destination = $location.url();
            var isUnauthenticatedRoute = unauthenticatedRoute(destination);
            var isSignedIn = authenticationService.hasToken();

            if (authlessRoute(destination)) {
                return;
            }

            if (!isUnauthenticatedRoute && !isSignedIn) {
                if (destination !== index) {
                    e.preventDefault();

                    $location.path(signIn);

                } else {
                    window.location.hash = signIn;
                    $location.path(signIn);
                }


            } else if (isSignedIn) {
                $rootScope.user = authenticationService.getUser();
                
                if ($rootScope.user.role == "sysadmin") {
                    $rootScope.user.isSysAdmin = true;
                }             

                if (isUnauthenticatedRoute) {
                    //e.preventDefault();
                    $location.path(index);
                }
            } else if (!isSignedIn) {
                $location.path(signIn);
            }

        });
    }
]);
