angular.module('angus.controllers').controller('serviceReworkSubRegSettingsCtrl', [
    '$scope', '$rootScope', '$http', '_', 'constantsService',
    function($scope, $rootScope, $http, _, constantsService) {

        'use strict';

        $scope.filter = {};

        $http.get(('api/subscribers/{0}/service/techs').format($scope.subscriberId))
            .then(function(list){
                $scope.techs = list.data;

                _.each($scope.settings.techs.value, function(selection){
                    var update = _.findIndex($scope.techs, function(tech){
                            return tech.id == selection;
                        });
                    if(update >= 0) $scope.techs[update].excludeTicked = true;
                });
            });

        $scope.periodIndicators = constantsService.reworkPeriodIndicators.enums;
        
        $scope.closeTechs = function(){
            $scope.settings.techs.value = angular.copy($scope.filter.techs);
        };
        $scope.serviceCallCategoryList = [{
                key: 1,
               value: 'Installations'
           }, {
                key: 2,
               value: 'A/C'
           }, {
                key: 3,
                value: 'Oil'
            }];
    }
]);