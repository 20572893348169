angular.module( 'angus.directives' ).directive( 'avgGalByTankSize', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		return {
			scope: {
				model: '=actionModel',
				settings: '=avgGalByTankSizeWidgetSettings',
				widgetCode : '='
			},
			templateUrl: actionViewTemplates.avgGalByTankSizeReportContainer
		};
	}
]).directive( 'flotBarChartAvgGal', [
	'$filter',
	function( $filter ) {
		return {
			scope: {
				ngModel: '=',				
			},
			link: function(scope, element, attr){
				'use strict';
				
				function plot(tanks){

					var colors = [
						'rgba(0,153,211, .75)',
						'rgba(73,191,103, .75)',
						'rgba(248,163,38 ,.75)',
						'rgba(149,100,226, .75)',
						'rgba(243,69,38, .75)'
					];

					var data = _.map(tanks, function(tank, index){
						return {
					        label: $filter('number')(tank.numberOfDeliveries),
					        data: [[tank.size + ' Gal' , tank.averageGallonsDeliveredPercent]],
					        color: colors[index],
					        bars:{			          
					          show: true,
					          fill: true,
					          align: 'center',
					          lineWidth: 1,
					          barWidth : 0.6,
					          order: index + 1,
					          fillColor: colors[index]
					      	}
					      };
					});

					var options = {
							xaxis:{
								mode: 'categories',
								tickLength: 0							
							},
							yaxis: {
								 tickFormatter: function (val, axis) { return (val*100).toFixed(0) + '%'}
							},
							grid:{
								hoverable: true,
								clickable: false,
								borderWidth: 0
							},
							legend:{
								show:false,
								labelBoxBorderColor: 'none',
								position: 'left'
							},
							series:{
								shadowSize: 1
							},
							tooltip: true,
							tooltipOpts:{
								defaultTheme: false,
								content: '%s deliveries / %y'
							}
					};
					
					$.plot(element, data, options);  

				}

				scope.$watch(function(){
					return scope.ngModel;
				}, function(newVal){
					if(newVal)
						plot(newVal);
				});
			}
		};
	}
] );
