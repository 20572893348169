angular.module('angus.controllers')
    .controller('paygoTMSAdminContainerCtrl', ['$rootScope','$scope', '$http', '$window', 'actionViewTemplates', '_', 'fluentRest', '$sce', 'modalService', 'Paging', '$filter', 'windowsService',
        function($rootScope,$scope, $http, $window, actionViewTemplates, _, fluentRest, $sce, modalService, paging, $filter, windowsService) {
        'use strict';

        windowsService.setCallingScopeCallBack($scope.windowId, function(newCallingScope){
          const newModel = newCallingScope.model;
          
          if (newModel.isAdmin) {
            $scope.isAdmin = true;
          }

          if (newModel.monitor) {
            $scope.openMonitor(newModel.monitor);
          }
          else {
            $scope.activeItem = $scope.monitorsItem;
          }
        });

        $scope.sideNavTemplate = actionViewTemplates.sideNav;
        $rootScope.$on("savedGeometry", function (evt, data) {
            $scope.activeItem.monitor = data || {};
            $scope.activeItem.name = data.customer.name;
        });

        $scope.monitorsItem = {
            name: 'Monitors',
            icon: 'map',
            templateUrl: actionViewTemplates.paygoTMSAdminMonitors,
            active: true,
            isAdmin : true
        }; 
 
        function checkGroupAccess() {
          $http.get(('api/subscribers/{0}/securityGroups').format($rootScope.user.subscriberId))
          .then(function(securityGroups) { 
            var group =  _.find(securityGroups.data, function(x) {
              return x.id == $rootScope.user.securityGroupId;
            });  
            if (group.id === $rootScope.user.securityGroupId) {
              $scope.isAdmin = group.name.startsWith('Admin');
            } 
          });
        }

        function init() {
            $scope.isSysAdmin = $rootScope.user.role == 'sysadmin';
            $scope.lastSearchTerm = null;
            $scope.pageSize       = 20;
            $scope.items          = [$scope.monitorsItem];

            checkGroupAccess();
            if ($scope.monitor) {
              $scope.openMonitor($scope.monitor);

            } else
                $scope.activeItem = $scope.monitorsItem;
        }

        $scope.trustSrc = function (src) {
          return $sce.trustAsResourceUrl(src);
        }

        $scope.switchActiveItem = function(item) {
            if(item.active) $scope.activeItem = item;
        };

        $scope.removeMonitor = function(item) {
          var index = _($scope.items).findIndex(item);
          _.remove($scope.items, item);
            if (index <= $scope.items.length - 1) $scope.activeItem = $scope.items[index];
            else $scope.activeItem = $scope.items[index - 1];
        };

        $scope.viewTankPhoto = function() {
          var monitor = $scope.monitor || $scope.activeItem.monitor;
          var scope = {
            photo: monitor.photoUri,
            tank: monitor.tankDescription
          };
 
          return modalService
          .openTopBarModal(actionViewTemplates.paygoCustRecViewPhoto, scope)
          .spread(function(photo) {    
          });
        }

        $scope.addComment = function() {
          var monitor = $scope.monitor || $scope.activeItem.monitor;
          var scope = {
            container: {
              id: monitor.id,
              name: monitor.idDisplay
            },
            comment: null,
          significantComment: null,
            tags: null
          };

          return modalService
            .openTopBarModal(actionViewTemplates.paygoCustRecAddComment, scope)
          .spread(function(comment, tags, exception, significantComment) {
              if (!comment)
                return;

              return fluentRest
                .api()
                .subscribers(monitor.subscriberId)
                .customerAccounts(monitor.customer.id)
                .containers(monitor.id)
                .comments()
                .post({
                  comment: comment,
                tags: tags ? tags.split(' ') : null,
                significantComment: significantComment
                })
                .then(function() {
                // if the significant comment checkbox is checked
                    if(significantComment) {
                        // then increment the counter for both view comments and view significant comments
                        $scope.monitor.commentCount = $scope.monitor.commentCount + 1;
                        $scope.monitor.significantCommentCount = $scope.monitor.significantCommentCount + 1;
                    } else {
                        // otherwise increment only the view comments counter
                        $scope.monitor.commentCount = $scope.monitor.commentCount + 1;
                    }

                });
            });
        };

      $scope.viewComments = function() {

        $scope.retrievingComments = true;

        var monitor = $scope.monitor || $scope.activeItem.monitor || $scope.monitorsItem.monitors;

        var url = fluentRest
          .api()
          .subscribers(monitor.subscriberId)
          .customerAccounts(monitor.customer.id)
          .containers(monitor.id)
          .comments()
          .toString();

        var commentPaging = new paging(10);
 
            return commentPaging
              .getPage(url)
              .then(function(comments) {

                var scope = {
                  customerId: monitor.customer.id,
                  subscriberId: monitor.subscriberId,
                  containerId: monitor.id,
                  display: ('Tank - {0}').format(monitor.idDisplay),
                  paging: commentPaging,
                  comments: comments.items, 
                  numberDeleted: 0,
                  selected: {
                    tags: []
                  },
                  getCommentsPage: function(pageNumber, pageSize, that) {
                    that = that || this;
                    that.gettingComments = true;

                    commentPaging.pageSize = pageSize;
                    
                    return commentPaging
                      .getPage(url, pageNumber, { 
                      })
                      .then(function(comments) {
                        that.comments = comments.items;
                        that.gettingComments = false;
                      });
                  },
                  deleteComment: function(comment) {
                    var that = this.$parent;
                    var scope = {
                      title: 'Delete Comment?',
                      message: ('Are you sure you want to delete the comment from {0} made by {1}?').format($filter('utcDate')(comment.timestamp), comment.user)
                    };

                    modalService
                      .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                      .then(function(response) {
                        if (!response)
                          return;

                        return fluentRest
                          .api()
                          .subscribers(that.subscriberId)
                          .customerAccounts(that.customerId)
                          .containers(that.containerId)
                          .comments(comment.id)
                          .delete()
                          .then(function() {
                            return that.getCommentsPage(null, that);
                          })
                          .then(function() {
                            that.numberDeleted = that.numberDeleted + 1;
                          });
                      });
                  }
                };

                return modalService
                  .openTopBarModal(actionViewTemplates.paygoCustRecViewComments, scope)
                  .then(function(numberDeleted) {
                    if (numberDeleted)
                      $scope.monitor.commentCount = $scope.monitor.commentCount - numberDeleted;
                      $scope.retrievingComments = false;
                  });
              }); 

      };

      $scope.downloadTutorial = function(){       
        getStorageFile('GREMLIN-Un-map_or_Edit_Mapping_Instructions.pdf');
      } 
        
      function getStorageFile(filename) {
        var url = ('api/storage'); 
        var data = { filename };
        var promise = $http.post(url, data)
        .success(function(result) {
          if (result) { 
            $window.open(result, '_blank');
          }
          else {
            $window.alert('File not found!');
          }
        })
        .error(function(data, status) {  
            $window.alert('File not found!');
        }); 
      } 

      $scope.copyTankInfo = function() { 
        var monitor = $scope.monitor || $scope.activeItem.monitor || $scope.monitorsItem.monitors;
        if (monitor) {
          var stringToCopy = monitor.customer.name + '\r\n' +
                            'Dealer: ' + monitor.dealerName + '\r\n' +
                            'Customer #: ' + monitor.customer.idDisplay + '\r\n' +
                            'Tank #: ' + monitor.idDisplay + '\r\n' +
                            'Serial #: ' + monitor.serialNumber + '\r\n' +
                            'Monitor Type: ' + monitor.monitorTypeName + '\r\n' +
                            'Tank Size: ' + monitor.tankSize 
  
          var copyElement = document.createElement("textarea");
          copyElement.style.position = 'fixed';
          copyElement.style.opacity = '0';
          copyElement.textContent = stringToCopy;
          var body = document.getElementsByTagName('body')[0];
          body.appendChild(copyElement);
          copyElement.select();
          document.execCommand('copy');
          body.removeChild(copyElement);
        }
      }

      $scope.viewSignificantComments = function() {

        $scope.retrievingComments = true;

        var monitor = $scope.monitor || $scope.activeItem.monitor || $scope.monitorsItem.monitors;

        var url = fluentRest
          .api()
          .subscribers(monitor.subscriberId)
          .customerAccounts(monitor.customer.id)
          .containers(monitor.id)
          .comments()
          .toString();

        url += '?isSignificant=true';

        var commentPaging = new paging(10);
 
            return commentPaging
              .getPage(url)
              .then(function(comments) {

                var scope = {
                  customerId: monitor.customer.id,
                  subscriberId: monitor.subscriberId,
                  containerId: monitor.id,
                  display: ('Tank - {0}').format(monitor.idDisplay),
                  paging: commentPaging,
                  significantComments: comments.items, 
                  numberDeleted: 0,
                  selected: {
                    tags: []
                  },
                  getSignificantCommentsPage: function(pageNumber, that) {
                    that = that || this;
                    that.gettingComments = true;
                    return commentPaging
                      .getPage(url, pageNumber, {
                        tags: that.selected.tags,
                        significantComments: comments.items,
                      })
                      .then(function(comments) {
                        that.comments = comments.items;
                        that.gettingComments = false;
                      });
                  },
                  deleteSignificantComment: function(comment) {
                    var that = this.$parent;
                    var scope = {
                      title: 'Delete Comment?',
                      message: ('Are you sure you want to delete the comment from {0} made by {1}?').format($filter('utcDate')(comment.timestamp), comment.user)
                    };

                    modalService
                      .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                      .then(function(response) {
                        if (!response)
                          return;

                        return fluentRest
                          .api()
                          .subscribers(that.subscriberId)
                          .customerAccounts(that.customerId)
                          .containers(that.containerId)
                          .comments(comment.id)
                          .delete()
                          .then(function() {
                            return that.getSignificantCommentsPage(null, that);
                          })
                          .then(function() {
                            that.numberDeleted = that.numberDeleted + 1;
                          });
                      });
                  }

                };

                return modalService
                  .openTopBarModal(actionViewTemplates.paygoCustRecViewSignificantComments, scope)
                  .then(function(numberDeleted) {
                    if (numberDeleted)
                      $scope.monitor.significantCommentCount = $scope.monitor.significantCommentCount - numberDeleted;
                      $scope.retrievingComments = false;
                  });

              }); 

      };
        function openItem(serialNumber){
            return _.find($scope.items, function(i) {
                return i.monitor && i.monitor.serialNumber == serialNumber;
            });
        }

        function newItem(monitor){
            var item = {
                icon        : 'square',
                templateUrl : actionViewTemplates.paygoTMSAdminMonitor,
                active      : true,
                monitor     : monitor,
                name        : monitor.customer.name,
                openLinkedMonitor : function(){
                    return fluentRest
                        .api()
                        .monitors(monitor.serialNumber)
                        .get()
                        .then(function(linkedMonitor){
                             var scope = {
                                widgetName: 'TMS Admin - Monitors',
                                widgetIcon: 'map',
                                widget: {
                                    instance: {
                                        id: 'tmsMonitorAdmin'
                                    }
                                },
                                model : {
                                    monitor : linkedMonitor
                                },
                                monitor : linkedMonitor
                            };

                            return modalService.openActionView(actionViewTemplates.tmsMonitorAdminDefault, scope, 'tmsMonitorAdmin');
                        });

                }
            };

            $scope.items.push(item);

            return item;
        }

        function isMonitorOpen(serialNumber) {
            return !!openItem(serialNumber);
        }

        $scope.openMonitor = function(monitor, searchTerm) {
            $scope.lastSearchTerm = searchTerm;
            $scope.activeItem     = isMonitorOpen(monitor.serialNumber) ? openItem(monitor.serialNumber) : newItem(monitor);

            fluentRest
              .api()
              .subscribers($scope.isSysAdmin ? monitor.subscriberId : $rootScope.user.subscriberId)
              .customerAccounts($scope.activeItem.monitor.customer.id)
              .containers($scope.activeItem.monitor.id)
              .comments()
              .get()
              .then(function(res) {

                  var monitor = $scope.monitor || $scope.activeItem.monitor

                  monitor.commentCount = res.length;
              });
        };


        init();

    }])
    .controller('paygoTMSAdminMonitorsController', [
        '$scope', '$rootScope', 'fluentRest', 'Paging', 'promiseMonitor', 'csvService', 'restrictedAccessService', 'modalService', 'actionViewTemplates', '$http', 'subscriberService',
        function($scope, $rootScope, fluentRest, Paging,  PromiseMonitor, csvService, restrictedAccessService, modalService, actionViewTemplates,  $http, subscriberService) {
            'use strict';

            $scope.load = true;

            $scope.monitorsItem.dealerSelectVisible = true;

            $scope.clearSearch = function() {
                delete $scope.monitorsItem.searchTerm;
            };

            $scope.importCustomers = function(){ 
                $rootScope.selectedSubscriber = $scope.monitorsItem.selectedSubscriber; 
                return modalService.openTopBarModal(actionViewTemplates.paygoCustRecImport, $scope.monitorsItem);
            };
 
            $scope.changeUnMappedState = function(unMappedAccounts) {
              $scope.unMappedAccounts = unMappedAccounts; 
            }

            $scope.getMonitors = function(pageNumber) {  
                if ($scope.monitorsItem.selectedSubscriber) {
                  $rootScope.impersonateAbosKey = $scope.monitorsItem.selectedSubscriber.abosKey;
                }

                var params = {
                    searchTerm      : $scope.monitorsItem.searchTerm ? $scope.monitorsItem.searchTerm : null,
                    sortBy          : $scope.monitorsItem.sortBy,
                    ascending       : $scope.monitorsItem.ascending,
                    dealerIds       : $scope.monitorsItem.selectedDealers,
                    inactiveMonitor : $scope.inactiveMonitor,
                    unMappedAccounts: $scope.unMappedAccounts
                }; 
                var url = fluentRest
                    .api()
                    .subscribers($scope.isSysAdmin ? $scope.monitorsItem.selectedSubscriber.id : $rootScope.user.subscriberId)
                    .containers()
                    .monitors()
                    .forDealers()
                    .toString(); 
                var promise =  $scope.monitorsItem.paging.getPage(url, pageNumber, params)
                    .then(function(monitors){
                        $scope.monitorsItem.monitors = monitors.items;
                        $scope.monitorsItem.showInactiveMonitorColumn = $scope.inactiveMonitor;
                        $scope.monitorsItem.showUnmapped = $scope.unMappedAccounts;
                    })
                    .catch(function(){
                        $scope.monitorsItem.monitors = [];
                    });

                $scope.monitorsMonitor = new PromiseMonitor(promise);

                return promise;
            };

            $scope.downloadInProgress = function(){
                return csvService.downloadInProgress();
            }; 
 
            function getActiveItem(activeItem) {
              return _.find($scope.monitorsItem.monitors, function(x) {
                return activeItem.serialNumber == x.serialNumber;
              }); 
            }

            function checkGroupAccess() {
              $http.get(('api/subscribers/{0}/securityGroups').format($rootScope.user.subscriberId))
              .then(function(securityGroups) { 
                var group =  _.find(securityGroups.data, function(x) {
                  return x.id == $rootScope.user.securityGroupId;
                });  
                if (group.id === $rootScope.user.securityGroupId) { 
                  $scope.isAdmin = group.name.startsWith('Admin');
                } 
              });
            }

            function checkIsNonIntegrated() {  
              $scope.isSysAdmin = $rootScope.user.role == 'sysadmin';  
              return $http({
                  method: 'GET',
                  url: ('api/subscribers/{0}/tanks/integrated').format($scope.monitorsItem.selectedSubscriber.id),
                  data: {}
              })
                  .then(function (status) {
                      $scope.isNonIntegrated = status.data;
                      $scope.canImport = ($scope.isAdmin || $scope.isSysAdmin) && $scope.isNonIntegrated;
                  }).catch(function (err) {
                      $scope.isNonIntegrated = false;
                  }); 
            }

            $scope.export = function() {
                var params = {
                    searchTerm : $scope.monitorsItem.searchTerm ? $scope.monitorsItem.searchTerm : null,
                    sortBy     : $scope.monitorsItem.sortBy,
                    ascending  : $scope.monitorsItem.ascending,
                    dealerIds  : $scope.monitorsItem.selectedDealers,
                    monitors   : $scope.monitorsItem.monitors,
                    unMappedAccounts: $scope.unMappedAccounts,
                    csv        : true,
                    isSysAdmin    : $scope.isSysAdmin //to generate TMS Admin report view
                };

                var url = fluentRest
                            .api()
                            .subscribers($scope.monitorsItem.isAdmin ? $scope.monitorsItem.selectedSubscriber.id : $rootScope.user.subscriberId)
                            .containers()
                            .monitors()
                            .forDealers()
                            .toString();

                csvService.postGetCsv(url, params, 'TMS Monitors');
            };

            $scope.subscriberChange = function(subscriber){
                $scope.monitorsItem.selectedSubscriber = subscriber;
                $scope.getDealers();
                checkIsNonIntegrated();
            };

            $scope.sort = function(sortBy) {
                if ($scope.monitorsItem.sortBy == sortBy)
                    $scope.monitorsItem.ascending = !$scope.monitorsItem.ascending;
                else {
                    $scope.monitorsItem.sortBy = sortBy;
                    $scope.monitorsItem.ascending = true;
                }
                $scope.getMonitors();
            };

            $scope.getDealers = function () {
                return fluentRest
                    .api()
                    .subscribers($scope.isSysAdmin ? $scope.monitorsItem.selectedSubscriber.id : $rootScope.user.subscriberId)
                    .dealers($scope.isSysAdmin ? undefined : $rootScope.user.hierarchy.dealer.nodeId)
                    .get()
                    .then(function(dealers) {
                        $scope.monitorsItem.dealerSelectVisible = !(!$scope.monitorsItem.isAdmin && (!dealers || dealers.length <= 1));
                        $scope.monitorsItem.dealers = dealers;
                        $scope.monitorsItem.selectedDealers = _.map($scope.monitorsItem.dealers, 'id'); 
                        checkIsNonIntegrated();
                    });
            };

            $scope.openMonitorDelete = function(monitor){  
              modalService
              .openTopBarModal(actionViewTemplates.paygoTMSAdminConfirmDeleteCustomer, {
                monitor: monitor
              })
              .then(removeCustomer);
            }

            function removeCustomer(monitor) {
              if(monitor) {
                  $scope.isLoading = true;
                  var dealer = _.find($scope.monitorsItem.dealers, function(dealer) {
                    return dealer.name == monitor.dealerName;
                  });
               
                  var customer = { 
                    CustomerTankID: monitor.idDisplay,
                    CustomerFirstName: monitor.customer.firstName,
                    CustomerLastName: monitor.customer.lastName,
                    CustomerAddressLine1: monitor.address.addressLine1,
                    CustomerAddressLine2: monitor.address.addressLine2,
                    CustomerID: monitor.customer.idDisplay,
                    CustomerPhoneNumber: monitor.customer.phoneNumber,
                    CustomerPostalCode: monitor.address.postalCode,
                    CustomerState: monitor.address.state,
                    CustomerCity: monitor.address.city,
                    CustomerEmailAddress: monitor.customer.email, 
                    ContainerKey: monitor.tankId,
                    DeleteFlag: 1
                  }
                  var params = { 
                    divisionKey: monitor.divisionKey,  
                    records: [customer],
                    insertUser: $rootScope.user.role
                  } 
                   
                  fluentRest
                      .api()
                      .subscribers($scope.isSysAdmin ? $scope.monitorsItem.selectedSubscriber.id : $rootScope.user.subscriberId)
                      .customerAccounts()
                      .import()
                      .update()
                      .post(params)
                  .then(function (results) {
                    if (results) {
                      $scope.message = results.result;
                      $scope.getMonitors();
                    }
                    else {
                      $scope.message = 'Fail to remove customer data!!';
                    }
                  }); 
              }
            }

            $scope.openMonitorEdit = function(monitor){ 
              var scope = {
                monitor
              }
              return modalService
              .openTopBarModal(actionViewTemplates.paygoTMSAdminMonitorEdit, scope)
              .then(function(monitor){  
              });
            }  

            $scope.$on('monitorUpdate', function(event, data) {
              if (data) {
                $scope.getMonitors(); 
              }
            });

            function init() {
                checkGroupAccess();
                $scope.monitorsItem.selectedSubscriber = $scope.monitorsItem.selectedSubscriber || $rootScope.selectedSubscriber;
                $scope.isSysAdmin = $rootScope.user.role == 'sysadmin';
                $scope.promiseMonitor = new PromiseMonitor();
                $scope.monitorsMonitor = new PromiseMonitor();
                $scope.inactiveMonitor = false;
                $scope.unMappedAccounts = false;

                if (!$scope.monitorsItem.initialized){
                    $scope.monitorsItem.initialized = true;

                    $scope.monitorsItem.paging = new Paging($scope.monitorsItem.pageSize || 25);
                    $scope.monitorsItem.sortBy = 'serialNumber';
                    $scope.monitorsItem.ascending = true;

                    var promise = subscriberService.getAmsSubscribers()
                        .then(function(subscribers){
                            $scope.monitorsItem.subscribers = subscribers;

                            let subscriber;
                            if (!$scope.monitorsItem.selectedSubscriber) {
                              const subscriberId = subscriberService.getSubscriberId();
                              subscriber = subscribers.find(subscriber => subscriber.id == subscriberId);
                            }
                            else {
                              subscriber = $scope.monitorsItem.selectedSubscriber;  
                            }
                            
                            if (!subscriber) {
                              subscriber = _.find($scope.monitorsItem.subscribers, function(s){ return s.id == $rootScope.user.subscriberId }) 
                            } 
                            $scope.monitorsItem.selectedSubscriber = subscriber ? _.find($scope.monitorsItem.subscribers, function(s){ return s.id == subscriber.id;}) : $scope.monitorsItem.subscribers[0];
                             
                            return $scope.getDealers($scope.monitorsItem.selectedSubscriber.id);
                        });

                }
                $scope.promiseMonitor.monitor(promise); 
            }
            
            init();

        }
    ]);
