angular.module('angus.controllers').controller('gainsToBudgetSettingsController', [
    '$scope', '$rootScope', 'hierarchyService',
    function($scope, $rootScope, hierarchyService) {
        'use strict';

        $scope.openDivisionHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };
    }
]);