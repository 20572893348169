angular.module('angus.controllers').controller('arSummaryFuelPakContainerController', [
    '$scope', '$rootScope', '$http', 'actionViewTemplates', 'constantsService', '_', 'Paging',
    function($scope, $rootScope, $http, actionViewTemplates, constantsService, _, paging) {
    	'use strict';
		$scope.sideNavTemplate = actionViewTemplates.sideNav;
		$scope.pageSize = 25;


		$scope.items = [];

		$scope.$watch(function() {
			return $scope.model;
		}, function(newVal) {
			if (newVal) {
				var openReport = _.find($scope.items, function(item) {
					return item.params == newVal;
				});
				if (!openReport) {
					openReport = {
						name: 'Report ' + ($scope.items.length <= 0 ? '1' : $scope.items.length),
						icon: 'table',
						templateUrl: actionViewTemplates.arSummaryFuelPakReportContent,
						active: true,
						params: newVal,
						closeable: $scope.items.length > 1,
						paging: new paging($scope.pageSize),
						sorting: {
							sortProp: 'divisionName',
							sortDir: 'asc'
						},
					};
					$scope.items.push(openReport);
				}
				$scope.activeItem = openReport;
			}
		}, true);

		$scope.switchActiveItem = function(item) {
			if (item.active) {
				$scope.activeItem = item;
			}
		};

		$scope.addNewOpenReport = function(filter, resetDetailedReport) {
			var openReport = _.find($scope.items, function(item) {
				return item.name == filter.name;
			});
			if (!openReport) {
				openReport = {
					name: filter.name,
					icon: 'user',
					templateUrl: actionViewTemplates.arSummaryFuelPakReportContent,
					active: true,
					filter: filter,
					params: $scope.activeItem.params || $scope.model,
					closeable: true,
					report: $scope.activeItem.report,
					aggregates: $scope.activeItem.aggregates,
					paging: new paging($scope.pageSize),
					sorting: {
						sortProp: 'divisionName',
						sortDir: 'asc'
					},
				};
				$scope.items.push(openReport);
			}
			$scope.activeItem = openReport;

			if (resetDetailedReport) $scope.resetDetailedReport();
		};

		$scope.resetDetailedReport = function() {
			var detailedReport = _($scope.items).find({
				name: "Report 1"
			});
			detailedReport.report = null;
			detailedReport.filter = null;
		};

		$scope.renameActiveReportTab = function(newName) {
			$scope.activeItem.name = newName;
		};

		$scope.closeItem = function(report) {
			if (report) {
				var openReport = _.find($scope.items, function(item) {
					return item.name == report.name;
				});
				var index = $scope.items.indexOf(openReport);

				if ($scope.activeItem == openReport)
					$scope.activeItem = $scope.items[index - 1];

				$scope.items.splice(index, 1);
			}
		};
    }
]);