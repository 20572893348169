angular.module('angus.controllers').controller('acctGainLossCustomersContainerCtrl', [
	'$scope', 'actionViewTemplates', '_', 'Paging', 'constantsService',
	function($scope, actionViewTemplates, _, paging, constantsService) {

		$scope.sideNavTemplate = actionViewTemplates.sideNav;
		$scope.pageSize        = 50;
		$scope.items           = [];
		$scope.gainComparision = constantsService.gainLossType.gain.key;

		$scope.$watch(function() {
			return $scope.model;
		}, function(newVal) {
			if(newVal)
				addNewOpenReport(newVal);
		}, true);

		$scope.switchActiveItem = function(item) {
			if (item.active)
				$scope.activeItem = item;
		};

		function addNewOpenReport(model) {
			var openReport = _.find($scope.items, function(item) {
					return (item.params.sourceReason == model.sourceReason && 
								item.params.gainLossType == model.gainLossType);
				});

			if (!openReport) {
				openReport = {
					name        : model.sourceReason,
					icon        : 'table',
					templateUrl : actionViewTemplates.acctGainLossCustomersContent,
					active      : true,
					params      : model,
					dates       : $scope.model.dates,
					closeable   : ($scope.items.length > 0),
					paging      : new paging($scope.pageSize),
					sorting     : {
						sortProp : 'changeDate',
						sortDir  : 'asc'
					},
					table       : []
				};

				$scope.items.push(openReport);
			}

			$scope.activeItem = openReport;
		}

		$scope.closeItem = function(report) {
			if (report) {
				var openReport = _.find($scope.items, function(item) {
						return (item.params.sourceReason == report.params.sourceReason && 
									item.params.gainLossType == report.params.gainLossType);
					});

				var index = $scope.items.indexOf(openReport);

				if ($scope.activeItem == openReport)
					$scope.model = $scope.items[index - 1].params;

				$scope.items.splice(index, 1);
			}
		};
	}
]);