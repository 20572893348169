

function avgGalDeliveryHistoryController($scope, $rootScope, fluentRest, _, drillService,  gridService2,  gridState) {

	'use strict';

	var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;
	
    $scope.$watch(function() {
    	return $scope.model;
    }, function(newVal) {
    	$scope.filters = newVal;    
    	$scope.filters.useTankDeliveryType = true;

    	// var promise = $scope.getDeliveries();
    	// $scope.promiseMonitor = new PromiseMonitor(promise);
    	// return promise;
    });

    $scope.getDeliveries = function() {
    	var rowPromise = fluentRest
			.api()
			.subscribers($rootScope.user.subscriberId)
			.batches()
			.deliveries()
			.post($scope.filters);

        if($scope.grid) {
            $scope.grid.setRows(rowPromise);
        }
    };



   function getColClicks(){        
        return {
            accountNumber 	: { 
            	onClick : function(row){ drillService.openAccountInfo(row, drillService.context.delivery); }
            },                       
            tankNumber 		: { 
            	onClick : function(row){ drillService.openTankInfo(row, drillService.context.delivery); }
            },  
            kFactorVariance : { 
            	onClick : function(row){ if(row.deliveryKey) drillService.openKFactorInfo(row); },
            	isNotClickable : function(params){
            		return !params.data.deliveryKey;	            		
            	}
            },    
            adjIdealDrop    : { 
            	onClick : function(row){ if(row.deliveryKey) drillService.openAdjustedPercentInfo(row); },
            	isNotClickable : function(params){
            		return !params.data.deliveryKey;	            		                		
            	}
            }
        };
    }

    function getColDefs(){
        var colDefs   = [];      	         
        colDefs.push(gridService2.colDef.createDate('deliveryDate','Delivery Date','deliveryDate', { sort : 'asc'}));
        colDefs.push(gridService2.colDef.createText('accountNumber','Account Number','accountNumber', { cellClass : ['center'], sort : 'asc'} ));    
        colDefs.push(gridService2.colDef.createText('customerName','Customer Name','customerName'));
        colDefs.push(gridService2.colDef.createText('tankNumber','Tank Number','tankNumber', { cellClass : ['center'] , sort : 'asc'} ));    
        colDefs.push(gridService2.colDef.createNumber('tankSize','Tank Size','tankSize'));      
        colDefs.push(gridService2.colDef.createNumber('units','Units Delivered','units', { aggFunc : 'sum'}, {decimalPlaces: 1 }));      
        colDefs.push(gridService2.colDef.createNumber('idealDrop','Ideal Drop','idealDrop',{},  { decimalPlaces: 1 }));      
        colDefs.push(gridService2.colDef.createPercentage('percentageOfIdealDrop','% Ideal Drop Delivered','percentageOfIdealDrop', {}, { decimalPlaces: 1 }));      
        colDefs.push(gridService2.colDef.createPercentage('adjIdealDrop','Adjusted % Ideal Drop','adjIdealDrop', {}, {decimalPlaces: 1 }));      
        colDefs.push(gridService2.colDef.createPercentage('kFactorVariance','K-Factor Variance','kFactorVariance', {}, {decimalPlaces: 1 }));      
      	colDefs.push(gridService2.colDef.createBoolean('partialFillFlag','Partial Fill','partialFillFlag'));      
      	colDefs.push(gridService2.colDef.createBoolean('runOutFlag','Run Out','runOutFlag'));      
        colDefs.push(gridService2.colDef.createBoolean('potentialRunOutFlag','Potential Run Out','potentialRunOutFlag'));      
      	colDefs.push(gridService2.colDef.createBoolean('monitored','Tank Monitor','monitored'));
      	colDefs.push(gridService2.colDef.createText('deliveryGroup','Delivery Group','deliveryGroup'));      
        return colDefs;                
   	} 

	       
    var gridParams = { 
    	gridOptions : {
    		groupAggFunction : function(rows){
		   		var percentageOfIdealDropCount  = 0;
		   		var adjIdealDropCount 			= 0;
		   		var kFactorVarianceCount        = 0;

		   		var retVal =  _.reduce(rows, function(result, row){
		   			var data = row.data;
		   			result.units += data.units;

		   			if(data.percentageOfIdealDrop || data.percentageOfIdealDrop == 0){
		   				if(!result.percentageOfIdealDrop) result.percentageOfIdealDrop = 0;
		   				result.percentageOfIdealDrop += data.percentageOfIdealDrop;
		   				percentageOfIdealDropCount++;
		   			}

		   			if(data.adjIdealDrop || data.adjIdealDrop == 0){
		   				if(!result.adjIdealDrop) result.adjIdealDrop = 0;
		   				result.adjIdealDrop += data.adjIdealDrop;
		   				adjIdealDropCount++;
		   			}

		   			if(data.kFactorVariance || data.kFactorVariance == 0){
		   				if(!result.kFactorVariance) result.kFactorVariance = 0;
		   				result.kFactorVariance += data.kFactorVariance;
		   				kFactorVarianceCount++;
		   			}

		   			return result;
	   			}, { 
	   				units :0
					});


		   		if(percentageOfIdealDropCount) retVal.percentageOfIdealDrop = retVal.percentageOfIdealDrop / percentageOfIdealDropCount;
		   		if(adjIdealDropCount) retVal.adjIdealDrop = retVal.adjIdealDrop / adjIdealDropCount;
		   		if(kFactorVarianceCount) retVal.kFactorVariance = retVal.kFactorVariance / kFactorVarianceCount;

		   		return retVal;
		    }
        },
        gridState     : gridState(subscriberId, 'tankSizeDeliveries'),                    
        defs          : getColDefs(),
        clicks        : getColClicks(),
        exportOptions : { fileName: 'Tank Size Deliveries' }
    };

    return gridService2
    	.createGrid(gridParams)	   
	    .then(function(grid){    	
	        $scope.grid = grid;        
	        $scope.getDeliveries();
	    });        

}

angular.module( 'angus.directives' ).directive( 'avgGalDeliveryHistory', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		'use strict';
		
		return {
			scope: {
				model: '=actionModel',
				settings: '=avgGalDeliveryHistoryWidgetSettings',
				widgetCode : '='
			},
			controller : ['$scope', '$rootScope', 'fluentRest', '_', 'drillService', 'gridService2', 'gridState', avgGalDeliveryHistoryController],
			templateUrl: actionViewTemplates.avgGalDeliveryHistory
		};
	}
] );