angular.module('angus.controllers').controller('deliveryPerfSubRegSettingsCtrl', [
	'$scope', 'productsService', 'constantsService', 'deliveryTypeService', 'promiseMonitor', '$q',
	function($scope, productsService, constantsService, deliveryTypeService, promiseMonitor, $q) {
		'use strict';
		$scope.promiseMonitor = new promiseMonitor();
        $scope.periodIndicators = constantsService.periodIndicators.enums;
        $scope.settings.toggleViewOptions = [{
            value: 'Avg. Ideal Drop',
            id: 1
        }, {
            value: 'Tank Size Delivered',
            id: 2
        }];

        var promise = $q.all([productsService.getProducts($scope.subscriberId)
        	.then(function(products) {
                $scope.products = products;
            }),
        	deliveryTypeService.getDeliveryTypes($scope.subscriberId)            
			.then(function(deliveryTypes) {
				$scope.deliveryTypes = deliveryTypes;
            }),
            deliveryTypeService.getDeliveryTypeUsedOptions()
                .then(function (deliveryTypeUsedOptions) {
                    $scope.deliveryTypeUsedOptions = deliveryTypeUsedOptions
                })
		]);

        $scope.promiseMonitor.monitor(promise);
	}
]);