angular.module('angus.controllers').controller('hedgePosCtrl', [
    '$scope', 
    function($scope) {     

        function init() {
          
        }
	
	init();
      
    }
]);