
angular.module('angus.directives')
  .directive('callReasonsSelect', [function () {
    'use strict';

    return {
      restrict: 'A',
      scope: {
        ngModel: '=',
        subscriberId: '=',        
      },
      controller: ['$scope', '$rootScope', '$http', function ($scope, $rootScope, $http) {
          $http
            .get(('api/subscribers/{0}/service/callReasons/list').format($scope.subscriberId || $rootScope.user.subscriberId))
              .then(function (list) {
                  $scope.callReasons = list.data;
              });
      }],
      template: '<div multi-select-ok-wrapped="callReasons" ng-model="ngModel" display-prop="\'code\'" selection-prop="\'id\'"></div>',
    };
  }]);
