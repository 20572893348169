angular.module('angus.directives').directive('floorCount', [
    '$document', '$http', 'actionViewTemplates', 'moment',
    function($document, $http, actionViewTemplates, moment) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, elem, attrs) {
                function floorNumber(val) {
                    var parsed = parseFloat(val, 10);
                    if (parsed !== parsed) {
                        return null;
                    } // check for NaN
                    var floored = Math.floor(parsed);
                    return floored;
                }
            }
        };
    }
]);