angular.module('angus.services').factory('shipmentService', [
    '$http', '$q', function ($http, Q) { 
 
        const getShipmentHeader = function(subscriberKey, includeDeleted) {   
            let url =  'api/tankMonitors/shipments/shipmentHeaders?';   
            if (subscriberKey) {
                url += 'subscriberKey=' + subscriberKey + '&';
            } 
            const includeDeletedValue = includeDeleted ? 1 : 0; 
            url += 'includeDeleted=' + includeDeletedValue;  
            
            return $http.get((url))
               .then(function (res) {
               return res.data;
           });
        };  
 
        const getShipment = function(riderOrderKey, shipmentTrackingID, subscriberKey, includeDeleted) { 
            let url =  'api/tankMonitors/shipments';             
            const hasDeletedValue = (includeDeleted == 1 || includeDeleted == 0);
            if (riderOrderKey || shipmentTrackingID || subscriberKey || hasDeletedValue) {
                url += '?';
            }
            if (riderOrderKey) {
                url += 'riderOrderKey=' + riderOrderKey + '&';
            }
            if (shipmentTrackingID) {
                url += 'shipmentTrackingID=' + shipmentTrackingID + '&';
            }
            if (subscriberKey) {
                url += 'subscriberKey=' + subscriberKey + '&';
            } 
            const includeDeletedValue = includeDeleted ? 1 : 0; 
            url += 'includeDeleted=' + includeDeletedValue; 

            return $http.get((url))
            .then(function (res) { 
                return res.data;
            });
        };     
        
        const getShipmentLine = function(shipmentKey, includeDeleted) { 
            let url =  `api/tankMonitors/shipments/shipmentLine/${shipmentKey}`;  
            const includeDeletedValue = includeDeleted ? 1 : 0; 
            url += '?includeDeleted=' + includeDeletedValue; 

            return $http.get((url))
            .then(function (res) { 
                return res.data;
            });
        };    
 
        const getMonitorSearch = function(monitorTypeKey, shippingBoxID, deliveryDate, serialNumber, simNumber, simcardCarrierKey) {
            let url =  'api/tankMonitors/shipments/monitorsearch?';          
            if (monitorTypeKey) {
                url += 'monitorTypeKey=' + monitorTypeKey + '&';
            }
            if (shippingBoxID) {
                url += 'shippingBoxID=' + shippingBoxID + '&';
            }
            if (deliveryDate) {
                url += 'deliveryDate=' + deliveryDate + '&';
            }  
            if (serialNumber) {
                url += 'serialNumber=' + serialNumber + '&';
            } 
            if (simNumber) {
                url += 'simNumber=' + simNumber + '&';
            } 
            if (simcardCarrierKey) {
                url += 'simcardCarrierKey=' + simcardCarrierKey;
            } 

            return $http.get((url))
            .then(function (res) { 
                return res.data;
            });
        }
        
        const postShipment = function(data) {
            return $http.post('api/tankMonitors/shipments', data, {headers: {'Content-Type': 'application/json'}})
                   .then(function (res) {
                       return res.data;
                   });
        };

        const postReport = function(data) {
            return $http.post('api/tankMonitors/shipments/report', data, {responseType:'arraybuffer'})
                   .then(function (res) {
                       return res.data;
                   });
        };

        return { 
            getShipment,
            getShipmentHeader,
            getShipmentLine,
            getMonitorSearch, 
            postShipment,
            postReport
         };
     }
 ]);
 
 