angular.module('angus.controllers').controller('batchDeliveriesReportContainerCtrl', [
	'$scope', '$rootScope', '$http', 'actionViewTemplates', '_', 'dateToString', 'productsService', 'deliveryTypeService', 'Paging',
	function($scope, $rootScope, $http, actionViewTemplates, _, dateToString, productsService, deliveryTypeService, paging) {
		'use strict';
		
		$scope.sideNavTemplate = actionViewTemplates.sideNav;
		$scope.pageSize        = 50;
		$scope.items           = [];

		$scope.$watch(function() {
			return $scope.model;
		}, function(newVal) {
			if (newVal) {
				addNewOpenReport(newVal);
			}
		}, true);

		$scope.switchActiveItem = function(item) {
			if (item.active) {
				$scope.activeItem = item;
			}
		};

		function addNewOpenReport(model) {
			var openReport = _.find($scope.items, function(item) {
				return item.params == model;
			});
			if (!openReport) {
				openReport = {
						name        : 'Batch: ' + model.batchID + ' on ' + dateToString.formatDate(model.date),
						icon        : 'table',
						templateUrl : actionViewTemplates.batchDeliveriesReportContent,
						active      : true,
						params      : model,
						closeable   : $scope.items.length > 0,
						paging      : new paging($scope.pageSize),
						sorting     : {
							sortProp : 'deliveryTime',
							sortDir  : 'asc'
						},
						table       : []
				};
				$scope.items.push(openReport);
			}
			$scope.activeItem = openReport;
		}

		$scope.closeItem = function(report) {
			if (report) {
				var openReport = _.find($scope.items, function(item) {
					return item.name == report.name;
				});
				var index = $scope.items.indexOf(openReport);

				if ($scope.activeItem == openReport)
					$scope.activeItem = $scope.items[index - 1];

				$scope.items.splice(index, 1);
			}
		};
		
		$scope.getProductCategoryDisplay = productsService.formatProductsList;
		$scope.getDeliveryTypeDisplay = deliveryTypeService.formatDeliveryTypesList;
	}
]);