angular.module('angus.controllers').controller('hedgePosContainerCtrl', [
    '$scope',
    function($scope) {

        function init() {
       
        }

        init();      

    }
]);