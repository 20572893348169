angular.module('angus.services').factory('firmwareUpdateService', [
    '$rootScope', '$http', function ($rootScope, $http) {
 
        const postFirmware = function(data) {
            return $http.post('api/tankMonitors/monitors/firmware', data);
        }; 

        return {
            postFirmware
        };
    }
]);

