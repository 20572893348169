angular
	.module('angus.services')
	.service("serviceHelper", ['$q', '_', 'Paging', ServiceHelper]);

	function ServiceHelper($q, _, Paging){

		return {
			transformFnsForSubscriberId : function(subscriberId, fns){
				return _.reduce(fns, function(result, fn, fnName){
					result[fnName] = function(){ 
						var args = Array.prototype.slice.call(arguments);
						args.unshift(subscriberId);
						return fn.apply(this, args);
					};
					return result;
				},{});
			},
			listOrPage : function(req, params){
				return function(pageNumber, page){					
					var promise;

		        	if(pageNumber){
		        		page = page || new Paging(10);
		        		promise = page
		        			.getPage(req.toString(), pageNumber, params)
		        			.then(function(data){
		        				return [data.items, page];	        				
		        			})
		        	} else promise = req.get(params);
		        	
		        	return $q.when(promise);
				};				
			}
		}
	}