angular.module( 'angus.controllers' ).controller( 'subRegPaygoCtrl', [
	'$scope', 'actionViewTemplates', 'fluentRest', 'promiseMonitor',
	function( $scope, actionViewTemplates, fluentRest, promiseMonitor ) {
		'use strict';

		$scope.promiseMonitor = new promiseMonitor();

		$scope.productTab = {
			name : 'Product Mapping',
			url : actionViewTemplates.subRegPaygoProducts
		};
		$scope.addersTab = {
			name : 'Adders',
			url : actionViewTemplates.subRegPaygoAdders
		};
		$scope.billingCycleTab = {
			name : 'Billing Options',
			url : actionViewTemplates.subRegPaygoBillingCycle
		};
		$scope.exceptionSettingsTab = {
			name : 'Exception Settings',
			url : actionViewTemplates.subRegPaygoExceptionSettings
		};
		$scope.tmsExceptionSettingsTab = {
			name : 'TMS Exception Settings',
			url : actionViewTemplates.subRegTMSExceptionSettings
		}


		$scope.mappingTabs = [$scope.productTab, $scope.addersTab, $scope.billingCycleTab, $scope.exceptionSettingsTab, $scope.tmsExceptionSettingsTab];

		$scope.switchTab = function(mappingTab){
			$scope.activeMappingTab = mappingTab;
		};

		function init(){
			var promise = fluentRest
							.api()
							.subscribers($scope.subscriberId)
							.dealers()
								.get()
								.then(function(dealers){
									if(dealers && dealers.length > 0 ){
										$scope.dealers = dealers;
										$scope.activeDealerId = dealers[0].id;
									}
								});

			$scope.promiseMonitor.monitor(promise);
		}

		init();

	}
] ).controller( 'subRegPaygoProductsCtrl', [
	'$scope', '$q', 'actionViewTemplates', 'fluentRest', '_',
	function( $scope, $q, actionViewTemplates, fluentRest, _ ) {
		'use strict';

		function getProducts(isPaygo){

			var params = {
				isPaygo : isPaygo
			};

			return fluentRest
					.api()
					.subscribers($scope.subscriberId)
					.products()
						.get(params);
		}

		function setProducts(products){
			$scope.products = products.filter(function(pro){return pro.id != '-1' && pro.id != '-2'});
		}

		function setPaygoProducts(products){
			console.log(products);
			$scope.paygoProducts = products
		}


		function init(){
			var promise = $q.all([
								getProducts()
									.then(setProducts),
								getProducts(true)
									.then(setPaygoProducts)
							]);

			$scope.promiseMonitor.monitor(promise);
		}

		$scope.saveMappings = function(){
			$scope.saving = true;
			var mappings = _.map($scope.products, function(product){
					return {
						productId : product.id,
						paygoProductId : product.paygoProductId
					};
			});

			fluentRest
				.api()
				.subscribers($scope.subscriberId)
				.products()
					.put({mappings : mappings}, { isPaygo : true })
					.then(function(){
						$scope.saving = false;
					});
		}

		init();

	}

] ).controller( 'subRegPaygoBillingCycleCtrl', [
	'$scope', '$q', 'actionViewTemplates', 'fluentRest', '_', 'promiseMonitor',
	function( $scope, $q, actionViewTemplates, fluentRest, _, promiseMonitor ) {
		'use strict';

		function getBillingCycleTypes(){
			return fluentRest
					.api()
					.subscribers($scope.subscriberId)
					.divisions()
					.paygo()
					.billingCycle()
					.types()
						.get()
						.then(function(types){
							$scope.billingCycleTypes = types;
						});
		}

		function getSettings(){

			var divisionId =  $scope.divisionId || $scope.activeDealerId;

			return fluentRest
					.api()
					.subscribers($scope.subscriberId)
					.divisions(divisionId)
					.paygo()
					.billingCycle()
						.get();
		}

		function setSettings(settings){
			$scope.billingCycle.id = settings.billingCycleId;
			$scope.billingCycle.day = settings.billingCycleDay;
		}

		function getDivisions(){
			return fluentRest
					.api()
					.subscribers($scope.subscriberId)
					.dealers($scope.activeDealerId)
					.divisions()
						.get();

		}


		function setDivisions(divisions){
			$scope.divisionId = null;
			var start = [{id : null, name: 'All'}]
			$scope.divisions = start.concat(divisions);
			return $scope.divisions;
		}

		function setActiveDivision(){
			$scope.divisionId = null;
		}

		function getUsageOptions(){
			return fluentRest
					.api()
					.subscribers($scope.subscriberId)
					.dealers($scope.activeDealerId)
					.usageOptions()
						.get();
		}

		function setUsageOptions(usageOptions){
			$scope.usageOptions = usageOptions;
		}


		function init(){
			$scope.billingCycle = {};
			$scope.settingsMonitor = new promiseMonitor();
			$scope.usageMonitor = new promiseMonitor();
			$scope.billingCycleDays = [];

			for(var i=1; i <= 31; i++)
				$scope.billingCycleDays.push(i);

			$scope.dayOfMonth = 2;

			var promise = $q.all([
				 					divisionChange(),
									getBillingCycleTypes()
								])
								.then(function(){
									return $q.all([
										getSettings().then(setSettings),
										getUsageOptions().then(setUsageOptions)
									]);
								});


			$scope.promiseMonitor.monitor(promise);
		}


		$scope.setDay = function(){
			$scope.billingCycle.day = $scope.billingCycle.id == $scope.dayOfMonth ? 1 : null;
		}

		function divisionChange(){
			getDivisions()
				.then(setDivisions)
				.then(setActiveDivision)
				.then(getSettings);

			var usagePromise = getUsageOptions()
								.then(setUsageOptions);

			$scope.usageMonitor.monitor(usagePromise);

		}

		$scope.reinit = divisionChange;

		$scope.getNewSettings = function(){
			$scope.settingsMonitor.monitor(getSettings().then(setSettings));
		};

		$scope.saveUsageOptions = function(){


			if(!$scope.usageOptions.seperate){
				$scope.usageOptions.combine.usage = false;
				$scope.usageOptions.combine.usageAdjustment = false;
			}
			return fluentRest
					.api()
					.subscribers($scope.subscriberId)
					.dealers($scope.activeDealerId)
					.usageOptions()
						.put($scope.usageOptions);
		};

		$scope.saveBillingCycle = function(){

			var data = {
				divisionId 		: $scope.divisionId || $scope.activeDealerId,
				billingCycleId  : $scope.billingCycle.id,
				billingCycleDay : $scope.billingCycle.day
			};

			return fluentRest
					.api()
					.subscribers($scope.subscriberId)
					.divisions()
					.paygo()
					.billingCycle()
						.post(data);
		}

		init();

	}
] ).controller( 'subRegPaygoAddersCtrl', [
	'$scope', '$q', 'actionViewTemplates', 'modalService', 'datePickerService', '_', 'moment', 'fluentRest', '$filter',
	function( $scope, $q, actionViewTemplates, modalService, datePickerService, _, moment, fluentRest, $filter ) {
		'use strict';

		function getAddersList(startDate, endDate, id){
			return fluentRest
					.api()
					.subscribers($scope.subscriberId)
					.dealers($scope.activeDealerId)
					.adders()
						.get({
							s : startDate,
							e : endDate,
							id : id
						});
		}

		function setAddersList(addersList){
			$scope.addersList = addersList;
		}

		function saveAdders(adders){
			if(adders){
				var promise = null;
				var data = adders;

				var apiEndpoint = fluentRest
									.api()
									.subscribers($scope.subscriberId)
									.dealers($scope.activeDealerId)
									.adders(adders.id);
				if(adders.id)
					promise = apiEndpoint.put(data);
				else
					promise = apiEndpoint.post(data);

				return promise
						.then(init);
			}
		}


		function init(){

			var promise = getAddersList()
							.then(setAddersList);

			$scope.promiseMonitor.monitor(promise);
		}
		$scope.reinit = init;

		$scope.editAdders = function(adders){
			addEditAdders(adders);
		};

		$scope.addAdders = function(){
			addEditAdders();
		};

		$scope.deleteAdders = function(adders){
			var scope = {
				title : 'Delete Adders',
				message : ('The adders {0} will be deleted.').format(moment.utc(adders.startDate).isSame(moment.utc(adders.endDate))
																		? ('on {0}').format($filter('utcDate')(adders.startDate))
																		: ('from {0} to {1}').format($filter('utcDate')(adders.startDate), $filter('utcDate')(adders.endDate)))
			};

			modalService
				.openTopBarModal(actionViewTemplates.confirmDialog, scope)
				.then(function(response){
					if(response)
						return fluentRest
								.api()
								.subscribers($scope.subscriberId)
								.dealers($scope.activeDealerId)
								.adders(adders.id)
									.delete()
									.then(init);
				});

		}

		function addEditAdders(adders){

			function adjustDateForTimeOffset(dateToAdjust) {
				dateToAdjust = new Date(moment.utc(dateToAdjust).format());
				return moment(dateToAdjust.getTime() + dateToAdjust.getTimezoneOffset() * 60 * 1000).format();
			}

			if(adders){
				adders = _.cloneDeep(adders);
				adders.startDate = adjustDateForTimeOffset(adders.startDate);
				adders.endDate = adjustDateForTimeOffset(adders.endDate);
			}
			else
				adders = {
					startDate : moment().format(),
					endDate : null,
					adder : 0,
					paygoMonitorAdder : 0
				};


			function stopEvent($event){
				$event.preventDefault();
				$event.stopPropagation();
			}



			var scope = {
				saving : false,
				adders : adders,
				dateOptions: $scope.dateOptions = {
					formatYear: 'yy',
					startingDay: 1
				},
				startDateOpened : false,
				endDateOpened : false,
				openStartDate: function($event){
					stopEvent($event);
					this.endDateOpened = false;
					this.startDateOpened = true;
				},
				openEndDate: function($event){
					stopEvent($event);
					this.startDateOpened = false;
					this.endDateOpened = true;
				},
				resetEndDate : function(){
					if(moment(this.adders.endDate).isBefore(moment(this.adders.startDate)))
						this.adders.endDate = this.adders.startDate;
				},
				check : function(adders){
					var that = this;
					that.saving = true;
					getAddersList(that.adders.startDate, that.adders.endDate, adders.id)
						.then(function(addersList){
							var scope;

							if(addersList.length > 0){
								var message = '';
								_.forEach(addersList, function(a){
									message += ('The adders for the date range {0} to {1} will be {2}.\n')
									.format($filter('utcDate')(a.startDate), $filter('utcDate')(a.endDate), (a.isContained && a.id != adders.id) ? 'deleted' : 'modified');
								})
								message += '\nDo you wish to proceed?';

								scope = {
					                title: 'Adders will be modified',
                					message: message
            					};

							}
							else
								scope = {
					                title: 'Adders will be added',
                					message: ('New adders for the date range {0} to {1} will be added.\nDo you wish to proceed?')
                								.format($filter('utcDate')(that.adders.startDate), $filter('utcDate')(that.adders.endDate))
            					};

							modalService
									.openTopBarModal(actionViewTemplates.confirmDialog, scope)
									.then(function(response){
										if(response)
											that.close(adders);
									});
							that.saving = false;
						})
				}
			};

			modalService
				.openTopBarModal(actionViewTemplates.subRegPaygoAddersModal, scope)
				.then(saveAdders);
		}

		init();
	}
] ).controller( 'subRegPaygoExceptionSettingsCtrl', [
	'$scope', '$q', 'actionViewTemplates', 'fluentRest', '_', 'promiseMonitor', 'moment',
	function( $scope, $q, actionViewTemplates, fluentRest, _, promiseMonitor, moment ) {

		'use strict';

		$scope.moment = moment;

		$scope.alerts = [];

		$scope.closeAlert = function(index) {
             $scope.alerts.splice(index, 1);
        };

		function getThresholds(){
			return fluentRest
					.api()
					.subscribers($scope.subscriberId)
					.dealers($scope.activeDealerId)
					.thresholds()
						.get()
						.then(function(monthlyValues){
							_.forEach(monthlyValues, function(monthlyValue, index){
								monthlyValue.name = moment().month(index).format('MMMM');
							});
							$scope.monthlyValues = monthlyValues;
						});
		}

		$scope.updateThresholds = function(){
			$scope.savingThresholds = true;
			return fluentRest
					.api()
					.subscribers($scope.subscriberId)
					.dealers($scope.activeDealerId)
					.thresholds()
						.post($scope.monthlyValues)
						.then(function(){
							$scope.alerts.push({
                            	type: 'success',
                            	msg: '<strong>Success!</strong> The thresholds were saved.'
                        	});
						}, function(){
							$scope.alerts.push({
                            	type: 'danger',
                            	msg: '<strong>Error!</strong> The thresholds were unable to be saved.'
                        	});
						})
						.finally(function(){
							$scope.savingThresholds = false;
						});
		};


		function init(){
			$scope.promiseMonitor.monitor(getThresholds());
		}

		$scope.reinit = init;

		init();

	}
] ).controller( 'subRegTMSExceptionSettingsCtrl', [
	'$scope', '$q', 'actionViewTemplates', 'fluentRest', '_', 'promiseMonitor', 'moment','constantsService',
	function( $scope, $q, actionViewTemplates, fluentRest, _, promiseMonitor, moment, constantsService ) {

		'use strict';

		$scope.moment = moment;
		$scope.constants = constantsService.tmsExceptionThresholds;

		$scope.alerts = [];

		$scope.closeAlert = function(index) {
             $scope.alerts.splice(index, 1);
        };

		function getThresholds(){
        var queryString = {
            group: 'tms'
        };
			return fluentRest
					.api()
					.subscribers($scope.subscriberId)
					.exceptions()
					.thresholds()
						.get(queryString)
						.then(function(thresholds){
							$scope.thresholds = thresholds;
							$scope.poorSonarThreshold = thresholds[$scope.constants.poorSonar.key].monthlyValues[0].value;
							$scope.levelRiseThreshold = thresholds[$scope.constants.levelRise.key].monthlyValues[0].value;
							$scope.criticalLowThreshold = thresholds[$scope.constants.criticalLow.key].monthlyValues[0].value;
							$scope.levelDropThresholds = thresholds[$scope.constants.levelDrop.key].monthlyValues;
						});
		}

		$scope.updateThresholds = function(){
			$scope.savingThresholds = true;

			$scope.thresholds[$scope.constants.poorSonar.key].monthlyValues[0].value = $scope.poorSonarThreshold;
			$scope.thresholds[$scope.constants.levelRise.key].monthlyValues[0].value = $scope.levelRiseThreshold;
			$scope.thresholds[$scope.constants.criticalLow.key].monthlyValues[0].value = $scope.criticalLowThreshold;
			$scope.thresholds[$scope.constants.levelDrop.key].monthlyValues = $scope.levelDropThresholds;

			return fluentRest
					.api()
					.subscribers($scope.subscriberId)
					.exceptions()
					.thresholds()
						.put($scope.thresholds)
						.then(function(){
							$scope.alerts.push({
                            	type: 'success',
                            	msg: '<strong>Success!</strong> The thresholds were saved.'
                        	});
						}, function(){
							$scope.alerts.push({
                            	type: 'danger',
                            	msg: '<strong>Error!</strong> The thresholds were unable to be saved.'
                        	});
						})
						.finally(function(){
							$scope.savingThresholds = false;
						});
		};


		function init(){
			$scope.promiseMonitor.monitor(getThresholds());
		}

		$scope.reinit = init;

		init();

	}
] );
