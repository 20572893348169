'use strict';

angular.module('angus.controllers').controller('marginAnalysisCtrl', [
    '$scope', '$rootScope', '$http', 'modalService', 'dateToString', 'productsService', 'periodIndicators', '$q', 'hierarchyService', 'actionViewTemplates', '_',
    'constantsService', 'moment', 'datePickerService', 'lastInvoiceDateService', 'dateCalculator', 'restrictedAccessService', 'fiscalYearService',
    function($scope, $rootScope, $http, modalService, dateToString, productsService, periodIndicators, $q, hierarchyService, actionViewTemplates, _,
             constantsService, moment, datePickerService, lastInvoiceDateService, dateCalculator, restrictedAccessService, fiscalYearService) {

        var divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.periodIndicators = constantsService.invoicePeriodIndicators.enums;
        $scope.periodIndicator  = _.find(constantsService.invoicePeriodIndicators, function(indicator) {
            return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
        });

        function retrieveMarginData() {
            $scope.widget.noContentFlag = false;

            return $q.all([
                queryMarginAnalysis(),
                restrictedAccessService.getAccess()
            ])
                .spread(function(response, access) {
                    if(access.restrictedAccess.costAndMargin) {
                        $scope.widget.noAccess = true;
                        return null;
                    }
                    if (!response || !response.data) {
                        $scope.widget.noContentFlag = true;
                        $scope.widget.instance.settings.customDate = null;
                        return null;
                    }

                    $scope.analysis = {};
                    $scope.detailAnalysis = {};
                    _.forEach(response.data, function(summary) {
                        if (summary.id === 1) {
                            $scope.analysis.cap = summary.margin;
                            $scope.detailAnalysis.cap = summary.unitPrice;
                        }else if (summary.id === 2) {
                            $scope.analysis.fixed = summary.margin;
                            $scope.detailAnalysis.fixed = summary.unitPrice;
                        } else if (summary.id === 3) {
                            $scope.analysis.variable = summary.margin;
                            $scope.detailAnalysis.variable = summary.unitPrice;

                        }
                    });

                    $scope.widget.setDefaultActionModel($scope.dates)
                });
        }

        function loadWidget() {
            $scope.widget.noContentFlag = false;

            var promise = lastInvoiceDateService.getLastInvoiceDate(divisions)
                .then(function(lastInvoiceDate) {

                    if (!$scope.dates){
                        $scope.dates = {};
                        var dates = dateCalculator.calculatePeriod($scope.dates.periodStartDate, lastInvoiceDate || moment.utc(), $scope.periodIndicator.key);

                        if($scope.periodIndicator.key === 'fiscalYearToDate')
                            fiscalYearService
                                .getFiscalYearStartMonth(subscriberId)
                                .then(function(fiscalMonth) {
                                    if(moment().month() >= fiscalMonth)
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month');
                                    else
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month').subtract(1, 'year');

                                    $scope.widget.defaultInitialization();
                                });

                        $scope.dates.periodStartDate   = !$scope.widget.instance.settings.customDate ? dates.startDate : $scope.widget.instance.settings.customDate.startDate;
                        $scope.dates.lastInvoiceDate   = !$scope.widget.instance.settings.customDate ? dates.endDate : $scope.widget.instance.settings.customDate.endDate;
                    }

                    return retrieveMarginData();
                });

            return $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(loadWidget);

        function queryMarginAnalysis() {
            var d = _.isArray(divisions) ? divisions : [divisions];
            var p = _.isArray($scope.widget.instance.settings.productIds.value) ? $scope.widget.instance.settings.productIds.value : [$scope.widget.instance.settings.productIds.value];
            var t = _.isArray($scope.widget.instance.settings.tradeClassIds) ? $scope.widget.instance.settings.tradeClassIds : [$scope.widget.instance.settings.tradeClassIds.value];

            return $http({
                url: ('api/subscribers/{0}/margins/summary').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    d : d.join(),
                    p : p.join(),
                    s : $scope.dates.periodStartDate ? $scope.dates.periodStartDate.format() : null,
                    e : $scope.dates.lastInvoiceDate ? $scope.dates.lastInvoiceDate.format() : null,
                    t : t.join(),
                    z : $scope.widget.instance.settings.zeroPriceSales.value,
                    usePostingDate: $scope.widget.instance.settings.usePostingDate.value
                }
            });
        }

        $scope.$on('widgetSettingsChanged', function() {
            divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
            $scope.periodIndicator = _.find(constantsService.invoicePeriodIndicators, function(indicator) {
                return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
            });

            delete $scope.dates;
            loadWidget();
        });

        $scope.openDatePicker = function() {
            return lastInvoiceDateService.getLastInvoiceDate(divisions)
                .then(function(lastInvoiceDate) {
                    return datePickerService.openDatePickerModal(
                        $scope.widget.instance.settings.periodIndicatorId.value == constantsService.invoicePeriodIndicators.lastInvoiceDate ? null : $scope.dates.periodStartDate,
                        $scope.dates.lastInvoiceDate,
                        lastInvoiceDate);
                })
                .then(function(resultDates) {
                    if (resultDates) {
                        $scope.dates.periodStartDate = moment(resultDates[0]).isValid() ? resultDates[0] : resultDates[1];
                        $scope.dates.lastInvoiceDate = resultDates[1];

                        $scope.widget.promises.monitor(retrieveMarginData());

                        $scope.periodIndicator = ($scope.widget.instance.settings.periodIndicatorId.value == constantsService.invoicePeriodIndicators.customDate ||
                            $scope.widget.instance.settings.periodIndicatorId.value == constantsService.invoicePeriodIndicators.lastInvoiceDate) ?
                            constantsService.invoicePeriodIndicators.customDate : constantsService.invoicePeriodIndicators.customDateRange;
                    }
                });
        };

        function setDateTooltipText() {
            if ($scope.widget.instance.settings.periodIndicatorId.value != constantsService.invoicePeriodIndicators.lastInvoiceDate && $scope.dates.periodStartDate)
                $scope.dateTooltipText = moment($scope.dates.periodStartDate).format('L') + ' - ' + moment($scope.dates.lastInvoiceDate).format('L');
            else
                $scope.dateTooltipText = moment($scope.dates.lastInvoiceDate).format('L');
        }

        $scope.openGraphView = function(propertyName, id) {
            var startDate = moment($scope.dates.periodStartDate);
            var d         = _.isArray(divisions) ? divisions : [divisions];
            var p         = _.isArray($scope.widget.instance.settings.productIds.value) ? $scope.widget.instance.settings.productIds.value : [$scope.widget.instance.settings.productIds.value];
            var t         = _.isArray($scope.widget.instance.settings.tradeClassIds.value) ? $scope.widget.instance.settings.tradeClassIds.value : [$scope.widget.instance.settings.tradeClassIds.value];

            var scope = {
                propertyName: propertyName,
                getGraphValues: function() {
                    return $http({
                        url: ('api/subscribers/{0}/margins/summary/history').format($rootScope.user.subscriberId),
                        method: 'POST',
                        data: {
                            d  : d.join(),
                            p  : p.join(),
                            s  : startDate.subtract(1, 'years').format(),
                            e  : moment($scope.dates.lastInvoiceDate).format('L'),
                            t  : t.join(),
                            z  : $scope.widget.instance.settings.zeroPriceSales.value,
                            pp : id,
                            w  : true
                        }
                    });
                }
            };

            modalService
                .openTopBarModal(actionViewTemplates.modalGraphContainer, scope);
        };
    }
]);
