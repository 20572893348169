angular
  .module('angus.utils')
  .factory('Throttler', ['$q', '$timeout', function ($q, $timeout) {
    'use strict';

    var index;

    function Throttler(delay) {
    	this.delay 		= delay || 1;
        this.timeout 	= null;
        this.deferred 	= null;
        this.id = index++;
    }

    Throttler.prototype.throttle = function() {
        var that = this;
               
        $timeout.cancel(that.timeout);

        that.timeout = $timeout(function() {
            if(that.deferred) that.deferred.resolve(true);
            that.deferred = null;
        }, that.delay);

        if(that.deferred){
            that.deferred.resolve(false);
            that.deferred = null;
        }

        that.deferred = $q.defer();
        return that.deferred.promise;
    };

    return Throttler;

  }]);
