angular.module('angus.directives').directive('closingQuotesThumbChart', ['_',
    function(_) {
        return {
            restrict: 'A',
            scope: {
                closingQuotesThumbChart: '=',
                active: '='
            },
            link: function(scope, elem, attrs, ctrl) {

                scope.$watch(function() {
                    return scope.active;
                }, function(newVal, oldVal) {
                    if (newVal && oldVal != 'undefined') {
                        var data = [];
                        var ticks = [];
                        var minValue = 1000000;
                        var maxValue = 0;
                        var median = Math.ceil((scope.closingQuotesThumbChart.length - 1) / 2);

                        scope.closingQuotesThumbChart = _.sortBy(scope.closingQuotesThumbChart, function(item) {
                            return item.date;
                        });

                        for (var i = 0; i < scope.closingQuotesThumbChart.length; i++) {
                            data.push([i + 1, scope.closingQuotesThumbChart[i].value]);

                            if (scope.closingQuotesThumbChart[i].value > maxValue) maxValue = Math.ceil(scope.closingQuotesThumbChart[i].value);
                            if (scope.closingQuotesThumbChart[i].value < minValue) minValue = Math.floor(scope.closingQuotesThumbChart[i].value);

                            if (i == 0) ticks.push([i + 1, scope.closingQuotesThumbChart[i].date]);
                            else if (i == median) ticks.push([i + 1, scope.closingQuotesThumbChart[i].date]);
                            else if (i == scope.closingQuotesThumbChart.length - 1) ticks.push([i + 1, scope.closingQuotesThumbChart[i].date]);
                            else ticks.push([i + 1, '']);
                        }

                        var incrementor = (maxValue - minValue) / 4;

                        scope.options = {
                            series: {
                                lines: {
                                    show: true
                                },
                                points: {
                                    show: true
                                },
                            },
                            grid: {
                                hoverable: true,
                                clickable: true
                            },
                            xaxis: {
                                ticks: ticks,
                                labelHeight: 35
                            },
                            yaxis: {
                                ticks: [
                                    [minValue, ''],
                                    [minValue + incrementor, ''],
                                    [minValue + (incrementor * 2), ''],
                                    [minValue + (incrementor * 3), ''],
                                    [maxValue, '']
                                ],
                                autoscaleMargin: 0.4
                            }
                        };

                        $.plot(elem, [data], scope.options);
                    }

                    function showTooltip(x, y, contents) {

                        $('<div id="tooltip">' + contents + '</div>').css({
                            position: 'absolute',
                            display: 'none',
                            top: y - 35,
                            left: x - 35,
                            border: '1px solid #000',
                            padding: '5px',
                            'border-radius': '2px',
                            'background-color': '#222',
                            color: '#fff',
                            'z-index': 9999,
                            opacity: 0.80
                        }).appendTo("body").fadeIn(200);
                    }

                    var previousPoint = null;
                    $(elem).bind("plothover", function(event, pos, item) {

                        $("#x").text(pos.x.toFixed(2));
                        $("#y").text(pos.y.toFixed(2));

                        if (item) {
                            document.body.style.cursor = 'pointer';
                            if (previousPoint != item.dataIndex) {
                                previousPoint = item.dataIndex;

                                $("#tooltip").remove();
                                var x = item.datapoint[0].toFixed(2),
                                    y = item.datapoint[1].toFixed(2);

                                showTooltip(item.pageX, item.pageY, item.datapoint[1] + ' on ' + scope.closingQuotesThumbChart[item.datapoint[0] - 1].date);
                            }
                        } else {
                            document.body.style.cursor = 'default';
                            $("#tooltip").remove();
                            previousPoint = null;
                        }

                    });


                });
            }
        };
    }
]);