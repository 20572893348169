angular.module('angus.directives')
  .directive('dateLabelSelect', [function () {
    'use strict';

    return {
      restrict: 'A',
      scope: {
        startDate      : '=',
        endDate   	   : '=',
        maxDate 	   : '=',
        onDateChange   : '&'
      },
      controller: ['$scope', '$timeout', 'datePickerService', 'moment', function ($scope, $timeout, datePickerService, moment) {

          if($scope.maxDate === undefined) $scope.maxDate = moment();

      	  $scope.openDatePicker = function () {
	      	    datePickerService
	      		    .openDatePickerModal($scope.startDate, $scope.endDate, $scope.maxDate)
	        	    .then(function (resultDates) {
	          		    if(!resultDates) return;
                        return $timeout(function(){
                            $scope.startDate  = resultDates[0];
                            $scope.endDate    = resultDates[1];
                            $scope.$apply();
                        }, 1);
	              })
                .then(function(){
                    $scope.onDateChange();
                });
	    };
      }],
      template: '<h4 class="text-primary pull-right clickable" data-ng-click="openDatePicker()"><i class="fa fa-calendar"></i> <span data-ng-show="startDate">{{startDate.format("L")}} to </span>{{endDate.format("L")}}</h4>',
    };
  }]);
