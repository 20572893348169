angular.module('angus.filters')
	.filter('telephone', function() {
		return function(val) {
		  if (!val) {
		    return '';
      }

      return '(' + val.substring(0, 3) + ') ' + val.substring(3, 6) + '-' + val.substring(6, 10);
		};
	});
