'use strict';

angular.module('angus.controllers').controller('salesReportController', [
    '$scope', '$rootScope', '$http', 'moment', '$q', '_', 'hierarchyService', 'constantsService', 'lastInvoiceDateService', 'dateCalculator', 'datePickerService', 'restrictedAccessService', 'fiscalYearService',
    function($scope, $rootScope, $http, moment, $q, _, hierarchyService, constantsService, lastInvoiceDateService, dateCalculator, datePickerService, restrictedAccessService, fiscalYearService) {
        var divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.widget.instance.settings.periodIndicatorId = $scope.widget.instance.settings.periodIndicatorId || {};
        
        $scope.periodIndicators = constantsService.invoicePeriodIndicators.enums;
        $scope.periodIndicator = _.find(constantsService.invoicePeriodIndicators, function(indicator) {
            return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
        });

        function retrieveSalesReportData() {
            $scope.widget.noContentFlag = false;

            return $q.all([
                querySalesReportData(),
                restrictedAccessService.getAccess()
            ])
                .spread(function(response, access) {
                    if(access.restrictedAccess.costAndMargin) {
                        $scope.widget.noAccess = true;
                        return null;
                    }
                    if(!response || !response.data) {
                        $scope.widget.noContentFlag = true;
                        $scope.widget.instance.settings.customDate = null;
                        return null;
                    }

                    _.forEach(response.data, function(salesInfo) {
                        $scope.numberOfSales = salesInfo.numberOfSales;
                        $scope.salesRevenue = salesInfo.salesRevenue;
                        $scope.quantity = salesInfo.quantity;
                    });

                    $scope.widget.setDefaultActionModel($scope.dates);
                });
        }

        function loadWidget() {
            $scope.widget.noContentFlag = false;

            var promise = lastInvoiceDateService
                .getLastInvoiceDate(divisions)
                .then(function(lastInvoiceDate) {
                    if(!$scope.dates) {
                        $scope.dates = {};

                        if(!$scope.widget.instance.settings.periodIndicatorId)
                            $scope.widget.instance.settings.periodIndicatorId = {};

                        if(!$scope.widget.instance.settings.periodIndicatorId.value)
                            $scope.widget.instance.settings.periodIndicatorId.value = constantsService.invoicePeriodIndicators.enums[0].key;

                        var dates = dateCalculator.calculatePeriod($scope.dates.periodStartDate, lastInvoiceDate || moment.utc(), $scope.widget.instance.settings.periodIndicatorId.value);

                        if($scope.widget.instance.settings.periodIndicatorId.value === 'fiscalYearToDate')
                            fiscalYearService
                                .getFiscalYearStartMonth(subscriberId)
                                .then(function(fiscalMonth) {
                                    if(moment().month() >= fiscalMonth)
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month');
                                    else
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month').subtract(1, 'year');

                                    $scope.widget.defaultInitialization();
                                });

                        $scope.dates.periodStartDate = !$scope.widget.instance.settings.customDate ? dates.startDate : $scope.widget.instance.settings.customDate.startDate;
                        $scope.dates.lastInvoiceDate = !$scope.widget.instance.settings.customDate ? dates.endDate : $scope.widget.instance.settings.customDate.endDate;
                    }

                    return retrieveSalesReportData();
                });

            return $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(loadWidget);

        function querySalesReportData() {
            return $http({
                url: ('api/subscribers/{0}/margins/categories/salesReportSummary').format(subscriberId),
                method: 'POST',
                data: {
                    divisions: divisions,
                    // startDate: $scope.dates.periodStartDate ? moment.utc($scope.dates.periodStartDate).format() : null,
                    // endDate: moment.utc($scope.dates.lastInvoiceDate).format(),
                    startDate: $scope.dates.periodStartDate ? $scope.dates.periodStartDate.format() : null,
                    endDate: $scope.dates.lastInvoiceDate ? $scope.dates.lastInvoiceDate.format() : null,
                    categoryId: $scope.widget.instance.settings.categoryData ?
                        $scope.widget.instance.settings.categoryData.filter(function(category) {
                            return category.ticked;
                        })
                            .map(function(category) {
                                return [category.categoryKey, category.subCategoryKey];
                            }) : []
                }
            });
        }

        $scope.$on('widgetSettingsChanged', function() {
            divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
            $scope.periodIndicator = _.find(constantsService.invoicePeriodIndicators, function(indicator) {
                return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
            });

            delete $scope.dates;
            loadWidget();
        });

        $scope.openDatePicker = function() {
            return lastInvoiceDateService.getLastInvoiceDate(divisions)
                .then(function(lastInvoiceDate) {
                    return datePickerService.openDatePickerModal(
                        $scope.widget.instance.settings.periodIndicatorId.value == constantsService.invoicePeriodIndicators.lastInvoiceDate ? null : $scope.dates.periodStartDate,
                        $scope.dates.lastInvoiceDate,
                        lastInvoiceDate);
                })
                .then(function(resultDates) {
                    if(resultDates) {
                        $scope.dates.periodStartDate = moment(resultDates[0]).isValid() ? resultDates[0] : resultDates[1];
                        $scope.dates.lastInvoiceDate = resultDates[1];

                        $scope.widget.promises.monitor(retrieveSalesReportData());

                        $scope.periodIndicator = ($scope.widget.instance.settings.periodIndicatorId.value == constantsService.invoicePeriodIndicators.customDate ||
                            $scope.widget.instance.settings.periodIndicatorId.value == constantsService.invoicePeriodIndicators.lastInvoiceDate) ?
                            constantsService.invoicePeriodIndicators.customDate : constantsService.invoicePeriodIndicators.customDateRange;
                    }
                });
        };
    }
]);