angular.module('angus.directives').directive('modalGraph',
[
    '$document', '$http', 'actionViewTemplates',
    function ($document, $http, actionViewTemplates) {
        return {
            controller: [
                '$scope', '$http', function ($scope) {


                    $scope.graphValues = [];

                    $scope.graphValues = [
                                {
                                    "label": "Weekly: Last 12-Month",
                                    "data": [[1, 29], [2, 30], [3, 32], [4, 33], [5, 32], [6, 31], [7, 30], [8, 29], [9, 26], [10, 23], [11, 21], [12, 18], [13, 16], [14, 14],
                                            [15, 11], [16, 8], [17, 6], [18, 4], [19, 2], [20, 1], [21, 0], [22, 0], [23, 0], [24, 0], [25, 0], [26, 0], [27, 0], [28, 0], [29, 0], [30, 0],
                                            [31, 0], [32, 0], [33, 0], [34, 0], [35, 0], [36, 0], [37, 0], [38, 0], [39, 0], [40, 1], [41, 4], [42, 8], [43, 11], [44, 13], [45, 14],
                                            [46, 16], [47, 19], [48, 22], [49, 25], [50, 27], [51, 27], [52, 28], [53, 28]]
                                }
                        ];

                    $scope.close = function () {
                        if ($scope.closeGraphViewCb) $scope.closeGraphViewCb();
                        if ($scope.modalClosedCb) $scope.modalClosedCb();
                    };
                }
            ],
            templateUrl: actionViewTemplates.hddTrackerContent,
            link: function (scope, element, attrs, ctrl) {

                scope.modalClosedCb = function () {
                    $document.unbind('click');
                };

                element.bind('click', function (e) {
                    e.stopPropagation();
                });

                $document.bind('click', function () {
                    scope.$apply(scope.closeGraphViewCb);
                    if (scope.modalClosedCb) scope.modalClosedCb();
                });

                scope.standardizePropertyName = function () {
                    return scope.propertyName.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
                };

                scope.propertyDate = function() {
                    return scope.selectedDate;
                };

                var options = {
                    lines: {
                        show: true
                    },
                    points: {
                        show: true
                    },
                    xaxis: {
                        tickSize: 1
                    },
                    series: {
                        lines: {
                            show: true
                        },
                        points: {
                            show: true
                        }
                    },
                    grid: {
                        hoverable: true,
                        clickable: true
                    }

                };

                $.plot($('.flot-graph'), scope.graphValues,
                    options);

                $("<div id='tooltip'></div>").css({
                    position: "absolute",
                    display: "none",
                    border: "1px solid #fdd",
                    padding: "2px",
                    "background-color": "#fee",
                    opacity: 0.80
                }).appendTo(".topBarModalContent");

                $('.flot-graph').bind("plothover", function (event, pos, item) {
                        if (item) {
                            var x = item.datapoint[0].toFixed(0),
                                y = item.datapoint[1].toFixed(0);

                            $('#tooltip').html('HDD Variance of Week: ' + x + '='  + y)
                                .css({ top: item.pageY + 5, left: item.pageX + 5 })
                                .fadeIn(200);
                        } else {
                            $('#tooltip').hide();
                        }
                });



            }
        };
    }
]);