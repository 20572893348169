angular.module('angus.directives').directive('tmsDealerExceptions', [
    '$document', 'actionViewTemplates',
    function($document, actionViewTemplates) {
        'use strict';

        return {
            scope: {
                closeCb: '=',
                actionModel: '=',
                widgetClass: '=',
                windowId: '='
            },
            controller: function($scope) {


                $scope.close = function() {
                    if ($scope.modalClosedCb) $scope.modalClosedCb();
                    if ($scope.closeCb) $scope.closeCb();
                };

                $scope.$watch(function() {
                    return $scope.actionModel;
                }, function(newVal) {
                    if (newVal) $scope.activeDealerIds = newVal.activeDealerIds || null;
                }, true);
            },
            templateUrl: 'templates/widgets/tmsDealerExceptions/html/actionViews/tmsDealerExceptionsActionView.html',
            link: function(scope, element) {
                scope.modalClosedCb = function() {
                    $document.unbind('click');
                };

                element.bind('click', function(e) {
                    e.stopPropagation();
                });
            }
        };
    }
]);