angular.module('angus.directives').directive('customPagination',
    function() {

        'use strict';

        return {
            scope: {
                paging: '=customPagination',
                queryCb: '&query'
            },
            controller: ['$scope',
                function($scope) {

                    function getPage(validationCondition, pageNumber) {
                        if (!$scope.paging.isPending() && validationCondition)
                            $scope.queryCb({
                                pageNumber: pageNumber
                            });
                    }

                    $scope.nextPage = function() {
                        getPage($scope.paging.currentPage < $scope.paging.numberOfPages, $scope.paging.currentPage + 1);
                    };

                    $scope.previousPage = function() {
                        getPage($scope.paging.currentPage > 1, $scope.paging.currentPage - 1);
                    };

                    $scope.lastPage = function() {
                        getPage($scope.paging.currentPage != $scope.paging.numberOfPages, $scope.paging.numberOfPages);
                    };

                    $scope.firstPage = function() {
                        getPage($scope.paging.currentPage != 1, 1);
                    };
                }
            ],
            restrict: 'A',
            templateUrl: 'templates/global/html/pagination/paginationContainer.html',
            link: function(scope, element, attrs) {

            }
        };
    });

angular.module('angus.directives').directive('extendedCustomPagination', ['$q',
    function($q) {

        'use strict';

        return {
            scope: {
                paging: '=extendedCustomPagination',
                queryCb: '&query',
                reinit: '='
            },
            controller: ['$scope',
                function($scope) {

                    var numberOfPagesShown = $scope.numberOfPagesShown = 9;

                    function compareNumbers(a, b) {
                        return a - b;
                    }

                    function generatePagesArray(currentPage, numberOfPages) {
                        var pages = [];

                        if (numberOfPages < $scope.numberOfPagesShown) {
                            for (var i = 1; i <= numberOfPages; i++)
                                pages.push(i);
                        } else if (currentPage > Math.ceil(numberOfPagesShown / 2)) {

                            if (currentPage > $scope.paging.numberOfPages - numberOfPagesShown)
                                for (var i = $scope.paging.numberOfPages - numberOfPagesShown; i <= $scope.paging.numberOfPages; i++)
                                    pages.push(i);
                            else {
                                pages.push(currentPage);

                                var offset = Math.floor(numberOfPagesShown / 2);
                                if (numberOfPagesShown % 2 === 0)
                                    pages.push(paging.currentPage - offset--);

                                for (var i = offset; i > 0; i--)
                                    pages.push(currentPage - i, currentPage + i);

                                pages.sort(compareNumbers);
                            }
                        } else
                            for (var i = 1; i <= numberOfPagesShown; i++)
                                pages.push(i);


                        return pages;
                    }


                    function getPage(validationCondition, pageNumber) {
                        if (!$scope.paging.isPending() && validationCondition)
                            $q.when($scope.queryCb({
                                pageNumber: pageNumber,
                                pageSize: $scope.paging.pageSize
                            }));
                    }

                    $scope.nextPage = function() {
                        getPage($scope.paging.currentPage < $scope.paging.numberOfPages, $scope.paging.currentPage + 1);
                    };

                    $scope.previousPage = function() {
                        getPage($scope.paging.currentPage > 1, $scope.paging.currentPage - 1);
                    };

                    $scope.lastPage = function() {
                        getPage($scope.paging.currentPage != $scope.paging.numberOfPages, $scope.paging.numberOfPages);
                    };

                    $scope.firstPage = function() {
                        getPage($scope.paging.currentPage != 1 > 1, 1);
                    };

                    $scope.getPage = function(pageNumber) {
                        $scope.paging.currentPage = pageNumber;
                        getPage(pageNumber >= 1 && pageNumber <= $scope.paging.numberOfPages, pageNumber);

                    };

                    $scope.updatePageSize = function(size) {
                        $scope.paging.pageSize = size;
                        $scope.paging.numberOfPages = Math.ceil($scope.paging.totalCount / size);
                        $scope.paging.currentPage = 1;
                        getPage(true, $scope.paging.currentPage);
                    };

                    function redisplay(){
                      if ($scope.paging) {
                            $scope.displayPages = generatePagesArray($scope.paging.currentPage, $scope.paging.numberOfPages);

                            var from = ($scope.paging.currentPage - 1) * $scope.paging.pageSize + 1;
                            var to = $scope.paging.currentPage * $scope.paging.pageSize;

                            if ($scope.paging.totalCount === 0)
                                to = 0;

                            $scope.records = {
                                from: from,
                                to: to < $scope.paging.totalCount ? to : $scope.paging.totalCount
                            };
                        }
                    }


                    $scope.$watch(function() {
                        return $scope.reinit;
                    }, function(newVal, oldVal) {
                        if (newVal != oldVal) redisplay();
                    });

                    $scope.$watch(function() {
                        return $scope.paging;
                    }, function(newVal, oldVal) {
                        if (newVal != oldVal) redisplay();
                    }, true);

                    redisplay();

                    //$scope.displayPages = generatePagesArray($scope.paging.currentPage, $scope.paging.numberOfPages)
                }
            ],
            restrict: 'A',
            templateUrl: 'templates/global/html/pagination/detailedPaginationContainer.html',
            link: function(scope, element, attrs) {
                scope.showRecordCounts = attrs.showRecordCounts;
                scope.showAll = attrs.showAll;
            }
        };
    }
]);
