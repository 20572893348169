angular.module('angus.services').service('yearMonthSelectService', [
    '$q', 'constantsService', 'moment', '_',
    function($q, constantsService, moment, _) {

      function getMonths(year) {
        var today = moment();

        if (year == today.year()) {
          var currentMonth = today.month() + 1;
          return _.filter(constantsService.months.enums, function(month) {
            return month.value.number <= currentMonth;
          });
        }
        return constantsService.months.enums;
      }


      return {
        getMonths: getMonths,
        getYears: function(year) {

          var years = [];

          for (var i = 0; i < 10; i++) {
            years.push(year - i);
          }

          return years;
        },
        yearChanged: function(year, currentMonth) {
          var deferred = $q.defer();

          var months = getMonths(year);

          if (!_.some(months, function(month) {
              return month.value.number == currentMonth;
            }))
            currentMonth = 1;

          deferred.resolve([months, currentMonth]);

          return deferred.promise;
        },

        calculateDates: function(selectedYear, selectedMonth, startMonth, lastInvoiceDate, fiscalYearMode, fiscalSelectedYear){
          var periodEndDate = null;
          var distanceFromEndDate = null;

          var result = {};


          if (fiscalYearMode) {
            periodEndDate = moment().month(startMonth).year(fiscalSelectedYear).startOf('month');
              result.periodStartDate  = moment(periodEndDate).subtract(1, 'year');

              distanceFromEndDate = periodEndDate.diff(lastInvoiceDate, 'months');

            if(distanceFromEndDate < 12 && distanceFromEndDate >= 0)
                result.periodEndDate = lastInvoiceDate;
            else
                result.periodEndDate = moment(periodEndDate).subtract(1, 'month').utc().endOf('month');
          } else {
            periodEndDate = moment().month(selectedMonth - 1).year(selectedYear).startOf('month');

            result.periodStartDate = lastInvoiceDate && lastInvoiceDate.diff(periodEndDate, 'month') >= 0 ?
                                                  moment().month(selectedMonth - 1)
                                                    .year(selectedYear).startOf('month') :
                                                  moment(lastInvoiceDate).startOf('month');

              distanceFromEndDate = periodEndDate.diff(lastInvoiceDate, 'months');

            if(distanceFromEndDate === 0)
                result.periodEndDate = lastInvoiceDate;
            else
                result.periodEndDate = moment(periodEndDate).utc().endOf('month');
          }

          return result;
        }

      };
    }
  ]);
