angular.module('angus.directives').directive("viewTanksContentLoader", [
	'$compile', '$templateCache', '$timeout', '$document', 'Paging',
	function($compile, $templateCache, $timeout, $document, paging) {
		return {
			scope: {
				queryObj     : "=",
				context 	 : '=', 
				getDataUrl   : "&contentUrl",
				dataTemplate : "=viewTanksContentLoader",
				callbackFn   : "&callbackFn"
			},
			link: function(scope, element) {

				scope.paging = new paging(10);
				scope.opened = false;

				element.bind('click', function() {
					if (!scope.content) {
						element.empty().append('<div class="text-center"><i class="fa fa-spinner fa-spin"></span></div>');
						element.removeClass('clickable');
						element.removeClass('text-primary');
						init(1);
					}
				});

				function init(pageNumber) {
					scope.paging.getPage(scope.getDataUrl({
						item: scope.queryObj
					}), pageNumber)
						.then(function(results, errors) {
							if (errors) {
								element.empty().append('<div class="text-center">No content found.</div>');
							} else {
								scope.containers = results.items;

								scope.content = $compile($templateCache.get(scope.dataTemplate))(scope);
								element.empty().append(scope.content);
							}
						});
				}

				scope.retrievePagedResult = function(pageNumber) {
					element.empty().append('<div class="text-center"><i class="fa fa-spinner fa-spin"></span></div>');

					scope.paging.getPage(scope.getDataUrl({
						item: scope.queryObj
					}), pageNumber)
						.then(function(results) {
							scope.containers = results.items;
							scope.content = $compile($templateCache.get(scope.dataTemplate))(scope);
							element.empty().append(scope.content);
						});
				};

				scope.clickClose = function(args) {
					$timeout(function() { // evalAsync gave errors.. this is working.
						scope.callbackFn({
							value: args
						});
					});
				};

				scope.createHistoryObj = function(container) {
					if (scope.content)
						return {
							accountNumber : scope.queryObj.accountNumber,
							tankNumber    : container.containerId,
							containerKey  : container.containerKey,
							productName   : container.productName,
							customerKey   : scope.queryObj.customerKey,
							customerName  : scope.queryObj.customerName || scope.$parent.item.customerName,
							tankSize      : container.capacity,
							idealDrop 	  : container.idealDrop
						};
					else
						return null;
				};
			}
		};
	}
]);