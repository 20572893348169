angular.module('angus.controllers').controller('tmsMonitorAdminSettingsCtrl', ['$scope', '$http', 'promiseMonitor', 'modalService', 'alertsService', TmsMonitorAdminSettingsCtrl]);

function TmsMonitorAdminSettingsCtrl($scope, $http, PromiseMonitor, modalService, AlertsService){
	'use strict';

	function getCurrentSettings(){
		var opts = { method: 'GET', url: ('api/monitors/{0}/settings').format($scope.activeItem.monitor.serialNumber) };
		return $http(opts).then(function(result){ $scope.activeItem.settings = result.data; });
	}

	function updateSettings(model){
		var opts = { url: ('api/monitors/{0}/settings').format($scope.activeItem.monitor.serialNumber), method: 'PUT', data : model };
		return $http(opts)
		.then(function(result){ 			
			$scope.settingsAlerts.addSuccess('The settings have been saved.');
			return getCurrentSettings();
		});
	}

	$scope.updateSettings = function(){	
		var proceed = true;	

		if($scope.activeItem.settings.connection.hour.value || $scope.activeItem.settings.connection.hour.value == 0){
			if(isNaN($scope.activeItem.settings.connection.hour.value)){
				$scope.settingsAlerts.addError('\'Connection Hour\' must be a number.');
				proceed = false;
			}

			if($scope.activeItem.settings.connection.hour.value < 0 || $scope.activeItem.settings.connection.hour.value > 23){
				$scope.settingsAlerts.addError('\'Connection Hour\' must be between 0 and 23.');
				proceed = false;
			}
		}

		if($scope.activeItem.settings.connection.minute.value || $scope.activeItem.settings.connection.hour.value == 0){
			if($scope.activeItem.settings.connection.minute.value < 0 || $scope.activeItem.settings.connection.minute.value > 59){
				$scope.settingsAlerts.addError('\'Connection Minute\' must be between 0 and 59.');
				proceed = false;
			}	

			if(isNaN($scope.activeItem.settings.connection.minute.value)){
				$scope.settingsAlerts.addError('\'Connection Minute\' must be a number.');
				proceed = false;
			}
		}


		if(($scope.activeItem.settings.api.port.value || $scope.activeItem.settings.api.port.value == 0) && isNaN($scope.activeItem.settings.api.port.value)){
			$scope.settingsAlerts.addError('\'API Port\' must be a number.');
			proceed = false;
		}
		if(($scope.activeItem.settings.tcp.timeout.value || $scope.activeItem.settings.tcp.timeout.value == 0) && isNaN($scope.activeItem.settings.tcp.timeout.value)){
			$scope.settingsAlerts.addError('\'TCP Timeout\' must be a number.');
			proceed = false;
		}
		if(($scope.activeItem.settings.tcp.retries.value || $scope.activeItem.settings.tcp.retries.value == 0) && isNaN($scope.activeItem.settings.tcp.retries.value)){
			$scope.settingsAlerts.addError('\'TCP Retries\' must be a number.');
			proceed = false;
		}
		if(($scope.activeItem.settings.sonicControl.value || $scope.activeItem.settings.sonicControl.value == 0) && isNaN($scope.activeItem.settings.sonicControl.value)){
			$scope.settingsAlerts.addError('\'Sonic Control\' must be a number.');
			proceed = false;
		}

		if(!proceed) return;

		var title = 'Save Monitor Settings?';
		var message = 'Are you sure you want to save these settings? Giving a monitor impropper settings can result in it becoming unusable.';
		return modalService
			.getConfirmation(title, message)
			.then(function(confirmed){
				if(!confirmed) return;
				return updateSettings($scope.activeItem.settings);		
		});
		
	};

	function init(){
		if(!$scope.activeItem.monitor.serialNumber) return;
		$scope.activeItem.settingsAlerts = new AlertsService();
		var promise =  getCurrentSettings();		
		$scope.activeItem.settingsMonitor = new PromiseMonitor(promise);
	}

	init();
}