angular.module('angus.directives')
    .directive("repeatFinishCallback", [
        '$parse',
        function($parse) {
            return function(scope, element, attrs) {
                var fn = $parse(attrs.repeatFinishCallback);

                if (scope.$last === true) {
                    scope.$evalAsync(fn(scope, {}));
                }
            };
        }
    ]);