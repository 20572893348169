angular.module( 'angus.directives' ).directive( 'hddTracker', [
	'actionViewTemplates',
	function( actionViewTemplates ) {

		'use strict';

		return {
			scope: {
				model: '=actionModel',
				settings: '=hddTrackerWidgetSettings',
				widgetCode : '='
			},
			templateUrl: actionViewTemplates.hddTrackerReportContainer
		};
	}
] );