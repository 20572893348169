angular.module('angus.controllers')
    .controller('etlStatusPopoverController', [
        '$scope', 'promiseMonitor', 'etlStatusService',
        function($scope, PromiseMonitor, etlStatusService) {
            'use strict';

            $scope.statuses = [];

            var promise = etlStatusService
                            .get()
                            .then(function(statuses) {
                              $scope.statuses = statuses;
                              console.log(statuses);
                            });

            $scope.etlStatusMonitor = new PromiseMonitor(promise);
        }
    ]);

