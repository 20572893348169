angular.module('angus.controllers').controller('acctGainsLossesBySrcCtrl', [
    '$scope', '$rootScope', '$q','$http', 'modalService','lastGainLossDateService', 'datePickerService', 'constantsService', 'moment', 'hierarchyService', 'dateCalculator', '_', 'fiscalYearService',
    function($scope, $rootScope, $q, $http, modalService, lastGainLossDateService, datePickerService, constantsService, moment, hierarchyService, dateCalculator, _, fiscalYearService) {
        'use strict';

        var divisionIds = null;
        var isLosses    = $scope.widget.instance.viewCode == constantsService.widgetCode.acctLossesBySrc;
        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        divisionIds = hierarchyService
            .getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes,
                $scope.widget.instance.settings.hierarchyNodeId);

        $scope.periodIndicators = $scope.widget.code == 'acctLossByRsn' ? constantsService.terminationIndicators.enums : constantsService.acquisitionIndicators.enums;

        $scope.periodIndicator = _.find($scope.periodIndicators, function(period) {
            return period.key == $scope.widget.instance.settings.periodIndicatorId.value;
        }).value.display;


        function loadWidget() {
            $scope.widget.noContentFlag = false;

            var promise = $q
                .when( $scope.widget.code == 'acctLossByRsn' ? lastGainLossDateService.getLastCustomerTerminationDate(divisionIds) : lastGainLossDateService.getLastCustomerAcquisitionDate(divisionIds))
                .then(function(date){
                    if(!$scope.dates){
                        $scope.dates    = {};

                        var dates = dateCalculator.calculatePeriod($scope.dates.periodStartDate, date || moment.utc(), $scope.widget.instance.settings.periodIndicatorId.value, true);

                        if($scope.widget.instance.settings.periodIndicatorId.value === 'fiscalYearToDate')
                            fiscalYearService
                                .getFiscalYearStartMonth(subscriberId)
                                .then(function(fiscalMonth) {
                                    if(moment().month() >= fiscalMonth)
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month');
                                    else
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month').subtract(1, 'year');

                                    $scope.widget.defaultInitialization();
                                });

                        $scope.dates.periodStartDate = !$scope.widget.instance.settings.customDate ? dates.startDate : $scope.widget.instance.settings.customDate.startDate;
                        $scope.dates.lastDeliveryDate = !$scope.widget.instance.settings.customDate ? dates.endDate : $scope.widget.instance.settings.customDate.endDate;

                        $scope.dateTooltipText = getDateTooltipText();
                    }

                    return queryAccountLosses();
                })
                .then(function(response) {
                    $scope.total  = _.reduce(response.data, function(result, item){
                        result += item.accounts;
                        return result;
                    }, 0);

                    if (response.data === 0 || response.data === 0) {
                        $scope.widget.noContentFlag = true;
                        $scope.widget.instance.settings.customDate = null;
                        return null;
                    }
                    var graphData = _.map(_.slice(response.data, 0, 5),
                        function(item) { 
                            return {
                                label: item.reason,
                                data: [
                                    [1, item.accounts]
                                ],
                                accts: item.accounts
                            };
                        });
 
                    if(response.data.length > 5){ 
                        var remaningData = response.data.splice(5, response.data.length - 5); 
                        var remaining = _.reduce(remaningData, function(result, item) {
                            result += item.accounts; 
                            return result;
                        }, 0);
 
                        graphData.push({
                            label: 'OTHER',
                            data: [
                                [1, remaining]
                            ],
                            accts: 0
                        });
                    }

                    $scope.summary = graphData;

                    $scope.widget.setDefaultActionModel({
                        dates    : $scope.dates,
                        isLosses : isLosses
                    });
                });

            return $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(loadWidget);

        function queryAccountLosses() {
            var data= {
                divisionIds       : divisionIds,
                startDate         : $scope.dates.periodStartDate ? $scope.dates.periodStartDate.format() : null,
                endDate           : $scope.dates.lastDeliveryDate.format(),
                acquisitionTermination : $scope.acquisitionTermination
            };

            if(isLosses) data.groupByReason = 1;
            else 		 data.groupBySource = 1;

            return $http({
                url: isLosses ?
                    ('api/subscribers/{0}/customerAccounts/losses').format($rootScope.user.subscriberId) : ('api/subscribers/{0}/customerAccounts/gains').format($rootScope.user.subscriberId),
                method: 'POST',
                data: data
            });
        }

        $scope.$on('widgetSettingsChanged', function() {
            $scope.periodIndicator = _.find($scope.periodIndicators, function(period) {
                return period.key == $scope.widget.instance.settings.periodIndicatorId.value;
            }).value.display;

            delete $scope.dates;

            divisionIds = hierarchyService
                .getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);

            loadWidget();
        });

        $scope.openDatePicker = function() {
            return datePickerService.openDatePickerModal(
                $scope.dates.periodStartDate,
                $scope.dates.lastDeliveryDate,
                moment())
                .then(function(resultDates) {
                    if (resultDates) {
                        $scope.dates.periodStartDate  = moment(resultDates[0]).isValid() ?  resultDates[0] : resultDates[1];
                        $scope.dates.lastDeliveryDate = resultDates[1];

                        $scope.periodIndicator = constantsService.marginPeriodIndicators.customDateRange.value.display;

                        $scope.dateTooltipText = getDateTooltipText();

                        loadWidget();
                    }
                });
        };

        function getDateTooltipText() {
            var start =  $scope.dates.periodStartDate ? $scope.dates.periodStartDate.format('L') : '';
            var end =  $scope.dates.lastDeliveryDate ? $scope.dates.lastDeliveryDate.format('L') : '';
            return start + (!!start && !!end ? ' - ' : '') + end;
        }
    }
]);