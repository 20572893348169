angular
    .module('angus.directives')
    .directive('weatherIcon', ['constantsService', function(constants) {
        return {
            restrict: 'E',
            scope: {
                type: '=',
                width: '=',
                height: '=',
                alt: '='
            },
            template: '<img ng-src="/img/weather/{{getWeatherIcon(type)}}.png" alt="{{alt}}" height="{{height}}" width="{{ width || \'100%\'}}" >',
            link: function(scope, elem, attrs) {     
                //weatherkit            
                // "Clear", "Cloudy", "Dust", "Fog", "Haze", "MostlyClear", "MostlyCloudy",
                // "PartlyCloudy", "ScatteredThunderstorms", "Smoke", "Breezy", "Windy",
                // "Drizzle", "HeavyRain", "Rain", "Showers", "Flurries", "HeavySnow",
                // "MixedRainAndSleet", "MixedRainAndSnow", "MixedRainfall", "MixedSnowAndSleet",
                // "ScatteredShowers", "ScatteredSnowShowers", "Sleet", "Snow", "SnowShowers",
                // "Blizzard", "BlowingSnow", "FreezingDrizzle", "FreezingRain", "Frigid",
                // "Hail", "Hot", "Hurricane", "IsolatedThunderstorms", "SevereThunderstorm",
                // "Thunderstorm", "Tornado", "TropicalStorm"
                scope.getWeatherIcon = function(type) { 
                    switch (type) {
                        case 'Hot':
                        case 'Clear':
                        case 'MostlyClear':
                            return 'weather-clear';  
                        case 'Thunderstorms':
                            return 'weather-storm-day';  
                        case 'Drizzle':
                        case 'Rain':
                        case 'ScatteredShowers':
                        case 'Showers':
                            return 'weather-rain-day';  
                        case 'HeavyRain':
                            return 'weather-showers-day';  
                        case 'Snow':
                        case 'BlowingSnow':
                        case 'Flurries':
                        case 'HeavySnow':
                            return 'weather-snow';  
                        case 'SnowShowers':
                        case 'Sleet':
                        case 'MixedRainAndSleet':
                        case 'ScatteredSnowShowers':
                            return 'weather-snow-rain';  
                        case 'Breezy':
                        case 'Windy':
                            return 'weather-wind';  
                        case 'Dust':
                        case 'Fog': 
                        case 'Smoke':
                            return 'weather-fog';  
                        case 'Haze':
                            return 'weather-haze';  
                        case 'Cloudy':
                            return 'weather-clouds';  
                        case 'PartlyCloudy':
                            return 'weather-few-clouds';  
                        case 'MostlyCloudy':
                            return 'weather-clouds';  
                        case 'Hail':
                            return 'weather-hail';  
                        case 'ScatteredThunderstorms':
                        case 'Hurricane':
                        case 'IsolatedThunderstorms':
                        case 'SevereThunderstorm':
                        case 'Tornado':
                        case 'TropicalStorm':
                            return 'weather-storm';  
                        default:
                            return 'weather-none-available'; 
                    }  
                }
            }
        }
    }]);
