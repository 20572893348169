angular.module( 'angus.directives' ).directive( 'anThumbGraph', [
	'$',
	function( $ ) {
		return {
			restrict: 'A',
			scope: {
				anThumbGraph: '=',
				className: '=',
				reinit: '='
			},
			link: function( scope, elem, attrs, ctrl ) {

				scope.$watch( function() {
					return scope.anThumbGraph;
				}, function( newval ) {
					if ( newval && newval.length > 0 ) {
						plotGraph();
					}
				}, true );
				function plotGraph() {
					scope.options = {
						series: {
							bars: {
								show: true,
								fill: 0.6
							},
							points: {
								show: false
							},
						},
						grid: {
							hoverable: true,
							borderWidth: 0
						},
						xaxis: {
							show: false
						},
						yaxis: {
							show: false
						},
						tooltip: true,
						tooltipOpts: {
							content: function( serieslabel, x, y, item ) {
								y = Number(y).toFixed( 2 );
								x = scope.monthValues[ x ][ 1 ].split( '-' );
								return y + ' for ' + scope.months[ x[ 1 ] - 1 ] + ', ' + x[ 0 ];
							},
                            defaultTheme : false
						}

					};

					var result = [];
					scope.monthValues = [];
					for ( var i = 0; i < scope.anThumbGraph.length; i++ ) {
						result.push( [ i, scope.anThumbGraph[ i ][ 1 ] ] );
						scope.monthValues.push( [ i, scope.anThumbGraph[ i ][ 0 ] ] );
					}

					$.plot( elem, [ {
						data: result,
						color: '#428bca'
					} ], scope.options );
				}


				scope.months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
			}
		};
	}
] );
