angular
  .module('angus.services')
  .service('restrictedAccessService', ['$http', function ($http) {
    'use strict';

    return {
      getAccess: function () {
        return $http
          .get('/access')
          .then(function (res) {
            return res.data;
          });
      }
    }
  }]);
