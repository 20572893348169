function tankExclusionInquiryExpandableInputController($element, $timeout) {
    var $ctrl = this;

    $ctrl.$onInit = function () {
        $ctrl.isExpanded = false;
        $ctrl.isOverflowed = false;

        $timeout($ctrl.checkOverflow, 0);
    };
        

    $ctrl.$onChanges = function (changes) {
        if (changes.data) {
            $timeout($ctrl.checkOverflow, 0);
        }
    }

    $ctrl.toggleExpanded = function () {
        $ctrl.isExpanded = !$ctrl.isExpanded;
    }

    $ctrl.checkOverflow = function () {
        var inputElement = $element.find('input')[0];

        if (inputElement) {
            $ctrl.isOverflowed = inputElement.scrollWidth > inputElement.clientWidth;
        }

        return $ctrl.isOverflowed;
    }
}

angular
    .module('angus.components')
    .component('tankExclusionInquiryExpandableInput', {
        templateUrl: 'templates/adept/inquiry/tankExclusionInquiry/tankExclusionInquiryExpandableInput.component.html',
        controller: tankExclusionInquiryExpandableInputController,
        bindings: {
            data: '<'
        }
    });