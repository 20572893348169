angular.module('angus.controllers').controller('tmsWeeklyQueriesCtrl', [
    '$scope', '$rootScope', '$http', 'fluentRest', 'gridService2', 'csvService', '_', '$q', '$mdToast', 'nodeType', 'gridState',
    function($scope, $rootScope, $http, fluentRest, gridService2, csvService, _, $q, $mdToast, nodeType, gridState) {

        'use strict';
        $scope.sourceId = 1;
        $scope.monitorSourceId = 1;
        $scope.showFilter = true; 
        $scope.filter = '';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.monitorSources = [
            { text: "Gremlin Oil (750)", value: 1 },
            { text: "3G Cellular Propane (643)", value: 2 },
            { text: "TEK687 - Oil", value: 3 },
            { text: "TEK733 - Oil", value: 4 },
            { text: "4G Cellular Propane (822)", value: 5 },
            { text: "4G Cellular Heating Oil (811)", value: 6 },
            { text: "4G Cellular Non-Heating Liquid (871)", value: 7 }
        ]; 

        $scope.sources = [
            { text: "Summary", value: 1 },
            { text: "Detail", value: 2 }
        ]; 
        
        $scope.submit = function() { 
            $scope.isLoading = true;  
            downloadAsCSV();
        }  

        function getSourceName() {
            var name = _.find($scope.sources, function(source){
                return $scope.sourceId == source.value
            });
            return name.text;
        }

        function getMonitorTypeName() {
            var name = _.find($scope.monitorSources, function(monitorSource){
                return $scope.monitorSourceId == monitorSource.value
            });
            return name.text;
        }

        function downloadAsCSV() { 
            var params = { 
                tankMonitorType: $scope.monitorSourceId
            };
            var monitorTypeName = getMonitorTypeName();
            var sourceName = getSourceName();
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear(); 
            var currentDate = yyyy + mm + dd; 
            var fileName = currentDate + ' Monitor Count ' + sourceName + ' For ' + monitorTypeName;
 
            if ($scope.sourceId === 1) { 
                var url = ('api/subscribers/{0}/containers/monitors/report/summaryReport').format($scope.subscriberId)  
                csvService.postGetCsv(url, params, fileName)
                .then(function(rows) { 
                    $scope.isLoading = false;  
                });  
            }
            else {  
                var url = ('api/subscribers/{0}/containers/monitors/report/detailReport').format($scope.subscriberId)  
                csvService.postGetCsv(url, params, fileName)
                .then(function(rows) { 
                    $scope.isLoading = false;  
                }); 
            }
        } 

    }
]);
