angular.module('angus.services').factory('monitorsService', [
    '$rootScope', '$http', function ($rootScope, $http) {

        const getMonitors = function(params) {
            return $http.get(('api/tankMonitors/monitors').format(params.id));
        };

        const postMonitors = function(data) {
            return $http.post('api/tankMonitors/monitors', data);
        };

        const searchMonitors = function(params) {
            return $http.post('api/tankMonitors/monitors/searchMonitors', params);
        }; 

        return {
            getMonitors,
            postMonitors,
            searchMonitors
        };
    }
]);

