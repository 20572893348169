angular.module('angus.controllers').controller('adeptPerformanceAnalysisReportController', [
    '$q', '$rootScope', '$scope', 'adeptPerformanceAnalysisService', 'adeptService', 'constantsService', 'containerService', 'deliveryTypeService', 'hierarchyService', 'gridService2', 'gridState', 'productsService',
    function($q, $rootScope, $scope, adeptPerformanceAnalysisService, adeptService, constantsService, containerService, deliveryTypeService, hierarchyService, gridService2, gridState, productsService) {
        'use strict';

        $scope.subscriberId = $rootScope.user.subscriberId;
        const subscriberKey = $rootScope.user.subscriberAbosKey;

        $scope.filters = {
            useAdeptGroup: $scope.settings.useAdeptGroup.value,
            adeptGroupIds: $scope.settings.adeptGroupIds.value,
            containerTypeId: $scope.settings.containerTypeId.value,
            hierarchyNodeId: $scope.settings.hierarchyNodeId,
            productCategoryIds: $scope.settings.productCategoryIds.value,
            deliveryTypeIds: $scope.settings.deliveryTypeIds.value,
            includeMonitoredTanks: $scope.settings.includeMonitoredTanks.value,
            includeGroupOptimizedTransactions: $scope.settings.includeGroupOptimizedTransactions.value,

            month: $scope.settings.month,
            year: $scope.settings.year
        }

        $scope.persistedFilters = {
            adeptGroupIds: [],
            hierarchyNodeId: [],
            productCategoryIds: [],
            deliveryTypeIds: [],
            containerTypeId: $scope.filters.containerTypeId
        }

        $scope.useAdeptGroupChanged = useAdeptGroupChanged;
        $scope.containerTypeChanged = () => $scope.persistedFilters.containerTypeId = $scope.filters.containerTypeId;

        $scope.adeptGroupChanged = () => loadContainerTypes();
        $scope.productCategoriesChanged = () => loadContainerTypes();
        $scope.deliveryTypesChanged = () => loadContainerTypes();
        $scope.hierarchyNodeChanged = () => loadContainerTypes(); /* TODO: Why is HierarchyNodeId not changed on initial selection? So we are calling it while the existing value is still in scope */

        $scope.runReport = runReport;

        var totalDeliveryCount = 0;
        
       

        /* Initialize Month and Year data */
        $scope.months = constantsService.months.enums;
        $scope.years = adeptPerformanceAnalysisService.getYears(5);

        function useAdeptGroupChanged() {
            if ($scope.filters.useAdeptGroup) {
                /* Store the values of the disabled controls in case they toggle the Use ADEPT Group option again before leaving the screen */
                $scope.persistedFilters.hierarchyNodeId = $scope.filters.hierarchyNodeId;
                $scope.persistedFilters.productCategoryIds = $scope.filters.productCategoryIds;
                $scope.persistedFilters.deliveryTypeIds = $scope.filters.deliveryTypeIds;

                /* Clear out values for disabled controls */
                $scope.filters.hierarchyNodeId = [];
                $scope.filters.productCategoryIds = [];
                $scope.filters.deliveryTypeIds = [];

                /* If we had a Persisted ADEPT Group then use it. */
                $scope.filters.adeptGroupIds = $scope.persistedFilters.adeptGroupIds;
            }
            else {
                 /* Store the values of the disabled controls in case they toggle the Use ADEPT Group option again before leaving the screen */
                 $scope.persistedFilters.adeptGroupIds = $scope.filters.adeptGroupIds;
                 $scope.filters.adeptGroupIds = [];

                 /* If we had a Persisted settings then use them. */
                 $scope.filters.hierarchyNodeId = $scope.persistedFilters.hierarchyNodeId;
                 $scope.filters.productCategoryIds = $scope.persistedFilters.productCategoryIds;
                 $scope.filters.deliveryTypeIds = $scope.persistedFilters.deliveryTypeIds;
            }

            loadContainerTypes();
        }


        function loadContainerTypes() {
            $scope.containerTypes = [];
            $scope.filters.containerTypeId = null;
            
            const useAdeptGroup = $scope.filters.useAdeptGroup;
            const subscriberId = $scope.subscriberId;

            let promise; 

            if (useAdeptGroup) {
                const adeptGroupIds = $scope.filters.adeptGroupIds;

                promise = containerService.getContainerTypes(subscriberId, undefined, undefined, undefined, undefined, adeptGroupIds);
            }
            else {
                const divisionIds = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.filters.hierarchyNodeId);
                const productCategoryIds = $scope.filters.productCategoryIds;
                const deliveryTypeIds = $scope.filters.deliveryTypeIds;

                promise = containerService.getContainerTypes(subscriberId, divisionIds, productCategoryIds, undefined, deliveryTypeIds, undefined);
            }

            $scope.areContainerTypesLoading = true;

            return promise.then(function(containerTypes) {
                $scope.containerTypes = containerTypes;

                const persistedContainerTypeId = $scope.persistedFilters.containerTypeId;

                if (persistedContainerTypeId) {
                    const containerTypeMatch = containerTypes.find(containerType => containerType.id == persistedContainerTypeId);

                    if (containerTypeMatch) {
                        $scope.filters.containerTypeId = containerTypeMatch.id.toString();
                    }
                }

                $scope.areContainerTypesLoading = false;
            });
        }


        $q.all([
            adeptService.getAdeptGroupsLegacy($scope.subscriberId),
            deliveryTypeService.getDeliveryTypes($scope.subscriberId),
            productsService.getProducts($scope.subscriberId),
            loadContainerTypes()
        ])
            .spread(function(adeptGroups, deliveryTypes, productCategories, containerTypeRes){
                $scope.adeptGroups = adeptGroups;
                $scope.deliveryTypes = deliveryTypes;
                $scope.productCategories = productCategories;

                var gridParams = {
                    gridState: gridState($scope.subscriberId, $scope.widgetCode),
                    defs: getColDefs(),
                    clicks: {},
                    exportOptions: {fileName: 'ADEPT Performance Analysis'},
                    gridOptions: {
                        groupAggFunction: function(rows) {

                            /* Build Sums */
                            const result = rows.reduce((accumulator, currentRow) => {
                                const data = currentRow.data;

                                accumulator.unitsPriorToDynamicReserve += data.unitsPriorToDynamicReserve;
                                accumulator.units += data.units;
                                accumulator.nonDynamicReserveUnits += data.nonDynamicReserveUnits;
                                accumulator.dynamicReserveUnits += data.dynamicReserveUnits;
                                accumulator.dynamicReserveOnTimeUnits += data.dynamicReserveOnTimeUnits;
                                accumulator.dynamicReserveOnTimeMonitoredUnits += data.dynamicReserveOnTimeMonitoredUnits;

                                accumulator.deliveryCountPriorToDynamicReserve += data.deliveryCountPriorToDynamicReserve;
                                accumulator.deliveryCount += data.deliveryCount;
                                accumulator.nonDynamicReserveDeliveryCount += data.nonDynamicReserveDeliveryCount;
                                accumulator.dynamicReserveDeliveryCount += data.dynamicReserveDeliveryCount;
                                accumulator.dynamicReserveOnTimeDeliveryCount += data.dynamicReserveOnTimeDeliveryCount;
                                accumulator.dynamicReserveOnTimeMonitoredDeliveryCount += data.dynamicReserveOnTimeMonitoredDeliveryCount;
                                
                                accumulator.percentOfDeliveries += data.percentOfDeliveries;

                                return accumulator;
                            }, 
                            { /* Defaults */
                                unitsPriorToDynamicReserve: 0,
                                units: 0,
                                nonDynamicReserveUnits: 0,
                                dynamicReserveUnits: 0,
                                dynamicReserveOnTimeUnits: 0,
                                dynamicReserveOnTimeMonitoredUnits: 0,

                                deliveryCountPriorToDynamicReserve: 0,
                                deliveryCount: 0,
                                nonDynamicReserveDeliveryCount: 0,
                                dynamicReserveDeliveryCount: 0,
                                dynamicReserveOnTimeDeliveryCount: 0,
                                dynamicReserveOnTimeMonitoredDeliveryCount: 0,
                                
                                percentOfDeliveries: 0
                            });

                            /* Average */
                            result.unitsPerDeliveryPriorToDynamicReserve = result.deliveryCountPriorToDynamicReserve == 0 ? 0 : result.unitsPriorToDynamicReserve / result.deliveryCountPriorToDynamicReserve || 0;
                            result.unitsPerDelivery = result.deliveryCount == 0 ? 0 : result.units / result.deliveryCount || 0;
                            result.percentAdeptOnTime = result.dynamicReserveDeliveryCount == 0 ? 0 : (result.dynamicReserveOnTimeDeliveryCount / result.dynamicReserveDeliveryCount) * 100 || 0;
                            result.nonDynamicReserveUnitsPerDelivery = result.nonDynamicReserveDeliveryCount == 0 ? 0 : result.nonDynamicReserveUnits / result.nonDynamicReserveDeliveryCount || 0;
                            result.dynamicReserveUnitsPerDelivery = result.dynamicReserveDeliveryCount == 0 ? 0 : result.dynamicReserveUnits / result.dynamicReserveDeliveryCount || 0;
                            result.dynamicReserveOnTimeUnitsPerDelivery =result.dynamicReserveOnTimeDeliveryCount == 0 ? 0 :  result.dynamicReserveOnTimeUnits / result.dynamicReserveOnTimeDeliveryCount || 0;
                            result.dynamicReserveOnTimeMonitoredUnitsPerDelivery = result.dynamicReserveOnTimeMonitoredDeliveryCount == 0 ? 0 : result.dynamicReserveOnTimeMonitoredUnits / result.dynamicReserveOnTimeMonitoredDeliveryCount || 0;
                                
                            return result;
                        }
                    }
                };

                return gridService2.createGrid(gridParams)
            })
            .then(function(grid) {
                $scope.grid = grid;

                return runReport();
            })
            .catch(function(error){
                console.log(error);
            });

        function getColDefs() {
            let nextIndex = 0;
            const colDefs = [];

            colDefs.push(gridService2.colDef.createNumber('capacity', 'Tank Size', 'capacity', {rowGroupIndex: nextIndex + 1, hide: true}));

            colDefs.push(gridService2.colDef.createYearMonth('monthDate', 'Year-Month', 'monthDate', {hide: false}, {}));
            colDefs.push(gridService2.colDef.createMonth('monthDateMonth', 'Month', 'monthDateMonth', {hide: true}, {}));
            colDefs.push(gridService2.colDef.createYear('monthDateYear', 'Year', 'monthDateYear', {hide: true}));

            colDefs.push(gridService2.colDef.createNumber('unitsPerDeliveryPriorToDynamicReserve', 'Historical Average', 'unitsPerDeliveryPriorToDynamicReserve', {}, {decimalPlaces: 1}));
            colDefs.push(gridService2.colDef.createNumber('unitsPriorToDynamicReserve', 'Historical Units', 'unitsPriorToDynamicReserve', {aggFunc: 'sum', hide: true}, {decimalPlaces: 0}));
            colDefs.push(gridService2.colDef.createNumber('deliveryCountPriorToDynamicReserve', 'Historical Delivery Count', 'deliveryCountPriorToDynamicReserve', {aggFunc: 'sum', hide: true}, {decimalPlaces: 0}));

            colDefs.push(gridService2.colDef.createNumber('deliveryCount', 'Total Deliveries', 'deliveryCount', {hide: true}, {decimalPlaces: 0}));
            colDefs.push(gridService2.colDef.createNumber('unitsPerDelivery', 'Total Units Per Delivery', 'unitsPerDelivery', {}, {decimalPlaces: 1}));
            colDefs.push(gridService2.colDef.createNumber('units', 'Total Units', 'units', {hide: true}, {decimalPlaces: 0}));

            colDefs.push(gridService2.colDef.createPercentage('percentOfDeliveries', 'Percentage of Total Deliveries', 'percentOfDeliveries', {hide: true}, {decimalPlaces: 1}));

            colDefs.push(gridService2.colDef.createPercentage('percentAdeptOnTime', 'Percentage of ADEPT On-Time', 'percentAdeptOnTime',{hide: true}, {decimalPlaces: 1}));
            
            colDefs.push(gridService2.colDef.createNumber('nonDynamicReserveUnitsPerDelivery', 'Non-ADEPT', 'nonDynamicReserveUnitsPerDelivery', {}, {decimalPlaces: 1}));
            colDefs.push(gridService2.colDef.createNumber('nonDynamicReserveDeliveryCount', 'Non-ADEPT Deliveries', 'nonDynamicReserveDeliveryCount', {aggFunc: 'sum', hide: true}, {decimalPlaces: 0}));
            colDefs.push(gridService2.colDef.createNumber('nonDynamicReserveUnits', 'Non-ADEPT Units', 'nonDynamicReserveUnits', {hide: true}, {decimalPlaces: 0}));

            colDefs.push(gridService2.colDef.createNumber('dynamicReserveUnitsPerDelivery', 'ADEPT', 'dynamicReserveUnitsPerDelivery', {}, {decimalPlaces: 1}));
            colDefs.push(gridService2.colDef.createNumber('dynamicReserveDeliveryCount', 'ADEPT Deliveries', 'dynamicReserveDeliveryCount', {hide: true}, {decimalPlaces: 0}));
            colDefs.push(gridService2.colDef.createNumber('dynamicReserveUnits', 'ADEPT Units', 'dynamicReserveUnits', {hide: true}, {decimalPlaces: 0}));

            colDefs.push(gridService2.colDef.createNumber('dynamicReserveOnTimeUnitsPerDelivery', 'ADEPT On-Time', 'dynamicReserveOnTimeUnitsPerDelivery', {}, {decimalPlaces: 1}));
            colDefs.push(gridService2.colDef.createNumber('dynamicReserveOnTimeDeliveryCount', 'ADEPT On-Time Deliveries', 'dynamicReserveOnTimeDeliveryCount', {hide: true}, {decimalPlaces: 0}));
            colDefs.push(gridService2.colDef.createNumber('dynamicReserveOnTimeUnits', 'ADEPT On-Time Units', 'dynamicReserveOnTimeUnits', {hide: true}, {decimalPlaces: 0}));

            colDefs.push(gridService2.colDef.createNumber('dynamicReserveOnTimeMonitoredUnitsPerDelivery', 'Adept Monitored On-Time', 'dynamicReserveOnTimeMonitoredUnitsPerDelivery', {}, {decimalPlaces: 1}));
            colDefs.push(gridService2.colDef.createNumber('dynamicReserveOnTimeMonitoredDeliveryCount', 'Adept Monitored Deliveries', 'dynamicReserveOnTimeMonitoredDeliveryCount', {hide: true}, {decimalPlaces: 0}));
            colDefs.push(gridService2.colDef.createNumber('dynamicReserveOnTimeMonitoredUnits', 'Adept Monitored Units', 'dynamicReserveOnTimeMonitoredUnits', {hide: true}, {decimalPlaces: 0}));            

            return colDefs;
        }

        function runReport() {
            const includeExcludeOnlyOptions = constantsService.includeExcludeOnlyOptions;
            
            const filters = $scope.filters;

            const subscriberId = $scope.subscriberId;

            const month = filters.month;
            const year = filters.year;
            const containerTypeId = filters.containerTypeId;
            const includeMonitoredTanks = includeExcludeOnlyOptions[filters.includeMonitoredTanks] || includeExcludeOnlyOptions.include;
            const includeGroupOptimizedTransactions = includeExcludeOnlyOptions[filters.includeGroupOptimizedTransactions] || includeExcludeOnlyOptions.include;


            let adeptGroupIds = [];
            let divisionIdsParam = [];
            let productCategoryIds = [];
            let deliveryTypeIds = [];

            if (filters.useAdeptGroup) {
                adeptGroupIds = filters.adeptGroupIds;
            }
            else {
                const divisionIds = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, filters.hierarchyNodeId);
                divisionIdsParam = divisionIds;
                productCategoryIds = filters.productCategoryIds;
                deliveryTypeIds = filters.deliveryTypeIds;
            }
            
            var dataPromise = adeptPerformanceAnalysisService.getDetail(subscriberKey, month, year, adeptGroupIds, divisionIdsParam, productCategoryIds, deliveryTypeIds, containerTypeId, includeMonitoredTanks, includeGroupOptimizedTransactions)
                .then(function(data){
                    /* Get the total Delivery Count */
                    totalDeliveryCount = data.reduce((accumulator, row) => accumulator += row.deliveryCount, 0);

                    data.forEach(function(row) {
                        row.percentAdeptOnTime = row.dynamicReserveDeliveryCount == 0 ? 0 : (row.dynamicReserveOnTimeDeliveryCount / row.dynamicReserveDeliveryCount) * 100 || 0;
                        row.percentOfDeliveries = totalDeliveryCount == 0 ? 0 : (row.deliveryCount / totalDeliveryCount) * 100 || 0;

                        const dateParts = row.deliveryMonthYear.split('/'); /* 08/2022 => ['08', '2022'] */
                        const monthDate = new Date(dateParts[1], dateParts[0] - 1); /* Month String is 1 based, but Date month is 0 based */

                        /* There seems to be a bug with the Grid where the Render won't work if the ColId is different from the FieldId. Duplicating the Fields so I can keep the names the same as the ColId */
                        row.monthDate = monthDate;
                        row.monthDateYear =  monthDate;    
                        row.monthDateMonth =  monthDate;                      
                    });

                    return data;
                });

            var promise = $scope.grid.setRows(dataPromise);

            return promise;
        }
    }
]);