'use strict';

angular.module('angus.controllers').controller('liquidFuelsHistoryReportContentCtrl', [
      '$scope', '$q', '$rootScope', '$document', '$http', 'drillService', 'gridService2', 'gridState', '_',
    function($scope, $q, $rootScope, $document, $http, drillService, gridService2, gridState, _) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.drillService = drillService;

        $scope.$watch(function() {
            return $scope.activeItem;
        }, function(newVal) {
            if (!newVal.grid)
                initLiquidFuelsHistory();
        });


        function getColDefs(){
            return [                
                gridService2.colDef.createDate('date','Date','date'),
                gridService2.colDef.createText('pricePlanName','Price Plan','pricePlanName'),                
                gridService2.colDef.createNumber('units','Units Delivered','units', {}, {decimalPlaces: 2, aggFunc : 'sum' }),
                gridService2.colDef.createText('invoiceID','Invoice Number','invoiceID'),
                gridService2.colDef.createText('sourceProductName','Invoice Item Name','sourceProductName')
            ];
            
        } 

        function initLiquidFuelsHistory() {
             var gridParams = {
                gridState     : gridState(subscriberId, 'liquidFuelsHistory'),
                defs          : getColDefs(),
                exportOptions : { fileName: 'Liquid Fuels History' }
            };

            return gridService2
                .createGrid(gridParams)
                .then(function(grid){
                    $scope.activeItem.grid = grid;
                    queryMarginTransactionHistoryReport();
                });
        }

        function queryMarginTransactionHistoryReport() {            
            var params = {
                containerKey  : $scope.activeItem.params.containerKey                
            };

            var rowPromise = $http({
                    url     : 'api/subscribers/{0}/margins/transactions/history'.format($rootScope.user.subscriberId), 
                    method  : 'POST',
                    data    : params
                })            
                .then(function(result){
                    console.log(result);
                    return result.data;
                });

            var promise = $scope.activeItem.grid.setRows(rowPromise);
            return promise;
        }
    }
]);