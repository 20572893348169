function customerTypeAheadController(constantsService, customerService) {
    var $ctrl = this;

    $ctrl.$onInit = function () {

        $ctrl.typeAheadOptions = {
            debounce: $ctrl.debounce || 350
        };
        
        if ($ctrl.subscriberId && $ctrl.customerKey) {
            handleCustomerKeyChanged($ctrl.customerKey);
        }
    }

    $ctrl.$onChanges = function (changes) {
        if (changes.customerKey) {
            handleCustomerKeyChanged(changes.customerKey.currentValue);
        }
        else if ($ctrl.customer) {
            handleCustomerKeyChanged(null);
        }
    }

    $ctrl.getCustomers = getCustomers;
    $ctrl.handleInputChanged = handleInputChanged;
    $ctrl.handleCustomerSelected = handleCustomerSelected; 


    function clearNoResult(){
        $ctrl.noResultsCustomers = false;
    }

    function getCustomers (customerId) {
        $ctrl.customers = null;
        $ctrl.customerKey = null;

        /* Don't try searching unless we have at least 1 character to search with */
        if (!customerId || customerId.length === 0) {
            return Promise.resolve();
        }
        
        const includeDeleted = 1;
        const includeInactive = 1;
        const includeUnknown = 0;
        const divisionKeys = null;
        const stringComparisonTypeKey = constantsService.StringComparisonTypes.start;
        const selectionCount = 10;

        return customerService.getForCustomerId($ctrl.subscriberId, includeDeleted, includeInactive, includeUnknown, customerId,
            divisionKeys, stringComparisonTypeKey, selectionCount)
            .then(function (customers) {

                const customersResult = customers.map(function (customer) {
                    return {
                        customerKey: customer.customerKey,
                        customerId: customer.customerId,
                        customerName: customer.customerName,
                        description: `${customer.customerId} - ${customer.customerName}`
                    };
                });

                return customersResult;
            });
    }

    function handleCustomerKeyChanged(customerKey) {
        
        $ctrl.customer = null;
        $ctrl.customerId = null;
        
        if (customerKey) {
            return customerService.getForCustomerKey($ctrl.subscriberId, customerKey)
                .then(function(customer) {
                    $ctrl.customerId = customer.customerId;
                    handleCustomerSelected(customer)
                });
        }
        else {
            handleCustomerRemoved(null);
        }
    }

    function handleInputChanged() {
        clearNoResult();

        $ctrl.customer = null;
        $ctrl.customerKey = null;

        if ($ctrl.onInputChanged) {
            
            $ctrl.onInputChanged();
        }
    }

    function handleCustomerSelected(item) {
        $ctrl.customer = item;
        $ctrl.customerKey = item.customerKey;
            
        if ($ctrl.onSelect){
            $ctrl.onSelect({newCustomer: item});
        }        
    }

    function handleCustomerRemoved() {
        $ctrl.customer = null;
        $ctrl.customerKey = null;

        if ($ctrl.onSelect){
            $ctrl.onSelect(null);
        }
    }
}

angular
    .module('angus.components')
    .component('customerTypeAhead', {
        templateUrl: 'templates/global/components/customerTypeAhead/customerTypeAhead.component.html',
        controller: customerTypeAheadController,
        bindings: {
            customerKey: '<',
            debounce: '<',
            disabled: "<ngDisabled",
            subscriberId: '<',
            onSelect: '&',
            onInputChanged: '&'
        }
    });