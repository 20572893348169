angular.module('angus.controllers').controller('marginHistoryReportContentCtrl', [
      '$scope', '$q', '$rootScope', '$document', '$http', 'drillService', 'gridService2', 'gridState', '_',
    function($scope, $q, $rootScope, $document, $http, drillService, gridService2, gridState, _) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.drillService = drillService;

        $scope.$watch(function() {
            return $scope.activeItem;
        }, function(newVal) {
            if (!newVal.grid)
                initMarginHistory();
        });


        function getColDefs(){
            return [                
                gridService2.colDef.createDate('date','Date','date'),
                gridService2.colDef.createText('pricePlanName','Price Plan','pricePlanName'),                
                gridService2.colDef.createNumber('units','Units Delivered','units', {}, {decimalPlaces: 2 }),
                gridService2.colDef.createCurrency('unitPrice','Unit Price','unitPrice',{},  { decimalPlaces: 4 }),      
                gridService2.colDef.createCurrency('unitCost','Unit Cost','unitCost',{},  { decimalPlaces: 4 }),      
                gridService2.colDef.createCurrency('unitMargin','Unit Margin','unitMargin',{},  { decimalPlaces: 4 }),
                gridService2.colDef.createText('priceCode','Pricing Code','priceCode',{hide: true}),
                gridService2.colDef.createText('priceCodeDescription','Price Code Description','priceCodeDescription',{hide: true}),
                gridService2.colDef.createCurrency('baseUnitPrice','Base Unit Price','baseUnitPrice',{hide: true},  { decimalPlaces: 2 }),
                gridService2.colDef.createCurrency('baseUnitPriceVariance','Unit price Variance','baseUnitPriceVariance',{hide: true},  { decimalPlaces: 2 }),
                gridService2.colDef.createCurrency('totalRevenue','Total Revenue','totalRevenue',{},  { decimalPlaces: 2 }),      
                gridService2.colDef.createCurrency('totalCost','Total Cost','totalCost',{},  { decimalPlaces: 2 }),      
                gridService2.colDef.createCurrency('totalMargin','Total Margin','totalMargin',{},  { decimalPlaces: 2 }),

            ];
            
        }
        // priceCode: summary.BasePriceCodeID,
        //     priceCodeDescription: summary.BasePriceCodeDescription,
        //     baseUnitPrice: summary.BaseUnitPrice,
        //     baseUnitPriceVariance: summary.BaseUnitPriceVariance

        function initMarginHistory() {
             var gridParams = {
                gridOptions : {
                     groupAggFunction : function(rows){
                            return _.reduce(rows, function(result, row){
                                var data = row.data;

                                result.units        += data.units;                                
                                result.totalRevenue += data.totalRevenue;
                                result.totalCost    += data.totalCost;
                                result.totalMargin  += data.totalMargin;
                                result.unitPrice     = result.units ? result.totalRevenue / result.units : null;
                                result.unitCost      = result.units ? result.totalCost / result.units : null;
                                result.unitMargin    = result.units ? result.totalMargin / result.units : null;
                                result.baseUnitPrice        += data.baseUnitPrice;
                                result.baseUnitPriceVariance += data.baseUnitPriceVariance;

                                return result;
                            }, {
                                units: 0,
                                unitPrice: 0,
                                unitCost: 0,
                                unitMargin: 0,
                                totalRevenue: 0,
                                totalCost: 0,
                                totalMargin: 0,
                                baseUnitPrice: 0,
                                baseUnitPriceVariance:0
                            });
                        }
                },
                gridState     : gridState(subscriberId, 'marginHistory'),
                defs          : getColDefs(),
                exportOptions : { fileName: 'Margin History' }
            };

            return gridService2
                .createGrid(gridParams)
                .then(function(grid){
                    $scope.activeItem.grid = grid;
                    queryMarginTransactionHistoryReport();
                });
        }

        function queryMarginTransactionHistoryReport() {            
            var params = {
                containerKey  : $scope.activeItem.params.containerKey                
            };

            var rowPromise = $http({
                    url     : 'api/subscribers/{0}/margins/transactions/history'.format($rootScope.user.subscriberId), 
                    method  : 'POST',
                    data    : params
                })            
                .then(function(result){

                    return result.data;
                });

            var promise = $scope.activeItem.grid.setRows(rowPromise);
            return promise;
        }
    }
]);
