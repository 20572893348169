angular.module('angus.services')
    .service('dateToString', function () {

        this.convert = function (date) {
            if (date) {
                var dd = date.getDate(); //yields day
                var MM = date.getMonth() + 1; //yields month
                var yyyy = date.getFullYear(); //yields year

                if (dd < 10) dd = "0" + dd;
                if (MM < 10) MM = "0" + MM;

                return ("{0}/{1}/{2} 00:00:00").format(MM, dd, yyyy);
            } else return "";
        };

        this.formatDate = function(date) {
            if(!(date instanceof Date))
                date = new Date(date);

            if (date) {
                var year = date.getFullYear();
                var month = date.getMonth();
                var day = date.getDate();
                return ("{0}/{1}/{2}").format(month + 1, day, year);
            } else return "";
        };
    });