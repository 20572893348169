angular.module( 'angus.directives' ).directive( 'ngAngusPasswordMatch', [
	'$parse',
	function( $parse ) {
		return {
			require: 'ngModel',
			link: function( scope, elem, attrs, ctrl ) {
				scope.$watch( function() {
					return ( $parse( attrs.ngAngusPasswordMatch )( scope ) === ctrl.$modelValue ) || ctrl.$modelValue == null;
				}, function( currentValue ) {
					ctrl.$setValidity( 'mismatch', currentValue );
				} );
			}
		};
	}
] );