angular.module('angus.directives')
    .directive('clickAnywhereButHere', [
        '$document', '$parse', function($document, $parse) {
            return {
                restrict: 'A',
                link: function(scope, elem, attr) {
                    var fn = $parse(attr.clickAnywhereButHere);

                    elem.bind('click', function(e) {
                        e.stopPropagation();
                    });

                    $document.on('click', function() {
                        scope.$apply(function() {
                            fn(scope, { $event: event });
                        });
                    });
                }
            };
        }
    ]);