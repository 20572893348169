'use strict';

angular.module('angus.directives').directive('tankProfAnalysis', [
    'actionViewTemplates',
    function(actionViewTemplates) {
        return {
            scope: {
                model: '=actionModel',
                settings: '=tankProfAnalysisWidgetSettings',
                widgetCode: '='
            },
            templateUrl: actionViewTemplates.tankProfAnalysisContainer            
        };
    }
]);
