angular.module('angus.services')
    .factory('paygoTMSAdminScopeService', ['$rootScope',
        function($rootScope) {
            var service = {};
            service.data = false;

            service.sendData = function(data) {
                this.data = data;
                $rootScope.$broadcast('share');
            };

            service.getData = function() {
                return this.data;
            };

            return service;
        }
    ]);
