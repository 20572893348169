'use strict';


angular.module('angus.controllers').controller('santaBudgetVarCtrl', [
  '$scope', 'constantsService', '_', 'hierarchyService', 'fiscalYearService', 'dateCalculator', '$rootScope', 'datePickerService',
  'moment', '$http', '$q', 'yearMonthSelectService', 'restrictedAccessService', 'fluentRest',
  function($scope, constantsService, _, hierarchyService, fiscalYearService, dateCalculator, $rootScope, datePickerService,
           moment, $http, $q, yearMonthSelectService, restrictedAccessService, fluentRest) {

    var divisions = [];
    var settingsBackup = null;
    var fiscalYearOfToday =null ;
    //api/subscribers/5565c6e8af6871aca9df359c/budgets/years
    // $scope.yearChange = function(){
    //     addYearsToMonths();
    //     reloadWidget();
    // };

    function addYearsToMonths() {
      var year = $scope.fiscalYear;
      if(moment().month(months[0]).month() > 0 )
        year--;
      $scope.months = _.map(months, function(month, index) {
        var monthNumber = moment().month(month).month();
        if(monthNumber === 0 && index !== 0)
          year++;
        return {
          display : month, //+ ' - ' + year
          value : monthNumber
        }
      });
    }


    var months;

    // $scope.years = _.range(moment().subtract(5, 'years').year(), moment().add(2, 'years').year(), 1);

    function loadWidget() {

      $scope.widget.noContentFlag = false;
      $scope.widget.instance.settings.selectedView = $scope.widget.instance.settings.selectedView || 1;

      divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);

      var promise = $q
      .all([
        fiscalYearService
        .getFiscalYearMonthsOrdered()
        .then(function(m) {
          $scope.month = moment().month();
          $scope.fiscalYear = fiscalYearOfToday = dateCalculator.getFiscalYear(moment(), moment().month(m[0]).month());

          months = m;
          addYearsToMonths();

          $scope.yearChanged();
        }),
        restrictedAccessService
        .getAccess()
        .then(function(access) {
          $scope.restrictedAccess = access.restrictedAccess;
        })
      ])
      //api/subscribers/5565c6e8af6871aca9df359c/budgets/years

      .then(function(){
          $scope.budget = {
              ytd: null,
              mtd: null
          };

          return Promise.all( [queryBudgetVarianceYear(), queryBudgetVariance($scope.month)] );
      })
      .then(function(){
        return fluentRest
        .api()
        .subscribers($rootScope.user.subscriberId)
        .budgets()
        .years()
        .get()
        .then(function(years){
          $scope.years = years
        })
      });

      return $scope.widget.promises.monitor(promise);
    }

    function reloadWidget() {
        $scope.widget.promises.monitor(queryBudgetVarianceYear(), queryBudgetVariance($scope.month));
    }

    $scope.reloadWidget = reloadWidget;

    $scope.widget.setDefaultInitialization(loadWidget);

    $scope.yearChanged = function() {
      $scope.widget.noContentFlag = false;

      fiscalYearService
      .getFiscalYearMonthsOrdered()
      .then(function(months){
        var startMonth   = moment().month(months[0]).month();
        var currentMonth = moment().month();


        $scope.months = _(months)
        .map(function(m){
          return {
            value: moment().month(m).month(),
            display : m
          };
        })
        .filter(function(m){
          return fiscalYearOfToday > $scope.fiscalYear || m.value <= currentMonth || m.value >= startMonth;
        })
        .value();

        var lastMonth    = $scope.months[$scope.months.length-1].value;

        if(!$scope.month || (fiscalYearOfToday == $scope.fiscalYear &&  $scope.month > lastMonth && $scope.month < startMonth) )
          $scope.month = currentMonth;

        reloadWidget();
      });
    };

    $scope.fiscalYearChanged = function() {
      reloadWidget();
    };

    $scope.monthChanged = function() {
      reloadWidget();
    };

    function queryBudgetVariance(month) {

      var params = {
        h  : $scope.widget.instance.settings.hierarchyNodeId,
        hs:1,
        wdo: $scope.widget.instance.settings.workingDaySubtraction ? $scope.widget.instance.settings.workingDaySubtraction.value : null,
        pc : $scope.widget.instance.settings.productCategoryIds ? $scope.widget.instance.settings.productCategoryIds.value : null,
        tc: $scope.widget.instance.settings.tradeClassCategoryIds ? $scope.widget.instance.settings.tradeClassCategoryIds.value : null,
        ppc: $scope.widget.instance.settings.pricePlanCategoryIds ? $scope.widget.instance.settings.pricePlanCategoryIds.value : null,
        fy : $scope.fiscalYear
      };

      if(month)
        params.m  = $scope.month;

        // for some reason January would not work in widget or detail view, unless explicitly set
        if(month === 0) {
            params.m = $scope.month;
        }


      return fluentRest
      .api()
      .subscribers($rootScope.user.subscriberId)
      .budgets()
      .variance()
      .get(params)
      .then(function(response){
        if (!response )
          $scope.widget.noContentFlag = true;
        else {
          $scope.budget["mtd"] = response.aggregates ? response.aggregates : null;
          $scope.MTDViewPercents = response.aggregates.percentVariance;
          $scope.MTDViewUnits = response.aggregates.variance;
          $scope.dataThroughDate = response.dataThroughDate;
          $scope.widget.setDefaultActionModel({ month : $scope.month, year : $scope.fiscalYear });
        }

      });
    }

      function queryBudgetVarianceYear() {

          var params = {
              h  : $scope.widget.instance.settings.hierarchyNodeId,
              hs:1,
              wdo: $scope.widget.instance.settings.workingDaySubtraction ? $scope.widget.instance.settings.workingDaySubtraction.value : null,
              pc : $scope.widget.instance.settings.productCategoryIds ? $scope.widget.instance.settings.productCategoryIds.value : null,
              tc: $scope.widget.instance.settings.tradeClassCategoryIds ? $scope.widget.instance.settings.tradeClassCategoryIds.value : null,
              ppc: $scope.widget.instance.settings.pricePlanCategoryIds ? $scope.widget.instance.settings.pricePlanCategoryIds.value : null,
              fy : $scope.fiscalYear
          };

          return fluentRest
          .api()
          .subscribers($rootScope.user.subscriberId)
          .budgets()
          .variance()
          .get(params)
          .then(function(response){
              if (!response )
                  $scope.widget.noContentFlag = true;
              else {
                  $scope.budget["ytd"] = response.aggregates ? response.aggregates : null;
                  $scope.YTDViewPercents = response.aggregates.percentVariance;
                  $scope.YTDViewUnits = response.aggregates.variance;
                  $scope.dataThroughDateYear = response.dataThroughDate;
                  $scope.widget.setDefaultActionModel({ month : $scope.month, year : $scope.fiscalYear });
              }

          });
      }

    $scope.$on('widgetSettingsChanged', function() {
      reloadWidget();
    });
  }
]);
