'use strict';

angular.module('angus.directives').directive('ngAngusPassword', 
	[ 'constantsService', 
	function(constantsService) {
		return {
			require: 'ngModel',
			restrict: 'A',
			link: function(scope, el, attrs, ctrl) {
				ctrl.$parsers.push(function(viewValue) {					
					
					ctrl.$setValidity('angusPasswordMin', true);
					ctrl.$setValidity('angusPasswordNum', true);
					ctrl.$setValidity('angusPasswordLetter', true);
					ctrl.$setValidity('angusPasswordBadChar', true);
					if (viewValue.length < constantsService.passwordRequirements.minLength.value) {
						ctrl.$setValidity('angusPasswordMin', false);
					} else if (viewValue.search(/\d/) == -1) {
						ctrl.$setValidity('angusPasswordNum', false);
					} else if (viewValue.search(/[a-zA-Z]/) == -1) {
						ctrl.$setValidity('angusPasswordLetter', false);
					} else if (viewValue.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+]/) != -1) {
						ctrl.$setValidity('angusPasswordBadChar', false);
					}
					return viewValue;
					 
				});
			}
		};
	}
]);