angular.module('angus.controllers').controller('avgGalByTankSizeSettingsCtrl', [
    '$scope', '$rootScope', '$http', 'periodIndicators', 'productsService',  'hierarchyService', 'tradeClassService', 'constantsService', 'deliveryTypeService',
    function($scope, $rootScope, $http, periodIndicators, productsService,hierarchyService, tradeClassService, constantsService, deliveryTypeService) {

        $scope.periodIndicators = constantsService.periodIndicators.enums;

        productsService.getProducts()
            .then(function(products) {
                $scope.products = products;
            });

        deliveryTypeService.getDeliveryTypes()
            .then(function(deliveryTypes) {
                $scope.deliveryTypes = deliveryTypes;
            });

        tradeClassService.getTradeClasses()
            .then(function(classes) {
                $scope.tradeClasses = classes;
            });

        //if(!$scope.widget.instance.settings.title) $scope.widget.instance.settings.title = "Average Delivery By Tank Size";

        $scope.openDivisionHierarchyView = function() {
            hierarchyService.openDivisionCenterModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };
    }
]);
