angular.module('angus.controllers').controller('tankProfAnalysisSettingsCtrl', [
  '$scope', 'hierarchyService', 'productsService', 'constantsService',
  function($scope, hierarchyService, productsService, constantsService) {
    'use strict';
    $scope.ieoOptions = constantsService.includeExcludeOnly.enums;

    productsService.getProducts()
      .then(function(products) {
        $scope.products = products;
      });

    $scope.openDivisionHierarchyView = function() {
      hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
        .then(function(nodeId) {
          $scope.widget.instance.settings.hierarchyNodeId = nodeId;
        });
    };
  }
]);
