angular.module( 'angus.filters' )
.filter( 'noOverlap', [ '_',
	function( _ ) {
		return function( listToSelectFrom, selectProperty, otherList, otherProperty, exception ) {
			if ( otherList ) {
				var out = _( listToSelectFrom )
					.filter( function( listItem ) {
						if ( selectProperty ) {
							listItem = listItem[ selectProperty ];

						}
						return _( otherList )
						.every( function( otherListItem ) {
							if ( otherListItem ) {
								if ( otherProperty ) {
									if ( _.isArray( otherProperty ) ) {
										var temp = null;
										temp = otherListItem[ otherProperty[ 0 ] ];
										if ( temp ) otherListItem = temp[ otherProperty[ 1 ] ];
									} else otherListItem = otherListItem[ otherProperty ];
								}
								return listItem != otherListItem || listItem == exception;
							}
						} );
					} );
				return out.value();
			} else return listToSelectFrom;
		};
	}
] )
.filter( 'nodeFilter', [ '_',
	function( _ ) {
		'use strict';

		return function( nodes, currentNode, isParents, isDealerHierarchy ) {
			var targetNodes, inverseNodes; //parents, children or children, parents
			var outerNodeProp;

			if(isParents){
				targetNodes   = currentNode.parents;
				inverseNodes  = currentNode.children;
				outerNodeProp = 'isLeaf';

			} else{
				targetNodes   = currentNode.children;
				inverseNodes  = currentNode.parents;
				outerNodeProp = 'isRoot';
			}

			var out =  _.filter(nodes, function(node){

				if(isDealerHierarchy){
					if(node.isUnderDealer){
						if (!_.some(targetNodes, function(targetNode){
							return targetNode == node.id;
						}))
							return false;
					}
				}

				if(node.id == currentNode.id)
					return false;

				if (_.some(inverseNodes, function(inverseNode){
					return node.id == inverseNode;
				}))
					return false;

				// if (_.some(inverseNodes, function(inverseNode){
				// 	return _.some(targetNodes, function(targetNode){
				// 		return targetNode == inverseNode;
				// 	});
				// }))
				// 	return false;



				if(node[outerNodeProp])
					return false;

				return true;
			});

			return out;

		};
	}
] );
