angular.module('angus.controllers').controller('resetPasswordCtrl', [
    '$scope', '$state', '$stateParams', '$http', 'brandingService', 'constantsService', '_', '$location', '$timeout', 'problemDetailsService',
    function ($scope, $state, $stateParams, $http, brandingService, constantsService, _, $location, $timeout, problemDetailsService) {


        $location.url('/#/ResetPassword');

        if (!$stateParams.token) $state.transitionTo('signIn');
        $scope.signInUrl = window.env.envApi + '/#/signIn';
        $scope.alerts = [];
        $scope.branding = brandingService;
        $scope.disableResetPassword = false;

        $scope.resetSuccessMessage = "";
        $scope.errorMessages = [];

        $scope.toggleTerms = function () {
            $scope.showTerms = !$scope.showTerms;
        }

        $scope.minLength = constantsService.passwordRequirements.minLength.value;

        $scope.resetPassword = function () {
            $scope.disableResetPassword = true;

            $scope.resetSuccessMessage = "";
            $scope.errorMessages = [];


            var model = {
                userName: $scope.resetPasswordModel.username,
                token: $stateParams.token,
                newPassword: $scope.resetPasswordModel.newPassword,
            };

            return $http.post('/identity/reset-password', model, { apiV2: true })
                .then(function () {
                    $scope.resetSuccessMessage = "Your password has been reset successfully!";

                    return $timeout(function () {
                        $location.path('/#/signIn');
                    }, 4000);
                })
                .catch(function (res) {
                    try {
                        $scope.errorMessages = problemDetailsService.getErrorMessages(res.data);
                    }
                    catch (error) {
                        console.log('Error processing the response: ', error);
                    }

                    $scope.disableResetPassword = false;
                });
        };
    }
]);
