angular.module('angus.controllers').controller('activeTankMonitorsController', [
    '$scope', '$rootScope', 'gridService2', '_', '$q', '$mdToast', 'moment', 'subscriberService', 'reportService', 'gridState', 'inventoryManagementService','constantsService',
    function($scope, $rootScope, gridService2, _, $q, $mdToast, moment, subscriberService, reportService, gridState, inventoryManagementService, constantsService ) {

        'use strict';  
        $scope.alerts = []; 
        $scope.isLoading = false;         
        $scope.areSubscriberDropdownsLoading = false;
        $scope.subscribers = [];
        $scope.flattenSubscribers = [];
        $scope.tankMonitorTypes = null;
        $scope.selectedTankMonitorTypes = null;
        $scope.reportDate = new Date(); 
        $scope.grandTotals = 0;
        $scope.rowStatus = {
            active: 1,
            delete: 3
        } 
 
		function init(){
            $scope.isLoading = true;
            Promise.all([
                getAmsSubscribers(),
                loadReportGrid()
            ])
            .then(function(results) {
                $scope.isLoading = false;
            });
        }

        $scope.clickRunReport = function() {
            getReportResult();
        }
        
        $scope.closeAlert = function (index) {
            $scope.alerts.splice(index, 1);
        }  
  
        //Private Methods ********************************************************************
        var getMonitorTypes = function() {   
            $scope.areItemsLoading = true;
            inventoryManagementService.getMonitorTypes($scope.subscribers[0].id) 
            .then(function(result) { 
                $scope.tankMonitorTypes = result;    
                $scope.selectedTankMonitorTypes = _.map(result, 'id');
                $scope.areItemsLoading = false; 
            }); 
        }  

        var loadReportGrid = function() { 
            var gridParams = {
                gridOptions: {}, 
                defs: getColDefsForReport(),
                clicks: {},
                exportOptions: { fileName: 'Report' }
            };

            return gridService2.createGrid(gridParams)
                .then(function (grid) { 
                    grid.setRows(Promise.resolve());

                    $scope.reportGrid = grid;
                });
        } 

        var getColDefsForReport= function(){ 
            var colDefs = [];  
            colDefs.push(gridService2.colDef.createText('subscriberName','Subscriber','subscriberName'));
            colDefs.push(gridService2.colDef.createText('monitorTypeKey','Monitor Type','monitorTypeName', { rowGroupIndex : 1, hide : true })); 
            colDefs.push(gridService2.colDef.createNumber('totalSold','Sold','totalSold', { aggFunc : 'sum'})); 
            colDefs.push(gridService2.colDef.createNumber('totalShipped','Delivered','totalShipped', { aggFunc : 'sum'})); 
            colDefs.push(gridService2.colDef.createNumber('open','Open','open', { aggFunc : 'sum'})); //Delivered - Sold ???
            colDefs.push(gridService2.colDef.createNumber('installedReporting','Installed Reporting','installedReporting', { aggFunc : 'sum'}));  
            colDefs.push(gridService2.colDef.createNumber('installedNotReporting','Installed Not Reporting','installedNotReporting', { aggFunc : 'sum'}));    
            colDefs.push(gridService2.colDef.createNumber('installedNeverReporting','Installed Never Reporting','installedNeverReporting', { aggFunc : 'sum'}));    
            colDefs.push(gridService2.colDef.createNumber('totalInstalled','Total Installed','totalInstalled', { aggFunc : 'sum'})); 
            colDefs.push(gridService2.colDef.createNumber('percentDeliveredSold','% Delivered / Sold','percentDeliveredSold', {}, {decimalPlaces: 0}));  //Delivered / Sold ???  
            colDefs.push(gridService2.colDef.createNumber('percentReportingInstalled','% Reporting / Installed','percentReportingInstalled', {}, {decimalPlaces: 0}));      
            colDefs.push(gridService2.colDef.createNumber('percentTotalInstalledDelivered','% Total Installed / Delivered','percentTotalInstalledDelivered', {}, {decimalPlaces: 0})); 
            colDefs.push(gridService2.colDef.createNumber('percentNotReporting','% Not Reporting','percentNotReporting', {}, {decimalPlaces: 0}));    
            //comparison 
            colDefs.push(gridService2.colDef.createNumber('installedReportingCompare','Comparison Installed Reporting','installedReportingCompare', { aggFunc : 'sum'}));  
            colDefs.push(gridService2.colDef.createNumber('installedNotReportingCompare','Comparison Installed Not Reporting','installedNotReportingCompare', { aggFunc : 'sum'}));    
            colDefs.push(gridService2.colDef.createNumber('installedNeverReportingCompare','Comparison Installed Never Reporting','installedNeverReportingCompare', { aggFunc : 'sum'}));    
            colDefs.push(gridService2.colDef.createNumber('totalInstalledCompare','Comparison Total Installed','totalInstalledCompare', { aggFunc : 'sum'}));  
            colDefs.push(gridService2.colDef.createNumber('installedUnitChange','Installed Unit Change','installedUnitChange', { aggFunc : 'sum'}));   
            colDefs.push(gridService2.colDef.createNumber('installedPercentChange','Installed % Change','installedPercentChange', {aggFunc : 'sum'}, {decimalPlaces: 0}));      
            colDefs.push(gridService2.colDef.createNumber('reportingUnitChange','Reporting Unit Change','reportingUnitChange', {aggFunc : 'sum'}));  
            colDefs.push(gridService2.colDef.createNumber('reportingPercentChange','Reporting % Change','reportingPercentChange', { aggFunc : 'sum'}, {decimalPlaces: 0}));   
            colDefs.push(gridService2.colDef.createNumber('notReportingUnitChange','Not Reporting Unit Change','notReportingUnitChange', { aggFunc : 'sum'}, {decimalPlaces: 0}));   
            colDefs.push(gridService2.colDef.createNumber('notReportingPercentChange','Not Reporting % Change','notReportingPercentChange', { aggFunc : 'sum'}, {decimalPlaces: 0}));     
            
            return colDefs;
        } 

        var getTotal = function(array, parameter) { 
            let sum = 0;
            if (array && array.length > 0 && typeof parameter === 'string') {
                sum = 0;
                for (let e of array) {
                    if (e && e.hasOwnProperty(parameter)) {
                        sum += e[parameter];
                    }
                }
            } 
            return sum;
        }

        var getReportResult = function(){    
            var flattenTankMonitorTypes = [];  
            for(var i = 0; i < $scope.tankMonitorTypes.length; i++){ 
                flattenTankMonitorTypes.push($scope.tankMonitorTypes[i].id);
            }  
            var data = {
                comparisonDate: moment($scope.reportDate).subtract(7, 'day').format("MM/DD/YYYY"), 
                defaultDate: moment($scope.reportDate).format('MM/DD/YYYY'), 
                tankMonitorType: flattenTankMonitorTypes,
                subscribers: $scope.flattenSubscribers
            } 
            var rowPromise = reportService.postActiveReport(data)
            .then(function(result) {     
                result.push(
                    {
                        endDate: "",
                        installedNeverReporting: getTotal(result, 'installedNeverReporting'),
                        installedNeverReportingCompare: getTotal(result, 'installedNeverReportingCompare'),
                        installedNotReporting: getTotal(result, 'installedNotReporting'),
                        installedNotReportingCompare: getTotal(result, 'installedNotReportingCompare'),
                        installedPercentChange: getTotal(result, 'installedPercentChange'),
                        installedReporting: getTotal(result, 'installedReporting'),
                        installedReportingCompare: getTotal(result, 'percentinstalledReportingCompareDeliveredSold'),
                        installedUnitChange: getTotal(result, 'installedUnitChange'),
                        levelSinceComparisonDate: getTotal(result, 'levelSinceComparisonDate'),
                        monitorTypeKey: 0,
                        monitorTypeName: "Grand Totals",
                        neverLeveled: getTotal(result, 'neverLeveled'),
                        noLevelSinceComparisonDate: getTotal(result, 'noLevelSinceComparisonDate'),
                        notReportingPercentChange: getTotal(result, 'notReportingPercentChange'),
                        notReportingUnitChange: getTotal(result, 'notReportingUnitChange'),
                        percentDeliveredSold: getTotal(result, 'percentDeliveredSold'),
                        percentNotReporting: getTotal(result, 'percentNotReporting'),
                        percentReporting: getTotal(result, 'percenpercentReportingtDeliveredSold'),
                        percentReportingInstalled: getTotal(result, 'percentReportingInstalled'),
                        percentTotalInstalledDelivered: getTotal(result, 'percentTotalInstalledDelivered'),
                        reportingPercentChange:getTotal(result, 'reportingPercentChange'),
                        reportingUnitChange: getTotal(result, 'reportingUnitChange'),
                        startDate: '',
                        subscriberKey: '0',   
                        subscriberName: "Grand Totals",
                        totalInstalled: getTotal(result, 'totalInstalled'),
                        totalInstalledCompare: getTotal(result, 'totalInstalledCompare'),
                        totalMonitors: getTotal(result, 'totalMonitors'),
                    }
                )
                var headers = result;  
                $scope.reportGrid.toggleToolPanel();

                return headers;
            });  
            var promise = $scope.reportGrid.setRows(rowPromise);
            return promise; 
        }

        var getAmsSubscribers = function() {
            $scope.areSubscriberDropdownsLoading = true;
            return subscriberService.getAmsSubscribers()
            .then(function(subscribers){  
                $scope.subscribers = _.filter(subscribers, function(subscriber){
                    return subscriber.isPaygo && subscriber.registrationStatusKey === constantsService.registrationStatus.live;
                });  
                $scope.flattenSubscribers = [];  
                for(var i = 0; i < $scope.subscribers.length; i++){ 
                    $scope.flattenSubscribers.push($scope.subscribers[i].id);
                } 
                getMonitorTypes(),
                $scope.areSubscriberDropdownsLoading = false;
            }); 
        }

		init(); 
    }
]);