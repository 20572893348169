angular.module('angus.controllers')
    .controller('deleteConsumerUserCtrl', [
        '$scope', '$state', 'brandingService','$http','$q','$mdToast','$location','modalService','actionViewTemplates',
        function($scope, $state, brandingService,$http,$q,$mdToast,$location,modalService,actionViewTemplates) {
            'use strict';
            $scope.branding = brandingService;

            $scope.signIn = function() {
                $scope.deleteStatusMessage = ""
                var deferred = $q.defer();
                $http.post('/consumer/signIn', $scope.model)
                    .success(function(data) {
                        var scope = {
                            user : {firstName: data.user.name, userName:data.user.username },
                            alerts: [],
                            closeAlert: function (index) {
                                this.alerts.splice(index, 1);
                            },
                            confirm: function (user) {
                                var that = this;
                                if (user) {
                                    var promise = $http({
                                        url: '/consumer/delete',
                                        method: 'POST',
                                        data: {username: user.userName}
                                    })
                                        .then(function (result) {
                                            $scope.deleteStatusMessage = "Account has been deleted"
                                            that.close(user);
                                        });

                                    promise
                                        .catch(function (err) {
                                            $location.url('/#/DeleteConsumerUser');
                                            var alerts = _.map(err, function (e) {
                                                return {
                                                    type: 'danger',
                                                    msg: '<strong>Error!</strong> ' + e.msg
                                                };
                                            });

                                            [].push.apply(that.alerts, alerts);
                                        });

                                    return promise;
                                } else
                                    that.close();
                            },

                        };
                        return modalService
                            .openTopBarModal(actionViewTemplates.subRegConfirmDeleteUser, scope)
                            .then(function(obj){
                                deferred.resolve();
                                if(!obj) return;
                            });
                    }).error(function(data) {
                    $location.url('/#/DeleteConsumerUser');
                    $scope.deleteStatusMessage = data && data.msg || 'Invalid username or password.'
                });
                return deferred.promise;
            };
        }
    ]);
