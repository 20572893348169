angular.module('angus.controllers').controller('liquidFuelsReportContainerCtrl', [
  '$scope', '$rootScope', '$http', 'periodIndicators', 'productsService', 'actionViewTemplates', '_', 'constantsService', 'lastDeliveryDateService', 'hierarchyService', 'dateCalculator', 'drillService',
    'deliveryTypeService', 'Paging', 'moment', 'gridService2', 'nodeType', 'gridState',
    function($scope, $rootScope, $http, periodIndicators, productsService, actionViewTemplates, _, constantsService, lastDeliveryDateService, hierarchyService, dateCalculator, drillService,
        deliveryTypeService, paging, moment, gridService2, nodeType, gridState) {

        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.dates = {
            startDate        : moment.utc($scope.settings.startDate).format('L')  || moment(),
            endDate          : moment.utc($scope.settings.endDate).format('L')|| moment(),
            lastInvoiceDate  : moment.utc($scope.settings.endDate).format('L')
        };


        $scope.filter =  {
            productIds      : $scope.settings.productIds.value,
            hierarchyNodeId : $scope.settings.hierarchyNodeId,
            tradeClassIds   : $scope.settings.tradeClassIds.value,
            usePostingDate: $scope.settings.usePostingDate.value
        };

        $scope.getLiquidFuels = function(){

            var params = {
                p           : $scope.filter.productIds,
                s           : $scope.dates.startDate.format(),
                e           : $scope.dates.endDate.format(),
                t           : $scope.filter.tradeClassIds,
                z           : 'include',
                divisionId  : $scope.filter.hierarchyNodeId,
                usePostingDate: $scope.filter.usePostingDate
            };

            var rowPromise = $http({
                    url: ('api/subscribers/{0}/margins').format($scope.subscriberId),
                    method: 'POST',
                    data: params
            }).then(function(result){
                  return result.data;
            });

            var promise = $scope.grid.setRows(rowPromise);

            //$scope.promiseMonitor = new PromiseMonitor(promise);
            return promise;
        }



       function getColClicks(){
            return {
                accountNumber   : {
                    onClick : function(row){ drillService.openAccountInfo(row, drillService.context.liquidFuels); }
                },
                tankNumber      : {
                    onClick : function(row){ drillService.openTankInfo(row, drillService.context.liquidFuels); }
                }
            };
        }

        function getColDefs(){

            var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];

            colDefs.push(gridService2.colDef.createText('productName','Product','productName', { rowGroupIndex : nextIndex, hide : true }));
            colDefs.push(gridService2.colDef.createText('tradeClassName','Trade Class','tradeClassName', { rowGroupIndex : nextIndex + 1, hide : true }));
            colDefs.push(gridService2.colDef.createText('pricePlanName','Price Plan','pricePlanName', { rowGroupIndex : nextIndex + 2, hide : true }));


            colDefs.push(gridService2.colDef.createDate('date','Date','date'));
            colDefs.push(gridService2.colDef.createDate('useAccountingDate','Accounting Date','useAccountingDate', { hide: true, sort: 'asc' }));
            colDefs.push(gridService2.colDef.createDate('postingDate','Posting Date','postingDate', { hide: true, sort: 'asc' }));

            colDefs.push(gridService2.colDef.createText('accountNumber','Account Number','accountNumber', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('customerName','Customer Name','customerName'));
            colDefs.push(gridService2.colDef.createText('tankNumber','Tank Number','tankNumber', { cellClass : ['center']} ));

            colDefs.push(gridService2.colDef.createNumber('units','Units','units', { aggFunc : 'sum'}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createText('postalCodeCustomerLocation','ZIP Code','postalCodeCustomerLocation', { cellClass : ['center']} ));

          return colDefs;
        }

        var nodeColDefs;

        nodeType
            .getDivisionColDefs(subscriberId)
            .then(function(colDefs){
                nodeColDefs = colDefs;          
                var gridParams = {
                    gridState     : gridState(subscriberId, $scope.widgetCode),
                    defs          : getColDefs(),
                    clicks        : getColClicks(),
                    exportOptions : { fileName: 'Liquid Fuels Volume' }
                };
                return gridService2.createGrid(gridParams);
            })
            .then(function(grid){
                $scope.grid = grid;
                $scope.getLiquidFuels();
            });

    }
]);
