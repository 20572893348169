angular.module('angus.controllers').controller('avgGalByTankSizeReportContainerCtrl', [
    '$scope', '$rootScope', '$http', 'modalService', 'actionViewTemplates', '_', 'drillService', 'gridService2', 'gridState', 'moment', 'yearMonthSelectService',
    function($scope, $rootScope, $http,  modalService, actionViewTemplates, _,  drillService,  gridService2,  gridState, moment, yearMonthSelectService) {

        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.filter =  {
            divisionId: $scope.settings.hierarchyNodeId,
            productIds: $scope.settings.productIds.value,
            hierarchyNodeId: $scope.settings.hierarchyNodeId,
            tradeClassIds: $scope.settings.tradeClassIds.value,
            deliveryTypeIds: $scope.settings.deliveryTypeIds.value,
            selectedYear: $scope.model.year,
            selectedMonth: $scope.model.month,
            includeZeroGallonDeliveries:$scope.settings.includeZeroGallonDeliveries.value
        };



        function openDeliveriesReport(tankSize, numberOfMonthsToSubtract) {
            numberOfMonthsToSubtract = numberOfMonthsToSubtract || 0;

            var startDate = moment.utc().year($scope.filter.selectedYear).month($scope.filter.selectedMonth - 1).subtract(numberOfMonthsToSubtract, 'months').startOf('month');
            var endDate   = moment.utc(startDate).endOf('month');

            var scope = {
                widgetName: 'Tank Size Deliveries',
                widgetIcon: 'car',
                model: {
                    productIds      : $scope.filter.productIds,
                    startDate       : startDate,
                    endDate         : endDate,
                    minTankSize     : tankSize,
                    maxTankSize     : tankSize,
                    deliveryTypeIds : $scope.filter.deliveryTypeIds,
                    tradeClassIds   : $scope.filter.tradeClassIds   ,
                    divisionId      : $scope.filter.hierarchyNodeId
                }
            };

            modalService
                .openActionView(actionViewTemplates.avgGalDeliveryHistoryDefault, scope, 'avgGalDeliveries');
        }

        $scope.getAverageGallonsByTankSize = function(){

            $scope.grid.setColDefs(getColDefs());

            var params = {
                divisionId: $scope.filter.hierarchyNodeId,
                productIds: $scope.filter.productIds,
                month: $scope.filter.selectedMonth,
                year: $scope.filter.selectedYear,
                deliveryTypeIds: $scope.filter.deliveryTypeIds,
                tradeClassIds: $scope.filter.tradeClassIds,
                includeZeroGallonDeliveries: $scope.filter.includeZeroGallonDeliveries
            };

            var rowPromise = $http({
                url: 'api/subscribers/{0}/tanks'.format($rootScope.user.subscriberId),
                method: 'POST',
                data: params
            }).then(function(result){
                return result.data;
            });

            var promise = $scope.grid.setRows(rowPromise, true);

            //$scope.promiseMonitor = new PromiseMonitor(promise);
            return promise;
        };

        function getColClicks(){
            return {
                currentStops   : {
                    onClick : function(row){ if(row.tankSize || row.tankSize == 0) openDeliveriesReport(row.tankSize, 0)}
                },
                priorStops   : {
                    onClick : function(row){ if(row.tankSize || row.tankSize == 0) openDeliveriesReport(row.tankSize, 2)}
                },
                previousStops   : {
                    onClick : function(row){ if(row.tankSize || row.tankSize == 0) openDeliveriesReport(row.tankSize, 1)}
                }
            };
        }

        var prefixs = ['current', 'previous', 'prior'];

        function getMonthColNames(){
            var months = moment.months();
            return _.reduce(prefixs, function(result, prefix, index){
                result[prefix] = months[moment().month($scope.filter.selectedMonth - 1).subtract(index, 'months').month()];
                return result;
            }, {});
        }

        function getColDefs(){
            var monthNames = getMonthColNames();

            var colDefs = _.map(['current', 'previous', 'prior'], function(prefix, index){
                var stops = prefix + 'Stops';
                var units = prefix + 'AverageUnits';
                var percentageTankSize = prefix + 'PercentOfTankSize';

                var monthName = monthNames[prefix];

                var stopOpts = {
                    width : 120
                };

                if(index === 0) stopOpts.sort = 'desc';

                var children = [

                    gridService2.colDef.createNumber(stops,(monthName + ' Stops'), stops, stopOpts),
                    gridService2.colDef.createNumber(units, (monthName + ' - Units Delivered'), units, { }, {decimalPlaces: 1 }),
                    gridService2.colDef.createPercentage(percentageTankSize, (monthName + ' - % of Tank Size'), percentageTankSize, {  }, { decimalPlaces: 0 })
                ];
                return {
                    colId      :  prefix,
                    headerName : monthName,
                    children   : children
                };
            });

            colDefs.unshift(gridService2.colDef.createNumber('tankSize','Tank Size','tankSize', { width : 90,  pinned : 'left'}));

            return colDefs;
        }

        $scope.months = yearMonthSelectService.getMonths($scope.filter.selectedYear);
        $scope.years =  yearMonthSelectService.getYears($scope.filter.selectedYear);

        $scope.yearChanged = function() {
            yearMonthSelectService
                .yearChanged($scope.filter.selectedYear, $scope.filter.selectedMonth)
                .then(function(results) {
                    var months = results[0];
                    var month  = results[1];

                    $scope.months = months;
                    if(month) $scope.filter.selectedMonth = month;
                });
        };

        var gridParams = {
            gridOptions   : {
                toolPanelSuppressGroups   : true,
                showToolPanel             : false
            },
            gridState     : gridState(subscriberId, $scope.widgetCode),
            defs          : getColDefs(),
            clicks        : getColClicks(),
            exportOptions : { fileName: 'Average Gallons By TankSize' }
        };
        return gridService2
            .createGrid(gridParams)
            .then(function(grid){
                $scope.grid = grid;
                $scope.getAverageGallonsByTankSize();
            });

    }
]);