function TMSDealerExceptionsCtrl( $scope, $rootScope, Paging, actionViewTemplates, fluentRest){
	'use strict';
	
	$scope.getDealers = function() {
		var params ={
			showExceptions 	: 1,
			searchTerm 		: $scope.searchTerm,
			sortBy 			: $scope.sortBy,
			ascending		: $scope.ascending ? 1 : 0,		
			eg 				: 'tms'	
		};

		var promise =  fluentRest
			.api()
			.subscribers($rootScope.user.subscriberId)
			.dealers()
				.get(params)
				.then(function(dealers){
					$scope.widget.setDefaultActionModel({
						// activeDealerIds : $scope.widget.instance.settings.dealerIds.value
					});
					$scope.dealers = dealers;
				});

			if($scope.widget) $scope.widget.promises.monitor(promise);
			return promise;
	}

	$scope.dealerSelected = function( dealer ) {
		$scope.widget.openDetailView( 'templates/widgets/tmsDealerExceptions/html/actionViews/default.html', {	activeDealerIds : [dealer.id]} );
	};

	$scope.sort = function( sortBy ) {
		if ( $scope.sortBy == sortBy )
			$scope.ascending = !$scope.ascending;
		else {
			$scope.sortBy = sortBy;
			$scope.ascending = true;
		}
		$scope.getDealers();
	};


	function init() {
		$scope.paging = new Paging( $scope.pageSize || 5 );
		$scope.searchTerm ='';
		$scope.sortBy = 'name';
		$scope.ascending = true;
		return $scope.getDealers();			
	}

    $scope.widget.setDefaultInitialization(init);    
}

angular.module('angus.controllers').controller('TMSDealerExceptionsCtrl',  ['$scope', '$rootScope', 'Paging', 'actionViewTemplates', 'fluentRest', 'datePickerService', 'moment', TMSDealerExceptionsCtrl ]);