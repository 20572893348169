angular.module( 'angus.directives' ).directive( 'serviceContractsProfile', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		'use strict';
		
		return {
			scope: {
				model: '=actionModel',
				settings: '=serviceContractProfileWidgetSettings',
				widgetCode : '='
			},
			templateUrl: actionViewTemplates.serviceContractsProfileContainer
		};
	}
] );
