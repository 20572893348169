'use strict';

angular.module('angus.controllers').controller('subRegDriverExclusionCtrl', [
    '$scope', '$rootScope', '$q', 'actionViewTemplates', '_', 'modalService', 'alertsService', 'promiseMonitor', 'Paging', '$http',
    function($scope, $rootScope, $q, actionViewTemplates, _, modalService, alertsService, PromiseMonitor, Paging, $http) {

        $scope.paging = new Paging($scope.pageSize || 5);
        $scope.searchTerm = $scope.lastSearchTerm || '';
        $scope.sortBy = 'name';
        $scope.ascending = true;

        var searchTerm = '';

        $scope.promiseMonitor = new PromiseMonitor();

        function init() {

            $scope.isSysAdmin = $rootScope.user.role == 'sysadmin';

            var promise = query();

            $scope.promiseMonitor.monitor(promise);
        }

        $scope.clearSearch = function() {
            searchTerm = '';
            query();
        };

        $scope.applySearch = function() {
            searchTerm = $scope.searchTerm;
            query();
        };

        function query(pageNumber) {
            return getDrivers(searchTerm, pageNumber);
        }

        function getDrivers(searchTerm, pageNumber) {

            var params = {
                q: searchTerm,
                sortBy: $scope.sortBy,
                ascending: $scope.ascending,
                deliveryCenterId: [],
                sourceSystemKeys: $scope.selected.sourceSystemKey
            };

            var url = ('api/subscribers/{0}/drivers').format($scope.subscriberId || $rootScope.user.subscriberId);

            return $scope.paging.postGetPage(url, pageNumber, params)
                .then(function(drivers) {
                    $scope.drivers = drivers.items;
                    _.forEach($scope.drivers, function(driver) {
                        driver.exclusionFlag = driver.exclusionFlag ? true : false;
                    });
                });

        }

        $scope.saveDrivers = function() {

            var promise = $http.put(('api/subscribers/{0}/drivers').format($scope.subscriberId || $rootScope.user.subscriberId), $scope.drivers);

            $scope.promiseMonitor.monitor(promise);

        };

        $scope.query = query;

        var headingCenter = 'text-center clickable';

        $scope.headings = [{
            name: 'Driver Code',
            isHidable: false,
            propClass: headingCenter,
            propName: 'code',
            isSortable: true
        }, {
            name: 'Driver Name',
            isHidable: false,
            propClass: headingCenter,
            propName: 'name',
            isSortable: true
        }, {
            name: 'Exclude',
            isHidable: false,
            propClass: headingCenter,
            propName: 'exclusionFlag',
            isSortable: false
        }
        ];

        $scope.sort = function(sortBy) {
            if($scope.sortBy == sortBy) $scope.ascending = !$scope.ascending;
            else {
                $scope.sortBy = sortBy;
                $scope.ascending = true;
            }
            query();
        };

        init();
    }
]);