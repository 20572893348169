angular.module('angus.controllers').controller('acctGainLossSettingsCtrl', [
    '$scope', 'constantsService', 'hierarchyService', 'salesPersonService', 'tradeClassService',
    function ($scope, constantsService, hierarchyService, salesPersonService, tradeClassService) {
        'use strict';

        $scope.periodIndicators = constantsService.marginPeriodIndicators.enums;

        salesPersonService.getSalesPerson()
            .then(function (classes) {
                $scope.salespersons = classes;
            });

        $scope.openDivisionHierarchyView = function () {
            hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function (nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };

        tradeClassService.getAllTradeClasses($scope.subscriberId)
            .then(function (tradeClasses) {
                $scope.tradeClasses = tradeClasses;
            });
    }
]);
