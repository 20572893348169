
angular.module('angus.controllers').controller('tmsInstallSettingsController', [
    '$scope', '$rootScope', 'productsService', 'hierarchyService', 'datePickerService','$http', '_',
    function($scope, $rootScope,  productsService, hierarchyService,datePickerService, $http,  _) {

        'use strict';
        $scope.selected = {};
        //capital P dates are used for presentation only
        //$http.get('api/monitors/products').then(function(res){console.log(res)})
        $scope.selected.PstartDate = moment($scope.widget.instance.settings.startDate).format('MMM DD, YYYY')
        $scope.selected.PendDate  = moment($scope.widget.instance.settings.endDate).format('MMM DD, YYYY')

        $scope.openCustomDate = function(){
          datePickerService.openDatePickerModal(
               $scope.widget.instance.settings.startDate,
               $scope.widget.instance.settings.endDate ,
              moment()
            )
            .then(function(resultDates) {
              //capital P dates are used for presentation only
              $scope.widget.instance.settings.startDate = moment(resultDates[0]._d).format('MMM DD, YYYY')
              $scope.selected.PstartDate = moment(resultDates[0]._d).format('MMM DD, YYYY')
              $scope.widget.instance.settings.endDate = moment(resultDates[1]._d).format('MMM DD, YYYY')
              $scope.selected.PendDate  = moment(resultDates[1]._d).format('MMM DD, YYYY')

            })
        }
        $scope.$watch('selected.PstartDate', function(old, newVal){
           var dates = document.getElementsByClassName('dateForDisplay');
          if(old != newVal){
              dates[0].classList.add('show-change');
              dates[1].classList.add('show-change');
            
            setTimeout(function(){
                dates[1].classList.remove('show-change')
                dates[0].classList.remove('show-change')
              },550)
          }
        })
        $scope.getThisMonth = function(){
          $scope.widget.instance.settings.endDate = $scope.selected.PendDate = moment().format('MMM DD, YYYY');
          $scope.widget.instance.settings.startDate = $scope.selected.PstartDate = moment($scope.selected.PendDate).startOf('month').format('MMM DD, YYYY');
        }
        $scope.getLastMonth = function(){
          $scope.widget.instance.settings.endDate = $scope.selected.PendDate = moment().subtract(1, 'month').endOf('month').format('MMM DD, YYYY');
          $scope.widget.instance.settings.startDate = $scope.selected.PstartDate = moment($scope.selected.PendDate).startOf('month').format('MMM DD, YYYY');
        }


        $scope.openDivisionHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {

                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });

        };
      
        productsService.getPaygoProducts($rootScope.user.subscriberId)
        .then(function(products) {
       
          $scope.products = products.filter(function(pro){
            
            return pro.name == '#2 Heating Oil' || pro.name == 'Bio Heat'|| pro.name == 'Propane'
          })
        });


    }
]);
