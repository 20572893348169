angular.module('angus.controllers').controller('usersReportDetailsController', [
    '$rootScope', '$scope', 'adminService','gridService2','gridState',
    function ($rootScope, $scope, adminService,gridService2, gridState) {

        $scope.isSysAdmin = !!$rootScope.user.isSysAdmin;
        $scope.runReport = runReport;
        
        var gridParams = {
            gridOptions: {},
            gridState: gridState($rootScope.user.subscriberId, 'usersReport'),
            defs: getColDefs(),
            exportOptions: { fileName: 'Users Report' }
        }

        function getColDefs() {
            var colDefs = [];
            colDefs.push(gridService2.colDef.createText('subscriberName', 'Subscriber', 'subscriberName', {sort: 'asc'}));
            colDefs.push(gridService2.colDef.createText('username', 'Username', 'username', {sort: 'asc'}));
            colDefs.push(gridService2.colDef.createText('firstName', 'First Name', 'firstName', {}));
            colDefs.push(gridService2.colDef.createText('lastName', 'Last Name', 'lastName', {}));
            colDefs.push(gridService2.colDef.createText('email', 'E-mail', 'email', {}));
            colDefs.push(gridService2.colDef.createText('securityGroupName', 'Security Group', 'securityGroupName', {}));
            colDefs.push(gridService2.colDef.createDate('lastSignOn', 'Last Login Date', 'lastSignOn', {}));
            colDefs.push(gridService2.colDef.createBoolean('isAdept', 'ADEPT', 'isAdept', {}));
            colDefs.push(gridService2.colDef.createBoolean('isBrite', 'BRITE', 'isBrite', {}));
            colDefs.push(gridService2.colDef.createBoolean('isAMS', 'AMS', 'isAMS', {}));
            return colDefs;
        }
        function init() {
            gridService2.createGrid(gridParams)
                .then(function (grid) {
                    grid.setRows(Promise.resolve());
                    $scope.grid = grid;
                    if ($scope.isSysAdmin) {
                        runReport();
                    }
                });
        }
        function runReport() {
            var rowPromise = adminService.getUsersReport()
                .then(function (data) {

                    data.forEach(function (row) {
                        row.lastSignOn = row.lastSignOn ? moment(row.lastSignOn).format("MM/DD/YYYY") : row.lastSignOn;

                    });
                    return data;
                });

            var promise = $scope.grid.setRows(rowPromise);

            return promise;
        }

        init();
    }
]);