'use strict';
angular.module('angus.controllers').controller('subRegAwdMappingsCtrl', [
	'$scope', '$http', '$q', '$timeout', '_', 'Paging', 'modalService', 'actionViewTemplates', 'promiseMonitor', '$',
	function($scope, $http, $q, $timeout, _, Paging, modalService, actionViewTemplates, PromiseMonitor, $) {
		var pageSize          = 25;
		$scope.driverId       = 'id';
		$scope.promiseMonitor = new PromiseMonitor();
		$scope.saveMonitor = new PromiseMonitor();
		$scope.stagedChanges  = {
				drivers: [],
				trucks: []
		};
		$scope.backup  = {
				drivers: [],
				trucks: []
		};

		$scope.alerts = [];

		function init(){
			$scope.activeTab       = $scope.pageManager.drivers;
			$scope.activeSystemKey = $scope.awdSourceSystemKeys[0].id;
			getData();
		}

		$scope.filtersChanged = function(){
			getData();
		}

		function getData(){
			$q.all([
					getDriverData(),
		            getAbosDriverData(),
		            getTruckData(),
		            getAbosTruckData()
			]).then(function(){

					$scope.updateSelectedDrivers();
					$scope.updateSelectedTrucks();
				});
		}

		function getDriverData(pageNumber){
			var promise = $scope.pageManager.drivers.paging.
						getPage(('api/subscribers/{0}/awdMappings/drivers?sourceSystemKey=' +
							$scope.activeSystemKey )
							.format($scope.subscriberId),
				pageNumber, {
					sortProp: $scope.pageManager.drivers.sorting.sortProp,
					sortDir: $scope.pageManager.drivers.sorting.sortDir
				})
				.then(function(drivers){
					$scope.backup.awdDrivers = _.cloneDeep(drivers.items);
					$scope.awdDrivers = drivers.items;
				});

				$scope.promiseMonitor.monitor(promise);
				return promise;
		}

		function getAbosDriverData(){
			var promise = $http.get(('api/subscribers/{0}/awdMappings/abosDrivers?sourceSystemKey=' +
								$scope.activeSystemKey )
								.format($scope.subscriberId))
				.then(function(drivers){
					$scope.abosDrivers = drivers.data;
				});

			$scope.promiseMonitor.monitor(promise);
			return promise;
		}

		function getTruckData(pageNumber){
			// query server to get data
			var promise = $scope.pageManager.trucks.paging.
						getPage(('api/subscribers/{0}/awdMappings/trucks?sourceSystemKey=' +
							$scope.activeSystemKey )
							.format($scope.subscriberId),
				pageNumber, {
					sortProp: $scope.pageManager.trucks.sorting.sortProp,
					sortDir: $scope.pageManager.trucks.sorting.sortDir
				})
				.then(function(trucks){
					$scope.backup.awdTrucks = _.cloneDeep(trucks.items);
					$scope.awdTrucks = trucks.items;
				});

			$scope.promiseMonitor.monitor(promise);
			return promise;
		}

		function getAbosTruckData(){
			// query server to get data
			var promise = $http.get(('api/subscribers/{0}/awdMappings/abosTrucks?sourceSystemKey=' +
								$scope.activeSystemKey )
								.format($scope.subscriberId))
				.then(function(trucks){
					$scope.abosTrucks = trucks.data;
				});

			$scope.promiseMonitor.monitor(promise);
			return promise;
		}

		$scope.sort = function(propName) {
			if (propName != $scope.activeTab.sorting.sortProp)
				$scope.activeTab.sorting.sortDir = 'asc';
			else
				$scope.activeTab.sorting.sortDir = $scope.activeTab.sorting.sortDir == 'asc' ?
									'desc' : 'asc';

			$scope.activeTab.sorting.sortProp = propName;

			$scope.activeTab.queryFn($scope.activeTab.paging.currentPage)
		};

		$scope.getNextPage = function(pageNumber){
			$scope.activeTab.queryFn(pageNumber);
		};

		$scope.saveMappings= function(){

			var promise = $http
							.put(('api/subscribers/{0}/awdMappings/' + $scope.activeTab.uriName + '?sourceSystemKey=' + $scope.activeSystemKey)
							.format($scope.subscriberId), $scope.stagedChanges[$scope.activeTab.uriName])
			.then(function(response){
				if(response.error){
					$scope.alerts.push({
						type : 'danger',
						msg  : '<strong>Error!</strong> ' + (response.msg || 'Save was unsuccessful!')
					});
				} else {
					$scope.alerts.push({
						type : 'success',
						msg  : '<strong>Success!</strong> ' + (response.msg || 'Your ' + $scope.activeTab.uriName + ' were updated!')
					});

					$scope.stagedChanges[$scope.activeTab.uriName] = [];
				}

				closePrevAlert();
			});

			$scope.saveMonitor.monitor(promise);
		};

		$scope.closeAlert = function(index) {
        	$scope.alerts.splice(index, 1);
    	};

		function closePrevAlert() {
			if($scope.alerts.length > 1){
				$timeout(function() {
					$scope.alerts.splice(0, 1);
				}, 1000);
			}
		}

		function updateDropDownItems(item, className){
			var items = $('.' + className)
			_.forEach(items, function(dropDownList) {
					$(dropDownList).find('option[value="number:' + item.mapping + '"]').attr('disabled', 'disabled');
				});
		}

		$scope.updateSelectedDrivers = function(awdDriver){
				if(awdDriver)
					$scope.stagedChanges.drivers.push(awdDriver);

				$scope.selectedDrivers = _.filter($scope.abosDrivers, function(driver){
						return _.some($scope.awdDrivers, function(awdDriver){
							return awdDriver.mapping == driver.id;
					});
				});
			};

		$scope.updateSelectedTrucks = function(awdTruck){
				if(awdTruck)
					$scope.stagedChanges.trucks.push(awdTruck);

				$scope.selectedTrucks = _.filter($scope.abosTrucks, function(truck){
						return _.some($scope.awdTrucks, function(awdTruck){
							return awdTruck.mapping == truck.id;
					});
				});
			};

		$scope.pageManager = {
			drivers: {
				uriName: 'drivers',
				sorting: {
					sortProp : 'id',
					sortDir  : 'asc'
				},
				paging: new Paging(pageSize),
				queryFn: getDriverData,
				abosProperty: 'abosDrivers',
				awdProperty: 'awdDrivers',
				headings: [{
					name         : 'Source Driver ID',
					isHidable    : false,
					propName     : 'id',
					isSortable   : true
				}, {
					name         : 'Source Driver Name',
					isHidable    : false,
					propName     : 'name',
					isSortable   : true
				},{
					name         : 'Source Driver Active Status',
					isHidable    : false,
					propName     : 'activeSortProp',
					isSortable   : true
				},{
					name         : 'Driver',
					isHidable    : false,
					propName     : 'mapping',
					isSortable   : false
				}]
			},
			trucks: {
				uriName: 'trucks',
				sorting: {
					sortProp : 'id',
					sortDir  : 'asc'
				},
				paging: new Paging(pageSize),
				queryFn: getTruckData,
				abosProperty: 'abosTrucks',
				awdProperty: 'awdTrucks',
				headings: [{
					name         : 'Source Truck ID',
					isHidable    : false,
					propName     : 'id',
					isSortable   : true
				}, {
					name         : 'Source Truck Name',
					isHidable    : false,
					propName     : 'name',
					isSortable   : true
				}, {
					name         : 'Source Truck Active Status',
					isHidable    : false,
					propName     : 'activeSortProp',
					isSortable   : true
				},{
					name         : 'Truck',
					isHidable    : false,
					propName     : 'mapping',
					isSortable   : false
				}]
			}
		};
		init();
	}
]);
