angular.module( 'angus.directives' ).directive( 'dateRangeStart', [ '$', '$compile', function( $, $compile ) {
	return {
		restrict: 'A',
		scope: {
			dateRange: '=dateRangeStart'
		},
		link: function( scope, elem, attrs){
			var html = ' <input type="text" ' +
							   'class="form-control"  ' +
							   'uib-datepicker-popup="MM dd, yyyy"  ' +
							   'data-ng-model="dateRange.start"  ' +
							   'is-open="dateRange.startOpened"  ' +
							   'datepicker-options="dateRange.dateOptions"  ' +
							   'close-text="Close"  ' +
							   'data-ng-change="dateRange.resetEnd()"';

			if(scope.dateRange.max) html += 'max-date="dateRange.max"';

			if(attrs.required) html += 'required';

			html+='/>';

            html+=	'<span class="input-group-btn"> '+
                	  	'<button class="btn btn-default" data-ng-click="dateRange.openStart($event)"><i class="fa fa-calendar"></i> '+
                	  	'</button> '+
            	   	 '</span>';

        	var e =$compile(html)(scope);
        	elem.replaceWith(e);
		}
	};
}]).directive( 'dateRangeEnd', [ '$', '$compile', function( $, $compile ) {
	return {
		restrict: 'A',
		scope: {
			dateRange: '=dateRangeEnd'
		},
		link: function( scope, elem, attrs){
			var html = ' <input type="text"  ' +
							   'class="form-control"  ' +
							   'uib-datepicker-popup="MM dd, yyyy"  ' +
							   'data-ng-model="dateRange.end"  ' +
							   'is-open="dateRange.endOpened"  ' +
							   'datepicker-options="dateRange.dateOptions"  ' +
							   'close-text="Close"  ' +
							   'min-date="dateRange.start"';

			if(scope.dateRange.max) html += 'max-date="dateRange.max"';

			if(attrs.required) html += 'required';

			html += '/>';

            html +=	'<span class="input-group-btn"> '+
                	  	'<button class="btn btn-default" data-ng-click="dateRange.openEnd($event)"><i class="fa fa-calendar"></i> '+
                	  	'</button> '+
            	   	 '</span>';

        	var e =$compile(html)(scope);
        	elem.replaceWith(e);
		}
	};
}]);