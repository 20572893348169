angular.module('angus.controllers').controller('batchDeliveriesReportContentCtrl', [
    '$scope', '$rootScope', '$document', '$http', 'drillService', 'gridService2', 'gridState', '_', 'moment',
    function($scope, $rootScope, $document, $http, drillService, gridService2, gridState, _, moment) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.$watch(function() {
            return $scope.activeItem;
        }, function(newVal) {
            if (!newVal.grid) {
                initBatchDeliveriesReport();
            }
        });

       function getColClicks(){
            return {
                accountNumber   : {
                    onClick : function(row){ drillService.openAccountInfo(row, drillService.context.delivery); }
                },
                tankNumber      : {
                    onClick : function(row){ drillService.openTankInfo(row, drillService.context.delivery); }
                },
                kFactorVariance : {
                    onClick : function(row){ if(row.deliveryKey) drillService.openKFactorInfo(row); },
                    isNotClickable : function(params){
                        return !params.data || params.data.deliveryKey;
                    }
                },
                adjIdealDrop    : {
                    onClick : function(row){ if(row.deliveryKey) drillService.openAdjustedPercentInfo(row); },
                    isNotClickable : function(params){
                        return !params.data || params.data.deliveryKey;
                    }
                }
            };
        }

        function getColDefs(){
            return [
                gridService2.colDef.createTime('deliveryTime', 'Delivery Time', 'deliveryTime', 'h:mm A', {sort :'asc'}),
                gridService2.colDef.createText('deliveryTypeName', 'Delivery Type', 'deliveryTypeName'),
                gridService2.colDef.createText('customerName', 'Customer Name', 'customerName'),
                gridService2.colDef.createText('accountNumber', 'Account Number', 'accountNumber', { cellClass : ['center']}),
                gridService2.colDef.createText('tankNumber', 'Tank Number', 'tankNumber', { cellClass : ['center']}),
                gridService2.colDef.createNumber('tankSize', 'Tank Size', 'tankSize'),
                gridService2.colDef.createText('productName', 'Product', 'productName'),
                gridService2.colDef.createNumber('units', 'Units Delivered', 'units'),
                gridService2.colDef.createNumber('idealDrop', 'Ideal Drop', 'idealDrop'),
                gridService2.colDef.createPercentage('percentageOfIdealDrop', '% Ideal Drop Delivered', 'percentageOfIdealDrop'),
                gridService2.colDef.createPercentage('adjIdealDrop', 'Adjusted % Ideal Drop', 'adjIdealDrop'),
                gridService2.colDef.createPercentage('preDeliveryPercentFull','Start %','preDeliveryPercentFull'),
                gridService2.colDef.createPercentage('postDeliveryPercentFull','End %','postDeliveryPercentFull'),
                gridService2.colDef.createBoolean('preDeliveryUnitsCalculatedFlag','Start % Est','preDeliveryUnitsCalculatedFlag'),
                gridService2.colDef.createBoolean('postDeliveryUnitsCalculatedFlag','End % Est','postDeliveryUnitsCalculatedFlag'),
                gridService2.colDef.createPercentage('kFactorVariance', 'K-Factor Variance', 'kFactorVariance'),
                gridService2.colDef.createBoolean('partialFillFlag', 'Partial Fill', 'partialFillFlag'),
                gridService2.colDef.createBoolean('runOutFlag', 'Run Out', 'runOutFlag'),
                gridService2.colDef.createBoolean('potentialRunOutFlag','Potential Run Out','potentialRunOutFlag'),
                gridService2.colDef.createBoolean('monitored', 'Tank Monitor', 'monitored'),
                gridService2.colDef.createText('deliveryGroup', 'Delivery Group', 'deliveryGroup')
            ];
        }

        function initBatchDeliveriesReport() {
             var gridParams = {
                gridState     : gridState(subscriberId, 'batchDelvieries'),
                defs          : getColDefs(),
                clicks        : getColClicks(),
                exportOptions : { fileName: 'Batch Deliveries' }
            };

            return gridService2
                .createGrid(gridParams)
                .then(function(grid){
                    $scope.activeItem.grid = grid;
                    $scope.getDeliveries();
                });
        }

        $scope.getDeliveries = function() {
            var rowPromise = $http
                .get('api/subscribers/{0}/batches/deliveries/{1}'.format($rootScope.user.subscriberId, $scope.activeItem.params.deliveryBatchKey))
                .then(function(result){
                    return _.map(result.data, function(d){
                      d.deliveryTime = d.deliveryTime ? moment.utc(d.deliveryTime).format('LT') : '';
                      return d;
                    });
                });

            var promise = $scope.activeItem.grid.setRows(rowPromise);
            return promise;
        };
    }
]);
