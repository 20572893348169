angular.module('angus.services').factory('importFileService', [
    '$http', function ($http) { 

        const getImportRecords = function(paging, pageNumber, pagingParams) {
            return paging.postGetPage('api/import/fileImport/retrieve', pageNumber, pagingParams)
            .then(function(records) {
                return records;
            }); 
        }

        const getSignedURL = function(data){
            return $http({
                method: 'POST',
                url: ('api/import/storageURL'),
                data
            }).then(function(response) {
                return response.data;
            });
        }
        
        const uploadFile = function(file, url) {
            return $http({
                method: 'PUT',
                url,
                headers: {
                    'Content-Type': file.type,
                },
                data: file
            }).then(function(response) {
                var url = response.config.url.split("?X-Goog-Algorithm")[0];
                return url;
            })
        };

        const upsertImportRecord = function(deferImport, fileImportKey, fileImportURL, subscriberID, importSubscriberID, originalFileName, fileImportDataTypeKey, fileImportStatusKey, fileImportUser, fileImportMessagesJSON, data){
            return $http({
                method: 'POST',
                url: ('api/import/processFile'),
                data: {
                    deferImport, 
                    fileImportKey,
                    fileImportURL,
                    subscriberID,
                    importSubscriberID,
                    originalFileName,
                    fileImportDataTypeKey,
                    fileImportStatusKey,
                    fileImportUser,
                    fileImportMessagesJSON,
                    data
                }
            }).then(function(response) {
                return response.data;
            });
        }

        const uploadMonitorInventory = function(url, file) {
            return $http.put(url, file, {headers: {'Content-Type': file.type}})
            .then(function (res) {
                return res;
            });
        } 

        const formatMonitorInventoryMessage = function(errors) {
            var messages = [];
            for (let index = 0; index < errors.length; index++) {
               let header = ''; let message = '';
               const error = errors[index];
               if (error.MonitorSerialNumber) {
                  header = `Serial ${error.MonitorSerialNumber}`;
               }
               if (error.SIMNumber) {
                  header = `Sim ${error.SIMNumber}`;
               }
               if (error.SNDuplicateInList || error.SIMDuplicateInList) {
                  message += 'Duplicated in File; ';
               }
               if (error.SNInDatabase || error.SIMInDatabase) {
                  message += 'Already Exists in Inventory; ';
               }
               messages.push({ header, message });
            };
            return messages;
         }

        return { 
            getImportRecords,
            getSignedURL,
            uploadFile,
            upsertImportRecord,
            uploadMonitorInventory,
            formatMonitorInventoryMessage
        };
     }
 ]);
 
 