angular.module('angus.services').service('degreeDayService', [
    '$http', '$rootScope',
    function ($http, $rootScope) {

        function getDegreeDayHeaders(subscriberId) {
            return queryDegreeDayHeaders(subscriberId)
                .then(function (res) {
                    return res.data;
                });
        }

        function getDegreeDaySources(subscriberId) {
            return queryDegreeDaySources(subscriberId)
                .then(function (res) {
                    return res.data;
                });
        }

        function getDeliveryMetrics(subscriberId, startDate, endDate, degreeDaySourceTypeId, degreeDayHeaderId, divisionIds,  productCategoryIds, deliveryTypeIds, tradeClassIds, containerTypeIds, includeMonitoredTanks, includeAdeptTransactions, includeGroupOptimizedTransactions) {
            return queryDeliveryMetrics(subscriberId, startDate, endDate, degreeDaySourceTypeId, degreeDayHeaderId, divisionIds,  productCategoryIds, deliveryTypeIds, tradeClassIds, containerTypeIds, includeMonitoredTanks, includeAdeptTransactions, includeGroupOptimizedTransactions)
                .then(function (res) {
                    return res.data;
                });
        }



        function queryDegreeDayHeaders(subscriberId) {
            return $http.get(('api/subscribers/{0}/degreeDays/headers/').format(subscriberId || $rootScope.user.subscriberId));
        }

        function queryDegreeDaySources(subscriberId) {
            return $http.get(('api/subscribers/{0}/degreeDays/headers/sources').format(subscriberId || $rootScope.user.subscriberId));
        }

        function queryDeliveryMetrics(subscriberId, startDate, endDate, degreeDaySourceTypeId, degreeDayHeaderId, divisionIds, productCategoryIds, deliveryTypeIds, tradeClassIds, containerTypeIds, includeMonitoredTanks, includeAdeptTransactions, includeGroupOptimizedTransactions) {
            return $http({
                url: ('api/subscribers/{0}/degreeDays/deliveryMetrics').format(subscriberId || $rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    startDate: startDate ? startDate.format() : null,
                    endDate: endDate ? endDate.format() : null,
                    divisionIds: divisionIds,
                    productCategoryIds: productCategoryIds,
                    deliveryTypeIds: deliveryTypeIds,
                    tradeClassIds: tradeClassIds,
                    degreeDaySourceTypeId: degreeDaySourceTypeId,
                    degreeDayHeaderId: degreeDayHeaderId,
                    containerTypeIds: containerTypeIds,
                    includeMonitoredTanks: includeMonitoredTanks || 'Y',
                    includeAdeptTransactions: includeAdeptTransactions || 'Y',
                    includeGroupOptimizedTransactions: includeGroupOptimizedTransactions || 'Y'
                }
            });
        }

        function getMetrics() {
            /* 
                isAvailable: Do we show the metric as an option on the widget?
                isActive: Has the metric been toggled on the widget?
                isDisabled: Can the user interact with the Metric. Used to disable the option in the settings screen.
            */
            return [
                {
                    id: 1,
                    code: 'deliveries',
                    label: 'Number of Deliveries',
                    fractionSize: 0,
                    columnName: 'deliveryCount',
                    isAvailable: false,
                    isActive: false,
                    isDisabled: false,
                    isHoursMetric: false,
                    isPerDegreeDayMetric: false,
                    color: '#81C784',
                    displayOrder: 1,
                    additionalTooltipMetricIds: [6]
                },
                {
                    id: 2,
                    code: 'gallons',
                    label: 'Number of Gallons',
                    fractionSize: 0,
                    columnName: 'units',
                    isAvailable: false,
                    isActive: false,
                    isDisabled: false,
                    isHoursMetric: false,
                    isPerDegreeDayMetric: false,
                    color: '#e57b1e',
                    displayOrder: 3,
                    additionalTooltipMetricIds: [7]
                },
                {
                    id: 3,
                    code: 'gallonsPerStop',
                    label: 'Gallons Per Stop',
                    fractionSize: 0,
                    columnName: 'gallonsPerStop',
                    isAvailable: false,
                    isActive: false,
                    isDisabled: false,
                    isHoursMetric: false,
                    isPerDegreeDayMetric: false,
                    color: '#1ee5de',
                    displayOrder: 5,
                    additionalTooltipMetricIds: []
                },
                {
                    id: 4,
                    code: 'gallonsPerHour',
                    label: 'Gallons Per Hour',
                    fractionSize: 0,
                    columnName: 'unitsPerHour',
                    isAvailable: false,
                    isActive: false,
                    isDisabled: false,
                    isHoursMetric: true,
                    isPerDegreeDayMetric: false,
                    color: '#FDD835',
                    displayOrder: 6,
                    additionalTooltipMetricIds: []
                },
                {
                    id: 5,
                    code: 'stopPerHour',
                    label: 'Stops Per Hour',
                    fractionSize: 2,
                    columnName: 'stopsPerHour',
                    isAvailable: false,
                    isActive: false,
                    isDisabled: false,
                    isHoursMetric: true,
                    isPerDegreeDayMetric: false,
                    color: '#B39DDB',
                    displayOrder: 7,
                    additionalTooltipMetricIds: []
                },
                {
                    id: 6,
                    code: 'deliveriesPerDegreeDay',
                    label: 'Deliveries Per Degree Day',
                    fractionSize: 2,
                    columnName: 'deliveriesPerDegreeDay',
                    isAvailable: false,
                    isActive: false,
                    isDisabled: false,
                    isHoursMetric: false,
                    isPerDegreeDayMetric: true,
                    color: '#388e3d',
                    displayOrder: 2,
                    additionalTooltipMetricIds: [1]
                },
                {
                    id: 7,
                    code: 'gallonsPerDegreeDay',
                    label: 'Gallons Per Degree Day',
                    fractionSize: 0,
                    columnName: 'gallonsPerDegreeDay',
                    isAvailable: false,
                    isActive: false,
                    isDisabled: false,
                    isHoursMetric: false,
                    isPerDegreeDayMetric: true,
                    color: '#e51e25',
                    displayOrder: 4,
                    additionalTooltipMetricIds: [2]
                }
            ];
        }



        return {
            /* Degree Day Headers only */
            getDegreeDayHeaders: getDegreeDayHeaders,

            /* Degree Day Headers and Postal Codes */
            getDegreeDaySources: getDegreeDaySources,

            /* Degree Day Metrics Widget Dashboard View */
            getDeliveryMetrics: getDeliveryMetrics,

            /* Metadata about all of the metrics that will be compared against Degree Days */
            getMetrics: getMetrics
        };
    }
]);