angular.module('angus.directives').directive('form', ['$',
    function($) {
        return {
            restrict: 'A',

            link: function(scope, element, attr) {
                var firstInput = $(element).find('input:first');
                if ($('form:first').index() == $(element).index()) {
                    scope.focusedElement = $(firstInput).attr('name');
                    $(firstInput).focus();
                }

                scope.$watch('activeSection', function(value) {
                    var form = $(element).find('.formSection');
                    if (value != attr.form) {
                        $(form).removeClass('active');
                    } else {
                        $(form).addClass('active');
                        $(firstInput).focus();
                    }
                });
            }
        };
    }
])
.directive('disableNgAnimate', ['$animate', function($animate) {
  return {
    restrict: 'A',
    link: function(scope, element) {
      $animate.enabled(false, element);
    }
  };
}]);

//angular.module('angus.directives').directive('formItem', [
//    '$parse', function($parse) {
//        return {
//            restrict: 'A',
//            link: function(scope, element, attr) {
//                var formItem = null;
//                var error = $($(element).siblings('[data-form-item-error]')[0]);
//                var hovering = false;

//                $(element).addClass("form-control");
//                element.bind('blur', function() {
//                    scope.focusedElement = "";
//                });

//                attr.$observe('formItem', function(value) {
//                    formItem = value;
//                });

//                element.bind('focus', function() {
//                    var name = (formItem == null) ? attr.name : attr.name + formItem;
//                    scope.focusedElement = name;
//                });

//                element.bind('mouseenter', function() {
//                    if (element.hasClass('ng-dirty') && element.hasClass('ng-invalid')) {
//                        $(error).addClass('active');
//                        hovering = true;
//                    }
//                });
//                scope.$watch(function() { return element.attr('class'); }, function() {
//                    if (element.hasClass('ng-dirty') && element.hasClass('ng-invalid') && hovering) {
//                        $(error).addClass('active');
//                    } else {
//                        $(error).removeClass('active');
//                    }
//                });

//                element.bind('mouseleave', function() {
//                    $(error).removeClass('active');
//                    hovering = false;
//                });

//                scope.$watch($parse(attr.ngModel), function(value) {
//                    if (value != "" && value != null) {
//                        $(error).addClass('hasValue');
//                    } else {
//                        $(error).removeClass('hasValue');
//                    }
//                });
//            }
//        };
//    }
//]);
angular.module('angus.directives')
    .directive('fancyValidation', ['$', '$timeout', function($, $timeout) {
        var link = function($scope, $element, $attrs, formCtrl) {
            var toggleClasses;
            var blurred = false;
            var input = $element[0].querySelector('[name]');
            var inputElement = angular.element(input);
            var inputName = inputElement.attr('name');
            var inputRequired = inputElement.prop('required');

            //Gentlemen....i'm not messing around.  Follow the html spec
            if (!inputName)
                throw 'fancy-validation requires a child input element with a \'name\' attribute';

            //Wrap the input element so that we can place the icon
            var wrapper = angular.element('<div class="left-inner-addon"></div>');

            //Build the icon
            var icon = angular.element('<i class="fa"></i>');
            if (inputRequired)
                icon.addClass('fa-asterisk');
            icon.appendTo(wrapper);
            wrapper.insertBefore(inputElement);

            //Build the error message
            var error = angular.element(inputElement.siblings('[data-form-item-error]')[0]);
            inputElement.appendTo(wrapper);
            error.appendTo(wrapper);

            $scope.$on('show-errors-check-validity', function() {
                return toggleClasses(formCtrl[inputName].$invalid);
            });
            $scope.$on('show-errors-reset', function() {
                return $timeout(function() {
                    inputElement.removeClass('has-error');
                    inputElement.removeClass('has-success');
                    return blurred = false;
                }, 0, false);
            });
            inputElement.bind('focus', function() {
                if (formCtrl[inputName].$dirty && formCtrl[inputName].$invalid)
                    error.addClass('active');
            });

            inputElement.bind('mouseenter', function() {
                if (formCtrl[inputName].$dirty && formCtrl[inputName].$invalid)
                    error.addClass('active');
            });

            inputElement.bind('mouseleave', function() {
                error.removeClass('active');
            });

            inputElement.bind('blur', function() {
                blurred = true;
                error.removeClass('active');
                return toggleClasses(formCtrl[inputName].$invalid);
            });
            $scope.$watch(function() {
                return formCtrl[inputName] && formCtrl[inputName].$invalid;
            }, function(invalid) {
                error.removeClass('active');
                return toggleClasses(invalid);
            });

            return toggleClasses = function(invalid) {
                inputElement.toggleClass('has-error', invalid);

                var hasValue = formCtrl[inputName].$viewValue !== undefined && formCtrl[inputName].$viewValue !== "";

                icon.toggleClass('text-danger', invalid);
                icon.toggleClass('text-success', !invalid);

                icon.toggleClass('fa-exclamation-triangle', hasValue && invalid);
                icon.toggleClass('fa-asterisk iconSmall', inputRequired && !hasValue && invalid);
                icon.toggleClass('fa-check', hasValue && !invalid);

                return inputElement.toggleClass('has-success', !invalid);
            };
        };
        return {
            restrict: 'A',
            require: '^form',
            compile: function($element, $attrs) {

                //I'm tired of folks not using forms appropriately.  You can't use this unless you have a form-group and a form
                // when creating inputs inside of a table adding the form-group messes with the inputs width - adding additional class
                if (!$element.hasClass('form-group') && !$element.hasClass('form-item'))
                    throw 'fancy-validation element requires the \'form-group\' or \'form-item\' class';

                return link;
            }
        };
    }])
    .directive('formItem', [
        '$parse', '$compile', '$',
        function($parse, $compile, $) {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function(scope, element, attr, ngModel) {


                    var wrapper = angular.element('<div class="left-inner-addon"></div>');

                    var icon = angular.element('<i class="fa"></i>');
                    icon.appendTo(wrapper);

                    wrapper.insertBefore(element);
                    var error = $($(element).siblings('[data-form-item-error]')[0]);
                    element.appendTo(wrapper);
                    error.appendTo(wrapper);


                    var required = 'fa fa-asterisk text-danger iconSmall';
                    var valid = 'fa fa-check text-success';
                    var invalid = 'fa fa-exclamation-triangle text-danger';

                    if (element.prop('required')) {
                        icon.addClass(required);
                    }
                    var formItem = null;

                    var focused = false;


                    attr.$observe('formItem', function(value) {
                        formItem = value;
                    });


                    element.bind('focus', function() {
                        var name = (formItem === null) ? attr.name : attr.name + formItem;
                        scope.focusedElement = name;
                        if (element.hasClass('ng-dirty') && element.hasClass('ng-invalid')) {
                            $(error).addClass('active');
                            focused = true;
                        }
                    });

                    element.bind('mouseenter', function() {
                        if (element.hasClass('ng-dirty') && element.hasClass('ng-invalid')) {
                            $(error).addClass('active');

                        }
                    });

                    element.bind('mouseleave', function() {
                        $(error).removeClass('active');
                    });

                    scope.$watch(function() {
                        return element.attr('class');
                    }, function(newClasses, oldClassess) {
                        if (newClasses != oldClassess) {
                            if (element.hasClass('ng-invalid')) {
                                icon.removeClass();
                                icon.addClass(invalid);
                            }

                            if (element.hasClass('ng-valid')) {
                                icon.removeClass();
                                if (ngModel.$modelValue || ngModel.$modelValue === 0) {
                                    icon.addClass(valid);

                                }
                            }

                            if (element.hasClass('ng-dirty') && element.hasClass('ng-invalid') && focused)
                                $(error).addClass('active');
                            else
                                $(error).removeClass('active');
                        }

                    });


                    element.bind('blur', function() {
                        scope.focusedElement = "";
                        $(error).removeClass('active');
                        focused = false;
                    });

                    scope.$watch(function() {
                        return ngModel.$modelValue;
                    }, function(value) {
                        if (value) {
                            $(error).addClass('hasValue');
                            element.addClass('hasValue');


                            if (element.hasClass('ng-valid')) {
                                icon.addClass(valid);
                            }
                        } else {
                            $(error).removeClass('hasValue');
                            element.removeClass('hasValue');
                            if (element.hasClass('ng-valid')) {
                                icon.removeClass(valid);
                            }
                        }
                    });
                }
            };
        }
    ]);

angular.module('angus.directives').directive('formItemError', ['$',
    function($) {
        return {
            restrict: 'A',
            link: function(scope, element, attr) {
                $(element).addClass('arrow_box');
            }
        };
    }
]);
