angular.module('angus.controllers').controller('paygoTMSAdminMonitorGeometryCtrl', [
	'$scope', '$rootScope', '$timeout', '$interval', '$window', 'fluentRest', 'Throttler', '$http', 'actionViews', '_',
	function($scope, $rootScope, $timeout, $interval, $window, fluentRest, Throttler, $http, actionViews, _) {
    'use strict';
          
    $scope.monitorTypes = null;
    $scope.shapeTypes = null;
    $scope.loadedTankValues = null;
    $scope.selectedShape; 
    $scope.selectedMonitorType = -1; 
    $scope.error = '';
    $scope.dialogOpened = false;
    $scope.dialogTitle = '';
    $scope.dialogType = '';
    $scope.dialogMessage = ''; 
    $scope.tankSelectionIndicator = [];
    $scope.tankSelection = null;
    $scope.selectedTank = null;
    $scope.automatic = false;
    $scope.requestNotMade = true;
    $scope.currentIndex = 0;
    $scope.currentContainer = null;
    $scope.loading = false;
    $scope.selectedContainer = {
      height: 0,
      width: 0,
      length: 0,
      diameter: 0,
      offset: 0,
    };
    $scope.containerGeometryList = [];
    $scope.containerMultiplier = [];
    $scope.monitorTypeList = [];
    $scope.monitorType = [];
    $scope.selectedMultiplier = null;
    $scope.selectedDualTank = false;
    $scope.selectedMonitorTypeImageURL = '';
    $scope.displayInput = false; 
    $scope.slideIndex = 0; 
    $scope.tanksByShape = []; 
    $scope.dualTankForm = {};
    $scope.chosenMonitorTypeName = 'Unknown';
 
    // Next/previous controls
    $scope.plusSlides = function(n) {
      $scope.dialogMessage = "";
      $scope.selectedDualTank = false;
      var slide = $scope.slideIndex += n;     
      //reverse
      if (n == -1 && $scope.slideIndex == -1) {
        slide = $scope.tanksByShape.length - 1;
        $scope.slideIndex = $scope.tanksByShape.length - 1; 
      }
      showSlides(slide);
      loadContainer(slide); 
    } 
  
    $scope.singleton = function() {
      return $scope.currentContainer ? !!$scope.currentContainer.Multiplier : false;
    }
   
    $scope.useDepth = function() {
      return $scope.currentContainer.Title == "Rectangular/Roth";
    }
 
    $scope.automaticDual = function() {  
      return $scope.isPreset()
        ? $scope.currentContainer && !$scope.currentContainer.Multiplier
        : $scope.currentContainer && !!$scope.currentContainer.Multiplier;
    }
   
    $scope.getShapeRequiresHeight = function() {
      return shapeRequiresHeight($scope.currentContainer.ContainerShapeKey);
    }
   
    $scope.getShapeRequiresWidth = function() {
      return shapeRequiresWidth($scope.currentContainer.ContainerShapeKey);
    }
   
    $scope.getShapeRequiresDiameter = function() {
      return shapeRequiresDiameter($scope.currentContainer.ContainerShapeKey);
    } 
    
    $scope.isPreset = function() {    
      return $scope.currentContainer ? $scope.currentContainer.Preset == 1 : false;
    }
   
    $scope.fuelTank = function() {
      var  monitorType = $scope.selectedMonitorType;
      return (monitorType == 2 || monitorType == 5 || monitorType == 3)
          ? "Propane"
          : (monitorType == 7) ? "871" : "NonPropane";
    }
   
    $scope.isNonPropane = function() { 
      return $scope.fuelTank() == "NonPropane";
    }
   
    $scope.getSelectedMonitorTypeImageURL = function() { 
      return $scope.selectedMonitorTypeImageURL ? $scope.selectedMonitorTypeImageURL : 'img/empty-tank.png';
    } 
   
  $scope.onSubmit = function() {
    var data = prepareModel();
    data = updateContainerDimensionWithMultiplier(data); 
		var opts = { 
          method : 'PUT', 
          url : 'api/subscribers/{0}/containers/{1}/monitors'.format(
                  $scope.activeItem.monitor.subscriberId, $scope.activeItem.monitor.tankId
                ), 
          data : data
        };
    return $http(opts)
			.then(function(response){  
        if (response.status == 200) {
              $scope.dialogMessage = "The geometry value has been updated and level will be adjusted on next reading.";
              if ($scope.activeItem.tankGeometry) {
                $scope.activeItem.tankGeometry.message = '';
              }
              $scope.calculatedTankSize = response.data.calculatedTankSize;
        } else { 
          $scope.dialogMessage = 'Failed to save the geometry value!';
        }    
      })
			.catch(function(response){ 
          $scope.dialogMessage = response.errorText || 'Something went wrong';
			}); 
	};
 
  $scope.downloadTutorial = function(){      
    getStorageFile('GREMLIN-Tank_Geometry_Configuration_Instructions.pdf');
  } 
    
  function getStorageFile(filename) {
    var url = ('api/storage'); 
    var data = { filename };
    var promise = $http.post(url, data)
    .success(function(result) {
      if (result) { 
        $window.open(result, '_blank');
      }
      else {
        $window.alert('File not found!');
      }
    })
    .error(function(data, status) {  
        $window.alert('File not found!');
    }); 
  }

    function filter(input, monitorType) { 
      var data = _.filter(input, function(item){ 
        return item.ContainerContentTypeKey == monitorType; 
      });
      return data;
    }

    $scope.getTanksByShape = function() {  
      var tanks =
        $scope.selectedMonitorType == 2 ||
        $scope.selectedMonitorType == 5 ||
        $scope.selectedMonitorType == 3
              ? filter($scope.containerGeometryList, 2)
                :  $scope.selectedMonitorType == 7 ? filter($scope.containerGeometryList, 3) : filter($scope.containerGeometryList, 1); 
      this.tanksByShape = _.sortBy(tanks, 'ContainerGeometrySequence');   
    }  

    $scope.monitorTypesChange = function(monitorTypeKey) {   
      $scope.selectedDualTank = false;
      $scope.dialogMessage = "";
      var originalTankKey = $scope.currentContainer ? $scope.currentContainer.ContainerContentTypeKey : 1;
      
      if (monitorTypeKey) { 
        // * Assigning 750s (Tank Key 1), and 811s (Tank Key 6) to one pool, Oil Tanks
        var  oilTankPool = [1, 6];
        // * Assigning the 643 (Tank Key 2) and the 822s (Tank Key 5) to another pool, Propane Tanks
        var  propaneTankPool = [2, 5]; 
        // * Var for storing the current index. The current index gets set upon monitorTypesChange, and grabs the value prior to the monitor change.
        var numberOfTanks = $scope.selectedMultiplier;
 
        // * Checking the Oil Tank pool first, to see if the newly selected value & original both exist in this pool
        if((oilTankPool.includes(monitorTypeKey) && oilTankPool.includes(originalTankKey) )
            || (propaneTankPool.includes(monitorTypeKey) && propaneTankPool.includes(originalTankKey)) ) 
          { 
          var selectedContainer = $scope.selectedContainer;                 // * Setting the original dimensions to a var  
          showSlides($scope.slideIndex);  // * Setting the carousel for the current idx 
          loadContainer($scope.slideIndex)                          // * Setting the container for current idx
          $scope.selectedMultiplier = numberOfTanks;  

          var isPreset = $scope.currentContainer.Preset;
          if(isPreset < 1) {                                              // * If tank IS preset, then set dimensions from selectedContainer var
            $scope.displayInput = true;
            $scope.selectedContainer = {
              height: selectedContainer.height,
              width: selectedContainer.width,
              length: selectedContainer.length,
              diameter: selectedContainer.diameter,
              offset: selectedContainer.offset,
            }
          } else {
            $scope.displayInput = false;
          }
          // * Checking the Propane Tank pool first, to see if the newly selected value & original both exist in this pool=
        } else {        
          showSlides($scope.slideIndex);                            // * Setting the carousel to idx 0
          loadContainer(0);                                        // * Setting the container to idx 0
          resetSelectedTankOptions();                              // * Resetting tank options only if no conditional is met
          $scope.displayInput = false;
        } 
  
        $scope.selectedMonitorType = monitorTypeKey;  
        var foundMonitorTypeImageURL = _.find($scope.monitorTypeList, function(c){ 
          return c.MonitorTypeKey == monitorTypeKey;
        });
 
        if (foundMonitorTypeImageURL) {
          $scope.selectedMonitorTypeImageURL = foundMonitorTypeImageURL.MonitorTypeImageURL;
        }
      }
      else {
        $scope.selectedMonitorType = -1;
        $scope.selectedMonitorTypeImageURL = ''; 
      }
    } 

    $scope.onNumberofTanksChanged = function(value) {  
      if (value) {
        $scope.selectedMultiplier = value;   
      } 
    } 

    $scope.getNumberOfTank = [ 
        { field: 1 },
        { field: 2 },
        { field: 3 },
        { field: 4 }
      ]; 

    $scope.onDuelTankChange = function(checked) {
      $scope.selectedDualTank = checked;
      $scope.selectedMultiplier = checked ? 2 : 1;  
    }

    $scope.isNewTankType = function() {
      return ($scope.currentContainer.Title == "Cylindrical Vertical 200" ||
              $scope.currentContainer.Title == "Cylindrical Vertical 500" ||
              $scope.currentContainer.Title == "Cylindrical Vertical 2500");
    } 

    $scope.geContainerShapeLabel = function(containerShapeKey) {
      return (containerShapeKey != 1 && 
              containerShapeKey != 2 && 
              containerShapeKey != 6 && 
              containerShapeKey != 7) ? 'Height' : 'Length';  
    }

    $scope.$watch('tanksByShape', function(tankSlides) {  
      if (tankSlides) { 
          $timeout(function() {  
            showSlides($scope.slideIndex);  
          }, 0);
      }   
    });
 
    function showSlides(n) {   
      var i;
      var slides = document.getElementsByClassName("tankSlides");    
      if (slides.length > 0) {
        if (n > slides.length) {
          $scope.slideIndex = 0; 
        }
        if (n < 0) {$scope.slideIndex = slides.length}
        for (i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";
        }  
        if (slides[$scope.slideIndex]) {
          slides[$scope.slideIndex].style.display = "block"; 
        }
        else { 
          $scope.slideIndex = 0;
          slides[$scope.slideIndex].style.display = "block"; 
        }
      }
    }

    function init() {  
			$scope.loading = true;
      $scope.selectedMultiplier = 1;
      $scope.dialogMessage = '';
  
      getPullMonitorAndTanks();  
      getMultiplier();
    }

    init();   

    function getMultiplier() {
      var opts = { method : 'GET',
                  url : 'api/subscribers/{0}/containers/monitors/containerGeometry'.format($scope.activeItem.monitor.subscriberId) };
      return $http(opts).then(function(response){  
        $scope.containerMultiplier = JSON.parse(response.data[0].ContainerGeometry);
      });
    }

    function getPullMonitorAndTanks(){
      var opts = { method : 'GET',
                  url : 'api/subscribers/{0}/containers/monitors/containerGeometry'.format($scope.activeItem.monitor.subscriberId) };
      return $http(opts).then(function(result){
        $scope.containerGeometryList = JSON.parse(result.data[0].ContainerGeometry); 
        $scope.monitorTypeList = JSON.parse(result.data[0].MonitorType);
        //rowStatusKey - active:1, inactive:2, deleted:3
        $scope.monitorTypeList = _.filter($scope.monitorTypeList, function(item){  
          return item.RowStatusKey == 1; 
        });
        $scope.monitorType = JSON.parse(result.data[0].MonitorType); 
        $scope.selectedMonitorTypeImageURL = $scope.monitorType[0].MonitorTypeImageURL;   
 
        getPullGeometry();
      });
    } 
  
    function loadContainer(index){ 
      $scope.getTanksByShape();
      if (index == $scope.tanksByShape.length) {
        index = 0;
      }
      $scope.currentContainer = $scope.tanksByShape[index];  
      if($scope.currentContainer && 
         $scope.currentContainer.ContainerGeometryKey == $scope.loadedTankValues.containerGeometryKey ){ 
        populateModel($scope.loadedTankValues,$scope.selectedMonitorType);
      }else{  
        resetSelectedTankOptions();
      }
      $scope.displayInput = $scope.isPreset() == false;  
    } 

    function resetSelectedTankOptions() {
      //reset the dueltank event
      $scope.selectedDualTank = false;
      $scope.selectedMultiplier = 1; 
      $scope.selectedContainer = {
        height: 0,
        width: 0,
        length: 0,
        diameter: 0,
        offset: 0,
      }
    } 

    function getPullGeometry() { 
      var opts = { 
          method : 'GET',
          url : 'api/subscribers/{0}/containers/{1}/monitors'.format(
              $scope.activeItem.monitor.subscriberId,
              $scope.activeItem.monitor.id
            ) 
        };
      return $http(opts)
      .then(function(response){   
        var geometry = response.data;

        if (geometry) {
          $scope.calculatedTankSize = geometry.calculatedTankSize;
          $scope.selectedMultiplier = geometry.multiplier;  
          $scope.loadedTankValues = geometry; 
          $scope.selectedMonitorType = $scope.activeItem.monitor.monitorTypeKey;
          $scope.getTanksByShape();
          populateModel(geometry,$scope.selectedMonitorType); 
          
          var monitorType = _.find($scope.monitorTypeList, function(c){
            return $scope.selectedMonitorType == c.MonitorTypeKey;
          });
          if (monitorType) {
            $scope.selectedMonitorTypeImageURL = monitorType.MonitorTypeImageURL;
            $scope.chosenMonitorTypeName = $scope.activeItem.monitor.monitorTypeName;
          }

          if ($scope.slideIndex > -1) {
            loadContainer($scope.slideIndex)  
          }
          $scope.loading = false;
        }
        else {
          $scope.dialogMessage = 'Failed to load geometry information, please try again.';
        }
      }); 
    }

    function populateModel(geometry,monitorType) {
      if (geometry.shape.name) {  
        $scope.selectedMonitorType = monitorType > 0 ? monitorType : 1;   
        $scope.slideIndex = $scope.tanksByShape.findIndex(x => x.ContainerGeometryKey == geometry.containerGeometryKey);  
        $scope.currentContainer = $scope.tanksByShape[$scope.slideIndex];    
      }
 
      var width = geometry.dimensions ? geometry.dimensions.width : 0;
      var height = geometry.dimensions ? geometry.dimensions.height : 0;
      var diameter = 0;
      var length = geometry.dimensions ? geometry.dimensions.length : 0;

      if (shapeRequiresDiameter(geometry.shape.id)) {
        diameter = width;
      } 
      if (shapeWidthIsHeight(geometry.shape.id)) {
        width = height;
      }   
      if (geometry.multiplier == 2 && $scope.fuelTank() == "NonPropane" && $scope.currentContainer && !!$scope.currentContainer.Preset) {
        $scope.selectedDualTank = true; 
      } else if ($scope.currentContainer && !!$scope.currentContainer.Multiplier && $scope.fuelTank() == "Propane") {
        $scope.selectedMultiplier = geometry.multiplier;   
      } 
      
      $scope.selectedContainer = {
        height: height,
        width: width,
        length: length,
        diameter: diameter,
        offset: geometry.sensorOffset ? geometry.sensorOffset : 0,
      } 
    } 
  
    function prepareModel() { 
      var width = $scope.selectedContainer.width;
      var height = $scope.selectedContainer.height;
      var diameter = $scope.selectedContainer.diameter;
      var length = $scope.selectedContainer.length;
      var offset = $scope.selectedContainer.offset;
      var multiplier = 1;
      var currentMultiplier = multiplier;   
 
      if ($scope.selectedMultiplier) {
        currentMultiplier = $scope.selectedMultiplier;  
      } 
      var currentLength = $scope.isPreset() ? parseFloat($scope.currentContainer.Length) : parseFloat(length); 
      currentLength *= currentMultiplier; 
      var currentDiameter = $scope.isPreset()? parseFloat($scope.currentContainer.Diameter) : parseFloat(diameter); 
      var shape = $scope.currentContainer.ContainerShapeKey;
      var currentWidth =  $scope.isPreset() ? parseFloat($scope.currentContainer.Width) : parseFloat(width); 
      var currentHeight = $scope.isPreset() ? parseFloat($scope.currentContainer.Height) : parseFloat(height); 
 
      if (shapeRequiresDiameter(shape)) {
        currentWidth = currentDiameter;
        if (!$scope.isNewTankType()) {
          currentHeight = currentLength;
        }
      }

      if (shapeWidthIsHeight(shape)) {
        currentHeight = currentWidth;
      }

      var location = 1;
      var antenna = 1;
      var monitorType = $scope.selectedMonitorType;

      if (monitorType !== 1 && monitorType !== 6) {
          location = 2; //Outdoor enclosed
          antenna = 2; //External
      }  
 
      return {
        org: $scope.activeItem.monitor.subscriberId,
        containerId: $scope.activeItem.monitor.tankId,
        shapeId: shape,
        locationId: location,
        monitorAntennaTypeId: antenna,
        monitorTypeKey:  parseInt(monitorType),
        width: currentWidth,
        height: currentHeight,
        length: currentLength, 
        outletHeight: $scope.isPreset() ? parseInt($scope.currentContainer.OutletHeight) : 0,
        safeCapacity: $scope.isPreset() ? parseInt($scope.currentContainer.SafeCapacity) : 100,
        sensorOffset: $scope.isPreset() ? parseInt($scope.currentContainer.SensorOffset) : parseInt(offset),
        container: $scope.activeItem.monitor.id,
        multiplier: currentMultiplier,
        containerGeometryKey: parseInt($scope.currentContainer.ContainerGeometryKey),
        serialNumber: $scope.activeItem.monitor.serialNumber,
        photoUri: $scope.activeItem.monitor.photoUri
      };
  
    } 
  
    function updateContainerDimensionWithMultiplier(payload) {  
      var result = _.find($scope.containerMultiplier, function(container){
        return (container.containerGeometryKey == payload.containerGeometryKey) &&
               (container.containerGeometryMultiplier == $scope.selectedMultiplier)
      }); 
      
      var returnPayload = payload; 
      returnPayload.width = result ? result.width: payload.width;
      returnPayload.height = result ? result.height: payload.height;
      returnPayload.length = result ? result.length: payload.length;
 
      return returnPayload;
    }
 
    function checkIfLevelExistForToday() {
      var startDate = moment(new Date()).format('YYYY-MM-DD');
      var endDate = moment(new Date()).format('YYYY-MM-DD'); 
       
      var opts = { 
        method : 'GET',
        params: { 
          startDate: startDate, 
          endDate: endDate,
          tank: $scope.activeItem.monitor.tankId
        },
        url : ('api/subscribers/{0}/containers/monitors/pullTankReadings')
              .format($scope.activeItem.monitor.subscriberId)
      };
      return $http(opts).then(function(result){   
        // $scope.monitorTypeList = result;   
        return result;
      });  
    }
 
    function shapeRequiresHeight(shapeId) {
      switch (shapeId) {
          case (1):
          case (2):
          case (3):
          case (4):
              return true;
          default:
              return false;
      }
    }

    function shapeRequiresWidth(shapeId) {
      switch (shapeId) {
          case (1):
          case (2):
          case (3):
          case (4):
              return true;
          default:
              return false;
      }
    }

    function shapeRequiresDiameter(shapeId) {  
      switch (shapeId) {
          case (5):
          case (6):
          case (7):
              return true;
          default:
              return false;
      }
    }

    function shapeWidthIsHeight(shapeId) {  
      switch (shapeId) {
          case (6):
          case (7):
              return true;
          default:
              return false;
      }
    } 
	}

]);