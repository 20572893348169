'use strict';

angular.module('angus.services')
	.factory('Paging', [
        '$http', '$q', '_',
        function($http, $q, _) {

			function Paging(pageSize, pageNumber) {
				this.currentPage = pageNumber || 1;
				this.pageSize = pageSize || 10;
				this.totalCount = 0;
				this.pending = false;
			}



			Paging.prototype = {
				getPage: function(url, pageNumber, params, method) {
					if(method){
						var httpMethod = method
					}else {
						var httpMethod = 'GET'
					}
					var deferred = $q.defer(), that = this;

					this.pending     = true;
					this.currentPage = pageNumber || 1;

					params = _.extend(params || {}, {
						pageSize   : this.pageSize,
						pageNumber : this.currentPage
					});

					$http({
							url: url,
							method: httpMethod,
							params: params
						})
						.success(function(pagedEnumeration) {
							if (that.numberOfPages != pagedEnumeration.totalPages)
								that.numberOfPages = pagedEnumeration.totalPages;

							that.totalCount = pagedEnumeration.totalCount;
							that.pending         = false;
							deferred.resolve(pagedEnumeration);
						})
						.error(function(err) {
							that.pending = false;
							deferred.reject(err);
						});

					return $q.when(deferred.promise);
				},
				postGetPage: function(url, pageNumber, postData) {
					var deferred = $q.defer(), that = this;

					this.pending     = true;
					this.currentPage = pageNumber || 1;

					postData = _.extend(postData || {}, {
						pageSize   : this.pageSize,
						pageNumber : this.currentPage
					});

					$http({
							url    : url,
							method : 'POST',
							data   : postData
						})
						.success(function(pagedEnumeration) {
							if (that.numberOfPages != pagedEnumeration.totalPages)
								that.numberOfPages = pagedEnumeration.totalPages;

							that.totalCount = pagedEnumeration.totalCount;
							that.pending         = false;
							deferred.resolve(pagedEnumeration);
						})
						.error(function(err) {
							that.pending = false;
							deferred.reject(err);
						});

					return deferred.promise;
				},
				isPending : function(){
					return this.pending;
				}
			};

			return Paging;
        }
    ]);
