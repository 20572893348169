angular.module('angus.controllers').controller('deliveryController', [
    '$scope', '$rootScope', 'moment', '_', 'modalService', 'actionViewTemplates', 'purchaseOrderService', 'purchaseOrderDeliveryService', 'constantsService', 'importFileService',
    function ($scope, $rootScope,  moment, _, modalService, actionViewTemplates, purchaseOrderService, purchaseOrderDeliveryService, constantsService, importFileService) {
        'use strict';
      
        $scope.isLoading = false; 
        $scope.importActive = false;
        $scope.purchaseOrderDeliverySubmitLoading = false;   
        $scope.alerts = []; 
        $scope.isCreateMode = false; 
        $scope.arePOLoading = false;
        $scope.deliveryData = {};
        $scope.orderDetailForm = {};
        $scope.selectedPO = {};
        $scope.hasNewDetailForm = false;   
        $scope.rowStatus = {
            active: 1,
            delete: 3
        }

        function init() {
            $scope.isLoading = true;
            Promise.all([ 
                $scope.getPurchaseOrderDeliveryHeader()
            ])
            .then(function(results) {
                $scope.isLoading = false;
            });
        } 
 
        //Public Methods ********************************************************************
        $scope.newOrderModel = function() {
            var deliveryData = { 
                purchaseOrderDeliveryHeaderKey   : -1,
                purchaseOrderHeaderKey           : null,
                expectedDeliveryDate 		     : null,
                actualDeliveryDate 		         : null,
                vendorShipDate 		             : null,
                vendorInvoiceID 	             : '',  
                purchaseOrderDeliveryHeaderNote  : '',  
                freightAndInsuranceCost          : null,
                purchaseOrderDeliveryDetails: [] 
            }  
            return deliveryData;
        } 

        $scope.reset = function() { 
            $scope.deliveryData = $scope.newOrderModel(); 
            $scope.isCreateMode = true;
            $scope.isLoading = false;
        }

        $scope.clickAddDelivery = function() {
            $scope.deliveryHeadersDropdown = [{ purchaseOrderDeliveryHeaderKey: -1,  purchaseOrderHeaderID: '<--- Create New --->' }];  
            $scope.deliveryData.purchaseOrderDeliveryHeaderKey = -1;

            $scope.reset(); 
            $scope.getPurchaseOrderHeader(); 
            $scope.hasNewDetailForm = true; 
        } 

        function openImport(name,icon,code,templateUrl,gridHeaders,fileImportDataTypes,subscriber) { 
            var scope = {
                widgetName: name,
                widgetIcon: icon,
                code,
                model: {
                    subscriberId: $rootScope.user.subscriberId,
                    gridHeaders,
                    dataTypeKey: fileImportDataTypes.dataTypeKey,
                    templateURL: fileImportDataTypes.templateURL,
                    product: fileImportDataTypes.product,
                    operation: fileImportDataTypes.operation,
                    subscriber: subscriber ? subscriber : $rootScope.user.subscriberId,
                }
            };

            modalService
                .openActionView(templateUrl, scope, code);
        }

        $scope.openImportMonitorInventory = function() {
            openImport('Monitor Inventory Import', 'cloud-upload', 'import', actionViewTemplates.importFile, 
                constantsService.importHeaders,
                constantsService.fileImportDataTypes.monitorInventory, 'common');
        }; 

        $scope.setFile = function(element){ 
            var purchaseOrderDeliveryDetailKey = element.name;
            var file = element.files[0]; 
            if(!file || !purchaseOrderDeliveryDetailKey){
                document.getElementById('file').value = null;
                return;
            }
            var data = {
                bucketName: constantsService.fileImportDataTypes.monitorInventory.operation,
                fileName: file.name,
                fileType: file.type,
                subscriber: $rootScope.user.subscriberId,
                dataTypeKey: constantsService.fileImportDataTypes.monitorInventory.dataTypeKey
            } 
            importFileService.getSignedURL(data)
            .then(function(response){ 
                uploadFile(response.url[0], file, purchaseOrderDeliveryDetailKey);
            }).catch(function(){
                $scope.importActive = false;
            }); 
        }

        function uploadFile(url, file, purchaseOrderDeliveryDetailKey) {
            $scope.importActive = true;  
            importFileService.uploadMonitorInventory(url, file)
            .then(function(response) {
                var url =  response.config.url.split("?X-Goog-Algorithm")[0]; 
                upsertImportRecord(url, file.name, null, purchaseOrderDeliveryDetailKey);
                document.getElementById('file').value = null; 
                $scope.importActive = false;
            }).catch(function(error) {
                document.getElementById('file').value = null; 
                $scope.importActive = false; 
                $scope.alerts.push({ type: 'danger', error });  
            })
        }; 

        function upsertImportRecord (fileImportURL, originalFileName, message, purchaseOrderDeliveryDetailKey){
            const deferImport = false;
            const fileImportKey = null;
            const subscriberID = $rootScope.user.subscriberId;
            const importSubscriberID = subscriberID;
            const fileImportDataTypeKey = constantsService.fileImportDataTypes.monitorInventory.dataTypeKey;
            const fileImportStatusKey = 1;
            const fileImportUser = $rootScope.user.username;
            const data = {
                purchaseOrderDeliveryDetailKey
            };

            importFileService.upsertImportRecord(deferImport, fileImportKey, fileImportURL, subscriberID, importSubscriberID, originalFileName, fileImportDataTypeKey,  fileImportStatusKey, fileImportUser, message, data)
                .then(function(response) {
                    document.getElementById('file').value = null; 
                    if (response.fileImportMessagesJSON && response.fileImportMessagesJSON.length) {
                        var errors = importFileService.formatMonitorInventoryMessage(response.fileImportMessagesJSON);
                        $scope.alerts.push({ type: 'danger', errors }); 
                    }
                    else { 
                        $scope.alerts.push({ type: 'success', msg: 'Successfully uploaded the data' });
                    } 
                }).catch(function(error) {
                    var errorMsg = 'Failed to upload the data!'; 
                    $scope.alerts.push({ type: 'danger', msg: errorMsg });   
                    document.getElementById('file').value = null; 
                });
        }

        $scope.submitHandler = function(formDelivery, rowStatusKey) {
            $scope.purchaseOrderDeliverySubmitLoading = true;
            $scope.deliveryData.updateUser = $rootScope.user.username;  
            $scope.deliveryData.rowStatusKey = rowStatusKey;  
             
            for (let index = 0; index < $scope.deliveryData.purchaseOrderDeliveryDetails.length; index++) {
                const detail = $scope.deliveryData.purchaseOrderDeliveryDetails[index];
                detail.unitCost = safeSpecialCharacterParse(detail.unitCost); 
                detail.freightAndInsuranceCost = safeSpecialCharacterParse(detail.freightAndInsuranceCost);
                detail.simCost = safeSpecialCharacterParse(detail.simCost);
                detail.clearCost = safeSpecialCharacterParse(detail.clearCost);
                detail.rowStatusKey = rowStatusKey;  
            }
            purchaseOrderDeliveryService.postPurchaseOrderDelivery($scope.deliveryData)
            .then(function(purchaseOrderDeliveryHeaderKey) {  
                if (purchaseOrderDeliveryHeaderKey) {
                    if (rowStatusKey == $scope.rowStatus.active) { 
                        $scope.alerts.push({ type: 'success', msg: 'Successfully Saved' });  
                        $scope.deliveryData.purchaseOrderDeliveryHeaderKey = purchaseOrderDeliveryHeaderKey; 
                        $scope.getPurchaseOrder(purchaseOrderDeliveryHeaderKey); 
                    }
                    else { 
                        $scope.alerts.push({ type: 'success', msg: 'Successfully Deleted' }); 
                    }
                    $scope.getPurchaseOrderDeliveryHeader();   
                    if (formDelivery) {
                        formDelivery.$setPristine(); 
                        $scope.isCreateMode = true;
                    } 
                }
                else { 
                    $scope.alerts.push({type: 'danger', msg: 'Failed to Submit, Please check your data!'});  
                } 
            })
            .catch(function(){
                $scope.alerts.push({type: 'danger', msg: 'Failed to Submit, Please check your data!'}); 
            });
    
            $scope.cancelHandler(formDelivery);
            $scope.purchaseOrderDeliverySubmitLoading = false;
            $scope.isCreateMode = false;
        }

        $scope.cancelHandler = function(form) {
            if (form) {
                form.$setPristine();
                form.$setUntouched();
            } 
             
            $scope.reset();
            $scope.deliveryData = {};  
            $scope.alerts = [];
            $scope.isCreateMode = false;
            $scope.getPurchaseOrderDeliveryHeader();
        }  

        $scope.deliveryDropDownHandler = function() { 
            var purchaseOrderDeliveryHeaderKey = $scope.deliveryData.purchaseOrderDeliveryHeaderKey;      
            $scope.getPurchaseOrder(purchaseOrderDeliveryHeaderKey); 
            $scope.isCreateMode = false; 
        } 

        $scope.getPurchaseOrder = function(purchaseOrderDeliveryHeaderKey) {    
            var purchaseOrderID = null;  

            purchaseOrderDeliveryService.getPurchaseOrderDelivery(purchaseOrderDeliveryHeaderKey)
            .then(function(delivery) {    
                $scope.getPurchaseOrderHeader(delivery.purchaseOrderHeaderKey);
                purchaseOrderService.getPurchaseOrder(delivery.purchaseOrderHeaderKey, purchaseOrderID)
                .then(function(results) {  
                    var purchaseOrder = results.data; 
                    if (purchaseOrder && delivery) { 
                        populatePurchaseOrderAndDelivery(purchaseOrder, delivery);  
                    }
                    else {
                        $scope.reset();
                        $scope.alerts.push({type: 'danger', msg: 'Failed to retrieve delivery information!'}); 
                    }
                }); 
            })
            .catch(function(){
                $scope.reset();
                $scope.alerts.push({type: 'danger', msg: 'Failed to retrieve delivery information!'}); 
            }); 
        }  

        $scope.getPurchaseOrderDeliveryHeader = function() {   
            purchaseOrderDeliveryService.getPurchaseOrderDeliveryHeader()
            .then(function(result) {
                $scope.deliveryHeadersDropdown =  _.filter(result, function (item) {  
                    return item.rowStatusKey === $scope.rowStatus.active;
                }); 
                $scope.deliveryHeadersDropdown.sort(function (a, b) {
                    return ('' + a.purchaseOrderHeaderID).localeCompare(b.purchaseOrderHeaderID);
                });
            }); 
        } 
 
        $scope.calcTotalCost = function(index) {      
            var poDeliveryDetail = $scope.deliveryData.purchaseOrderDeliveryDetails[index];
            var unitCost = safeSpecialCharacterParse(poDeliveryDetail.unitCost);
            var fICost = safeSpecialCharacterParse(poDeliveryDetail.freightAndInsuranceCost);
            var simCost = safeSpecialCharacterParse(poDeliveryDetail.simCost);
            var clearCost = safeSpecialCharacterParse(poDeliveryDetail.clearCost); 
            //Total Cost = Raw cost + f/i cost + SIM Cost + Clear Cost 
            var totalCost = parseFloat(unitCost) + parseFloat(fICost) + parseFloat(simCost) + parseFloat(clearCost);
            poDeliveryDetail.totalCost = totalCost;  
        }

        $scope.clickDeleteDelivery = function(formDelivery) {   
            if(confirm("Are you sure to delete the delivery?")) {
                $scope.submitHandler(formDelivery, $scope.rowStatus.delete);
            }  
        }

        $scope.purchaseOrderDropDownHandler = function(purchaseOrderHeaderKey) {      
            if (purchaseOrderHeaderKey) {
                $scope.getNewPurchaseOrder(purchaseOrderHeaderKey);
            }
        } 

        $scope.getPurchaseOrderHeader = function(purchaseOrderHeaderKey) {     
            $scope.arePOLoading = true;
            purchaseOrderService.getPurchaseOrderHeader(purchaseOrderHeaderKey)
            .then(function(result) {      
                $scope.purchaseOrderHeadersDropdown = result.data;   
                $scope.purchaseOrderHeadersDropdown =  _.filter($scope.purchaseOrderHeadersDropdown, function (item) {  
                    return item.rowStatusKey === $scope.rowStatus.active;
                });
                $scope.arePOLoading = false; 
            }); 
        }  
 
        $scope.getNewPurchaseOrder = function(purchaseOrderHeaderKey) {   
            try { 
                var purchaseOrderID = null; 
                
                purchaseOrderService.getPurchaseOrder(purchaseOrderHeaderKey, purchaseOrderID)
                .then(function(result) {     
                    populatePurchaseOrder(result.data);  
                });  
            } catch (error) { 
                $scope.reset();
                $scope.alerts.push({type: 'danger', msg: 'Failed to retrieve delivery information!'}); 
            } 
        }     

        $scope.closeAlert = function (index) {
            $scope.alerts.splice(index, 1);
        } 

        //Private Methods ********************************************************************
 
        var populatePurchaseOrderAndDelivery = function(po, delivery) {    
            if (po && delivery) {  
                delivery.purchaseOrderDeliveryDetails = _.filter(delivery.purchaseOrderDeliveryDetails, function (item) {  
                    return item.rowStatusKey === $scope.rowStatus.active;
                }); 
                $scope.deliveryData = { 
                    purchaseOrderDeliveryHeaderKey   : delivery.purchaseOrderDeliveryHeaderKey,
                    purchaseOrderHeaderKey           : delivery.purchaseOrderHeaderKey,
                    expectedDeliveryDate 		     : toDate(delivery.expectedDeliveryDate), 
                    actualDeliveryDate 		         : toDate(delivery.actualDeliveryDate),
                    vendorShipDate 		             : toDate(delivery.vendorShipDate),
                    vendorInvoiceID 	             : delivery.vendorInvoiceID,  
                    purchaseOrderDeliveryHeaderNote  : delivery.purchaseOrderDeliveryHeaderNote,  
                    freightAndInsuranceCost          : delivery.freightAndInsuranceCost,
                    purchaseOrderDeliveryDetails     : delivery.purchaseOrderDeliveryDetails
                }   
                for (let index = 0; index < $scope.deliveryData.purchaseOrderDeliveryDetails.length; index++) {
                    var deliveryDetail = $scope.deliveryData.purchaseOrderDeliveryDetails[index];  
                    var orderDetail = _.find(po.purchaseOrderDetails, function(pod) {
                        return pod.purchaseOrderDetailKey == deliveryDetail.purchaseOrderDetailKey;
                    }); 
                    if (orderDetail) {
                        deliveryDetail.inventoryItem = orderDetail.inventoryItem.description;
                        deliveryDetail.simCardCarrier = orderDetail.simCardCarrier.description; 
                        deliveryDetail.poOrderQuantity = orderDetail.orderQuantity;
                        deliveryDetail.inventoryCarrier = orderDetail.simCardCarrier.description ? 
                            orderDetail.inventoryItem.description + ' - ' + orderDetail.simCardCarrier.description : orderDetail.inventoryItem.description;

                        //(PO Qty - Total Delivery Qty)
                        deliveryDetail.poQuantityRemaining = (orderDetail.orderQuantity - orderDetail.deliveryQuantity) || 0;
                    }
                } 
            }     
        }

        var toDate = function(date) { 
            //removing 'z' from the date string to make it work with utc library
            date = date ? date.slice(0, -1) : null;
            return date ? moment(date).toDate() : null; 
        }

        var populatePurchaseOrder = function(po) {   
            if (po) { 
                $scope.deliveryData.purchaseOrderDeliveryHeaderKey = -1;
                $scope.deliveryData.purchaseOrderHeaderKey = po.purchaseOrderHeaderKey;
                $scope.deliveryData.purchaseOrderDeliveryDetails = [];
                for (let i = 0; i < po.purchaseOrderDetails.length; i++) {
                    var poDetail = po.purchaseOrderDetails[i];  
                    $scope.deliveryData.purchaseOrderDeliveryDetails.push({ 
                        purchaseOrderDetailKey: poDetail.purchaseOrderDetailKey,
                        inventoryItem: poDetail.inventoryItem.description,
                        simCardCarrier: poDetail.simCardCarrier.description,
                        poOrderQuantity: poDetail.orderQuantity,
                        orderQuantity: 0,
                        purchaseOrderDeliveryQuantity: 0,
                        freightAndInsuranceCost: 0,
                        unitCost: 0,
                        totalCost: 0,
                        rowStatusKey: $scope.rowStatus.active
                    })
                }
                $scope.deliveryData.purchaseOrderDeliveryDetails = _.filter($scope.deliveryData.purchaseOrderDeliveryDetails, function (item) {  
                    return item.rowStatusKey === $scope.rowStatus.active;
                }); 
            }    
        }

        var safeSpecialCharacterParse = function(value) {
            var isString = (typeof value) == "string";
            return isString ? value.replace('$', '') : value;
        }

        init();
 
    }
]);
