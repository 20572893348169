angular.module('angus.services').factory('purchaseOrderService', [
    '$http', function ($http) {
 
         const getPurchaseOrder = function(purchaseOrderHeaderKey, purchaseOrderID) { 
             let url =  'api/tankMonitors/purchaseOrders';
             if (purchaseOrderID || purchaseOrderHeaderKey) {
                url += '?';
             }
             if (purchaseOrderID) {
                url += 'purchaseOrderID=' + purchaseOrderID;
             }
             if (purchaseOrderHeaderKey) {
                url += 'purchaseOrderHeaderKey=' + purchaseOrderHeaderKey;
             } 
             return $http.get((url));
         };
  
         const getPurchaseOrderHeader = function(purchaseOrderHeaderKey, purchaseOrderID) { 
             let url =  'api/tankMonitors/purchaseOrders/purchaseOrderHeaders';
             if (purchaseOrderID || purchaseOrderHeaderKey) {
                url += '?';
             }
             if (purchaseOrderID) {
                url += 'purchaseOrderID=' + purchaseOrderID;
             }
             if (purchaseOrderHeaderKey) {
                url += 'purchaseOrderHeaderKey=' + purchaseOrderHeaderKey;
             } 
             return $http.get((url));
         };
 
         const getPurchaseOrderDetail = function(purchaseOrderHeaderKey) {
             return $http.get(('api/tankMonitors/purchaseOrders/purchaseOrderDetails/' + purchaseOrderHeaderKey));
         };
 
         const postPurchaseOrder = function(data) {
             return $http.post(('api/tankMonitors/purchaseOrders'), data, {headers: {'Content-Type': 'application/json'}});
         }; 

         const postPurchaseOrderHeader = function(data) { 
             return $http.post(('api/tankMonitors/purchaseOrders/purchaseOrderHeaders'), data);
         }; 
         
         const postPurchaseOrderDetail = function(purchaseOrderHeaderKey, data) { 
             return $http.post(('api/tankMonitors/purchaseOrders/' + purchaseOrderHeaderKey), data);
         }; 
         
         return {
             getPurchaseOrder, 
             getPurchaseOrderHeader,
             getPurchaseOrderDetail, 
             postPurchaseOrder,
             postPurchaseOrderHeader,
             postPurchaseOrderDetail
         };
     }
 ]);
 
 