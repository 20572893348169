angular
    .module('angus.directives')
    .directive('hierarchyDropDown', ['constantsService', HierarchyDropDown])
    .directive('nodeDropDown', ['constantsService', NodeDropDown])
    .directive('divisionDropDown', ['constantsService', DivisionDropDown])
    .directive('deliveryCenterDropDown', ['constantsService', DeliveryCenterDropDown])
    .directive('serviceCenterDropDown', ['constantsService', ServiceCenterDropDown])
    .directive('dealerDropDown', ['constantsService', DealerDropDown])
    .directive('hierarchyBreadcrumb', HierarchyBreadcrumb);

function HierarchyBreadcrumb() {
    'use strict';

    return {
        scope: {
            nodeId: '=ngModel',
            nodes: '=hierarchyBreadcrumb',
            change: '&crumbSelected'
        },
        replace: true,
        template: '<span class="breadcrumb" ><li data-ng-repeat="crumb in breadcrumbs" ng-class="{active:$last}"><span ng-show="$first"><i class="fa fa-sitemap"></i>&nbsp;</span><a href="#" ng-class="{\'text-primary\':!$last}" ng-click="switchHierarchyNode(crumb.id)">{{crumb.name}}</a></li></span>',
        // template: '<div><i class="fa fa-sitemap"></i>&nbsp;<h5 style="display:inline-block;" data-ng-repeat="crumb in breadcrumbs"><a href="" ng-click="switchHierarchyNode(crumb.id)"><strong class="text-primary">{{crumb.name}}</strong></a> <i class="fa fa-chevron-right" ng-hide="$last==true"></i>&nbsp;</h5></div>',
        controller: ['$scope', '_', function($scope, _) {

            function createBreadcrumb(nodeId) {

                var ancestryNameList = [];

                function findNode(nodeId) {
                    return _.find($scope.nodes, function(node) {
                        return node.id == nodeId;
                    });
                }


                while(nodeId) {
                    var node = findNode(nodeId);

                    if(node) {
                        ancestryNameList.push(node);
                        nodeId = node.parents[0] ? node.parents[0].id : null;
                    } else
                        nodeId = null;
                }

                ancestryNameList.reverse();

                return ancestryNameList;
            }

            if($scope.nodeId) $scope.breadcrumbs = createBreadcrumb($scope.nodeId);

            $scope.switchHierarchyNode = function(nodeId) {
                $scope.nodeId = nodeId;
                $scope.change({ nodeId: nodeId });
            };

            $scope.$watch(function() {
                return $scope.nodeId;
            }, function(newVal, oldVal) {
                if(newVal && newVal != oldVal)
                    $scope.breadcrumbs = createBreadcrumb(newVal);
            });
        }]
    };
}

var controller = ['$scope', '$rootScope', '_', 'hierarchyService', function($scope, $rootScope, _, hierarchyService) {
    'use strict';

    $scope.getCollection = function(suppliedNodes, hierarchyType, hideLeaves) {

        var nodes, nodeId;

        if(suppliedNodes) {
            nodes = suppliedNodes;
        } else {
            nodes = $rootScope.user.hierarchy[hierarchyType].nodes;
            nodeId = $rootScope.user.hierarchy[hierarchyType].nodeId;
        }

        nodes = _.cloneDeep(nodes);
        nodes = hierarchyService.convertDataForEaseOfUse(nodes);

        var node = _.find(nodes, function(node) {
            return nodeId ? node.id == nodeId : node.isRoot;
        });

        function removeLeaves(node) {
            if(node.children) {
                _.forEach(node.children, function(child) {
                    return removeLeaves(child);
                });

                _.remove(node.children, function(child) {
                    return child.isLeaf;
                });
            }
        }

        if(hideLeaves) removeLeaves(node);

        return [node];
    };
}];


var template = '<div><label class="control-label" ng-if="!hideLabel">{{hierarchyType.value.display}}</label>';
template += '<fancy-drop-down allow-multi-select="allowMultiSelect" id-field="id" collection="collection" append-to="appendTo" ng-model="nodeId" ng-disabled="ngDisabled" control-class="col-md-7" ng-change="onSelection(member)" />';

var templateNoLabel= '<fancy-drop-down allow-multi-select="allowMultiSelect" id-field="id" collection="collection" append-to="appendTo" ng-model="nodeId" ng-disabled="ngDisabled" control-class="col-md-7" ng-change="onSelection(member)" />';

function HierarchyDropDown(constantsService){
    'use strict';
    return {
        scope: {
            nodeId      : '=ngModel',
            nodes       : '=hierarchyDropDown',
            labelTag    : '=',
            type        : '=hierarchyType',
            allowMultiSelect : '=allowMultiSelect',
            appendTo    : '=',
            changeEvent : '&ngChange',
            ngDisabled  : '='
        },
        template: template,
        controller : controller,
        link: function(scope, elem, attrs){
            scope.hideLabel = attrs.hideLabel;
            scope.hierarchyType = constantsService.hierarchyType[scope.type];
            scope.collection = scope.getCollection(scope.nodes, scope.hierarchyType, attrs.hideLeaves);
            scope.onSelection = function(member){
                scope.changeEvent({member: member});
            }
            // scope.changeEvent = attrs.changeEvent;
            if(!scope.nodeId) scope.nodeId = scope.collection[0].id;
        }
    };
}

function NodeDropDown(constantsService) {
    'use strict';

    return {
        scope: {
            nodeId: '=ngModel',
            nodes: '=nodeDropDown',
            allowMultiSelect: '=allowMultiSelect',
            require: '=',
            labelTag: '=',
            appendTo: '=',
            changeEvent: '&ngChange',
            ngDisabled  : '='
        },
        template: templateNoLabel,
        controller: controller,
        link: function(scope, elem, attrs) {
            scope.hideLabel = attrs.hideLabel;
            scope.hierarchyType = constantsService.hierarchyType.division;
            scope.collection = scope.getCollection(scope.nodes, scope.hierarchyType);

            scope.onSelection = function(member) {
                scope.changeEvent({ member: member });
            };

            if(!scope.nodeId) scope.nodeId = scope.collection[0].id;
        }
    };
}

function DivisionDropDown(constantsService) {
    'use strict';

    return {
        scope: {
            nodeId: '=ngModel',
            nodes: '=divisionDropDown',
            allowMultiSelect: '=allowMultiSelect',
            require: '=',
            labelTag: '=',
            appendTo: '=',
            changeEvent: '&ngChange',
            ngDisabled  : '='
        },
        template: template,
        controller: controller,
        link: function(scope, elem, attrs) {
            scope.hideLabel = attrs.hideLabel;
            scope.hierarchyType = constantsService.hierarchyType.division;
            scope.collection = scope.getCollection(scope.nodes, scope.hierarchyType);
            scope.onSelection = function(member) {
                scope.changeEvent({ member: member });
            };
            // scope.changeEvent = attrs.changeEvent;
            if(!scope.nodeId) scope.nodeId = scope.collection[0].id;
        }
    };
}

function DeliveryCenterDropDown(constantsService) {
    'use strict';

    return {
        scope: {
            nodeId: '=ngModel',
            nodes: '=deliveryCenterDropDown',
            allowMultiSelect: '=allowMultiSelect',
            require: '=',
            labelTag: '=',
            appendTo: '=',
            changeEvent: '&ngChange',
            ngDisabled  : '='
        },
        replace: true,
        template: template,
        controller: controller,
        link: function(scope, elem, attrs) {
            scope.hideLabel = attrs.hideLabel;
            scope.hierarchyType = constantsService.hierarchyType.deliveryCenter;
            scope.collection = scope.getCollection(scope.nodes, scope.hierarchyType);
            scope.onSelection = function(member) {
                scope.changeEvent({ member: member });
            };
            // scope.changeEvent = attrs.changeEvent;
            if(!scope.nodeId) scope.nodeId = scope.collection[0].id;
        }
    };
}

function ServiceCenterDropDown(constantsService) {
    'use strict';

    return {
        scope: {
            nodeId: '=ngModel',
            nodes: '=serviceCenterDropDown',
            allowMultiSelect: '=allowMultiSelect',
            require: '=',
            labelTag: '=',
            appendTo: '=',
            changeEvent: '&ngChange',
            ngDisabled  : '='
        },
        replace: true,
        template: template,
        controller: controller,
        link: function(scope, elem, attrs) {
            scope.hideLabel = attrs.hideLabel;
            scope.hierarchyType = constantsService.hierarchyType.serviceCenter;
            scope.collection = scope.getCollection(scope.nodes, scope.hierarchyType);
            scope.onSelection = function(member) {
                scope.changeEvent({ member: member });
            };
            // scope.changeEvent = attrs.changeEvent;
            if(!scope.nodeId) scope.nodeId = scope.collection[0].id;
        }
    };
}

function DealerDropDown(constantsService) {
    'use strict';

    return {
        scope: {
            nodeId: '=ngModel',
            nodes: '=dealerDropDown',
            allowMultiSelect: '=allowMultiSelect',
            labelTag: '=',
            appendTo: '=',
            changeEvent: '&ngChange',
            ngDisabled  : '='
        },
        replace: true,
        template: template,
        controller: controller,
        link: function(scope, elem, attrs) {
            scope.hideLabel = attrs.hideLabel;
            scope.hierarchyType = constantsService.hierarchyType.dealer;
            scope.collection = scope.getCollection(scope.nodes, scope.hierarchyType);
            scope.onSelection = function(member) {
                scope.changeEvent({ member: member });
            };
            // scope.changeEvent = attrs.changeEvent;
            if(!scope.nodeId) scope.nodeId = scope.collection[0].id;
        }
    };
}
