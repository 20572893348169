'use strict';

angular.module('angus.controllers').controller('acctGainLossByTankCtrl', [
    '$scope', '$rootScope', '$http', 'modalService', 'periodIndicators', '$q', 'actionViewTemplates', '_', 'constantsService', 'hierarchyService', 'dateCalculator', 'moment', 'datePickerService', 'lastGainLossDateService', 'fiscalYearService',
    function ($scope, $rootScope, $http, modalService, periodIndicators, $q, actionViewTemplates, _, constantsService, hierarchyService, dateCalculator, moment, datePickerService, lastGainLossDateService, fiscalYearService) {

        var divisionIds = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.Math = Math;
        $scope.ieoEnums = constantsService.includeExcludeOnly.enums;
        $scope.unusedNames = ['Actuals', 'Delivery Type Changes'];
        $scope.periodIndicators = constantsService.marginPeriodIndicators.enums;
        $scope.periodIndicator = _.find($scope.periodIndicators, function (period) {
            return period.key == $scope.widget.instance.settings.periodIndicatorId.value;
        }).value.display;

        if (!$scope.widget.instance.settings.tradeClassIds)
            $scope.widget.instance.settings.tradeClassIds = {
                value: []
            };

        if (!$scope.widget.instance.settings.salesPersonIds)
            $scope.widget.instance.settings.salesPersonIds = {
                value: []
            };
        if(typeof $scope.widget.instance.settings.customerOwned === "undefined") {
            $scope.widget.instance.settings.customerOwned = { value : 'Y' };
        }
        function getCounts(startDate, endDate) {
            var t = _.isArray($scope.widget.instance.settings.tradeClassIds.value) ? $scope.widget.instance.settings.tradeClassIds.value : [$scope.widget.instance.settings.tradeClassIds.value];
            var sp = _.isArray($scope.widget.instance.settings.salesPersonIds.value) ? $scope.widget.instance.settings.salesPersonIds.value : [$scope.widget.instance.settings.salesPersonIds.value];
            return $http({
                method: 'POST',
                url: ('api/subscribers/{0}/customerAccounts/gainsLosses/byTank/summary').format($rootScope.user.subscriberId),
                data: {
                    p: $scope.widget.instance.settings.productIds.value,
                    t: t.join(),
                    d: hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId),
                    // e: $scope.dates.lastDeliveryDate.format(),
                    // s: $scope.dates.periodStartDate ? $scope.dates.periodStartDate.format() : null,
                    customerOwned : $scope.widget.instance.settings.customerOwned.value,
                    e: endDate.format(),
                    s: startDate.format(),
                    m: $scope.widget.instance.settings.meteredTanks.value,
                    dt: $scope.widget.instance.settings.deliveryTypes.value,
                    min: $scope.widget.instance.settings.minTankSize ? $scope.widget.instance.settings.minTankSize.value : undefined,
                    max: $scope.widget.instance.settings.maxTankSize ? $scope.widget.instance.settings.maxTankSize.value : undefined,
                    sp: sp,
                    dts: $scope.widget.instance.settings.deliveryTypeIds ? $scope.widget.instance.settings.deliveryTypeIds.value : undefined
                }
            })
            .then(function (counts) {
                return counts.data;
            });
        }

        function init() {
            var promise = lastGainLossDateService
                .getLastContainerAcquisitionOrTermiationDate(divisionIds)
                .then(function (lastDeliveryDate) {

                    if(!$scope.dates) {
                        $scope.dates = {};
                        var dates = dateCalculator.calculatePeriod($scope.dates.periodStartDate, lastDeliveryDate || moment.utc(), $scope.widget.instance.settings.periodIndicatorId.value);

                        if($scope.widget.instance.settings.periodIndicatorId.value === 'fiscalYearToDate')
                            fiscalYearService
                                .getFiscalYearStartMonth(subscriberId)
                                .then(function(fiscalMonth) {
                                    if(moment().month() >= fiscalMonth)
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month');
                                    else
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month').subtract(1, 'year');

                                    $scope.widget.defaultInitialization();
                                });

                        $scope.dates.periodStartDate   = !$scope.widget.instance.settings.customDate ? dates.startDate : $scope.widget.instance.settings.customDate.startDate;
                        $scope.dates.lastDeliveryDate  = !$scope.widget.instance.settings.customDate ? dates.endDate : $scope.widget.instance.settings.customDate.endDate;
                    }

                    var promise = getCounts($scope.dates.periodStartDate, $scope.dates.lastDeliveryDate);
                    return $scope.widget.promises.monitor(promise);
                })
                .then(function (counts) {
                    $scope.counts = counts;
                    // calculate totals

                    switch ($scope.widget.instance.settings.deliveryTypes.value) {
                        case (constantsService.includeExcludeOnly.exclude.key):
                            $scope.counts.total = _.cloneDeep($scope.counts.actual);
                            break;
                        case (constantsService.includeExcludeOnly.only.key):
                            $scope.counts.total = _.cloneDeep($scope.counts.deliveryTypeChange);
                            break;
                        default:
                            $scope.counts.total = {
                                // net: (gains - losses) + (will-call to automatic - automatic to will-call)
                                net: $scope.counts.actual.net + $scope.counts.deliveryTypeChange.net
                            };
                            break;
                    }
                    // $scope.widget.instance.settings.displayDate = moment($scope.dates.periodStartDate).format('MMM DD, YYYY') + ' to ' + moment.utc($scope.dates.lastDeliveryDate).format('MMM DD, YYYY');

                    $scope.counts.total.display = 'Total';
                });

            return $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(init);


        $scope.$on('widgetSettingsChanged', function() {
            divisionIds = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);

            $scope.periodIndicator = _.find($scope.periodIndicators, function (period) {
                return period.key == $scope.widget.instance.settings.periodIndicatorId.value;
            }).value.display;

            delete $scope.dates;
            init();
        });

        $scope.openDatePicker = function () {
            return lastGainLossDateService
                .getLastContainerAcquisitionOrTermiationDate(divisionIds)
                .then(function(lastDeliveryDate) {
                    return datePickerService.openDatePickerModal(
                        $scope.dates.periodStartDate,
                        $scope.dates.lastDeliveryDate,
                        lastDeliveryDate)
                        .then(function (resultDates) {
                            if (resultDates) {
                                $scope.dates.periodStartDate  = moment(resultDates[0]).isValid() ? resultDates[0] : resultDates[1];
                                $scope.dates.lastDeliveryDate = resultDates[1];
                                $scope.periodIndicator  = constantsService.marginPeriodIndicators.customDateRange.value.display;

                                init();

                                $scope.periodIndicator = ($scope.widget.instance.settings.periodIndicatorId.value == constantsService.marginPeriodIndicators.customDate ||
                                    $scope.widget.instance.settings.periodIndicatorId.value == constantsService.marginPeriodIndicators.lastDeliveryDate) ?
                                    constantsService.marginPeriodIndicators.customDate : constantsService.marginPeriodIndicators.customDateRange;
                            }
                        });
                })
        };
    }
]);