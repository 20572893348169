angular.module('angus.controllers').controller('serviceHistoryReportContentCtrl', [
    '$scope', '$rootScope', '$document', '$http', 'drillService', 'gridService2', 'gridState', 'restrictedAccessService',
    function($scope, $rootScope, $document, $http, drillService, gridService2, gridState, restrictedAccessService) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.$watch(function() {
            return $scope.activeItem;
        }, function(newVal) {
            if (!newVal.grid) {
                initServiceHistoryReport();
            }
        });



        $scope.drillService = drillService;

      function getColClicks(){
          return {
              partsMargin : {
                onClick : function(row){ if(row.id) drillService.openServicePartsMargin(row); },
                isNotClickable : function(params){
                  return !params.data.id || params.data.partsMargin == 0;
                }
              },
              laborMargin : {
                onClick : function(row){ if(row.id) drillService.openServiceLaborMargin(row); },
                isNotClickable : function(params){
                  return !params.data.id || params.data.laborMargin == 0;
                }
              },
              callMargin : {
                onClick : function(row){ if(row.id) drillService.openServiceCallMargin(row); },
                isNotClickable : function(params){
                  return !params.data.id || params.data.callMargin == 0;
                }
              },

          };
      }

      function getColDefs(){
            var colDefs = [];
            colDefs.push(gridService2.colDef.createDate('date','Date','date', {sort: 'desc'}));
            colDefs.push(gridService2.colDef.createText('workOrderNumber','Work Order Number','workOrderNumber', { cellClass : ['center']} ));

            colDefs.push(gridService2.colDef.createText('callReason','Call Reason','callReason', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('primaryTech','Primary Tech','primaryTech', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('contractDescription','Contract Description','contractDescription'));
            colDefs.push(gridService2.colDef.createDate('contractRenewalDate','Contract Renewal Date','contractRenewalDate'));

            colDefs.push(gridService2.colDef.createBoolean('flatRateFlag','Flat Rate','flatRateFlag'));

            if(!$scope.restrictedAccess.revenue)
                colDefs.push(gridService2.colDef.createNumber('laborHoursBilled','Bill Hours','laborHoursBilled', { aggFunc : 'sum'}, {decimalPlaces: 2 }));

            if(!$scope.restrictedAccess.costAndMargin)
                colDefs.push(gridService2.colDef.createCurrency('partsMargin','Parts Margin','partsMargin', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

            if(!$scope.restrictedAccess.costAndMargin)
                colDefs.push(gridService2.colDef.createCurrency('laborMargin','Labor Margin','laborMargin', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

            if(!$scope.restrictedAccess.revenue)
                colDefs.push(gridService2.colDef.createCurrency('callBilled','Revenue','callBilled', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

            if(!$scope.restrictedAccess.costAndMargin)
                colDefs.push(gridService2.colDef.createCurrency('callMargin','Call Margin','callMargin', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

            if(!$scope.restrictedAccess.revenue && !$scope.restrictedAccess.costAndMargin) {
                colDefs.push(gridService2.colDef.createPercentage('percentOfMargin','% Margin','percentOfMargin', {aggFunc : 'none'}, {decimalPlaces: 1 }));
            }

            return colDefs;
        }

        function initServiceHistoryReport() {

          restrictedAccessService
            .getAccess()
            .then(function(access){
                $scope.restrictedAccess = access.restrictedAccess;

                var gridParams = {
                    gridState     : gridState(subscriberId, 'serviceHistory'),
                    defs          : getColDefs(),
                    clicks        : getColClicks(),
                    exportOptions : { fileName: 'Service History' }
                };

                return gridService2.createGrid(gridParams)
            })
            .then(function(grid){
                $scope.activeItem.grid = grid;
                $scope.getServiceHistory();
            });
        }

        $scope.getServiceHistory = function() {
            var params = {
                locationId    : $scope.activeItem.params.locationId,
                accountNumber : $scope.activeItem.params.accountNumber,
                equipmentId   : $scope.activeItem.params.equipmentId,
            };

            var rowPromise =  $http({
                    url: 'api/subscribers/{0}/service/history'.format($rootScope.user.subscriberId),
                    method: 'GET',
                    params: params
                })
                .then(function(result){
                    var records = result.data;
                    _.forEach(records, function(record) {
                      record.percentOfMargin = (function(margin, sales) {
                        if(margin == 0 || sales == 0 || margin > sales) {
                          return 0;
                        }
                        return margin / sales * 100;
                      })(record.callMargin, record.callBilled);
                    });
                    return records;
                });

            var promise = $scope.activeItem.grid.setRows(rowPromise);
            return promise;
        };
    }
]);
