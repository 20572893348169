angular.module('angus.directives')
    .directive('salesPeopleSelect', [function(){
    	'use strict';

        return {
            restrict: 'A',
            scope: {
                ngModel: '='                
            },
            controller : ['$scope', 'salesPersonService', function($scope, salesPersonService){            	
            	salesPersonService
					.getSalesPerson()            
            		.then(function(salesPeople) {
                		$scope.salesPeople = salesPeople;
            		});            	
            }],            
            template : '<div multi-select-ok-wrapped="salesPeople" ng-model="ngModel" display-prop="\'name\'" selection-prop="\'id\'"></div>',            
        };
    }]);
