angular.module('angus.controllers').controller('weatherSubRegSettingsCtrl', [
    '$scope', '$http', 'modalService', 'dateToString', '$q', 'actionViewTemplates', '_', 'constantsService',
    function ($scope, $http, modalService, dateToString, $q, actionViewTemplates, _, constantsService) {

		$scope.days = 15;
		$scope.units = 0; //hardcoded to USA. TODO: get the country from the subsciber
		$scope.tempBase = 65;
		$scope.forecast = {
			current: null,
			daily: null
		};
		$scope.allMax = [];
		$scope.allMin = [];
		$scope.tempMaximum = [];
		$scope.tempMinimum = [];
		$scope.Datetime = [];
		$scope.WeatherSummary = [];
		$scope.Object = [];
		$scope.weatherIcon = 'clear-day';


		$scope.filterDailyForecast = function (skip, take) {
			return _.take(_.slice($scope.forecast.daily, skip), take);

		};


		$scope.getSearchLocation = function (address) {

			$http.get('api/geocoding/suggest', {
					params: {
						q: address,
						country: constantsService.countries.unitedStates.key
					}
				})
				.success(function (results) {
					$scope.addresses = _.map(results, function (suggestion) {
						return {
							id: suggestion.suggestionKey,
							text: suggestion.location
						};
					});
				});
		};

		$scope.getForecast = function (address, suggestionKey) {
			var promise =
				$http.get('api/geocoding', {
					params: {
						q: address,
						key: suggestionKey
					}
				})
				.success(function (result) {
					return getWeatherForecast(result[0].latitude, result[0].longitude)
						.then(function (results) {
							$scope.forecast = results.data;
							for (var t in $scope.forecast.daily) {
								$scope.allMin.push($scope.forecast.daily[t].temperatureMin);
								$scope.allMax.push($scope.forecast.daily[t].temperatureMax);
							}
						});

				});
			$scope.widget.promises.monitor(promise);
		};


		function getWeatherForecast(lat, lng) {
			return $http({
				url: ('api/weather/forecast'),
				method: 'GET',
				params: {
					days: $scope.days,
					latitude: lat,
					longitude: lng,
					units: $scope.units
				}
			});
		}

		$scope.openHDDGraphView = function (forecastdate, i) {
			var scope = $scope.$new();
			scope.propertyName = 'Weather Detail';

			$scope.idx = i;
			$scope.forecastdates = forecastdate;
			scope.forecastDate = forecastdate;
			scope.selectedDate = new Date(forecastdate).toDateString().slice(4);
			scope.selectedDay = new Date(forecastdate).toDateString().slice(0, 3);


			scope.getGraphValues = function () {

				var deferred = $q.defer();

				deferred.resolve({
					data: [{
						result: [
                            [0, 3]
                        ]
                    }, {
						result: [
                            [1, 3]
                        ]
                    }]
				});

				return deferred.promise;
			};

			scope.closeGraphViewCb = function () {
				if ($scope.modalInstance) $scope.modalInstance.close();
			};


			$scope.modalInstance = modalService.open({
				templateUrl: actionViewTemplates.weatherHourlyDetailContainer,
				scope: scope
			}, modalService.types.topBar);

		};

    }
]);