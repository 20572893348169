angular.module('angus.controllers').service('adeptInquiryService', [
    '$rootScope', '$timeout',
    function ($rootScope, $timeout) {

        this.tabs = {
            executions: 'executions',
            executionDetails: 'executionDetails',
            tankInquiry: 'tankInquiry'
        }

        let loadedTabs = [];

        this.activeTab = "";
        this.params = null;

        function isTabLoaded(tabId) {
            return loadedTabs.includes(tabId);
        }

        this.activateTab = function (tabId, params) {            
            if (!isTabLoaded(tabId)) {
                loadedTabs.push(tabId);
            }

            this.activeTab = tabId;
            this.params = params;

            $rootScope.$broadcast('adeptInquiryActiveTabChanged', this.activeTab, this.params);
        }


        this.isTabLoaded = function (tabId) {
            return isTabLoaded(tabId);
        }

        this.unloadTabs = function() {
            loadedTabs = [];
        } 
    }
])