angular.module('angus.controllers').controller('weatherHDDCtrl', [
    '$scope', '$http', '$rootScope', 'moment',
    function ($scope, $http, $rootScope, moment) {
        function hddVariance() {
            $scope.sources = $scope.widget.instance.settings.sources;

            var data = {
                degreeDayHeaderKey: $scope.sources ? JSON.parse($scope.sources).degreeDayHeaderKey : null,
                yearsHistory: $scope.widget.instance.settings.hddAverageResult.value,
                selectedSeasonMonth: $scope.widget.instance.settings.selectedSeasonMonth,
                degreeDayHeaderSources: $scope.sources ? JSON.parse($scope.sources).degreeDaySourceTypeKey : null
            };

            return $scope.widget.promises.monitor($http({
                url: ('api/subscribers/{0}/degreeDays/variance/').format($rootScope.user.subscriberId),
                method: 'GET',
                params: data
            })
                .then(function (degreeDayYear) {
                    $scope.ddYear = degreeDayYear.data;
                    // $scope.ddStartMonth = degreeDayYear.config.params.selectedSeasonMonth;

                    var variance = $scope.ddYear.variancePercent;
                    $scope.feels = '---';
                    if (variance > 0.5) {
                        $scope.feels = 'colder';
                    }
                    if (variance < -0.5) {
                        $scope.feels = 'warmer';
                    }

                    $scope.ddYear.variancePercent = Math.abs($scope.ddYear.variancePercent);
                    var normal = $scope.ddYear.normal;
                    var normalToDate = $scope.ddYear.normalToDate;
                    var actual = $scope.ddYear.actual;
                    // var endDate = $scope.ddYear.endDate;


                    var endDate   = moment.utc($scope.ddYear.endDate);
                    var startDate = moment.utc($scope.ddYear.startDate);

                    //Default to the current year
                    var seasonStart = startDate; //moment($scope.ddYear.startDate);
                    seasonStart.year(moment().year());

                    //Assumes the current year...however if that date is in the future
                    //then we base the season off of last year
                    if (moment().isBefore(seasonStart))
                        seasonStart.subtract(1, 'year');

                    // var elapsedDays = moment($scope.ddYear.endDate).diff(seasonStart, 'days');
                    var elapsedDays = endDate.diff(seasonStart, 'days');
                    var elapsedPct = (elapsedDays / 365.0) * 100;

                    var diffPct = (elapsedPct * (Math.abs(variance / 100)));
                    $scope.progressMeter = (elapsedPct - diffPct);
                    $scope.diff = diffPct;
                    $scope.testDate = $scope.widget.instance.settings.selectedSeasonMonth;

                    $scope.startDate = seasonStart.format('L');
                    // $scope.endDate = moment($scope.ddYear.endDate).format('L');
                    $scope.endDate = endDate.format('L');

                    $scope.popoverTitle = $scope.endDate;
                    $scope.popoverActual = 'Actual: ' + actual;
                    $scope.popoverAverages = 'Averages: ' + normalToDate;

                    //var fullPeriodStart = moment($scope.endDate).subtract($scope.widget.instance.settings.hddAverageResult.value, 'years');
                    var fullPeriodStart = endDate.subtract($scope.widget.instance.settings.hddAverageResult.value, 'years');
                    // $scope.fullPeriod = (moment($scope.ddYear.startDate).diff(fullPeriodStart, 'days') === 0);
                    $scope.fullPeriod = (startDate.diff(fullPeriodStart, 'days') === 0);
                    $scope.ddYear.startDate = startDate.format('L');//moment($scope.ddYear.startDate).format('L');

                    // register the model for the action view
                    $scope.widget.setDefaultActionModel({
                        periodStartDate: $scope.ddYear.startDate,
                        periodEndDate: $scope.endDate
                    });
                }));
        }

        $scope.widget.setDefaultInitialization(hddVariance);

        $scope.$on('widgetSettingsChanged', function(){
            hddVariance();
        });

    } // controller
]); // controller