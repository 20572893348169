'use strict';

angular.module('angus.controllers').controller('driverPerfCtrl', [
    '$scope', '$rootScope', '$http', 'modalService', 'dateToString', 'productsService', '$q', 'hierarchyService', 'actionViewTemplates', 'constantsService', '_',
    'moment', 'datePickerService', 'lastDeliveryDateService', 'dateCalculator', 'restrictedAccessService', 'fiscalYearService',
    function($scope, $rootScope, $http, modalService, dateToString, productsService, $q, hierarchyService, actionViewTemplates, constantsService, _,
             moment, datePickerService, lastDeliveryDateService, dateCalculator, restrictedAccessService, fiscalYearService) {

        $scope.product = {};

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.periodIndicators = constantsService.periodIndicators.enums;
        $scope.periodIndicator = _.find(constantsService.periodIndicators, function(indicator) {
            return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
        });

        var dcSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.deliveryCenter.nodes, $scope.widget.instance.settings.hierarchyNodeId);

        restrictedAccessService
            .getAccess()
            .then(function(access){
                $scope.restrictedAccess = access.restrictedAccess;
            });

        function loadWidget() {
            $scope.widget.noContentFlag = false;

            var promise = lastDeliveryDateService
                .getLastDeliveryDate(dcSetting)
                .then(function(lastDeliveryDate) {
                    if (!$scope.dates){
                        $scope.dates = {};
                        var dates = dateCalculator.calculatePeriod($scope.dates.periodStartDate, lastDeliveryDate || moment.utc(), $scope.widget.instance.settings.periodIndicatorId.value);

                        if($scope.periodIndicator.key === 'fiscalYearToDate')
                            fiscalYearService
                                .getFiscalYearStartMonth(subscriberId)
                                .then(function(fiscalMonth) {
                                    if(moment().month() >= fiscalMonth)
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month');
                                    else
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month').subtract(1, 'year');

                                    $scope.widget.defaultInitialization();
                                });

                        $scope.dates.periodStartDate   = !$scope.widget.instance.settings.customDate ? dates.startDate : $scope.widget.instance.settings.customDate.startDate;
                        $scope.dates.lastDeliveryDate   = !$scope.widget.instance.settings.customDate ? dates.endDate : $scope.widget.instance.settings.customDate.endDate;
                    }

                    return queryDriverPerformance();
                })
                .then(function(response) {
                    if (!response || !response.data) {
                        $scope.widget.noContentFlag = true;
                        $scope.widget.instance.settings.customDate = null;
                        return null;
                    }

                    $scope.product.kpis = response.data;
                    $scope.widget.setDefaultActionModel($scope.dates);
                });

            return $scope.widget.promises.monitor(promise);
        }

        function reloadWidget() {
            $scope.widget.noContentFlag = false;

            var promise = queryDriverPerformance()
                .then(function(response) {
                    if (!response || !response.data) {
                        $scope.widget.noContentFlag = true;
                        return null;
                    }

                    $scope.product.kpis = response.data;
                    $scope.widget.setDefaultActionModel($scope.dates);
                });
            $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(loadWidget);

        function queryDriverPerformance() {
            return $http({
                url: ('api/subscribers/{0}/batches/summary').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    d  : dcSetting,
                    p  : $scope.widget.instance.settings.productIds.value,
                    pi : $scope.periodIndicator.key,
                    // s  : $scope.widget.instance.settings.startDate ? $scope.dates.periodStartDate : $scope.widget.instance.settings.startDate.format(),
                    // e  : $scope.widget.instance.settings.endDate ? $scope.dates.lastDeliveryDate : $scope.widget.instance.settings.endDate.format(),
                    s  : $scope.dates.periodStartDate ? $scope.dates.periodStartDate.format() : null,
                    e  : $scope.dates.lastDeliveryDate ? $scope.dates.lastDeliveryDate.format() : null,
                    ed : $scope.widget.instance.settings.excludeDriverIds.value,
                    transportationCharge : $scope.widget.instance.settings.transportationCharge
                }
            });
        }


        $scope.openFilteredReport = function(metricCategoryId, event) {
            var selectedFilter = {
                metricCategoryId: metricCategoryId,
                includeGreenIndicator: _(event.target.parentElement.className).contains('text-success')
            };
            $scope.widget.openDetailView(actionViewTemplates.driverPerfDefault, selectedFilter);
        };

        $scope.$on('widgetSettingsChanged', function() {
            dcSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.deliveryCenter.nodes, $scope.widget.instance.settings.hierarchyNodeId);
            $scope.periodIndicator = _.find(constantsService.periodIndicators, function(indicator) {
                return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
            });
            delete $scope.dates;
            loadWidget();
        });

        $scope.openDatePicker = function() {
            return lastDeliveryDateService.getLastDeliveryDate(dcSetting)
                .then(function(lastDeliveryDate) {
                    return datePickerService.openDatePickerModal(
                        $scope.widget.instance.settings.periodIndicatorId.value == constantsService.periodIndicators.lastDeliveryDate ? null : $scope.dates.periodStartDate,
                        $scope.dates.lastDeliveryDate,
                        lastDeliveryDate);
                })
                .then(function(resultDates) {
                    if (resultDates) {
                        $scope.dates.periodStartDate = moment(resultDates[0]).isValid() ? resultDates[0] : resultDates[1];
                        $scope.dates.lastDeliveryDate = resultDates[1];

                        reloadWidget();

                        $scope.periodIndicator = ($scope.widget.instance.settings.periodIndicatorId.value == constantsService.periodIndicators.customDate ||
                            $scope.widget.instance.settings.periodIndicatorId.value == constantsService.periodIndicators.lastDeliveryDate) ?
                            constantsService.periodIndicators.customDate : constantsService.periodIndicators.customDateRange;
                    }
                });
        };

        function getGraphValues(byWeek) {
            return $http({
                url: ('api/subscribers/{0}/batches/summary/history').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    d  : dcSetting,
                    p  : $scope.widget.instance.settings.productIds.value,
                    l  : $scope.dates.lastDeliveryDate.format(),
                    b  : typeof byWeek !== 'undefined' ? byWeek : false,
                    ed : $scope.widget.instance.settings.excludeDriverIds.value
                }
            });
        }

        $scope.getDateTooltipText = function() {
            if(!$scope.dates) return;
            if (!constantsService.periodIndicators.lastDeliveryDate.is($scope.widget.instance.settings.periodIndicatorId.value) && $scope.dates.periodStartDate) {
                return $scope.dates.periodStartDate.format('L') + ' - ' + $scope.dates.lastDeliveryDate.format('L');
            }
            return $scope.dates.lastDeliveryDate ? $scope.dates.lastDeliveryDate.format('L') : '';
        };

        $scope.openGraphView = function(propertyName) {
            var scope = {
                propertyName: propertyName,
                getGraphValues: getGraphValues
            };

            modalService
                .openTopBarModal(actionViewTemplates.modalGraphContainer, scope);
        };
    }
]);