angular.module('angus.filters')
    .filter('percentage', function() {
        'use strict';

        return function(number, suffix) {
            var val = !isNaN(number) ? Math.round((number * 100)) : 0;
            return val + (suffix || '%');
        };
    })
    .filter('percentageDecimal', function(){

        'use strict';

        return function(number, decimals, suffix) {
          var val = !isNaN(number) ? (number * 100).toFixed(decimals || 2) : 0;
          return val + (suffix || '%');
        };
    });