'use strict';

angular.module('angus.controllers').controller('excessCallsCtrl', [
    '$scope', '$rootScope', '$http', 'modalService', 'dateToString', 'productsService', '$q', 'hierarchyService', 'actionViewTemplates',
    'constantsService', '_', 'moment', 'datePickerService', 'lastInvoiceDateService',
    function($scope, $rootScope, $http, modalService, dateToString, productsService, $q, hierarchyService, actionViewTemplates,
             constantsService, _, moment, datePickerService, lastInvoiceDateService) {

        $scope.periodIndicators = constantsService.serviceExtendedPeriodIndicators.enums;
        $scope.periodIndicator = _.find(constantsService.serviceExtendedPeriodIndicators, function(indicator) {
            return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
        });

        var divisionSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
        $scope.dates = {};

        function loadWidget() {
            $scope.widget.noContentFlag = false;

            var promise = lastInvoiceDateService.getLastInvoiceDate(divisionSetting,undefined, 1)
                .then(function(lastPostingDate) {
                    if (!lastPostingDate)
                        return null;

                    $scope.dates.lastPostingDate = !$scope.widget.instance.settings.customDate ? lastPostingDate : $scope.widget.instance.settings.customDate.endDate;
                    $scope.dates.periodStartDate = !$scope.widget.instance.settings.customDate ? moment(lastPostingDate).subtract($scope.periodIndicator.value.daySubtraction, 'days') : $scope.widget.instance.settings.customDate.startDate;
                    return queryExcessCallsSummary();
                })
                .then(function(response) {
                    if (!response || !response.data) {
                        $scope.widget.noContentFlag = true;
                        $scope.widget.instance.settings.customDate = null;
                        return null;
                    }

                    $scope.calls = response.data;
                    setDateTooltipText();
                    $scope.widget.setDefaultActionModel($scope.dates);
                });
            return $scope.widget.promises.monitor(promise);
        }

        function reloadWidget() {
            $scope.widget.noContentFlag = false;

            var promise = queryExcessCallsSummary()
                .then(function(response) {
                    if (!response || !response.data) {
                        $scope.widget.noContentFlag = true;
                        $scope.widget.instance.settings.customDate = null;
                        return null;
                    }

                    $scope.calls = response.data;
                    setDateTooltipText();
                    $scope.widget.setDefaultActionModel($scope.dates);
                });
            $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(loadWidget);

        function queryExcessCallsSummary() {
            return $http({
                url: ('api/subscribers/{0}/service/excessCalls/summary').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    d  : divisionSetting,
                    nc : $scope.widget.instance.settings.nonContract.value,
                    mc : $scope.widget.instance.settings.minNumberCalls.value,
                    sd : $scope.dates.periodStartDate ? $scope.dates.periodStartDate.format() : null,
                    ed : $scope.dates.lastPostingDate.format()
                }
            });
        }

        $scope.$on('widgetSettingsChanged', function() {
            divisionSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);

            $scope.periodIndicator = _.find(constantsService.serviceExtendedPeriodIndicators, function(indicator) {
                return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
            });

            $scope.dates.periodStartDate = null;
            loadWidget();
        });

        $scope.openFilteredReport = function(metricCategoryId, event) {
            var selectedFilter = {
                metricCategoryId: metricCategoryId,
                includeGreenIndicator: _(event.target.parentElement.className).contains('text-success')
            };
            $scope.widget.openDetailView(actionViewTemplates.driverPerfDefault, selectedFilter);
        };

        $scope.openDatePicker = function() {
            return lastInvoiceDateService.getLastInvoiceDate(divisionSetting,undefined, 1)
                .then(function(lastPostingDate) {
                    return datePickerService.openDatePickerModal($scope.dates.periodStartDate, $scope.dates.lastPostingDate, lastPostingDate);
                })
                .then(function(resultDates) {
                    if (resultDates) {
                        $scope.periodIndicator = ($scope.dates.periodStartDate != resultDates[0] || $scope.dates.lastPostingDate != resultDates[1]) ?
                            constantsService.serviceExtendedPeriodIndicators.customDateRange : $scope.periodIndicator;

                        $scope.dates.periodStartDate = resultDates[0];
                        $scope.dates.lastPostingDate = resultDates[1];

                        reloadWidget();
                    }
                });
        };

        function setDateTooltipText() {
            $scope.dateTooltipText = $scope.dates.periodStartDate.format('L') + ' - ' + $scope.dates.lastPostingDate.format('L');
        }
    }
]);