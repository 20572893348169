angular.module( 'angus.directives' ).directive( 'propaneVar', [
    'actionViewTemplates',
    function( actionViewTemplates ) {
        return {
            scope: {
                model      : "=actionModel",
                settings   : "=propaneVarWidgetSettings",
                widgetCode : "="
            },
            templateUrl: actionViewTemplates.propaneVarContainer
        };
    }
] );