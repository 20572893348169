angular.module('angus.services').service('lastGainLossDateService', ['$rootScope', '$http', '$q', '_', 'moment',
    function($rootScope, $http, $q, _, moment) {

        'use strict';

        function getLastAcquisitionTerminationDates(divisions) {
            if(!_.isArray(divisions)) divisions = [divisions];

            return $http({
                url: ('api/subscribers/{0}/customerAccounts/gainsLosses/lastAcquisitionTermination').format($rootScope.user.subscriberId),
                method: 'POST',
                params: {
                    d : divisions,
                }
            })
                .then(function(response) {
                    return response.data;
                });
        }

        function getLastAcquistionDate(divisions, key){
            return getLastAcquisitionTerminationDates(divisions)
                .then(function(dates){
                    return moment.utc(dates[key].acquisition);
                });
        }

        function getLastTerminationDate(divisions, key){
            return getLastAcquisitionTerminationDates(divisions)
                .then(function(dates){
                    return moment.utc(dates[key].termination);
                });
        }

        function getLastAcquisitionOrTermiationDate(divisions, key){
            return getLastAcquisitionTerminationDates(divisions)
                .then(function(dates){
                    var acquisition = moment.utc(dates[key].acquisition);
                    var termination = moment.utc(dates[key].termination);

                    return moment.max(acquisition, termination);
                });
        }


        return _.reduce(['customer', 'container'], function(result, key){
            result['getLast'+ key.capitalizeFirstLetter() +'AcquisitionDate'] = function(divisions){
                return getLastAcquistionDate(divisions, key);
            };

            result['getLast'+ key.capitalizeFirstLetter() +'TerminationDate'] = function(divisions){
                return getLastTerminationDate(divisions, key);
            };

            result['getLast'+ key.capitalizeFirstLetter() +'AcquisitionOrTermiationDate'] = function(divisions){
                return getLastAcquisitionOrTermiationDate(divisions, key);
            };

            return result;
        }, {});
    }
]);