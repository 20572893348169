angular.module('angus.directives').directive('angusAlert',
    function() {

        'use strict';

        return {
            scope: {
                alerts: '=angusAlert'                
            },   
            replace: true,         
            template:'<div class="container-fluid"><div class="row form-group" data-slide-toggle="alerts.list && alerts.list.length > 1"><div class="col-md-12"><button ng-click="alerts.clearAll()" class="btn btn-default pull-right">Close all</button></div></div><div class="row"><div class="col-md-12"><uib-alert ng-repeat="alert in alerts.list" class="animate-flip-x" type="{{alert.type}}" close="alerts.close($index)"><span ng-bind-html="alert.msg"></span></uib-alert></div></div></div>',
        };
    });
