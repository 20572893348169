angular.module( 'angus.directives' ).directive( 'tmsSummary', [
	'',
	function( ) {
		return {
			scope: {
				model: '=monitors',
				settings: '=tmsInstallSettingsController',
				widgetCode : '='
			},
			templateUrl:' templates/widgets/tmsInstallSummeray/html/views/tmsInstallSummary.html'
		};
	}
] ).directive( 'flotBarChartTmsSummary', [
	'$filter',
	function( $filter ) {
		return {
			scope: {
				ngModel: '=',				
			},
			link: function(scope, element, attr){
                	

				'use strict';
				
				function plot(monitors){
                   

						var total = {
								label:'Total Monitors Installed',
								data:[],
								lines:{	
									//order:1,		          
									show: true,		
								},
								points:{
									show:true
								}
							}
                        var readings = {
								label:'Monitors Sending Readings Daily',
								data:[],
								bars:{	
									//order:2,		          
									show: true,
									align: 'right',
									lineWidth: 3,
									barWidth : .19		
									}
								}
                        var installed = {
								label:'Monitors Installed Daily',
								data:[],
								bars:{
									//order:3,			          
									show:true,
									align: 'left',
									lineWidth: 3,
									barWidth : .19		
									}
							}
					var bars = [];
					var y = []
					monitors.forEach(function(monitor, index){
						total.data.push([monitor.installDate, monitor.totalCount])	
						readings.data.push([monitor.installDate,monitor.readingCount])	
						installed.data.push([monitor.installDate,monitor.installed])
						y.push(monitor.intallDate)
					});
					bars.push(total, readings, installed)
					
					var options = {
						legend :{
							position:'nw',
							margin: 10,
							},
						series: {

							bars: {
								show: true,
								barWidth:0,
								
							}
						},
						grid: {
							hoverable: true,
							clickable: true
						},
						yaxes: {
             				 tickFormatter: function (val, axis) { return val}
         				},
						tooltip:true,
						tooltipOpts:{
							defaultTheme: false,
							content: '%y %s'
							},
						xaxis:{
							mode: 'categories',
							tickLength: 0,
							font: {
								size:8,
								color:'#242526'
							}						
						},
					 };
					
					$.plot(element, bars, options);  

				}

				scope.$watch(function(){
					return scope.ngModel;
				}, function(newVal){
					if(newVal)
						plot(newVal);
				});
			}
		};
	}
] );
