angular.module('angus.services').service('alertsService', [ '_',
	function(_) {
        'use strict'; 

        var types = {
        	success : {
        		class : 'success',
        		display : 'Success',
        	},
        	error : {
        		class : 'danger',
        		display : 'Error',
        	},
        	info : {
        		class : 'info',        		
        	}
        };

		return function(){			

			this.list = [];

			var that = this;

			_.forEach(types, function(type, key){
				var funcName = 'add' + key.charAt(0).toUpperCase() + key.slice(1);
				that[funcName] = function(mainMessage, detailMessages){                     					
                    var message = '<h5>';

        			if(!!type.display)
        				message += '<strong>'+ type.display +'!</strong> ';
                    
                    if(mainMessage) {
                        message += mainMessage;
                    }
                    message+='</h5>';

                    if(detailMessages && detailMessages.length > 0){
                        message+='<ul>';
                        _.forEach(detailMessages, function(detailMessage){
                            message += '<li>';
                            message += detailMessage;
                            message += '</li>';
                        });
                        message+='</ul>';
                    }                    

        			that.list.push({
                		type: type.class,
                		msg: message 
            		});
				};

			});

			this.close = function(index){
				this.list.splice(index, 1);
			};

            this.clearAll = function(){
                this.list = [];
            };

			return this;
		};
	}
]);