'use strict';

angular.module('angus.directives')
.directive('percentage', ['$filter', function ($filter) {
    return {
        require: '?ngModel',
        link: function (scope, elem, attrs, ctrl) {
            if (!ctrl) return;

            ctrl.$formatters.unshift(function (a) {  
                return ctrl.$modelValue + '%';
            });

            elem.bind('blur', function(event) {
                var plainNumber = elem.val().replace(/[^\d|\-+|\.+]/g, ''); 
                elem.val(plainNumber + '%');
            });
        }
    };
}]);