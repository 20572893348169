angular.module('angus.services')
    .factory('widgetPackageService', [
        '$http',
        function($http) {
            
            return {
                get: function(excludeUserSecurityGroup, securityGroupId) {
                    return $http({
                        url: 'api/widgetPackages',
                        method: 'GET',
                        params: {
                            excludeUserSecurityGroup: excludeUserSecurityGroup,
                            securityGroupId: securityGroupId
                        }
                    }).then(function(widgetPackages){
                        return widgetPackages.data;
                    });
                },
                
                getWidgets: function() {
                    return $http({
                        url: 'api/widgetPackages/widgets',
                        method: 'GET',
                        params: {}
                    }).then(function(results){
                        return results.data;
                    });
                }
            };
        }
    ]);