angular.module('angus.controllers').controller('tankUtilSubRegSettingsCtrl', [
    '$scope', '_', 'alertsService',
    function($scope, _, AlertsService) {
        'use strict';
        $scope.Number = Number;

        $scope.alerts = new AlertsService();

        mapToTankSizes();

        function mapToTankSizes(){
            $scope.tankSizes = _.cloneDeep($scope.settings.tankSizes.value);
        }

        function mapToSettings(){
            $scope.settings.tankSizes.value = _.map($scope.tankSizes, function(tankSizes){
                return {
                    start : tankSizes.start,
                    end   : tankSizes.end
                };
            });
        }


        function compareNumbers(a, b) {
          return Number(a.start) - Number(b.start);
        }

        $scope.isEditting = function(){
            return _.some($scope.tankSizes, function(tankSize){
                return tankSize.editting;
            });
        }

        $scope.editSizes = function(index){
            if($scope.isEditting()) return;
            $scope.tankSizes[index].editting = true;
        };

        $scope.canSave = function(index){

            var start = Number($scope.tankSizes[index].start);
            var end   = Number($scope.tankSizes[index].end);

            var canSave = true;

            if(isNaN(start)){
                $scope.alerts.addError('\'From\' is not a number.');
                canSave = false;
            }

            if(isNaN(end)){
                $scope.alerts.addError('\'To\' is not a number.');
                canSave = false;
            }

            if(start > end){
                $scope.alerts.addError('\'From\' must be less than \'To\'.');
                canSave = false;
            }

            if($scope.tankSizes.length > 1){
                if( _.some($scope.tankSizes, function(tankSizes, i){
                    if(i == index) return false;
                    return ((start <= tankSizes.end && start >= tankSizes.start ) ||
                            (end <= tankSizes.end && end >= tankSizes.start )) //Contained within some tank size grouping

                })){
                    $scope.alerts.addError('Than size size range overlaps with one or more ranges.');
                    canSave = false;
                }

                var min = _.reduce($scope.tankSizes, function(result, tankSize, i){
                    if(i == index) return result;
                    var start = Number(tankSize.start);
                    if(!result || start < result) result = start;
                    return result;
                }, null);

                var max = _.reduce($scope.tankSizes, function(result, tankSize, i){
                    if(i == index) return result;
                    var end = Number(tankSize.end);
                    if(!result || end > result) result = end;
                    return result;
                }, 0);

                if(start < min && end > min ){
                    $scope.alerts.addError('Than size size range overlaps with one or more ranges.');
                    canSave = false;
                }

                if(start < max && end > max ){
                    $scope.alerts.addError('Than size size range overlaps with one or more ranges.');
                    canSave = false;
                }
            }



            return canSave;
        }

        $scope.saveSizes = function(index){
            if(!$scope.canSave(index)) return;
            $scope.tankSizes[index].editting = false;
            $scope.tankSizes.sort(compareNumbers);
            mapToSettings();
        };

        $scope.biggestEndPlusOne = function(){
            return _.reduce($scope.tankSizes, function(result, tankSizes){
                if(tankSizes.end > result)
                    result = Number(tankSizes.end);
                return result;
            }, 0) + 1;
        }

        $scope.discardChanges = function(){
            mapToTankSizes();
        };

        $scope.deleteSizes = function(index){
            $scope.tankSizes.splice(index, 1);
            mapToSettings();
        };

        $scope.addSizes = function(){
            var newIndex = $scope.tankSizes.length;
            $scope.tankSizes.splice(newIndex, 0, { start : null, end : null});
            $scope.editSizes(newIndex);
        };
    }
]);
