angular.module('angus.services').service('ssoService', [
    '$rootScope', '_',
    function($rootScope, _) {

        'use strict'; 

        function pathToB3(host) {
            var env = '';
            if (host == 'localhost') {env = 'edge.';}
            else {
                switch(host) {
                case 'dev':
                    env = 'edge.';
                    break;
                case 'qa':
                    env = 'test.';
                    break;
                case 'stage':
                    env = 'stage.';
                    break;
                default:
                    env = '';
                }
            }
            return 'https://' + env + 'portal.service.aa-api.com';
          }

        return {  
            pathToB3: pathToB3, 
        };
    }
]);
