/* As I was refactoring the code to separate the layout into different components (Directives) I had to share the Dashboard Service across other 
    directives. Like the Side Menu and the Header. This caused an issue when Default Dashboards were used since Default Dashboards used
    the same service as everything else, but going to default dashboards changes the dashboards in the service. 

    At this time I took the easy route and created the Default Dashboard Service which is an exact copy of the Dashboard Service. If changes are needed
    then we should start breaking the logic out into a "Core Dashboard Service" that the 2 can share. 

*/

angular
    .module('angus.services')
    .factory('dashboardsService', [
        '$rootScope', '$http', '$q', 'WidgetInstance', 'modalService', '_', 'EQ', '$timeout', 'promiseQueue', '$analytics',
        function($rootScope, $http, $q, widgetInstance, modalService, _, EQ, $timeout, PromiseQueue) {
            'use strict';

            var defaultRows = 100;
            var defaultColumns = 6;
            this.dashboards = [];
            this.activeDashboard = null;
            this.subscriberId = null;
            this.securityGroupId = null;
            this.isLocked = false;

            var DashboardProfile = function(name, id, columns, rows, userId, order, subscriberId, securityGroupId, isLocked) {
                this.name = name;
                this.id = id;
                this.columns = columns;
                this.rows = rows;
                this.userId = userId;
                this.order = order;
                this.isLocked = isLocked;
                this.subscriberId = subscriberId;
                this.securityGroupId = securityGroupId;

                //Do not immediately publish default dashboards
                if(subscriberId && securityGroupId)
                    this.published = false;
            };

            var DefaultDashboardProfile = function(ids, userId) {
                this.ids = ids;
                this.userId = userId;
            };

            this.init = function(subscriberId, securityGroupId) {
                var that = this;
                return $http({
                    url: 'api/dashboards',
                    method: 'GET',
                    params: (subscriberId && securityGroupId) ? {
                        subscriberId: subscriberId,
                        securityGroupId: securityGroupId
                    } : {}
                })
                    .then(function(result) {
                        that.subscriberId = subscriberId;
                        that.securityGroupId = securityGroupId;
                        that.dashboards = result.data || [];
                        that.activeDashboard = result.data ? result.data[0] : null;
                        return {
                            dashboards: that.dashboards,
                            activeDashboard: that.activeDashboard
                        };
                    });
            };

            this.switchDashboard = function(dashboard) {
                var that = this;
                if(!dashboard.promiseQueue)
                    dashboard.promiseQueue = new PromiseQueue();

                var url = 'api/dashboards/{0}/widgets';
                if(that.securityGroupId)
                    url += '?securityGroupId={0}'.format(that.securityGroupId);

                return $q.when(dashboard.widgets || $http.get(url.format(dashboard.id))
                    .then(function(response) {
                        return response.data;
                    }))
                    .then(function(widgets) {
                        dashboard.widgets = widgets || [];
                        $timeout(function() {
                            EQ.update();
                        });
                        return that.activeDashboard = dashboard;
                    });
            };

            this.addDashboard = function(name, defaultDashboardIds, userId, isLocked) {
                var that = this,
                    max = this.dashboards.length > 0 ? _.max(this.dashboards, 'order').order : 0,
                    order = max ? max + 1 : 1;

                var dashboardName = name || 'New Dashboard';

                var subscriberId = that.subscriberId;
                var securityGroupId = that.securityGroupId;
                var ids = defaultDashboardIds || null;

                var profile = (defaultDashboardIds && defaultDashboardIds.length > 0) ? {
                    ids: defaultDashboardIds,
                    order: order,
                    userId: userId,
                    isLocked: isLocked,
                    isDefaultDashboard: true
                } : new DashboardProfile(dashboardName, ids, defaultColumns, defaultRows, $rootScope.user.userId, order, subscriberId, securityGroupId, isLocked);

                return $http
                    .post('api/dashboards/', profile)
                    .then(function(dashboard) {
                        if(!dashboard.data.isArray) dashboard.data = [dashboard.data];

                        _.forEach(dashboard.data, function(dashboard) {
                            that.dashboards.push(dashboard);
                            // if((!that.subscriberId && !that.securityGroupId)) //ignore events when an admin is setting things up...
                                //$analytics.eventTrack('dashboard:add', {
                                //     category: 'dashboard',
                                //     label: dashboard.name
                                // });

                        });

                        return dashboard.data;
                    });
            };

            this.deleteDashboard = function(dashboard) {
                var that = this;
                return $http
                    .delete(('api/dashboards/{0}').format(dashboard.id))
                    .then(function() {
                        _.remove(that.dashboards, function(d) {
                            return d.id == dashboard.id;
                        });
                    });
            };

            this.renameDashboard = function(dashboard, name) {
                if(dashboard && name) {
                    return $http
                        .put('api/dashboards/{0}?newName={1}'.format(dashboard.id, name))
                        .then(function() {
                            dashboard.name = name;
                            return dashboard;
                        });
                }
            };

            this.reorderDashboard = function(index, targetIndex) {
                var self = this;
                // slice  (shallow copy paste, allows for diff check)
                var dashboards = this.dashboards.slice();
                // splice (remove)
                dashboards.splice(targetIndex, 0, dashboards.splice(index, 1)[0]);

                var tasks = [];
                for(var i = 0; i < dashboards.length; i++) {
                    var dashboard = dashboards[i],
                        shift = i + 1;

                    if(dashboard.order === shift) {
                        continue;
                    }

                    dashboard.order = shift;

                    $http.put('api/dashboards/{0}/order?newOrder={1}'.format(dashboard.id, shift));
                }

                return self.dashboards = dashboards;
            };

            this.publishDashboard = function(dashboard) {
                var that = this;
                return $http
                    .post('api/dashboards/{0}/publish'.format(dashboard.id))
                    .then(function() {
                        dashboard.published = true;
                    });
            };

            this.unpublishDashboard = function(dashboard) {
                var that = this;
                return $http
                    .post('api/dashboards/{0}/unpublish'.format(dashboard.id))
                    .then(function() {
                        dashboard.published = false;
                    });
            };

            this.getDefaultDashboards = function(subscriberId, securityGroupId, published) {
                var that = this;
                return $http({
                    url: 'api/dashboards',
                    method: 'GET',
                    params: { subscriberId: subscriberId, securityGroupId: securityGroupId, published: published }
                })
                .then(function(result) {
                    return result.data || [];
                });
            };

            this.addWidget = function(dashboard, widget, widgetView, column, row) {
                if(!dashboard.widgets) dashboard.widgets = [];

                var postData = {
                    id: widget.id,
                    viewCode: widgetView.code,
                    location: {
                        column: column,
                        row: row,
                    }
                };

                return $http
                    .post('api/dashboards/{0}/widgets'.format(dashboard.id), postData)
                    .then(function(widgetInstance) {

                        widget = _.cloneDeep(widget);
                        widget.instance = widget.instance || widgetInstance.data;
                        widget.isNew = true;
                        dashboard.widgets.push(widget);
                        //Log the event
                        //$analytics.eventTrack('widget:add', { category: 'widget', label: widget.name });
                        return widget;

                    });
            };

            this.deleteWidget = function(dashboard, widgetInstanceId) {
                var widget = _.remove(dashboard.widgets, function(widget) {
                    return widget.instance.id == widgetInstanceId;
                });

                return $http.delete(('api/dashboards/{0}/widgets/{1}').format(dashboard.id, widgetInstanceId))
                    .success(function() {

                    }).error(function() {
                    });

            };

            this.updateWidgets = function(dashboard) {
                return $http.put(('api/dashboards/{0}/widgets').format(dashboard.id), dashboard.widgets);
            };

            this.updateWidgetSettings = function(dashboard, widgetInstanceId, settings) {
                return $http.put(('api/dashboards/{0}/widgets/{1}/settings/').format(dashboard.id, widgetInstanceId), settings);
            };

            this.updateWidgetClass = function(dashboard, widgetInstanceId, className) {
                return $http.put(('api/dashboards/{0}/widgets/{1}?className={2}').format(dashboard.id, widgetInstanceId, className));
            };

            this.getActiveDashboard = function() {
                return this.activeDashboard;
            };

            this.setActiveDashboard = function(dashboard) {
                this.activeDashboard = dashboard;
                return this.activeDashboard;
            };

            return {
                dashboards: this.dashboards,
                activeDashboard: this.activeDashboard,
                init: this.init,
                getDefaultDashboards: this.getDefaultDashboards,
                getUserDefaultDashboards: this.getUserDefaultDashboards,
                getActiveDashboard: this.getActiveDashboard,
                setActiveDashboard: this.setActiveDashboard,
                updateWidgets: this.updateWidgets,
                addWidget: this.addWidget,
                deleteWidget: this.deleteWidget,
                reorderDashboard: this.reorderDashboard,
                renameDashboard: this.renameDashboard,
                publishDashboard: this.publishDashboard,
                unpublishDashboard: this.unpublishDashboard,
                addDashboard: this.addDashboard,
                deleteDashboard: this.deleteDashboard,
                updateWidgetSettings: this.updateWidgetSettings,
                switchDashboard: this.switchDashboard,
                updateWidgetClass: this.updateWidgetClass
            };
        }
    ]);

