angular.module('angus.directives').directive('window', [
    function() {
        return {
            restrict: 'E',
            scope: {
              window: '='
            },
            templateUrl: 'templates/authenticated/layout/window/window.html'
        };
    }
]);