angular.module('angus.services').service('customerService', [
    '$http',
    function($http) {

        function getForCustomerKey(subscriberId, customerKey) {
            return $http.get(('api/subscribers/{0}/customerAccounts/{1}').format(subscriberId, customerKey))
                .then(function(res){
                    return res.data;
                })
        }

        function getForCustomerId(subscriberId, includeDeleted, includeInactive, includeUnknown,
            customerId, divisionKeys, stringComparisonTypeKey, selectionCount) {

            return $http({
                url: ('api/subscribers/{0}/customerAccounts/customerIdSearch').format(subscriberId),
                method: 'POST',
                data: {
                    includeDeleted: includeDeleted,
                    includeInactive: includeInactive,
                    includeUnknown: includeUnknown,
                    customerId: customerId,
                    divisionKeys: divisionKeys,
                    stringComparisonTypeKey: stringComparisonTypeKey,
                    selectionCount: selectionCount
                }
            })
                .then(function(res){
                    return res.data;
                });
        }

        return {
            getForCustomerKey: getForCustomerKey,
            getForCustomerId: getForCustomerId
        }
    }
])