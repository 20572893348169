angular.module('angus.controllers').controller('hddTrackerReportListContainerCtrl', [
    '$scope', '$http', '$rootScope', '_', 'modalService', 'actionViewTemplates',
    function($scope, $http, $rootScope, _, modalService, actionViewTemplates) {

        'use strict';

        function init() {
            $http.get('api/subscribers/' + $rootScope.user.subscriberId + '/reports/' + $scope.widgetCode)
                .success(function(data) {
                    $scope.reports = data;
                    _($scope.reports).forEach(function(report) {
                        report.hddLogResultDisplay = $scope.getLogFileDisplayName(report.hddLogResultId);
                    });
                });
        }
        $scope.deleteReport = function(report) {
            var scope = {
                title: 'Delete ' + report.name + '?',
                message: 'Are you sure you want to delete ' + report.name + '?'
            };


            modalService
                .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                .then(function(result) {
                    if (result)
                        $http.delete(('api/subscribers/{0}/reports/{1}/{2}').format($rootScope.user.subscriberId, $scope.widgetCode, report.id))
                        .success(function() {
                            _.remove($scope.reports, function(r) {
                                return r.id == report.id;
                            });
                        });
                });
        };

        $scope.openReport = function(report) {
            $scope.addNewOpenReport(report);
        };

        init();
    }
]);
