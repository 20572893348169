angular.module( 'angus.directives' ).directive( 'budgetVar', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		return {
			scope: {
				model      : "=actionModel",
				settings   : "=budgetVarWidgetSettings",
				widgetCode : "="
			},
			templateUrl: actionViewTemplates.budgetVarContainer
		};
	}
] );