angular.module('angus.services').service('containerService', [
    '$http',
    function($http) {

        function getContainerTypes(subscriberId, divisionIds, productCategoryIds, productIds, deliveryTypeIds, adeptGroupsIds) {
            return queryContainerTypes(subscriberId, divisionIds, productCategoryIds, productIds, deliveryTypeIds, adeptGroupsIds)
                .then(function(res){
                    return res.data;
                });
        }

        function getForCustomerKey(subscriberId, customerKey) {
            return $http.get(('api/subscribers/{0}/customerAccounts/{1}/containers?paging=false').format(subscriberId, customerKey))
                .then(function(res){
                    return res.data;
                });
        }

        function queryContainerTypes(subscriberId, divisionIds, productCategoryIds, productIds, deliveryTypeIds, adeptGroupIds) {
            var data = {
                divisionIds: divisionIds,
                productCategoryIds: productCategoryIds,
                productIds: productIds,
                deliveryTypeIds: deliveryTypeIds,
                adeptGroupIds: adeptGroupIds
            }

            return $http.post(('api/subscribers/{0}/containers/containerTypes/').format(subscriberId), data);
        }

        return {
            getContainerTypes: getContainerTypes,
            getForCustomerKey: getForCustomerKey
        };
    }
])