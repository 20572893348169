angular.module('angus.directives')
  .directive('propaneProductsSelect', [function () {
    'use strict';

    const propaneProductCategoryKey = 1;

    return {
      restrict: 'A',
      scope: {
        ngModel: '=',
        subscriberId: '='
      },
      controller: ['$scope', '$rootScope', 'productsService', '$q', function ($scope, $rootScope, productsService, $q) {
        $q.all([
          productsService.getProducts($scope.subscriberId),
          productsService.getBriteProducts($rootScope.user.subscriberId)
        ])
        .spread(function (products, briteProducts) {

          var resolve = [];
          for(var i = 0, length = products.length; i < length; i++) {
            var product = products[i];

            // confirm this product is a propane product by cross checking briteProducts
            for(var x = 0, xlength = briteProducts.length; x < xlength; x++) {
              var briteProduct = briteProducts[x];

              // match found
              if(briteProduct.reportingId === product.id) {
                if(briteProduct.productCategoryKey === propaneProductCategoryKey) {
                  resolve.push(product);
                }
                break;
              }

            }
          }

          $scope.products = resolve;
        });
      }],
      template: '<div multi-select-ok-wrapped="products" ng-model="ngModel" display-prop="\'name\'" selection-prop="\'id\'"></div>',
    };
  }]);
