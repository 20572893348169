angular.module('angus.directives')
    .directive('savedFilters', [
        function() {
            'use strict';

            return {
                restrict: 'A',
                scope: {
                    subscriberId: '=',
                    widgetCode: '=',
                    filterChange: '&savedFilters',
                    filter: '=',
                    grid: '='
                },
                controller: ['$scope', '$q', 'fluentRest', 'modalService', 'actionViewTemplates', '_', function($scope, $q, fluentRest, modalService, actionViewTemplates, _) {
                    $scope.currentFilterId = null;

                    function getSavedFilters() {
                        return fluentRest
                            .api()
                            .subscribers($scope.subscriberId)
                            .reports($scope.widgetCode)
                            .get()
                            .then(function(filters) {
                                filters.unshift({id: null, name: ''});
                                $scope.filters = filters;
                                $scope.currentFilterId = $scope.currentFilterId || null;
                            });
                    }

                    $scope.savedFilterChange = function() {
                        var filter = _.find($scope.filters, function(filter) {
                            return filter.id == $scope.currentFilterId;
                        });

                        filter = filter && filter.id ? filter : null;

                        if($scope.grid) {
                            if(filter) {
                                $scope.grid.setState(filter.gridState);
                            } else {
                                $scope.grid.setState();
                            }
                        }

                        $scope.filterChange({filter: filter});
                    };

                    $scope.saveFilters = function(saveAs) {
                        return $q
                            .when(saveAs ? saveFiltersAs() : null)
                            .then(function(name) {
                                if(name === undefined) return;
                                return saveFilters(name);
                            });
                    };

                    $scope.deleteFilters = function() {

                        var filter = _.find($scope.filters, function(savedFilter) {
                            return savedFilter.id == $scope.currentFilterId;
                        });

                        if(!filter) return;

                        var scope = {
                            title: 'Delete ' + filter.name + '?',
                            message: 'Are you sure you want to delete ' + filter.name + '?'
                        };


                        modalService
                            .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                            .then(function(result) {
                                if(result)
                                    return fluentRest
                                        .api()
                                        .subscribers($scope.subscriberId)
                                        .reports($scope.widgetCode, filter.id)
                                        .delete()
                                        .then(function() {
                                            return getSavedFilters()
                                                .then(function() {
                                                    $scope.currentFilterId = null;
                                                    $scope.filterChange({filter: null});
                                                });
                                        });
                            });
                    };

                    function saveFilters(name) {
                        var data = $scope.filter;

                        var url = fluentRest
                            .api()
                            .subscribers($scope.subscriberId);

                        var promise;

                        if($scope.grid) {
                            data.gridState = $scope.grid.getCurrentState();
                        }

                        if(name) {
                            data.name = name;
                            promise = url
                                .reports($scope.widgetCode).post(data)
                                .then(function(data) {
                                    $scope.currentFilterId = data.id;
                                });
                        } else
                            promise = url
                                .reports($scope.widgetCode, $scope.currentFilterId)
                                .put(data);

                        return promise
                            .then(function() {
                                return getSavedFilters();
                            });
                    }

                    function saveFiltersAs() {
                        var scope = {
                            validationUrl: ('api/subscribers/{0}/reports/' + $scope.widgetCode + '/nameAvailable?name={1}'),
                            validationKey: 'name',
                            modalTitle: 'Save Filters'
                        };

                        return modalService.openTopBarModal(actionViewTemplates.saveReportFilter, scope);
                    }

                    getSavedFilters();

                }],
                template:
                '<div class="panel panel-info">' +
                    '<div class="panel-heading ">' +
                        'Saved Settings' +
                    '</div>' +
                    '<div class="panel-body">' +
                        '<select class="form-control" ng-model="currentFilterId" ng-options="filter.id as filter.name for filter in filters" ng-change="savedFilterChange()"/>' +
                    '</div>' +
                    '<div class="panel-footer">' +
                        '<div class="row">' +
                            '<div class="col-md-12">' +
                                '<div class="btn-group pull-right">' +
                                    '<button class="btn btn-default" ng-click="saveFilters()" data-ng-if="!!currentFilterId">Save</button>' +
                                    '<button class="btn btn-default" ng-click="saveFilters(true)">' +
                                        '<span data-ng-if="!currentFilterId">Create New</span>' +
                                        '<span data-ng-if="currentFilterId">Save As</span>' +
                                    '</button>' +
                                    '<button class="btn btn-danger" data-ng-if="!!currentFilterId" ng-click="deleteFilters()">Delete</button>' +
                                '</div>' +
                            '</div>' +
                        '</div>' +
                    '</div>' +
                '</div>'
            };
        }
    ]);
