angular.module('angus.directives').directive('dailyDetail',
[
    '$document', '$http', 'actionViewTemplates', 'moment',
    function ($document, $http, actionViewTemplates, moment) {
        return {
            controller: [
                '$scope', '$http', function ($scope) {
                    $scope.close = function () {
                        if ($scope.closeGraphViewCb) $scope.closeGraphViewCb();
                        if ($scope.modalClosedCb) $scope.modalClosedCb();
                    }; 

                    if ($scope.forecast.hourly && $scope.forecast.hourly.hours.length > 0) { 
                        var hours = $scope.forecast.hourly.hours;    
                        var hourlyData = _.filter(hours, function(hour) { 
                            return (moment(hour.forecastStart).format('YYYY-MM-DD') === moment($scope.selectedDate).format('YYYY-MM-DD'));
                        });   

                        $scope.hourlyForcast = hourlyData;
                        var summaryRun  = { start: 0, stop: 0, summary: hourlyData[0].conditionCode, cnt: 0 };
                        var summaries   = [];
                        var weight = 0; 
                        
                        for(var i=1; i < hourlyData.length; i++){ 
                            if( hourlyData[i].conditionCode !== hourlyData[i-1].conditionCode){
                                summaryRun.stop = i-1;
                                summaryRun.cnt = summaryRun.stop - summaryRun.start + 1;
                                summaries.push(summaryRun); 
                                //Reset the start/stop
                                summaryRun = {
                                    start   : i,
                                    stop    : i,
                                    summary : hourlyData[i].conditionCode || '',
                                    cnt : 0
                                };
                            }
                        }
                        summaryRun.stop = hourlyData.length;
                        weight = hourlyData.length - summaryRun.start;
                        summaryRun.cnt = weight;
                        summary: hourlyData[hourlyData.length-1].conditionCode;
                        summaries.push(summaryRun);
                    }
                    else {
                        $scope.forecast.current = {}; 
                        $scope.forecast.hourly = {hours: []};
                    }  
                    $scope.summaries = summaries || []; 
                }
            ],
            templateUrl: actionViewTemplates.weatherHourlyDetailContent,
            link: function (scope, element, attrs, ctrl) {

                scope.modalClosedCb = function () {
                    $document.unbind('click');
                };

                element.bind('click', function (e) {
                    e.stopPropagation();
                });

                // $document.bind('click', function () {
                //     scope.$apply(scope.closeGraphViewCb);
                //     if (scope.modalClosedCb) scope.modalClosedCb();
                // });

                scope.standardizePropertyName = function () {
                    return scope.propertyName.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
                };

                scope.propertyDate = function() {
                    return scope.selectedDate;
                };

            }
        };
    }
]);
