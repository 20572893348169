angular.module('angus.controllers').controller('driverPerfSettingsCtrl', [
    '$scope', '$rootScope', '$http', '_', 'constantsService', 'productsService',   'hierarchyService', 'restrictedAccessService',
    function($scope, $rootScope, $http, _, constantsService, productsService, hierarchyService, restrictedAccessService) {
        'use strict';
        var dcSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.deliveryCenter.nodes, $scope.widget.instance.settings.hierarchyNodeId);

        $scope.drivers = [];
        $scope.settings = {
            excludeDriverIds: []
        };

        restrictedAccessService
            .getAccess()
            .then(function(access) {
                $scope.restrictedAccess = access.restrictedAccess;
            });

        function init() {
            $scope.periodIndicators = constantsService.periodIndicators.enums;
            productsService.getProducts()
                .then(function(products) {
                    $scope.products = products;

                    return $http({
                        url     : ('api/subscribers/{0}/drivers').format($rootScope.user.subscriberId),
                        method  : 'POST',
                        data    : {
                            deliveryCenter: dcSetting,
                            excludeDrivers: true
                        }
                    });
                })
                .then(function(drivers) {
                    _.each($scope.widget.instance.settings.excludeDriverIds.value, function(selection) {
                        var update = _.findIndex(drivers.data, function(driver) {
                            return driver.id == selection;
                        });
                        if (update >= 0) drivers.data[update].ticked = true;
                    });
                    $scope.drivers = drivers.data
                });
        }

        init();

        $scope.updateDriverExcludes = function(model) {
            if (model)
                $scope.widget.instance.settings.excludeDriverIds.value = angular.copy(model);
        };

        $scope.openDeliveryHierarchyView = function() {
            hierarchyService.openDeliveryCenterModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                    dcSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.deliveryCenter.nodes, $scope.widget.instance.settings.hierarchyNodeId);
                });
        };

    }
]);
