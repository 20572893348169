angular.module( 'angus.directives' ).directive( 'snapShots', [
	'actionViewTemplates',
	function(actionViewTemplates ) {
		
		'use strict';

		return {
			scope: {
				closeCb     : '=',
				model : '=actionModel',
				widgetClass : '='
			},
			templateUrl: actionViewTemplates.snapShotsContent
		};
	}
] );