angular.module( 'angus.controllers' ).controller( 'tmsExceptionsCtrl', [
	'$scope', '$rootScope', 'Paging', 'actionViewTemplates', 'constantsService', 'fluentRest', 'datePickerService', 'moment', 'subscriberService',
	function( $scope, $rootScope, paging, actionViewTemplates, constantsService, fluentRest, datePickerService, moment, subscriberService ) {
		'use strict';

		$scope.selectedDealer = null;

		$scope.initialLoad = true;
		$scope.exceptionsLoaded = false;

		$scope.openDatePicker = function() {
				datePickerService
				.openDatePickerModal($scope.startDate,$scope.endDate, moment())
				.spread(function(startDate, endDate) {
					if (startDate && endDate) {
						$scope.startDate = startDate;
						$scope.endDate = endDate;

						$scope.getDealers();
					}
				});
		};

		$scope.getDealers = function( ) {		
			
			if ($scope.initialLoad) {
				/* Don't execute the report by default since it can be taxing to the system */
				$scope.initialLoad = false;
				return Promise.resolve();
			}
			else if (!$scope.exceptionsLoaded) {
				$scope.exceptionsLoaded = true;
			}

			var params ={
				showExceptions 	: 1,
				searchTerm 		: $scope.searchTerm,
				sortBy 			: $scope.sortBy,
				ascending		: $scope.ascending ? 1 : 0,
				eg 				: 'tms' 
			};

			//get valid subscribers first
			var promise =  subscriberService.getAmsSubscribers()
			.then(function(subscribers){
				$scope.subscribers = _.filter(subscribers, function(subscriber){
					return subscriber.isPaygo && subscriber.registrationStatusKey == constantsService.registrationStatus.live;
				});

				var promise = fluentRest
					.api()
					.subscribers()
					.dealers()
						.get(params)
						.then(function(dealers){
							$scope.widget.setDefaultActionModel({
								exceptionGroups : 'tms'
							});  
							//filter based on active un-deleted dealers
							$scope.dealers = _.filter(dealers, function(dealer){
								return _.some($scope.subscribers, function(subscriber){ 
									if (dealer.subscriberId == subscriber.id) {
										dealer.subscriber = subscriber.name
										dealer.total = Object.hasOwn(dealer.exceptions, 'new') ? dealer.exceptions.new.total : 0;
										return true;
									}
								});
							}); 
							$scope.sort($scope.sortBy, true)
						});

				if($scope.widget) $scope.widget.promises.monitor(promise);		 
			}); 

			return promise;
		}

		$scope.loadExceptionsClick = function() {
			init();
		}

		$scope.clearSearch = function() {
			$scope.searchTerm = '';
			$scope.getDealers();
		};

		$scope.dealerSelected = function(dealer) { 
			$scope.selectedDealer = dealer.id;
			$scope.widget.openDetailView( actionViewTemplates.paygoExceptionsActionView, {	activeDealerIds : [dealer.id]} );
		};

		$scope.subscriberSelected = function(dealer) { 
			$scope.selectedDealer = dealer.id;
			$scope.widget.openDetailView( actionViewTemplates.paygoExceptionsActionView, {	activeSubscriberId : dealer.subscriberId} );
		};

		$scope.sort = function( sortBy, ascending ) {
			$scope.ascending = !ascending ? true : false;  
			$scope.sortBy = sortBy;
			
			function compareAsc( a, b ) {
				if ( a[sortBy] < b[sortBy] ){
					return -1;
				}
				if ( a[sortBy] > b[sortBy] ){
					return 1;
				}
				return 0;
			}
			
			function compareDes( a, b ) {
				if ( a[sortBy] > b[sortBy] ){
					return -1;
				}
				if ( a[sortBy] < b[sortBy] ){
					return 1;
				}
				return 0;
			}

			if (ascending)
				$scope.dealers = $scope.dealers.sort( compareAsc ); 
			else
				$scope.dealers = $scope.dealers.sort( compareDes ); 

		};

		$scope.rowSelected = function(dealerId) {
			return dealerId == $scope.selectedDealer;
		}

		function init() {
			$scope.paging = new paging( $scope.pageSize || 5 );
			$scope.searchTerm ='';
			$scope.sortBy = 'subscriber';
			$scope.ascending = true;
			return $scope.getDealers();			
		}

        $scope.widget.setDefaultInitialization(init);

        $scope.$on('widgetSettingsChanged', function() {
           init();
        });

		$scope.$watch(function(){
			return $scope.searchTerm;
		}, function(newVal, oldVal){
			if(newVal != oldVal)
				$scope.getDealers();
		});

	}
]);