angular.module('angus.controllers').controller('serviceCallsSettingsCtrl', [
    '$scope', '$rootScope', '$http', '_', 'constantsService', 'productsService', 'hierarchyService',
    function($scope, $rootScope, $http, _, constantsService, productsService, hierarchyService) {

        'use strict';
        $scope.widget.instance.settings.callReasons = $scope.widget.instance.settings.callReasons || {};
        $scope.widget.instance.settings.techs = $scope.widget.instance.settings.techs || {};

        $http.get(('api/subscribers/{0}/service/techs').format($rootScope.user.subscriberId))
            .then(function(list) {
                $scope.techs = list.data;

                _.forEach($scope.widget.instance.settings.techs.value, function(selection) {
                    var update = _.findIndex($scope.techs, function(tech) {
                        return tech.id == selection;
                    });
                    if (update >= 0) $scope.techs[update].excludeTicked = true;
                });
            });

        $http.get(('api/subscribers/{0}/service/callReasons/list').format($rootScope.user.subscriberId))
        .then(function(list) {
            $scope.callReasons = list.data;

            _.each($scope.widget.instance.settings.callReasons.value, function(selection) {
                var update = _.findIndex($scope.callReasons, function(callReasons) {
                    return callReasons.id == selection;
                });
                if (update >= 0) $scope.callReasons[update].excludeTicked = true;
            });
        });

        $scope.periodIndicators = constantsService.servicePeriodIndicators.enums;
        $scope.serviceRevenueOptions = constantsService.serviceRevenueCategories.enums;

        $scope.openHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };

        $scope.updateTechs = function(model) {
            if (model)
                $scope.widget.instance.settings.techs.value = angular.copy(model);
        };

        $scope.updateCallReasons = function(model) {
            if (model && _.isArray(model))
                $scope.widget.instance.settings.callReasons.value = angular.copy(model);
        };

    }
]);
