angular.module('angus.directives')
    .directive('acquisitionSourceSelect', [function(){
    	'use strict';

        return {
            restrict: 'A',
            scope: {
                ngModel: '=',
                subscriberId: '='
            },
            controller : ['$scope', 'acquisitionSourceService', function($scope, acquisitionSourceService){
            	acquisitionSourceService
					.getAcquisitionSource($scope.subscriberId)            
            		.then(function(acquisitionSource) {
                		$scope.acquisitionSource = acquisitionSource;
            		});            	
            }],            
            template : '<div multi-select-ok-wrapped="acquisitionSource" ng-model="ngModel" display-prop="\'name\'" selection-prop="\'id\'"></div>',            
        };
    }]);
