angular.module('angus.directives')
    .directive('onEnter', [
        '$parse', '$document', function($parse, $document) {
            'use strict';

            return function(scope, element, attr) {
                var fn    = $parse(attr.onEnter);
                var fnEsc = $parse(attr.onEscape);

                var e = !attr.isModal ? element : $document;

                function handler(event) {
                    if (event.which === 13) {
                        if(attr.isModal){
                            $document.unbind('keydown keypress', handler);
                        }
                        scope.$apply(function() {
                            fn(scope, { $event: event });
                        });
                    } else if (event.which === 27) {
                        if(attr.isModal){
                            $document.unbind('keydown keypress', handler);
                        }
                        scope.$apply(function() {
                            fnEsc(scope, { $event: event });
                        });
                    }
                }

                e.bind('keydown keypress', handler);

            };
        }
    ]);
