angular.module('angus.services').factory('forecastingService', [
    function () {
        function getDeliveryCategories() {
            return [{
                id: 1,
                name: 'ADEPT',
                isReportCategory: true
            }, {
                id: 2,
                name: 'Automatic (Non-ADEPT)',
                isReportCategory: true
            }, {
                id: 3,
                name: 'Will-Call (Non-ADEPT)',
                isReportCategory: false
                }];
        }

        return {
            getDeliveryCategories: getDeliveryCategories
        };
    }
]);