angular.module('angus.controllers').controller('forgotPasswordCtrl', [
    '$scope', '$http', '$state', 'brandingService',
    function($scope, $http, $state, brandingService) {

        $scope.branding = brandingService;
        $scope.disableRecoverPassword = false;
        $scope.showTerms = false;
        $scope.successMessage = "";
        $scope.errorMessage = "";
        $scope.toggleTerms = function(){
            $scope.showTerms = !$scope.showTerms;
        }

        $scope.forgotPassword = function() {
            $scope.successMessage = "";
            $scope.errorMessage = "";
            $scope.disableRecoverPassword = true;

            var data = {
                userName: $scope.username
            }

            return $http.post('/identity/forgot-password', data, {apiV2: true})
                .then(function() {
                    $scope.disableRecoverPassword = false;
                    $scope.successMessage = "If an account with the provided username exists, a password reset link has been sent to your email. Please check your inbox (and spam folder) and follow the instructions to reset your password.";
                })
                .catch(function(err) {
                    $scope.disableRecoverPassword = false;
                    $scope.errorMessage = 'We encountered an issue while processing your request. Please try again later, or contact support if the problem persists.'
                });
        };
    }
]);
