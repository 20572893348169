angular.module('angus.directives').directive('serviceContractTypeSelect', [
    function () {
        'use strict';

        return {
            restrict: 'A',
            scope: {
                ngModel: '=',
                subscriberId: '=',
            },
            controller: ['$scope', '$rootScope', '$http', function ($scope, $rootScope, $http) {
                $http.get(('api/subscribers/{0}/serviceContracts/types').format($scope.subscriberId || $rootScope.user.subscriberId))
                    .then(function (types) {
                        $scope.contractTypes = types.data;
                    });
            }],
            template: '<div multi-select-ok-wrapped="contractTypes" ng-model="ngModel" display-prop="\'serviceContractTypeName\'" selection-prop="\'id\'"></div>',
        };
    }
]);