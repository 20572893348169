angular.module('angus.directives').directive('hddVariance',
    [
        '$document', '$http', 'actionViewTemplates',
        function ($document, $http, actionViewTemplates) {
            return {
                controller: [
                    '$scope', '$http', function ($scope) {


                        $scope.TestValues = [];

                        $scope.TestValues = [
                            {
                                "data": [[8, 0], [9, 200], [10, -100]]
                            }
                        ];

                        $scope.close = function () {
                            if ($scope.closeGraphViewCb) $scope.closeGraphViewCb();
                            if ($scope.modalClosedCb) $scope.modalClosedCb();
                        };
                    }
                ],
                templateUrl: actionViewTemplates.hddTrackerContent,
                link: function (scope, element, attrs, ctrl) {

                    scope.modalClosedCb = function () {
                        $document.unbind('click');
                    };

                    element.bind('click', function (e) {
                        e.stopPropagation();
                    });

                    $document.bind('click', function () {
                        scope.$apply(scope.closeGraphViewCb);
                        if (scope.modalClosedCb) scope.modalClosedCb();
                    });

                    scope.standardizePropertyName = function () {
                        return scope.propertyName.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
                    };

                    scope.propertyDate = function () {
                        return scope.selectedDate;
                    };

                    var Actual = [[1, 0], [2, 4], [3, 75], [4, 101], [5, 0], [6, 0], [7, 0], [8, 0], [9, 0], [10, 0], [11, 0], [12, 0]];
                    var Normal = [[1, 12], [2, 1], [3, 26], [4, 143], [5, 100], [6, 56], [7, 45], [8, 35], [9, 110], [10, 30], [11, 15], [12, 30]];


                    data = [{ data: Actual, label: "Actual", lines: { show: true, lineWidth: 3 }, curvedLines: { apply: true } }
                        , { data: Normal, label: "Normal", lines: { show: true, lineWidth: 3 }, curvedLines: { apply: true } }
                    ]

                    options = {
                        legend: { position: "nw", backgroundcolor: null },

                        series: {
                            curvedLines: {
                                active: true
                            }
                        },
                        xaxis: {
                            // ticks: [[7,'jul'], [8,'aug'], [9,'sep'], [10,'oct'], [11,'nov'], [12,'dec'],[1,'jan'], [2,'feb'], [3,'mar'], [4,'apr'], [5,'may'], [6,'jun']],
                            ticks: [[1, 'jul'], [2, 'aug'], [3, 'sep'], [4, 'oct'], [5, 'nov'], [6, 'dec'], [7, 'jan'], [8, 'feb'], [9, 'mar'], [10, 'apr'], [11, 'may'], [12, 'jun']],
                            tickColor: '#C0C0C0',
                            yaxis: { min: 1, max: 110 }
                        }

                    };

                    $.plot($(".flot-graph"), data, options);


                    $("<div id='tooltip'></div>").css({
                        position: "absolute",
                        display: "none",
                        border: "1px solid #fdd",
                        padding: "2px",
                        "background-color": "#fee",
                        opacity: 0.80
                    }).appendTo(".topBarModalContent");

                    $('.flot-graph').bind("plothover", function (event, pos, item) {
                        if (item) {
                            var x = item.datapoint[0].toFixed(0),
                                y = item.datapoint[1].toFixed(0);

                            $('#tooltip').html('HDD Variance of Week: ' + x + '=' + y)
                                .css({ top: item.pageY + 5, left: item.pageX + 5 })
                                .fadeIn(200);
                        } else {
                            $('#tooltip').hide();
                        }
                    });



                }
            };
        }
    ]);