angular.module('angus.services').factory('registrationStatusService', [
    '$http',
    function($http) {

        function getRegistrationStatuses() {
            return queryRegistrationStatus()
                .then(function (res) {
                    return res.data;
                });
        }

        function queryRegistrationStatus() {
            return $http.get('api/registrationStatus');
        }

        return {
            getRegistrationStatuses: getRegistrationStatuses
        }
    }
]);