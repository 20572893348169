angular.module('angus.directives').directive('anModalGraph', [
    '$document', '$http', 'actionViewTemplates', 'dateToString', '$', '_',
    function($document, $http, actionViewTemplates, dateToString, $, _) {
        return {
            controller: [
                '$scope', '$http',
                function($scope) {
                    $scope.graphValues = [];
                    $scope.renderComplete = false;
                }
            ],
            templateUrl: actionViewTemplates.modalGraphContent,
            link: function(scope, element) {

                scope.modalClosedCb = function() {
                    $document.unbind('click');
                };

                element.bind('click', function(e) {
                    e.stopPropagation();
                });

                $document.bind('click', function() {
                    scope.$apply(scope.closeGraphViewCb);
                    if (scope.modalClosedCb) scope.modalClosedCb();
                });

                scope.standardizePropertyName = function() {
                    return scope.propertyName.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) {
                        return str.toUpperCase();
                    });
                };

                function getMonthTicks() {
                    scope.monthValues = [];
                    var monthTicks = [];

                    for (var z = 0; z < scope.graphValues.length; z++) {
                        if ((z % 4) === 0)
                            monthTicks.push([z, dateToString.formatDate(scope.graphValues[z][0])]);
                        else
                            monthTicks.push([z, 0]);

                        scope.monthValues.push([z, dateToString.formatDate(scope.graphValues[z][0])]);
                    }
                    return monthTicks;
                }

                function updateCss() {
                    $('.flot-graph').bind('plothover', function(event, pos, item) {
                        if (item) {
                            var y = item.datapoint[1].toFixed(2);
                            var x = scope.monthValues[item.datapoint[0]][1];

                            $('#tooltip').html(y + ' for week of ' + x)
                                .css({
                                    top: item.pageY - 35,
                                    left: item.pageX - 35
                                })
                                .fadeIn(200);
                        } else {
                            $('#tooltip').hide();
                        }
                    });

                    $('.yAxis div').css('left', '5px');
                    $('.xAxis div').css('top', '590px').each(function() {
                        var left = $(this).css('left');
                        $(this).css('left', Number(left.substring(0, left.length - 2)) + 25 + 'px');
                    });
                }

                function plotGraph() {
                    scope.options = {
                        series: {
                            bars: {
                                show: true
                            },
                            points: {
                                show: true
                            },
                        },
                        grid: {
                            hoverable: true,
                            clickable: true
                        },
                        xaxis: {
                            ticks: getMonthTicks()
                        },
                        yaxis: {
                            position: 'left',
                            labelWidth: 25
                        }
                    };

                    var result = [];
                    for (var i = 0; i < scope.graphValues.length; i++)
                        result.push([i, scope.graphValues[i][1]]);

                    $.plot($('.flot-graph'), [result], scope.options);
                    updateCss();
                }

                scope.getGraphValues().then(function(result) {
                    if (result) {
                        _.forEach(result.data, function(item) {
                            scope.graphValues.push([item.yearWeek, item[scope.propertyName]]);
                        });

                        if (scope.graphValues.length > 0)
                            plotGraph();
                    }
                    scope.renderComplete = true;
                });

            }
        };
    }
])
.directive('anModalDualGraph', [
    '$document', '$http', 'actionViewTemplates', 'dateToString', '$', '_',
    function($document, $http, actionViewTemplates, dateToString, $, _) {
        return {
            controller: [
                '$scope', '$http',
                function($scope) {
                    $scope.graphValues = [];
                    $scope.otherGraphValues = [];
                    $scope.renderComplete = false;
                }
            ],
            templateUrl: actionViewTemplates.modalGraphContent,
            link: function(scope, element) {

                scope.modalClosedCb = function() {
                    $document.unbind('click');
                };

                element.bind('click', function(e) {
                    e.stopPropagation();
                });

                $document.bind('click', function() {
                    scope.$apply(scope.closeGraphViewCb);
                    if (scope.modalClosedCb) scope.modalClosedCb();
                });

                scope.standardizePropertyName = function() {
                    return scope.propertyName.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) {
                        return str.toUpperCase();
                    });
                };

                function getMonthTicks() {
                    scope.monthValues = [];
                    var monthTicks = [];

                    for (var z = 0; z < scope.graphValues.length; z++) {
                        if ((z % 4) === 0)
                            monthTicks.push([z, dateToString.formatDate(scope.graphValues[z][0])]);
                        else
                            monthTicks.push([z, 0]);

                        scope.monthValues.push([z, dateToString.formatDate(scope.graphValues[z][0])]);
                    }
                    return monthTicks;
                }

                function updateCss() {
                    $('.flot-graph').bind('plothover', function(event, pos, item) {
                        if (item) {
                            var y = item.datapoint[1].toFixed(2);
                            var x = scope.monthValues[item.datapoint[0]][1];

                            $('#tooltip').html(y + ' for week of ' + x)
                                .css({
                                    top: item.pageY - 35,
                                    left: item.pageX - 35
                                })
                                .fadeIn(200);
                        } else {
                            $('#tooltip').hide();
                        }
                    });

                    $('.yAxis div').css('left', '5px');
                    $('.xAxis div').css('top', '590px').each(function() {
                        var left = $(this).css('left');
                        $(this).css('left', Number(left.substring(0, left.length - 2)) + 25 + 'px');
                    });
                }

                function plotGraph() {
                    scope.options = {
                        stack: true,
                        series: {
                            bars: {
                                show: true
                            },
                            points: {
                                show: true
                            },
                        },
                        grid: {
                            hoverable: true,
                            clickable: true
                        },
                        xaxis: {
                            ticks: getMonthTicks()
                        },
                        yaxis: {
                            position: 'left',
                            labelWidth: 25
                        },
                        legend: {
                            show: true,
                            noColumns: 2,                            
                            position: 'ne',
                            backgroundOpacity: 0.5      
                        }
                    };

                    var result      = {label: ' Gains ', data:[]};
                    var otherResult = {label: ' Losses ', data:[]};
                    for (var i = 0; i < scope.graphValues.length; i++){
                        result.data.push([i, scope.graphValues[i][1]]);
                    }

                    for (i = 0; i < scope.otherGraphValues.length; i++){
                        otherResult.data.push([i, scope.otherGraphValues[i][1]]);
                    }

                    $.plot($('.flot-graph'), [result, otherResult], scope.options);
                    updateCss();
                }

                scope.getGraphValues().then(function(result) {
                    if (result) {
                        var sorted = _.sortBy(result.data, function(item){ return item.yearWeek})
                        _.forEach(sorted, function(item) {
                            if(item[scope.checkProp])
                                scope.graphValues.push([item.yearWeek, item[scope.propertyName]]);
                            else
                                scope.otherGraphValues.push([item.yearWeek, item[scope.propertyName]]);
                        });

                        if (scope.graphValues.length > 0)
                            plotGraph();
                    }
                    scope.renderComplete = true;
                });

            }
        };
    }
])
.directive('anModalAcctGainLossByTankHistoryGraph', [
    '$document', '$http', 'actionViewTemplates', 'dateToString', '$', '_', 'constantsService',
    function($document, $http, actionViewTemplates, dateToString, $, _, constantsService) {
        return {
            templateUrl: actionViewTemplates.modalAcctGainLossByTankContent,
            link: function(scope, element) {

                scope.graphValues = [[], [], [], []];
                scope.renderComplete = false;

                var result = [];

                scope.modalClosedCb = function() {
                    $document.unbind('click');
                };

                element.bind('click', function(e) {
                    e.stopPropagation();
                });

                $document.bind('click', function() {
                    scope.$apply(scope.closeGraphViewCb);
                    if (scope.modalClosedCb) scope.modalClosedCb();
                });

                function getMonthTicks() {
                    scope.monthValues = [];
                    var monthTicks = [];

                    for (var z = 0; z < scope.graphValues[0].length; z++) {
                        if ((z % 4) === 0)
                            monthTicks.push([z, dateToString.formatDate(scope.graphValues[0][z].yearWeek)]);
                        else
                            monthTicks.push([z, 0]);

                        scope.monthValues.push([z, dateToString.formatDate(scope.graphValues[0][z].yearWeek)]);
                    }
                    return monthTicks;
                }

                function updateCss() {
                    $('.flot-graph').bind('plothover', function(event, pos, item) {
                        if (item) {
                            var y = item.datapoint[1].toFixed(2);
                            var x = scope.monthValues[item.datapoint[0]][1];

                            $('#tooltip').html(y + ' for week of ' + x)
                                .css({
                                    top: item.pageY - 35,
                                    left: item.pageX - 35
                                })
                                .fadeIn(200);
                        } else {
                            $('#tooltip').hide();
                        }
                    });

                    $('.yAxis div').css('left', '5px');
                    $('.xAxis div').css('top', '590px').each(function() {
                        var left = $(this).css('left');
                        $(this).css('left', Number(left.substring(0, left.length - 2)) + 25 + 'px');
                    });
                }

                function determineDefaultDisplay(displayName, deliveryTypeSetting){
                    switch(deliveryTypeSetting){
                        case(constantsService.includeExcludeOnly.exclude.key):
                            return displayName == constantsService.gainLossType.gain.value.display ||
                                displayName == constantsService.gainLossType.loss.value.display;
                        case(constantsService.includeExcludeOnly.only.key):
                            return displayName == constantsService.gainLossType.toAuto.value.display ||
                                displayName == constantsService.gainLossType.toWillCall.value.display;
                        default:
                            return true;
                    }
                }

                var colors = ['#0099D3', '#49BF67', '#EDC240', '#CB4B4B'];

                function plotGraph() {
                    scope.options = {
                        stack: true,
                        series: {
                            bars: {
                                show: true
                            },
                            points: {
                                show: true
                            },
                        },
                        grid: {
                            hoverable: true,
                            clickable: true
                        },
                        xaxis: {
                            ticks: getMonthTicks()
                        },
                        yaxis: {
                            position: 'left',
                            labelWidth: 25
                        },
                        legend: {
                            show: false,
                            // noColumns: 1,                            
                            // position: 'ne',
                            // backgroundOpacity: 0.5
                        }
                    };

                    for (var j = 0; j < 4; j++){

                        var lableName = constantsService.gainLossType.enums[j].value.display;

                        var dataSet = {
                            label : lableName,
                            data  : [],
                            color : colors[j],
                            index : j,
                            defaultDisplay: determineDefaultDisplay(lableName, scope.deliveryType)
                        };

                        for (var i = 0; i < scope.graphValues[j].length; i++){
                            dataSet.data.push([i, scope.graphValues[j][i].netGain]);
                        }

                        result.push(dataSet);
                    }

                    // insert checkboxes 
                    var choiceContainer = $('#choices');
                    _.forEach(result, function(item) {
                        choiceContainer.append('<div class="checkbox"><label>' +
                        '<input type="checkbox" name="' + item.index + 
                            '" ' + (item.defaultDisplay ? 'checked="checked" ' : '') + 'id="id' + item.index + '"></input>' +
                            '<label style="padding-left:0;" for="id' + item.index + '">' + item.label + '</label>' + 
                            '<i class="fa fa-square pull-right" style="color: ' + item.color + '; padding-top: 4px;"></i></div>');
                    });

                    function plotAccordingToChoices() {

                        var data = [];

                        choiceContainer.find('input:checked').each(function () {
                            var key = $(this).attr('name');
                            if (key && result[key]) {
                                data.push(result[key]);
                            }
                        });

                        if (data.length > 0) {
                            $.plot($('.flot-graph'), data, scope.options);
                        }
                    }

                    choiceContainer.find('input').click(plotAccordingToChoices);

                    plotAccordingToChoices();
                    
                    updateCss();
                }                          

                scope.getGraphValues().then(function(result) {
                    if (result) {
                        _.forEach(result.data, function(item) {
                               scope.graphValues[item.gainLossType - 1].push(item); 
                            });

                        plotGraph();
                    }
                    scope.renderComplete = true;
                });

            }
        };
    }
]);