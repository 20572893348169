angular.module('angus.controllers').controller('serviceReworkSettingsCtrl', [
    '$scope', '$rootScope', '$http', '_', 'constantsService', 'productsService', 'hierarchyService',
    function ($scope, $rootScope, $http, _, constants, productsService, hierarchyService) {

      'use strict';

      $http.get(('api/subscribers/{0}/service/techs').format($rootScope.user.subscriberId))
        .then(function (list) {
          $scope.techs = list.data;

          _.each($scope.widget.instance.settings.techs.value, function (selection) {
            var update = _.findIndex($scope.techs, function (tech) {
              return tech.id == selection;
            });
            if (update >= 0) $scope.techs[update].excludeTicked = true;
          });
        });

      $scope.periodIndicators = constants.reworkPeriodIndicators.enums;


      $scope.openHierarchyView = function () {
        hierarchyService
          .openServiceCenterModal($scope.widget.instance.settings.hierarchyNodeId)
          .then(function (nodeId) {
            $scope.widget.instance.settings.hierarchyNodeId = nodeId;
          });
      };

      $scope.updateTechs = function (model) {
        if (model)
          $scope.widget.instance.settings.techs.value = angular.copy(model);

      };

      $scope.updateCallCategory = function (model) {
        if (model)
          $scope.widget.instance.settings.serviceCallList.value = angular.copy(model);

      };
      $scope.serviceCallCategoryList = [
        constants.serviceCallCategory.get('installation').value, 
        constants.serviceCallCategory.get('a/c').value, 
        constants.serviceCallCategory.get('oil').value
      ];
    }
]);
