angular.module('angus.controllers').controller('customerPopoverCtrl', [
	'$scope', 'subscriberService',
	function($scope, subscriberService) {

		const subscriberId = subscriberService.getSubscriberId();

		$scope.retrieveCustomerTanksUrl = function(item) {
			return ('api/subscribers/{0}/customerAccounts/{1}/containers?paging=true').format(subscriberId, item.customerKey);
		};
	}
]);