angular.module('angus.services').service('lastServiceCallDate', ['$rootScope', '$http', '$q', '_', 'moment',
	function($rootScope, $http, $q, _, moment) {

		'use strict';

		var lastServiceCallDate = {};

		function getKey(serviceCenter, comparisonDate) {
			var serviceCenterKey = '';
			_.forEach(serviceCenter, function(serviceCenter) {
				serviceCenterKey += serviceCenter + ';';
			});

			return serviceCenterKey + '_' +
				(comparisonDate !== undefined ? '_' + moment(comparisonDate).format('L') : '');
		}

		function getLastServiceCallDate(serviceCenter, comparisonDate) {
			var key = getKey(serviceCenter, comparisonDate)
			if (lastServiceCallDate[key] !== undefined)
				return lastServiceCallDate[key];
			else
				return queryLastServiceCallDate(serviceCenter, comparisonDate, key);
		}

		function queryLastServiceCallDate(serviceCenter, comparisonDate, key) {
			return $http({
					url: ('api/subscribers/{0}/service/lastServiceCallDate').format($rootScope.user.subscriberId),
					method: 'POST',
					data: {
						serviceCenter: serviceCenter,
						comparisonDate: comparisonDate
					}
				})
				.then(function(response) {
					lastServiceCallDate[key] = response.data.date;
					return response.data.date;
				});
		}

		return {
			getLastServiceCallDate: function(serviceCenter, comparisonDate) {
				return $q.when(getLastServiceCallDate(serviceCenter, comparisonDate))
					.then(function(lastServiceCallDate) {
						return lastServiceCallDate ? moment(lastServiceCallDate, 'YYYY-MM-DD') : null;
					});
			}
		};
	}
]);