'use strict';

angular.module('angus.controllers').controller('liquidFuelsCtrl', [
    '$scope', '$rootScope', '$http', 'modalService', 'dateToString', 'productsService', 'periodIndicators', '$q', 'hierarchyService', 'actionViewTemplates', '_',
    'constantsService', 'moment', 'datePickerService', 'lastInvoiceDateService', 'dateCalculator', 'fiscalYearService',
    function($scope, $rootScope, $http, modalService, dateToString, productsService, periodIndicators, $q, hierarchyService, actionViewTemplates, _,
             constantsService, moment, datePickerService, lastInvoiceDateService, dateCalculator, fiscalYearService) {

        var divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.periodIndicators = constantsService.invoicePeriodIndicators.enums;
        $scope.periodIndicator = _.find(constantsService.invoicePeriodIndicators, function(indicator) {
            return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
        });

        function retrieveLiquidFuelsData() {
            $scope.widget.noContentFlag = false;

            return queryLiquidFuels()
                .then(function(response) {
                    if(!response || !response.data) {
                        $scope.widget.noContentFlag = true;
                        $scope.widget.instance.settings.customDate = null;
                        return null;
                    }

                    $scope.analysis = {};

                    _.forEach(response.data, function(summary) {
                        if(summary.id == 1)
                            $scope.analysis.cap = summary.units;
                        else if(summary.id == 2)
                            $scope.analysis.fixed = summary.units;
                        else if(summary.id == 3)
                            $scope.analysis.variable = summary.units;
                        else if(summary.id == -1)
                            $scope.analysis.unknown = summary.units;
                        else if(summary.id == -2)
                            $scope.analysis.unmapped = summary.units;
                    });

                    $scope.analysis.variable = ($scope.analysis.variable || 0) + ($scope.analysis.unknown || 0) + ($scope.analysis.unmapped || 0);

                    $scope.analysis.total = ($scope.analysis.cap || 0) + ($scope.analysis.fixed || 0) + ($scope.analysis.variable || 0);
                    if($scope.analysis.total && $scope.analysis.total > 0) {
                        $scope.analysis.capPct = (($scope.analysis.cap || 0) / $scope.analysis.total) * 100;
                        $scope.analysis.fixedPct = (($scope.analysis.fixed || 0) / $scope.analysis.total) * 100;
                        $scope.analysis.variablePct = (($scope.analysis.variable || 0) / $scope.analysis.total) * 100;
                    }

                    $scope.widget.setDefaultActionModel($scope.dates);
                });
        }

        function loadWidget() {
            $scope.widget.noContentFlag = false;

            var promise = lastInvoiceDateService.getLastInvoiceDate(divisions)
                .then(function(lastInvoiceDate) {

                    if(!$scope.dates) {
                        $scope.dates = {};

                        var dates = dateCalculator.calculatePeriod($scope.dates.periodStartDate, lastInvoiceDate || moment.utc(), $scope.periodIndicator.key);

                        if($scope.periodIndicator.key === 'fiscalYearToDate')
                            fiscalYearService
                                .getFiscalYearStartMonth(subscriberId)
                                .then(function(fiscalMonth) {
                                    if(moment().month() >= fiscalMonth)
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month');
                                    else
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month').subtract(1, 'year');

                                    $scope.widget.defaultInitialization();
                                });

                        $scope.dates.periodStartDate = !$scope.widget.instance.settings.customDate ? dates.startDate : $scope.widget.instance.settings.customDate.startDate;
                        $scope.dates.lastInvoiceDate = !$scope.widget.instance.settings.customDate ? dates.endDate : $scope.widget.instance.settings.customDate.endDate;
                    }

                    return retrieveLiquidFuelsData();
                });

            return $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(loadWidget);

        function queryLiquidFuels() {
            var d = _.isArray(divisions) ? divisions : [divisions];
            var p = _.isArray($scope.widget.instance.settings.productIds.value) ? $scope.widget.instance.settings.productIds.value : [$scope.widget.instance.settings.productIds.value];
            var t = _.isArray($scope.widget.instance.settings.tradeClassIds.value) ? $scope.widget.instance.settings.tradeClassIds.value : [$scope.widget.instance.settings.tradeClassIds.value];

            return $http({
                url: ('api/subscribers/{0}/margins/summary').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    d: d.join(),
                    p: p.join(),
                    s: $scope.dates.periodStartDate ? $scope.dates.periodStartDate.format() : null,
                    e: $scope.dates.lastInvoiceDate.format(),
                    t: t.join(),
                    z: 'include',
                    usePostingDate: $scope.widget.instance.settings.usePostingDate.value
                }
            });
        }

        $scope.$on('widgetSettingsChanged', function() {
            divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
            $scope.periodIndicator = _.find(constantsService.invoicePeriodIndicators, function(indicator) {
                return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
            });
            delete $scope.dates;
            loadWidget();
        });

        $scope.openDatePicker = function() {
            return lastInvoiceDateService.getLastInvoiceDate(divisions)
                .then(function(lastInvoiceDate) {
                    return datePickerService.openDatePickerModal(
                        $scope.widget.instance.settings.periodIndicatorId.value == constantsService.invoicePeriodIndicators.lastInvoiceDate ? null : $scope.dates.periodStartDate,
                        $scope.dates.lastInvoiceDate,
                        lastInvoiceDate);
                })
                .then(function(resultDates) {
                    if(resultDates) {
                        $scope.dates.periodStartDate = moment(resultDates[0]).isValid() ? resultDates[0] : resultDates[1];
                        $scope.dates.lastInvoiceDate = resultDates[1];

                        $scope.widget.promises.monitor(retrieveLiquidFuelsData());

                        $scope.periodIndicator = ($scope.widget.instance.settings.periodIndicatorId.value == constantsService.invoicePeriodIndicators.customDate ||
                            $scope.widget.instance.settings.periodIndicatorId.value == constantsService.invoicePeriodIndicators.lastInvoiceDate) ?
                            constantsService.invoicePeriodIndicators.customDate : constantsService.invoicePeriodIndicators.customDateRange;
                    }
                });
        };

        function setDateTooltipText() {
            if(!$scope.dates) return;
            if($scope.widget.instance.settings.periodIndicatorId.value != constantsService.invoicePeriodIndicators.lastInvoiceDate &&
                $scope.dates.periodStartDate)
                $scope.dateTooltipText = $scope.dates.periodStartDate.format('L') + ' - ' + $scope.dates.lastInvoiceDate.format('L');
            else
                $scope.dateTooltipText = $scope.dates.lastInvoiceDate.format('L');
        }
    }
]);