angular.module( 'angus.directives' ).directive( 'excessCalls', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		'use strict';

		return {
			scope: {
				model      : '=actionModel',
				settings   : '=excessCallsWidgetSettings',
				widgetCode : '='
			},
			templateUrl: actionViewTemplates.excessCallsReportContainer
		};
	}
] );