angular.module( 'angus.directives' ).directive( 'ngEmail', [
	"$rootScope", "$http",
	function( $rootScope, $http ) {
		return {
			require: 'ngModel',
			restrict: 'A',
			link: function( scope, el, attrs, ctrl ) {
				ctrl.$parsers.push( function( viewValue ) {
					ctrl.$setValidity( 'emailAvailable', true );
					if ( viewValue ) {
						$http.get( '/api/users/' + $rootScope.user.id + '/emailAvailable?email=' + viewValue )
							.success( function( data ) {
								ctrl.$setValidity( 'emailAvailable', true );
							} )
							.error( function( data ) {
								ctrl.$setValidity( 'emailAvailable', false );
							} );
						return viewValue;
					}
				} );
			}
		};
	}
] );