
angular.module('angus.directives')
  .directive('serviceRevenueCategoriesSelect', [function () {
    'use strict';

    return {
      restrict: 'A',
      scope: {
        ngModel: '=',
        subscriberId: '=',        
      },
      controller: ['$scope', '$rootScope', 'constantsService', function ($scope, $rootScope, constantsService) {
          $scope.categories = _.map(constantsService.serviceRevenueCategories.enums, function(cat){
            return {
              id : cat.value.id,
              name : cat.value.display,
            }
          });          
      }],
      template: '<div multi-select-ok-wrapped="categories" ng-model="ngModel" display-prop="\'name\'" selection-prop="\'id\'"></div>',      
    };
  }]);
