angular.module('angus.services').service('gridService2',
	['$q', '_', 'colDefService', '$rootScope',
	function($q, _, colDefService, $rootScope) {

		'use strict';

		var defaultGridOptions = {
            columnDefs                : null,
            rowData                   : null,
            rowSelection              : 'single',
            suppressRowClickSelection : false,
            enableFilter              : true,
            enableColResize           : true,
            enableSorting             : true,
            rowHeight                 : 20,
            showToolPanel             : true,
            toolPanelSuppressValues   : true,
            suppressCellSelection     : true,
            suppressMenuHide          : true,
            overlayLoadingTemplate    : '<strong class = "ag-overlay-loading-center"><i class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</strong>',
            overlayNoRowsTemplate     : '<strong class = "alert alert-danger center">No results found!  Please change your filters and try again.</strong>',
            icons                     : {
              menu                     : '<i class="fa fa-bars clickable"/>',
              filter                   : '<i class="fa fa-filter clickable"/>',
              sortAscending            : '<i class="fa fa-sort-amount-asc clickable"/>',
              sortDescending           : '<i class="fa fa-sort-amount-desc clickable"/>',
              groupExpanded            : '<i class="fa fa-minus-square-o clickable"/>',
              groupContracted          : '<i class="fa fa-plus-square-o clickable"/>',
              columnGroupOpened        : '<i class="fa fa-minus-square-o clickable"/>',
              columnGroupClosed        : '<i class="fa fa-plus-square-o clickable"/>',
            },
            groupColumnDef : {
                comparator: agGrid.defaultGroupComparator,
    			headerName : 'Group',
    			cellRenderer: {
			        renderer: 'group',
			    },
    			pinned: 'left'
			}
    	};

        function camelize(str) {
            return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
                if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
                return index == 0 ? match.toLowerCase() : match.toUpperCase();
            });
        }

		function Grid(params){
            this.suppressAutoExpand = params.suppressAutoExpand;

	        this.col = {
	        	defs   	: {},
	        	clicks  : {}
	        };

            if(params.exportOptions)
                this.export = {
                    fileName : params.exportOptions.fileName,
                    fn       : params.exportOptions.fn
                };

	       	if(params.defs && params.defs.length > 0)
	       		this.setColDefs(params.defs);

			this.gridState = params.gridState;

			this.options =_.cloneDeep(defaultGridOptions);

			if(params.gridOptions){
                if(params.overwriteOptions)
                    this.options = params.gridOptions;
                else
				    _.assign(this.options, params.gridOptions || {});
            }

            if(params.clicks) {
                var that = this;
                that.setColClicks(params.clicks);
                that.options.onCellClicked = function(node){
                    if(node && node.colDef && node.colDef.colId && that.col.clicks[node.colDef.colId])
                        that.col.clicks[node.colDef.colId].onClick(node.data);
                };
            }

            if(this.options.showToolPanel === false)
                this.showToolPanel = this.options.showToolPanel =  false;

            this.state = null;

            $rootScope.widgetCode = camelize(params.exportOptions.fileName);
		}

		Grid.prototype.getCurrentState = function(){
			var columnApi = this.options.columnApi;
            var cols = _.map(columnApi.getAllColumns(), function(col){
                return {
                    id      : col.colId,
                    visible : col.visible,
                    sort    : col.sort,
                    width   : col.actualWidth
                };
            });

            _.forEach(columnApi.getRowGroupColumns(), function(rowGroupColumn, index){
                var col = _.find(cols, function(c){
                    return rowGroupColumn.colId == c.id;
                });
                if(!col) return;
                col.groupIndex = index;
            });

            return {
            	cols 		  : cols,
            	showToolPanel : this.showToolPanel,
                filters       : this.options.api ? this.options.api.getFilterModel() : {}
            };
        }


        Grid.prototype.getColDefs = function(){
        	var that = this;

            function addClick(colDef){
                if(that.col.clicks[colDef.colId]){
                    var originalCellClass = colDef.cellClass || [];

                    colDef.cellClass = function(params){

                        if(typeof originalCellClass === 'function')
                            originalCellClass = originalCellClass(params);

                        if(that.col.clicks[colDef.colId].isNotClickable && that.col.clicks[colDef.colId].isNotClickable(params))
                            return originalCellClass;

                        var newCellClass = originalCellClass.concat(['clickable', 'text-primary', 'underline']);

                        _.remove(newCellClass, function(c){
                            return c == 'text-danger';
                        });

                        return newCellClass;
                    };
                }
            }

            function getColumn(colId, opts){
                var colDef;

                colDef = that.col.defs[colId];

                if(!colDef) return;

                colDef = _.cloneDeep(colDef)
                _.assign(colDef, opts || {});
                addClick(colDef);

                return colDef;
            }


            var defs = that.state
            	?  _.reduce(that.state.cols, function(result, col){
            		var opts = { rowGroupIndex : col.groupIndex, hide :!col.visible, sort : col.sort, width : col.width };
                    var colDef = getColumn(col.id, opts);
                    if(colDef){
                        result.push(colDef);
                        if(colDef.children && colDef.children .length > 0)
                            colDef.children = _.map(colDef.children, function(childColDef){
                                var opts = { rowGroupIndex : childColDef.groupIndex, hide :!childColDef.visible, sort : childColDef.sort, width : childColDef.width };
                                childColDef = _.cloneDeep(childColDef)
                                _.assign(childColDef, opts || {});
                                addClick(childColDef);
                                return childColDef;
                            });
                    }
                    return result;
                }, [])
                : [];

            return _.reduce(that.col.defs, function(result, def, colId){
                if(!_.some(defs, function(def){ return def.colId == colId; })){
                    var colDef = getColumn(colId);
                     if(colDef){
                        if(colDef.children && colDef.children .length > 0)
                            colDef.children = _.forEach(colDef.children, function(childColDef){
                                addClick(childColDef);
                            });
                        result.push(colDef);
                    }
                }
                return result;
            }, defs);
        };

        Grid.prototype.setShowToolPanel= function(showToolPanel){
    		this.showToolPanel = showToolPanel;
    		this.options.api.showToolPanel(showToolPanel);
    	};

        Grid.prototype.setState= function(state){
        	var that = this;
            if(state){
                that.showToolPanel = state.showToolPanel;
                that.state = state;
            }
            else
                delete that.state;

            if(that.options.api){
               that.options.api.showToolPanel(that.showToolPanel);
        	   that.options.api.setColumnDefs(that.getColDefs());
               that.options.api.setFilterModel(state ? state.filters : {});
        	   if(!that.suppressAutoExpand) that.options.api.expandAll();
               // that.options.api.sizeColumnsToFit();
            }
        };


        Grid.prototype.setUserState= function(state){
        	this.userState = state;
            this.setState(state);
        };
            Grid.prototype.setCustomState = function(state, name){
            state.type = 'custom';
            state.name = name;
            this.customState = state;
            this.useCustomState(state);
        };

        Grid.prototype.hasCustomState = function(){
            return !!this.customState;
        };

        Grid.prototype.hasUserState = function(){
            return !!this.userState;
        };

        Grid.prototype.useCustomState = function(){
            this.setState(this.customState);
        };

        Grid.prototype.useUserState = function(){
            this.setState(this.userState);
        };

        Grid.prototype.useSystemState = function(){
            this.setState();
        };

        Grid.prototype.saveUserState = function(){
            if(!this.gridState) return;
            var that = this;
            var state = this.getCurrentState();
            return this.gridState
                .save(state)
                .then(function(state){
                    that.userState = state;
                });
        };

        Grid.prototype.removeUserState = function(){
            if(!this.gridState) return;
            var that = this;
            return this.gridState
                .remove()
                .then(function(){
                    that.userState = null;
                });
        };

        Grid.prototype.search = function(searchTerm){
            this.options.api.setQuickFilter(searchTerm);
        };


        Grid.prototype.clearFilters = function(){
            this.options.api.setFilterModel({});
        };

        Grid.prototype.hasFilters = function(){
            var that = this;
            return !_.isEmpty(that.options.api ? that.options.api.getFilterModel(): {});
        };

        Grid.prototype.setColDefs = function(list, refresh){
            var that = this;
            that.col.defs = {};
        	_.forEach(list, function(def){
    			that.col.defs[def.colId] = def;
        	});
            if (refresh){
                 that.options.api.setColumnDefs(that.getColDefs());
                 if(!that.suppressAutoExpand) that.options.api.expandAll();
            }
    	};

    	Grid.prototype.setColClicks = function(obj){
            var that = this;
    		if(obj && Object.keys(obj).length > 0 )
        	_.forEach(obj, function(subObj, colId){
    			if(subObj && subObj.onClick) that.col.clicks[colId] = subObj;
        	});
    	};

    	Grid.prototype.setRows = function(promise, refreshColumns){
    		var that = this;

    		if(this.options.api) this.options.api.showLoadingOverlay();

    		return promise
    			.then(function(rows){
                    var gridApi   = that.options.api;
                    var columnApi = that.options.columnApi;
                    
                    if (gridApi && columnApi) {
                        gridApi.hideOverlay();
                        gridApi.setRowData(rows);
    
                        if(columnApi.getAllColumns().length === 0 )
                            that.setState(that.state)
    
                        if(refreshColumns )
                            gridApi.setColumnDefs(that.getColDefs());
    
                        if(rows.length === 0)
                            gridApi.showNoRowsOverlay();
                        else if(!that.suppressAutoExpand)
                            gridApi.expandAll();
                    }
    			})
    			.catch(function(){
                    var gridApi = that.options.api;
                    if (gridApi) {
                        gridApi.hideOverlay();
                        gridApi.setRowData([]);
                        gridApi.showNoRowsOverlay();
                    }
    			});
    	};

    	Grid.prototype.toggleToolPanel = function(){
    		this.showToolPanel = !this.showToolPanel;
    		this.options.api.showToolPanel(this.showToolPanel)
    	};

        function formatDateTimeForFile(date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
          
            return `${year}${month}${day} ${hours}${minutes}`;
          }

        Grid.prototype.exporttoCsv = function(){
            if(!this.export) {
                throw 'Need to setup export options.';
            }

            const date = new Date();
            const dateString = formatDateTimeForFile(date);
            const fileName = `${this.export.fileName} ${dateString}.csv`;

            this.options.api.exportDataAsCsv({fileName: fileName});
        };

        Grid.prototype.showNoRowsOverlay = function(){
            this.options.api.showNoRowsOverlay();
        };

        Grid.prototype.hideOverlay = function(){
            this.options.api.hideOverlay();
        };

        Grid.prototype.canExport = function(){
            return !!this.export;
        };

        Grid.prototype.recomputeAggregates = function(){
            return this.options.api.recomputeAggregates();
        };


		return {

			colDef 	   : colDefService,
			createGrid : function(params){
				var grid = new Grid(params);
				var promise = grid.gridState ? grid.gridState.get() : $q.when();

				return promise
					.then(function(state){
						if(state) grid.setUserState(state);
				        return grid;
					});
			}
		}
	}
]);
