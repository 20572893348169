angular.module( 'angus.services' )
	.factory( 'countryInfo', [
		'$http', '_',
		function( $http, _ ) {
			var countries = null;

			function countrySwitch( countryName ) {
				_( countries ).forEach( function( country ) {
					if ( country.name == countryName )
						return country;
				} );
			}

			return {
				countrySwitch: function( countryName ) {
					if ( !countries ) {
						$http.get( 'api/countries/' )
							.success( function( data ) {
								countries = data;
								return countrySwitch( countryName );
							} );
					} else {
						return countrySwitch( countryName );
					}
				}
			};
		}
	] );