angular.module('angus.controllers').controller('deliveryForecastingSubRegSettingsCtrl', [
    '$scope', '$q', 'productsService', 'tradeClassService', 'promiseMonitor', 'constantsService',
    function ($scope, $q, productsService, tradeClassService, promiseMonitor, constantsService) {

        $scope.promiseMonitor = new promiseMonitor();

        $scope.periodIndicators = constantsService.forecastingPeriodIndicators.enums;

        var promise = $q.all([
            productsService.getProducts($scope.subscriberId)
                .then(function (products) {
                    $scope.products = products;
                }),
            tradeClassService.getTradeClasses($scope.subscriberId)
                .then(function (tradeClasses) {
                    $scope.tradeClasses = tradeClasses;
                })
        ]);

        $scope.promiseMonitor.monitor(promise);

    }
]);