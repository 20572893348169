angular.module('angus.controllers').controller('marginComponentsSubRegSettingsCtrl', [
    '$scope', '$rootScope', '$http', 'datePickerService', 'periodIndicators', 'productsService',  'tradeClassService', 'hierarchyService', 'constantsService', 'budgetsService', 'promiseMonitor', '$q',
    function($scope, $rootScope, $http, datePickerService, periodIndicators, productsService, tradeClassService, hierarchyService, constantsService, BudgetsService, promiseMonitor, $q) {
        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        function selectedCategory(categories, categoryIds) {
            _.forEach(categories, function(category) {
                category.selected = _.some(categoryIds, function(categoryId){ return category.id == categoryId });
            });
        }

        $scope.selected = {};

        var service = new BudgetsService(subscriberId);
        $scope.commodities = constantsService.commodityIndex.enums;
        $scope.selected.PstartDate = moment($scope.settings.startDate ).format('MMM DD, YYYY');
        $scope.selected.PendDate = moment($scope.settings.endDate ).format('MMM DD, YYYY');
        productsService.getProducts()
        .then(function (products) {
            $scope.products = products;
        });

        $scope.openStartDate = function() {
            datePickerService.openDatePickerModal(
                $scope.settings.startDate,
                $scope.settings.endDate,
                moment()
            )
            .then(function(resultDates) {
                //capital P dates are used for presentation only
                $scope.settings.startDate = resultDates[0]._d;
                $scope.selected.PstartDate = moment(resultDates[0]._d).format('MMM DD, YYYY');
                $scope.settings.endDate = resultDates[1]._d;
                $scope.selected.PendDate  = moment(resultDates[1]._d).format('MMM DD, YYYY');
            });
        };

        service.getPricePlans()
            .then(function(pricePlanCategories) {
                pricePlanCategories.shift();
                selectedCategory(pricePlanCategories, $scope.settings.pricePlanCategoryIds ? $scope.settings.pricePlanCategoryIds.value : []);
                $scope.pricePlanCategories = pricePlanCategories;
            });
    }
]);