angular.module( 'angus.directives' ).directive( 'appLiquidFuelsHistory', [
	'actionViewTemplates',
	function( actionViewTemplates ) {

		'use strict';

		return {
			scope: {
				closeCb: '=',
				model: '=actionModel',
				settings: '=',
				widgetCode : '='
			},
			templateUrl: actionViewTemplates.liquidFuelsHistoryReportContainer
		};
	}
] );