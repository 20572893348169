angular.module('angus.controllers').controller('serviceReworkCtrl', [
    '$scope', '$rootScope', '$http', 'modalService', 'productsService', 'hierarchyService', 'actionViewTemplates', 
    'constantsService', '_', 'moment', 'datePickerService', 'lastServiceCallDate',
    function($scope, $rootScope, $http, modalService, productsService, hierarchyService, actionViewTemplates, 
        constantsService, _, moment, datePickerService, lastServiceCallDate) {

        'use strict';

     $scope.periodIndicators = constantsService.reworkPeriodIndicators.enums;
        $scope.periodIndicator = _.find(constantsService.reworkPeriodIndicators, function(indicator) {
            return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
        });

        var scSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.serviceCenter.nodes, $scope.widget.instance.settings.hierarchyNodeId);
        $scope.dates = {};

        function loadWidget() {
            $scope.widget.noContentFlag = false;

            var promise = lastServiceCallDate.getLastServiceCallDate(scSetting)
                .then(function(lastPostingDate) {
                    if (!lastPostingDate)
                        return null;

                    $scope.dates.lastPostingDate = lastPostingDate;
                    setDates(lastPostingDate);

                    return queryServiceCallsSummary();
                })
                .then(function(response) {
                    if (!response || !response.data) {
                        $scope.widget.noContentFlag = true;
                        return null;
                    }

                    $scope.callbacks = response.data;
                    setDateTooltipText();
                    $scope.widget.setDefaultActionModel($scope.dates);
                });
            return $scope.widget.promises.monitor(promise);
        }

        function reloadWidget() {
            $scope.widget.noContentFlag = false;

            var promise = queryServiceCallsSummary()
                .then(function(response) {
                    if (!response || !response.data) {
                        $scope.widget.noContentFlag = true;
                        return null;
                    }

                    $scope.callbacks = response.data;
                    setDateTooltipText();
                    $scope.widget.setDefaultActionModel($scope.dates);
                });
            $scope.widget.promises.monitor(promise);
        }
        
        $scope.widget.setDefaultInitialization(loadWidget);

        function queryServiceCallsSummary() {
            return $http({
                url: ('api/subscribers/{0}/service/serviceRework/summary').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    sc: scSetting,
                    t : $scope.widget.instance.settings.techs ? $scope.widget.instance.settings.techs.value.join(',') : null,
                    sd: $scope.dates.periodStartDate ? $scope.dates.periodStartDate.format() : null,
                    ed: $scope.dates.lastPostingDate.format(),
                    scl: $scope.widget.instance.settings.serviceCallList ? $scope.widget.instance.settings.serviceCallList.value.join(',') : null,
                }
            });
        }

        $scope.$on('widgetSettingsChanged', function() {
            scSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.serviceCenter.nodes, $scope.widget.instance.settings.hierarchyNodeId);
            $scope.periodIndicator = _.find(constantsService.reworkPeriodIndicators, function(indicator) {
                    return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
                });
            $scope.dates.periodStartDate = null;
            loadWidget();
        });        

        $scope.openDatePicker = function() {
            lastServiceCallDate.getLastServiceCallDate(scSetting)
                .then(function(lastPostingDate) {
                    return datePickerService.openDatePickerModal(                       
                        $scope.dates.periodStartDate,
                        $scope.dates.lastPostingDate,
                        lastPostingDate);
                })
                .then(function(resultDates) {
                    if (resultDates) {
                        $scope.dates.periodStartDate = moment(resultDates[0]).isValid() ? resultDates[0] : resultDates[1];
                        $scope.dates.lastPostingDate = resultDates[1];

                        reloadWidget();
                        //$scope.periodIndicator = constantsService.analysisPeriodIndicators.customDateRange;
                        $scope.periodIndicator = constantsService.reworkPeriodIndicators.customDateRange;
                    }
                });
        };
       
        function setDateTooltipText() {
            $scope.dateTooltipText = $scope.dates.periodStartDate.format('L') + ' - ' + $scope.dates.lastPostingDate.format('L');
        }

        function setDates(lastPostingDate){
            switch($scope.periodIndicator.key){
                case(constantsService.reworkPeriodIndicators.prior10Days.key):
                    $scope.dates.periodStartDate = moment(lastPostingDate).subtract(10, 'days');
                    break;
                case(constantsService.reworkPeriodIndicators.currentMonth.key):
                    $scope.dates.periodStartDate = moment(lastPostingDate).startOf('month');
                    break;
                case(constantsService.reworkPeriodIndicators.priorMonth.key):
                    $scope.dates.lastPostingDate = moment(lastPostingDate).subtract(1, 'month').endOf('month');
                    $scope.dates.periodStartDate = moment(lastPostingDate).subtract(1, 'month').startOf('month');
                    break;
                default:
                    break;
            }
        }
    }
]);