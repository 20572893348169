angular.module('angus.services').service('hierarchyService', ['$rootScope', '$q', '_', 'fluentRest', 'constantsService', 'modalService', 'hierarchyHelperService',
    function($rootScope, $q, _, fluentRest, constantsService, modalService, hierarchyHelperService) {

        'use strict';

        function getHierarchy(subscriberId, type, nodeId) {
            return fluentRest
                .api()
                .subscribers(subscriberId)
                .hierarchy()
                .nodes(nodeId)
                .get({
                    hierarchyType: type
                })
                .then(function(nodes) {
                    return hierarchyHelperService.convertDataForEaseOfUse(nodes);
                });
        }

        function getDivisionHierarchy(subscriberId, nodeId) {
            return getHierarchy(subscriberId, constantsService.hierarchyType.division.key, nodeId);
        }

        function getDeliveryCenterHierarchy(subscriberId, nodeId) {
            return getHierarchy(subscriberId, constantsService.hierarchyType.deliveryCenter.key, nodeId);
        }

        function getServiceCenterHierarchy(subscriberId, nodeId) {
            return getHierarchy(subscriberId, constantsService.hierarchyType.serviceCenter.key, nodeId);
        }

        function getDealerHierarchy(subscriberId, nodeId) {
            return getHierarchy(subscriberId, constantsService.hierarchyType.dealer.key, nodeId);
        }

        function getHierarchies(subscriberId, types) {

            if (!types)
                types = _.map(constantsService.hierarchyType.enums, 'key');

            return $q.all(
                    _.map(types, function(type) {
                        return getHierarchy(subscriberId, type)
                            .then(function(nodes) {
                                return {
                                    type: type,
                                    nodes: nodes
                                };
                            });
                    })
                )
                .then(function(dtos) {
                    return _.reduce(dtos, function(result, dto) {
                        result[dto.type] = dto.nodes;
                        return result;
                    }, {});
                });
        }

        function openModal(nodes, currentNodeId, hierarchyType) {
            var currentNode = _(nodes).find(function(node) {
                return node.id == currentNodeId;
            });

            var scope = {
                hierarchyType: constantsService.hierarchyType.get(hierarchyType),
                currentNode: currentNode
            };

            return modalService.openTopBarModal('templates/widgetPartials/html/hierarchy/display.html', scope);
        }


        return {
            getHierarchy: getHierarchy,
            getHierarchies: getHierarchies,
            getDivisionHierarchy: getDivisionHierarchy,
            getDeliveryCenterHierarchy: getDeliveryCenterHierarchy,
            getServiceCenterHierarchy: getServiceCenterHierarchy,
            getDealerHierarchy: getDealerHierarchy,
            types: constantsService.hierarchyType,
            openModal: function(currentNodeId, nodes, hierarchyType) {
                return openModal(nodes, currentNodeId, hierarchyType);
            },
            openDivisionModal: function(currentNodeId, nodes) {
                return openModal(nodes || $rootScope.user.hierarchy.division.nodes, currentNodeId, constantsService.hierarchyType.division);
            },
            openServiceCenterModal: function(currentNodeId, nodes) {
                return openModal(nodes || $rootScope.user.hierarchy.serviceCenter.nodes, currentNodeId, constantsService.hierarchyType.serviceCenter);
            },
            openDeliveryCenterModal: function(currentNodeId, nodes) {
                return openModal(nodes || $rootScope.user.hierarchy.deliveryCenter.nodes, currentNodeId, constantsService.hierarchyType.deliveryCenter);
            },
            //To cleanup later
            getDescendants: hierarchyHelperService.getDescendants,
            getDistanceFromLeaf: hierarchyHelperService.getDistanceFromLeaf,
            getLeafNodeEntityIds: hierarchyHelperService.getLeafNodeEntityIds,
            convertDataForEaseOfUse: hierarchyHelperService.convertDataForEaseOfUse,
            markDealerAncestry: hierarchyHelperService.markDealerAncestry
        };
    }
]);
