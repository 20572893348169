'use strict';

angular
.module('angus.controllers')
.controller('santaBudgetVarContainerCtrl',
  ['$scope', '$rootScope', '$q', 'fluentRest', 'moment', 'fiscalYearService', 'constantsService', '_', 'promiseMonitor', 'gridService2','gridState', 'nodeType', 'restrictedAccessService', 'dateCalculator', santaBudgetVarianceController]);

function santaBudgetVarianceController($scope, $rootScope, $q, fluentRest, moment, fiscalYearService,  constantsService, _, PromiseMonitor, gridService2, gridState, nodeType, restrictedAccessService, dateCalculator) {

  var  subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;
  var fiscalYearOfToday = null;


  function initFilter(){
    return {
      showEntireFiscalYear    : _.get($scope, ['settings', 'periodIndicatorKey', 'value'], false),
      fiscalYear            : $scope.model ? $scope.model.year : moment().year(),
      month                 : $scope.model ? $scope.model.month : moment().month(),
      workingDayOffset      :  _.get($scope, ['settings', 'workingDaySubtraction', 'value'], false) || 0,
      divisionId            : $scope.settings.hierarchyNodeId || $rootScope.user.hierarchy.division.nodeId,
      productCategoryIds    : $scope.settings.productCategoryIds ? $scope.settings.productCategoryIds.value : [],
      tradeClassCategoryIds : $scope.settings.tradeClassCategoryIds ? $scope.settings.tradeClassCategoryIds.value : [],
      pricePlanCategoryIds  : $scope.settings.pricePlanCategoryIds ? $scope.settings.pricePlanCategoryIds.value : [],
      isFinance  			  : !!$scope.settings.isFinance ? !!$scope.settings.isFinance.value : false
    };
  }
  $scope.filter = initFilter();


  $scope.filterChanged = function(filter){
    $scope.filter = filter || initFilter();
        Promise.all([$scope.getVariance(), $scope.getVariance($scope.filter.month)]);
  };

  function addYearsToMonths(){
    var year = $scope.fiscalYear;
    if(moment().month(months[0]).month() > 0 )
      year --;
    $scope.months = _.map(months, function(month, index){
      var monthNumber = moment().month(month).month();
      if(monthNumber == 0 && index !== 0)
        year++;
      return {
        display : month, // + ' - ' + year,
        value : monthNumber
      }
    });
  }
  $scope.yearChange = function(){
    fiscalYearService
    .getFiscalYearMonthsOrdered()
    .then(function(months){
      var startMonth   = moment().month(months[0]).month();
      var currentMonth = moment().month();


      $scope.months = _(months)
      .map(function(m){
        return {
          value: moment().month(m).month(),
          display : m
        };
      })
      .filter(function(m){
        return fiscalYearOfToday > $scope.fiscalYear || m.value <= currentMonth || m.value >= startMonth;
      })
      .value();

      var lastMonth    = $scope.months[$scope.months.length-1].value;

      if(!$scope.month || (fiscalYearOfToday == $scope.fiscalYear &&  $scope.month > lastMonth && $scope.month < startMonth) )
        $scope.month = currentMonth;
    });
      // return Promise.all([$scope.getVariance(), $scope.getVariance($scope.filter.month)]);

  };

  $scope.getVariance = function(m) {
      $scope.grid.setColDefs(getColDefs());

      var params = {
          fy: $scope.filter.fiscalYear,
          h: $scope.filter.divisionId,
          wdo: $scope.filter.workingDayOffset,
          pc: $scope.filter.productCategoryIds,
          tc: $scope.filter.tradeClassCategoryIds,
          ppc: $scope.filter.pricePlanCategoryIds,
      };

      if(m)
          params.m = m;

      // for some reason January would not work in widget or detail view, unless explicitly set
      if(m === 0) {
          params.m = $scope.filter.month;
      }

      return fluentRest
      .api()
      .subscribers(subscriberId)
      .budgets()
      .variance()
      //api/subscribers/23443342432432/budgets/variance
      .get(params)
      .then(function(data) {
          $scope.lastTrxDate = data.lastTrxDate ? moment.utc(data.lastTrxDate).format('l') : '-';
          $scope.workingDays = data.workingDays || $scope.workingDays;
          $scope.dataThroughDate = data.dataThroughDate ? moment.utc(data.dataThroughDate).format('l') : '-';
          $scope.startDate = data.startDate ? moment.utc(data.startDate).format('l') : '-';
          $scope.endDate = data.endDate ? moment.utc(data.endDate).format('l') : '-';
          return data.budgets;
      });

  };

  var gridOptions = {

    groupAggFunction: function(rows) {

        return _.reduce(rows, function(result, row){
        var data = row.data;

        if(data){
          var keys = ['current', 'budgeted', 'currentYTD', 'budgetedYTD', 'varianceYTD', 'percentVarianceYTD'];
          _.forEach(keys, function(key){
            result[key].units       += data[key].units;
            result[key].grossMargin += data[key].units * data[key].unitMargin;
            result[key].unitMargin   = data[key].units ? (result[key].grossMargin / result[key].units) : 0;
          });

          result.varianceUnits              =  result.current.units - result.budgeted.units;
          result.varianceGrossMargin        =  result.current.grossMargin - result.budgeted.grossMargin;
          result.varianceUnitMargin         =  result.current.unitMargin - result.budgeted.unitMargin;
          result.variance = {
              units 			: result.current.units - result.budgeted.units,
              grossMargin : result.current.grossMargin - result.budgeted.grossMargin,
              unitMargin  : result.current.unitMargin - result.budgeted.unitMargin
          };

            result.currentYTD = {
                units 			: result.currentYTD.units,
                grossMargin : result.currentYTD.grossMargin,
                unitMargin  : result.currentYTD.unitMargin
            };

            result.budgetedYTD = {
                units 			: result.budgetedYTD.units,
                grossMargin : result.budgetedYTD.grossMargin,
                unitMargin  : result.budgetedYTD.unitMargin
            };

            result.varianceYTD = {
                units 			: result.varianceYTD.units,
                grossMargin : result.varianceYTD.grossMargin,
                unitMargin  : result.varianceYTD.unitMargin
            };

            result.percentVarianceYTD = {
                units 			: result.budgetedYTD.units ? (result.varianceYTD.units / result.budgetedYTD.units) * 100 : 100,
                grossMargin : result.budgetedYTD.grossMargin ? (result.varianceYTD.grossMargin / result.budgetedYTD.grossMargin) * 100 : 100,
                unitMargin  : result.budgetedYTD.unitMargin ? (result.varianceYTD.unitMargin / result.budgetedYTD.unitMargin) * 100 : 100
            };

            result.percentVariance = {
                units 			: result.budgeted.units ? (result.variance.units / result.budgeted.units) * 100 : 100,
                grossMargin : result.budgeted.grossMargin ? (result.variance.grossMargin / result.budgeted.grossMargin) * 100 : 100,
                unitMargin  : result.budgeted.unitMargin ? (result.variance.unitMargin / result.budgeted.unitMargin) * 100 : 100
            };

          _.forIn(result.variance, function(value, key) {
            if(!value) {
              result.percentVariance[key] = null;
            }
          });

        }

        return result;
      },{
            current: {
                units: 0,
                grossMargin: 0,
                unitMargin: 0
            },
            budgeted: {
                units: 0,
                grossMargin: 0,
                unitMargin: 0
            },
            currentYTD: {
                units: 0,
                grossMargin: 0,
                unitMargin: 0
            },
            budgetedYTD: {
                units: 0,
                grossMargin: 0,
                unitMargin: 0
            },
            varianceYTD: {
                units: 0,
                grossMargin: 0,
                unitMargin: 0
            },
            percentVarianceYTD: {
                units: 0,
                grossMargin: 0,
                unitMargin: 0
            }
      });
    }

  };

  function getColDefs() {
    var nextIndex = $scope.nodeColDefs ? $scope.nodeColDefs.length : 0;
    var colDefs   = $scope.nodeColDefs ? _.cloneDeep($scope.nodeColDefs) : [];
    var colOpts = {};


    colDefs.push(gridService2.colDef.createText('productCategory','Budget Products', 'productCategory', { rowGroupIndex: nextIndex, hide : true }));
    // colDefs.push(gridService2.colDef.createText('tradeClassCategory','Budget Trade Classes', 'tradeClassCategory', { rowGroupIndex: nextIndex+1, hide : true } ));
    // colDefs.push(gridService2.colDef.createText('pricePlanCategory','Budget Price Plans', 'pricePlanCategory', { rowGroupIndex: nextIndex+2, hide : true } ));

      colDefs.push( gridService2.colDef.createNumber('current_units', 'MTD Units', 'current.units', colOpts, {decimalPlaces: 1}) );
      colDefs.push( gridService2.colDef.createNumber('budgeted_units', 'MTD Budget', 'budgeted.units', colOpts, {decimalPlaces: 1}) );
      colDefs.push( gridService2.colDef.createNumber('variance_units', 'MTD Variance', 'variance.units', colOpts, {decimalPlaces: 1}) );
      colDefs.push( gridService2.colDef.createPercentage('percentVariance_units', 'MTD Variance Percent', 'percentVariance.units', colOpts, {decimalPlaces: 0}) );

      colDefs.push( gridService2.colDef.createNumber('ytd_units', 'YTD Units', 'currentYTD.units', colOpts, {decimalPlaces: 1}) );
      colDefs.push( gridService2.colDef.createNumber('ytd_budgeted_units', 'YTD Budget', 'budgetedYTD.units', colOpts, {decimalPlaces: 1}) );
      colDefs.push( gridService2.colDef.createNumber('ytd_variance_units', 'YTD Variance', 'varianceYTD.units', colOpts, {decimalPlaces: 1}) );
      colDefs.push( gridService2.colDef.createPercentage('ytd_percentVariance_units', 'YTD Variance Percent', 'percentVarianceYTD.units', colOpts, {decimalPlaces: 0}) );
      return colDefs;
  }

  // reloads the grid with data when selecting new filter
  $scope.loadGridData = function(){
      $scope.variancePromises = null;
      $scope.load();
  };

  function getYears() {
    return fluentRest
    .api()
    .subscribers($rootScope.user.subscriberId)
    .budgets()
    .years()
    .get()
    .then(function(years) {
      $scope.years = years
    })
  }
  getYears();
  var months;
  $q.all([
    restrictedAccessService
    .getAccess(),
    nodeType
    .getDivisionColDefs(subscriberId),
    fiscalYearService
    .getFiscalYearMonthsOrdered()
  ])
  .spread(function(access, nodeColDefs, m){
    $scope.restrictedAccess = access.restrictedAccess;
    $scope.nodeColDefs 		= nodeColDefs;

    months = m;
    $scope.years = _.range(moment().subtract(5, 'years').year(), moment().add(2, 'years').year(), 1);
    fiscalYearOfToday = dateCalculator.getFiscalYear(moment(), moment().month(m[0]).month());
    addYearsToMonths();
    $scope.yearChange();

    var gridParams = {
      gridOptions	  : gridOptions,
      gridState     : gridState(subscriberId, $scope.widgetCode),
      defs          : getColDefs(),
      exportOptions : { fileName: 'Santa Fuel - Budget Variance' }
    };
    return gridService2.createGrid(gridParams);
  })
  .then(function(grid){
      $scope.grid = grid;
      $scope.load = function() {
          return Promise.all([$scope.getVariance(), $scope.getVariance($scope.filter.month)])
              .then(function(result) {
                  var YTDRecords = result[0];
                  var MTDRecords = result[1].map(function(record, index) {
                      record.currentYTD = YTDRecords[index].current;
                      record.budgetedYTD = YTDRecords[index].budgeted;
                      record.varianceYTD = YTDRecords[index].variance;
                      record.percentVarianceYTD = YTDRecords[index].percentVariance;
                      return record;
                  });

                  $scope.variancePromises = new PromiseMonitor($scope.grid.setRows($q.resolve(MTDRecords)));
              });
      };

      $scope.load();
  });
}

