 angular.module('angus.services').factory('purchaseOrderDeliveryService', [
    '$http', 'importFileService', function ($http, importFileService) {
 
         const getPurchaseOrderDelivery = function(purchaseOrderDeliveryHeaderKey, purchaseOrderID) { 
             let url =  'api/tankMonitors/delivery';
             if (purchaseOrderID || purchaseOrderDeliveryHeaderKey) {
                url += '?';
             }
             if (purchaseOrderID) {
                url += 'purchaseOrderID=' + purchaseOrderID;
             }
             if (purchaseOrderDeliveryHeaderKey) {
                url += 'purchaseOrderDeliveryHeaderKey=' + purchaseOrderDeliveryHeaderKey;
             } 
             return $http.get((url)).then(function (res) {
                        return res.data;
                    });
         };
  
         const getPurchaseOrderDeliveryHeader = function(purchaseOrderDeliveryHeaderKey, purchaseOrderID) {  
             let url =  'api/tankMonitors/delivery/purchaseOrderDeliveryHeaders';
             if (purchaseOrderID || purchaseOrderDeliveryHeaderKey) {
                url += '?';
             }
             if (purchaseOrderID) {
                url += 'purchaseOrderID=' + purchaseOrderID;
             }
             if (purchaseOrderDeliveryHeaderKey) {
                url += 'purchaseOrderDeliveryHeaderKey=' + purchaseOrderDeliveryHeaderKey;
             } 
             return $http.get((url)).then(function (res) {
                    return res.data;
                });
         }; 
 
         const postPurchaseOrderDelivery = function(data) {
             return $http.post(('api/tankMonitors/delivery'), data, {headers: {'Content-Type': 'application/json'}})
                    .then(function (res) {
                        return res.data.length ? res.data[0].PurchaseOrderDeliveryHeaderKey : null;
                    });
         };

        return {
            getPurchaseOrderDelivery, 
            getPurchaseOrderDeliveryHeader,
            postPurchaseOrderDelivery
        };
     }
 ]);
 
 