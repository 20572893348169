angular.module('angus.controllers').controller('securityGroupCtrl', [
    '$rootScope', '$scope', '$http', '$q', 'modalService', 'actionViewTemplates', '_', 'constantsService',
    function($rootScope, $scope, $http, $q, modalService, actionViewTemplates, _, constantsService) {
        'use strict';

        const widgetPackages = constantsService.widgetPackages;

        $scope.isLoading = false;
        $scope.isSysAdmin = $rootScope.user.role === 'sysadmin';
        $scope.alerts = [];

        function addAlert(msg, isError) {
            if (isError) {
                $scope.alerts.push({
                    type: 'danger',
                    msg: '<strong>Error!</strong> ' + (msg || 'Delete was unsuccessful!')
                });
            } else {
                $scope.alerts.push({
                    type: 'success',
                    msg: '<strong>Success!</strong> ' + (msg || 'Delete was successful!')
                });
            }
        }

        function init() {
            $q.all([
                    $http.get(('api/subscribers/{0}/users?pageSize=0&pageNumber=0').format($scope.subscriberId))
                    .then(function(users) {
                        return users.data;
                    }),


                    $http.get(('api/subscribers/{0}/securityGroups').format($scope.subscriberId))
                    .then(function(securityGroups) {
                        return securityGroups.data;
                    })

                ])
                .then(function(dataSets) {
                    $scope.users = dataSets[0];
                    $scope.securityGroups = dataSets[1];
                    _.forEach($scope.securityGroups, function(securityGroup) {
                        securityGroup.numberOfUsers = _.filter($scope.users, function(user) {
                            return user.securityGroupId == securityGroup.id;
                        }).length;
                    });

                    $scope.dataLoaded = true;
                });
        }

        init();

        $scope.closeAlert = function(index) {
            this.alerts.splice(index, 1);
        };

        function getUnassignedOrSecurityUsers(securityGroup) {
            return _.compact(_.map($scope.users, function(user) {
                
                if ((securityGroup && user.securityGroupId == securityGroup.id) || !user.securityGroupId) {

                    var lastSignOnDisplay = 'Never';
                    
                    if(user.lastSignOn){
                        var lastSignOn = new Date(user.lastSignOn);
                        lastSignOnDisplay = lastSignOn.toLocaleDateString() + ' ' + lastSignOn.toLocaleTimeString();
                    }
                    
                    return {
                        username: user.username,
                        isOnCurrent: securityGroup && user.securityGroupId == securityGroup.id,
                        isSelected: false,
                        id: user.userId,
                        lastSignOnDisplay: lastSignOnDisplay
                    };
                }

                return null;
            }));
        }

        function setWidgetAccess(widgets, securityGroup) {

            return _.map(widgets, function(widget) {

                var widgetPermission = securityGroup ? _.find(securityGroup.permissions, function(permission) {
                    return permission.widgetId == widget.id;
                }) : null;

                return {
                    name: widget.name,
                    id: widget.id,
                    icon: widget.icon,
                    access: widgetPermission ? widgetPermission.access : constantsService.widgetAccess.none.key
                };
            });
        }

        function createSecurityGroup(securityGroup, users){
            return $http
                .post(('api/subscribers/{0}/securityGroups').format($scope.subscriberId), securityGroup)
                .then(function(newGroup) {
                    securityGroup = newGroup.data;
                    $scope.securityGroups.push(securityGroup);

                    if(!users) return [];

                    return $http
                        .put('api/subscribers/{0}/securityGroups/{1}/addUsers'.format($scope.subscriberId, securityGroup.id), users)
                        .then(function(securityGroup) {
                            _.forEach($scope.users, function(user) {
                                if (_.some(users, function(userId) {
                                        return userId == user.userId;
                                    }))
                                    user.securityGroupId = securityGroup.id;
                            });
                            return users;
                        });
                })
                .then(function(users) {
                    securityGroup.numberOfUsers = users.length;
                    $scope.dataLoaded = true;
                });
        }

        $scope.copySecurityGroup = function(securityGroup){
            var newName = securityGroup.name + ' (Copy)';
             var data = {
                subscriberId        : $scope.subscriberId,
                name                : newName,
                permissions         : securityGroup.permissions,
                restrictedAccess    : securityGroup.restrictedAccess,
                adminAccess         : securityGroup.adminAccess
            };
            return createSecurityGroup(data)
                .then(function(){
                    addAlert('Copied ' + securityGroup.name + '.');
                });
        };

        function saveSecurityGroup(securityGroup, users, widgets) {
            $scope.dataLoaded = false;

            var usersToAddToSecurityGroup = [];
            var usersToRemoveFromSecurityGroup = [];


            _.forEach(users, function(user) {
                if (user.isSelected)
                    if (user.isOnCurrent)
                        usersToRemoveFromSecurityGroup.push(user.id);
                    else
                        usersToAddToSecurityGroup.push(user.id);
            });

            var permissions = _.compact(_.map(widgets, function(widget) {
                if (widget.access)
                    return {
                        widgetId: widget.id,
                        access: widget.access
                    };
                else
                    return null;
            }));

            var data = {
                subscriberId: $scope.subscriberId,
                name: securityGroup.name,
                permissions: permissions,
                restrictedAccess: securityGroup.restrictedAccess,
                adminAccess: securityGroup.adminAccess
            };

            if (securityGroup.id) {
                $q.all([
                        $http.put(('api/subscribers/{0}/securityGroups/{1}').format($scope.subscriberId, securityGroup.id), data),
                        $http.put(('api/subscribers/{0}/securityGroups/{1}/removeUsers').format($scope.subscriberId, securityGroup.id), usersToRemoveFromSecurityGroup),
                        $http.put(('api/subscribers/{0}/securityGroups/{1}/addUsers').format($scope.subscriberId, securityGroup.id), usersToAddToSecurityGroup)
                    ])
                    .then(function() {
                        $scope.securityGroups = _.forEach($scope.securityGroups, function(sg) {
                            if (sg.id == securityGroup.id) {
                                sg.permissions = permissions;
                                sg.restrictedAccess = securityGroup.restrictedAccess;
                                sg.adminAccess = securityGroup.adminAccess;
                                sg.name = securityGroup.name;
                                sg.numberOfUsers = sg.numberOfUsers + usersToAddToSecurityGroup.length - usersToRemoveFromSecurityGroup.length;
                            }
                        });
                        _.forEach($scope.users, function(user) {
                            if (_.some(usersToRemoveFromSecurityGroup, function(userId) {
                                    return userId == user.userId;
                                }))
                                user.securityGroupId = null;
                            if (_.some(usersToAddToSecurityGroup, function(userId) {
                                    return userId == user.userId;
                                }))
                                user.securityGroupId = securityGroup.id;
                        });

                        $scope.dataLoaded = true;
                    });

            } else {
                createSecurityGroup(data, usersToAddToSecurityGroup)
            }
        }

        $scope.addEditSecurityGroup = function(securityGroup) {

            $http.get(('api/subscribers/{0}/widgets').format($scope.subscriberId))
                .then(function(widgets) {
                    var scope = {
                        users: getUnassignedOrSecurityUsers(securityGroup),
                        securityGroup: securityGroup ? _.cloneDeep(securityGroup) : {},
                        access: constantsService.widgetAccess,
                        widgets: setWidgetAccess(widgets.data, securityGroup),
                        giveAllWidgetsAccess: function(access) {
                            _.forEach(this.widgets, function(widget) {
                                widget.access = access;
                            });
                        },
                        isAngus: $scope.subscriber.isAngus,
                        hasSnapshot:$scope.subscriber.widgetPackageKeys.includes(widgetPackages.snapShots),
                        hasService: $scope.subscriber.widgetPackageKeys.includes(widgetPackages.service),
                        canEditUsers: $scope.adminAccess.users || !$scope.adminMode,
                        isSysAdmin: $scope.isSysAdmin
                    };

                    if(!scope.securityGroup.restrictedAccess) {
                        scope.securityGroup.restrictedAccess = {
                            inlineCallbackEditing: true,
                            inlineBatchEditing: true
                        }
                    }

                    modalService
                        .openTopBarModal(actionViewTemplates.subRegAddEditSecurityGroup, scope)
                        .then(function(data) {
                            if (data) {
                                var securityGroup = data[0];
                                var users = data[1];
                                var widgets = data[2];
                                saveSecurityGroup(securityGroup, users, widgets);
                            }
                        });
                });
        };

        $scope.confirmDeleteSecurityGroup = function(securityGroup) {
            var scope = {
                title: 'Delete security group?',
                message: 'Are you sure you want to delete ' + securityGroup.name + '?'
            };
            modalService
                .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                .then(function(result) {
                    if (result) {

                        var usersToRemoveFromSecurityGroup = _.compact(_.map($scope.users, function(user) {
                            if ((securityGroup && user.securityGroupId == securityGroup.id) || !user.securityGroupId)
                                return user.userId;
                            return null;
                        }));

                        $http
                            .delete(('api/subscribers/{0}/securityGroups/{1}').format($scope.subscriberId, securityGroup.id))
                            .success(function(res) {
                                _.remove($scope.securityGroups, function(sg) {
                                    return sg.id == securityGroup.id;
                                });

                                return $http
                                    .put(('api/subscribers/{0}/securityGroups/{1}/removeUsers').format($scope.subscriberId, securityGroup.id), usersToRemoveFromSecurityGroup)
                                    .success(function(res) {
                                        _.forEach($scope.users, function(user) {
                                            if (_.some(usersToRemoveFromSecurityGroup, function(userId) {
                                                    return userId == user.userId;
                                                }))
                                                user.securityGroupId = null;
                                        });

                                        addAlert(res.msg || 'Delete was successful!');
                                    })
                                    .error(function(err) {
                                        addAlert(err.msg || 'Delete was unsuccessful!', true);
                                    })
                            })
                            .error(function(err) {
                                addAlert(err.msg || 'Delete was unsuccessful!', true);
                            });
                    }
                });
        };
    }
]);
