angular.module('angus.controllers').controller('acctGainLossSubRegSettingsCtrl', [
    '$q', '$scope', 'constantsService', 'productsService', 'promiseMonitor', 'salesPersonService', 'tradeClassService',
    function ($q, $scope, constantsService, productsService, promiseMonitor, salesPersonService, tradeClassService) {
        'use strict';
        $scope.promiseMonitor = new promiseMonitor();

        $scope.periodIndicators = constantsService.marginPeriodIndicators.enums;

        var promise = $q.all([productsService.getProducts($scope.subscriberId)
            .then(function (products) {
                $scope.products = products;
            }),
        salesPersonService.getSalesPerson($scope.subscriberId)
            .then(function (classes) {
                $scope.salespersons = classes;
            }),
        tradeClassService.getAllTradeClasses($scope.subscriberId)
            .then(function (tradeClasses) {
                $scope.tradeClasses = tradeClasses;
            })
        ]);

        $scope.promiseMonitor.monitor(promise);
    }
]);

