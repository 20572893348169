angular.module('angus.controllers').controller('adeptExecutionDetailsController', [
    '$q', '$rootScope', '$scope', '$timeout', 'adeptInquiryService', 'adeptService', 'constantsService', 'gridService2', 'gridState', 'subscriberService',
    function ($q, $rootScope, $scope, $timeout, adeptInquiryService, adeptService, constantsService, gridService2, gridState, subscriberService) {

        /*** PARAMS ***
         * subscriberId
         * 
         * tab ('tank' | 'adeptGroup')
         * 
         * -- Tank --
         * customerKey
         * containerKey - To Do: ContainerKey needs to be added as a binding to the Container Select. Container Select needs to watch for it.
         * startDate
         * endDate
         * 
         * -- Adept Group --
         * dynamicReserveSettingsKey
         * dynamicReserveExecutionKey
         * includeUnscheduledDays
         */


        const tabs = {
            tank: 'tank',
            adeptGroup: 'adeptGroup'
        }

        $scope.subscriberId = $rootScope.user.subscriberId;
        $scope.isSysAdmin = !!$rootScope.user.isSysAdmin;
        $scope.widgetCode = 'adeptInquiryExecutionDetails';

        $scope.pageLoaded = false;

        /* Add savedSettings as an empty object since we don't want any of the settings saved. 
            If we do need to retrieve any settings in the future we should be able to add a watch on filters and then update the setting(s) that are needed.*/
        $scope.savedSettings = {};

        /* Shared across tabs */
        $scope.handleSubscriberChanged = handleSubscriberChanged;

        $scope.handleCustomerSelected = handleCustomerSelected;
        $scope.handleCustomerInputChanged = handleCustomerInputChanged;

        $scope.handleContainerSelected = handleContainerSelected;

        $scope.isApplyDisabled = isApplyDisabled;
        $scope.areControlsDisabled = areControlsDisabled;
        $scope.runReport = runReport;

        $scope.handleAdeptGroupChanged = handleAdeptGroupChanged;
        $scope.handleUnscheduledExecutionsChanged = handleUnscheduledExecutionsChanged;
        $scope.onTabChanged = handleSettingsTabChanged;

        $scope.handleExclusionReasonClicked = handleExclusionReasonClicked;

        $scope.$on('adeptInquiryActiveTabChanged',
            function (event, newActiveTab, params) {

                /* Only do work if the active tab is Execution Details */
                if (newActiveTab === adeptInquiryService.tabs.executionDetails) {
                    /* Only update the settings if we have parameters. 
                        Otherwise we assume they are just returning to the tab without activating it for a Tank or Group*/
                    if (params) {
                        updateSettingsFromParameters(params);

                        loadDropDowns()
                            .then(function () {
                                runReport();
                            });
                    }
                }
            });


        function loadDropDowns() {
            return $q.when()
                .then(function () {
                    if ($scope.isSysAdmin && !$scope.subscribers) {
                        return loadSubscribers();
                    }
                })
                .then(function () {
                    /* Load Tab data */
                    if ($scope.settings.activeTab === tabs.adeptGroup) {
                        return loadAdeptGroupDropDowns($scope.settings.selectedSubscriberId);
                    }
                    else {
                        return loadTankDropDowns($scope.selectedSubscriberId);
                    }
                });
        }

        function loadTankDropDowns() {
            /* Nothing to do here. The Type Ahead Component will use the updated CustomerKey to Select the Customer, which then triggers the onCustomerSelected event */
            return $q.when();
        }

        function loadAdeptGroupDropDowns() {
            return loadAdeptGroups()
                .then(function () {
                    if ($scope.settings.adeptGroupId) {
                        return loadExecutions();
                    }
                    else {
                        return $q.when();
                    }
                });
        }


        var gridParams = {
            gridOptions: {},
            gridState: gridState($scope.subscriberId, $scope.widgetCode),
            defs: getColDefs(),
            clicks: getColClicks(),
            exportOptions: { fileName: 'ADEPT Execution Details' }
        }

        gridService2.createGrid(gridParams)
            .then(function (grid) {
                grid.setRows(Promise.resolve());

                $scope.grid = grid;
            });

        function getColDefs() {
            var colDefs = [];

            colDefs.push(gridService2.colDef.createDate('executionDate', 'Execution Date', 'executionDate', { sort: 'desc' }));
            colDefs.push(gridService2.colDef.createYear('executionDateYear', 'Execution Year', 'executionDateYear', { hide: true }));
            colDefs.push(gridService2.colDef.createMonth('executionDateMonth', 'Execution Month', 'executionDateMonth', { hide: true }));
            colDefs.push(gridService2.colDef.createBoolean('isUnscheduledExecution', 'Unscheduled', 'isUnscheduledExecution', {}, { hide: true }));

            colDefs.push(gridService2.colDef.createText('customerId', 'Account Number', 'customerId', {}));
            colDefs.push(gridService2.colDef.createText('customerName', 'Customer Name', 'customerName', { hide: true }, true));
            colDefs.push(gridService2.colDef.createText('customerLocationId', 'Location Number', 'customerLocationId', {}));
            colDefs.push(gridService2.colDef.createText('containerId', 'Tank Number', 'containerId', {}));

            colDefs.push(gridService2.colDef.createNumber('capacity', 'Tank Size', 'capacity', {}, { decimalPlaces: 0 }));
            colDefs.push(gridService2.colDef.createNumber('usableVolume', 'Tank Usable', 'usableVolume', {}, { decimalPlaces: 0 }));
            colDefs.push(gridService2.colDef.createNumber('optimalDeliveryUnits', 'Ideal Units', 'optimalDeliveryUnits', {}, { decimalPlaces: 0 }));
            colDefs.push(gridService2.colDef.createNumber('reserveUnits', 'BOS Reserve Units', 'reserveUnits', {}, { decimalPlaces: 0 }));

            colDefs.push(gridService2.colDef.createBoolean('isTankMonitored', 'Monitored', 'isTankMonitored'));
            colDefs.push(gridService2.colDef.createBoolean('isTmsTankMonitorFlag', 'AMS', 'isTmsTankMonitorFlag'));
            colDefs.push(gridService2.colDef.createNumber('kFactorCurrent', 'Current K-Factor', 'kFactorCurrent', {}, { decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createNumber('kFactorToUse', 'K-Factor Used', 'kFactorToUse', {}, { decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createNumber('baseLoadUnits', 'Baseload', 'baseLoadUnits', {}, { decimalPlaces: 1 }));

            colDefs.push(gridService2.colDef.createText('degreeDayHeaderId', 'DD Log Number', 'degreeDayHeaderId', {}));
            colDefs.push(gridService2.colDef.createText('degreeDayHeaderName', 'DD Log Name', 'degreeDayHeaderName', { hide: true }));
            colDefs.push(gridService2.colDef.createText('degreeDayHeaderDescription', 'DD Log Description', 'degreeDayHeaderDescription', { hide: true }));
            colDefs.push(gridService2.colDef.createNumber('degreeDaysFutureSum', 'Est DD Until Reserve Next Delivery', 'degreeDaysFutureSum', { hide: false }, { decimalPlaces: 0 }));

            colDefs.push(gridService2.colDef.createNumber('unitsUsedKFactorFutureSum', 'Est KF Units Until Reserve Next Delivery', 'unitsUsedKFactorFutureSum', { hide: false }, { decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createNumber('unitsUsedBaseLoadFutureSum', 'Est BL Units Until Reserve Next Delivery', 'unitsUsedBaseLoadFutureSum', { hide: false }, { decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createNumber('unitsUsedFutureSum', 'Est Total Units Used Until Reserve Next Delivery', 'unitsUsedFutureSum', { hide: false }, { decimalPlaces: 1 }));

            colDefs.push(gridService2.colDef.createNumber('containerUnitsAvailableNextDelivery', 'Est In-Tank Units On Reserve Next Delivery', 'containerUnitsAvailableNextDelivery', { hide: true }, { decimalPlaces: 1 }));

            colDefs.push(gridService2.colDef.createDate('reserveNextDeliveryDate', 'Reserve Next Delivery Date', 'reserveNextDeliveryDate', {}));
            colDefs.push(gridService2.colDef.createYear('reserveNextDeliveryDateYear', 'Reserve Next Delivery Year', 'reserveNextDeliveryDateYear', { hide: true }));
            colDefs.push(gridService2.colDef.createMonth('reserveNextDeliveryDateMonth', 'Reserve Next Delivery Month', 'reserveNextDeliveryDateMonth', { hide: true }));

            colDefs.push(gridService2.colDef.createNumber('reserveUnitsBasedOnDaysReserve', 'Reserve Units Target By Days', 'reserveUnitsBasedOnDaysReserve', { hide: false }, { decimalPlaces: 0 }));
            colDefs.push(gridService2.colDef.createNumber('reserveMinimum', 'Reserve Units Target By %', 'reserveMinimum', { hide: false }, { decimalPlaces: 0 }));
            colDefs.push(gridService2.colDef.createNumber('reserveUnitsToUse', 'Reserve Units Used', 'reserveUnitsToUse', { hide: false }, { decimalPlaces: 0 }));

            colDefs.push(gridService2.colDef.createNumber('degreeDaysReserve', 'DD Used To Reserve', 'degreeDaysReserve', { hide: false }, { decimalPlaces: 0 }));

            colDefs.push(gridService2.colDef.createDate('sourceNextDeliveryDate', 'BOS Next Delivery Date', 'sourceNextDeliveryDate', {}));
            colDefs.push(gridService2.colDef.createYear('sourceNextDeliveryDateYear', 'BOS Next Delivery Year', 'sourceNextDeliveryDateYear', { hide: true }));
            colDefs.push(gridService2.colDef.createMonth('sourceNextDeliveryDateMonth', 'BOS Next Delivery Month', 'sourceNextDeliveryDateMonth', { hide: true }));

            colDefs.push(gridService2.colDef.createText('degreeDayNext', 'BOS DD Next', 'degreeDayNext', { hide: false, cellStyle: { 'text-align': 'end' } }));

            colDefs.push(gridService2.colDef.createNumber('optimalKFactorToUse', 'Optimized K-Factor Used', 'optimalKFactorToUse', { hide: true }, { decimalPlaces: 1 }));

            colDefs.push(gridService2.colDef.createDate('optimalNextDeliveryDate', 'Optimized Next Delivery Date', 'optimalNextDeliveryDate', {}));
            colDefs.push(gridService2.colDef.createYear('optimalNextDeliveryDateYear', 'Optimized Next Delivery Year', 'optimalNextDeliveryDateYear', { hide: true }));
            colDefs.push(gridService2.colDef.createMonth('optimalNextDeliveryDateMonth', 'Optimized Next Delivery Month', 'optimalNextDeliveryDateMonth', { hide: true }));

            colDefs.push(gridService2.colDef.createText('exportStatusName', 'Export Status', 'exportStatusName', { hide: false }));

            colDefs.push(gridService2.colDef.createNumber('containerUnitsAvailable', 'In-Tank Units', 'containerUnitsAvailable', { hide: false }, { decimalPlaces: 1 }));

            colDefs.push(gridService2.colDef.createDate('finalNextDeliveryDate', 'Final Next Delivery Date', 'finalNextDeliveryDate', {}));
            colDefs.push(gridService2.colDef.createYear('finalNextDeliveryDateYear', 'Final Next Delivery Year', 'finalNextDeliveryDateYear', { hide: true }));
            colDefs.push(gridService2.colDef.createMonth('finalNextDeliveryDateMonth', 'Final Next Delivery Month', 'finalNextDeliveryDateMonth', { hide: true }));

            colDefs.push(gridService2.colDef.createNumber('optimizedDays', 'Optimized Days', 'optimizedDays', { hide: false }, { decimalPlaces: 0 }));

            colDefs.push(gridService2.colDef.createNumber('baseLoadUnitsMaximum', 'Baseload Units Max', 'baseLoadUnitsMaximum', { hide: false }, { decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createNumber('baseLoadUnitsToUse', 'Baseload Units Used', 'baseLoadUnitsToUse', { hide: false }, { decimalPlaces: 1 }));

            colDefs.push(gridService2.colDef.createBoolean('hasExistingDeliveryTicket', 'Existing Delivery Ticket', 'hasExistingDeliveryTicket'));
            colDefs.push(gridService2.colDef.createBoolean('isAcquisitionDateExclusion', 'Acquisition Date Excl', 'isAcquisitionDateExclusion'));
            colDefs.push(gridService2.colDef.createBoolean('hasRecentDeliveryInvalidUnitsAvailable', 'Invalid ITR', 'hasRecentDeliveryInvalidUnitsAvailable', {}/*, {headerTooltip: ''}*/));
            colDefs.push(gridService2.colDef.createBoolean('hasTMSMonitoredPotentialDelivery', 'AMS Potential Delivery', 'hasTMSMonitoredPotentialDelivery'));

            colDefs.push(gridService2.colDef.createBoolean('isShortDelivery', 'Short Delivery', 'isShortDelivery'));
            colDefs.push(gridService2.colDef.createNumber('optimizedContainerUnitsAvailableNextDelivery', 'Est In-Tank Units On Optimized Next Delivery', 'optimizedContainerUnitsAvailableNextDelivery', { hide: false }, { decimalPlaces: 1 }));

            colDefs.push(gridService2.colDef.createBoolean('hasTMSNonRecentUnitsAvailable', 'AMS Non-Recent Units', 'hasTMSNonRecentUnitsAvailable'));
            colDefs.push(gridService2.colDef.createBoolean('isCreditCardExclusion', 'CC Exclusion', 'isCreditCardExclusion'));
            colDefs.push(gridService2.colDef.createBoolean('isExcludeFromDelivery', 'Exclude From Delivery', 'isExcludeFromDelivery'));
            colDefs.push(gridService2.colDef.createBoolean('hasDeliverySchedule', 'Delivery Schedule', 'hasDeliverySchedule'));
            colDefs.push(gridService2.colDef.createBoolean('hasExceededExportDeliveryDays', 'Export Delivery Days Exceeded', 'hasExceededExportDeliveryDays'));



            return colDefs;
        }

        function getColClicks() {
            /* To Do - This part works. But I need to make sure the Account Number and Tank drop downs are working as expected. Plus to make sure the report is loading. */
            return {
                containerId: {
                    onClick: function (row) {
                        const settings = $scope.settings;

                        if (settings.activeTab === tabs.tank) {
                            /* Already on the Tanks Tab.  Don't run the same report again */
                            return;
                        }

                        const params = {
                            subscriberId: settings.selectedSubscriberId,
                            tab: tabs.tank,
                            customerKey: row.customerKey,
                            containerKey: row.containerKey
                        };

                        adeptInquiryService.activateTab(adeptInquiryService.tabs.executionDetails, params);
                    }
                },
                executionDate: {
                    onClick: function (row) {
                        const settings = $scope.settings;
                        const includeExcludeOnly = constantsService.includeExcludeOnly;

                        if (settings.activeTab === tabs.adeptGroup) {
                            /* Already on the ADEPT Group Tab. Don't run the same report again */
                            return;
                        }

                        const params = {
                            subscriberId: settings.selectedSubscriberId,
                            tab: tabs.adeptGroup,
                            dynamicReserveSettingsKey: row.dynamicReserveSettingsKey,
                            dynamicReserveExecutionKey: row.dynamicReserveExecutionKey,
                            includeUnscheduledDays: row.isUnscheduledExecution ? includeExcludeOnly.include.key : includeExcludeOnly.exclude.key
                        };

                        adeptInquiryService.activateTab(adeptInquiryService.tabs.executionDetails, params);
                    }
                }
            }
        }


        function isApplyDisabled() {
            if ($scope.settings.activeTab === tabs.tank) {
                
                /* No Subscriber */
                if (!$scope.settings.selectedSubscriberId) {
                    return true;
                }

                /* No Customer */
                if (!$scope.settings.customerKey) {
                    return true;
                }

                /* No Container */
                if (!$scope.settings.containerKey) {
                    return true;
                }

                /* No Dates */
                if (!$scope.settings.startDate || !$scope.settings.endDate) {
                    return true;
                }
            }
            else {
                /* No Subscriber */
                if (!$scope.settings.selectedSubscriberId) {
                    return true;
                }

                /* No ADEPT Group */
                if (!$scope.settings.adeptGroupId) {
                    return true;
                }

                /* No ADEPT Execution */
                if (!$scope.settings.dynamicReserveExecutionKey) {
                    return true;
                }
            }

            return false;
        }

        function areControlsDisabled() {
            return $scope.areSubscribersLoading || $scope.areAdeptGroupsLoading || $scope.areAdeptExecutionsLoading;
        }


        /* Handlers */
        function handleSettingsTabChanged(tab) {
            $scope.settings.activeTab = tab;

            if (tab == tabs.adeptGroup && $scope.settings.selectedSubscriberId && !$scope.adeptGroups) {
                loadAdeptGroupDropDowns();
            }
        }

        function handleSubscriberChanged() {

            /* Handle Tanks Tab */
            clearCustomerData();

            /* Handle ADEPT Groups Tab */
            clearAdeptGroupData();
            clearAdeptExecutionData();
            loadAdeptGroups();
        }

        function handleAdeptGroupChanged() {
            clearAdeptExecutionData();

            loadExecutions();
        }

        function handleUnscheduledExecutionsChanged() {
            $timeout(function () {
                clearAdeptExecutionData();

                loadExecutions();
            });
        }

        function handleCustomerSelected(newCustomer) {
            if (newCustomer) {
                $scope.settings.customerKey = newCustomer.customerKey;
            }
        }

        function handleCustomerInputChanged() {
            clearCustomerData();
        }

        function handleContainerSelected(newContainer) {
            if (newContainer) {
                $scope.settings.containerKey = newContainer.containerKey;
            }
        }

        function handleExclusionReasonClicked() {
            const params = {
                subscriberId: $scope.settings.selectedSubscriberId,
                customerKey: $scope.settings.customerKey,
                containerKey: $scope.settings.containerKey
            }

            adeptInquiryService.activateTab(adeptInquiryService.tabs.tankInquiry, params);
        }

        function clearCustomerData() {
            $scope.settings.customerKey = null;

            clearContainerData();
        }

        function clearContainerData() {
            $scope.settings.containerKey = null;
        }


        function updateSettingsFromParameters(params) {
            $scope.settings.selectedSubscriberId = params.subscriberId;
            $scope.settings.activeTab = params.tab;

            if ($scope.settings.activeTab === tabs.tank) {
                $scope.settings.customerKey = params.customerKey;
                $scope.settings.containerKey = params.containerKey;
            }
            else {
                $scope.settings.adeptGroupId = params.dynamicReserveSettingsKey;
                $scope.settings.dynamicReserveExecutionKey = params.dynamicReserveExecutionKey;
                $scope.settings.includeUnscheduledDays = params.includeUnscheduledDays || $scope.settings.includeUnscheduledDays
            }
        }


        function init() {

            /* Default Settings */
            $scope.settings = {};
            $scope.settings.activeTab = tabs.tank;
            $scope.settings.includeUnscheduledDays = 'exclude';

            if (!$scope.isSysAdmin) {
                $scope.settings.selectedSubscriberId = $scope.subscriberId;
            }

            /* Default Dates */
            const today = new Date();

            $scope.settings.startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
            $scope.settings.endDate = new Date(today);


            /* Update Settings from Parameters */
            const params = adeptInquiryService.params;
            if (params) {
                updateSettingsFromParameters(params);
            }

            loadDropDowns()
                .then(function () {
                    $scope.pageLoaded = true;

                    if (params) {
                        runReport();
                    }
                });
        }

        function loadSubscribers() {
            $scope.areSubscribersLoading = true;

            return subscriberService.getAdeptSubscribers()
                .then(function (subscribers) {
                    $scope.subscribers = subscribers;
                    $scope.areSubscribersLoading = false;
                });
        }

        /*** ADEPT GROUPS ***/
        function clearAdeptGroupData() {
            $scope.adeptGroups = null;
            $scope.settings.adeptGroupId = null;

        }

        function clearAdeptExecutionData() {
            $scope.adeptExecutions = null;
            $scope.settings.dynamicReserveExecutionKey = null;

        }


        function loadAdeptGroups() {

            const settings = $scope.settings;

            if (!settings.selectedSubscriberId) {
                return;
            }

            $scope.areAdeptGroupsLoading = true;

            return adeptService.getAdeptGroupsLegacy(settings.selectedSubscriberId)
                .then(function (adeptGroups) {
                    $scope.adeptGroups = adeptGroups;
                    $scope.areAdeptGroupsLoading = false;

                    /* If a subscriber only has 1 group then default the selection to that group*/
                    if (adeptGroups.length === 1) {
                        $scope.settings.adeptGroupId = adeptGroups[0].id;

                        loadExecutions();
                    }
                });

        }

        function loadExecutions() {

            const settings = $scope.settings;

            /* Don't do anything if there's any missing settings. */
            if (!settings.selectedSubscriberId || !settings.adeptGroupId || !settings.includeUnscheduledDays) {
                return;
            }

            $scope.areAdeptExecutionsLoading = true;

            const includeExcludeOnlyOptions = constantsService.includeExcludeOnlyOptions;

            const subscriberId = settings.selectedSubscriberId;
            const adeptGroupIds = [settings.adeptGroupId];
            const includeUnscheduledDays = includeExcludeOnlyOptions[settings.includeUnscheduledDays] || includeExcludeOnlyOptions.exclude;

            /* Start and End dates will default to retrieve the last 1 year of data */
            const today = new Date();

            const startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
            const endDate = new Date(today);

            return adeptService.getExecutions(subscriberId, adeptGroupIds, startDate, endDate, includeUnscheduledDays)
                .then(function (executions) {

                    $scope.areAdeptExecutionsLoading = false;

                    /* Since the Executions are for the Execution Date Drop Down only, update the object for the drop down */
                    $scope.adeptExecutions = executions.map(function (execution) {

                        const dynamicReserveExecutionDate = new Date(execution.dynamicReserveExecutionDate);

                        const options = {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                        };


                        return {
                            dynamicReserveExecutionKey: execution.dynamicReserveExecutionKey,
                            dynamicReserveExecutionDate: dynamicReserveExecutionDate,
                            isUnscheduledExecution: execution.isUnscheduledExecution,
                            description: `${dynamicReserveExecutionDate.toLocaleDateString('en-US', options)}${execution.isUnscheduledExecution ? '*' : ''}`
                        };
                    });
                });
        }

        function runReport() {
            const settings = $scope.settings;

            const subscriberId = settings.selectedSubscriberId;
            var dynamicReserveExecutionKey;
            var containerKey, startDate, endDate;

            if ($scope.settings.activeTab === tabs.tank) {
                containerKey = settings.containerKey;
                startDate = settings.startDate;
                endDate = settings.endDate;
            }
            else {
                dynamicReserveExecutionKey = settings.dynamicReserveExecutionKey;
            }

            var rowPromise = adeptService.getExecutionDetails(subscriberId, dynamicReserveExecutionKey, containerKey, startDate, endDate)
                .then(function (data) {
                    data.forEach(function (row) {
                        /* Execution Date */
                        row.executionDateYear = row.executionDate;
                        row.executionDateMonth = row.executionDate;

                        /* Reserve Next Delivery Date */
                        row.reserveNextDeliveryDateYear = row.reserveNextDeliveryDate
                        row.reserveNextDeliveryDateMonth = row.reserveNextDeliveryDate

                        /* Source Next Delivery Date */
                        row.sourceNextDeliveryDateYear = row.sourceNextDeliveryDate
                        row.sourceNextDeliveryDateMonth = row.sourceNextDeliveryDate

                        /* Optimal Next Delivery Date */
                        row.optimalNextDeliveryDateYear = row.optimalNextDeliveryDate
                        row.optimalNextDeliveryDateMonth = row.optimalNextDeliveryDate

                        /* Final Next Delivery Date */
                        row.finalNextDeliveryDateYear = row.finalNextDeliveryDate
                        row.finalNextDeliveryDateMonth = row.finalNextDeliveryDate
                    });

                    return data;
                });

            var promise = $scope.grid.setRows(rowPromise);

            return promise;
        }


        init();
    }
]);