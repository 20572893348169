'use strict'
angular.module('angus.directives').directive('angusLineGraph',
	[
	function() {
		return {
			require:'=data',
      template:'<g></g>',
			link: function(scope, ele, att) {

			console.log(scope,'LINEGRAPH');


			}
		}
	}
]);
