angular.module('angus.controllers').controller('arSummaryReportContentCtrl', [
    '$scope', '$rootScope', '$http', '$q', '_', 'constantsService', 'gridService2', 'nodeType', 'gridState',
    function($scope, $rootScope, $http, $q, _, constantsService, gridService2, nodeType, gridState) {
        'use strict';

        const includeExcludeOnlyOptions = constantsService.includeExcludeOnlyOptions;

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.filter =  {
            hierarchyNodeId: $scope.settings.hierarchyNodeId,
            tradeClassIds: $scope.settings.tradeClassIds,
            budgetCustomers: $scope.settings.budgetCustomers
        };

        $scope.ageAgainst = $scope.settings.ageAgainst;

        $scope.getAR = function(){  
                            
            var params = {
                divisionId: $scope.filter.hierarchyNodeId,
                includeBudgetCustomers: includeExcludeOnlyOptions[$scope.filter.budgetCustomers] || includeExcludeOnlyOptions.include,
                tradeClassIds: $scope.filter.tradeClassIds,
            };

            var rowPromise = $http({            
                    url: ('api/subscribers/{0}/accountsReceivable/').format(subscriberId),
                    method: 'POST',
                    data: params
            }).then(function(result){                 
                return result.data;
            });        

            var promise = $scope.grid.setRows(rowPromise);

            return promise;
        };

        function getArAgingPeriods(){
            return $http({
                url: ('api/subscribers/{0}/accountsReceivable/agingPeriods').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    divisionId: $scope.settings.hierarchyNodeId
                }
            })
            .then(function(result){
                return result.data;
            });
        }



        function getColDefs(ageAgainst){
            // var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];                 

            colDefs.push(gridService2.colDef.createText('customerNumber','Account Number','customer.number', { cellClass : ['center']}));
            colDefs.push(gridService2.colDef.createText('customerName','Customer Name','customer.name'));

            colDefs.push(gridService2.colDef.createText('phoneNumber', 'Phone Number', 'customer.phoneNumber'));
            colDefs.push(gridService2.colDef.createText('emailAddress', 'Email Address', 'customer.emailAddress'));

            colDefs.push(gridService2.colDef.createCurrency('balanceAmount','Balance','balance', {}, {decimalPlaces: 2 }));            

            if (agingPeriods) {
                 _.forEach(agingPeriods, function(agingPeriod){

                    var colId = 'customerAging' + agingPeriod.id;
                    var field = 'agingPeriods.' + agingPeriod.id;
                    var label = agingPeriod.label;
                    var renderOpts = { decimalPlaces: 2 };

                    if(ageAgainst === 'dueDate'){
                        field +='.balanceDueDueDateAging';                        
                    } else {

                        // Remove the 'Late prefix when not ageing against the due date
                        if (label.startsWith("Late")) {
                            label = label.substring(5);
                        }
                        
                        field +='.balanceDueInvoiceDateAging';                     
                    }

                    colDefs.push(gridService2.colDef.createCurrency(colId, label, field, {}, renderOpts));                    
                 });
            }

            colDefs.push(gridService2.colDef.createBoolean('budgetFlag', 'Budget', 'budgetFlag'));
            colDefs.push(gridService2.colDef.createText('tradeClass', 'Trade Class', 'tradeClass'));
            colDefs.push(gridService2.colDef.createDate('lastPaymentDate','Last Payment Date','lastPaymentDate'));
            colDefs.push(gridService2.colDef.createCurrency('lastPaymentAmount', 'Last Payment Amount', 'lastPaymentAmount', {}, {decimalPlaces: 2}));
            

            // DIFF check the existing grid context and assure new columns match previous visible state
            // Note: this must only be performed if the current scope has a grid instance
            if (_.has($scope, ['grid', 'col', 'defs'])) {

                var colDiffCheck = function(colDef) {

                    // Confirm that current column defs contain 
                    // column in question, otherwise proceed
                    if (!$scope.grid.col.defs.hasOwnProperty(colDef.colId)) {
                        return;
                    }

                    // Query the current column state via the column api attached
                    // to the grid options. This is the only reliable way to get current state.
                    var currentColState = $scope.grid.options.columnApi.getColumn(colDef.colId);

                    // Set column hidden state based on current column's visible property
                    colDef.hide = !currentColState.visible;

                };

                _.forEach(colDefs, colDiffCheck);
            }


            return colDefs;                
        } 

        //Toggles
        $scope.changeAgeAgainst = function(ageAgainst){
            $scope.grid.setColDefs(getColDefs(ageAgainst), true);    
        };

        var nodeColDefs;
        var agingPeriods;

        $q.all([
            nodeType.getDivisionColDefs(subscriberId),
            getArAgingPeriods()
            ])     
            .spread(function(_nodeColDefs, _agingPeriods){
                nodeColDefs = _nodeColDefs;
                agingPeriods = _agingPeriods;
                var gridParams = { 
                        gridOptions : {
                            groupAggFunction : function(rows){
                                return _.reduce(rows, function(newRow, row){
                                    var data = row.data;

                                    newRow.balance += data.balance;

                                    _.forEach(data.agingPeriods, function(agingPeriod, agingPeriodKey){
                                        if (!newRow.agingPeriods[agingPeriodKey]){
                                            newRow.agingPeriods[agingPeriodKey] = {
                                               balanceDueInvoiceDateAging : 0,
                                               balanceDueDueDateAging : 0
                                            }
                                        }

                                        newRow.agingPeriods[agingPeriodKey].balanceDueInvoiceDateAging += agingPeriod.balanceDueInvoiceDateAging;
                                        newRow.agingPeriods[agingPeriodKey].balanceDueDueDateAging += agingPeriod.balanceDueDueDateAging;
                                    });

                                    return newRow;
                                },{
                                    agingPeriods : {},
                                    balance: 0
                                });
                        },
                    },
                    gridState     : gridState(subscriberId, $scope.widgetCode),                    
                    defs          : getColDefs($scope.ageAgainst),
                    //isExternalFilterPresent: isExternalFilterPresent,
                    // clicks        : getColClicks(),
                    exportOptions : { fileName: 'Accounts Receivable' }
                };
                return gridService2.createGrid(gridParams);
            })
            .then(function(grid){       
                $scope.grid = grid;        
                $scope.getAR();
            });
    }
]);
