angular.module('angus.controllers').controller('tankProfAnalysisSubRegSettingsCtrl', [
    '$scope', 'constantsService', 'promiseMonitor', 'productsService',
    function($scope, constantsService, promiseMonitor, productsService) {

        $scope.promiseMonitor = new promiseMonitor();

        var promise = productsService.getProducts($scope.subscriberId)
            .then(function(products) {
                $scope.products = products;               
            });

        $scope.ieoOptions = constantsService.includeExcludeOnly.enums;

        $scope.promiseMonitor.monitor(promise);
    }
]);