angular.module('angus.providers')
    .provider('modalService', function() {
        'use strict';
        this.$get = [
            '$rootScope', '$q', '$compile', '$templateCache', '$document', '_', 'ModalInstance', '$timeout', 'actionViewTemplates', 'windowsService',
            function($rootScope, $q, $compile, $templateCache, $document, _, ModalInstance, $timeout, actionViewTemplates, windowsService) {
                var modal = {};

                function appendHtml(modalType, templateUrl, scope) {
                    var modalContainer, modalContent;

                    modalContainer = $compile($templateCache.get(modalType.url))(scope);

                    var parent = $document.find('body').eq(0);

                    parent.append(modalContainer);
                    modalContent = modalContainer.find(modalType.contentSelector);

                    var content = $compile($templateCache.get(templateUrl))(scope);
                    modalContent.append(content);

                    return {
                        modalContainer: modalContainer,
                        modalContent: modalContent
                    };
                }


                modal.types = {
                    topBar: {
                        url: 'topBarModal.html',
                        contentSelector: '.topBarModalContent'
                    },
                    detailView: {
                        url: 'detailView.html',
                        contentSelector: '.detailViewContent',
                        tabUrl: 'detailViewTab.html'
                    },
                    fullPage: {
                        url: 'fullPageModal.html',
                        contentSelector: '.fullPageModalContent'
                    }
                };

                function createModalScope(callingScope, deferred, modalInstance) {
                    var modalScope = {
                        close: function(value) {
                            if(modalInstance) modalInstance.close();
                            deferred.resolve(value || null);
                        }

                    };
                    return _.merge($rootScope.$new(), _.merge(modalScope, callingScope || {}));
                }

                function openModal(templateUrl, callingScope, modalType, onHTMLCallback) {
                    var deferred = $q.defer();
                    var modalInstance = new ModalInstance();

                    var scope = createModalScope(callingScope, deferred, modalInstance);

                    if(!templateUrl)
                        throw new Error('The templateUrl parameter is required.');

                    //WTF?  This shouldn't be needed.  Something is funky with the async(?) nature of the $compile.
                    $timeout(function() {
                        var html = appendHtml(modalType, templateUrl, scope);
                        modalInstance.setHtml(html);

                        if(onHTMLCallback) {
                            $timeout(function() {
                                onHTMLCallback(html.modalContainer);
                            });
                        }
                    }, 100);

                    return deferred.promise;
                }

                modal.openFullPageModal = function(templateUrl, callingScope) {
                    return openModal(templateUrl, callingScope, modal.types.fullPage);
                };

                modal.openTopBarModal = function(templateUrl, callingScope, onHTMLCallback) {
                    return openModal(templateUrl, callingScope, modal.types.topBar, onHTMLCallback);
                };

                modal.getConfirmation = function(title, message) {
                    return openModal(actionViewTemplates.confirmDialog, {
                        title: title,
                        message: message
                    }, modal.types.topBar);
                };

                modal.open = function(modalOptions, modalType, noAnimation) {
                    var modalContainer, modalContent;

                    function addContentToModal(startHidden) {
                        modalContent = modalContainer.find(modalType.contentSelector);

                        if(startHidden) {
                            modalContainer.hide();
                            modalContent.hide();
                        }

                        var html = $templateCache.get(modalOptions.templateUrl);
                        var content = $compile(html)(modalOptions.scope);

                        modalContent.append(content);

                        if(startHidden)
                            modalContainer.fadeIn(100, function() {
                                modalContent.fadeIn(500, function() {

                                });
                            });
                    }

                    var modalInstance = {
                        close: function(stopAnimation) {
                            if(!stopAnimation)
                                modalContainer.remove();
                            else {
                                modalContent.fadeOut(150, function() {
                                    modalContainer.fadeOut(150, function() {
                                        modalContainer.remove();
                                    });
                                });
                            }
                        },
                        dismiss: function() {
                            modalContainer.remove(modalOptions);
                        }
                    };

                    if(!modalOptions.template && !modalOptions.templateUrl)
                        throw new Error('One of template or templateUrl options is required.');

                    modalContainer = $compile($templateCache.get(modalType.url))(modalOptions.scope);
                    var parent = $document.find('body').eq(0);

                    parent.append(modalContainer);
                    addContentToModal(!noAnimation);
                    return modalInstance;
                };


                modal.openActionView = function(templateUrl, callingScope, key) {

                    const widgetId = callingScope.widgetId || callingScope.widget && callingScope.widget.instance && callingScope.widget.instance.id || key;

                    /* Try to get an existing window */
                    const window = windowsService.getWindow(widgetId);

                    if (window) {
                        window.updateCallingScope(callingScope);
                        windowsService.activateWindow(window.id);
                    }
                    else {
                        windowsService.addWindow(widgetId, callingScope.widgetName, callingScope.widgetIcon, templateUrl, callingScope);
                    }
                };

                return modal;
            }
        ];
    });
