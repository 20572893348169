angular.module('angus.services').factory('pricePlanService', [
    '$http', '$rootScope', '_', '$q', 'fluentRest', 'serviceHelper',
    function($http, $rootScope, _, $q, fluentRest, serviceHelper) {
        'use strict';
        
        var subscriberPricePlans    = [];
        var pricePlans              = [];

        function getPricePlan(subscriberId) {
            if (subscriberId) {
                if (subscriberPricePlans[subscriberId] && subscriberPricePlans[subscriberId].length > 0)
                    return subscriberPricePlans[subscriberId];
                else {
                    return queryPricePlan(subscriberId)
                        .then(function(pricePlanList) {
                            subscriberPricePlans[subscriberId] = pricePlanList.data;
                            return subscriberPricePlans[subscriberId];
                        });
                }
            } else {
                if (pricePlans && pricePlans.length > 0)
                    return pricePlans;
                else {
                    return queryPricePlan()
                        .then(function(pricePlanList) {
                            pricePlans = pricePlanList.data;
                            return pricePlans;
                        });
                }
            }
        }

        function queryPricePlan(subscriberId) {
           return $http.get(('api/subscribers/{0}/pricingPlans/').format(subscriberId || $rootScope.user.subscriberId));
        }

        return {
            getPricePlans: function(subscriberId) {
                return $q.when(getPricePlan(subscriberId))
                    .then(function(PricePlan) {
                        return PricePlan;
                    });
            },
            getBudgetCategories: function (subscriberId, params) {
                var req = fluentRest.api().subscribers(subscriberId).budgets().categories().pricePlans();
                return serviceHelper.listOrPage(req, params);
            },
            getFinanceStandards: function () {
                return fluentRest.api().finance().standards().pricePlans().get();                
            },
            saveStandardMapping : function(subscriberId, standardId, abosKeys){                
                return fluentRest.api().subscribers(subscriberId).finance().standards().pricePlans(standardId).post({ abosKeys : abosKeys});
            }
        };
    }
]);
