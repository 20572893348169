angular.module('angus.controllers').controller('callbackAnalysisSettingsCtrl', [
    '$scope', '$rootScope', '$http', '_', 'constantsService', 'productsService', 'hierarchyService',
    function($scope, $rootScope, $http, _, constantsService, productsService, hierarchyService) {

        'use strict';

        $http.get(('api/subscribers/{0}/service/techs').format($rootScope.user.subscriberId))
            .then(function(list) {
                $scope.techs = list.data;

                _.each($scope.widget.instance.settings.techs.value, function(selection) {
                    var update = _.findIndex($scope.techs, function(tech) {
                        return tech.id == selection;
                    });
                    if (update >= 0) $scope.techs[update].excludeTicked = true;
                });
            });

        $scope.periodIndicators = constantsService.analysisPeriodIndicators.enums;

        $scope.openHierarchyView = function() {
            hierarchyService.openServiceCenterModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };

        $scope.updateTechs = function(model) {
            if (model)
                $scope.widget.instance.settings.techs.value = angular.copy(model);
        };
    }
]);
