angular.module('angus.controllers').controller('growthToBudgetController', [
    '$scope', '$rootScope', '$q', '$http', 'hierarchyService', '_', 'moment', 'fiscalYearService', 'restrictedAccessService', 'fluentRest', 'dateCalculator',
    function($scope, $rootScope, $q, $http, hierarchyService, _, moment, fiscalYearService, restrictedAccessService, fluentRest, dateCalculator) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;
        var divisions = [];

        var fiscalYearOfToday = null,
            months;

        function getBudgetCategories() { return $http.get(('api/subscribers/{0}/budgets/categories/products').format(subscriberId)) }
        function getTankGainLoss() {
            return $http({
                method: 'POST',
                url: ('api/subscribers/{0}/customerAccounts/gainsLosses/byTank/summary').format(subscriberId),
                data: {
                    p: [],
                    t: "",
                    d: hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId),
                    s: moment().month($scope.month).startOf('month').year($scope.fiscalYear).format(),
                    e: moment().month($scope.month).endOf('month').year($scope.fiscalYear).format(),
                    m: 'include',
                    dt: 'include',
                    min: undefined,
                    max: undefined,
                    sp: [],
                    dts: []
                }
            });
        }
        function getBudgetVariance() {
            return $http({
                method: 'GET',
                url: (('api/subscribers/{0}/budgets/variance').format(subscriberId)),
                params: {
                    h: $scope.widget.instance.settings.hierarchyNodeId,
                    wdo: 1,
                    pc: [],
                    tc: [],
                    ppc: [],
                    fy: $scope.fiscalYear,
                    m: $scope.month
                }
            })
        }

        function getBudgets() {
            return $q.all([
                getBudgetCategories(),
                getTankGainLoss()
            ])
            .spread(function(categories, tankGainLoss) {
                var categoryIdContainer = [],
                    categoryNameContainer = [];

                _.forEach(categories.data, function(category) {
                    categoryIdContainer.push(category.id);
                    categoryNameContainer.push(category.name);
                });

                return $http({
                    method: 'GET',
                    url: (('api/subscribers/{0}/budgetsTankGainLoss/byDate').format(subscriberId)),
                    params: {
                        hierarchyNodeId: $scope.widget.instance.settings.hierarchyNodeId,
                        fiscalYear: $scope.fiscalYear,
                        month: $scope.month,
                        wdo: 1,
                        productCategoryId: categories.data ? categoryIdContainer : []
                    }
                })
                .then(function(response) {
                    $scope.budgetTotalsData = {};
                    var budgetsContainer = [];

                    _.forEach(categoryNameContainer, function(category) {
                        _.forEach(response.data.budgets, function(budget) {
                            if(category === budget.productCategory) {
                                budgetsContainer.push({
                                    productCategory: category,
                                    units: budget.budgeted,
                                    netUnits: budget.netBudgeted
                                });
                            }
                        });
                    });

                    var months = moment.months();

                    $scope.budgetTotalsData.budgets = _.reduce(budgetsContainer, function(result, budget) {
                        var existing = _.find(result, function(item) {
                            return item.productCategory === budget.productCategory;
                        });

                        if(existing) {
                            months.map(function(month) {
                                existing.units[month.toLowerCase()] += budget.units[month.toLowerCase()];
                                existing.netUnits[month.toLowerCase()] += budget.netUnits[month.toLowerCase()];
                                // existing.netUnits[month.toLowerCase()] = (tankGainLoss.data.net / existing.netUnits[month.toLowerCase()]) * 100;
                            });
                        } else {
                            result.push(budget)
                        }

                        return result;
                    }, []);

                    $scope.budgetTotalsData.dates = {
                        dataThroughDate: response.data.dataThroughDate,
                        startDate: response.data.startDate,
                        endDate: response.data.endDate,
                        month: moment.utc().month($scope.month).format('MMMM').toLowerCase(),
                        workingDays: {
                            elapsed: response.data.workingDays.elapsed,
                            total: response.data.workingDays.total
                        }
                    };

                    $scope.budgetTotalsData.netTankGainLosses = tankGainLoss.data;

                    $scope.widget.instance.settings.dataThroughDate = $scope.budgetTotalsData.dates.dataThroughDate;
                    $scope.widget.instance.settings.startDate = $scope.budgetTotalsData.dates.startDate;
                    $scope.widget.instance.settings.endDate = $scope.budgetTotalsData.dates.endDate;
                    $scope.widget.instance.settings.workingDays = {
                        elapsed: $scope.budgetTotalsData.dates.workingDays.elapsed,
                        total: $scope.budgetTotalsData.dates.workingDays.total
                    };

                    _.forEach($scope.budgetTotalsData.budgets, function(item) {
                        item.netTankGainsLosses = tankGainLoss.data;
                        months.map(function(month) {
                            item.netUnits[month.toLowerCase()] = (item.netTankGainsLosses.net / item.netUnits[month.toLowerCase()]) * 100;
                        });
                    });

                    $scope.widget.setDefaultActionModel({
                        month: $scope.month,
                        year: $scope.fiscalYear
                    });
                });
            });
        }

        function addYearsToMonths() {
            var year = $scope.fiscalYear;

            if(moment().month(months[0]).month() > 0) year--;

            $scope.months = _.map(months, function(month, index) {
                var monthNumber = moment().month(month).month();

                if(monthNumber === 0 && index !== 0) year++;

                return {
                    display: month,
                    value: monthNumber
                };
            });
        }

        $scope.yearChanged = function() {
            $scope.widget.noContentFlag = false;

            fiscalYearService
                .getFiscalYearMonthsOrdered()
                .then(function(months) {
                    var startMonth = moment().month(months[0]).month();
                    var currentMonth = moment().month();

                    $scope.months = _(months)
                        .map(function(m) {
                            return {
                                value: moment().month(m).month(),
                                display: m
                            };
                        })
                        .filter(function(m) {
                            return (fiscalYearOfToday > $scope.fiscalYear) || (m.value <= currentMonth) || (m.value >= startMonth);
                        })
                        .value();

                    var lastMonth = $scope.months[$scope.months.length - 1].value;

                    if( !$scope.month || ((fiscalYearOfToday === $scope.fiscalYear) && ($scope.month > lastMonth) && ($scope.month < startMonth)) )
                        $scope.month = currentMonth;

                    $scope.reloadWidget();
                });

        };

        function loadWidget() {
            $scope.widget.noContentFlag = false;

            divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
            var promise = $q
                .all([
                    fiscalYearService
                        .getFiscalYearMonthsOrdered()
                        .then(function(m) {
                            $scope.month = $scope.month ? $scope.month : moment().month();
                            $scope.fiscalYear = fiscalYearOfToday = dateCalculator.getFiscalYear(moment(), moment().month(m[0]).month());

                            months = m;
                            addYearsToMonths();
                            $scope.yearChanged();
                        }),
                    restrictedAccessService
                        .getAccess()
                        .then(function(access) {
                            $scope.restrictedAccess = access.restrictedAccess;
                        })
                ])
                .then(function() {
                    return getBudgets();
                })
                .then(function() {
                    return $http.get(('/api/subscribers/{0}/budgetsTankGainLoss/years').format(subscriberId))
                        .then(function(years) {
                            $scope.years = years.data;
                        });
                });

            return $scope.widget.promises.monitor(promise);
        }

        $scope.reloadWidget = function() { $scope.widget.promises.monitor(getBudgets()) };
        $scope.fiscalYearChanged = function() { $scope.reloadWidget() };
        $scope.monthChanged = function() { $scope.reloadWidget() };

        $scope.saveDashboardHierarchyNodeDropdownSettings = function(save) {
            if(save) {
                $scope.reloadWidget();
                $scope.widget.saveSettings();
            } else {
                $scope.reloadWidget();
            }
        };

        function checkIfDefaultDashboard() {
            return $http({
                url: 'api/dashboards',
                method: 'GET'
            })
                .then(function(dashboards) {
                    _.forEach(dashboards.data, function(dashboard) {
                        if(dashboard.isDefaultDashboard) {
                            $scope.isDefaultDashboard = true;
                        }
                    })
                });
        }
        checkIfDefaultDashboard();

        $scope.widget.setDefaultInitialization(loadWidget);
    }
]);