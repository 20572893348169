angular
    .module('angus.directives')
  .directive('tmsSubscriberSelect', [function(){
    'use strict';

    return {
      restrict: 'A',
      scope: {
        ngModel: '='
      },
      controller : ['$scope', 'fluentRest', '_', 'constantsService', function($scope, fluentRest, _, constantsService) {
        fluentRest
          .api()
          .subscribers()
          .get()
          .then(function (subscribers) {
            $scope.subscribers = _.filter(subscribers, function (subscriber) {
              return subscriber.isPaygo && subscriber.registrationStatusKey === constantsService.registrationStatus.live;
            });
          });
      }],
      template : '<select class="form-control" ng-options="subscriber.id as subscriber.name for subscriber in subscribers" ng-model="ngModel" ></select>',
    };
  }]);
