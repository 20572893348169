'use strict';

angular.module('angus.controllers').controller('subRegCategoryCodesCtrl', [
	'$scope', '$http', '_', 'Paging', 'modalService', 'actionViewTemplates',
	function($scope, $http, _, Paging, modalService, actionViewTemplates) {

		$scope.pageSize           = 25;
		$scope.paging             = new Paging($scope.pageSize);
		$scope.sortProp           = 'id';
		$scope.sortDir            = 'asc';

		$scope.stagedChangesCount = 0;

		var stagedProductSalesChanges = [];
		var existingProductSales = [];

    $scope.$watch(function() {
      return $scope.activeSourceSystem;
    }, function(newVal) {
      if (newVal) {
        getCategoryCodes();
      }
    }, true);

		function getCategoryCodes(pageNumber){
			var params = {
				sortDir            : $scope.sortDir,
				sortProp           : $scope.sortProp,
        stagedProductSaleCodes  : stagedProductSalesChanges.join(','),
			};

			$scope.codesLoaded = false;

			$scope.paging.getPage(('api/subscribers/{0}/categoryCodes/' + $scope.activeSourceSystem.id).format($scope.subscriberId),
				pageNumber,
				params)
					.then(function(categoryCodes) {
							$scope.categoryCodes = categoryCodes.items;
              existingProductSales = categoryCodes.extraInfo.existingProductSalesCodes || [];
							syncWithStagedChanges();
							$scope.codesLoaded  = true;
					});
		}

		$scope.getPage = function(pageNumber) {
      getCategoryCodes(pageNumber);
		};

		$scope.sort = function(propName) {
			if (propName != $scope.sortProp)
				$scope.sortDir = 'asc';
			else
				$scope.sortDir = $scope.sortDir == 'asc' ?
									'desc' : 'asc';

			$scope.sortProp = propName;
      getCategoryCodes($scope.paging.currentPage);
		};

		function syncWithStagedChanges(){
			_.forEach($scope.categoryCodes, function(code){
					code.isProductSale  = code.isProductSaleLocked || _.includes(stagedProductSalesChanges, code.id);
				});
		}


		function updateArray(array, value){
			if(!_.includes(array, value))
				array.push(value);
			else
				array.splice(array.indexOf(value), 1);
		}

		function updateStagedValues(){
			$scope.stagedChangesCount = stagedProductSalesChanges.length;
		}

		$scope.updateStagedChanges = function(id){
			updateArray(stagedProductSalesChanges, id);
			updateStagedValues();
		};

		$scope.updateCategoryCodes = function() {

			var confirmScope = {
					title: 'Confirm Save?',
					message: 'Category codes cannot be deselected after they have been saved! Please confirm you have made the necessary changes. ' +
						'If selections need to be changed you will need to contact your system administrator.'
				};

			return modalService
				.openTopBarModal(actionViewTemplates.confirmDialog, confirmScope)
				.then(function(response) {
					if(response){

						$scope.updating = true;

            stagedProductSalesChanges  = _.union(stagedProductSalesChanges, existingProductSales);

						$http.post(('api/subscribers/{0}/categoryCodes/' + $scope.activeSourceSystem.id).format($scope.subscriberId),
							{ categoryCodes: stagedProductSalesChanges})
							.then(function() {
									getCategoryCodes();

									$scope.updating           = false;
									$scope.stagedChangesCount = 0;

                  stagedProductSalesChanges       = [];
								});
					}
			});
		};


		var headingCenter = 'text-center clickable';

		$scope.headings = [{
					name: 'Code',
					isHidable: false,
					propClass: headingCenter,
					propName: 'id',
					isSortable: true
				}, {
					name: 'Description',
					isHidable: false,
					propClass: headingCenter,
					propName: 'desc',
					isSortable: true
				}, {
					name: 'Product Sale',
					isHidable: false,
					propClass: headingCenter,
					propName: 'productSale',
					isSortable: true
				}
			];

    getCategoryCodes();
	}
]);
