angular.module('angus.services').factory('acquisitionSourceService', [
	'$rootScope', '$http', '$q', '_',
	function($rootScope, $http, $q, _) {
		'use strict';

		var subscriberAcquisitionSource = [];
		var acquisitionSource = [];

		function getAcquisitionSource(subscriberId) {
			if (subscriberId) {
				if (subscriberAcquisitionSource[subscriberId] && subscriberAcquisitionSource[subscriberId].length > 0)
					return subscriberAcquisitionSource[subscriberId];
				else {
					return queryAcquisitionSource(subscriberId)
						.then(function(acquisitionSourceList) {
							subscriberAcquisitionSource[subscriberId] = acquisitionSourceList.data;
							return subscriberAcquisitionSource[subscriberId];
						});
				}
			} else {
				if (acquisitionSource && acquisitionSource.length > 0)
					return acquisitionSource;
				else {
					return queryAcquisitionSource()
						.then(function(acquisitionSourceList) {
							acquisitionSource = acquisitionSourceList.data;
							return acquisitionSource;
						});
				}
			}
		}

		function queryAcquisitionSource(subscriberId) {
			return $http.get(('api/subscribers/{0}/acquisitionSource').format(subscriberId || $rootScope.user.subscriberId));
		}

		return {
			getAcquisitionSource: function(subscriberId) {
				return $q.when(getAcquisitionSource(subscriberId))
					.then(function(acquisitionSource) {
						return _.cloneDeep(acquisitionSource);
					});
			},
			formatDeliveryTypesList: function(deliveryTypeIds) {
				var result = '';
				var acquisitionSourceList = acquisitionSource;

				if (!(acquisitionSourceIds instanceof Array))
					acquisitionSourceIds = [acquisitionSourceIds.toString()];

				if (acquisitionSourceIds.length > 0) {
					_.forEach(acquisitionSourceList, function(acquisitionSource) {
						if (_.includes(acquisitionSourceIds, acquisitionSource.id.toString()))
							result += acquisitionSource.name + ', ';
					});
				}

				return result.substring(0, result.length - 2);
			}
		};
	}
]);
