'use strict';
angular.module('angus.controllers').controller('degreeDayMetricsCtrl', [
    '$rootScope', '$scope', '_', 'constantsService', 'degreeDayService', 'hierarchyService',
    function ($rootScope, $scope, _, constantsService, degreeDayService, hierarchyService) {

        $scope.periodIndicators = constantsService.yearsPeriodIndicator.enums;

        $scope.periodIndicator = _.find(constantsService.yearsPeriodIndicator, function(indicator) {
            return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
        });

        const includeExcludeOnlyOptions = constantsService.includeExcludeOnlyOptions;

        /* Save the settings anytime the bar graph selected metric changes */
        $scope.$watch(function() {
            return $scope.widget.instance.settings.lineGraphSelectedMetricId.value;
        }, function(){
            if ($scope.data) {
                $scope.widget.saveSettings();			
            }
        });

        /* Save the settings anytime the line graph selected metrics changes */
        $scope.$watchCollection(function() {
            return $scope.widget.instance.settings.barGraphSelectedMetricIds.value;
        }, function(){
            if ($scope.data) {
                $scope.widget.saveSettings();	
            }		
        });


        function loadWidget() {
            $scope.widget.noContentFlag = false;

            $scope.metrics = degreeDayService.getMetrics();

            $scope.metrics.forEach(metric => {
                var index = $scope.widget.instance.settings.availableMetricIds.value.indexOf(metric.id);

                if (index >=0) {
                    metric.isAvailable = true;
                } else {
                    metric.isAvailable = false;
                }
            });
        

            /* Evaluate settings to determine if the Hour based Metrics should be disabled */
            toggleHourBasedMetrics();

            var promise = degreeDayService.getDegreeDaySources()
                .then(function(degreeDayLogs) {
                    $scope.degreeDayLogs = degreeDayLogs;

                    if (!$scope.dates) {
                        $scope.dates = {};

                        var dates = dateCalculator.calculatePeriod(null, null, $scope.widget.instance.settings.periodIndicatorId.value, false);
                                                
                        $scope.dates.startDate = dates.startDate;
                        $scope.dates.endDate = dates.endDate;
                    }
                    
                    return  queryDeliveryMetricsData();
                })
                .then(function(response){
                    if (!response || response.length === 0){
                        $scope.widget.noContentFlag = true;
                        return null;
                    }

                    $scope.data = response;

                    /* Add Per Degree Day Metrics if enabled */
                    _.forEach($scope.data, function(row){
                        var heatingDegreeDays = row.heatingDegreeDays;

                        if (heatingDegreeDays < $scope.widget.instance.settings.perDegreeDayThreshold.value) {
                            /* Zero out "Per Degree Day" metrics if the number of Degree Days is below the Threshold setting */
                            row.deliveriesPerDegreeDay = 0;
                            row.gallonsPerDegreeDay = 0;
                        } 
                        else {
                            row.deliveriesPerDegreeDay = heatingDegreeDays ? row.deliveryCount / heatingDegreeDays : 0;
                            row.gallonsPerDegreeDay = heatingDegreeDays ? row.units / heatingDegreeDays : 0;
                        }
                    });
                });

            return $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(loadWidget);

        $scope.$on('widgetSettingsChanged', function() {
            $scope.periodIndicator = _.find(constantsService.yearsPeriodIndicator, function(indicator) {
                return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
            });

            delete $scope.dates;

            loadWidget();
        });


        function toggleHourBasedMetrics() {

            var areHoursBasedMetricsDisabled = false;

            const settings = $scope.widget.instance.settings;

            /* Any Delivery Type or Any Tank Size has been selected */
            if (settings.deliveryTypeIds.value.length > 0 || settings.containerTypeIds.value.length > 0) {
                areHoursBasedMetricsDisabled = true;
            } 
            /* Include Monitored, Adept Transactions, or Optimized Transactions are Exclude or Only */
            else if (settings.includeMonitoredTanks.value !== 'include' || settings.includeAdeptTransactions.value !== 'include' || settings.includeGroupOptimizedTransactions.value !== 'include') {
                areHoursBasedMetricsDisabled = true;
            }


            /* Set Disabled State */
            $scope.metrics.forEach(metric => {
                if (!areHoursBasedMetricsDisabled) {
                    metric.isDisabled = false;
                }
                else if (metric.isHoursMetric && areHoursBasedMetricsDisabled) {
                    metric.isDisabled = true;
                }
                else {
                    metric.isDisabled = false;
                }
            });
        }


        function queryDeliveryMetricsData() {
            const subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

            const divisionIds = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId)
            
            const divisionIdsParam = _.isArray(divisionIds) ? divisionIds : [divisionIds];
            const productCategoryIds = _.isArray($scope.widget.instance.settings.productCategoryIds.value) ? $scope.widget.instance.settings.productCategoryIds.value : [$scope.widget.instance.settings.productCategoryIds.value];
            const deliveryTypeIds = _.isArray($scope.widget.instance.settings.deliveryTypeIds.value) ? $scope.widget.instance.settings.deliveryTypeIds.value : [$scope.widget.instance.settings.deliveryTypeIds.value];
            const tradeClassIds = _.isArray($scope.widget.instance.settings.tradeClassIds.value) ? $scope.widget.instance.settings.tradeClassIds.value : [$scope.widget.instance.settings.tradeClassIds.value];
            const containerTypeIds = _.isArray($scope.widget.instance.settings.containerTypeIds.value) ? $scope.widget.instance.settings.containerTypeIds.value : [$scope.widget.instance.settings.containerTypeIds.value];
            const includeMonitoredTanks =  includeExcludeOnlyOptions[$scope.widget.instance.settings.includeMonitoredTanks.value] || includeExcludeOnlyOptions.include;
            const includeAdeptTransactions = includeExcludeOnlyOptions[$scope.widget.instance.settings.includeAdeptTransactions.value] || includeExcludeOnlyOptions.include;
            const includeGroupOptimizedTransactions = includeExcludeOnlyOptions[$scope.widget.instance.settings.includeGroupOptimizedTransactions.value] || includeExcludeOnlyOptions.include;


            /* At some point we should re-evaluate how Degree Day Logs are stored (DegreeDayHeaderId vs Postal Code) so that we can store a single "Id" instead of the complete object */
            const degreeDayLog = JSON.parse($scope.widget.instance.settings.degreeDayHeaderId.value);

            const degreeDaySourceTypeId = degreeDayLog ? degreeDayLog.degreeDaySourceTypeKey : null;
            const degreeDayHeaderId = degreeDayLog ? degreeDayLog.degreeDayHeaderKey : null;
            
            return degreeDayService.getDeliveryMetrics(subscriberId,  $scope.dates.startDate, $scope.dates.endDate, degreeDaySourceTypeId, degreeDayHeaderId, divisionIdsParam,  productCategoryIds, deliveryTypeIds, tradeClassIds, containerTypeIds, includeMonitoredTanks, includeAdeptTransactions, includeGroupOptimizedTransactions);
        }
    }
]);
