'use strict';
angular.module( 'angus.directives' ).directive( 'arSummaryBsg', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		return {
			scope: {
				model: '=actionModel',
				settings: '=arSummaryBsgWidgetSettings',
				widgetCode : '='
			},
			templateUrl: actionViewTemplates.arSummaryBsgReportContent
		};
	}
] );