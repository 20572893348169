angular.module( 'angus' )
	.factory( 'ModalInstance', function() {
		function ModalInstance(html){
			var that = this;

			this.html  = html;
			this.close = function(){
				that.html.modalContent.fadeOut( 150, function() {
													that.html.modalContainer.fadeOut( 150, function() {
														that.html.modalContainer.remove();
													} );
												} );
			};
		}

		ModalInstance.prototype = {
			setHtml : function(html){
				this.html = html;				
			}
		};

		return ModalInstance;
	});		