angular.module('angus.services')
    .factory('WidgetInstance', [
        function() {

            function Widgetinstance(id, code, viewCode, size, settings, location, dashboardId) {
                this.id = id;
                this.widgetCode = code;
                this.widgetViewCode = viewCode;
                this.size = size;
                this.location = location;
                this.dashboardId = dashboardId;
            };

            return Widgetinstance;
        }
    ]);