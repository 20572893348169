angular.module('angus.controllers').controller('paygoExceptionsSettingsCtrl', [
    '$scope', '$q', 'fluentRest', 
    function($scope, $q, fluentRest){                

        function getDealers( ) {            

            return fluentRest
                        .api()
                        .subscribers()
                        .dealers()
                            .get( { sortBy: 'name' } )
                            .then(function(dealerList){
                                $scope.dealerList = dealerList;
                            });
            
        }


        function getExceptionsTypes() {            

            return fluentRest
                    .api()
                    .subscribers()
                    .dealers()
                    .exceptions()
                    .types()
                        .get()
                        .then(function(exceptionTypes){
                            return $scope.exceptionTypes = exceptionTypes;                            
                        });
            
        }

        $q.all([
            getDealers(),
            getExceptionsTypes()
        ]);
    }
]);