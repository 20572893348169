angular.module('angus.directives').directive('appValueAvailability', [
    '$http', '$rootScope',
    function($http, $rootScope) {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, element, attrs, ctrl) {

                var validationKey = scope.$eval(attrs.validationKey);
                var url = scope.$eval(attrs.appValueAvailability);

                ctrl.$parsers.push(function(viewValue) {
                    ctrl.$setValidity(validationKey, true);
                    if (viewValue) {
                        $http.get((url).format($rootScope.user.subscriberId, viewValue))
                            .success(function(response) {
                                ctrl.$setValidity(validationKey, response.isAvailable);
                            })
                            .error(function() {});
                        return viewValue;
                    }
                });
            }
        };
    }
]);