angular
    .module('angus.directives')
    .directive('subscriberSelect', [ function(){
    	'use strict';

        return {
            restrict: 'A',
            scope: {
                ngModel: '='
            },
            controller : ['$scope', 'fluentRest', function($scope, fluentRest){
                fluentRest
                    .api()
                    .subscribers()
                    .get()
                    .then(function(subscribers){
                        $scope.subscribers = subscribers;
                    });
            }],
            template : '<select class="form-control" ng-options="subscriber.id as subscriber.name for subscriber in subscribers" ng-model="ngModel" ></select>',
        };
    }])
;
