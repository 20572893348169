'use strict';

angular.module('angus.controllers').controller('importFileCtrl' , [
    '$rootScope', '$scope', '_', 'Paging', 'importFileService', 'modalService', 'actionViewTemplates',
    function($rootScope, $scope, _, Paging, importFileService, modalService, actionViewTemplates) {
        $scope.paging = new Paging($scope.pageSize || 50);
        $scope.sortBy = 'lastUpdated';
        $scope.ascending = false;
        $scope.importActive = false;
        $scope.importsLoaded = true;
        $scope.importedFiles = [];
        $scope.hideImport = false;
        $scope.headings = $scope.model.gridHeaders; 
        $scope.templateURL = `${window.env.storageURL}/${window.env.storageBucket}` +
            `/${$scope.model.product}/${$scope.model.operation}/${$scope.model.subscriber}/${$scope.model.templateURL}`;
 
        $scope.init = function (){
            $scope.importedFiles = [];
            $scope.importsLoaded = false;
            getImportRecords().then(function(records) {
                $scope.importsLoaded = true;
            }).catch(function(error) {
                $scope.importsLoaded = true;
            });
        }
 
        $scope.query = function query(pageNumber) { 
            return getImportRecords(pageNumber);
        } 

        $scope.sort = function(sortBy) {
            if($scope.sortBy == sortBy) $scope.ascending = !$scope.ascending;
            else {
                $scope.sortBy = sortBy;
                $scope.ascending = true;
            }
            $scope.query();
        };

        $scope.setFile = function(element){
            $scope.importActive = true;
            var file = element.files[0];
            if(!file){
                document.getElementById('file').value = null;
                return;
            }
            var data = {
                bucketName: $scope.model.operation,
                fileName: file.name,
                fileType: file.type,
                subscriber: $scope.model.subscriber,
                dataTypeKey: $scope.model.dataTypeKey
            }
            getSignedURL(data)
            .then(function(response){
                uploadFile(file,response.url[0]);
            }).catch(function(){
                $scope.importActive = false;
            });

        }
        function getSignedURL(data){ 
            return importFileService.getSignedURL(data)
                .then(function(response) {
                    return response;
                }).catch(function(error) {
                    document.getElementById('file').value = null;
                });
        }
        
        function uploadFile(file, url) {
            importFileService.uploadFile(file, url)
                .then(function(url) {
                    upsertImportRecord(url, file.name, null);
                    document.getElementById('file').value = null;
                    callGetImportRecords(getImportRecords);
                }).catch(function(error) {
                    document.getElementById('file').value = null;
                    callGetImportRecords(getImportRecords);
                });
        };

        function callGetImportRecords(getImportRecords) {
            var count = 0;
            var interval = 10000; // 10 seconds in milliseconds

            var timer = setInterval(function() {
                getImportRecords();
                count++;
                if (count === 5) {
                    clearInterval(timer);
                }
            }, interval);
        }

        function upsertImportRecord (fileImportURL, originalFileName, message){
            
            const deferImport = true;
            const fileImportKey = null;
            const subscriberID = $scope.model.subscriberId;
            const importSubscriberID = $scope.model.subscriber;
            const fileImportDataTypeKey = $scope.model.dataTypeKey;
            const fileImportStatusKey = 1;
            const fileImportUser = $rootScope.user.username;
            const data = {}
            
            return importFileService.upsertImportRecord(deferImport, fileImportKey, fileImportURL, subscriberID, importSubscriberID, originalFileName, fileImportDataTypeKey, fileImportStatusKey, fileImportUser, message, data)
                .then(function(response) {
                    getImportRecords();
                    return response.data;
                }).then(function(){
                    $scope.importActive = false;
                }).catch(function(error) {
                    getImportRecords();
                    $scope.importActive = false;
                    console.error( error);
                });
        }
        
        $scope.showMessages = function(importedFile) {
            var scope = {
                importedFile
            };  

            return modalService
			.openTopBarModal(actionViewTemplates.monitorImportMessages, scope)
			.then(function(obj){ 
				if(!obj) return; 
			});
        }

        function getImportRecords(pageNumber) {        
            const pagingParams = {
                sortBy: $scope.sortBy,
                ascending: $scope.ascending,
                subscriberID: $scope.model.subscriberId,
                type:$scope.model.dataTypeKey,
            };
            $scope.hideImport = pagingParams.type === 3;  
            return importFileService.getImportRecords($scope.paging, pageNumber, pagingParams)
                .then(function(records) {
                    $scope.importedFiles =  _.map(records.items, function (item) {
                        item.lastUpdated = moment.utc(item.lastUpdated).format('lll'); 
                        if (item.messages) {   
                            item.messages = importFileService.formatMonitorInventoryMessage(item.messages); 
                        } 
                        return item;
                    });
                    return records;
                }).catch(function(error) {
                    $scope.importedFiles = [];
                });
        }
       
        $scope.init();
    }
]);
