angular.module('angus.controllers').controller('tankUtilContainerCtrl', [
    '$scope', '$q', '$rootScope', '$http',  '_', 'constantsService','drillService',  'moment', 'gridService2', 'nodeType', 'gridState', 'modalService', 'actionViewTemplates', 'restrictedAccessService',
    function($scope, $q, $rootScope, $http,  _, constantsService, drillService, moment, gridService2, nodeType, gridState, modalService, actionViewTemplates, restrictedAccessService) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.dates = {
            startDate        : $scope.model.periodStartDate  || moment(),
            endDate          : $scope.model.lastDeliveryDate || moment(),
            lastDeliveryDate : $scope.model.lastDeliveryDate,
        };

        $scope.filter =  {
            productIds      : $scope.model.productIds,
            hierarchyNodeId : $scope.model.hierarchyNodeId,
            minTankSize: $scope.model.minTankSize,
            maxTankSize: $scope.model.maxTankSize,
            deliveryTypeIds: $scope.model.deliveryTypeIds,
            inactiveTanks: $scope.model.includeInactive,
            customerOwned: $scope.model.includeCustomerOwned,
            customDesiredDelivery: false,
            desiredDeliveryAmount: 80,
            minActivityYrs: $scope.model.minimumYearsHistory || 1,
        };


        $scope.getTankUtilization = function(){
            var params = {
                divisionId : $scope.filter.hierarchyNodeId,
                productIds: $scope.filter.productIds,
                startDate: $scope.dates.startDate.format(),
                endDate: $scope.dates.endDate.format(),
                minTankSize: $scope.filter.minTankSize,
                maxTankSize: $scope.filter.maxTankSize,
                inactiveTanks: $scope.filter.inactiveTanks ? 1 : 0,
                deliveryTypeIds: $scope.filter.deliveryTypeIds,
                customerOwned: $scope.filter.customerOwned ? 1 : 0,
                minActivityYrs: $scope.filter.minActivityYrs,
                customDesiredDelivery: $scope.filter.customDesiredDelivery ? $scope.filter.desiredDeliveryAmount : null,
            };

            var rowPromise = $http({
                url: ('api/subscribers/{0}/batches/deliveries/containers').format($rootScope.user.subscriberId),
                method: 'POST',
                data: params
            }).then(function(result){
                return result.data;
            });

            var promise = $scope.grid.setRows(rowPromise);

            //$scope.promiseMonitor = new PromiseMonitor(promise);
            return promise;
        }


        function getColClicks(){
            return {
                accountNumber 	: {
                    onClick : function(row){ drillService.openAccountInfo(row, drillService.context.delivery); }
                },
                tankNumber 		: {
                    onClick : function(row){ drillService.openTankInfo(row, drillService.context.delivery); }
                }
            };
        }

        function getColDefs(){
            var colDefs   =  [];
            colDefs.push(gridService2.colDef.createNumber('turns','Turns','turns', { sort:'desc'}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createText('accountNumber','Account Number','accountNumber', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('customerName','Customer Name','customerName'));
            colDefs.push(gridService2.colDef.createText('tankNumber','Tank Number','tankNumber', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createNumber('tankSize','Tank Size','tankSize'));
            colDefs.push(gridService2.colDef.createNumber('avgAnnualUnits','Average Annual Units','avgAnnualUnits', {}, {decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createNumber('idealDrop','Ideal Drop','idealDrop',{},  { decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createNumber('years','Years','years',{},  { decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createDate('earliestDeliveryDate','Earliest Delivery Date','earliestDeliveryDate'));
            colDefs.push(gridService2.colDef.createDate('recentDeliveryDate','Most Recent Delivery Date','recentDeliveryDate'));
            colDefs.push(gridService2.colDef.createBoolean('willCallFlag','Will Call','willCallFlag'));
            colDefs.push(gridService2.colDef.createBoolean('customerOwnedFlag','Customer Owned','customerOwnedFlag'));

            return colDefs;
        }


        $scope.desiredDeliveryAmountOptions = [{
            value: false,
            name: 'Back Office System Value'
        }, {
            value: true,
            name: 'Custom Value'
        }];


        var gridParams = {
            gridOptions: {
                groupAggFunction: function(rows) {
                    var calc = _.reduce(rows, function(result, row) {
                        var data = row.data;

                        result.avgAnnualUnits += data.avgAnnualUnits;
                        result.count += 1;

                        return result;
                    }, {
                        avgAnnualUnits: 0,
                        count: 0
                    });

                    calc.avgAnnualUnits = calc.avgAnnualUnits / calc.count;

                    return calc;
                }
            },
            gridState     : gridState(subscriberId, $scope.widgetCode),
            defs          : getColDefs(),
            clicks        : getColClicks(),
            exportOptions : { fileName: 'Propane Tank Utilization' }
        };
        gridService2
            .createGrid(gridParams)
            .then(function(grid){
                $scope.grid = grid;
                $scope.getTankUtilization()
            });

    }
]);