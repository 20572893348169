angular.module('angus.directives')
  .directive('reportingProductsSelect', [function () {
    'use strict';

    return {
      restrict: 'A',
      scope: {
        ngModel: '=',
        subscriberId: '='
      },
      controller: ['$scope', 'productsService', function ($scope, productsService) {
        productsService
          .getProducts($scope.subscriberId)
          .then(function (products) {
            $scope.products = products;
          });
      }],
      template: '<div multi-select-ok-wrapped="products" ng-model="ngModel" display-prop="\'name\'" selection-prop="\'id\'"></div>',
    };
  }]);


// angular.module('angus.directives')
//     .directive('reportingProductsSelect', ['$compile', function($compile){
//         'use strict';

//         return {
//             restrict: 'A',
//             scope: {
//                 ngModel: '='                
//             },
//             controller : ['$scope', 'productsService', function($scope, productsService){  
//                 $scope.getProducts = function(){            
//                    return productsService
//                        .getProducts()            
//                       .then(function(products) {
//                           $scope.products = products;
//                       });               
//                 }
//             }],
//             link : function(scope, element){
//                 scope
//                     .getProducts()
//                     .then(function(){
//                         var html = '<div multi-select-ok-wrapped="products" ng-model="ngModel" display-prop="\'name\'" selection-prop="\'id\'"></div>';
//                         var e = $compile(html)(scope);
//                         element.replaceWith(e);
//                     })
//             }                   
//         };
//     }]);
