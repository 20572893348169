'use strict';

angular.module('angus.controllers').controller('datePickerCtrl', ['$scope', 'constantsService', 'moment', function($scope, constantsService, moment) {

	$scope.dateOptions = {
		formatYear: 'yy',
		startingDay: 0
	};

	var maxStartDateSet = $scope.minYears !== undefined;
	$scope.startDateMaximum = $scope.endDate;

	$scope.updateRange = function() {
		$scope.endDateMin = $scope.startDate || null;

		if(maxStartDateSet) {
			$scope.startDateMaximum = moment.utc($scope.endDate).subtract($scope.minYears, 'years').toDate();

			if($scope.startDate > $scope.startDateMaximum) {
				$scope.startDate = $scope.startDateMaximum;
			}
		}
	};

	$scope.convetToMomentDatesArray = function() {
		return [moment.utc($scope.startDate), moment.utc($scope.endDate)];
	};

	$scope.updateRange();
}]).controller('paygoDatePickerCtrl', ['$scope', 'constantsService', 'moment', function($scope, constantsService, moment) {

	$scope.dateOptions = {
		formatYear: 'yy',
		startingDay: 0
	};

	$scope.startDateMaximum = $scope.endDate;

	$scope.updateRange = function() {

		$scope.endDateMin = $scope.startDate || null;

		if($scope.startDate > $scope.startDateMaximum)
			$scope.startDate = $scope.startDateMaximum;

	};

	$scope.convetToMomentDatesArray = function() {
		return [moment($scope.startDate), moment($scope.endDate)];
	};

	$scope.updateRange();
}]);