angular.module('angus.controllers').controller('performanceCtrl', [
    '$scope', '$http', '_', '$filter', '$rootScope', 'moment', 'modalService', 
    function ($scope, $http, _, $filter, $rootScope, moment, modalService) {
        'use strict';

        $scope.data = [];
        $scope.csv = {};
        $scope.isLoading = false;

        function fixedDecimal(value, decimal,eomRunRate) {
            value.metric.MetricGoal = value.metric.MetricGoal != null && value.metric.MetricGoal ?  Number(value.metric.MetricGoal).toFixed(decimal) : value.metric.MetricGoal;
            value.metric.MetricValue = value.metric.MetricValue != null && value.metric.MetricValue ?  Number(value.metric.MetricValue).toFixed(decimal) : value.metric.MetricValue;
            value.metric.MetricValuePriorYearEOM = value.metric.MetricValuePriorYearEOM != null && value.metric.MetricValuePriorYearEOM ?  Number(value.metric.MetricValuePriorYearEOM).toFixed(decimal) : value.metric.MetricValuePriorYearEOM;
            value.metric.MetricValueFYTD = value.metric.MetricValueFYTD != null && value.metric.MetricValueFYTD ?  Number(value.metric.MetricValueFYTD).toFixed(decimal) : value.metric.MetricValueFYTD;
            value.metric.EOMRunRate = value.metric.EOMRunRate != null && value.metric.EOMRunRate ? Number(value.metric.EOMRunRate).toFixed(eomRunRate || 1) : value.metric.EOMRunRate;
            return value;
        }

        $scope.close = function(e) {
            console.log('close:', e); 		 
        }

        function init() {
            var year = $scope.widget.instance.settings.year || new Date().getFullYear();
            var month = $scope.widget.instance.settings.month || new Date().getMonth() + 1;
            var displayEmpty = $scope.widget.instance.settings.displayEmpty || false;
            var workingDaysOffset =  $scope.widget.instance.settings.workingDaySubtraction || 2;
            // console.log("YEAR", year, "MONTH", month);
            return $scope.widget.promises.monitor(
                $http({
                    method: 'POST',
                    url: ('/api/subscribers/{0}/snapShot/performance').format($rootScope.user.subscriberId),
                    data: {
                        year: year,
                        month: month,
                        workingDaysOffset: workingDaysOffset
                    }
                })
                    .success(function (data) {
                        var categories = [];
                        var buckets = {};

                        for (let index = 0; index < data.length; index++) {
                            var record = data[index];
                            if (!displayEmpty && record.MetricGoal == 0) {
                                continue;
                            }

                            var group = record["PerformanceMetricCategoryName"];
                            if (categories.indexOf(group) === -1) {
                                categories.push(group);
                                buckets[group] = [];
                            }

                            buckets[group].push(record);
                        }
                        var report = [];
                        for (let index = 0; index < categories.length; index++) {
                            var category = categories[index];
                            var height = buckets[category].length;
                            for (let mIndex = 0; mIndex < height; mIndex++) {
                                report.push({
                                    alt: index % 2 === 0,
                                    index: mIndex,
                                    height: height,
                                    title: category,
                                    metric: buckets[category][mIndex]
                                });
                            }
                        }
                        $scope.year = $scope.widget.instance.settings.year || new Date().getFullYear();
                        $scope.month = $scope.widget.instance.settings.month || new Date().getMonth();
                        $scope.monthName = moment().month($scope.month - 1).format("MMMM");

                        $scope.data = report.map(function (value) {
                            if (value.title === "Customer Service & Oil Sales") {
                                    value = fixedDecimal(value, 0)

                            }
                            if (value.title === "Delivery") {
                                if (value.metric.PerformanceMetricName === "Gallons Delivered") {
                                    value = fixedDecimal(value, 1)
                                }
                                if (value.metric.PerformanceMetricName === "Delivery Payroll Hours") {
                                    value = fixedDecimal(value, 2)
                                }
                                if (value.metric.PerformanceMetricName === "Gallons per Payroll Hour") {
                                    value = fixedDecimal(value, 0,1)
                                }
                                if (value.metric.PerformanceMetricName === "Average 275 Drop") {
                                    value = fixedDecimal(value, 0)
                                }
                                if (value.metric.PerformanceMetricName === "Credit") {
                                    value = fixedDecimal(value, 2)
                                }
                                if (value.metric.PerformanceMetricName === "Deliveries") {
                                    value = fixedDecimal(value, 0)
                                }

                            }
                            if (value.title === "Service") {
                                if (value.metric.PerformanceMetricName === "Calls") {
                                    value = fixedDecimal(value, 0)
                                }

                                if (value.metric.PerformanceMetricName === "Service Payroll Hours") {
                                    value = fixedDecimal(value, 2)
                                }
                                if (value.metric.PerformanceMetricName === "Calls per Payroll Hour") {
                                    value = fixedDecimal(value, 4,1)
                                }
                                if (value.metric.PerformanceMetricName === "Revenue") {
                                    value = fixedDecimal(value, 0)
                                }
                                if (value.metric.PerformanceMetricName === "Credit") {
                                    value = fixedDecimal(value, 2)
                                }
                                if (value.metric.PerformanceMetricName === "Unscheduled ATUs") {
                                    value = fixedDecimal(value, 2)
                                }
                            }
                            if (value.title === "Plumbing") {
                                    value = fixedDecimal(value, 2)

                            }
                            if (value.title === "Installation") {

                                if (value.metric.PerformanceMetricName === "Payroll Hours") {
                                    value = fixedDecimal(value, 2)
                                }
                                if (value.metric.PerformanceMetricName === "On-Site Hours") {
                                    value = fixedDecimal(value, 2)
                                }else{
                                    value = fixedDecimal(value, 2)
                                }
                            }
                            if (value.title === "Chimney Cleaning") {
                                    value = fixedDecimal(value, 2)

                            }
                            if (value.title === "Duct & Carpet Cleaning") {
                                    value = fixedDecimal(value, 2)

                            }

                            return value;
                        });

                        // console.log("DATA", $scope.data);
                    })
            );
        }

        $scope.widget.setDefaultInitialization(init);

        $scope.$on('widgetSettingsChanged', function () {
            init();

        });

    }
]);
