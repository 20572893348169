'use strict';

angular.module('angus.directives').directive('angusGraph',
    ['moment', '_',
        function(moment, _) {
            return {
                transclude:true,
                require: 'ngModel',
                template:'<div class="container"></div>',
                link: function(scope, ele, att, controller, transclude) {
                    function createAxes(graph, xBuffer, yBuffer, width, height, xmls) {
                        var path = document.createElementNS(xmls, 'path');
                        if(scope.graphData.maxNum) {
                            scope.graphData.maxNum.forEach(function(item, i, arry) {
                                var y = (height - (item * yBuffer + 100));
                                var longTicks = document.createElementNS(xmls, 'path');
                                var text = document.createElementNS(xmls, 'text');

                                text.setAttribute('x', xBuffer - 75);
                                text.setAttribute('y', y);

                                text.setAttribute('font-family', "Verdana");
                                text.setAttribute('fill', "black");

                                var textContent = document.createTextNode(item.toFixed(4));
                                text.append(textContent);
                                longTicks.setAttribute('d', 'M '+ (xBuffer) + ' ' + y + ' H ' + (width + xBuffer));
                                longTicks.setAttribute('fill', 'none');
                                longTicks.setAttribute('stroke-width', 1);
                                longTicks.setAttribute('stroke', '#605c5c');
                                graph.appendChild(longTicks);
                                graph.appendChild(text);
                            })
                        }

                        path.setAttribute('d', 'M ' + xBuffer + ' ' + (height - yBuffer ) + ' H ' + (width + xBuffer) );
                        path.setAttribute('fill', 'none');
                        path.setAttribute('stroke-width', 2);
                        path.setAttribute('stroke', '#605c5c');
                        graph.appendChild(path);
                    }

                    function getHowManyDays(startDate, endDate) {
                        var dateArry = [];
                        dateArry.push(moment(startDate).format('MMM DD, YYYY'));

                        var checkDate = moment(startDate);
                        while(checkDate.isBefore(endDate) ) {
                            checkDate = checkDate.add(1, 'months');
                            dateArry.push(moment(checkDate).format('MMM DD, YYYY'))
                        }
                        return dateArry
                    }

                    function createKey(colors, graph, xmls, width) {
                        var content = ['Unit Price', 'Unit Cost', 'Index Price'];
                        var keyColors = colors;
                        var block = document.createElementNS(xmls, 'rect');

                        block.setAttribute('x', width - 150 );
                        block.setAttribute('y', 0 );
                        block.setAttribute('width', 120);
                        block.setAttribute('height', 75);
                        block.setAttribute('fill', 'white');
                        block.setAttribute('stroke', '#d4d4d6');
                        block.setAttribute('stroke-width', 1);

                        graph.appendChild(block);

                        keyColors.reverse().forEach(function(color, i) {
                            var keyItem = document.createElementNS(xmls, 'rect');
                            keyItem.setAttribute('x', width - 55 );
                            keyItem.setAttribute('y', (25 * i) + 5);
                            keyItem.setAttribute('width', 20);
                            keyItem.setAttribute('height', 20);
                            keyItem.setAttribute('fill', colors[i].color);
                            keyItem.setAttribute('stroke', '#d4d4d6');
                            keyItem.setAttribute('stroke-width', 1);
                            var text = document.createElementNS(xmls, 'text');

                            text.setAttribute('x', width - 150);
                            text.setAttribute('y', (25 * i) + 15);

                            text.setAttribute('font-family', "Verdana");
                            text.setAttribute('fill', "black");
                            var textContent = document.createTextNode(content[i]);
                            text.append(textContent);

                            graph.appendChild(text);
                            graph.appendChild(keyItem);
                        })
                    }

                    function createLineGraph(arry, xmls, graph, height, xBuffer,yBuffer, width, color) {
                        var path = 'M' + xBuffer + ' ' + (height - (arry[0].data * yBuffer + 100));
                        var line = document.createElementNS(xmls, 'path');
                        line.setAttribute('fill', 'none');
                        line.setAttribute('stroke-width', 3);
                        line.setAttribute('stroke', color);
                        graph.appendChild(line);

                        arry.forEach(function(item, i, arry) {
                            var x = ((width - xBuffer) / (arry.length) * i ) + xBuffer;
                            x += x / arry.length;
                            var rect = null;
                            var textDate = null;
                            var textAmount = null;
                            var marginText = null;
                            var dot = document.createElementNS(xmls, 'circle');
                            var contentDate = document.createTextNode(item.dateData);

                            var contentAmount = document.createTextNode(item.type +': $ ' + item.data );
                            var margin = document.createTextNode('Margin: $ ' + item.margin );

                            if(!scope.graphData.isMonthOnly) {
                                var dates = getHowManyDays(scope.widget.instance.settings.startDate, scope.widget.instance.settings.endDate);

                                dates.forEach(function(date) {
                                    if(date === item.dateData) {
                                        var tick = document.createElementNS(xmls,'path');
                                        var group = document.createElementNS(xmls,'g');
                                        var tPath = 'M ' + x + '  ' + (height - 80 )+ ' V ' + (height - 90);

                                        tick.setAttribute('d', tPath);
                                        tick.setAttribute('fill', 'none');
                                        tick.setAttribute('stroke-width', 1);
                                        tick.setAttribute('stroke', 'black');

                                        var datesEle = document.createElementNS(xmls, 'text');
                                        var datesDisplay = document.createTextNode(date);

                                        datesEle.setAttribute('x', x - 50);
                                        datesEle.append(datesDisplay);
                                        datesEle.setAttribute('y', height - 70);
                                        datesEle.setAttribute('font-family', "Verdana");
                                        datesEle.setAttribute('font-size', 9);
                                        group.appendChild(datesEle);

                                        graph.appendChild(group);
                                        graph.appendChild(tick);
                                    }
                                })
                            } else {
                                var tick = document.createElementNS(xmls,'path');
                                var group = document.createElementNS(xmls,'g');
                                var tPath = 'M ' + x + '  ' + (height - 80 )+ ' V ' + (height - 90);

                                tick.setAttribute('d', tPath);
                                tick.setAttribute('fill', 'none');
                                tick.setAttribute('stroke-width', 1);
                                tick.setAttribute('stroke', 'black');

                                var datesEle = document.createElementNS(xmls, 'text');
                                var datesDisplay = document.createTextNode(item.dateData);

                                datesEle.setAttribute('x', x - 50);
                                datesEle.append(datesDisplay);
                                datesEle.setAttribute('y', height - 70);
                                datesEle.setAttribute('font-family', "Verdana");
                                datesEle.setAttribute('font-size', 9);
                                group.appendChild(datesEle);

                                graph.appendChild(group);
                                graph.appendChild(tick);
                            }

                            dot.addEventListener('mouseenter', function(e) {
                                var toolPosX = null;
                                var toolPosY = e.offsetY - 100;

                                if(i < arry.length / 2) {
                                    toolPosX = e.offsetX + 65;
                                } else {
                                    toolPosX = e.offsetX - 120
                                }

                                var textX = toolPosX + 20;
                                var textY = toolPosY + 30;

                                rect = document.createElementNS(xmls, 'rect');
                                textDate = document.createElementNS(xmls, 'text');
                                textDate.setAttribute('font-family', "Verdana");
                                textDate.setAttribute('x', textX);
                                textDate.setAttribute('y', textY);
                                textDate.setAttribute('fill', '#d4d4d6');
                                textDate.append(contentDate);

                                textAmount = document.createElementNS(xmls, 'text');
                                textAmount.setAttribute('font-family', "Verdana");
                                textAmount.setAttribute('x', textX);
                                textAmount.setAttribute('y', textY + 20);
                                textAmount.setAttribute('fill', '#d4d4d6');
                                textAmount.append(contentAmount);

                                marginText = document.createElementNS(xmls, 'text');
                                marginText.setAttribute('font-family', "Verdana");
                                marginText.setAttribute('x', textX);
                                marginText.setAttribute('y', textY + 40);
                                marginText.setAttribute('fill', '#d4d4d6');
                                marginText.append(margin);

                                rect.setAttribute('width', 150);
                                rect.setAttribute('height', 100);
                                rect.setAttribute('x',toolPosX);
                                rect.setAttribute('y', toolPosY);
                                rect.setAttribute('fill', '#77787a');
                                rect.setAttribute('stroke', '#323233');
                                rect.setAttribute('stroke-width', 3);
                                rect.setAttribute('opacity', .95);

                                graph.appendChild(rect);
                                graph.appendChild(textDate);
                                graph.appendChild(textAmount);
                                graph.appendChild(marginText);

                                if(!item.margin) {
                                    marginText.setAttribute('opacity', 0);
                                }

                            });

                            dot.addEventListener('mouseleave', function(e) {
                                graph.removeChild(rect);
                                graph.removeChild(textDate);
                                graph.removeChild(textAmount);
                                graph.removeChild(marginText);
                            });

                            line.setAttribute('opacity', .5);

                            dot.setAttribute('r', 6);
                            dot.setAttribute('fill', color);
                            dot.setAttribute('cy',  (height - (item.data * yBuffer + 100)));
                            dot.setAttribute('cx', x);

                            path += 'L ' + (x) + ' ' +  (height - (item.data * yBuffer + 100));

                            graph.appendChild(dot)
                        });

                        line.setAttribute('d', path);
                    }

                    scope.init = function() {

                        var parent = ele[0].parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;

                        scope.sizes = {
                            height:parent.style.height.slice(0 , parent.style.height.length - 2),
                            width: parent.style.width.slice(0 , parent.style.width.length - 2)
                        };

                        var colors = [
                            {color: scope.colors.color1},
                            {color: scope.colors.color2},
                            {color: scope.colors.color3},
                        ];

                        var xmls = "http://www.w3.org/2000/svg";
                        var container = ele["0"].childNodes['0'];
                        var graphCreator = document.createElementNS(xmls, 'svg');
                        var id = Math.floor(Math.random() * (30 - 1) + 1);

                        graphCreator.setAttribute('id', id);
                        container.appendChild(graphCreator);

                        var graph = document.getElementById(id);
                        graph.style.padding = '0 1.5% 0 1.5%';
                        graph.style.zIndex = 200;

                        container.style.width = '75%';
                        container.style.position = 'absolute';
                        container.style.zIndex = 200;


                        var height = scope.sizes.height;
                        var width = scope.sizes.width;


                        var xBuffer = 75;
                        var yBuffer = 100;

                        graph.setAttribute('width', width - xBuffer + 'px');
                        graph.setAttribute('height', height);
                        graph.setAttribute('viewBox', '0 0 ' + ' ' + (width) + ' ' + height);

                        createLineGraph(scope.graphData.indexPrice, xmls, graph, height, xBuffer, yBuffer, width, colors[0].color);
                        createLineGraph(scope.graphData.unitCost, xmls, graph, height, xBuffer,yBuffer, width, colors[1].color);
                        createLineGraph(scope.graphData.unitPrice, xmls, graph, height, xBuffer, yBuffer,width, colors[2].color);
                        createAxes(graph, xBuffer, yBuffer, width, height, xmls);
                        createKey(colors, graph, xmls, width)

                    };

                    var previousContent = null;

                    scope.$watch(function() {
                        return scope.graphData;
                    }, function(old, newData) {
                        if(newData) {
                            if(ele["0"].childNodes['0'].childNodes['0']) {
                                ele["0"].childNodes['0'].childNodes['0'].remove();
                            }
                            scope.init()
                        }
                    },true);

                    scope.$watch(function() {
                        return scope.widget.instance.size;
                    }, function(old, newData) {
                        if(old !== newData) {
                            ele["0"].childNodes['0'].childNodes['0'].remove();
                            scope.init();
                        }
                    }, true);

                }

            }
        }

    ]);
