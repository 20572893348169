angular.module('angus.controllers').controller('monitorComplianceReportContainerCtrl', [
   '$rootScope', '$scope', 'dealerService', 'drillService', 'gridService2', 'gridState', 'productsService', 'subscriberService', 'tmsMonitorComplianceService',
    function($rootScope, $scope,  dealerService, drillService, gridService2, gridState, productsService, subscriberService, tmsMonitorComplianceService) {
        'use strict';

        let tmsAdminWidget;

        $scope.subscriberId = $rootScope.user.subscriberId;

        /* Default Filter Values */
        $scope.filter = {
            monitorReportingThresholdPercent: 70,
            containerVolumeVariancePercent: 3
        };

        let today = new Date();
        today.setHours(0, 0, 0, 0);

        $scope.filterDate = {};

        $scope.filterDate.endDeliveryDate = new Date(today.getTime() - 24 * 60 * 60 * 1000); /* Previous Day */

        $scope.runReport = runReport;    
        
        /* Need to use the watch to support the user selecting a saved filter */
        $scope.$watch('filter.selectedSubscriberId', () => {
            /* Reload Subscriber Specific Assets */
            loadDealers();
            loadProducts();
        });

        /* If the user changes the Subscriber then Reset then Subscriber Specific selections.
            The reloading of the assets will be handled in the selectedSubscriberId watch */
        $scope.subscriberChanged = () => {
            $scope.filter.productCategoryIds = [];
            $scope.filter.dealerIds = [];
        }

        /* Initialize Subscribers and Grid */
        loadSubscribers()
            .then(function() {
                var gridParams = {
                    gridState: gridState($scope.subscriberId, $scope.widgetCode),
                    defs: getColDefs(),
                    clicks: getColClicks(),
                    exportOptions: {fileName: 'Monitor Compliance'},
                    gridOptions: {
                        /*** I'm having an issue with the Month and Year columns showing incorrect aggregates and I can't disable the aggregates for those columns. 
                         * Since no aggregates are needed in this report i'm just setting the groupAggFunction to return undefined so that nothing shows an aggregate. 
                         * If aggregates are needed then we'll probably need to update this funciton to handle them instead of using the built in functions (sum, avg, etc...) */
                        groupAggFunction: (rows) => undefined
                    }
                }

                return gridService2.createGrid(gridParams);
            })
            .then(function(grid) {
                /* When the report loads the user must select a subscriber before they can run the report.
                    So when we initially load the page we don't want to run the report and show an empty grid. 
                    However, it appears the grid will just show "loading" until we call setRows for the first time. setRows expects a promise.
                    Here i'm calling setRows it with an empty promise which will mimic running the report and returning 0 rows. */
                grid.setRows(Promise.resolve());

                $scope.grid = grid;
            })
            .catch(function(error) {
                console.log(error);
            });



        function loadSubscribers(){
            $scope.isSubscribersLoading = true;

            return subscriberService.getAmsSubscribers()
                .then(function(subscribers){
                    $scope.subscribers = subscribers;
                    $scope.isSubscribersLoading = false;
                });
        }

        function loadProducts() {
            if (!$scope.filter.selectedSubscriberId) {
                return;
            }

            $scope.areProductsLoading = true;

            /* Temporary Clear out selection while loading. Will be set back in the callback */
            var selectedProductCategoryIds = $scope.filter.productCategoryIds;
            $scope.filter.productCategoryIds = [];

            return productsService
                .getProducts($scope.filter.selectedSubscriberId)
                .then(function(products){
                    $scope.products = products;
                    $scope.filter.productCategoryIds = selectedProductCategoryIds;
                    $scope.areProductsLoading = false;
                });
        }

        function loadDealers() {
            if (!$scope.filter.selectedSubscriberId) {
                return;
            }

            $scope.areDealersLoading = true;

            /* Temporary Clear out selection while loading. Will be set back in the callback */
            var selectedDealerIds = $scope.filter.dealerIds;
            $scope.filter.dealerIds = [];

            return dealerService
                .getDealers($scope.filter.selectedSubscriberId)
                .then(function(dealers) {
                    $scope.dealers = dealers;
                    $scope.filter.dealerIds = selectedDealerIds;
                    $scope.areDealersLoading = false;
                });
        }

        function getColDefs() {
            let nextIndex = 0;
            const colDefs = [];

            colDefs.push(gridService2.colDef.createText('dealerName','Dealer','dealerName'));
            colDefs.push(gridService2.colDef.createText('divisionName','Division','divisionName'));
            colDefs.push(gridService2.colDef.createText('deliveryCenterName','Delivery Center','deliveryCenterName', {hide: true}));

            colDefs.push(gridService2.colDef.createBoolean('containerLevelPercentageFailureFlag','Level Interruptions','containerLevelPercentageFailureFlag'));
            colDefs.push(gridService2.colDef.createNumber('containerLevelCount','Level Days','containerLevelCount', {}, { decimalPlaces: 0 }));
            colDefs.push(gridService2.colDef.createPercentage('containerLevelPercentThirtyDays','Levels %','containerLevelPercentThirtyDays', {}, {decimalPlaces: 1 }));
    
            colDefs.push(gridService2.colDef.createBoolean('potentialPartialDeliveryFlag','Potential Not Full','potentialPartialDeliveryFlag'));
            colDefs.push(gridService2.colDef.createPercentage('postDeliveryFullPercent','Potential Not Full %','postDeliveryFullPercent', {}, {decimalPlaces: 1 }));

            colDefs.push(gridService2.colDef.createBoolean('calculatedTankSizeToCapacityPercentageFailureFlag','Geometry Variance','calculatedTankSizeToCapacityPercentageFailureFlag'));
            
            colDefs.push(gridService2.colDef.createNumber('calculatedTankSize','Monitor Geometry','calculatedTankSize', {}, { decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createNumber('capacity','Tank Size','capacity'));
            colDefs.push(gridService2.colDef.createPercentage('calculatedTankSizeToCapacityPercentageDisplay','Tank Capacity Variance','calculatedTankSizeToCapacityPercentageDisplay', {}, {decimalPlaces: 1 }));

            colDefs.push(gridService2.colDef.createText('productName','Product','productName'));
            colDefs.push(gridService2.colDef.createText('sourceProductName','Source Product','sourceProductName', { hide: true }));

            colDefs.push(gridService2.colDef.createText('deliveryTypeName','BRITE Delivery Type','deliveryTypeName'));
            colDefs.push(gridService2.colDef.createText('sourceDeliveryTypeName','Source Delivery Type','sourceDeliveryTypeName', { hide : true }));
            colDefs.push(gridService2.colDef.createText('deliveryTypeNameTank','BRITE Tank Delivery Type','deliveryTypeNameTank', { hide : true }));
            colDefs.push(gridService2.colDef.createText('sourceDeliveryTypeNameTank','Source Tank Delivery Type','sourceDeliveryTypeNameTank', { hide : true }));

            colDefs.push(gridService2.colDef.createDate('dynamicReserveExecutionDate','Adept Run Date','dynamicReserveExecutionDate', { hide: true} ));
            colDefs.push(gridService2.colDef.createDate('expectedDeliveryDate','Expected Delivery Date','expectedDeliveryDate', { hide: true} ));
            colDefs.push(gridService2.colDef.createText('deliveryId','Delivery Number','deliveryId', { hide: true} ));
        
            colDefs.push(gridService2.colDef.createDate('deliveryDate','Delivery Date','deliveryDate', { sort : 'desc'}));
            colDefs.push(gridService2.colDef.createMonth('deliveryDateMonth','Delivery Month','deliveryDateMonth', { hide: true }));
			colDefs.push(gridService2.colDef.createYear('deliveryDateYear','Delivery Year','deliveryDateYear',  { hide: true }));

            colDefs.push(gridService2.colDef.createText('deliveryDateToExpectedDateDaysDifference','Adept Date Variance','deliveryDateToExpectedDateDaysDifference', { cellClass : ['center'], hide: true} ));
            colDefs.push(gridService2.colDef.createBoolean('deliveryDateMovedEarlyFlag','Optimized','deliveryDateMovedEarlyFlag', {}, { hide: true} ));
            colDefs.push(gridService2.colDef.createNumber('optimizedDays', 'Days Optimized', 'optimizedDays', { hide: true }));


            colDefs.push(gridService2.colDef.createText('customerId','Account Number','customerId'));
            colDefs.push(gridService2.colDef.createText('customerName','Customer Name','customerName'));
            colDefs.push(gridService2.colDef.createText('containerId','Tank Number','containerId'));
            colDefs.push(gridService2.colDef.createText('monitorSerialNumber','Monitor Serial Number','monitorSerialNumber'));

            colDefs.push(gridService2.colDef.createNumber('optimalDeliveryUnits','Ideal Drop','optimalDeliveryUnits'));
            colDefs.push(gridService2.colDef.createNumber('usableVolume','Tank Usable','usableVolume')); 
            colDefs.push(gridService2.colDef.createNumber('containerUnitsAvailableClosestToDelivery','Prior In-tank','containerUnitsAvailableClosestToDelivery', {}, {decimalPlaces: 1 } )); 
            colDefs.push(gridService2.colDef.createNumber('expectedDeliveryUnits','Expected Delivery Units','expectedDeliveryUnits', {}, {decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createNumber('units','Units Delivered','units', {}, {decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createNumber('deliveredUnitsToExpectedUnitsVariance','Units Variance','deliveredUnitsToExpectedUnitsVariance', {}, {decimalPlaces: 1 } ));  
            colDefs.push(gridService2.colDef.createBoolean('partialFillFlag','Partial Fill','partialFillFlag'));

            colDefs.push(gridService2.colDef.createNumber('kFactorPrior','Prior Working K','kFactorPrior', {}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createNumber('kFactorDelivered','Delivery K','kFactorDelivered', {}, { decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createPercentage('kFactorVariance','K-Factor Variance','kFactorVariance', {}, {decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createNumber('baseLoadForDelivery','Baseload','baseLoadForDelivery', {}, {decimalPlaces: 2} ));

            colDefs.push(gridService2.colDef.createBoolean('runOutFlag','Run Out','runOutFlag'));
            colDefs.push(gridService2.colDef.createBoolean('potentialRunOutFlag','Potential Run Out','potentialRunOutFlag', { hide: true }));
            colDefs.push(gridService2.colDef.createText('deliveryGroupId','Delivery Group','deliveryGroupId', { hide: true }));
            colDefs.push(gridService2.colDef.createText('driverName','Driver Name','driverName', { hide: true } ));

            colDefs.push(gridService2.colDef.createPercentage('preDeliveryPercentFull','Start %','preDeliveryPercentFull', { hide: true }, {decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createPercentage('postDeliveryPercentFull','End %','postDeliveryPercentFull', { hide: true }, {decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createBoolean('preDeliveryUnitsCalculatedFlag','Start % Est','preDeliveryUnitsCalculatedFlag', { hide: true }));
            colDefs.push(gridService2.colDef.createBoolean('postDeliveryUnitsCalculatedFlag','End % Est','postDeliveryUnitsCalculatedFlag,', { hide: true }));
            colDefs.push(gridService2.colDef.createNumber('preDeliveryUnits','Prior to Delivery Units','preDeliveryUnits', { hide: true }, {decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createNumber('postDeliveryUnits','Post Delivery Units','postDeliveryUnits', { hide: true }, {decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createNumber('postDeliveryContainerUnitsAvailable','Post Delivery Available Units','postDeliveryContainerUnitsAvailable', { hide: true }, {decimalPlaces: 1 }));

            colDefs.push(gridService2.colDef.createPercentage('percentageOfIdealDrop','% Ideal Drop Delivered','percentageOfIdealDrop', { hide: true }, { decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createPercentage('adjustedPercentOptimalDrop','Adjusted % Ideal Drop','adjustedPercentOptimalDrop', { hide: true }, {decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createPercentage('percentTankDelivered','% of Tank Size Delivered','percentTankDelivered', {}, {decimalPlaces: 1 }));

            colDefs.push(gridService2.colDef.createDate('monitorInstallDate','Monitor Install Date','monitorInstallDate')); 
            colDefs.push(gridService2.colDef.createDate('acquisitionDateTank ','Tank Acquisition Date','acquisitionDateTank'));
            colDefs.push(gridService2.colDef.createBoolean('propaneFlag','Propane','propaneFlag,', { hide: true }));

            colDefs.push(gridService2.colDef.createDate('mostRecentLevelDatePriorToDelivery','Last Tank Level Date','mostRecentLevelDatePriorToDelivery')); 
            colDefs.push(gridService2.colDef.createText('monitorTypeName','Monitor Type','monitorTypeName'));
            colDefs.push(gridService2.colDef.createText('monitorTypeDescription','Monitor Type Description','monitorTypeDescription', { hide: true }));

            return colDefs;
        }

        function getColClicks() {
            return {
                monitorSerialNumber: {
                    onClick: function(row) {
                        subscriberService.setAdminSubscriberId($scope.selectedSubscriberId);

                        drillService.openTmsAdminCustomer(row.containerKey);
                    }
                },
                customerId: { /* Account Number */
                    onClick: function(row) {
                        subscriberService.setAdminSubscriberId($scope.selectedSubscriberId);
                        
                        const model = {
                            customerKey: row.customerKey,
                            accountNumber: row.customerId,
                            customerName: row.customerName
                        };

                        drillService.openAccountInfo(model, drillService.context.delivery, $scope.selectedSubscriberId);
                    }
                },
                containerId: { /* Tank Number */
                    onClick: function(row) {
                        subscriberService.setAdminSubscriberId($scope.selectedSubscriberId);

                        const model = {
                            customerKey: row.customerKey,
                            accountNumber: row.customerId,
                            customerName: row.customerName,
                            containerKey: row.containerKey,
                            tankNumber: row.containerId,
                            productName: row.productName,
                            tankSize: row.capacity,
                            idealDrop: row.optimalDeliveryUnits
                        }

                        drillService.openTankInfo(model, drillService.context.delivery);

                    }
                }
            };
        }

        function runReport() {
            const subscriberId = $scope.filter.selectedSubscriberId;

            if (!subscriberId) {
                /* Don't run the report if a Subscriber hasn't been selected */
                return;
            }

            /* We'll need this if the User try's to open a Container in the TMS Admin page */
            $scope.selectedSubscriberId = subscriberId;

            const endDate = new Date($scope.filterDate.endDeliveryDate);
            
            const startDate = new Date(endDate.getTime() - 30 * 24 * 60 * 60 * 1000 ); /* 31 Days Prior */

            const dealerIds = $scope.filter.dealerIds;
            const productCategoryIds = $scope.filter.productCategoryIds;
            const monitorReportingThresholdPercent = $scope.filter.monitorReportingThresholdPercent;
            const containerVolumeVariancePercent = $scope.filter.containerVolumeVariancePercent;
 
            var dataPromise = tmsMonitorComplianceService.getComplianceReport($scope.filter.selectedSubscriberId, startDate, endDate, dealerIds, productCategoryIds, monitorReportingThresholdPercent, containerVolumeVariancePercent)
                .then(data => {
                    data.forEach(row => {
                        /* There seems to be a bug with the Grid where the Render won't work if the ColId is different from the FieldId. Duplicating the Fields so I can keep the names the same as the ColId */
                        row.deliveryDateMonth = row.deliveryDate;
                        row.deliveryDateYear = row.deliveryDate;
                    });

                    return data;
                });

            var promise = $scope.grid.setRows(dataPromise);

            return promise;
        }
    }


]);