angular.module( 'angus.directives' ).directive( 'onlyNum', ['$parse', function($parse) {
	return {
		require: 'ngModel',
		restrict: 'A',
		link: function( scope, element, attrs, ngModel ) {

			var validationPattern = attrs.onlyNumValidation;
			// var restrictionPattern = attrs.onlyNum  || /^-?\d*\.?\d*$/;
            var restrictionPattern = attrs.onlyNum  || /^-?[0-9]*(?:\.[0-9]+)?$/;

            if(attrs.onlyInt)
				restrictionPattern =/^\d*$/;


			
			var minVal = Number( attrs.min 
									? isNaN(attrs.min)
										? $parse(attrs.min)(scope)
										: attrs.min
									: Number.MAX_VALUE * -1 );
			var maxVal = Number( attrs.max 
									? isNaN(attrs.max)
										? $parse(attrs.max)(scope)
										: attrs.max
									: Number.MAX_VALUE);

			scope.$watch( attrs.ngModel, function( newValue, oldValue ) {
				if ( newValue === oldValue ) return;

				ngModel.$setValidity( 'min', true );
				ngModel.$setValidity( 'max', true );
				ngModel.$setValidity( 'number', true );

				if ( validationPattern ) ngModel.$setValidity( 'regex', true );

				if ( newValue === null || newValue === '' || newValue === undefined) return;



				if ( !isNaN( ngModel.$viewValue ) ) {
					var newVal = Number( ngModel.$viewValue );
					if ( newVal < minVal )
						ngModel.$setValidity( 'min', false );
					else if ( newVal > maxVal )
						ngModel.$setValidity( 'max', false );
					else if ( validationPattern && !new RegExp( validationPattern ).test( ngModel.$viewValue ) )
						ngModel.$setValidity( 'regex', false );
				} else {

					ngModel.$setValidity( 'number', false );
				}


				if ( new RegExp( restrictionPattern ).test( newValue ) ) {
					ngModel.$setViewValue( newValue );
				} else {
					ngModel.$setViewValue( oldValue );
				}


				
				ngModel.$render();
			} );
		}
	};
}] );