'use strict';

angular.module( 'angus.directives' ).directive( 'serviceCalls', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		return {
			scope: {
				model: '=actionModel',
				settings: '=serviceCallsWidgetSettings',
				widgetCode : '='
			},
			templateUrl: actionViewTemplates.serviceCallsReportContainer
		};
	}
] );