angular.module('angus.controllers').controller('authenticatedController', [
    '$scope', 'windowsService',
    function($scope, windowsService) {

        $scope.windows = windowsService.getWindows();
        $scope.activeWindow = windowsService.getActiveWindow();

        $scope.$on('activeWindowChanged', function(event, newActiveWindow) {
            $scope.activeWindow = newActiveWindow;
        });
    }
]);