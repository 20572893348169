angular.module( 'angus.directives' ).directive( 'performance', [
    'actionViewTemplates',
    function(actionViewTemplates ) {
        return {
            scope: {
                closeCb     : '=',
                model : '=actionModel',
                widgetClass : '=',
                settings   : "=performanceWidgetSettings",
            }, 
            templateUrl: actionViewTemplates.performanceTableTemplate
        };
    }
] );