angular.module( 'angus.directives' ).directive( 'uniqueChild', [
	'$parse', '_',
	function( $parse, _ ) {
		return {
			require: 'ngModel',
			link: function( scope, elem, attrs, ctrl ) {

				var parent       = $parse( attrs.uniqueChild )( scope );
				var propertyName = attrs.propertyName;

				scope.$watch( function() {
					return ctrl.$modelValue;
				}, function( currentValue ) {

					var matchingSiblings = _.filter(parent, function(child){
							return child[propertyName] === currentValue;
						});

					ctrl.$setValidity( 'uniqueChild', matchingSiblings.length == 1 );
				} );
			}
		};
	}
] );