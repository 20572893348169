'use strict';
angular.module( 'angus.directives' ).directive( 'arSummary', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		return {
			scope: {
				model: '=actionModel',
				settings: '=arSummaryWidgetSettings',
				widgetCode : '='
			},
			templateUrl: actionViewTemplates.arSummaryReportContent
		};
	}
] );