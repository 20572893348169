angular.module('angus.controllers').controller('subRegHierarchyController', [
    '$scope', '$http', '$q', 'hierarchyService', 'modalService', 'settingsViews', 'actionViewTemplates', 'constantsService', '_', 'fluentRest', 'promiseMonitor', 'alertsService',
    function($scope, $http, $q, hierarchyService, modalService, settingsViews, actionViewTemplates, constants, _, fluentRest, PromiseMonitor, AlertsService) {

        'use strict';

        var vm = this;

        vm.subscriber = $scope.$parent.$parent.subscriber; // This is bad but I want to do the controllerAs

        vm.alerts = new AlertsService();


        function getAccess() {
            return $http
                .get('/access')
                .success(function() {
                // .success(function(access) {
                    vm.hasAccess = true; //access.role == constants.roles.sysadmin;
                    return vm.hasAccess;
                });
        }

        function boldQuotes(str){
            var startsWithQuote = str.charAt(0) == '\"';

            function boldIt(substring){
                return '<strong>' + substring + '</strong>';
            }

            var arr = str.split('\"');

            if(startsWithQuote) arr.splice(0, 1);

            return _.reduce(arr, function(result, substring, index){
                var mod = (index % 2) === 0;
                if(mod == startsWithQuote)
                    result += boldIt(substring)
                else
                    result += substring;

                return result;
            },'');

        }

        function mapBoldQuotes(strs){
            return _.map(strs, function(str){ return boldQuotes(str); })
        }


        vm.isHierarchySyncing = false;
        vm.syncHierarchy = function() { 
            vm.isHierarchySyncing = true;
            return $http.get(('api/subscribers/{0}/hierarchy/nodes/sync?preserve=1').format(vm.subscriber.id))
                .then(function() {
                    vm.isHierarchySyncing = false;
                    init()
                }); 
        };

        vm.getTMSContacts = function(requery){
            if(vm.tmsContacts && !requery) return $q.when();

            return   $http({
                method: 'GET',
                url   : '/api/subscribers/{0}/tmsContacts'.format($scope.subscriberId),                
                params : { allDealers : true }
            })
            .then(function(result){ 
              vm.tmsContacts = result.data;
            });
        };

        vm.getTMSContactForNode = function(node){
            return _.find(vm.tmsContacts, function(contact){
                return contact.dealerId == node.id;
            });            
        };

        vm.openTMSContactModal = function(node, contact){      
           var scope = {
              contact         : contact ? _.cloneDeep(contact) : {},        
              subscriberName  : $scope.subscriber.name,
          };

          modalService
            .openTopBarModal(actionViewTemplates.subRegEditTMSContact, scope)
            .then(function(contact){          
                if(!contact) return;

                contact.dealerId = node.id;

                var promise =   $http({
                    method: 'PUT',
                    url   : '/api/subscribers/{0}/tmsContacts'.format($scope.subscriberId),
                    data  : contact
                })
                .then(function(){ 
                    vm.tmsContact = contact;
                    return vm.getTMSContacts(true);                    
                });
                
                $scope.tmsContactMonitor = new PromiseMonitor(promise);
            });
        };


        function HierarchyType(key, display){
            var that = this;

            that.key            = key;
            that.display        = display;

            that.showSuppressed = false;
            that.showUsers      = true;
            that.showBudgets    = true;
            that.showTypes      = true;

            that.selectedNodes  = [];
            that.leafSelected   = false;
            that.rootSelected   = false;

            that.batchPromises = new PromiseMonitor();
            that.promiseMonitor = new PromiseMonitor();

            that.isDealer = that.key == constants.hierarchyType.dealer.key;

            that.init = function(){
                var promises = [vm.activeHierarchyType.getNodes()];

                if(that.isDealer){
                    promises.push(vm.getTMSContacts());   
                }                  

                var promise = $q.all(promises);
                that.promiseMonitor.monitor(promise);
                return promise;
            }


            that.nodeSelected = function(node, selected) {
                node.willBeOrphaned = node.originalParent == that.currentNode.id && !selected;
            };

            function getFilter(){
                var filter =  {
                    hierarchyType  : that.key,
                    showSuppressed :  that.showSuppressed ? 1 : undefined,
                    showUsers :  that.showUsers ? 1 : undefined,
                    showWithoutChildren : true
                };

                if(that.key == 'division' && that.showBudgets)
                    filter.showBudgets =  that.showBudgets ? 1 : undefined;

                return filter;
            }


            function saveNode(id, postData){
                //nodeIdToUseForSettings
                var endpoint = fluentRest.api().subscribers(vm.subscriber.id).hierarchy().nodes(id);

                var promise = id ? endpoint.put(postData, getFilter()) : endpoint.post(postData, getFilter());

                return promise
                    .then(function(data){
                            if(data.messages.success && data.messages.success.length > 0)
                                vm.alerts.addSuccess(((id ? 'Updated' : 'Inserted') + ' node.'), mapBoldQuotes(data.messages.success));

                            mapNodes(data.nodes);
                            that.cancelEdit();
                        })
                        .catch(function(err){
                            vm.alerts.addError(boldQuotes(err));
                        });
            }


            function CurrentNode(node){
                var currentNode = this;

                currentNode.promises = new PromiseMonitor();

                _.assign(currentNode, node);

                function getData(){
                    var data = {
                        parent : currentNode.parent,
                        children : _.map(currentNode.children, 'id'),
                        name     : currentNode.name,
                        typeId   : currentNode.typeId
                    };

                    if(that.isDealer) data.amsDealerFlag = !!currentNode.amsDealerFlag;

                    return data;
                }

                currentNode.save = function(){
                    var promise = saveNode(currentNode.id, getData());
                    that.currentNode.promises.monitor(promise);
                    return promise;
                };

                return currentNode;
            }

            function mapNodes(nodes){
                that.nodes = hierarchyService.convertDataForEaseOfUse(nodes);

                var rootNode = _.find(that.nodes, function(node){ return node.isRoot; });

                function convert(node, hierarchyName){

                    hierarchyName = hierarchyName ? (hierarchyName + ':)' +  node.name) : node.name;

                    var data = {
                        name                    : node.name,
                        id                      : node.id,
                        originalParent          : node.parents[0] ? node.parents[0].id : null,
                        isRoot                  : node.isRoot,
                        isLeaf                  : node.isLeaf,
                        isSuppressed            : node.isSuppressed,
                        innerNodeWithNoChildren : node.innerNodeWithNoChildren,
                        hierarchyName           : hierarchyName,
                        numberOfUsers           : node.numberOfUsers,
                        numberOfBudgets         : node.numberOfBudgets,
                        type                    : node.type
                    };

                    if(that.isDealer){
                        data.isInDealerHierarchy = node.isInDealerHierarchy;
                        data.isDealer = node.isDealer;
                        data.amsDealerFlag = !!node.amsDealerFlag;
                    }

                    if(node.children) data.children = _.map(node.children, function(child){ return convert(child, hierarchyName); });
                    data.children = _.sortBy(data.children, 'name')
                    return data;
                }


                function getAllNodesInTree(nodes){
                    return _.reduce(nodes, function(result, node){
                        result.push(node);
                        var descendants = getAllNodesInTree(node.children);
                        result = result.concat(descendants);
                        return result;
                    },[])
                }

                that.treeViewNodes         = [convert(rootNode)];
                that.treeViewNodesExpanded = getAllNodesInTree(that.treeViewNodes);

                that.childrenTreeVieNodes         = _.cloneDeep(that.treeViewNodes[0].children);
                that.childrenTreeVieNodesExpanded = getAllNodesInTree(that.childrenTreeVieNodes);
            }



            function getSelectedNodeIds(){ return _.map(that.selectedNodes, 'id'); }

            function nodeSuppression(suppress){
                var postData = {
                    nodeIds    : getSelectedNodeIds(),
                    suppress   : suppress
                };

                var word = postData.suppress ? 'Suppress' : 'Unsupress';

                var promise = fluentRest
                    .api()
                    .subscribers(vm.subscriber.id)
                    .hierarchy()
                    .nodes()
                    .suppress()
                    .put(postData, getFilter())
                        .then(function(data){
                            if(data.messages.success && data.messages.success.length > 0)
                                vm.alerts.addSuccess((word + 'ed nodes.'), mapBoldQuotes(data.messages.success));

                            if(data.messages.failure && data.messages.failure.length > 0)
                                vm.alerts.addError('Unable to ' + word.toLowerCase() + ' nodes.', mapBoldQuotes(data.messages.failure));

                            mapNodes(data.nodes);
                            that.selectedNodes = [];
                        })
                        .catch(function(err){
                            vm.alerts.addError(boldQuotes(err));
                        });

                that.batchPromises.monitor(promise);

                return promise;
            }

            that.anyUnsuppressableSelected = function(){
                return _.some(that.selectedNodes, function(node){
                    return !node.isSuppressed && !node.isRoot;
                });
            };
            that.anySuppressableSelected = function(){
                return _.some(that.selectedNodes, function(node){
                    return node.isSuppressed;
                });
            };
            that.anyNotRootSelected = function(){
                return _.some(that.selectedNodes, function(node){
                    return !node.isRoot;
                });
            };

            that.anyNotLeafSelected = function(){
                return _.some(that.selectedNodes, function(node){
                    return !node.isLeaf;
                });
            };

            that.anyInnerSelected = function(){
                return _.some(that.selectedNodes, function(node){
                    return !node.isLeaf && !node.isRoot;
                });
            };

            that.getNodes = function(){
                that.selectedNodes = [];

                var nodePromise = fluentRest
                    .api()
                    .subscribers(vm.subscriber.id)
                    .hierarchy()
                    .nodes()
                    .panel()
                    .get(getFilter())
                    .then(mapNodes);

                var levelPromise = fluentRest
                    .api()
                    .subscribers(vm.subscriber.id)
                    .hierarchy()
                    .types()
                    .get({ hierarchyType  : that.key })
                    .then(function(types){
                        that.nodeTypes = types;
                        that.nodeTypeSelections = _.filter(that.nodeTypes, function(type){
                            return !type.isRoot && !type.isLeaf;
                        })
                    });

                var promise = $q.all([
                    nodePromise,
                    levelPromise
                ]);

                that.promiseMonitor.monitor(promise);

                return promise;
            };

            that.changeParent = function(){

                var scope = {
                    nodes : that.nodes,
                    key : that.key
                };

                return modalService
                    .openTopBarModal(actionViewTemplates.subRegSelectHierarchyNode, scope)
                    .then(function(nodeId){
                        if(!nodeId) return;

                        var node = _.find(that.nodes, function(n){
                            return n.id == nodeId;
                        });

                        var selectedNodeIds = getSelectedNodeIds();
                        var currentChildrenIds = _.map(node.children, 'id');

                        selectedNodeIds = _.uniq(selectedNodeIds.concat(currentChildrenIds));
                        var postData = { parent : node.parents[0] ? node.parents[0].id : null, children : selectedNodeIds, typeId  : node.typeId};
                        return saveNode(nodeId, postData);
                    });
            };

            that.deleteNodes = function(){

                var nodes = _.filter(that.selectedNodes, function(node){
                    return !node.isLeaf && !node.isRoot;
                });

                var promise = modalService
                    .openTopBarModal(actionViewTemplates.subRegHierarchyConfirmRemoveNode, {
                        nodeNames: nodes.length > 0 ? _.map(nodes, 'name') : null,
                        hierarchyType: that.key
                    })
                    .then(function(confirmation) {
                        if(!confirmation) return;

                        var postData = {
                            nodeIds    : _.map(nodes, 'id')
                        };

                        _.assign(postData,getFilter());

                        return fluentRest
                            .api()
                            .subscribers(vm.subscriber.id)
                            .hierarchy()
                            .nodes()
                            .delete(postData)
                                .then(function(data){
                                    if(data.messages.success && data.messages.success.length > 0)
                                        vm.alerts.addSuccess('Deleted nodes.', mapBoldQuotes(data.messages.success));

                                    if(data.messages.failure && data.messages.failure.length > 0)
                                        vm.alerts.addError('Unable to delete nodes.', mapBoldQuotes(data.messages.failure));

                                    mapNodes(data.nodes);
                                    that.selectedNodes = [];
                                })
                                .catch(function(){
                                    vm.alerts.addError('Unable to delete nodes.');
                                });


                    });

                 that.batchPromises.monitor(promise);

                return promise;
            }

            that.suppressNodes = function(){
                return nodeSuppression(true);
            };

            that.unSuppressNodes = function(){
                return nodeSuppression(false);
            };

            that.cancelEdit = function(){
                delete that.currentNode;
            };

            that.addNewNode = function() {
                that.selectedNodes = [];
                var newNode = {
                    name : null,
                    isSuppressed : false,
                    children : []
                };
                vm.alerts.clearAll();
                that.currentNode = new CurrentNode(newNode);

            };

            that.editNode = function(node){
                that.selectedNodes = [];
                var nodeWithAllProps = _.find(this.nodes, function(potentialNode){ return potentialNode.id == node.id; });

                if(!nodeWithAllProps) return;

                var editNode = _.cloneDeep(nodeWithAllProps);

                editNode.typeId = nodeWithAllProps.type && nodeWithAllProps.type.id ? nodeWithAllProps.type.id : null;

                if(!nodeWithAllProps.isRoot) editNode.parent = nodeWithAllProps.parents[0].id;

                if(that.isDealer) editNode.amsDealerFlag = nodeWithAllProps.amsDealerFlag;


                function getChildren(nodes, nodeId){
                    var children = _.reduce(nodes, function(result, node){
                        if(node.id == nodeId) result =  node.children;
                        else {
                            var children = getChildren(node.children, nodeId);
                            if(children.length> 0) result = children;
                        }

                        return result;
                    },[]);

                    return children;
                }

                if(!nodeWithAllProps.isLeaf)
                    editNode.children = nodeWithAllProps.isRoot ? _.cloneDeep(that.childrenTreeVieNodes) : _.cloneDeep(getChildren(that.childrenTreeVieNodes, editNode.id));

                vm.alerts.clearAll();
                that.currentNode = new CurrentNode(editNode);
            };

            that.editTypes = function(){
                var scope  = {
                    alerts : new AlertsService(),
                    hierarchyType : that.key,
                    types : that.nodeTypes,
                    saveTypes : function(){
                        var modal = this;

                        fluentRest
                            .api()
                            .subscribers(vm.subscriber.id)
                            .hierarchy()
                            .types()
                            .put({ nodeTypes : modal.types },{ hierarchyType  : that.key })
                            .then(function(){
                                modal.close(true);
                            })
                            .catch(function(err){
                                modal.alerts.addError(err);
                            });
                    },
                    add : function(){
                        var newType = {
                            hierarchyType: this.hierarchyType,
                            name: '',
                            subscriberId: vm.subscriber.id
                        };
                        this.types.splice(this.types.length - 1, 0, newType);
                    },
                    move : function(oldIndex, newIndex){
                        var types = this.types;
                        if (newIndex >= types.length) {
                            var k = oldIndex - types.length;
                            while ((k--) + 1) {
                                types.push(undefined);
                            }
                        }
                        types.splice(newIndex, 0, types.splice(oldIndex, 1)[0]);
                    },
                    delete : function(i){
                        this.types.splice(i, 1);
                    }
                };

                modalService
                    .openTopBarModal(actionViewTemplates.subRegEditNodeTypes, scope)
                    .then(function(saved){
                        if(!saved) return;
                        return that.getNodes();
                    });
            }
        }

        $scope.switchHierarchyType = function(){
            return vm.activeHierarchyType.init();
        }



        function init() {
            vm.promiseMonitor = new PromiseMonitor();

            vm.treeControlOptions = {
                multiSelection  : true, templateUrl:
                actionViewTemplates.subRegHierarchyTreeControl,
                isSelectable: function() {
                    return !vm.activeHierarchyType.promiseMonitor.isPending();
                }, injectClasses : {
                    'iExpanded': 'fa-minus-square-o',
                    'iCollapsed': 'fa-plus-square-o',
                }
            };

            vm.formTreeControlOptions = {
                multiSelection  : true, templateUrl:
                actionViewTemplates.subRegHierarchyTreeControl,
                isSelectable: function() {
                    return vm.activeHierarchyType.currentNode && !vm.activeHierarchyType.currentNode.promises.isPending();
                }, injectClasses : {
                    'iExpanded': 'fa-minus-square-o',
                    'iCollapsed': 'fa-plus-square-o',
                }
            };

            var promise = getAccess()
                .then(function(hasAccess){

                    vm.hierarchyTypes = _.reduce(constants.hierarchyType.enums, function(result, type){
                            if(type.key != constants.hierarchyType.dealer.key || (vm.subscriber.isPaygo && hasAccess))
                                result.push(new HierarchyType(type.key, type.value.display));
                            return result;
                        }, []);

                    vm.activeHierarchyType = vm.hierarchyTypes[0];
                    return vm.activeHierarchyType.init();
                });

            vm.promiseMonitor.monitor(promise);
        }

        init();
    }
]);
