angular.module('angus.services').service('adminService', [
    '$http',
    function($http) {
        function getUsersReport() {
            return queryUsersReport()
                .then(function(res){
                    return res.data;
                });
        }
        function queryUsersReport() {
            return $http.get(('api/subscribers/users'));
        }

        return {
            getUsersReport: getUsersReport,
        };
    }
])