angular.module('angus.services').factory('salesPersonService', [
	'$rootScope', '$http', '$q', '_',
	function($rootScope, $http, $q, _) {
		'use strict';

		var subscriberSalesPerson = [];
		var salesPerson = [];

		function getSalesPerson(subscriberId) {
			if (subscriberId) {
				if (subscriberSalesPerson[subscriberId] && subscriberSalesPerson[subscriberId].length > 0)
					return subscriberSalesPerson[subscriberId];
				else {
					return querySalesPerson(subscriberId)
						.then(function(salesPersonList) {
							subscriberSalesPerson[subscriberId] = salesPersonList.data;
							return subscriberSalesPerson[subscriberId];
						});
				}
			} else {
				if (salesPerson && salesPerson.length > 0)
					return salesPerson;
				else {
					return querySalesPerson()
						.then(function(salesPersonList) {
							salesPerson = salesPersonList.data;
							return salesPerson;
						});
				}
			}
		}

		function querySalesPerson(subscriberId) {
			return $http.get(('api/subscribers/{0}/salesPerson').format(subscriberId || $rootScope.user.subscriberId));
		}

		return {
			getSalesPerson: function(subscriberId) {
				return $q.when(getSalesPerson(subscriberId))
					.then(function(salesPerson) {
						return _.cloneDeep(salesPerson);
					});
			},
			formatDeliveryTypesList: function(deliveryTypeIds) {
				var result = '';
				var salesPersonList = salesPerson;

				if (!(salesPersonIds instanceof Array))
					salesPersonIds = [salesPersonIds.toString()];

				if (salesPersonIds.length > 0) {
					_.forEach(salesPersonList, function(salesPerson) {
						if (_.includes(salesPersonIds, salesPerson.id.toString()))
							result += salesPerson.name + ', ';
					});
				}

				return result.substring(0, result.length - 2);
			}
		};
	}
]);
