angular.module('angus.directives')
  .directive('deliveryTypesSelect', [function () {
    'use strict';

    return {
      restrict: 'A',
      scope: {
        ngModel: '=',
        subscriberId: '='
      },
      controller: ['$scope', 'deliveryTypeService', function ($scope, deliveryTypeService) {
          deliveryTypeService
          	.getDeliveryTypes()               
          	.then(function (deliveryTypes) {
            	$scope.deliveryTypes = deliveryTypes;
          	});
      }],
      template: '<div multi-select-ok-wrapped="deliveryTypes" ng-model="ngModel" display-prop="\'name\'" selection-prop="\'id\'"></div>',
    };
  }]);