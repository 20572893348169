angular.module('angus.controllers').controller('deliveryPerfCtrl', [
    '$scope', '$rootScope', '$http', 'modalService', 'dateToString', 'productsService', 'periodIndicators', '$q', 'hierarchyService', 'actionViewTemplates', '_',
    'constantsService', 'moment', 'datePickerService', 'lastDeliveryDateService', 'dateCalculator', 'fiscalYearService',
    function($scope, $rootScope, $http, modalService, dateToString, productsService, periodIndicators, $q, hierarchyService, actionViewTemplates, _,
             constantsService, moment, datePickerService, lastDeliveryDateService, dateCalculator, fiscalYearService) {

        'use strict';

        var divisionIds = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.product = {};

        $scope.periodIndicators = constantsService.periodIndicators.enums;

        $scope.periodIndicator = _.find(constantsService.periodIndicators, function(indicator) {
            return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
        });

        function loadWidget() {
            $scope.widget.noContentFlag = false;

            $scope.widget.instance.settings.percentageView = $scope.widget.instance.settings.percentageView || 1;
            var promise = lastDeliveryDateService
                .getLastDeliveryDateForDivisions(divisionIds)
                .then(function(lastDeliveryDate) {
                    if (!$scope.dates) {
                        $scope.dates = {};

                        var dates = dateCalculator.calculatePeriod($scope.dates.periodStartDate, lastDeliveryDate || moment.utc(), $scope.widget.instance.settings.periodIndicatorId.value);

                        if($scope.periodIndicator.key === 'fiscalYearToDate')
                            fiscalYearService
                                .getFiscalYearStartMonth(subscriberId)
                                .then(function(fiscalMonth) {
                                    if(moment().month() >= fiscalMonth)
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month');
                                    else
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month').subtract(1, 'year');

                                    $scope.widget.defaultInitialization();
                                });

                        $scope.dates.periodStartDate   = !$scope.widget.instance.settings.customDate ? dates.startDate : $scope.widget.instance.settings.customDate.startDate;
                        $scope.dates.lastDeliveryDate   = !$scope.widget.instance.settings.customDate ? dates.endDate : $scope.widget.instance.settings.customDate.endDate;
                    }

                    return queryDeliveryPerformance();
                })
                .then(function(response) {
                    if (!response || !response.data || response.data.deliveryCount === 0) {
                        $scope.widget.noContentFlag = true;
                        $scope.widget.instance.settings.customDate = null;
                        return null;
                    }

                    $scope.product.kpis = response.data;
                    $scope.widget.setDefaultActionModel($scope.dates);
                });

            return $scope.widget.promises.monitor(promise);
        }

        function reloadWidget() {
            $scope.widget.noContentFlag = false;

            var promise = queryDeliveryPerformance()
                .then(function(response) {
                    if (!response || !response.data || response.data.deliveryCount === 0) {
                        $scope.widget.noContentFlag = true;
                        // $scope.widget.instance.settings.customDate = null;
                        return null;
                    }

                    $scope.product.kpis = response.data;
                    $scope.widget.setDefaultActionModel($scope.dates);
                });
            $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(loadWidget);

        function queryDeliveryPerformance() {

            return $http({
                url: ('api/subscribers/{0}/batches/deliveries/summary').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    divisionIds : divisionIds,
                    productIds      : $scope.widget.instance.settings.productIds.value,
                    periodIndicator : $scope.periodIndicator.key,
                    startDate       : $scope.dates.periodStartDate ? $scope.dates.periodStartDate.format() : null,
                    endDate         : $scope.dates.lastDeliveryDate ? $scope.dates.lastDeliveryDate.format() : null,
                    minTankSize     : $scope.widget.instance.settings.minTankSize.value,
                    maxTankSize     : $scope.widget.instance.settings.maxTankSize.value,
                    minUnits        : $scope.widget.instance.settings.minUnits.value,
                    maxUnits        : $scope.widget.instance.settings.maxUnits.value,
                    deliveryTypeIds : $scope.widget.instance.settings.deliveryTypeIds.value,
                    usePostingDate: $scope.widget.instance.settings.usePostingDate.value,
                    deliveryTypeUsed: $scope.widget.instance.settings.deliveryTypeUsed.value,
                    monitoredTanks  :constantsService.includeExcludeOnlyOptions[$scope.widget.instance.settings.monitoredTanks.value]
                }
            });
        }

        $scope.openFilteredReport = function(metricCategoryId) {
            var selectedFilter = {
                metricCategoryId: metricCategoryId
            };
            $scope.widget.openDetailView(actionViewTemplates.deliveryPerfDefault, selectedFilter);
        };

        $scope.$on('widgetSettingsChanged', function() {
            divisionIds = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
            $scope.periodIndicator = _.find(constantsService.periodIndicators, function(indicator) {
                return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
            });

            delete $scope.dates;

            loadWidget();
        });

        $scope.openDatePicker = function() {
            return lastDeliveryDateService
                .getLastDeliveryDateForDivisions(divisionIds)
                .then(function(lastDeliveryDate) {
                    return datePickerService.openDatePickerModal(
                        $scope.widget.instance.settings.periodIndicatorId.value == constantsService.periodIndicators.lastDeliveryDate ? null : $scope.dates.periodStartDate,
                        $scope.dates.lastDeliveryDate,
                        lastDeliveryDate);
                })
                .then(function(resultDates) {
                    if (resultDates) {
                        $scope.dates.periodStartDate = moment(resultDates[0]).isValid() ? resultDates[0] : resultDates[1];
                        $scope.dates.lastDeliveryDate = resultDates[1];

                        reloadWidget();

                        $scope.periodIndicator = ($scope.widget.instance.settings.periodIndicatorId.value == constantsService.periodIndicators.customDate ||
                            $scope.widget.instance.settings.periodIndicatorId.value == constantsService.periodIndicators.lastDeliveryDate) ?
                            constantsService.periodIndicators.customDate : constantsService.periodIndicators.customDateRange;
                    }
                });
        };

        $scope.getDateTooltipText = function() {
            if(!$scope.dates) return;
            if ($scope.widget.instance.settings.periodIndicatorId.value != constantsService.periodIndicators.lastDeliveryDate && $scope.dates.periodStartDate) {
                return $scope.dates.periodStartDate.format('L') + ' - ' + $scope.dates.lastDeliveryDate.format('L');
            }

            return $scope.dates.lastDeliveryDate ? $scope.dates.lastDeliveryDate.format('L') : '';
        };

        $scope.openGraphView = function(propertyName) {
            var scope = {
                propertyName: propertyName,
                getGraphValues: function() {
                    return $http({
                        url: ('api/subscribers/{0}/batches/deliveries/summary/history').format($rootScope.user.subscriberId),
                        method: 'POST',
                        data: {
                            deliveryCenters  : divisionIds,
                            productIds       : $scope.widget.instance.settings.productIds.value,
                            startDate        : $scope.dates.periodStartDate ? $scope.dates.periodStartDate.format() : null,
                            endDate          : $scope.dates.lastDeliveryDate.format(),
                            lastDeliveryDate : $scope.dates.lastDeliveryDate.format(),
                            minTankSize      : $scope.widget.instance.settings.minTankSize.value,
                            maxTankSize      : $scope.widget.instance.settings.maxTankSize.value,
                            minUnits         : $scope.widget.instance.settings.minUnits.value,
                            maxUnits         : $scope.widget.instance.settings.maxUnits.value,
                            deliveryTypeIds  : $scope.widget.instance.settings.deliveryTypeIds.value,
                            deliveryTypeUsed : $scope.widget.instance.settings.deliveryTypeUsed.value,
                            byWeek           : true,
                            usePostingDate   : $scope.widget.instance.settings.usePostingDate.value
                        }
                    });
                }
            };

            modalService
                .openTopBarModal(actionViewTemplates.modalGraphContainer, scope);
        };

        $scope.openDeliveryEfficiencyReport = function() {
            var periodStartDate = moment($scope.dates.lastDeliveryDate);
            var scope = {
                widgetName: 'Delivery Efficiency',
                widgetIcon: 'line-chart',
                widgetCode: 'deliveryEff',
                model: {
                    productIds       : $scope.widget.instance.settings.productIds.value,
                    hierarchyNodeId  : $scope.widget.instance.settings.hierarchyNodeId,
                    lastDeliveryDate : $scope.dates.lastDeliveryDate,
                    periodStartDate  : periodStartDate.subtract(1, 'years'),
                    minTankSize      : $scope.widget.instance.settings.minTankSize.value,
                    maxTankSize      : $scope.widget.instance.settings.maxTankSize.value,
                    minUnits         : $scope.widget.instance.settings.minUnits.value,
                    maxUnits         : $scope.widget.instance.settings.maxUnits.value,
                    deliveryTypeIds  : $scope.widget.instance.settings.deliveryTypeIds.value
                }
            };

            modalService
                .openActionView(actionViewTemplates.deliveryEffDefault, scope, 'deliveryEff');
        };

        $scope.openTankUtilizationReport = function() {
            var periodStartDate = moment($scope.dates.lastDeliveryDate);
            var scope = {
                widgetName: 'Propane Tank Utilization',
                widgetIcon: 'line-chart',
                widgetCode: 'tankUtil',
                model: {
                    productIds       : $scope.widget.instance.settings.productIds.value,
                    hierarchyNodeId  : $scope.widget.instance.settings.hierarchyNodeId,
                    lastDeliveryDate : $scope.dates.lastDeliveryDate,
                    periodStartDate  : periodStartDate.subtract(3, 'years'),
                    minTankSize      : $scope.widget.instance.settings.minTankSize.value,
                    maxTankSize      : $scope.widget.instance.settings.maxTankSize.value,
                    minUnits         : $scope.widget.instance.settings.minUnits.value,
                    maxUnits         : $scope.widget.instance.settings.maxUnits.value,
                    deliveryTypeIds  : $scope.widget.instance.settings.deliveryTypeIds.value
                }
            };

            modalService
                .openActionView(actionViewTemplates.tankUtilDefault, scope, 'tankUtil');
        };
    }
]);