angular.module('angus.services')
    .factory('contactTypes', [
        '$http', function($http) {
            return {
                getContactTypes: function() {
                    $http.get('api/contactTypes/')
                        .success(function(data) {
                            return data;
                        })
                        .error(function() {
                            return { list: [] };
                        });

                }
            };
        }
    ]);