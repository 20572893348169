angular.module('angus.services').factory('editProfileService', [
    '$rootScope', '$http', function ($rootScope, $http) {
        return {
            updatePassword: function (currentPassword, newPassword, confirmNewPassword, successCb, failureCb) {
                var data = {
                    userId: $rootScope.user.userId,
                    currentPassword: currentPassword,
                    newPassword: newPassword
                };

                $http.post('/identity/change-password', data, {apiV2: true})
                .success(function() {
                    successCb();
                }).error(function(err) {
                    failureCb(err);
                });
            }
        };
    }
]);

