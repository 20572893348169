angular.module('angus.directives')
    .directive("loadDataSelect", [
            '$rootScope',
            function($rootScope) {
                return {
                    scope: {
                        restrict: 'E',
                        ngModel: '=',
                        link: function(scope, element, attrs) {
                            $(element).select2({
                                minimumInputLength: 1,
                                query: function(query) {
                                    var data = { results: [] }, i, j, s;
                                    for (i = 1; i < 5; i++) {
                                        s = "";
                                        for (j = 0; j < i; j++) {
                                            s = s + query.term;
                                        }
                                        data.results.push({ id: query.term + i, text: s });
                                    }

                                }
                            });

                        }
                    }
                };
            }
        ]
    );