angular.module('angus.directives').directive('deliveryForecasting', [
    'actionViewTemplates',
    function (actionViewTemplates) {
        return {
            scope: {
                model: "=actionModel",
                settings: "=",
                widgetCode: "="
            },
            templateUrl: actionViewTemplates.deliveryForecastingReportContainer
        }
    }
]);