angular.module('angus.controllers').controller('performanceUploadCtrl', [
    '$rootScope', '$scope', '$http', 
    function($rootScope, $scope, $http) {
        'use strict';

        console.log('performanceUploadCtrl $scope ---------------', $scope);
        console.log('performanceUploadCtrl $rootScope.user---------------', $rootScope.user);
 
        var records = []; 

        //api/subscribers/:subscriberId/customerAccounts/robinson/import 
        $scope.importCustomers = function() {
            console.log('importCustomers');
            // var params = {
            //     subscriberId 	: $rootScope.user.subscriberId,
            //     dealerId        : $rootScope.user.hierarchy.dealer.nodeId,
            //     records 		: records,
            //     insertUser      : $rootScope.user.username
            // }
            // console.log(22, params);
            // var rowPromise = $http({            
            //     url: ('api/subscribers/{0}/customerAccounts/robinson/import').format($rootScope.user.subscriberId),
            //     method: 'POST',
            //     data: params
            // }).then(function(result){                 
            //     return result.data;
            // });    
        }  
    }
]);