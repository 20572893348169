angular.module( 'angus.filters' ).filter( 'customNoOverlap', [ '_',
	function( _ ) {
		return function( listToSelectFrom, callbackFn, inputArray ) {
			if ( callbackFn ) {
				return _.filter(listToSelectFrom, function(listItem){
						return callbackFn(inputArray);
					});
			} else return listToSelectFrom;
		};
	}
] );