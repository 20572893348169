angular.module( 'angus.directives' ).directive( 'driverPerf', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		return {
			scope: {
				model: "=actionModel",
				settings: "=driverPerfWidgetSettings",
				widgetCode : "="
			},
			templateUrl: actionViewTemplates.driverPerfReportContainer
		};
	}
] );