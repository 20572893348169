angular.module('angus.directives')
  .directive('subscriberDealerSelect', [function () {
    'use strict';

    return {
      restrict: 'A',
      scope: {
        ngModel: '=',
        subscriberId: '=',        
      },
      controller: ['$scope', '$rootScope', '$http', function ($scope, $rootScope, $http) {
          $http
            .get(('api/subscribers/{0}/dealers').format($scope.subscriberId || $rootScope.user.subscriberId))
              .then(function(dealers) {
                  $scope.dealers = dealers.data;
              });

            $scope.selectNone = function(){
            	$scope.ngModel = undefined;
            };
      }],
      template: '<select class="form-control" ng-model="ngModel" ng-options="dealer.id as dealer.name for dealer in dealers"></select><div ng-show="ngModel"><small><a href="" ng-click="selectNone()">Select None</a></small></div>',      
    };
  }]);
