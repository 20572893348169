angular.module('angus.directives').directive('dropDown',
[
    '$document',
function ($document) {
        return {
            restrict: 'A',
            link: function (scope, element, attr, ctrl) {

                var ddContainer = $document.find(attr.dropDown);
                var isOpen = false;

                element.bind('click', function (e) {
                    e.stopPropagation();

                    if (isOpen) {
                        ddContainer.removeClass('active');
                        $document.off('click');
                    } else {
                        ddContainer.addClass('active');
                        $document.on('click', docClicked);
                    }
                        
                    isOpen = !isOpen;
                });

                function docClicked () {
                    if (isOpen)
                        ddContainer.removeClass('active');

                    isOpen = false;
                };
            }
        };
    }
]);