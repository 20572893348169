angular.module('angus.directives')
  .controller('FancyDropdownController', ['$scope', '$element', function ($scope, $element) {
    'use strict';
    var that = this;

    function buildTree(collection, selection, tree, lineage, parent, isParentSelected, depth) {
// console.log("thisi s the collection", collection)
      selection = [].concat(selection);
      tree = tree || {map: {}, label: []};
      lineage = lineage || [];
      depth = depth || 1;

      if(!collection) {
        if (!parent) {
          tree.label = tree.label.join(", ");
        }
        return tree;
      }

      for (var i = 0, length = collection.length; i < length; i++) {

        var node = collection[i];
        var id = node[$scope.idField || 'id'];

        var branch = tree.map[id] || (tree.map[id] = {});

        branch.label = node.name;
        branch.id = id;
        branch.depth = depth;
        branch.selected = selection.indexOf(id) !== -1;
        branch.isParentSelected = isParentSelected;
        branch.lineage = lineage;
        branch.forebears = false;
        branch.children = [];
        branch.ancestry = [];
        branch.parent = parent;

        if (branch.selected) {
          tree.label.push(branch.label);
        }

        for (var v = 0, vLength = branch.lineage.length; v < vLength; v++) {
          var ancestor = branch.lineage[v];

          if (branch.selected || branch.isParentSelected) {
            ancestor.forebears = true;
          }

          ancestor.ancestry.push(branch);
        }

        if (parent) {
          parent.children.push(branch);
        }

        if (node.hasOwnProperty("children") && Array.isArray(node.children)) {
          var subLineage = lineage.slice();
          subLineage.push(branch);

          buildTree(node.children, selection, tree, subLineage, branch, branch.selected || isParentSelected, depth + 1);
        }
      }

      if (!parent) {
        tree.label = tree.label.join(", ");
      }

      return tree;
    }

    function mutateCollection(collection, element, remove) {
      var index = collection.indexOf(element);

      if (arguments.length < 3) {
        remove = index !== -1;
      }

      if (!remove) {
        if (index === -1)
          collection.push(element);
      } else {
        if (index !== -1)
          collection.splice(index, 1);
      }
    }

    function normalize(selected) {
      if (!selected.length) {
        return;
      }

      var nodes = Object.values(that.tree.map).sort(function (a, b) {
        // deepest nodes first
        return b.depth - a.depth;
      });

      for (var i = 0; i < nodes.length; i++) {
        var item = nodes[i];

        if (!item.children.length) {
          // not a parent, skip the normalize
          continue;
        }

        // check if all of children are in selected
        var allChildrenSelected = true;

        for (var v = 0; v < item.children.length; v++) {
          if (selected.indexOf(item.children[v].id) === -1) {
            allChildrenSelected = false;
            break;
          }
        }

        if (allChildrenSelected) {
          // deselect all children of parent
          for (var v = 0, vLength = item.children.length; v < vLength; v++) {
            mutateCollection(selected, item.children[v].id, true);
          }
          // select parent
          mutateCollection(selected, item.id, false);
        }
      }
    }

    function clean(map, collection) {
      return [].concat(collection).filter(function(entry) {
        return map.hasOwnProperty(entry);
      });
    }

    this.appendToEl = $scope.appendTo ? angular.element($element.closest($scope.appendTo)) : undefined;

    this.tree = buildTree(
      $scope.collection,
      $scope.selection
    );

    if($scope.allowMultiSelect) {
      $scope.selection = clean(this.tree.map, $scope.selection);
    }

    this.isSelected = function (id, isParentSelected) {
      return id && that.tree.map.hasOwnProperty(id) && (that.tree.map[id].selected || (isParentSelected && that.tree.map[id].isParentSelected));
    };

    this.forebears = function (id) {
      return id && that.tree.map.hasOwnProperty(id) && that.tree.map[id].forebears;
    };

    $scope.$watch('controlDisabled', function (value) {
      that.controlDisabled = value;
    });

    $scope.$watch('selection', function (selection) {
      that.tree = buildTree(
        $scope.collection,
        selection
      );
    });

    this.selectValue = function (event, member) {
      var id = member[$scope.idField || 'id'];

      if (!$scope.allowMultiSelect && that.isSelected(id)) {
        return true;
      }

      if ($scope.allowMultiSelect) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (member.id === 'root') {
        member.name = event.currentTarget.innerText;
      }

      if ($scope.allowMultiSelect) {
        var collection = [].concat($scope.selection);
        var branch = that.tree.map[id];

        if (!branch.selected && !branch.isParentSelected) {
          for (var i = 0, length = branch.ancestry.length; i < length; i++) {
            // deselect all children and their children
            mutateCollection(collection, branch.ancestry[i].id, true);
          }
        }
        if (!branch.selected && branch.isParentSelected) {
          for (var i = 0, length = branch.lineage.length; i < length; i++) {
            // deselect all parents and their parents
            mutateCollection(collection, branch.lineage[i].id, true);

            if (branch.lineage[i].selected || branch.lineage[i].isParentSelected) {
              // select all children if originally selected
              for (var v = 0, vLength = branch.lineage[i].children.length; v < vLength; v++) {
                // select parent siblings
                if (branch.lineage[i].children[v].id === branch.id) {
                  continue;
                }

                mutateCollection(collection, branch.lineage[i].children[v].id, false);
              }
            }
          }

        } else {
          // simply toggle
          mutateCollection(collection, id);

        }

        normalize(collection);

        $scope.selection = collection;
      } else {

        $scope.selection = id;
      }

      if ($scope.changeEvent) {
        $scope.changeEvent({
          member: member,
          state: that.tree.map[id]
        });
      }

      return false;
    };

  }])
  .directive('fancyDropDown', function () {
    'use strict';

    return {
      restrict: 'E',
      controller: 'FancyDropdownController',
      controllerAs: 'vm',
      templateUrl: 'templates/global/directives/fancy-select-group.html',
      scope: {
        collection: '=',
        idField: '=',
        allowMultiSelect: '=',
        selection: '=ngModel',
        controlClass: '@',
        controlDisabled: '=ngDisabled',
        changeEvent: '&ngChange',
        appendTo: '=',
        ngDisabled: '='
      }
    };
  });
