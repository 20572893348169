angular.module('angus.directives')
.directive('flotColumnChartTmsDeliveryEfficiency', [
  '_',
  function(_) {
    //'use strict';
    return {
      scope: {
        ngModel : '='
      },
      link: function(scope, element){

        function plot(ranges){

          var data = _.map(ranges, function(range){
            return [range.id, range.percentOfDeliveries];
          });

          var dataSet = [{
            label: '% of All Deliveries',
            data: data,
            color: '#5482FF'
          }];

          var ticks = _.map(ranges, function(range){
            return [range.id, range.name];
          });

          var options = {
            series: {
              bars: {
                show: true
              }
            },
            bars: {
              align: "center",
              barWidth: 0.5
            },
            xaxis: {
              ticks: ticks,
              tickLength: 0,
            },
            yaxis: {
            },
            legend: {},
            grid: {
              hoverable: true,
              borderWidth: 0
            }
          };

          $.plot(element, dataSet, options);
        }

        scope.$watch(function(){
          return scope.ngModel;
        }, function(newVal){
          if (newVal){
            plot(newVal);
          }
        })
      }
    }
  }
]);
