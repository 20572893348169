angular.module('angus.controllers').controller('gainsToBudgetController', [
    '$scope', '$rootScope', '$http', 'hierarchyService', 'moment', '$q', 'modalService', 'actionViews', 'actionViewTemplates', '_',
    function ($scope, $rootScope, $http, hierarchyService, moment, $q, modalService, actionViews, actionViewTemplates, _) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;
        $scope.dataAvaliableForChart = true;

        function getGainsToBudgetSummary() {
            return $http({
                method: 'POST',
                url: ('api/subscribers/{0}/budgets/gainsToBudget/summary').format(subscriberId),
                data: {
                    divisionIds: [$scope.widget.instance.settings.hierarchyNodeId],
                    productCategoryIds: [4, 5, 6, 7, 8, 9], // spes product keys that have propane flag set
                    tradeClassCategoryIds: [1, 2], // spes residential and commercial trade classes
                    startDate: moment().startOf('year').format('YYYY-MM-DD'),
                    endDate: moment().endOf('year').format('YYYY-MM-DD'),
                    minTankSize: 120
                }
            })
                .then(function (response) {
                    return response.data;
                });
        }

        function getGainsToBudgetResidentialGoals() {
            return $http({
                method: 'GET',
                url: ('api/subscribers/{0}/budgets/gainsToBudget/residentialGoals').format(subscriberId),
                params: {
                    hierarchyNodeId: $scope.widget.instance.settings.hierarchyNodeId,
                    fiscalYear: moment().year()
                }
            })
                .then(function (response) {
                    return response.data;
                });
        }

        function getGainsToBudgetCommercialGoals() {
            return $http({
                method: 'GET',
                url: ('api/subscribers/{0}/budgets/gainsToBudget/commercialGoals').format(subscriberId),
                params: {
                    hierarchyNodeId: $scope.widget.instance.settings.hierarchyNodeId,
                    fiscalYear: moment().year()
                }
            })
                .then(function (response) {
                    return response.data;
                });
        }

        function getGainsToBudgetLeadGoals() {
            return $http({
                method: 'GET',
                url: ('api/subscribers/{0}/budgets/gainsToBudget/leadGoals').format(subscriberId),
                params: {
                    hierarchyNodeId: $scope.widget.instance.settings.hierarchyNodeId,
                    fiscalYear: moment().year()
                }
            })
                .then(function (response) {
                    return response.data;
                });
        }

        function init() {
            $scope.dataAvaliableForChart = true;
            return $q.all([
                getGainsToBudgetSummary(),
                getGainsToBudgetResidentialGoals(),
                getGainsToBudgetCommercialGoals(),
                getGainsToBudgetLeadGoals()
            ])
                .spread(function (gainsToBudgetSummary, residentialGoals, commercialGoals, leadGoals) {

                    if (residentialGoals.length > 0 || commercialGoals.length > 0) {

                        var formattedGainsToBudget = gainsToBudgetFormat(gainsToBudgetSummary.residentialAndCommercial);

                        var formattedLeads = gainsToBudgetSummary.leads.sort(function (a, b) {
                            return a.gainMonth - b.gainMonth;
                        }).map(function (lead) {
                            return lead.gainCount
                        });

                        var residentialGoalsFilter = residentialGoals.length == 1 ? residentialGoals : residentialGoals.filter(function (goal) {
                            return goal.hierarchyNodeId == $scope.widget.instance.settings.hierarchyNodeId;
                        });

                        //creates the Residential chart's series object
                        var formattedResidentialChartSeries = chartSeriesFormat(residentialGoalsFilter[0].units, formattedGainsToBudget.residential, 'residentialChart', 'Residential Tanks Gained', 1);

                        //Calculating the totals for residential Actual, goal, and percentage to goal
                        $scope.residentialActualTotal = formattedGainsToBudget.residential.reduce(function (a, b) {
                            return a + b;
                        });

                        $scope.residentialGoalTotal = formattedResidentialChartSeries.goal.data.reduce(function (a, b) {
                            return a + b;
                        });

                        var commercialGoalsFilter = commercialGoals.length == 1 ? commercialGoals : commercialGoals.filter(function (goal) {
                            return goal.hierarchyNodeId == $scope.widget.instance.settings.hierarchyNodeId;
                        });

                        //creates the Commercials chart's series object
                        var formattedCommercialChartSeries = chartSeriesFormat(commercialGoalsFilter[0].units, formattedGainsToBudget.commercial, 'commercialChart', 'Commercial Tanks Gained', 2);

                        //Calculating the totals for commercial Actual, goals, and percentage to goal
                        $scope.commercialActualTotal = formattedGainsToBudget.commercial.reduce(function (a, b) {
                            return a + b;
                        });

                        $scope.commercialGoalTotal = formattedCommercialChartSeries.goal.data.reduce(function (a, b) {
                            return a + b;
                        });

                        var leadGoalsFilter = leadGoals.length == 1 ? leadGoals : leadGoals.filter(function (goal) {
                            return goal.hierarchyNodeId == $scope.widget.instance.settings.hierarchyNodeId;
                        });

                        //creates the lead chart's series object
                        var formattedLeadsChartSeries = chartSeriesFormat(leadGoalsFilter[0].units, formattedLeads, 'leadChart', 'Leads Gained', 3);

                        //Calculating the totals for lead Actual, goals, and percentage to goal
                        $scope.leadActualTotal = formattedLeads.reduce(function (a, b) {
                            return a + b;
                        });

                        $scope.leadGoalTotal = formattedLeadsChartSeries.goal.data.reduce(function (a, b) {
                            return a + b;
                        });


                        $(document).ready(function () {
                            createChart([formattedResidentialChartSeries, formattedCommercialChartSeries, formattedLeadsChartSeries]);
                        });

                        $(document).bind('kendo:skinChange', createChart);

                    } else {
                        $scope.dataAvaliableForChart = false;
                    }

                });
        }

        $scope.widget.setDefaultInitialization(init);
        $scope.reloadWidget = function () {
            $scope.widget.promises.monitor(init())
        };
        $scope.saveDashboardHierarchyNodeDropdownSettings = function (save) {
            if (save) {
                $scope.reloadWidget();
                $scope.widget.saveSettings();
            } else {
                $scope.reloadWidget();
            }
        };

        function chartSeriesFormat(arrayOfGoal, arrayOfActual, chartName, graphTitle, tradeClassId) {
            var seriesActual = {
                name: 'Actual',
                tradeClass: tradeClassId,
                labels: {
                    visible: true,
                    position: "insideBase",
                    background: "orchid",
                    rotation: -90,
                    font: "12px tahoma;"
                },
                color: 'orchid',
            };
            var seriesGoal = {
                name: 'Goal',
                tradeClass: tradeClassId,
                labels: {
                    visible: true,
                    color: 'black',
                    font: '11px tahoma;',
                    position: 'outsideEnd',
                    template: function (item) {
                        var variance = item.series.variance[item.series.residentialIndex];
                        item.series.residentialIndex++;

                        return 'Goal: ' + item.value + '\nDiff: ' + variance;
                    }
                },
                spacing: -1,
                gap: 0.75,
                color: 'mistyrose',
                opacity: 0.5,
                residentialIndex: 0
            };

            seriesActual.data = arrayOfActual;
            seriesGoal.data = Object.values(arrayOfGoal).reverse();
            seriesGoal.variance = seriesGoal.data.map(function (goal, index) {
                return arrayOfActual[index] - goal;
            });

            return {
                name: chartName,
                goal: seriesGoal,
                actual: seriesActual,
                title: graphTitle
            };
        }

        function gainsToBudgetFormat(arrayGainToBudget) {
            var arrayOfResidential = [];
            var arrayOfCommercial = [];
            arrayGainToBudget.sort(function (a, b) {
                return a.gainMonth - b.gainMonth;
            }).forEach(function (value) {
                if (value.tradeClassKey == 1) {
                    arrayOfResidential.push(value.gainCount);
                } else if (value.tradeClassKey == 2) {
                    arrayOfCommercial.push(value.gainCount);
                }
            });

            return {
                commercial: arrayOfCommercial,
                residential: arrayOfResidential
            };
        }

        function createChart(chartsArray) {
            chartsArray.forEach(function (chart) {
                $('#' + chart.name).kendoChart({
                    title: {
                        text: 'Superior Plus Energy Services: ' + chart.title
                    },
                    legend: {
                        position: 'bottom'
                    },
                    seriesDefaults: {
                        type: 'column'
                    },
                    series: [chart.goal, chart.actual],
                    seriesClick: function (e) {
                        if (e.series.tradeClass == 1 || e.series.tradeClass == 2) {
                            $scope.widget.instance.settings.month = e.category;
                            $scope.widget.instance.settings.tradeClass = e.series.tradeClass;
                            $scope.widget.openDetailView(actionViewTemplates.gainsToBudgetContainer);
                        }
                    },
                    categoryAxis: {
                        categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                        majorGridLines: {
                            visible: false
                        },
                        labels: {
                            rotation: "auto"
                        }
                    },
                    valueAxis: {
                      visible: true
                    },
                    tooltip: {
                        visible: true,
                        format: '{0}%',
                        template: '#= series.name #: #= value #'
                    }
                });
            });

            $('.widget').on('resize', function () {
                kendo.resize($('.chart-wrapper'));
            });
        }
    }
]);