angular.module('angus.directives').directive('adeptExecutions', [
    'actionViewTemplates', 
    function(actionViewTemplates) {
        return {
            scope: {
                settings: "=",
            },
            restrict: 'E',
            templateUrl: actionViewTemplates.adeptExecutions
        }
    }
])