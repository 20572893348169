function containerSelectController(containerService) {
    var $ctrl = this;

    $ctrl.handleContainerSelected = handleContainerSelected;

    $ctrl.$onInit = function () {
        if ($ctrl.subscriberId && $ctrl.customerKey) {
            loadContainers();
        }
    }

    $ctrl.$onChanges = function (changes) {
        /* If the Subscriber Changes, but the CustomerKey doesn't then just clear the component */
        if (changes.subscriberId && !changes.customerKey){
            clearComponent();
        }
       
        /* CustomerKey Changed. Load the containers for that new Customer */
        if (changes.customerKey) {
            loadContainers();
        }        
    }
        

    function clearComponent() {
        $ctrl.containers = null;
        $ctrl.showNoResults = false;
        $ctrl.selectedContainerKey = null;
    }

    function loadContainers() {
        const selectedContainerKey = $ctrl.selectedContainerKey;

        clearComponent();        

        /* Don't attempt to load Containers if we don't have a Subscriber or Customer */
        if (!$ctrl.subscriberId || !$ctrl.customerKey) {
            $ctrl.containers = null;
            $ctrl.showNoResults = false;
            return;
        }


        /* Load Containers */
        $ctrl.areContainersLoading = true;
        
        containerService.getForCustomerKey($ctrl.subscriberId, $ctrl.customerKey)
            .then(function (containers) {
                /* No tanks found */
                if (!containers || containers.length === 0) {
                    $ctrl.showNoResults = true;
                    $ctrl.areContainersLoading = false;
                    return;
                }

                /* Map the results to an object specifically for the Container Select */
                $ctrl.containers = containers.map(function (container) {
                    return {
                        containerKey: container.containerKey,
                        display: `Loc: ${container.customerLocationId} - Tank: ${container.containerId} - ${container.productName} (${container.capacity})`
                    };
                });

                /* If a container was previously selected, then attempt to select it again. */
                if (selectedContainerKey) {
                    selectContainer(selectedContainerKey);
                }

                /* If a customer only has one Container then default the Container Select to that Container */
                if (!$ctrl.selectedContainerKey && $ctrl.containers.length === 1) {
                    selectContainer($ctrl.containers[0].containerKey);
                }

                $ctrl.areContainersLoading = false;
            });
    }

    function selectContainer(containerKey) {
        const container = $ctrl.containers.find(function(container) {
            return container.containerKey === containerKey;
        });

        if (container) {
            $ctrl.selectedContainerKey = container.containerKey;
        }
        
        if ($ctrl.onSelect) {
            $ctrl.onSelect({newContainer: container});
        }
    }

    function handleContainerSelected() {
        selectContainer($ctrl.selectedContainerKey);
    }
}

angular
    .module('angus.components')
    .component('containerSelect', {
        templateUrl: 'templates/global/components/containerSelect/containerSelect.component.html',
        controller: containerSelectController,
        bindings: {
            subscriberId: '<',
            customerKey: '<',
            onSelect: '&',
            selectedContainerKey: '<',
        }
    });