angular.module('angus.controllers').controller('salesReportSettingsController', [
    '$scope', '$rootScope', '$http', 'moment', '_', 'hierarchyService', 'periodIndicators', 'constantsService',
    function($scope, $rootScope, $http, moment, _, hierarchyService, periodIndicators, constantsService) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;
        $scope.periodIndicators = constantsService.invoicePeriodIndicators.enums;
        $scope.widget.instance.settings.periodIndicatorId = $scope.widget.instance.settings.periodIndicatorId || {};

        $scope.widget.instance.settings.title = $scope.widget.instance.settings.title || '';
        $scope.widget.instance.settings.items = $scope.widget.instance.settings.items || {};

        $scope.updateCategoriesSubCategories = function(model) {
            if(model) $scope.widget.instance.settings.items = angular.copy(model);
        };

        $scope.salesItems = [];

        $scope.categoryNames = [];
        $scope.subCategoryNames = [];
        $scope.categories = {};

        function init() {
            return $http.get(('api/subscribers/{0}/margins/categories').format(subscriberId))
                .then(function(categories) {
                    $scope.categoriesSubCategories = categories.data;
                    _.forEach(categories.data, function(category) {
                        $scope.salesItems.push({
                            categoryKey: category.categoryKey,
                            categoryName: category.categoryName,
                            subCategoryKey: category.subCategoryKey,
                            subCategoryName: category.subCategoryName
                        });

                        if($scope.categoryNames.indexOf(category.categoryName) == -1) {
                            $scope.categoryNames.push(category.categoryName);
                        }

                        if($scope.subCategoryNames.indexOf(category.subCategoryName) == -1) {
                            $scope.subCategoryNames.push(category.subCategoryName);
                        }

                        _.forEach($scope.salesItems, function(item) {
                            _.forEach($scope.categoryNames, function(name) {
                                if(name == item.categoryName) {
                                    $scope.categories[item.categoryName] = [];
                                }
                            });
                        });

                        _.forEach($scope.salesItems, function(item) {
                            _.forEach($scope.subCategoryNames, function(subCatNames) {
                                if(item.subCategoryName == subCatNames) {
                                    $scope.categories[item.categoryName].push({
                                        subCategoryKey: item.subCategoryKey,
                                        subCategoryName: subCatNames,
                                        categoryName: item.categoryName,
                                        categoryKey: item.categoryKey
                                    });
                                }
                            });
                        });
                    });

                    $scope.widget.instance.settings.categoryData = [];

                    _.forIn($scope.categories, function(value, key) {
                        $scope.widget.instance.settings.categoryData.push(
                            { name: '<strong>' + key + '</strong>', categoryGroup: true }
                        );

                        _.forEach(value, function(item) {
                            $scope.widget.instance.settings.categoryData.push(
                                {
                                    name: item.subCategoryName,
                                    categoryKey: item.categoryKey,
                                    subCategoryKey: item.subCategoryKey,
                                    ticked: _.some($scope.widget.instance.settings.items, function(setting) {
                                        return setting.categoryKey == item.categoryKey
                                            && setting.subCategoryKey == item.subCategoryKey
                                    })
                                }
                            );
                        });

                        $scope.widget.instance.settings.categoryData.push({ categoryGroup: false });
                    });
                });
        }


        $scope.widget.instance.settings.startDate = new Date(moment($scope.widget.instance.settings.startDate).format('l')) || null;
        $scope.widget.instance.settings.endDate = new Date(moment($scope.widget.instance.settings.endDate).format('l')) || null;

        init();

        $scope.openDivisionHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };

    }
]);