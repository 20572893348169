const degreeDayColor = '#42a5f5';

function buildTooltipContent($filter, metricDate, degreeDays, selectedMetrics, metricSeries, dataIndex, perDegreeDayThreshold){
	const dateString = $filter('date')(metricDate, 'MMMM yyyy');
	const degreeDaysString = $filter('number')(degreeDays ) || 0;


	var tooltipString = `${dateString} <br> Degree Days: ${degreeDaysString}`;

	function buildMetricString (degreeDays, label, data, dataFractionSize, isPerDegreeDayMetric, perDegreeDayThreshold) {
		if (isPerDegreeDayMetric && degreeDays < perDegreeDayThreshold) {
			return `<br> ${label}: Below Threshold`;
		}

		return `<br> ${label}: ${$filter('number')(data || 0, dataFractionSize)}`;
	}

	/* Add entries for each selected Metric */
	selectedMetrics.forEach(metric => {
		const metricSeriesSeries = metricSeries[metric.id].series;

		tooltipString += buildMetricString(degreeDays, metric.label, metricSeriesSeries[dataIndex][1], metric.fractionSize, metric.isPerDegreeDayMetric, perDegreeDayThreshold);

		/* Include any additional metrics for the selected metric*/
		metric.additionalTooltipMetricIds.forEach(metricId => {
			/* Don't include the additional metric if it's already one of the selected metrics */
			if (selectedMetrics.some(metric => metric.id === metricId)) {
				return;
			}

			const additionalMetricSeries = metricSeries[metricId]

			tooltipString += buildMetricString(degreeDays, additionalMetricSeries.metric.label, additionalMetricSeries.series[dataIndex][1], additionalMetricSeries.metric.fractionSize, additionalMetricSeries.isPerDegreeDayMetric, perDegreeDayThreshold);
		});
	});

	return tooltipString;

};

angular.module('angus.directives').directive('degreeDayMetrics', [
	'actionViewTemplates',
	function (actionViewTemplates) {
		return {
			scope: {
				closeCb: "=",
				model: "=actionModel",
				settings: "=",
				widgetCode: "="
			},
			templateUrl: actionViewTemplates.degreeDayMetricsContainerCtrl
		};
	}
]).directive('degreeDayMetricsLineGraph', ['$filter', 'actionViewTemplates',
	function ($filter, actionViewTemplates) {
		return {
			restrict: 'A',
			scope: {
				data: '=',
				metrics: '=',
				selectedMetricId: '=',
				settings: '='
			},
			templateUrl: actionViewTemplates.degreeDayMetricsLineGraph,
			link: function (scope, element, attr) {
				'use strict';

				/* Functions */
				scope.metricClicked = function (clickedMetric) {
					if (clickedMetric.isActive) {
						/* Clicked Metric already enabled. Do nothing */
						return;
					}
					else {
						/* Disable all others */
						scope.metrics.forEach(metric => metric.isActive = false);

						clickedMetric.isActive = true;
					}

					/* The parent has a watch on selectedMetricId and will save the settings automatically anytime it changes. */
					scope.selectedMetricId = clickedMetric.id;
				}


				/* Watches */
				scope.$watch(function () {
					return scope.data;
				}, function () {
					if (scope.data) {
						loadDirective();
					}
				});


				scope.$watch(function () {
					return scope.selectedMetricId;
				}, function () {
					if (scope.data) {
						loadDirective();
					}
				});


				function loadDirective() {
					/* Activate Selected Metric */
					scope.metrics.forEach(metric => {
						if (metric.id === scope.selectedMetricId && !metric.isDisabled) {
							metric.isActive = true;
						} else {
							metric.isActive = false;
						}
					});


					/* We should always have an active metric. If for some reason we don't then just activate the first enabled metric */
					if (!scope.metrics.some(metric => metric.isActive)) {
						const firstEnabledMetric = scope.metrics.find(metric => metric.isAvailable);

						firstEnabledMetric.isActive = true;
						scope.selectedMetricId = firstEnabledMetric.id;
					}

					renderGraph();
				}


				function renderGraph() {
					var degreeDaysSeries = [];

					/* One Property for each metric. Property Name is the metric Id. Each Object will contain the parent Metric object as well as the series of data for the graph */
					var metricSeries = [];

					/* Sort the data by Date. Otherwise the line graphs will be all over the place */
					const data = scope.data.sort((a, b) => a.deliveryYearMonth - b.deliveryYearMonth);			

					data.forEach(row => {
						/* Convert to Seconds */
						const dateParts = row.deliveryMonthYear.split('/');
						const month = parseInt(dateParts[0]);
						const year = parseInt(dateParts[1]);

						const deliveryMonthYeaMilliseconds = new Date(year, month - 1, 1).getTime();

						/* Degree Days Series */
						degreeDaysSeries.push([deliveryMonthYeaMilliseconds, row.heatingDegreeDays || 0]);


						/* Metrics Series */
						scope.metrics.forEach(metric => {
							if (!metricSeries[metric.id]) {
								metricSeries[metric.id] = {
									metric: metric,
									series: []};
							}

							/* Add a data point to the Series Array */
							metricSeries[metric.id].series.push([deliveryMonthYeaMilliseconds, row[metric.columnName] || 0]);
						});
					});

					var selectedMetric = scope.metrics.find(metric => metric.isActive && metric.isAvailable);

					const legend = {
						noColumns: 2,
						container: element.find('#degreeDayMetricsGraphLegend'),
						sorted: "reverse"
					};

					const yaxes = [
						{
							show: true,
							position: 'right',
							min: 0
						},
						{
							show: true,
							position: 'left',
							min: 0
						}
					];

					const xaxis = {
						mode: 'time',
						timeBase: 'milliseconds',
						timeformat: '%m/%Y',
						tickLength: 0
					};

					function tooltipContent(label, xval, yval, flotItem) {
						const perDegreeDayThreshold = scope.settings.perDegreeDayThreshold.value;
						return buildTooltipContent($filter, xval, degreeDaysSeries[flotItem.dataIndex][1], [selectedMetric], metricSeries, flotItem.dataIndex, perDegreeDayThreshold);
					}

					const options = {
						legend: legend,
						yaxes: yaxes,
						xaxis: xaxis,
						grid: {
							borderWidth: 0,
							hoverable: true
						},
						tooltip: {
							show: true,
							content: tooltipContent,
							defaultTheme: false
						}
					};

					const series = [
						{
							label: selectedMetric.label,
							color: selectedMetric.color,
							data: metricSeries[selectedMetric.id].series,
							yaxis: 1,
							bars: {
								show: true,
								fill: 1,
								align: "center",
								barWidth: 864000000
							},

						},
						{
							label: "Degree Days",
							color: degreeDayColor,
							data: degreeDaysSeries,
							yaxis: 2,
							lines: {
								show: true,
								fill: false
							},
							points: {
								show: true,
								fill: true,
								fillColor: "rgba(255, 255, 255, 1)",
								radius: 4
							},

						}
					];

					$.plot(element.find('#degreeDayMetricsGraph'), series, options);
				}
			}
		}
	}
]).directive('degreeDayMetricsBarGraph', ['$filter', 'actionViewTemplates',
	function ($filter, actionViewTemplates) {
		return {
			restrict: 'A',
			scope: {
				data: '=',
				metrics: '=',
				selectedMetricIds: '=',
				settings: '='
			},
			templateUrl: actionViewTemplates.degreeDayMetricsBarGraph,
			link: function (scope, element, attr) {
				'use strict';


				/* Functions */
				scope.metricChanged = function (metric) {
					const idIndex = scope.selectedMetricIds.indexOf(metric.id);

					if (idIndex === -1) {
						scope.selectedMetricIds.push(metric.id);
					} else {
						scope.selectedMetricIds.splice(idIndex, 1)
					}

					scope.selectedMetricIds.sort((a, b) => a - b);
				}


				/* Watches */
				scope.$watch(function () {
					return scope.data;
				}, function () {
					if (scope.data) {
						loadDirective();
					}
				});

				scope.$watchCollection(function () {
					return scope.selectedMetricIds;
				}, function () {
					if (scope.data) {
						loadDirective();
					}
				});


				function loadDirective() {
					/* Activate Selected Metrics */
					scope.metrics.forEach(metric => {
						if (!metric.isDisabled && scope.selectedMetricIds.includes(metric.id)) {
							metric.isActive = true;
						} else {
							metric.isActive = false;
						}
					});

					renderGraph();
				}


				function renderGraph() {
					var degreeDaysSeries = [];

					/* One Property for each metric. Property Name is the metric Id. Each Object will contain the parent Metric object as well as the series of data for the graph */
					var metricSeries = {}; 

					/* Sort the data by Date. Otherwise the line graphs will be all over the place */
					const data = scope.data.sort((a, b) => a.deliveryYearMonth - b.deliveryYearMonth);

					data.forEach(row => {
						/* Convert to Seconds */
						const dateParts = row.deliveryMonthYear.split('/');
						const month = parseInt(dateParts[0]);
						const year = parseInt(dateParts[1]);

						const deliveryMonthYeaMilliseconds = new Date(year, month - 1, 1).getTime();

						/* Degree Days Series */
						degreeDaysSeries.push([deliveryMonthYeaMilliseconds, row.heatingDegreeDays]);

						/* Metrics Series */
						scope.metrics.forEach(metric => {
							if (!metricSeries[metric.id]) {
								metricSeries[metric.id] = {
									metric: metric,
									series: []};
							}

							/* Add a data point to the Series Array */
							metricSeries[metric.id].series.push([deliveryMonthYeaMilliseconds, row[metric.columnName] || 0]);
						});
					});

					
					var selectedMetrics = scope.metrics.filter(metric => metric.isActive && metric.isAvailable);

					/* Sort the selected Metrics so the Legend's order matches the order of the toggles */
					selectedMetrics.sort((a, b) => a.displayOrder - b.displayOrder);
					
					const legend = {
						noColumns: 6,
						container: element.find('#degreeDayMetricsGraphLegend'),
					};

					var yaxes = [{
						show: true,
						position: 'left',
						min: 0
					}];

					const xaxis = {
						mode: 'time',
						timeBase: 'milliseconds',
						timeformat: '%m/%Y',
						tickLength: 0
					};

					function tooltipContent(label, xval, yval, flotItem) {
						const perDegreeDayThreshold = scope.settings.perDegreeDayThreshold.value;
						return buildTooltipContent($filter, xval, degreeDaysSeries[flotItem.dataIndex][1], selectedMetrics, metricSeries, flotItem.dataIndex, perDegreeDayThreshold);
					}

					const options = {
						legend: legend,
						yaxes: yaxes,
						xaxis: xaxis,
						grid: {
							borderWidth: 0,
							hoverable: true
						},
						tooltip: {
							show: true,
							content: tooltipContent,
							defaultTheme: false
						}
					};

					const series = [
						{
							label: "Degree Days",
							data: degreeDaysSeries,
							color: degreeDayColor,
							yaxis: 1,
							bars: {
								show: true,
								fill: 1,
								align: "center",
								barWidth: 864000000
							}
						}
					];

					selectedMetrics.forEach(selectedMetric => {
						/* Add a new Y Axis. Need to do this so we can hide the labels */
						yaxes.push({
							show: selectedMetrics.length === 1, /* Only show labels for metrics if only one metric is selected. */
							position: 'right',
							min: 0
						});


						series.push({
							label: selectedMetric.label,
							data: metricSeries[selectedMetric.id].series,
							color: selectedMetric.color,
							yaxis: yaxes.length, /* yaxis is 1 based. Degree Days were yaxis 1, so we can just use the length to get the last one added to the array */
							lines: {
								show: true,
								fill: false
							},
							points: {
								show: true,
								fill: true,
								fillColor: "rgba(255, 255, 255, 1)",
								radius: 4
							}
						});
					});

					$.plot(element.find('#degreeDayMetricsGraph'), series, options);
				}
			}
		}
	}
])