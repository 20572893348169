angular.module('angus.controllers').controller('avgGalByTankSizeCtrl', [
    '$scope', '$rootScope', '$q', '$http', 'actionViewTemplates', 'constantsService', 'lastDeliveryDateService', 'datePickerService', 'hierarchyService', 'moment', 'yearMonthSelectService', '_',
    function($scope, $rootScope, $q, $http, actionViewTemplates, constantsService, lastDeliveryDateService, datePickerService, hierarchyService, moment, yearMonthSelectService, _) {

      'use strict';

      function getTanks(month, year) {
        return $http({
            method: 'POST',
            url: ('api/subscribers/{0}/tanks/averageGallons').format($rootScope.user.subscriberId),
            data: {
              productIds: $scope.widget.instance.settings.productIds.value,
              deliveryTypeIds: $scope.widget.instance.settings.deliveryTypeIds.value,
              tradeClassIds: $scope.widget.instance.settings.tradeClassIds.value,
              year: year,
              month: month,
              divisionIds: hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId),
                includeZeroGallonDeliveries:$scope.widget.instance.settings.includeZeroGallonDeliveries.value
            }
          })
          .then(function(tanks) {
            return _.sortBy(tanks.data, 'numberOfDeliveries').reverse();
          });
      }

      function init() {
        var nodeIds = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
        var promise = lastDeliveryDateService
            .getLastDeliveryDateForDivisions(nodeIds)
            .then(function(lastDeliveryDate) {
              if (lastDeliveryDate) {
                $scope.selectedYear = lastDeliveryDate.year();
                $scope.selectedMonth = lastDeliveryDate.month() + 1;

                $scope.months = yearMonthSelectService.getMonths($scope.selectedYear);
                $scope.years = yearMonthSelectService.getYears($scope.selectedYear);

                $scope.widget.setDefaultActionModel({
                  month: $scope.selectedMonth,
                  year: $scope.selectedYear
                });

                return getTanks($scope.selectedMonth, $scope.selectedYear);
              } else return [];
            })
            .then(function(tanks) {
              $scope.tanks = tanks;
            });
        return $scope.widget.promises.monitor(promise);
      }


      $scope.widget.setDefaultInitialization(init);

      $scope.yearChanged = function() {

        yearMonthSelectService
          .yearChanged($scope.selectedYear, $scope.selectedMonth)
          .then(function(results) {
            var months = results[0];
            var month = results[1];

            $scope.months = months;

            if (month) $scope.selectedMonth = month;

            $scope.widget.setDefaultActionModel({
              month: $scope.selectedMonth,
              year: $scope.selectedYear
            });

          })
          .then(function() {

            $scope.widget.promises.monitor(
              getTanks($scope.selectedMonth, $scope.selectedYear)
              .then(function(tanks) {
                $scope.tanks = tanks;
              })
            );
          });
      };

      $scope.monthChanged = function() {
        $scope.widget.setDefaultActionModel({
          month: $scope.selectedMonth,
          year: $scope.selectedYear
        });

        $scope.widget.promises.monitor(
          getTanks($scope.selectedMonth, $scope.selectedYear)
          .then(function(tanks) {
            $scope.tanks = tanks;

          })
        );
      };

      $scope.$on('widgetSettingsChanged', function(){
        init();
      });
    }
  ]);
