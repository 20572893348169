angular.module('angus.services').factory('tradeClassService', [
    '$http', '$rootScope', '_', '$q', 'fluentRest', 'serviceHelper',
    function($http, $rootScope, _, $q, fluentRest, serviceHelper) {
        'use strict';
        
        var subscriberTradeClasses    = [];
        var tradeClasses              = [];

        var allSubscriberTradeClasses = [];
        var allTradeClasses           = [];

        function getTradeClass(subscriberId) {
            if (subscriberId) {
                if (subscriberTradeClasses[subscriberId] && subscriberTradeClasses[subscriberId].length > 0)
                    return subscriberTradeClasses[subscriberId];
                else {
                    return queryTradeClass(subscriberId)
                        .then(function(tradeClassList) {
                            subscriberTradeClasses[subscriberId] = tradeClassList.data;
                            return subscriberTradeClasses[subscriberId];
                        });
                }
            } else {
                if (tradeClasses && tradeClasses.length > 0)
                    return tradeClasses;
                else {
                    return queryTradeClass()
                        .then(function(tradeClassList) {
                            tradeClasses = tradeClassList.data;
                            return tradeClasses;
                        });
                }
            }
        }

        function getAllTradeClass(subscriberId) {
            if (subscriberId) {
                if (subscriberTradeClasses[subscriberId] && subscriberTradeClasses[subscriberId].length > 0)
                    return subscriberTradeClasses[subscriberId];
                else {
                    return queryAllTradeClass(subscriberId)
                        .then(function(tradeClassList) {
                            allSubscriberTradeClasses[subscriberId] = tradeClassList.data;
                            return allSubscriberTradeClasses[subscriberId];
                        });
                }
            } else {
                if (tradeClasses && tradeClasses.length > 0)
                    return tradeClasses;
                else {
                    return queryAllTradeClass()
                        .then(function(tradeClassList) {
                            allTradeClasses = tradeClassList.data;
                            return allTradeClasses;
                        });
                }
            }
        }

        function queryTradeClass(subscriberId) {
           return $http.get(('api/subscribers/{0}/tradeClasses/').format(subscriberId || $rootScope.user.subscriberId));
        }

        function queryAllTradeClass(subscriberId) {
           return $http.get(('api/subscribers/{0}/tradeClasses/all').format(subscriberId || $rootScope.user.subscriberId));
        }

        return {
            getTradeClasses: function(subscriberId) {
                return $q.when(getTradeClass(subscriberId))
                    .then(function(TradeClass) {
                        return TradeClass;
                    });
            },
            getAllTradeClasses: function(subscriberId) {
                return $q.when(getAllTradeClass(subscriberId))
                    .then(function(TradeClass) {
                        return TradeClass;
                    });
            },
            formatTradeClassList: function(tradeClassIds) {
                var result = '';

                if (!(tradeClassIds instanceof Array))
                    tradeClassIds = [tradeClassIds.toString()];

                if (tradeClassIds.length > 0) {
                    _.forEach(tradeClasses, function(tc) {
                        if (_.includes(tradeClassIds, tc.id.toString() || _.includes(tradeClassIds, tc.id)))
                            result += tc.name + ', ';
                    });
                }

                return result.substring(0, result.length - 2);
            },
            getBudgetCategories: function (subscriberId, params) {
                var req = fluentRest.api().subscribers(subscriberId).budgets().categories().tradeClasses();
                return serviceHelper.listOrPage(req, params);
            },
            getFinanceStandards: function () {
                return fluentRest.api().finance().standards().tradeClasses().get();                
            },
            saveStandardMapping : function(subscriberId, standardId, abosKeys){                
                return fluentRest.api().subscribers(subscriberId).finance().standards().tradeClasses(standardId).post({ abosKeys : abosKeys});
            },
            saveBudgetCategory : function(subscriberId, category){
                var req = fluentRest.api().subscribers(subscriberId).budgets().categories().tradeClasses(category.id);
                return !!category.id
                    ? req.put(category)
                    : req.post(category);
            },
            deleteBudgetCategory : function(subscriberId, categoryId){
                return fluentRest.api().subscribers(subscriberId).budgets().categories().tradeClasses(categoryId).delete();
            },
        };
    }
]);
