angular.module('angus.controllers').controller('liquidFuelsSettingsCtrl', [
    '$scope', '$rootScope', '$http', 'periodIndicators', 'productsService', 'hierarchyService', 'tradeClassService', 'constantsService',
    function($scope, $rootScope, $http, periodIndicators, productsService, hierarchyService, tradeClassService, constantsService) {

        $scope.periodIndicators      = constantsService.invoicePeriodIndicators.enums;
        $scope.zeroPriceSalesOptions = constantsService.includeExcludeOnly.enums;

        productsService.getProducts()
            .then(function(products) {
                $scope.products = products;
            });

        tradeClassService.getTradeClasses()
            .then(function(classes) {
                $scope.tradeClasses = classes;
            });

        $scope.openDivisionHierarchyView = function() {
            hierarchyService
                .openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };
    }
]);