angular.module( 'angus.directives' ).directive( 'volumeToBudget', [
    'actionViewTemplates',
    function(actionViewTemplates) {
        'use strict';

        return {
            scope: {
                model: '=actionModel',
                settings: '=volumeToBudgetWidgetSettings',
                widgetCode : '='
            },
            templateUrl: actionViewTemplates.volumeToBudgetDetailView
        };
    }
]).directive('volumeToBudgetBarChart', [
    'moment', '_',
    function(moment, _) {
        'use strict';

        return {
            scope: {
                ngModel: '='
            },
            link: function(scope, element, attrs) {
                function generateRandomRGBColor() {
                    var r = Math.floor(Math.random() * 240) + 55,
                        g = Math.floor(Math.random() * 240) + 35,
                        b = Math.floor(Math.random() * 240) + 15;

                    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
                }

                var colors = [
                    'rgb(2, 117, 216)',
                    'rgb(91, 192, 222)',
                    'rgb(92, 184, 92)',
                    'rgb(240, 173, 78)',
                    'rgb(230, 125, 74)'
                ];

                var budgets = [],
                    budgetsContainer = [];

                function plot(budgetData) {
                    var unitContainer = [],
                        dataContainer = [];

                    var unitContainerForValueAxisMax = [];

                    var workingDayPercentage = ((budgetData.workingDays.elapsed / budgetData.workingDays.total) * 100);

                    _.forEach(budgetData.budgets, function(data) {
                        if(data.percentOfPeriodBudget > 0 || data.percentOfPeriodBudget < 0)
                            unitContainer.push(data);
                    });

                    budgets = [].concat(unitContainer);
                    _.forEach(budgets, function(budget) { if(budget.percentOfPeriodBudget != 0) dataContainer.push(budget) });
                    budgetsContainer = [].concat(dataContainer);

                    _.forEach(budgetsContainer, function(item) { unitContainerForValueAxisMax.push(item.percentOfPeriodBudget) });

                    var largestValue = 0;
                    unitContainerForValueAxisMax.forEach(function(largest) {
                        if(largest > largestValue) largestValue = largest
                    });

                    var chartHeight = 365;
                    var barHeight = chartHeight / (budgetsContainer.length + 5);

                    while(budgetData.budgets.length > colors.length) colors.push(generateRandomRGBColor());

                    _.forEach(colors, function(color, index) { budgetsContainer[index] ? budgetsContainer[index].color = color : null });

                    $(element).kendoChart({
                        theme: 'bootstrap',
                        title: {
                            text: 'Deliveries through ' + moment.utc(budgetData.dataThroughDate).format('MMMM Do, YYYY'),
                            font: 'bold 17px Helvetica Neue, Helvetica, Arial, sans-serif',
                        },

                        series: [{
                            type: 'bar',
                            border: { width: 0 },
                            data: budgetsContainer,
                            field: 'percentOfPeriodBudget',
                            visual: function(event) {
                                var changeBarHeight = event.createVisual();
                                changeBarHeight.transform(kendo.geometry.transform().scale(1, barHeight / event.rect.size.height, event.rect.center() ));

                                return changeBarHeight;
                            },
                            overlay: { gradient: 'glass' },
                            labels: {
                                visible: true,
                                template: function(event) { return event.dataItem.productCategory },
                                position: 'right',
                                color: 'rgb(0, 0, 0)',
                                font: 'bold 16px Helvetica Neue, Helvetica, Arial, sans-serif; text-shadow: 0px 0px 2px #444',
                            }
                        }],

                        valueAxis: {
                            max: largestValue < 100 ? 100 : largestValue + (largestValue - 100),
                            narrowRange: false,
                            line: {
                                visible: true,
                                color: 'rgb(0, 0, 0)',
                                width: 7
                            },
                            majorGridLines: {
                                visible: true,
                                width: 2
                            },
                            majorTicks: {
                                size: 14,
                                width: 5,
                                visible: true,
                                color: 'rgb(0, 0, 0)'
                            },
                            minorTicks: {
                                size: 0,
                                visible: false
                            },
                            labels: {
                                format: '{0}%',
                                font: 'bold 15px Helvetica Neue, Helvetica, Arial, sans-serif',
                                color: '#000',
                            },
                            plotBands: [{
                                from: workingDayPercentage - 1,
                                to: workingDayPercentage + 1,
                                color: 'rgb(255, 0, 0)'
                            }]
                        },

                        categoryAxis: {
                            line: {
                                visible: true,
                                color: 'rgb(0, 0, 0)',
                                width: 7
                            },
                            majorGridLines: { visible: false },
                            majorTicks: {
                                size: 0,
                                visible: false
                            },
                            minorTicks: {
                                size: 0,
                                visible: false
                            }
                        },

                        chartArea: {
                            height: chartHeight
                        },

                        tooltip: {
                            visible: true,
                            template: function(event) {
                                var budgetMonth = moment(budgetData.dataThroughDate).format('MMMM');
                                var budgetYear = moment(budgetData.dataThroughDate).format('YYYY');
                                var currentUnits = (event.dataItem.currentUnits).toLocaleString(undefined, { maximumFractionDigits: 1 });
                                var budgetedUnitsToDate = (event.dataItem.budgetedUnits).toLocaleString(undefined, { maximumFractionDigits: 1 });
                                var budgetedPeriodUnits = (event.dataItem.periodBudgetUnits).toLocaleString(undefined, { maximumFractionDigits: 1 });

                                return  '<span class="tooltip-text">Delivered -- ' + currentUnits + '</span>' +
                                        '<br>' +
                                        '<span class="tooltip-text">Budgeted to Date -- ' + budgetedUnitsToDate + '</span>' +
                                        '<br>' +
                                        '<span class="tooltip-text">' + budgetMonth + ' ' + budgetYear + ' Budget -- ' + budgetedPeriodUnits + '</span>';
                            }
                        }
                    });

                    $('.widget').on('resize', function() {
                        kendo.resize($('.chart-wrapper'));
                    });

                }

                scope.$watch(function() {
                    return scope.ngModel;
                }, function(newVal) {
                    if(newVal) plot(newVal);
                });
            }
        };
    }
]);