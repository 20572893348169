angular.module('angus.controllers').controller('deliveryEffContainerCtrl', [
	'$scope', '$rootScope', '$http', '_', 'constantsService', 'drillService', 'moment', 'gridService2', 'nodeType', 'gridState',
	function($scope, $rootScope, $http,  _, constantsService,  drillService, moment, gridService2, nodeType, gridState) {

		'use strict';

		var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

		$scope.dates = {
			startDate 		 : $scope.model.periodStartDate  || moment(),
			endDate 		 : $scope.model.lastDeliveryDate || moment(),
			lastDeliveryDate : $scope.model.lastDeliveryDate,
		};		

	    $scope.filter =  {
	    	productIds 			: $scope.model.productIds,
            hierarchyNodeId 	: $scope.model.hierarchyNodeId,
            deliveryTypeIds 	: $scope.model.deliveryTypeIds,
            inactiveTanks 		: constantsService.includeExcludeOnly.exclude.key,
            terminatedCustomers : constantsService.includeExcludeOnly.exclude.key            
	    };    


	    $scope.getDeliveryEfficiency = function(){  
	    	    	    	
	      	var params = {
	      	    divisionId         : $scope.filter.hierarchyNodeId,
              	productIds         : $scope.filter.productIds,
              	startDate          : $scope.dates.startDate.format(),
              	endDate            : $scope.dates.endDate.format(),
              	deliveryTypeIds    : $scope.filter.deliveryTypeIds,
              	inactiveTanks      : $scope.filter.inactiveTanks,
              	terminatedCustomers: $scope.filter.terminatedCustomers              	
	      	};

	    	var rowPromise = $http({    		
	        	  	url: ('api/subscribers/{0}/batches/deliveries/efficiency/').format($scope.subscriberId),
	          		method: 'POST',
	          		data: params
	        }).then(function(result){
				const data = result.data;

				data.forEach(row => {
					/* A bug with AG Grid appears to force us to use the same ColId and Field names. So duplicate the Date fields here. */
					row.lastDeliveryDateMonth = row.lastDeliveryDate;
					row.lastDeliveryDateYear = row.lastDeliveryDate;
					
					row.tankAcquisitionDateMonth = row.tankAcquisitionDate;
					row.tankAcquisitionDateYear = row.tankAcquisitionDate;
				});

				return data;
	        	  
	        });        

	        var promise = $scope.grid.setRows(rowPromise);

			//$scope.promiseMonitor = new PromiseMonitor(promise);
			return promise;
	    }



	   function getColClicks(){        
	        return {
	            accountNumber 	: { 
	            	onClick : function(row){ drillService.openAccountInfo(row, drillService.context.delivery); }
	            },                       
	            tankNumber 		: { 
	            	onClick : function(row){ drillService.openTankInfo(row, drillService.context.delivery); }
	            }	            
	        };
	    }

	    function getColDefs(){
            
	        var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
	        var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];      	         

	        colDefs.push(gridService2.colDef.createText('productName','Product','productName', { rowGroupIndex : nextIndex, hide : true }));
	        colDefs.push(gridService2.colDef.createText('deliveryTypeName','Delivery Type','deliveryTypeName', { rowGroupIndex : nextIndex + 1, hide : true }));
	        colDefs.push(gridService2.colDef.createText('accountNumber','Account Number','accountNumber', { cellClass : ['center']} ));    
	        colDefs.push(gridService2.colDef.createText('customerName','Customer Name','customerName'));
	        colDefs.push(gridService2.colDef.createText('tankNumber','Tank Number','tankNumber', { cellClass : ['center']} ));    
	        colDefs.push(gridService2.colDef.createNumber('tankSize','Tank Size','tankSize'));      
	        colDefs.push(gridService2.colDef.createNumber('idealDrop','Ideal Drop','idealDrop',{},  { decimalPlaces: 1 }));      
	        colDefs.push(gridService2.colDef.createNumber('dropNumber','Number Of Drops','dropNumber',{},  { decimalPlaces: 1 }));      
	        colDefs.push(gridService2.colDef.createPercentage('efficiencyPercentage','Efficiency %','efficiencyPercentage', {}, { decimalPlaces: 1 }));
	        colDefs.push(gridService2.colDef.createNumber('savedDrops','Potential Saved Drops','savedDrops',{},  { decimalPlaces: 1 }));      
	        colDefs.push(gridService2.colDef.createNumber('averageDrop','Average Drop','averageDrop',{},  { decimalPlaces: 1 }));      
	        colDefs.push(gridService2.colDef.createNumber('averageMissedUnits','Average Missed Units','averageMissedUnits',{},  { decimalPlaces: 1 }));
	        colDefs.push(gridService2.colDef.createNumber('annualUnits','Annual Units','annualUnits',{},  { decimalPlaces: 1 }));      	        	      	
	      	colDefs.push(gridService2.colDef.createBoolean('monitored','Tank Monitor','monitored'));
	      	colDefs.push(gridService2.colDef.createBoolean('customerOwnedFlag','Customer Owned Tank','customerOwnedFlag'));      
	      	colDefs.push(gridService2.colDef.createText('deliveryGroup','Delivery Group','deliveryGroup'));         
			colDefs.push(gridService2.colDef.createDate('lastDeliveryDate','Last Delivery Date','lastDeliveryDate'));
			colDefs.push(gridService2.colDef.createMonth('lastDeliveryDateMonth','Last Delivery Month','lastDeliveryDateMonth', { hide: true }));
			colDefs.push(gridService2.colDef.createYear('lastDeliveryDateYear','Last Delivery Year','lastDeliveryDateYear', { hide: true }));
			colDefs.push(gridService2.colDef.createDate('tankAcquisitionDate','Tank Acquisition Date','tankAcquisitionDate'));
			colDefs.push(gridService2.colDef.createMonth('tankAcquisitionDateMonth','Tank Acquisition Month','tankAcquisitionDateMonth'));
			colDefs.push(gridService2.colDef.createYear('tankAcquisitionDateYear','Tank Acquisition Year','tankAcquisitionDateYear'));
	        return colDefs;                
	   	} 

	   	var nodeColDefs;
	    
	    nodeType
		    .getDivisionColDefs(subscriberId)    	    
		    .then(function(colDefs){
		        nodeColDefs = colDefs;          
		        var gridParams = { 
		        	gridOptions   : {
		        		groupAggFunction : function(rows){
					   		var percentageOfIdealDropCount  = 0;
					   		var adjIdealDropCount 			= 0;
					   		var kFactorVarianceCount        = 0;
					   		var averageDropCount 			= 0;
					   		var averageMissedUnitsCount		= 0;
					   		var efficiencyPercentageCount 	= 0;

					   		var retVal =  _.reduce(rows, function(result, row){
					   			var data = row.data;

					   			result.units += data.units;
					   			result.dropNumber += data.dropNumber;
								result.annualUnits += data.annualUnits;
								result.savedDrops += data.savedDrops;

					   			if(data.percentageOfIdealDrop || data.percentageOfIdealDrop == 0){
					   				if(!result.percentageOfIdealDrop) result.percentageOfIdealDrop = 0;
					   				result.percentageOfIdealDrop += data.percentageOfIdealDrop;
					   				percentageOfIdealDropCount++;
					   			}

					   			if(data.adjIdealDrop || data.adjIdealDrop == 0){
					   				if(!result.adjIdealDrop) result.adjIdealDrop = 0;
					   				result.adjIdealDrop += data.adjIdealDrop;
					   				adjIdealDropCount++;
					   			}

					   			if(data.kFactorVariance || data.kFactorVariance == 0){
					   				if(!result.kFactorVariance) result.kFactorVariance = 0;
					   				result.kFactorVariance += data.kFactorVariance;
					   				kFactorVarianceCount++;
					   			}

					   			if(data.averageDrop || data.averageDrop == 0){
					   				if(!result.averageDrop) result.averageDrop = 0;
					   				result.averageDrop += data.averageDrop;
					   				averageDropCount++;
					   			}

					   			if(data.averageMissedUnits || data.averageMissedUnits == 0){
					   				if(!result.averageMissedUnits) result.averageMissedUnits = 0;
					   				result.averageMissedUnits += data.averageMissedUnits;
					   				averageMissedUnitsCount++;
					   			}

					   			if(data.efficiencyPercentage || data.efficiencyPercentage == 0){
					   				if(!result.efficiencyPercentage) result.efficiencyPercentage = 0;
					   				result.efficiencyPercentage += data.efficiencyPercentage;
					   				efficiencyPercentageCount++;
					   			}

					   			return result;
				   			}, { 
				   				units :0,
				   				dropNumber : 0,
				   				savedDrops : 0,
				   				annualUnits : 0
	   						});


					   		if(percentageOfIdealDropCount) retVal.percentageOfIdealDrop = retVal.percentageOfIdealDrop / percentageOfIdealDropCount;
					   		if(adjIdealDropCount) retVal.adjIdealDrop = retVal.adjIdealDrop / adjIdealDropCount;
					   		if(kFactorVarianceCount) retVal.kFactorVariance = retVal.kFactorVariance / kFactorVarianceCount;
					   		if(averageDropCount) retVal.averageDrop = retVal.averageDrop / averageDropCount;
					   		if(averageMissedUnitsCount) retVal.averageMissedUnits = retVal.averageMissedUnits / averageMissedUnitsCount;
					   		if(efficiencyPercentageCount) retVal.efficiencyPercentage = retVal.efficiencyPercentage / efficiencyPercentageCount;
					   		

					   		return retVal;
					    }
		        	},
		            gridState     : gridState(subscriberId, $scope.widgetCode),                    
		            defs          : getColDefs(),
		            clicks        : getColClicks(),
		            exportOptions : { fileName: 'Delivery Efficiency' }
		        };
		        return gridService2.createGrid(gridParams);
		    })
		    .then(function(grid){    	
		        $scope.grid = grid;        
		        $scope.getDeliveryEfficiency();
		    });        
	}
]);