angular.module('angus.controllers').controller('closingQuotesCtrl', [
    '$scope', '$http', '_', '$filter', '$rootScope', 'moment',
    function($scope, $http, _, $filter, $rootScope, moment) {
        'use strict';

        $scope.data = [];

        function symbolPrecision(symbol) {
            switch(symbol) {
                case 'RB':
                case 'RT':
                case 'HO':
                case 'MBCC':
                    return 4;
                case 'NG':
                    return 3;
                case '8K':
                case 'B0':
                    return 5;
                default:
                    return 2;
            }
        }

        function init() {
            return $scope.widget.promises.monitor(
                $http({
                    method: 'GET',
                    url: ('/api/subscribers/{0}/commodityIndicies/closingQuotes').format($rootScope.user.subscriberId),
                    params: {
                        symbols: $scope.widget.instance.settings.commodityIndexSymbols.value,
                        date: moment().format('YYYY-MM-DD')
                    }
                })
                .success(function(data) {
                    // if(data) {
                        $scope.data = _.map(data, function(quote) {
                            var isChangeUp = quote.change > 0;
                            var isChangeDown = quote.change < 0;

                            return {
                                success: quote.success,
                                date: quote.date,
                                symbol: quote.symbol,
                                name: quote.name,
                                last: quote.success ? '$' + quote.close.toFixed(symbolPrecision(quote.symbol)) : '-',
                                lastDir: quote.close > 0,
                                change: quote.success ? '$' + Math.abs(quote.change).toFixed(symbolPrecision(quote.symbol)) : '-',
                                isChangeUp: isChangeUp,
                                isChangeDown: isChangeDown,
                                isChangeZero: !isChangeUp && !isChangeDown
                            };
                        });
                    // }
                    $scope.widget.noContentFlag = false;
                }, function(err) {
                    $scope.widget.noContentFlag = true;
                })
            );
        }

        $scope.widget.setDefaultInitialization(init);

        $scope.isZeroThreshold = function(value) {
            return value.toFixed(4);
        };

        $scope.$on('widgetSettingsChanged', function() {
            init();
        });

    }
]);
