angular.module('angus.directives')
.directive('yesNoOnlySelect', [function() {
    'use strict';

    return {
        restrict: 'E',
        scope: {
            selectedId: '=',
            required: '='
        },
        controller: ['$scope', 'constantsService', function($scope, constantsService){
            $scope.yesNoOnly = constantsService.yesNoOnly.enums;
        }],

        template: '<select class="form-control" ng-model="selectedId" ng-options="option.value.id as option.value.display for option in yesNoOnly" ng-required="required"></select>'
    };
}]);