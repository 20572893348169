angular.module( 'angus.directives' ).directive( 'appBatchDeliveries', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		'use strict';

		return {
			scope: {
				closeCb: '=',
				model: '=actionModel',
				widgetSettings: '=batchDeliveriesWidgetInstance'
			},
			templateUrl: actionViewTemplates.batchDeliveriesReportContainer
		};
	}
] );