
angular.module('angus.directives')
  .directive('terminationPeriodSelect', [function () {
    'use strict';

    return {
      restrict: 'A',
      scope: {
        ngModel: '=',
        subscriberId: '='
      },
      controller: ['$scope', 'constantsService', function ($scope, constantsService) {
      		$scope.periodIndicators = constantsService.terminationIndicators.enums          
      }],

      template: '<select class="form-control" data-ng-model="ngModel" data-ng-options="indicator.key as indicator.value.display for indicator in periodIndicators | noOverlap : \'key\' : [\'customDateRange\']"></select>',
    };
  }]);