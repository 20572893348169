angular.module('angus.controllers').controller('serviceRevenueSubRegSettingsCtrl', [
    '$scope', '_', 'constantsService',
    function($scope, _, constantsService) {
        
        'use strict';
        
        $scope.periodIndicators      = constantsService.servicePeriodIndicators.enums;
        $scope.serviceRevenueOptions = constantsService.serviceRevenueCategories.enums;

    }
]);