angular.module('angus.controllers').controller('inventoryManagementController', [
    '$scope', '$rootScope', 'gridService2', '_', '$q', '$mdToast', 'gridState',
    function($scope, $rootScope, gridService2, _, $q, $mdToast, gridState) {

        'use strict';  
 
		function init(){
            $scope.activeItem = {
                activeSection: 'monitors'
            }
        }

		init();
    }
]);