angular.module('angus.directives').directive('custProfAnalysis', [
	'actionViewTemplates',
	function(actionViewTemplates) {
		return {
			scope: {
				model: "=actionModel",
				settings: "=custProfAnalysisWidgetSettings",
				widgetCode: "="
			},
			templateUrl: actionViewTemplates.custProfAnalysisContainer
		};
	}
])
	.directive('flotPieChart', [
		'$',
		function($) {
			return {
				scope: {
					ngModel: "=",
					startHidden : "=",
					useTooltip: "=",
					dontUseLegend: "=",
					reinit:  "="
				},
				link: function(scope, element) {

					var colors = [
						"#0099d3",
						"#49bf67",
						"#f8a326",
						"#9564e2",
						"#f34541"
					];

					function labelFormatter(label, series) {
						return "<div style='font-size:8pt; text-align:center; padding:2px; color:white;'>" + label + "<br/>" + Math.round(series.percent) + "%</div>";
					}

					function plot(data) {
						var options = {
							series: {
								pie: {
									show: true,
									radius: 1,
									label: {
										show: !scope.useTooltip,
										radius: 3 / 4,
										formatter: labelFormatter,
										background: {
											opacity: 0.5,
											color: '#000'
										}
									}
								}
							},
							colors: colors,
							legend: {
								show: scope.dontUseLegend ? false : true
							}
						};

						if (scope.useTooltip) {
							options.tooltip = true;
							options.tooltipOpts = {
								content: (label, xval, yval, flotItem) => {return `${label} - ${Math.round(xval)}% / ${yval[0][1]} accounts`},
								shifts: {
									x: 20,
									y: 0
								},
								defaultTheme: false
							};
							options.grid = {
								hoverable: true
							};
						}
						var plot = $.plot(element, data, options);
						var series = plot.getData();

						_.forEach(data, function(d, index){
							d.color = series[index].color;
						});
						
						
					}

					scope.$watch(function() {
						return scope.ngModel;
					}, function(newVal) {
						if (newVal && !scope.startHidden)
							plot(newVal);
					});

					scope.$watch(function() {
						return scope.reinit;
					}, function(newVal) {
						if (newVal && scope.ngModel){
							scope.startHidden = false;
							plot(scope.ngModel);
						}
					});
				}
			};
		}
	]);