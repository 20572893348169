angular.module('angus.services')
    .factory('widgetLibraryService', [
       '$http',
        function($http) {

            var baseApiUrl = ("api/widgetPackages");

            return {
                getWidgets: function(securityGroupId) {
                    var url = ("{0}/widgets").format(baseApiUrl);

                    return $http({
                            url: url,
                            method: 'GET',
                            params: securityGroupId ? { securityGroupId: securityGroupId } : {}
                        })
                        .then(function(widgets) {
                            return widgets.data;
                        });
                },
                getViews: function(widgetPackageKey, widgetId, securityGroupId) {
                    var url = ("{0}/{1}/widgets/{2}/views").format(baseApiUrl, widgetPackageKey, widgetId);

                    return $http({
                            url: url,
                            method: 'GET',
                            params: securityGroupId ? { securityGroupId: securityGroupId } : {}
                        })
                        .then(function(views) {
                            return views.data;
                        });
                }
            };
        }
    ]);
