angular.module( 'angus.directives' ).directive( 'acctGainLossByTank', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		return {
			scope: {
				model: "=actionModel",
				settings: "=acctGainLossByTankSettings",
				widgetCode: "="
			},
			templateUrl: actionViewTemplates.acctGainLossByTankContainer
		};
	}
]).directive('updateColorFromLabel', [ '$',
	function($){
		return {
			scope: {
				propertyValue: "=",
			},
			link: function(scope, element){

				// scope.$watch(function() {
				// 		return scope.ngModel;
				// 	}, function(newVal) {
				// 		if (newVal)
				// 			plot(newVal);
				// 	});

				var colorValue = $('.sideNavContainer .legendLabel:contains(" ' + scope.propertyValue + '")').css('color');

				element.css('color', colorValue);
			}
		};
	}
]);
