angular.module('angus.controllers').controller('bsgServiceMarginActionViewCtrl', [
    '$scope', '$q', '$rootScope', 'fluentRest',  '_', 'constantsService','drillService',  'moment', 'gridService2', 'nodeType', 'gridState', 'modalService', 'actionViewTemplates', 'restrictedAccessService',
    function($scope, $q, $rootScope, fluentRest,  _, constantsService, drillService, moment, gridService2, nodeType, gridState, modalService, actionViewTemplates, restrictedAccessService) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        if($scope.settings.customDate) {
            $scope.dates = {
                startDate: moment.utc($scope.settings.customDate.startDate).format('L') || moment(),
                endDate: moment.utc($scope.settings.customDate.endDate).format('L') || moment()
            }
        } else {
            $scope.dates = {
                startDate      : $scope.model.periodStartDate || moment(),
                endDate      : $scope.model.lastPostingDate || moment(),
                lastPostingDate : $scope.model.lastPostingDate || moment()
            };
        }

        $scope.filter =  {
            hierarchyNodeId: $scope.settings.hierarchyNodeId,
        };

        $scope.getServiceMargin = function(){
            var params = {
                divisionId          : $scope.filter.hierarchyNodeId,
                startDate           : $scope.dates.startDate.format(),
                endDate             : $scope.dates.endDate.format(),
            };

            var rowPromise = fluentRest
                .api()
                .subscribers($rootScope.user.subscriberId)
                .service()
                .margin()
                    .get(params)
                    .then(function(data){
                        _.each(data, function(record) {
                          if((record.salesTypeName || '').trim().toUpperCase() === 'PARTS') {
                            record.margin = record.sales - record.cost;
                          }
                        });
                        return data;
                    });

            var promise = $scope.grid.setRows(rowPromise);
            //$scope.promiseMonitor = new PromiseMonitor(promise);
            return promise;
        };

        function getColClicks(){
            return {
                accountNumber   : {
                    onClick : function(row){ drillService.openAccountInfo(row); }
                }
            };
        }

        function getColDefs(){

            var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];

            colDefs.push(gridService2.colDef.createText('salesType','Sales Type','salesTypeName', { rowGroupIndex : nextIndex, hide : true }));

            colDefs.push(gridService2.colDef.createDate('date','Date','date'));

            colDefs.push(gridService2.colDef.createText('accountNumber','Account Number','accountNumber', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('customerName','Customer Name','customerName', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('invoiceNumber','Invoice Number','workOrderNumber', { cellClass : ['center']} ));

            colDefs.push(gridService2.colDef.createCurrency('sales','Sales Amount','sales', {aggFunc : 'sum'}, {decimalPlaces: 2 }));


            if(!$scope.restrictedAccess.costAndMargin) {
                colDefs.push(gridService2.colDef.createCurrency('cost','Cost','cost', {aggFunc : 'sum'}, {decimalPlaces: 2 }));
                colDefs.push(gridService2.colDef.createCurrency('margin','Margin','margin', {aggFunc : 'sum'}, {decimalPlaces: 2 }));
            }


            return colDefs;
        }

        var nodeColDefs;

        $q.all([
            restrictedAccessService.getAccess(),
            nodeType.getDivisionColDefs(subscriberId)
        ])
        .spread(function(access, colDefs){
            $scope.restrictedAccess = access.restrictedAccess;

            nodeColDefs = colDefs;

            var gridParams = {
                gridState     : gridState(subscriberId, $scope.widgetCode),
                defs          : getColDefs(),
                clicks        : getColClicks(),
                exportOptions : { fileName: 'Service Margin' }
            };
            return gridService2.createGrid(gridParams);
        })
        .then(function(grid){
            $scope.grid = grid;
            $scope.getServiceMargin();
        });

    }
]);
