'use strict';

angular.module('angus.controllers').controller('partsMarginPopoverCtrl', [
	'$scope', '$http', '$rootScope', 'Paging',
	function($scope, $http, $rootScope, Paging) {

		$scope.pageSize = 10;
		$scope.paging   = new Paging($scope.pageSize);
		$scope.sorting  = {
			sortProp : 'partNumber',
			sortDir  : 'asc'
		};
		$scope.table = [];

		$scope.sortFn = function(propName){
			if (propName != $scope.sorting.sortProp)
                $scope.sorting.sortDir = 'asc';
            else
                $scope.sorting.sortDir = $scope.sorting.sortDir == 'asc' ? 'desc' : 'asc';

            $scope.sorting.sortProp = propName;
            $scope.getData($scope.paging.currentPage);
		};

		$scope.getData = function (pageNumber) {
			$scope.loading = true;

			$scope.paging.getPage(('api/subscribers/{0}/service/parts').format($rootScope.user.subscriberId),
				pageNumber || $scope.paging.currentPage,
				{
					serviceId : $scope.id,
					sortProp  : $scope.sorting.sortProp,
					sortDir   : $scope.sorting.sortDir
			 	})
			.then(function(result){
				$scope.item = result;
				$scope.loading = false;
			});
		};

		var headingCenterClass = 'text-center clickable';

		$scope.tableHeader = [
          {
              name: 'Qty',
              isHidable: true,
              propClass: headingCenterClass,
              propName: 'quantity',
              isSortable: true
          }, {
              name: 'Part Nbr',
              isHidable: true,
              propClass: headingCenterClass,
              propName: 'partNumber',
              isSortable: true
          }, {
              name: 'Description',
              isHidable: true,
              propClass: headingCenterClass,
              propName: 'partDescription',
              isSortable: true
          }, {
	            name: 'Unit Price',
	            isHidable: true,
	            propClass: headingCenterClass,
	            propName: 'unitPrice',
	            isSortable: true
	        }, {
	            name: 'Unit Cost',
	            isHidable: true,
	            propClass: headingCenterClass,
	            propName: 'unitCost',
	            isSortable: true
	        }, {
	            name: 'Extended Price',
	            isHidable: true,
	            propClass: headingCenterClass,
	            propName: 'billedAmount',
	            isSortable: true
	        }, {
              name: 'Extended Cost',
              isHidable: true,
              propClass: headingCenterClass,
              propName: 'cost',
              isSortable: true
          }
		];

		$scope.getData();
	}
]);
