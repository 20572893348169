angular.module('angus.directives').directive('usersReport', [
    'actionViewTemplates',
    function(actionViewTemplates) {
        return {
            scope: {
                model: "=",
                settings: "=",
                windowId: "="
            },
            restrict: 'A',
            templateUrl: actionViewTemplates.usersReport
        }
    }
])