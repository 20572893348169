angular.module('angus.controllers').controller('custProfAnalysisCtrl', [
    '$scope', '$rootScope', '$http', 'hierarchyService',
    function($scope, $rootScope, $http, hierarchyService) {

        'use strict';

        // on initial widget load, set default values for dropdowns in settings view
        $scope.widget.instance.settings.inactiveTanks = { value : 'N' };

        function getCounts(){

            return $http({
                method: 'POST',
                url: ('api/subscribers/{0}/customerAccounts/counts').format($rootScope.user.subscriberId),
                data: {
                    useBrite: $scope.widget.instance.settings.useBrite.value,
                    totals  : true,
                    inactiveTanks: $scope.widget.instance.settings.inactiveTanks.value,
                    leafIds : hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes,  $scope.widget.instance.settings.hierarchyNodeId),
                }
            })
                .then(function(counts){
                    return counts.data;
                });
        }


        function init() {
            return $scope.widget.promises.monitor(
                getCounts()
                    .then(function(counts){
                        $scope.active = counts.active;
                        $scope.inactive = counts.inactive;
                        $scope.total = counts.total;

                        $scope.data = [{
                            label: 'Budget',
                            data: [[1, $scope.active.budget]]
                        }, {
                            label: 'No Budget',
                            data: [[1, $scope.active.noBudget]]
                        }];
                    })
            );
        }

        $scope.widget.setDefaultInitialization(init);

        $scope.$on('widgetSettingsChanged', function() {
            init();
        });
    }
]);