'use strict';

angular.module('angus.controllers').controller('subRegDeliveriesCtrl', [
    '$scope', '$q', 'actionViewTemplates','$http',
    function( $scope, $q, actionViewTemplates,$http) {

        $scope.mappingTabs = [
            {
                name : 'Driver Exclusion',
                url : actionViewTemplates.subRegDriverExclusion
            }  
        ];
        
        $scope.selected = {sourceSystemKey : null};
        
        function getSourceSystem() {
            if ($scope.selected.sourceSystemKey && $scope.selected.sourceSystemKey.length > 0)
                return $q.when ($scope.selected.sourceSystemKey);
            else   
                return $http
                    .get (('api/subscribers/{0}/sourceSystem').format($scope.subscriberId))
                    .then(function(sourceSystemKeys){
                        $scope.sourcesystem = { sourceSystemKeys:sourceSystemKeys.data };                       
                    }); 
                
        }

        getSourceSystem();

        $scope.switchTab = function(mappingTab){
            $scope.activeMappingTab = mappingTab;
        };
    }
] );