 angular.module('angus.controllers').controller('purchasOrderController', [
    '$scope', '$rootScope', 'moment',  '_', 'purchaseOrderService', 'inventoryManagementService' ,
    function ($scope, $rootScope,  moment, _, purchaseOrderService, inventoryManagementService) {
        'use strict';
     
        $scope.isDetailSectionEnabled = true;
        $scope.isLoading = false; 
        $scope.purchaseOrderSubmitLoading = false;  
        $scope.vendors = [];
        $scope.alerts = []; 
        $scope.isCreateMode = false; 
        $scope.orderForm = {};
        $scope.orderDetailForm = {
            purchaseOrderDetailKey   : null, 
            purchaseOrderHeaderKey 	 : null,  
            purchaseOrderDetailNote  : null,
            simCardCarrier           : null,
            inventoryItem            : null,
            rowStatusKey             : 1,
            orderQuantity            : null
        } 
        $scope.hasNewDetailForm = false; 
        $scope.areItemsLoading = false; 
        $scope.areCarriersLoading = false; 
        $scope.arePurchaseOrderHeadersDropdownsLoading = false; 
        $scope.areVendorsDropdownsLoading = false; 
        $scope.simCardCarriers = [];
        $scope.inventoryItems = [];
        $scope.purchaseOrderHeadersDropdown = [];
        $scope.rowStatus = {
            active: 1,
            delete: 3
        }

        function init() {
            $scope.isLoading = true;
            Promise.all([
                getVendors(),
                $scope.getPurchaseOrderHeader(), 
                $scope.getSimCardCarriers()
            ])
            .then(function(results) {
                $scope.isLoading = false;
            });
        } 
 
        //Public Methods ********************************************************************
        $scope.newOrderModel = function() { 
            var orderForm = { 
                purchaseOrderHeaderKey   : -1,
                vendorKey                : null,
                purchaseOrderDate 		 : new Date(),
                purchaseOrderID 	     : null,  
                purchaseOrderHeaderNote  : null,
                purchaseOrderDetails: [] 
            }  
            return orderForm;
        }

        $scope.newOrderDetailModel = function() { 
            var orderDetailForm = {
                purchaseOrderDetailKey   : null, 
                purchaseOrderHeaderKey 	 : null,  
                purchaseOrderDetailNote  : null,
                simCardCarrier           : null,
                inventoryItem            : null,
                rowStatusKey             : 1,
                orderQuantity            : null 
            }  
            return orderDetailForm;
        }

        $scope.reset = function() { 
            $scope.orderForm = $scope.newOrderModel(); 
            $scope.isCreateMode = true;
            $scope.isLoading = false;
        }

        $scope.clickAddPurchaseOrder = function(form) { 
            $scope.purchaseOrderHeadersDropdown = [{ purchaseOrderHeaderKey: -1,  purchaseOrderID: '<--- Create New --->' }]; 
            $scope.orderForm.purchaseOrderHeaderKey = -1;

            $scope.reset();
            $scope.addNewOrderDetailRow();
            $scope.getInventoryItems();
            $scope.hasNewDetailForm = true;
            form.$setDirty();
        }

        $scope.submitHandler = function(formPurchaseOrder, rowStatusKey) {
            $scope.purchaseOrderSubmitLoading = true;
            $scope.orderForm.updateUser = $rootScope.user.username;  
            $scope.orderForm.rowStatusKey = rowStatusKey;  

            try {
                purchaseOrderService.postPurchaseOrder($scope.orderForm)
                .then(function(result) { 
                    if (result.status === 200) {  
                        if (rowStatusKey == $scope.rowStatus.active) {
                            $scope.alerts.push({ type: 'success', msg: 'Successfully Saved' }); 
                            $scope.getPurchaseOrder(result.data[0].PurchaseOrderHeaderKey); 
                        }
                        else { 
                            $scope.alerts.push({ type: 'success', msg: 'Successfully Deleted' }); 
                        }
                        if (formPurchaseOrder) {
                            formPurchaseOrder.$setPristine(); 
                            $scope.isCreateMode = true;
                        } 
                    }
                    else { 
                        $scope.alerts.push({type: 'danger', msg: 'Failed to Submit, Please check your data!'});  
                    }
                    $scope.getPurchaseOrderHeader();
                }); 
            } catch (error) { 
                $scope.alerts.push({type: 'danger', msg: 'Failed to Submit, Please check your data!'}); 
            }
            $scope.cancelHandler(formPurchaseOrder);
            $scope.purchaseOrderSubmitLoading = false;
            $scope.isCreateMode = false;
        }

        $scope.cancelHandler = function(form) {  
            if (form) {
                form.$setPristine();
                form.$setUntouched();
            } 
            
            $scope.getPurchaseOrderHeader(); 
            $scope.reset();
            $scope.orderForm = {};  
            $scope.alerts = [];
            $scope.isCreateMode = false;
        }  
        
        $scope.getInventoryItems = function(vendorKey) {   
            $scope.areItemsLoading = true; 
            inventoryManagementService.getInventoryItems(vendorKey)
            .then(function(result) {    
                $scope.inventoryItems = result.data;    
                $scope.areItemsLoading = false; 
            }); 
        }  
        
        $scope.getSimCardCarriers = function() {   
            $scope.areCarriersLoading = true; 
            inventoryManagementService.getSIMCardCarriers()
            .then(function(result) {   
                $scope.simCardCarriers = result.data;  
                $scope.areCarriersLoading = false; 
            }); 
        }   
 
        $scope.clickDeletePurchaseOrder = function(formPurchaseOrder) {   
            if(confirm("Are you sure to delete the purchase order?")) {
                $scope.submitHandler(formPurchaseOrder, $scope.rowStatus.delete);
            }  
        }

        $scope.purchaseOrderDropDownHandler = function(purchaseOrderHeaderKey) {     
            $scope.getPurchaseOrder(purchaseOrderHeaderKey); 
            $scope.addNewOrderDetailRow();
            $scope.getInventoryItems();
 
            $scope.hasNewDetailForm = false;
            $scope.isCreateMode = true;
            if ($scope.orderForm.purchaseOrderDetails && 
                $scope.orderForm.purchaseOrderDetails[$scope.orderForm.purchaseOrderDetails.length - 1].simCardCarrierKey) {
                $scope.hasNewDetailForm = true;
            }
        }

        $scope.getPurchaseOrderHeader = function(purchaseOrderHeaderKey) {     
            $scope.arePurchaseOrderHeadersDropdownsLoading = true;
            purchaseOrderService.getPurchaseOrderHeader(purchaseOrderHeaderKey)
            .then(function(result) {     
                $scope.purchaseOrderHeadersDropdown = result.data;   
                $scope.purchaseOrderHeadersDropdown =  _.filter($scope.purchaseOrderHeadersDropdown, function (item) {  
                    return item.rowStatusKey === $scope.rowStatus.active;
                });
                $scope.arePurchaseOrderHeadersDropdownsLoading = false; 
            }); 
        } 

        $scope.getPurchaseOrder = function(purchaseOrderHeaderKey) {   
            $scope.isLoading = true;
            var purchaseOrderID = null;

            purchaseOrderService.getPurchaseOrder(purchaseOrderHeaderKey, purchaseOrderID)
            .then(function(result) {
                populatePurchaseOrder(result.data); 
                $scope.isLoading = false; 
            }); 
        }  

        $scope.deleteOrderDetail = function(form, index) {   
            var detailItem = $scope.orderForm.purchaseOrderDetails[index];
            if (!detailItem.purchaseOrderDetailKey) {
                //if this record does not exist in DB then remove it.
                $scope.orderForm.purchaseOrderDetails.splice(index, 1);
                $scope.hasNewDetailForm = false;
            }
            else {
                if(confirm("Are you sure to delete this order item?")) {  
                    $scope.orderForm.purchaseOrderDetails[index].rowStatusKey = $scope.rowStatus.delete;
                    $scope.hasNewDetailForm = false;
                    form.$setDirty();
                }
            }  
        }  

        $scope.addNewOrderDetailRow = function() {    
            if ($scope.orderForm.purchaseOrderDetails) {
                var newDetail = $scope.newOrderDetailModel(); 
                newDetail.purchaseOrderHeaderKey = $scope.orderForm.purchaseOrderHeaderKey; 
                $scope.orderForm.purchaseOrderDetails.push(newDetail);    
                $scope.hasNewDetailForm = ($scope.orderForm.purchaseOrderDetails[$scope.orderForm.purchaseOrderDetails.length - 1] === $scope.orderDetailForm);
            } 
        }

        $scope.closeAlert = function (index) {
            $scope.alerts.splice(index, 1);
        } 

        //Private Methods ********************************************************************
 
        var getVendors = function() {    
            $scope.areVendorsDropdownsLoading = true;
            inventoryManagementService.getVendors()
            .then(function(result) {   
                $scope.vendors = result.data; 
                $scope.areVendorsDropdownsLoading = false;
            }); 
        } 

        var populatePurchaseOrder = function(po) {
            var detail = _.map(po.purchaseOrderDetails, function(d) {
                d.inventoryItem = d.inventoryItem ? d.inventoryItem.key : null; 
                d.simCardCarrier = d.simCardCarrier ? d.simCardCarrier.key : null;
                d.vendor = d.vendor ? d.vendor.key : null
                return d;
            });
            $scope.orderForm = {
                purchaseOrderHeaderKey   : po.purchaseOrderHeaderKey,
                vendorKey                : po.vendorKey,
                purchaseOrderDate 		 : moment(moment.utc(po.purchaseOrderDate).format('YYYY-MM-DD')).toDate(),
                purchaseOrderID 	     : po.purchaseOrderID,  
                purchaseOrderHeaderNote  : po.purchaseOrderHeaderNote,
                rowStatusKey             : po.rowStatusKey,
                purchaseOrderDetails     : detail
            }   
        }

        init();
 
    }
]);
