angular.module('angus.controllers').controller('gainsToBudgetDetailViewController', [
    '$scope', '$rootScope', '$http', 'hierarchyService', 'moment', '_', 'gridService2', 'nodeType', 'gridState', 'promiseMonitor',
    function($scope, $rootScope, $http, hierarchyService, moment, _, gridService2, nodeType, gridState, PromiseMonitor) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        // kendo chart from dashboard controller gives back converted month as a string, need to convert to number
        var month = Number(moment().month($scope.settings.month).format('M'));

        $scope.dates = {
            startDate: moment.utc().month(month).subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
            endDate: moment.utc().month(month).subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
        };

        $scope.dateLabelStartDate = moment.utc($scope.dates.startDate).format('L');
        $scope.dateLabelEndDate = moment.utc($scope.dates.endDate).format('L');

        $scope.getGainsToBudget = function() {
            $scope.grid.setColDefs(getColDefs());

            var rowPromise = $http({
                method: 'POST',
                url: ('api/subscribers/{0}/budgets/gainsToBudget/detail').format(subscriberId),
                data: {
                    hierarchyNodeId: [$scope.settings.hierarchyNodeId],
                    productCategoryIds: [4, 5, 6, 7, 8, 9], // spes product keys that have propane flag set
                    tradeClassCategoryIds: [$scope.settings.tradeClass],
                    startDate: $scope.dates.startDate,
                    endDate: $scope.dates.endDate,
                    minTankSize: 120
                }
            })
            .then(function(response) {
                _.forEach(response.data, function(gains) {
                    _.forEach(gains.hierarchy, function(hierarchy) {

                        if(hierarchy._id == $scope.settings.hierarchyNodeId) {
                            $scope.selectedHierarchyNodeName = hierarchy.name ? hierarchy.name : hierarchy.nameOverride;
                        }
                    });
                });

                return response.data
            });

            var promise = $scope.grid.setRows(rowPromise);
            $scope.variancePromises = new PromiseMonitor(promise);
        };

        function getColDefs() {
            var colDefs = $scope.nodeColDefs ? _.cloneDeep($scope.nodeColDefs) : [];

            colDefs.push(gridService2.colDef.createDate('acquisitionDate', 'Acquisition Date', 'acquisitionDate', { sort: 'asc'} ));
            colDefs.push(gridService2.colDef.createText('customerId', 'Account Number', 'customerId', { cellClass: ['center'] } ));
            colDefs.push(gridService2.colDef.createText('customerName', 'Customer Name', 'customerName', { cellClass: ['center'] } ));
            colDefs.push(gridService2.colDef.createText('tankId', 'Tank Number', 'tankId', { cellClass: ['center'] } ));
            colDefs.push(gridService2.colDef.createText('capacity', 'Tank Size', 'capacity', { cellClass: ['center'] } ));

            return colDefs;
        }

        var nodeColDefs;

        nodeType
            .getDivisionColDefs(subscriberId)
            .then(function(colDef) {
                nodeColDefs = colDef;
                var gridParams = {
                    gridState: gridState(subscriberId, $scope.widgetCode),
                    defs: getColDefs(),
                    exportOptions: { fileName: 'Gains To Budget' }
                };

                return gridService2.createGrid(gridParams);
            })
            .then(function(grid) {
                $scope.grid = grid;
                $scope.getGainsToBudget();
            })
    }
]);