angular.module('angus.directives').directive('adeptPerformanceAnalysis', [
    'actionViewTemplates',
    function(actionViewTemplates) {
        return {
            scope: {
                model: "=actionModel",
                settings: "=",
                widgetCode: "="
            },
            templateUrl: actionViewTemplates.adeptPerformanceAnalysisReportContainer
        }
    }
])
.directive('adeptPerformanceAnalysisChart', [
    '$', '$filter', 'adeptPerformanceAnalysisService', 'constantsService',
    function($, $filter, adeptPerformanceAnalysisService, constantsService) {
        return {
            scope: {
                data: "=",
                historicalAverage: "=",
                timePeriod: "=",
                month: "=",
                year: "=",
                showHistoricalMarker: "=",
                unitsPerDeliveryAxisMinimum: "="
            },
            link: function(scope, element) {

                /* Watches */
				scope.$watch(function () {
					return scope.data;
				}, function () {
					if (scope.data) {
						renderGraph();
					}
				});

                function renderGraph() {
                   
                    const data = scope.data;
                    const colors = constantsService.graphColors;

                    /* Setup the data to be used for the Series and Tooltips */
                    const metrics = [];
                    /* Non-ADEPT */
                    metrics.push({
                        id: 1,
                        label: 'Non-ADEPT',
                        units: data.nonDynamicReserveUnits,
                        deliveryCount: data.nonDynamicReserveDeliveryCount,
                        unitsPerDelivery: data.nonDynamicReserveUnitsPerDelivery,
                        color: colors[0]
                    });

                    /* ADEPT */
                    metrics.push({
                        id: 2,
                        label: 'ADEPT',
                        units: data.dynamicReserveUnits,
                        deliveryCount: data.dynamicReserveDeliveryCount,
                        unitsPerDelivery: data.dynamicReserveUnitsPerDelivery,
                        color: colors[1]
                    });

                    /* ADEPT On-Time */
                    metrics.push({
                        id: 3,
                        label: 'ADEPT On-Time',
                        units: data.dynamicReserveOnTimeUnits,
                        deliveryCount: data.dynamicReserveOnTimeDeliveryCount,
                        unitsPerDelivery: data.dynamicReserveOnTimeUnitsPerDelivery,
                        color: colors[2]
                    });

                    /* ADEPT Monitored On-Time */
                    metrics.push({
                        id: 4,
                        label: 'ADEPT Monitored On-Time',
                        units: data.dynamicReserveOnTimeMonitoredUnits,
                        deliveryCount: data.dynamicReserveOnTimeMonitoredDeliveryCount,
                        unitsPerDelivery: data.dynamicReserveOnTimeMonitoredUnitsPerDelivery,
                        color: colors[3]
                    });

                    /* Build Chart Series */
                    const bars = {
                        show: true,
                        fill: 1,
                        align: "center",
                        barWidth: .5,
                        horizontal: true
                    }

                    const yTicks = [];
                    const series = [];
                    var maxUnitsPerDelivery = 0;
                    

                    metrics.forEach(function(metric){
                        series.push({
                            data: [[metric.unitsPerDelivery, metric.id]],
                            label: metric.label,
                            bars: bars,
                            color: metric.color
                        });

                        yTicks.push([metric.id, metric.label]);

                        if (maxUnitsPerDelivery < metric.unitsPerDelivery) {
                            maxUnitsPerDelivery = metric.unitsPerDelivery;
                        }
                    });

                    
                    
                    function tooltipContent(label, xval, yval, flotItem) {

                        const metric = metrics.find(metric => metric.id == yval);
                        const unitsPerDeliveryPriorToDynamicReserve = data.unitsPerDeliveryPriorToDynamicReserve;

                        const timePeriods = adeptPerformanceAnalysisService.getTimePeriods();
                        const month = scope.month;
                        const year = scope.year;

                        let timePeriodString;

                        if (scope.timePeriod == timePeriods.month) {
                            const endDate = new Date(year, month, 0);

                            timePeriodString = $filter('date')(endDate, 'MMMM yyyy');
                        }
                        else {
                            const startDate = new Date(year - 1, month - 1, 1);
                            const endDate = new Date(year, month, 0);

                            timePeriodString = `${$filter('date')(startDate, 'MMMM yyyy')} - ${$filter('date')(endDate, 'MMMM yyyy')}`;
                        }

                        const unitsAboveAverage = metric.unitsPerDelivery - unitsPerDeliveryPriorToDynamicReserve;
                        const percentAboveAverage = unitsAboveAverage / unitsPerDeliveryPriorToDynamicReserve * 100;
                        

                        return `${timePeriodString}
                        <br> Units Per Delivery: ${$filter('number')(metric.unitsPerDelivery || 0, 1)}
                        <br> Units: ${$filter('number')(metric.units || 0, 0)}
                        <br> Deliveries: ${$filter('number')(metric.deliveryCount || 0, 0)}
                        <br> Historical Average: ${$filter('number')(unitsPerDeliveryPriorToDynamicReserve || 0, 1)}
                        <br> Units vs Average: ${$filter('number')(unitsAboveAverage || 0, 1)} (${$filter('number')(percentAboveAverage || 0, 1)}%)`;
                        
                    }
                    
                    
                    /* X Axis Min*/
                    var xAxisMin = scope.unitsPerDeliveryAxisMinimum || 0;

                    /* Validation on X Axis Min */
                    if (xAxisMin > maxUnitsPerDelivery) {
                        /* Set to 0 if the Min is greater than the Max Data Point */
                        xAxisMin = 0;
                    }
                    else if (scope.showHistoricalMarker && xAxisMin > data.unitsPerDeliveryPriorToDynamicReserve) {
                        /* Set to 0 if the Min is less than the historical */
                        xAxisMin = 0;
                    }


                    /* X Axis Max*/
                    var xAxisMax = null;
                    if (data.unitsPerDeliveryPriorToDynamicReserve > maxUnitsPerDelivery || maxUnitsPerDelivery === 0) {
                        xAxisMax = data.unitsPerDeliveryPriorToDynamicReserve + (data.unitsPerDeliveryPriorToDynamicReserve * .05); /* Add 5% */
                    }

                    var markings = undefined;
                    if (scope.showHistoricalMarker && data.unitsPerDeliveryPriorToDynamicReserve > 0){
                        markings = [{xaxis: {from: data.unitsPerDeliveryPriorToDynamicReserve - 1, to: data.unitsPerDeliveryPriorToDynamicReserve + 1}, color: colors[7]}]
                    }

                    const options = {
                        legend: {
                            show:false
                        },
                        grid: {
                            markings: markings,
                            borderWidth: 0,
                            hoverable: true
                        },
                        yaxis: {
                            ticks: yTicks,
                            tickLength: 0
                        },
                        xaxis: {
                            min: xAxisMin,
                            max: xAxisMax,
                            axisLabel: 'Units Per Delivery',
                            
                        },
                        tooltip: {
							show: true,
							content: tooltipContent,
							defaultTheme: false
						}
                    };

                    $.plot(element.find('#adeptPerformanceAnalysisChart'), series, options);
                    
                }
            }
        }
    }
]);