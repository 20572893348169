angular.module( 'angus.directives' ).directive( 'appTankUtil', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		'use strict';

		return {
			scope: {
				model: '=actionModel',
				settings: '=tankUtilWidgetSettings',
				widgetCode : '='
			},
			templateUrl: actionViewTemplates.tankUtilReportContainer
		};
	}
] ).directive( 'flotBarChartTankUtil', [
	'$filter', '_',
	function( $filter, _ ) {
		'use strict';

		return {
			scope: {
				ngModel		: '=',
				barClick  : '&',
				turnsThreshold :'=',
			},
			link: function(scope, element){

				'use strict';

				function plot(groupings){

					var colors = [
						'rgba(0,153,211, .75)',
            'rgba(73,191,103, .75)',
            'rgba(248,163,38 ,.75)',
            'rgba(149,100,226, .75)',
            'rgba(243,69,38, .75)'
					];


					var data = _.map(groupings, function(grouping, index){
            grouping.minTankSize = grouping.minTankSize || 0;

            var label = '';
            if(!grouping.maxTankSize){
              label = grouping.minTankSize + '+';
            }
            else{
						  label = grouping.minTankSize + ' - ' + grouping.maxTankSize;
            }

						return {
					        label: label,
					        data: [[grouping.turns, groupings.length - index - 1 ]],
					        color: colors[index],
					        bars:{
					          show: true,
					          fill: true,
					          horizontal : true,
					          align: 'center',
					          lineWidth: 1,
					          barWidth : 0.6,
					          fillColor: colors[index]
					      	}
					      };
					});

					scope.turnsThreshold = scope.turnsThreshold || 0;

					var range = _.range(-1, groupings.length + 1);
					data.push({
				        data: _.map(range, function(val){ return [scope.turnsThreshold || 0, val]}),
				        color: scope.turnsThreshold ? '#FF0000' : '#eeeeee',
				        lines: { show: true }
				    });



					var options = {
							xaxis:{
								axisLabel : 'Turns'
							},
							yaxis: {
							    // tickColor: "#5E5E5E",
							    // ticks: ticks,
							    ticks : false,
								tickLength: 0
							},
							grid:{
								hoverable: true,
								clickable: true,
								borderWidth: 0
							},
							series:{
								shadowSize: 1
							},
							tooltip: false,
					};

					if(scope.use3DMaps) {
					  options.use3DMaps = true;
          }

					$.plot(element, data, options);

					element.bind('plotclick', function (event, pos, item) {
					    if (item) {
					        var index = groupings.length - 1 - item.series.data[0][1];
					        scope.barClick({minTankSize : groupings[index].minTankSize, maxTankSize : groupings[index].maxTankSize  });
					    }
					});
				}

				scope.$watch(function(){
					return scope.ngModel;
				}, function(newVal){
					if(newVal)
						plot(newVal);
				});
			}
		};
	}
] );
;
