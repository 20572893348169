'use strict';

angular.module('angus.controllers').controller('santaPriorPeriodVarSettingsCtrl', [
    '$scope', '$rootScope', '$q', 'constantsService', 'budgetsService', 'hierarchyService', '_',
    function($scope, $rootScope, $q, constantsService, budgetsService, hierarchyService, _) {

        $scope.periodIndicators = constantsService.budgetPeriodIndicators.enums;
        $scope.widget.instance.settings.toggleViewOptions = [{
            value: 'Percents',
            id: 1
        }, {
            value: 'Numbers',
            id: 2
        }];

        $scope.openDivisionHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
            .then(function(nodeId) {
                $scope.widget.instance.settings.hierarchyNodeId = nodeId;
            });
        };

        var budgets = new budgetsService($rootScope.user.subscriberId);

        function selectedCategory(categories, categoryIds){
            _.forEach(categories, function(category){
                category.selected = _.some(categoryIds, function(categoryId){ return category.id == categoryId }) ;
            });
        }

        var params = { sortBy: 'name', ascending: 1 };

        if(  $scope.widget.instance.settings
            && $scope.widget.instance.settings.isFinance
            && $scope.widget.instance.settings.isFinance.value)
            params.onlyFinance = 1;

        $q.all([
            budgets
            .getProducts(params)
            .then(function(productCategories){
                selectedCategory(productCategories, $scope.widget.instance.settings.productCategoryIds ? $scope.widget.instance.settings.productCategoryIds.value : []);
                $scope.productCategories = productCategories;
            }),
            budgets
            .getTradeClasses(params)
            .then(function(tradeClassCategories){
                selectedCategory(tradeClassCategories, $scope.widget.instance.settings.tradeClassCategoryIds ? $scope.widget.instance.settings.tradeClassCategoryIds.value : []);
                $scope.tradeClassCategories = tradeClassCategories;
            }),
            budgets
            .getPricePlans(params)
            .then(function(pricePlanCategories){
                selectedCategory(pricePlanCategories, $scope.widget.instance.settings.pricePlanCategoryIds ? $scope.widget.instance.settings.pricePlanCategoryIds.value : []);
                $scope.pricePlanCategories = pricePlanCategories;
            })
        ]);
    }
]);