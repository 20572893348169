'use strict';

angular.module('angus.controllers').controller('costingCtrl', [
    '$scope', '$http', '$q', 'constantsService', 'hierarchyHelperService', '_', 'productsService', 'tradeClassService', 'modalService',
    'actionViewTemplates', '$', 'hierarchyService', 'Paging', 'fluentRest', 'moment', 'promiseMonitor',
    function($scope, $http, $q, constantsService, hierarchyHelperService, _, productsService, tradeClassService, modalService,
        actionViewTemplates, $, hierarchyService, Paging, fluentRest, moment, promiseMonitor) {

        var otherVendor = 'Other';
        $scope.loadingDetails = {};
        $scope.filter= {};
        var effectiveOrder = "desc";
        function retrieveCostingInfo() {
            $scope.costingInfoLoaded = false;

            delete $scope.settings;
            delete $scope.diffs;
            delete $scope.overrides;

            getEachCostingInfo()
                .then(function(results) {
                    $scope.settings = results[0];

                    // if (Object.keys(results[0].costCategories).length > 0)
                    //     $scope.settings = results[0];

                    if (Object.keys(results[1].costCategories).length > 0)
                        $scope.diffs = results[1];

                    if (Object.keys(results[2].costCategories).length > 0)
                      dateOrdering(results[2],effectiveOrder);
                    // overrides.costCategories[costCategory].pricePlans[pricePlan].effectiveDates
                    $scope.costingInfoLoaded = true;
                });

                getUpdatedActiveHedgePositions();
        }

        function dateOrdering(overrides, order) {

            for (var i in overrides.costCategories) {
                for (var j in overrides.costCategories[i].pricePlans) {
                    if(order == "desc"){
                        Object.keys(overrides.costCategories[i].pricePlans[j].effectiveDates).sort(function(a,b) { return new Date(a).getTime() - new Date(b).getTime()}).reduce(
                            function (obj, key) {
                                obj[key] = overrides.costCategories[i].pricePlans[j].effectiveDates[key];
                                return obj;
                            },
                            {}
                        );
                    }else if(order == "asc"){
                        Object.keys(overrides.costCategories[i].pricePlans[j].effectiveDates).sort(function(a,b) { return new Date(b).getTime() - new Date(a).getTime()}).reduce(
                            function (obj, key) {
                                obj[key] = overrides.costCategories[i].pricePlans[j].effectiveDates[key];
                                return obj;
                            },
                            {}
                        );
                    }
                }
            }

            $scope.overrides = overrides;
        }

        function getEachCostingInfo() {
            return $q
                .all(_.map(Object.keys($scope.pageManager), function(propName) {
                    if ($scope.pageManager[propName].generalCosting)
                        return getCostingInfo(1, propName);
                }));
        }

        function getCostingInfo(pageNumber, costingItemName) {
            if (!costingItemName)
                costingItemName = $scope.activeTab.uriName;
            var params = {
                d: hierarchyService.getLeafNodeEntityIds($scope.divisions, $scope.filter.divisionNodeId),
                effectiveDate: $scope.effectiveDate,
                expirationDate: $scope.expirationDate,
                sortProp: $scope.pageManager[costingItemName].sorting.sortProp,
                sortDir: $scope.pageManager[costingItemName].sorting.sortDir
            };

            return fluentRest
                .api()
                .subscribers($scope.subscriberId)
                .costing()[costingItemName]()
                .get(params);
        }

        function saveCostingInfo(costingItemName, item) {
            var promises = [];

            if(item.pricePlanId && Array.isArray(item.pricePlanId)) { // this is needed for price plan multi-select (in overrides section), which pushes price plans to an array
                item.pricePlanId.forEach(function(pricePlanId) {
                    var newCostInfo = _.cloneDeep(item);
                    newCostInfo.pricePlanId = pricePlanId;
                    promises.push($http.post(('api/subscribers/{0}/costing/').format($scope.subscriberId) + $scope.activeTab.uriName, newCostInfo));
                });
            } else {
                return $http.post(('api/subscribers/{0}/costing/').format($scope.subscriberId) + $scope.activeTab.uriName, item)
            }

            return Promise.all(promises);
        }

        function deleteCostingInfo(costingItemName, item) {
            return $http({
                method: 'DELETE',
                url: ('api/subscribers/{0}/costing/')
                    .format($scope.subscriberId) + $scope.activeTab.uriName,
                params: {
                    ids: _.map(item.keyMappings, function(idKeyValue) {
                        return idKeyValue.value;
                    })
                }
            });
        }
        function getUpdatedActiveCostItems(pageNumber) {
            $scope.costingInfoLoaded = false;
            delete $scope[$scope.activeTab.uriName];

            getCostingInfo(pageNumber || $scope.pageManager[$scope.activeTab.uriName].paging.currentPage)
                .then(function(items) {
                    $scope[$scope.activeTab.uriName] = items;
                    $scope.costingInfoLoaded = true;
                });
        }

        function isolatedSaveUpdateCostingItem(item, existingItem) {
            var that = this;
            if (item) {
                //need to convert to number to SQL
                item.divisions = item.divisions.map(Number); 
                if (existingItem) {
                    item.removedKeyMappings = [];
                    _.forEach(existingItem.keyMappings, function(existingId) {
                        if (!_.some(item.divisions, function(divisionId) {
                                return divisionId === existingId.key;
                            }))
                            item.removedKeyMappings.push(existingId);
                    });
                }
                saveCostingInfo($scope.activeTab.uriName, item)
                    .then(function(newItem) {
                        that.close(newItem);
                    })
                    .catch(function(err) {
                        that.alerts.push({
                            type: 'danger',
                            msg: '<strong>Error!</strong> ' +
                                (err.msg || 'Setting attempted to be saved conflicts with an existing setting. Save was unsuccessful!')
                        });
                    });
            }
        }

        function deleteCostingInfoItem(item, scope) {
            modalService
                .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                .then(function(result) {
                    if (result) {
                        deleteCostingInfo($scope.activeTab.uriName, item)
                            .success(function() {
                                getUpdatedActiveCostItems();
                            });
                    }
                });
        }

        function getHierarchyNodes() {
            return fluentRest
                .api()
                .subscribers($scope.subscriberId)
                .hierarchy()
                .nodes()
                .get({
                    hierarchyType: constantsService.hierarchyType.division.key
                });
        }

        function getProducts() {
            return fluentRest
                .api()
                .subscribers($scope.subscriberId)
                .products()
                .get();
        }

        function getTradeClasses() {
            return tradeClassService
                .getAllTradeClasses($scope.subscriberId);
        }

        function getPricePlans() {
            return fluentRest
                .api()
                .subscribers($scope.subscriberId)
                .pricingPlans()
                .categories()
                .get();
        }

        function getCostingCategories() {
            return fluentRest
                .api()
                .subscribers($scope.subscriberId)
                .costing()
                .categories()
                .get();
        }

        function getHedgeVendors() {
            return fluentRest
                .api()
                .subscribers($scope.subscriberId)
                .costing()
                .hedgePositions()
                .vendors()
                .get();
        }

        function getCommodityIndices() {
            return fluentRest
                .api()
                .subscribers($scope.subscriberId)
                .costing()
                .indices()
                .get();
        }

        function getDecendantLeafNodes() {
            var divisionNode = _.find($scope.divisions, function(node) {
                return node.id == $scope.filter.divisionNodeId;
            });

            if (divisionNode.isLeaf)
                return [divisionNode];

            var descendants = hierarchyService
                .getDescendants($scope.divisions, divisionNode);

            $scope.decendantLeafNodes = _.filter(descendants, function(node) {
                return node.isLeaf;
            });
        }

        function init() {
            $scope.costingInfoLoaded = false;
            $scope.activeTab = $scope.pageManager.settings;

            getHierarchyNodes()
                .then(function(nodes) {
                    $scope.divisions = hierarchyService.convertDataForEaseOfUse(nodes);
                    $scope.filter.divisionNodeId = _.find($scope.divisions, function(node) {
                        return node.isRoot;
                    }).id;

                    retrieveCostingInfo();
                    getDecendantLeafNodes();
                    getUpdatedActiveHedgePositions();

                    return $q.all([getProducts(), getTradeClasses(), getPricePlans(), getCostingCategories(), getHedgeVendors(), getCommodityIndices()]);
                })
                .then(function(results) {
                    $scope.products = results[0];
                    $scope.tradeClasses = results[1];
                    $scope.pricePlans = results[2];
                    $scope.costCategories = filterCostCategories(results[3]);
                    $scope.vendors = results[4];
                    $scope.costIndices = results[5];
                    $scope.vendors.push(otherVendor);
                    var filteredResults = results.map(function(res){
                      return res.filter(function(fil){
                        return fil.id != '-1' && fil.id != '-2';
                      })
                    });

                    //these have unmapped and unknown filtered out.
                    $scope.Fproducts = filteredResults[0];
                    $scope.FtradeClasses = filteredResults[1];
                    $scope.FpricePlans = filteredResults[2];
                    $scope.FcostCategories = filteredResults[3];
                    $scope.Fvendors = filteredResults[4];
                    $scope.FcostIndices = filteredResults[5];
                    //filtered divisionLeafNodes
                    $scope.FdecendantLeafNodes = $scope.decendantLeafNodes.filter(function(node) {
                        return node.entityId != '-1' && node.entityId != '-2'
                    });

                });
        }

        function filterCostCategories(costCategories) {
            return costCategories.filter(function(category){
                return category.name !== 'Unmapped';
            });
        }

        $scope.editCostCategories = function() {

            var scope = {
                costingCategories: $scope.costCategories,
                costIndexes: $scope.costIndices,
                addCategory: function(costingCategories) {
                    costingCategories.push({
                        name: '',
                        hedgeIndexId: ''
                    });
                },
                removeCategory: function(costingCategories, category) {
                    _.remove(costingCategories, function(costCat) {
                        return costCat.name == category.name;
                    });
                }
            };

            modalService
                .openTopBarModal(actionViewTemplates.subRegAddEditCostCategories, scope)
                .then(function(costingCategories) {
                    if (costingCategories) {
                        return fluentRest
                            .api()
                            .subscribers($scope.subscriberId)
                            .costing()
                            .categories()
                            .post(costingCategories)
                            .then(function(categories) {
                                getCostingCategories()
                                    .then(function(categories) {
                                        $scope.costCategories = filterCostCategories(categories);
                                        $scope.FcostCategories = filterCostCategories(categories);
                                        retrieveCostingInfo();
                                    });
                            });
                    }
                });
        };

        $scope.editCostCategoryMappings = function() {

            var scope = {
                products: $scope.products,
                costingCategories: $scope.costCategories
            };

            modalService
                .openTopBarModal(actionViewTemplates.subRegAddEditCostCategoryMapping, scope)
                .then(function(costingCategoryMappings) {
                    if (costingCategoryMappings) {
                        return fluentRest
                            .api()
                            .subscribers($scope.subscriberId)
                            .costing()
                            .categories()
                            .merge()
                            .post(costingCategoryMappings)
                            .then(function(mappedProducts) {
                                $scope.products = mappedProducts;
                                retrieveCostingInfo();
                            });
                    }
                });
        };

        $scope.getNextPage = function(pageNumber) {
            getUpdatedActiveCostItems(pageNumber);
        };

        $scope.sort = function(propName) {
            if (propName != $scope.pageManager[$scope.activeTab.uriName].sorting.sortProp)
                $scope.pageManager[$scope.activeTab.uriName].sorting.sortDir = 'asc';
            else
                $scope.pageManager[$scope.activeTab.uriName].sorting.sortDir = $scope.pageManager[$scope.activeTab.uriName].sorting.sortDir == 'asc' ?
                'desc' : 'asc';

            $scope.getNextPage($scope.pageManager[$scope.activeTab.uriName].paging.currentPage);
        };

        $scope.openHierarchyModal = function() {
            hierarchyService.openModal($scope.filter.divisionNodeId, $scope.divisions, constantsService.hierarchyType.division)
                .then(function(nodeId) {
                    $scope.filter.divisionNodeId = nodeId;

                    retrieveCostingInfo();
                    getDecendantLeafNodes();
                });
        };

        $scope.filtersChanged = function() {
            if ($scope.filter.divisionNodeId) {
                retrieveCostingInfo();
                getDecendantLeafNodes();
            }
        };

        function openEffectiveDate($event) {
            $event.preventDefault();
            $event.stopPropagation();

            this.effectiveDateOpened = true;
        }

        function openEndDate($event) {
            $event.preventDefault();
            $event.stopPropagation();

            this.endDateOpened = true;
        }

        $scope.openEndDate = openEndDate;
        $scope.openEffectiveDate = openEffectiveDate;
        $scope.addEditSetting = function(setting) {
            var scope = {
                formType: setting ?
                    'Edit' : 'New',
                costingCategories: $scope.FcostCategories,
                costBasises: constantsService.costBasisOptions.enums,
                costIndexes: $scope.costIndices,
                divisionLeafNodes: $scope.FdecendantLeafNodes,
                indexComparisonValue: constantsService.costBasisOptions.index.value.id,
                dateOptions: $scope.dateOptions = {
                    formatYear: 'yy',
                    startingDay: 1
                },
                alerts: [],

                closeAlert: function(index) {
                    this.alerts.splice(index, 1);
                },
                openEffectiveDate: openEffectiveDate,
                openEndDate: openEndDate,
                today: function() {
                    $scope.dt = new Date();
                },
                saveSetting: isolatedSaveUpdateCostingItem,
                setting: setting ?_.cloneDeep(setting) : {},
                existingSetting: setting
            };


            modalService
                .openTopBarModal(actionViewTemplates.subRegNewCostSetting, scope)
                .then(function(changedSetting) {
                    if (changedSetting)
                        getUpdatedActiveCostItems();
                });
        };

        $scope.sortSettingsBy = function(sortBy){
            if($scope.pageManager.settings.sorting.sortProp == sortBy)
                $scope.pageManager.settings.sorting.sortDir = !$scope.pageManager.settings.sorting.sortDir;
            else
                $scope.pageManager.settings.sorting.sortProp = sortBy;

            getCostSettings();
        };

        $scope.editSetting = function(setting) {
            setting = _.cloneDeep(setting);

            // need to remove 'Z' from end of returned date strings so uib-datepicker-popup input AND html view both display correct utc date
            var utcTimeActualEffectiveDate = setting.effectiveDate.slice(0, -1);
            var utcTimeActualExpirationDate = setting.expirationDate ? setting.expirationDate.slice(0, -1) : null;
            setting.effectiveDate = setting.effectiveDate ? moment(utcTimeActualEffectiveDate).toDate() : undefined;
            setting.expirationDate = setting.expirationDate ? moment(utcTimeActualExpirationDate).toDate() : undefined;

            var scope = {
                costingCategories: $scope.FcostCategories,
                costBasises: constantsService.costBasisOptions.enums,
                costIndexes: $scope.costIndices,
                divisionLeafNodes: $scope.decendantLeafNodes,
                indexComparisonValue: constantsService.costBasisOptions.index.value.id,
                dateOptions: $scope.dateOptions = {
                    formatYear: 'yy',
                    startingDay: 1
                },
                alerts: [],
                closeAlert: function(index) {
                    this.alerts.splice(index, 1);
                },
                openEffectiveDate: openEffectiveDate,
                openEndDate: openEndDate,
                today: function() {
                    $scope.dt = new Date();
                },
                saveSetting: function(setting){
                    var that = this;

                    _.each(scope.divisionLeafNodes, function(leafNode) {
                        if( (setting.divisionId === leafNode.entityId) && (setting.divisionName === leafNode.name) ) {
                            setting.divisionName = leafNode.name;

                            return setting;
                        }
                    });

                    return $http({
                        method: 'PUT',
                        url: 'api/subscribers/{0}/costing/settings/{1}'.format($scope.subscriberId, setting.id),
                        data: {
                            setting : setting
                        }
                    })
                    .success(function() {
                        that.close(true);
                    })
                    .error(function(err) {
                        that.alerts.push({
                            type: 'danger',
                            msg: '<strong>Error!</strong> ' + (err.msg || 'Setting attempted to be saved conflicts with an existing setting. Save was unsuccessful!')
                        });
                    });
                },
                setting: _.cloneDeep(setting)
            };


            modalService
                .openTopBarModal(actionViewTemplates.subRegEditCostSetting, scope)
                .then(function(result){
                    if(!result) return;
                    getCostSettings();
                });
        };

        function getCostSettings(){
            var params = {
                d: hierarchyService.getLeafNodeEntityIds($scope.divisions, $scope.filter.divisionNodeId),
                effectiveDate: $scope.effectiveDate,
                expirationDate: $scope.expirationDate,
                sortProp: $scope.pageManager.settings.sorting.sortProp,
                sortDir: $scope.pageManager.settings.sorting.sortDir
            };

            return fluentRest
                .api()
                .subscribers($scope.subscriberId)
                .costing()
                .settings()
                .get(params)
                .then(function(settings){
                    $scope.settings = settings;
                });
        }

        $scope.toggleAllSettings = function(selected){
            _.forEach($scope.settings,function(s){
                s.selected = selected;
            });
        }

        $scope.anySelected = function(){
            return _.some($scope.settings,function(s){
                return s.selected;
            });
        }

        $scope.deleteSettings = function() {

            var selectedSettingIds = _
                .chain($scope.settings)
                .filter(function(s){
                    return s.selected;
                })
                .map(function(s){
                    return s.id;
                })
                .value();

            var scope = {
                message : 'Delete {0} settings?'.format(selectedSettingIds.length),
                title : 'Delete settings'
            };

            modalService
                .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                .then(function(response) {
                    if(!response) return;

                     return $http({
                        method: 'DELETE',
                        url: 'api/subscribers/{0}/costing/settings'.format($scope.subscriberId),
                        params: {
                            settingIds: selectedSettingIds
                        }
                    })
                     .then(function(){
                        getCostSettings();
                     });
                });
        };

        $scope.deleteSetting = function(setting) {
            deleteCostingInfoItem(setting, {
                title: 'Delete Setting',
                message: 'Are you sure you want to delete this setting?'
            });
        };

        $scope.addEditDiff = function(diff) {
            if (diff) {
                diff.effectiveDate = diff.effectiveDate ? new Date(diff.effectiveDate) : '';
                diff.expirationDate = diff.expirationDate ? new Date(diff.expirationDate) : '';
            }
            var scope = {
                formType: diff ?
                    'Edit' : 'New',
                costingCategories: $scope.costCategories,
                differentialTypes: constantsService.costDiffOptions.enums,
                pricePlans: $scope.FpricePlans,
                divisionLeafNodes: $scope.FdecendantLeafNodes,
                dateOptions: $scope.dateOptions = {
                    formatYear: 'yy',
                    startingDay: 1
                },
                alerts: [],

                closeAlert: function(index) {
                    this.alerts.splice(index, 1);
                },
                openEffectiveDate: openEffectiveDate,
                openEndDate: openEndDate,
                today: function() {
                    $scope.dt = new Date();
                },
                saveDiff: isolatedSaveUpdateCostingItem,
                diff: diff ? setToString(diff) : {},
                existingDiff: diff,
            };


            modalService
                .openTopBarModal(actionViewTemplates.subRegNewCostDiff, scope)
                .then(function(changedDiff) {
                    if (changedDiff)
                        getUpdatedActiveCostItems();
                });
        };

        function setToString(diff) {
            var newDiff = _.cloneDeep(diff);
            newDiff.divisions = diff.divisions.map(String);
            return newDiff;
        } 

        $scope.deleteDiff = function(diff) {
            deleteCostingInfoItem(diff, {
                title: 'Delete Diff',
                message: 'Are you sure you want to delete this diff?'
            });
        };

        $scope.addEditOverride = function(override) {
            if (override) {
                override.effectiveDate = override.effectiveDate ? new Date(override.effectiveDate) : '';
                override.expirationDate = override.expirationDate ? new Date(override.expirationDate) : '';
            }
            var scope = {
                formType: override ?
                    'Edit' : 'New',
                costingCategories: $scope.FcostCategories,
                pricePlans: $scope.FpricePlans,
                tradeClasses: $scope.tradeClasses,
                divisionLeafNodes: $scope.FdecendantLeafNodes,
                dateOptions: $scope.dateOptions = {
                    formatYear: 'yy',
                    startingDay: 1
                },
                alerts: [],

                closeAlert: function(index) {
                    this.alerts.splice(index, 1);
                },
                openEffectiveDate: openEffectiveDate,
                openEndDate: openEndDate,
                today: function() {
                    $scope.dt = new Date();
                },
                saveOverride: isolatedSaveUpdateCostingItem,

                override: override ?
                    _.cloneDeep(override) : {},
                existingOverride: override
            };


            modalService
                .openTopBarModal(actionViewTemplates.subRegNewCostOverride, scope)
                .then(function(changedOverride) {
                    if (changedOverride)
                        getUpdatedActiveCostItems();
                });
        };

        $scope.deleteOverride = function(override) {
            deleteCostingInfoItem(override, {
                title: 'Delete Override',
                message: 'Are you sure you want to delete this override?'
            });
        };

        $scope.includeNoDiv = false;

        // hedge functions
        function getHedgePositions(pageNumber) {
            var params = {
                d: hierarchyService.getLeafNodeEntityIds($scope.divisions, $scope.filter.divisionNodeId),
                effectiveDate: $scope.effectiveDate,
                expirationDate: $scope.expirationDate,
                sortProp: $scope.pageManager.hedges.sorting.sortProp,
                includeNoDiv: $scope.includeNoDiv,
                sortDir: $scope.pageManager.hedges.sorting.sortDir
            };

            return $scope.pageManager.hedges.paging.getPage(('api/subscribers/{0}/costing/hedgePositions')
                .format($scope.subscriberId), pageNumber, params);
        }

        function deleteHedge(headerId) {
            return $http
                .delete(('api/subscribers/{0}/costing/hedgePositions/{1}')
                    .format($scope.subscriberId, headerId));
        }

        function getHedgeDetails(headerId) {
            return fluentRest
                .api()
                .subscribers($scope.subscriberId)
                .costing()
                .hedgePositions(headerId)
                .details()
                .get();
        }

        function saveHedgePosition(item) {
            return $http
                .post(('api/subscribers/{0}/costing/hedgePositions/{1}')
                    .format($scope.subscriberId, item.id || '0'), item);
        }

        function deleteHedgePosition(headerId, scope) {
            modalService
                .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                .then(function(result) {
                    if (result) {
                        deleteHedge(headerId)
                            .success(function() {
                                getUpdatedActiveHedgePositions();
                            });
                    }
                });
        }

        function getUpdatedActiveHedgePositions(pageNumber) {
            $scope.hedgePositionsLoaded = false;
            delete $scope.hedges;

            getHedgePositions(pageNumber || $scope.pageManager.hedges.paging.currentPage)
                .then(function(pagedResponse) {
                    if (pagedResponse && pagedResponse.items.length > 0)
                        $scope.hedges = pagedResponse.items;

                    $scope.hedgePositionsLoaded = true;
                });
        }

        $scope.getPage = getUpdatedActiveHedgePositions;

        $scope.sort = function(propName) {
            if (propName != $scope.pageManager.hedges.sorting.sortProp)
                $scope.pageManager.hedges.sorting.sortDir = 'asc';
            else
                $scope.pageManager.hedges.sorting.sortDir = $scope.pageManager.hedges.sorting.sortDir == 'asc' ? 'desc' : 'asc';

            $scope.pageManager.hedges.sorting.sortProp = propName;
            getUpdatedActiveHedgePositions();
        };

        function convertMonthYearToDate(monthYear) {
            var dates = monthYear.split('/');
            return moment().month(Number(dates[0]) - 1).year(dates[1]);
        }

        function convertKeyDateToDate(keyDate, toDate) {
            var value = keyDate.toString();
            var year = value.substring(0, 4);
            var month = value.substring(4, 6);
            var day = value.substring(6, 8);

            var newMoment = moment().year(Number(year)).month(Number(month) - 1).date(Number(day));

            if (toDate)
                return newMoment.toDate();

            return newMoment;
        }

        function getTotalVolume(details) {
            return _.reduce(details, function(results, detail) {
                if (!detail.forDeletion)
                    results.volume += Number(detail.volume);
                return results
            }, {
                volume: 0
            }).volume;
        }

        function getRemainingVolume(hedge) {
            return (hedge.totalVolume || 0) - getTotalVolume(hedge.details);
        }

        function determineDateFormat(date) {
            return typeof(date) != 'object' ?
                convertKeyDateToDate(date, true) : date;
        }

        $scope.addEditHedge = function(hedge) {
            if (hedge) {
                hedge.purchaseDate = determineDateFormat(hedge.purchaseDate);
                hedge.effectiveDate = determineDateFormat(hedge.effectiveDate);
                hedge.expirationDate = determineDateFormat(hedge.expirationDate);

                hedge.effectiveDisplayDate = moment(hedge.effectiveDate).format('MM/YYYY');
                hedge.expirationDisplayDate = moment(hedge.expirationDate).format('MM/YYYY');
            }


            var scope = {
                formType: hedge ? 'Edit' : 'New',
                hedge: hedge ? _.cloneDeep(hedge) : {},
                divisionLeafNodes: $scope.FdecendantLeafNodes,
                costingCategories: $scope.FcostCategories,
                hedgeTypes: constantsService.hedgeTypes.enums,
                vendors: $scope.vendors,
                dateOptions: $scope.dateOptions = {
                    formatYear: 'yy',
                    startingDay: 1
                },
                alerts: [],
                hedgeWetBarrel: constantsService.hedgeTypes.wetBarrel.value.id,
                otherVendor: otherVendor,

                closeAlert: function(index) {
                    this.alerts.splice(index, 1);
                },
                chooseEffMonth: function(hedge) {
                    var expirationDate = hedge.expirationDate ? convertMonthYearToDate(hedge.expirationDisplayDate).toDate() : undefined;

                    return modalService
                        .openTopBarModal(actionViewTemplates.monthPicker, {
                            maxDate: expirationDate
                        })
                        .then(function(date) {
                            if (date) {
                                hedge.effectiveDate = date;
                                hedge.effectiveDisplayDate = moment(date).format('MM/YYYY');
                            }
                        });
                },
                chooseExpMonth: function(hedge) {
                    var effectiveDate = hedge.expirationDate ? convertMonthYearToDate(hedge.effectiveDisplayDate).toDate() : undefined;

                    return modalService
                        .openTopBarModal(actionViewTemplates.monthPicker, {
                            minDate: effectiveDate
                        })
                        .then(function(date) {
                            if (date) {
                                hedge.expirationDate = date;
                                hedge.expirationDisplayDate = moment(date).format('MM/YYYY');
                            }
                        });
                },
                openPurchaseDate: function($event) {
                    $event.preventDefault();
                    $event.stopPropagation();

                    this.purchaseDateOpened = true;
                },
                today: function() {
                    $scope.dt = new Date();
                },
                saveHedge: function(item) {

                    var that = this;

                    function validateTotalVolume() {
                        return getTotalVolume(item.details) == item.totalVolume;
                    }

                    if (validateTotalVolume()) {
                        if (item) {
                            saveHedgePosition(item)
                                .success(function() {
                                    // add new vendor to dropdown list
                                    if (item.vendor == otherVendor && item.newVendor) {
                                        getHedgeVendors()
                                            .then(function(vendors) {
                                                $scope.vendors = vendors;
                                                $scope.vendors.push(otherVendor);
                                            });
                                    }

                                    that.close(true);
                                })
                                .error(function(err) {
                                    that.alerts.push({
                                        type: 'danger',
                                        msg: '<strong>Error!</strong> ' +
                                            (err.msg || 'Hedge Position attempted to be saved conflicts with an existing hedge. Save was unsuccessful!')
                                    });
                                });
                        }
                    } else {
                        this.alerts.push({
                            type: 'danger',
                            msg: '<strong>Error!</strong> ' +
                                ('Monthly hedge volumes do not equal the total volume!')
                        });
                    }
                },

                updateDetails: function() {
                    var hedgeCopy = this.hedge;

                    if (hedgeCopy.effectiveDisplayDate && hedgeCopy.expirationDisplayDate) {
                        var effectiveDate = convertMonthYearToDate(hedgeCopy.effectiveDisplayDate).startOf('month');
                        var expirationDate = convertMonthYearToDate(hedgeCopy.expirationDisplayDate).endOf('month');

                        // pad with extra days to account for moment treating a month as 30 days
                        var range = expirationDate.diff(effectiveDate.add(15, 'day'), 'month');
                        var tempArray = [];


                        for (var i = 0; i <= range; i++) {
                            var yearMonth = moment(effectiveDate).add(i, 'month').format('YYYYMM');
                            var match = _.find(hedgeCopy.details, function(detail) {
                                return detail.deliveryDate == yearMonth && !detail.forDeletion;
                            });

                            if (!match) {
                                tempArray.push({
                                    deliveryDate: yearMonth,
                                    volume: Math.round(hedgeCopy.totalVolume / (range + 1)),
                                    strikePrice: 0,
                                    // unitCost    : (hedgeCopy.hedgeTypeId != constantsService.hedgeTypes.wetBarrel.value.id) ? 0.0001 : undefined,
                                    id: 0
                                });
                            } else {
                                tempArray.push(match);
                                _.remove(hedgeCopy.details, function(detail) {
                                    return detail.deliveryDate == yearMonth && !detail.forDeletion;
                                });
                            }
                        }

                        // tack on the list of detail rows to be deleted
                        _.forEach(hedgeCopy.details, function(detail) {
                            if (detail.id > 0) {
                                detail.forDeletion = true;
                                tempArray.push(detail);
                            }
                        });

                        this.hedge.details = tempArray;
                        this.hedge.volumeRemaining = getRemainingVolume(this.hedge);

                        if (this.hedge.volumeRemaining < 0) {
                            var totalVolume = this.hedge.totalVolume;
                            _.forEach(this.hedge.details, function(detail) {
                                if (!detail.forDeletion)
                                    detail.volume = Math.round(totalVolume / (range + 1));
                            });
                            this.hedge.volumeRemaining = getRemainingVolume(this.hedge);
                        }

                        if (this.hedge.volumeRemaining !== 0) {
                            var pos = _.findLastIndex(this.hedge.details, function(detail) {
                                return !detail.forDeletion;
                            });
                            this.hedge.details[pos].volume += this.hedge.volumeRemaining;
                            this.hedge.volumeRemaining = 0;
                        }
                    }

                    this.activeTab = this.details;
                },

                calculateRemainingVolume: function() {
                    this.hedge.volumeRemaining = getRemainingVolume(this.hedge);
                }
            };

            scope.info = 'info';
            scope.details = 'details';
            scope.activeTab = scope.info;


            if (hedge) {
                $scope.loadingDetails[hedge.id] = true;

                getHedgeDetails(hedge.id)
                    .then(function(details) {
                        scope.hedge.details = details;
                        $scope.loadingDetails[hedge.id] = false;

                        modalService
                            .openTopBarModal(actionViewTemplates.subRegNewHedgePosition, scope)
                            .then(function(changedHedges) {
                                if (changedHedges)
                                    getUpdatedActiveHedgePositions();
                            });
                    })
            } else {
                scope.hedge.details = [];

                modalService
                    .openTopBarModal(actionViewTemplates.subRegNewHedgePosition, scope)
                    .then(function(changedHedges) {
                        if (changedHedges)
                            getUpdatedActiveHedgePositions();
                    });
            }
        };

        $scope.deleteHedge = function(headerId) {
            deleteHedgePosition(headerId, {
                title: 'Delete Hedge',
                message: 'Are you sure you want to delete this hedge position?'
            });
        };


        var headingClass = 'text-center';
        var sortableHeadingClass = 'text-center clickable';
        var pageSize = 10;

        $scope.pageManager = {
            settings: {
                uriName: 'settings',
                generalCosting: true,
                sorting: {
                    sortProp: 'costingCategoryName',
                    sortDir: 'asc'
                },
                paging: new Paging(pageSize),
                collapse: {},
                headings: [{
                    name: 'Cost Category',
                    isHidable: false,
                    propClass: headingClass,
                    propName: 'costingCategoryName',
                    isSortable: false
                }, {
                    name: 'Cost Basis',
                    isHidable: false,
                    propClass: headingClass,
                    propName: 'costBasisName',
                    isSortable: false
                }, {
                    name: 'Effective Date',
                    isHidable: false,
                    propClass: headingClass,
                    propName: 'effectiveDate',
                    isSortable: false
                }, {
                    name: 'Expiration Date',
                    isHidable: false,
                    propClass: headingClass,
                    propName: 'expirationDate',
                    isSortable: false
                }, {
                    name: '',
                    isHidable: false,
                    propClass: '',
                    propName: '',
                    isSortable: false
                }, {
                    name: '',
                    isHidable: false,
                    propClass: '',
                    propName: '',
                    isSortable: false
                }]
            },
            diffs: {
                uriName: 'diffs',
                generalCosting: true,
                sorting: {
                    sortProp: 'costingCategoryName',
                    sortDir: 'asc'
                },
                paging: new Paging(pageSize),
                collapse: {},
                headings: [{
                    name: 'Cost Category',
                    isHidable: false,
                    propClass: headingClass,
                    propName: 'costingCategoryName',
                    isSortable: false
                }, {
                    name: 'Differential Type',
                    isHidable: false,
                    propClass: headingClass,
                    propName: 'differentialTypeName',
                    isSortable: false
                }, {
                    name: 'Price Plan',
                    isHidable: false,
                    propClass: headingClass,
                    propName: 'pricePlanName',
                    isSortable: false
                }, {
                    name: 'Effective Date',
                    isHidable: false,
                    propClass: headingClass,
                    propName: 'effectiveDate',
                    isSortable: false
                }, {
                    name: 'Expiration Date',
                    isHidable: false,
                    propClass: headingClass,
                    propName: 'expirationDate',
                    isSortable: false
                }, {
                    name: 'Unit Differential',
                    isHidable: false,
                    propClass: headingClass,
                    propName: 'unitDifferential',
                    isSortable: false
                }, {
                    name: '',
                    isHidable: false,
                    propClass: '',
                    propName: '',
                    isSortable: false
                }, {
                    name: '',
                    isHidable: false,
                    propClass: '',
                    propName: '',
                    isSortable: false
                }]
            },
            overrides: {
                uriName: 'overrides',
                generalCosting: true,
                sorting: {
                    sortProp: 'effectiveDate',
                    sortDir: 'desc'
                },
                paging: new Paging(pageSize),
                collapse: {},
                headings: [{
                    name: 'Cost Category',
                    isHidable: false,
                    propClass: headingClass,
                    propName: 'costCategory',
                    isSortable: false
                }, {
                    name: 'Price Plan',
                    isHidable: false,
                    propClass: headingClass,
                    propName: 'pricePlanName',
                    isSortable: false
                }, {
                    name: 'Effective Date',
                    isHidable: false,
                    propClass: headingClass,
                    propName: 'effectiveDate',
                    isSortable: false
                }, {
                    name: 'Expiration Date',
                    isHidable: false,
                    propClass: headingClass,
                    propName: 'expirationDate',
                    isSortable: false
                }, {
                    name: 'Unit Cost',
                    isHidable: false,
                    propClass: headingClass,
                    propName: 'unitCost',
                    isSortable: false
                }, {
                    name: '',
                    isHidable: false,
                    propClass: '',
                    propName: '',
                    isSortable: false
                }, {
                    name: '',
                    isHidable: false,
                    propClass: '',
                    propName: '',
                    isSortable: false
                }]
            },
            hedges: {
                uriName: 'hedges',
                generalCosting: false,
                sorting: {
                    sortProp: 'purchaseDate',
                    sortDir: 'desc'
                },
                paging: new Paging(pageSize),
                collapse: {},
                headings: [{
                    name: 'Contract Number',
                    isHidable: false,
                    propClass: sortableHeadingClass,
                    propName: 'contactNum',
                    isSortable: true
                }, {
                    name: 'Cost Category',
                    isHidable: false,
                    propClass: sortableHeadingClass,
                    propName: 'costingCategoryName',
                    isSortable: true
                }, {
                    name: 'Purchase Date',
                    isHidable: false,
                    propClass: sortableHeadingClass,
                    propName: 'purchaseDate',
                    isSortable: true
                }, {
                    name: 'Hedge Type',
                    isHidable: false,
                    propClass: sortableHeadingClass,
                    propName: 'hedgeTypeName',
                    isSortable: true
                }, {
                    name: 'Vendor',
                    isHidable: false,
                    propClass: sortableHeadingClass,
                    propName: 'vendor',
                    isSortable: true
                }, {
                    name: 'Effective Date',
                    isHidable: false,
                    propClass: sortableHeadingClass,
                    propName: 'effectiveDate',
                    isSortable: true
                }, {
                    name: 'Expiration Date',
                    isHidable: false,
                    propClass: sortableHeadingClass,
                    propName: 'expirationDate',
                    isSortable: true
                }, {
                    name: 'Total Volume',
                    isHidable: false,
                    propClass: sortableHeadingClass,
                    propName: 'totalVolume',
                    isSortable: true
                }, {
                    name: '',
                    isHidable: false,
                    propClass: '',
                    propName: '',
                    isSortable: false
                }, {
                    name: '',
                    isHidable: false,
                    propClass: '',
                    propName: '',
                    isSortable: false
                }]
            }
        };

        init();

    }
]);
