angular.module('angus.controllers').controller('tmsDeliveryEfficiencyCtrl', ['$rootScope','$scope', '_', 'constantsService', 'fluentRest', 'datePickerService', 'dateCalculator', 'moment', 'fiscalYearService',
    function($rootScope, $scope, _, constantsService, fluentRest, datePickerService, dateCalculator, moment, fiscalYearService) {
        'use strict';

        $scope.periodIndicators = constantsService.paygoPeriodIndicators.enums;
        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;


        /* Default the Widget Settings. */
        if (typeof $scope.widget.instance.settings.periodIndicatorId === 'undefined') {
            $scope.widget.instance.settings.periodIndicatorId = {value: constantsService.paygoPeriodIndicators.priorMonth.key};
        }

        if (typeof $scope.widget.instance.settings.paygoProductIds === 'undefined') {
            $scope.widget.instance.settings.paygoProductIds = [];
        }

        if (typeof $scope.widget.instance.settings.dealers === 'undefined') {
            $scope.widget.instance.settings.dealers = [];
        }

        if (typeof $scope.widget.instance.settings.rangeIncrement === 'undefined'){
            $scope.widget.instance.settings.rangeIncrement = 5;
        }

        var periodIndicators = constantsService.paygoPeriodIndicators.enums;

        function getDateTooltipText(){
            var start = $scope.dates.startDate ? $scope.dates.startDate.format('L') : '';
            var end = $scope.dates.endDate ? $scope.dates.endDate.format('L') : '';

            return start + (!!start && !!end ? ' - ' : '') + end;
        }

        /*
         Build the Ranges object. We can always assume the first Range will be <= the Range Increment, while the last Range will always be > 100.
         So we just need to build the inner ranges based on the Range Increment.
         Example using 25 as the Increment:
         ranges = [
           {id: 0, name: '<= 25', start: -2147483648, end: 25},
           {id: 1, name: '26-50', start: 26, end: 50},
           {id: 2, name: '51-75', start: 51, end: 75},
           {id: 3, name: '76-100', start: 76, end: 100},
           {id: 4, name: '> 100', start: 101, end: 2147483647}
         ];
         */
        function getRanges(){

            var rangeIncrement = $scope.widget.instance.settings.rangeIncrement;

            /* Add the First Range. */
            var ranges = [{id: 0, name: '<= ' + rangeIncrement, start: -2147483648, end: rangeIncrement}];

            for (var i = rangeIncrement; i < 100; i += rangeIncrement){
                ranges.push({id: ranges.length, name: (i + 1) + '-' + (i + rangeIncrement), start: i + 1, end: i + rangeIncrement});
            }

            /* Add the final Range */
            /* NOTE: It was requested to ignore any deliveries that went over 100%. Leaving this here in case it's requested to add it back.
            ranges.push({id: ranges.length, name: '> 100', start: 101, end: 2147483647});
            */

            return ranges;
        }

        function getDeliveryEfficiencySummary(){

            var params = {
                startDate : $scope.dates.startDate.format(),
                endDate: $scope.dates.endDate.format(),
                dealerIds: $scope.widget.instance.settings.dealers,
                paygoProducts: $scope.widget.instance.settings.paygoProductIds,
                ranges: getRanges()
            };

            console.log('params to getDeliveryEff call', params.startDate)

            return fluentRest
                .api()
                .subscribers($rootScope.user.subscriberId)
                .tms()
                .deliveryEfficiencySummary()
                .get(params)
                .then(function(data){
                    $scope.ranges = data;

                    /* The server should return the full list of ranges regardless if they have any data.
                      Therefor we need to set a property to determine if data was found and cannot use Array.length.
                     */
                    if (_.find(data, function(range){return range.rangeCount > 0;})){
                        $scope.hasData = true;
                    } else{
                        $scope.hasData = false;
                    }
                });
        }

        $scope.openDatePicker = function() {
            return datePickerService.openDatePickerModal(
                $scope.dates.startDate,
                $scope.dates.endDate,
                moment())
                .then(function(resultDates){
                    $scope.dates.startDate = moment(resultDates[0].isValid() ? resultDates[0] : resultDates[1]);
                    $scope.dates.endDate = resultDates[1];


                    if($scope.widget.instance.settings.periodIndicatorId.value == constantsService.paygoPeriodIndicators.customDate) {
                        $scope.periodIndicator = constantsService.paygoPeriodIndicators.customDate;
                        $scope.widget.instance.settings.customDate = null;
                        $scope.widget.instance.settings.displayDate = moment($scope.dates.startDate).format('MMM DD, YYYY') + ' to ' + moment($scope.dates.endDate).format('MMM DD, YYYY');
                    }

                    if($scope.widget.instance.settings.periodIndicatorId.value == constantsService.paygoPeriodIndicators.customDateRange) {
                        $scope.periodIndicator = constantsService.paygoPeriodIndicators.customDateRange;
                        $scope.widget.instance.settings.customDate = null;
                        $scope.widget.instance.settings.displayDate = moment($scope.dates.startDate).format('MMM DD, YYYY') + ' to ' + moment($scope.dates.endDate).format('MMM DD, YYYY');
                    }

                    return $scope.widget.promises.monitor(getDeliveryEfficiencySummary());
                });
        };

        function dateDisplay() {
            $scope.widget.instance.settings.displayDate =
                !$scope.widget.instance.settings.customDate ?
                    moment($scope.dates.startDate).format('MMM DD, YYYY') + ' to ' + moment($scope.dates.endDate).format('MMM DD, YYYY') :
                    moment($scope.widget.instance.settings.customDate.startDate).format('MMM DD, YYYY') + ' to ' + moment($scope.widget.instance.settings.customDate.endDate).format('MMM DD, YYYY');
        }

        function init(){

            $scope.widget.noContentFlag = false;
            $scope.periodIndicators = constantsService.paygoPeriodIndicators.enums;
            $scope.periodIndicator = _.find(periodIndicators, function(period) {
                return period.key == $scope.widget.instance.settings.periodIndicatorId.value;
            }).value.display;

            /* Setup Dates */
            $scope.dates = {};
            var dates = dateCalculator.calculatePeriod($scope.dates.startDate, moment.utc(), $scope.widget.instance.settings.periodIndicatorId.value, true);
            $scope.dates.startDate = dates.startDate;
            $scope.dates.endDate = dates.endDate;

            if($scope.widget.instance.settings.periodIndicatorId && $scope.widget.instance.settings.periodIndicatorId.value == 'yearToDate') {
                $scope.dates.startDate = moment().startOf('year');
                $scope.dates.endDate = moment.utc();
            }

            if($scope.widget.instance.settings.periodIndicatorId && $scope.widget.instance.settings.periodIndicatorId.value == 'fiscalYearToDate') {
                fiscalYearService
                    .getFiscalYearStartMonth(subscriberId)
                        .then(function(fiscalYearStartMonth) {
                            var startFisc = fiscalYearStartMonth;
                            var currentMonth = moment().month();

                            if (currentMonth >= startFisc) {
                                $scope.dates.startDate = moment().month(startFisc).startOf('month');
                                $scope.dates.endDate = moment.utc();
                            } else {
                                $scope.dates.startDate = moment().month(startFisc).startOf('month').subtract(1, 'year');
                                $scope.dates.endDate = moment.utc();
                            }
                            console.log('dates from fisc yr', $scope.dates)
                            dateDisplay();
                            return $scope.widget.promises.monitor(getDeliveryEfficiencySummary());
                        })
            }

            $scope.widget.instance.settings.startDate   = $scope.dates.startDate;
            $scope.widget.instance.settings.endDate     = $scope.dates.endDate;

            dateDisplay();
            return $scope.widget.promises.monitor(getDeliveryEfficiencySummary());
        }

        $scope.widget.setDefaultInitialization(init);
        $scope.$on('widgetSettingsChanged', init);

}]);