angular.module( 'angus.controllers' ).controller( 'subRegStandardMappingCtrl', [
	'$scope', '$rootScope', '$http', '$q', '_', 'modalService', 'actionViewTemplates', 'Paging', 'fluentRest', 'productsService','promiseMonitor',
	function( $scope, $rootScope, $http, $q, _, modalService, actionViewTemplates , Paging, fluentRest, productsService, PromiseMonitor ) {

		'use strict';

		$scope.paging  			   = new Paging($scope.pageSize);
		$scope.pageSize 		   = 25;
		$scope.sortProp 		   = 'code';
		$scope.sortDir  		   = 'asc';
		$scope.actionViewTemplates = actionViewTemplates;

		function getSources(pageNumber){
			var params = {
				sortDir            : $scope.sortDir,
				sortProp           : $scope.sortProp,
				sourceSystemKey    : $scope.selected.sourceSystemKey
			};

			return $scope
				.paging
				.getPage(('api/subscribers/{0}/source{1}').format($scope.subscriberId, $scope.urlPortion), pageNumber, params)
				.then(function(sources){
					$scope.sources = sources.items;
				});
		}

		function getMappings(){
			return $http
					.get(('api/subscribers/{0}/{1}').format($scope.subscriberId, $scope.urlPortion))
					.then(function(mappings){
							$scope.mappings = mappings.data
							//use this to filter out unmapped and unknowns for clones
							$scope.filteredMappings = mappings.data.filter(function(mapping){
								if(mapping.id != "-1" && mapping.id != "-2"){
									return mapping;
								}
							});
							//use this when you need unmapped but not known
							$scope.filteredWithUnMapped = mappings.data.filter(function(mapping){
								return mapping.id != "-1";
							})
					});
		}

		function getReportingMappings(){
			return $http
					.get(('api/subscribers/{0}/reporting{1}').format($scope.subscriberId, $scope.urlPortion))
					.then(function(reportingMappings){ $scope.reportingMappings = reportingMappings.data; });
		}

		function getPricePlanCategories(){
			return $http
					.get(('api/subscribers/{0}/pricingPlans/categories').format($scope.subscriberId))
					.then(function(pricePlanCategories){
						 $scope.pricePlanCategories = pricePlanCategories.data;
					 });
		}

		function getProductCategories() {
			return productsService.getProductCategories($scope.subscriberId)
				.then(function (productCategories) {
					$scope.productCategories = productCategories;
					
				});
		}

		function init(){
			var promises = [ 
				getSources(),
				getMappings(),
				getPricePlanCategories(),
				getProductCategories()
			];

			if($scope.hasReportingMappings) promises.push( getReportingMappings());

			return $q.all(promises);
		}

		function mergeMappings(mappings){
			if(!mappings) return;

			return $http
				.put(('api/subscribers/{0}/{1}').format($scope.subscriberId, $scope.urlPortion), mappings)
				.then(function(mappings){ return getMappings(); });
		}

		function mergeReportingMappings(reportingMappings){
			if(!reportingMappings) return;

			return $http
					.put(('api/subscribers/{0}/reporting{1}').format($scope.subscriberId, $scope.urlPortion), reportingMappings)
					.then(function(reportingMappings){ $scope.reportingMappings = reportingMappings.data; });
		}

		$scope.getSources = getSources;
		$scope.filtersChanged = getSources;
		$scope.getPage = getSources;

		$scope.mappingSourceSystemKeys = function(){
			return $http
					.get(('api/subscribers/{0}/sourceSystemKey').format($scope.subscriberId, $scope.urlPortion))
					.then(function(reportingMappings){ return reportingMappings.data; });
		}

		$scope.sort = function(propName) {
			if (propName != $scope.sortProp) $scope.sortDir = 'asc';
			else $scope.sortDir = $scope.sortDir == 'asc' ? 'desc' : 'asc';
			$scope.sortProp = propName;
			getSources($scope.paging.currentPage)
		};


		$scope.saveMappings= function(){
			var data = _
				.map($scope.sources, function(source){
					return {
						source : source.id,
						mapped : source.mappedId
					};
				});

			return $http.put(('api/subscribers/{0}/source{1}').format($scope.subscriberId, $scope.urlPortion), data);
		};

		$scope.editReportingMappings = function(template){
			var scope = {
				reportingMappings : _.cloneDeep($scope.reportingMappings),
				addMapping: function(){
					var mapping = {name : '', id : null};
					this.reportingMappings.push(mapping);
				},
				removeMapping : function(mapping){
					_.remove(this.reportingMappings, function(mappingToDelete){
						return mappingToDelete == mapping;
					});
				},
				singular : $scope.singular,
				plural : $scope.plural,
			};

			modalService
				.openTopBarModal(template || actionViewTemplates.subRegAddEditTradeClass, scope)
				.then(mergeReportingMappings);
		};

		$scope.editMappings = function(template){
			//filter out -1 -2
			var scope = {
				pricePlanCategories : $scope.pricePlanCategories.filter(function(plan){return plan.id != '-1' && plan.id != '-2'}),
				productCategories: $scope.productCategories,
				mappings            : _.cloneDeep($scope.filteredMappings),
				reportingMappings   : _.cloneDeep($scope.reportingMappings),
				filteredWithUnMapped: _.cloneDeep($scope.filteredWithUnMapped),
				addMapping: function(){
					var mapping = {name : '', id : null};
					this.mappings.push(mapping);
				},
				removeMapping : function(mapping){
					_.remove(this.mappings, function(mappingToDelete){
						return mappingToDelete == mapping;
					});
				},
				singular : $scope.singular,
				plural : $scope.plural,
			};

			modalService
				.openTopBarModal(template || actionViewTemplates.subRegAddEditTradeClass, scope)
				.then(mergeMappings);
		};

		$scope.autoMapProducts = function(){
			fluentRest
				.api()
				.subscribers($scope.subscriberId)
				.products()
				.automap()
					.post({})
						.then(function(){
							return $q.all([getSources(), getMappings()]);
						});
		};

		$scope.autoMapTradeClasses = function(){
			fluentRest
				.api()
				.subscribers($scope.subscriberId)
				.tradeClasses()
				.automap()
					.post({})
						.then(function(){
							return $q.all([getSources(), getMappings()]);
						});
		};

		$scope.autoMapCallReasons = function(){

			return $http
					.post(('api/subscribers/{0}/source{1}/automap').format($scope.subscriberId, $scope.urlPortion))
					.then(function(){ return $q.all([getSources(), getMappings()]);  });
		};

		var headingCenter = 'text-center clickable';

		$scope.headingsForProduct = [{
				name: 'Source System',
				isHidable: false,
				propClass: headingCenter,
				propName: 'sourceInfo',
				isSortable: true
			}, {
				name: ' Source Code',
				isHidable: false,
				propClass: headingCenter,
				propName: 'code',
				isSortable: true
			}, {
				name: ' Source Name',
				isHidable: false,
				propClass: headingCenter,
				propName: 'name',
				isSortable: true
			}, {
				name: '   SourceDescription',
				isHidable: false,
				propClass: headingCenter,
				propName: ' description',
				isSortable: true
			}, {
				name: '   Product',
				isHidable: false,
				propClass: headingCenter,
				propName: 'mappedId',
				isSortable: false
			}
		];

 		$scope.headings = [{
				name: 'Source System',
				isHidable: false,
				propClass: headingCenter,
				propName: 'sourceInfo',
				isSortable: true
			}, {
				name: 'Source ID',
				isHidable: false,
				propClass: headingCenter,
			propName: 'id',
				isSortable: true
			}, {
				name: ' Source Name',
				isHidable: false,
				propClass: headingCenter,
				propName: 'name',
				isSortable: true
			}, {
				name: ' Trade Class',
				isHidable: false,
				propClass: headingCenter,
				propName: 'mappedId',
				isSortable: false
			}
		];

		$scope.headingsForCallReason = [{
				name: 'Source System',
				isHidable: false,
				propClass: headingCenter,
				propName: 'sourceInfo',
				isSortable: true
			}, {
				name: 'Source ID',
				isHidable: false,
				propClass: headingCenter,
			propName: 'id',
				isSortable: true
			}, {
				name: ' Source Name',
				isHidable: false,
				propClass: headingCenter,
				propName: 'name',
				isSortable: true
			}, {
				name: ' Call Reason',
				isHidable: false,
				propClass: headingCenter,
				propName: 'mappedId',
				isSortable: false
			}
		];

		$scope.headingsForPricePlan = [{
				name: 'Source System',
				isHidable: false,
				propClass: headingCenter,
				propName: 'sourceInfo',
				isSortable: true
			}, {
				name: 'Source ID',
				isHidable: false,
				propClass: headingCenter,
				propName: 'code',
				isSortable: true
			}, {
				name: ' Source Name',
				isHidable: false,
				propClass: headingCenter,
				propName: 'name',
				isSortable: true
			}, {
				name: '  Price Plan',
				isHidable: false,
				propClass: headingCenter,
				propName: 'mappedId',
				isSortable: false
			}
 		];

		$scope.headingsForDeliveryType = [{
				name: 'Source System',
				isHidable: false,
				propClass: headingCenter,
				propName: 'sourceInfo',
				isSortable: true
			}, {
				name: ' Source Name',
				isHidable: false,

				propClass: headingCenter,
				propName: 'name',
				isSortable: true
			}, {
				name: '  Delivery Type',
				isHidable: false,
				propClass: headingCenter,
				propName: 'mappedId',
				isSortable: false
			}
		];

		$scope.promises = new PromiseMonitor(init());
	}
] );
