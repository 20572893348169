angular.module('angus.controllers')
    .controller('paygoCustRecInfoCtrl', [
        '$rootScope', '$scope', 'Paging', 'modalService', 'fluentRest', '$filter', '$q', 'actionViewTemplates', 'datePickerService', 'moment', '_', 'promiseMonitor', 'csvService', 'constantsService',
        function($rootScope, $scope, paging, modalService, fluentRest, $filter, $q, actionViewTemplates, datePickerService, moment, _, promiseMonitor, csvService, constants) {
            'use strict';


            function setActiveContainer(containerId) {
                if (containerId) {
                    $scope.activeItem.activeContainer = _.find($scope.activeItem.containers, function(container) {
                        return container.containerId == containerId || container.id == containerId;
                    });
                    getLayers();
                }


                $scope.activeItem.activeSection = 'tanks';
            }

            function init() {
                $scope.activeItem.alerts = [];
                $scope.promiseMonitor = new promiseMonitor();
                $scope.usageMonitor = new promiseMonitor();

                if (!$scope.activeItem.containers || !$scope.activeItem.bills) {
                    $scope.activeItem.layerPaging = new paging(50);
                    $scope.activeItem.layerSortBy = 'date';
                    $scope.activeItem.layerAscending = false;

                    $scope.activeItem.billLinePaging = new paging(25);
                    $scope.activeItem.billLineSortBy = 'date';
                    $scope.activeItem.billLineAscending = false;

                    var promise = $q.all([
                            getContainers(),
                            getBills(),
                            getContainerStatuses().then( function(statuses) { $scope.containerStatuses = statuses; }),
                            getUsageTypes()
                        ])
                        .then(function() {
                            setActiveContainer($scope.activeItem.containerId);
                            delete $scope.activeItem.containerId;
                        });

                    $scope.promiseMonitor.monitor(promise);
                } else {
                    setActiveContainer($scope.activeItem.containerId);
                    delete $scope.activeItem.containerId;
                }


            }



            $scope.closeAlert = function(index) {
                $scope.activeItem.alerts.splice(index, 1);
            };

            function getLayers(pageNumber) {

                var url = fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .containers($scope.activeItem.activeContainer.id)
                    .layers()
                    .toString();

                var params = {
                    sortBy: $scope.activeItem.layerSortBy,
                    ascending: $scope.activeItem.layerAscending,
                    showAll: $scope.activeItem.activeContainer.showingAllLayers
                };


                return $scope.activeItem.layerPaging
                    .getPage(url, pageNumber, params)
                    .then(function(layers) {

                        $scope.activeItem.activeContainer.layers = layers.items;

                        if (layers.items.length === 0) {
                            params.showAll = true;
                            return $scope.activeItem.layerPaging
                                .getPage(url, pageNumber, params)
                                .then(function(layers) {
                                    if (layers.items.length > 0)
                                        $scope.activeItem.hasFullyBilledLayers = true;
                                    else
                                        $scope.activeItem.hasFullyBilledLayers = false;
                                });
                        } else {

                            var layersTotals = {
                                units: {
                                    total: 0,
                                    billed: 0,
                                    consumed: 0,
                                    unconsumed: 0
                                },
                                amount: {
                                    total: 0,
                                    billed: 0,
                                    unbilled: 0,
                                    unconsumed: 0
                                }
                            };

                            _.forEach(layers.items, function(layer) {

                                layer.amount = {
                                    total: layer.units.total * layer.unitPrice,
                                    billed: layer.units.billed * layer.unitPrice,
                                    unbilled: (layer.units.consumed - layer.units.billed) * layer.unitPrice,
                                    unconsumed: layer.units.unconsumed * layer.unitPrice
                                };

                                layersTotals.units.total += layer.units.total;
                                layersTotals.units.billed += layer.units.billed;
                                layersTotals.units.consumed += layer.units.consumed;
                                layersTotals.units.unconsumed += layer.units.unconsumed;

                                layersTotals.amount.total += layer.amount.total;
                                layersTotals.amount.billed += layer.amount.billed;
                                layersTotals.amount.unbilled += layer.amount.unbilled;
                                layersTotals.amount.unconsumed += layer.amount.unconsumed;

                            });

                            $scope.activeItem.activeContainer.layersTotals = layersTotals;
                        }
                    });

            }

            function getContainers() {

                if (!$scope.activeItem.containerPromises) $scope.activeItem.containerPromises = new promiseMonitor();

                var item = $scope.activeItem;
                item.containersLoaded = false;


                var activeContainerId = item.activeContainer ? item.activeContainer.id : null;
                item.activeContainer = null;

                var promise = fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .containers()
                    .get({
                        onlyPaygo: true
                    })
                    .then(function(containers) {
                        $scope.activeItem.containers = containers;
                        var activeContainer = null;
                        if (item.containers)
                            activeContainer = activeContainerId ? _.find(item.containers, function(c) {
                                return c.id == activeContainerId;
                            }) : item.containers[0];

                        item.activeContainer = activeContainer;

                        return item.activeContainer;

                    })
                    .then(function(container) {
                        return container ? getLayers() : null;
                    })
                    .then(function() {
                        item.containersLoaded = true;
                    });

                $scope.activeItem.containerPromises.monitor(promise);

                return promise;
            }

            $scope.getContainers = getContainers;

            $scope.getLayers = getLayers;

            var usageItems = {
                tank: null,
                layer: null,
                bill: null,
                detail: null,
            };

            $scope.getUsagesForActiveTank = function() {

                $scope.usageItemName = 'tank';
                usageItems[$scope.usageItemName] = $scope.activeItem.activeContainer;

                if (!$scope.tankUsageMonitor)
                    $scope.tankUsageMonitor = new promiseMonitor();

                if (!$scope.activeItem.activeContainer)
                    return;

                var url = fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .containers($scope.activeItem.activeContainer.id)
                    .usages()
                    .toString();

                var graphEndpoint = fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .containers($scope.activeItem.activeContainer.id)
                    .usages()
                    .byDay();

                var promise = openUsage(url, graphEndpoint, ('for tank {0}.').format($scope.activeItem.activeContainer.display), actionViewTemplates.paygoCustRecUsageTableTank);

                $scope.tankUsageMonitor.monitor(promise);
            };



            $scope.getUsagesByLayer = function(layer) {
                if (!layer.hasAppliedUsage)
                    return;

                if (!layer.promises)
                    layer.promises = new promiseMonitor();

                $scope.usageItemName = 'layer';
                usageItems[$scope.usageItemName] = layer;

                var url = fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .containers($scope.activeItem.activeContainer.id)
                    .layers(layer.id)
                    .usages()
                    .toString();

                var graphEndpoint = fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .containers($scope.activeItem.activeContainer.id)
                    .layers(layer.id)
                    .usages()
                    .byDay();

                var promise = openUsage(url, graphEndpoint, ('for the delivery on {0}.').format($filter('utcDate')(layer.date)), actionViewTemplates.paygoCustRecUsageTableDelivery);

                layer.promises.monitor(promise);
            };



            $scope.hideUsage = function() {
                $scope.activeItem.usages = null;
                $scope.activeItem.showUsage = false;
                $scope.activeItem.showGraph = false;
                $scope.isBillUsage = false;
                $scope.usageItemName = null;

                usageItems = {
                    tank: null,
                    layer: null,
                    bill: null,
                    detail: null,
                };
            };

            $scope.getUsagesForActiveBill = function() {
                if (!$scope.activeItem.activeBill)
                    return;

                $scope.isBillUsage = $scope.activeItem.activeBill;

                $scope.usageItemName = 'bill';
                usageItems[$scope.usageItemName] = $scope.activeItem.activeBill;

                var url = fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .bills($scope.activeItem.activeBill.id)
                    .usages()
                    .toString();

                var graphEndpoint = fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .bills($scope.activeItem.activeBill.id)
                    .usages()
                    .byDay();

                openUsage(url, graphEndpoint, ('for the bill on {0}.').format($filter('utcDate')($scope.activeItem.activeBill.invoiceDate)), actionViewTemplates.paygoCustRecUsageTableBill);


            };



            $scope.getUsagesByBillDetail = function(detail) {
                $scope.isBillUsage = true;

                $scope.usageItemName = 'detail';
                usageItems[$scope.usageItemName] = detail;

                var url = fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .bills($scope.activeItem.activeBill.id)
                    .details(detail.id)
                    .usages()
                    .toString();

                var graphEndpoint = fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .bills($scope.activeItem.activeBill.id)
                    .details(detail.id)
                    .usages()
                    .byDay();

                openUsage(url, graphEndpoint, ('for the bill on {0}. Showing the delivery on {2} to tank {1}. ').format($filter('utcDate')($scope.activeItem.activeBill.invoiceDate), detail.containerDisplay, $filter('utcDate')(detail.layerDate)), actionViewTemplates.paygoCustRecUsageTableBill);
            };


            function exportToCsv(url, name) {

                $scope.csvExportMonitor = $scope.csvExportMonitor || new promiseMonitor();


                if ($scope.csvExportMonitor.isPending())
                    return;

                var params = {
                    sortBy: $scope.usageSortBy,
                    ascending: $scope.usageAscending
                };

                var promise = csvService
                    .getCsv(url, params, name)
                    .then(function() {

                    });

                $scope.csvExportMonitor.monitor(promise);

            }

            $scope.exportTankUsage = function() {
                var url = fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .containers(usageItems[$scope.usageItemName].id)
                    .usages()
                    .toString();


                var name = ('{0} - Tank ( {1} )').format($scope.activeItem.customer.name, usageItems[$scope.usageItemName].display);

                exportToCsv(url, name);
            };

            $scope.exportLayerUsage = function() {

                var url = fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .containers($scope.activeItem.activeContainer.id)
                    .layers(usageItems[$scope.usageItemName].id)
                    .usages()
                    .toString();

                var name = ('{0} - Layer ( {1} )').format($scope.activeItem.customer.name, moment(usageItems[$scope.usageItemName].date).format('l'));

                exportToCsv(url, name);
            };

            $scope.exportBillUsage = function() {

                var url = null;
                var name = null;

                if ($scope.usageItemName == 'bill') {
                    url = fluentRest
                        .api()
                        .subscribers($scope.activeItem.customer.subscriberId)
                        .customerAccounts($scope.activeItem.customer.id)
                        .bills(usageItems[$scope.usageItemName].id)
                        .usages()
                        .toString();

                    name = ('{0} - Bill ( {1} )').format($scope.activeItem.customer.name, moment(usageItems[$scope.usageItemName].invoiceDate).format('l'));
                } else if ($scope.usageItemName == 'detail') {
                    url = fluentRest
                        .api()
                        .subscribers($scope.activeItem.customer.subscriberId)
                        .customerAccounts($scope.activeItem.customer.id)
                        .bills($scope.activeItem.activeBill.id)
                        .details(usageItems[$scope.usageItemName].id)
                        .usages()
                        .toString();

                    name = ('{0} - Bill Detail for ( Delivery on {1} )').format($scope.activeItem.customer.name, moment(usageItems[$scope.usageItemName].deliveryDate).format('l'));
                } else
                    return;

                exportToCsv(url, name);
            };


            function getUsageGraph() {
                return $scope.graphEndpoint.get();
            }

            $scope.getUsages = function(pageNumber, sortBy) {

                if (sortBy) {
                    if ($scope.usageSortBy == sortBy)
                        $scope.usageAscending = !$scope.usageAscending;
                    else {
                        $scope.usageSortBy = sortBy;
                        $scope.ascending = true;
                    }
                } else
                    delete $scope.usageSortBy;


                var params = {
                    sortBy: $scope.usageSortBy,
                    ascending: $scope.usageAscending
                };

                var promise = $scope.usagePaging
                    .getPage($scope.usageUrl, pageNumber, params)
                    .then(function(usages) {
                        _.forEach(usages.items, function(usage) {
                            usage.containerDisplay = usage.containerName || usage.containerId;
                            usage.layerDate = moment(usage.layerDate)._d;
                        });

                        $scope.activeItem.usages = usages.items;

                        if ($scope.edittingUsage)
                            $scope.enableEditMode();
                    });

                $scope.usageMonitor.monitor(promise);

                return promise;

            };

            $scope.usageVisible = function() {
                $scope.$evalAsync(function() {
                    $scope.activeItem.showGraph = true;
                });
            };

            function openUsage(url, graphEndpoint, subtitle, tableTemplate) {

                $scope.usagePaging = new paging(50);
                $scope.usageUrl = url;
                $scope.usageSortBy;
                $scope.usageAscending;
                $scope.graphEndpoint = graphEndpoint;
                $scope.subtitle = subtitle;

                var promise = $scope
                    .getUsages()
                    .then(function() {
                        getUsageGraph()
                            .then(function(usages) {
                                if (usages) {
                                    var graphEnd = moment(usages[usages.length - 1].date);
                                    var graphStart = moment(usages[0].date);

                                    $scope.activeItem.difference = graphEnd.diff(graphStart, 'months', true);

                                    $scope.activeItem.graphStart = usages[0].date;
                                    $scope.activeItem.graphEnd = usages[usages.length - 1].date;

                                    $scope.activeItem.graphData = _.map(usages, function(usage) {
                                        return [moment(usage.date)._d, usage.units];
                                    });
                                }
                            });
                        $scope.usageTable = tableTemplate;
                        $scope.activeItem.showUsage = true;
                    });

                $scope.usageMonitor.monitor(promise);

                return promise;
            }

            $scope.getUsageLayers = function(usageId) {
                if (!usageId)
                    return;

                return fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .containers($scope.activeItem.activeContainer.id)
                    .usages(usageId)
                    .layers()
                    .get()
                    .then(function(usageLayers) {
                        var scope = {
                            layers: usageLayers
                        };

                        modalService.openTopBarModal(actionViewTemplates.paygoCustRecLayersModal, scope);
                    });

            }

            function getBills() {
                $scope.activeItem.billsLoaded = false;
                var item = $scope.activeItem;
                fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .bills()
                    .get({
                        sortBy: 'date',
                        ascending: false
                    })
                    .then(function(bills) {
                        if (bills.length > 0) {
                            item.bills = bills;
                            //Get display name
                            _.forEach(bills, function(bill) {
                                bill.display = '#{0} - {1} for {2}'.format(bill.id, $filter('utcDate')(bill.invoiceDate), $filter('currency')(bill.totalDue));
                            });
                            return item.bills;
                        }
                        return null;
                    })
                    .then(function(bills) {
                        return bills ? item.activeBill = bills[0] : null;
                    })
                    .then(function(bill) {
                        return bill ? getBillLines(bill) : null;
                    })
                    .then(function() {
                        item.billsLoaded = true;
                    });
            }


            function getBillLines(bill) {
                if (!$scope.activeItem.activeBill)
                    return;

                var params = {
                    sortBy: $scope.activeItem.billLineSortBy,
                    ascending: $scope.activeItem.billLineAscending
                };

                return fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .bills(bill.id)
                    .details()
                    .get(params)
                    .then(function(details) {

                        _.forEach(details, function(detail) {
                            detail.containerDisplay = detail.containerName || detail.containerId;
                            detail.deliveryDate = moment(detail.deliveryDate)._d;
                            detail.invoiceNumber = bill.id;
                        });

                        bill.details = details;
                    });
            }




            $scope.billReOrder = function(propertyName) {
                if ($scope.activeItem.billOrder == propertyName) {
                    $scope.activeItem.billDescending = !$scope.activeItem.billDescending;
                } else {
                    $scope.activeItem.billDescending = true;
                    $scope.activeItem.billOrder = propertyName;
                }
            };

            $scope.openContainer = function(containerId) {
                $scope.activeItem.activeContainer = _.find($scope.activeItem.containers, function(container) {
                    return container.containerId == containerId || container.id == containerId;
                });
                $scope.activeItem.activeSection = 'tanks';
            }

            $scope.activeContainerSwitch = function() {
                if (!$scope.activeItem.activeContainer.layers) getLayers();
            };

            $scope.activeBillSwitch = function() {
                if (!$scope.activeItem.activeBill.details) getBillLines($scope.activeItem.activeBill);
            };

            $scope.confirmLeavePaygo = function() {
                modalService
                    .openTopBarModal('../content/templates/widgets/paygoCustRec/html/actionViews/confirmLeavePaygo.html', {});
            };



            $scope.toggleCreditExposure = function() {
                $scope.showCreditExposure = !(!!$scope.showCreditExposure);
            };

            $scope.toggleFullBilledDeliveries = function() {
                $scope.activeItem.activeContainer.showingAllLayers = !(!!$scope.activeItem.activeContainer.showingAllLayers);
                getLayers();
            };


            var containerStatuses = null;

            function getContainerStatuses() {
                return $q
                    .when(containerStatuses)
                    .then(function(hasContainerStatuses) {
                        if (hasContainerStatuses)
                            return containerStatuses;
                        else
                            return fluentRest
                                .api()
                                .containers()
                                .statuses()
                                .get()
                                .then(function(statuses) {
                                    containerStatuses = statuses;
                                    return containerStatuses;
                                });

                    });
            }

            $scope.generateFinalBill = function() {
                $scope.generatingFinalBill = true;
                var container = $scope.activeItem.activeContainer;
                return fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .containers(container.id)
                    .finalBill()
                    .post()
                    .then(getBills)
                    .finally(function() {
                        container.status = 'Left Paygo';
                        container.statusId = 6;
                        $scope.generatingFinalBill = false;
                        $scope.activeItem.alerts.push({
                            type: 'success',
                            msg: '<strong>Success!</strong> A final bill was created.'
                        });
                    });
            };

            $scope.editContainer = function() {
                var originalContainer = $scope.activeItem.activeContainer;


                var scope = {
                    container: {
                        id: originalContainer.id,
                        name: originalContainer.display,
                        status: originalContainer.statusId,
                        isPaygoOwnedMonitor: originalContainer.isPaygoOwnedMonitor,
                        paygoStartDate: originalContainer.paygoStartDate,
                        transmitterId: originalContainer.transmitterId
                    },
                    comment: null,
                    statuses: $scope.containerStatuses,
                    openDatePicker: function() {
                        var that = this;

                        datePickerService
                            .openDatePickerModal(null, moment(that.container.paygoStartDate))
                            .spread(function(startDate, endDate) {
                                if (endDate) that.container.paygoStartDate = endDate.format('L');
                            });
                    }
                };

                return modalService
                    .openTopBarModal(actionViewTemplates.paygoCustRecEditTank, scope)
                    .spread(function(container, comment, tags) {
                        var promises = [];

                        if (container) {
                            if (!container.transmitterId || container.transmitterId === '')
                                container.isPaygoOwnedMonitor = false;



                            promises.push(fluentRest
                                .api()
                                .subscribers($scope.activeItem.customer.subscriberId)
                                .customerAccounts($scope.activeItem.customer.id)
                                .containers(container.id)
                                .put(container)
                                .then(function() {
                                    originalContainer.statusId = container.status;
                                    originalContainer.status = _.find($scope.containerStatuses, function(status) {
                                        return status.id == container.status;
                                    }).name;
                                    originalContainer.isPaygoOwnedMonitor = container.isPaygoOwnedMonitor;
                                    originalContainer.paygoStartDate = container.paygoStartDate;
                                    originalContainer.transmitterId = container.transmitterId;
                                }));
                        }

                        if (comment)
                            promises.push(fluentRest
                                .api()
                                .subscribers($scope.activeItem.customer.subscriberId)
                                .customerAccounts($scope.activeItem.customer.id)
                                .containers(container.id)
                                .comments()
                                .post({
                                    comment: comment,
                                    tags: tags.split(' ')
                                }));

                        return $q.all(promises);
                    });
            };

            $scope.addComment = function() {

                var scope = {
                    container: {
                        id: $scope.activeItem.activeContainer.id,
                        name: $scope.activeItem.activeContainer.display
                    },
                    comment: null,
                    significantComment: null,
                    tags: null
                };

                return modalService
                    .openTopBarModal(actionViewTemplates.paygoCustRecAddComment, scope)
                    .spread(function(comment, tags, exception, significantComment) {
                        if (!comment)
                            return;

                        return fluentRest
                            .api()
                            .subscribers($scope.activeItem.customer.subscriberId)
                            .customerAccounts($scope.activeItem.customer.id)
                            .containers($scope.activeItem.activeContainer.id)
                            .comments()
                            .post({
                                comment: comment,
                                tags: tags ? tags.split(' ') : null,
                                significantComment: significantComment
                            })
                            .then(function() {
                                if(significantComment) {
                                  $scope.activeItem.activeContainer.commentCount = $scope.activeItem.activeContainer.commentCount + 1;
                                  $scope.activeItem.activeContainer.significantCommentCount = $scope.activeItem.activeContainer.significantCommentCount + 1;
                                } else {
                                  $scope.activeItem.activeContainer.commentCount = $scope.activeItem.activeContainer.commentCount + 1;
                                }
                            });
                    });
            };

            $scope.viewComments = function() {

                $scope.retrievingComments = true;

                console.log($scope.activeItem);
                var url = fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .containers($scope.activeItem.activeContainer.id)
                    .comments()
                    .toString();

                var commentPaging = new paging(10);

                return fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .containers($scope.activeItem.activeContainer.id)
                    .comments()
                    .tags()
                    .get()
                    .then(function(tags) {
                        return commentPaging
                            .getPage(url)
                            .then(function(comments) {

                                var scope = {
                                    customerId: $scope.activeItem.customer.id,
                                    subscriberId: $scope.activeItem.customer.subscriberId,
                                    containerId: $scope.activeItem.activeContainer.id,
                                    display: ('Tank - {0}').format($scope.activeItem.activeContainer.display),
                                    paging: commentPaging,
                                    comments: comments.items,
                                    tags: tags,
                                    numberDeleted: 0,
                                    selected: {
                                        tags: []
                                    },
                                    getCommentsPage: function(pageNumber, that) {
                                        that = that || this;
                                        that.gettingComments = true;
                                        return commentPaging
                                            .getPage(url, pageNumber, {
                                                tags: that.selected.tags
                                            })
                                            .then(function(comments) {
                                                that.comments = comments.items;
                                                that.gettingComments = false;
                                            });
                                    },
                                    deleteComment: function(comment) {
                                        var that = this.$parent;
                                        var scope = {
                                            title: 'Delete Comment?',
                                            message: ('Are you sure you want to delete the comment from {0} made by {1}?').format($filter('utcDate')(comment.timestamp), comment.user)
                                        };

                                        modalService
                                            .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                                            .then(function(response) {
                                                if (!response)
                                                    return;

                                                return fluentRest
                                                    .api()
                                                    .subscribers(that.subscriberId)
                                                    .customerAccounts(that.customerId)
                                                    .containers(that.containerId)
                                                    .comments(comment.id)
                                                    .delete()
                                                    .then(function() {
                                                        return that.getCommentsPage(null, that);
                                                    })
                                                    .then(function() {
                                                        that.numberDeleted = that.numberDeleted + 1;
                                                    });
                                            });
                                    }

                                };

                                return modalService
                                    .openTopBarModal(actionViewTemplates.paygoCustRecViewComments, scope)
                                    .then(function(numberDeleted) {
                                        if (numberDeleted)
                                            $scope.activeItem.activeContainer.commentCount = $scope.activeItem.activeContainer.commentCount - numberDeleted;

                                        $scope.retrievingComments = false;
                                    });

                            });
                    });

            };

          $scope.viewSignificantComments = function() {

              $scope.retrievingComments = true;

              var url = fluentRest
                  .api()
                  .subscribers($scope.activeItem.customer.subscriberId)
                  .customerAccounts($scope.activeItem.customer.id)
                  .containers($scope.activeItem.activeContainer.id)
                  .comments()
                  .toString();

              url += '?isSignificant=true';

              var commentPaging = new paging(10);

              return fluentRest
                  .api()
                  .subscribers($scope.activeItem.customer.subscriberId)
                  .customerAccounts($scope.activeItem.customer.id)
                  .containers($scope.activeItem.activeContainer.id)
                  .comments()
                  .tags()
                  .get()
                  .then(function(tags) {
                    return commentPaging
                      .getPage(url)
                      .then(function(comments) {

                        var scope = {
                          customerId: $scope.activeItem.customer.id,
                          subscriberId: $scope.activeItem.customer.subscriberId,
                          containerId: $scope.activeItem.activeContainer.id,
                          display: ('Tank - {0}').format($scope.activeItem.activeContainer.display),
                          paging: commentPaging,
                          significantComments: comments.items,
                          tags: tags,
                          numberDeleted: 0,
                          selected: {
                            tags: []
                          },
                          getSignificantCommentsPage: function(pageNumber, that) {
                            that = that || this;
                            that.gettingComments = true;
                            return commentPaging
                              .getPage(url, pageNumber, {
                                tags: that.selected.tags,
                                significantComments: comments.items
                              })
                              .then(function(comments) {
                                that.comments = comments.items;
                                that.gettingComments = false;
                              });
                          },
                          deleteSignificantComment: function(comment) {
                            var that = this.$parent;
                            var scope = {
                              title: 'Delete Comment?',
                              message: ('Are you sure you want to delete the comment from {0} made by {1}?').format($filter('utcDate')(comment.timestamp), comment.user)
                            };

                            modalService
                              .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                              .then(function(response) {
                                if (!response)
                                  return;

                                return fluentRest
                                  .api()
                                  .subscribers(that.subscriberId)
                                  .customerAccounts(that.customerId)
                                  .containers(that.containerId)
                                  .comments(comment.id)
                                  .delete()
                                  .then(function() {
                                    return that.getSignificantCommentsPage(null, that);
                                  })
                                  .then(function() {
                                    that.numberDeleted = that.numberDeleted + 1;
                                  });
                              });
                          }

                        };

                        return modalService
                          .openTopBarModal(actionViewTemplates.paygoCustRecViewSignificantComments, scope)
                          .then(function(numberDeleted) {
                            if (numberDeleted)
                              $scope.activeItem.activeContainer.significantCommentCount = $scope.activeItem.activeContainer.significantCommentCount - numberDeleted;

                            $scope.retrievingComments = false;
                          });

                      });
                  });

            };

            $scope.deleteDelivery = function(layer) {

                var containerId = $scope.activeItem.activeContainer.id;
                var customer = $scope.activeItem.customer;

                var scope = {
                    title: 'Delete Layer?',
                    message: 'Are you sure you want to delete the delivery on ' + $filter('utcDate')(layer.date) + '?'
                };

                modalService
                    .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                    .then(function(deleteLayer) {
                        if (!deleteLayer)
                            return;

                        var params = {
                            deliveryLineId: layer.deliveryLineId,
                            date: layer.date,
                            units: layer.units.total,
                            unitPrice: layer.unitPrice,
                            dealerUnitPrice: layer.dealerUnitPrice,
                            sequenceNumber: layer.sequenceNumber
                        };

                        fluentRest
                            .api()
                            .subscribers(customer.subscriberId)
                            .customerAccounts(customer.id)
                            .containers(containerId)
                            .layers(layer.id)
                            .delete(params)
                            .then(getLayers);
                    });
            };

            function translateLayerOrigin(isFull, isConsumerOwned){
                if(isConsumerOwned) return constants.LayerOriginType.CONSUMER_OWNED;
                if(isFull) return constants.LayerOriginType.DELIVERY;
                return constants.LayerOriginType.PARTIAL_DELIVERY;
            }

            $scope.editDelivery = function(layer) {

                var newDelivery = true;

                var minQuantity = null;


                if (layer) {
                    newDelivery = false;
                    if (layer.units.billed > 0)
                        minQuantity = layer.units.billed;

                    layer = {
                        id: layer.id,
                        unitPrice: layer.unitPrice,
                        dealerUnitPrice: layer.dealerUnitPrice || null,
                        transactionDate: layer.date,
                        quantity: layer.units.total,
                        sequenceNumber: layer.sequenceNumber,
                        isFull: layer.isFull,
                        deliveryLineId: layer.deliveryLineId,
                        containerId: $scope.activeItem.activeContainer.id,
                        consumerOwned : layer.consumerOwned
                    };
                } else
                    layer = {
                        id: null,
                        transactionDate: moment().format('L'),
                        unitPrice: 0,
                        dealerUnitPrice: null,
                        quantity: 0,
                        sequenceNumber: 1,
                        deliveryLineId: null,
                        containerId: $scope.activeItem.activeContainer.id,
                        consumerOwned : false
                    };


                var scope = {
                    newDelivery: newDelivery,
                    layer: layer,
                    minQuantity: minQuantity,
                    openDatePicker: function() {
                        var that = this;

                        datePickerService
                            .openDatePickerModal(null, moment(that.layer.transactionDate))
                            .spread(function(startDate, endDate) {
                                if (endDate) that.layer.transactionDate = endDate.format('L');
                            });
                    }
                };



                modalService
                    .openTopBarModal(actionViewTemplates.paygoCustRecAddEditDelivery, scope)
                    .then(function(layer) {
                        if (!layer)
                            return;

                        var apiCall = fluentRest
                            .api()
                            .subscribers($scope.activeItem.customer.subscriberId)
                            .customerAccounts($scope.activeItem.customer.id)
                            .containers(layer.containerId)
                            .layers(layer.id);

                        var data = {
                            deliveryLineId: layer.deliveryLineId,
                            date: layer.transactionDate,
                            units: layer.quantity,
                            unitPrice: layer.unitPrice,
                            dealerUnitPrice: layer.dealerUnitPrice,
                            layerOriginKey: translateLayerOrigin(layer.isFull, layer.consumerOwned),
                            sequenceNumber: layer.sequenceNumber
                        };

                        var promise = layer.id ? apiCall.put(data) : apiCall.post(data);

                        return promise.then(getLayers);
                    });
            };


            $scope.usageTypes = null;

            function getUsageTypes() {
                if ($scope.usageTypes)
                    return $scope.usageTypes;
                else
                    return fluentRest
                        .api()
                        .usages()
                        .types()
                        .get()
                        .then(function(types) {
                            $scope.usageTypes = types;
                            return $scope.usageTypes;
                        });
            }


            $scope.editLevel = function(usage) {

                var scope = {
                    level: usage.level
                };


                modalService
                    .openTopBarModal(actionViewTemplates.paygoCustRecEditLevel, scope)
                    .then(function(level) {
                        if (!level && level !== 0)
                            return;

                        return fluentRest
                            .api()
                            .subscribers($scope.activeItem.customer.subscriberId)
                            .customerAccounts($scope.activeItem.customer.id)
                            .usages(usage.id)
                            .level()
                            .put({
                                level: level
                            })
                            .then(function() {
                                $scope.getUsages();
                            });
                    })
            }

            $scope.editUsage = function(usage) {

                var newUsage = true;

                $q.when(getUsageTypes())
                    .then(function(types) {
                        if (usage) {
                            newUsage = false;

                            usage = {
                                id: usage.id,
                                type: usage.typeId,
                                transactionDate: usage.date,
                                quantity: usage.volumeUsed,
                            };
                        } else
                            usage = {
                                id: null,
                                type: types[0].id,
                                transactionDate: moment().format('L'),
                                quantity: 0,

                            };


                        var scope = {
                            newUsage: newUsage,
                            usage: usage,
                            types: types,
                            openDatePicker: function() {
                                var that = this;

                                datePickerService
                                    .openDatePickerModal(null, moment(that.usage.transactionDate))
                                    .spread(function(startDate, endDate) {
                                        if (endDate) that.usage.transactionDate = endDate.format('L');
                                    });
                            }
                        };

                        return modalService.openTopBarModal(actionViewTemplates.paygoCustRecAddEditUsage, scope)
                    })
                    .then(function(usage) {
                        if (!usage)
                            return;

                        return fluentRest
                            .api()
                            .subscribers($scope.activeItem.customer.subscriberId)
                            .customerAccounts($scope.activeItem.customer.id)
                            .containers($scope.activeItem.activeContainer.id)
                            .usages(usage.id)
                            .post({
                                units: usage.quantity,
                                date: usage.transactionDate,
                                typeId: usage.type
                            });
                    });
            };


            $scope.reapplyUnbilledUsage = function(reloadUsages){
              if (!$scope.activeItem.saveUsageMonitor) $scope.activeItem.saveUsageMonitor = new promiseMonitor();

                var promise = fluentRest
                  .api()
                  .subscribers($scope.activeItem.customer.subscriberId)
                  .customerAccounts($scope.activeItem.customer.id)
                  .containers($scope.activeItem.activeContainer.id)
                  .usages()
                  .put({ reapplyUnbilledUsages : 1 })
                  .then(function(){
                    var reload = [getContainers()];
                    if(reloadUsages) reload.push($scope.getUsages());
                    return $q.all(reload).then(function(){
                      $scope.activeItem.alerts.push({
                            type: 'success',
                            msg: '<strong>Success!</strong> The unbilled usages have been reapplied.'
                        });
                    });
                  });

                $scope.activeItem.saveUsageMonitor.monitor(promise);
                return promise;
            }


            $scope.enableEditMode = function() {
                _.forEach($scope.activeItem.usages, function(usage) {
                    usage.backup = {
                        volumeUsed: usage.volumeUsed,
                        typeId : usage.typeId
                    };
                });


                $scope.edittingUsage = true;
            };



            function saveUsages(usages) {
                var data = {
                    usages: _.chain(usages)
                        .map(function(usage) {
                            //If there were no changes then bail out.
                            if (usage.backup.volumeUsed == usage.volumeUsed && usage.backup.typeId == usage.typeId)
                                return null;

                            return {
                                id: usage.id,
                                volumeUsed: usage.backup.volumeUsed,
                                typeId: usage.backup.typeId
                            };
                        })
                        .compact()
                        .value()
                }

                return fluentRest
                    .api()
                    .subscribers($scope.activeItem.customer.subscriberId)
                    .customerAccounts($scope.activeItem.customer.id)
                    .usages()
                    .put(data)
                    .then(function() {
                        $scope.activeItem.usagesWereSaved = true;
                        getLayers();
                        return $scope.getUsages();
                    });
            }

            $scope.saveUsages = function() {
                if (!$scope.activeItem.saveUsageMonitor) $scope.activeItem.saveUsageMonitor = new promiseMonitor();
                var promise = saveUsages($scope.activeItem.usages);

                $scope.activeItem.saveUsageMonitor.monitor(promise);

                return promise;
            }

            function disableEditMode(usages) {
                _.forEach(usages, function(usage) {
                    delete usage.backup;
                });

                $scope.activeItem.usagesWereSaved = false;

                $scope.edittingUsage = false;
            }

            $scope.disableEditMode = function() {
                disableEditMode($scope.activeItem.usages);
            };




            $scope.$watch(function() {
                return $scope.activeItem;
            }, function(newVal) {
                if (newVal != $scope.customersItem) init();
            });
        }
    ]).controller('paygoTankCommentController', [
        '$scope', 'promiseMonitor',
        function($scope, promiseMonitor) {

            function init() {
                $scope.promiseMonitor = new promiseMonitor();
                $scope.promiseMonitor.monitor($scope.getCommentsPage());
            }

            init();
        }
    ]);
