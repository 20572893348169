angular.module('angus.services').service('adeptService', [
    '$http',
    function($http) {

        function getAdeptGroups(subscriberKey) {
            return $http.get(`api/subscriber/${subscriberKey}/dynamic-reserve-settings`, {apiV2: true})
                .then(function(res){
                    return res.data;
                });
        }

        function getAdeptGroupsLegacy(subscriberId) {
            return $http.get(`api/subscriber-legacy/${subscriberId}/dynamic-reserve-settings/legacy`, {apiV2: true})
                .then(function(res){
                    return res.data;0
                }); 
        }

        function getAdeptGroup(subscriberId, adeptGroupId) {
            return $http.get(`api/subscribers/${subscriberId}/adept/groups/${adeptGroupId}`)
                .then(function(res){
                    return res.data;
                });
        }

        function getExecutions(subscriberId, adeptGroupIds, startDate, endDate, includeUnscheduledDays) {
            return $http({
                url: 'api/subscribers/{0}/adept/executions'.format(subscriberId),
                method: 'POST',
                data: {
                    dynamicReserveSettingsKeys: adeptGroupIds,
                    startDate: startDate,
                    endDate: endDate,
                    includeUnscheduledDays: includeUnscheduledDays
                }
            })
                .then(function (res) {
                    return res.data;
                })
        }

        function getExecutionDetails(subscriberId, dynamicReserveExecutionKey, containerKey, startDate, endDate) {
            return $http({
                url: 'api/subscribers/{0}/adept/executions/executionDetails'.format(subscriberId),
                method: 'POST',
                data: {
                    dynamicReserveExecutionKey: dynamicReserveExecutionKey,
                    containerKey: containerKey,
                    startDate: startDate,
                    endDate: endDate
                }
            })
                .then(function (res) {
                    return res.data;
                });
        }

        function getTankExclusions(subscriberId, containerKey) {
            return $http({
                url: 'api/subscribers/{0}/adept/executions/tankExclusions'.format(subscriberId),
                method: 'POST',
                data: {
                    containerKey: containerKey
                }
            })
                .then(function(res) {
                    return res.data;
                });
        }

        return {
            getAdeptGroups: getAdeptGroups,
            getAdeptGroupsLegacy: getAdeptGroupsLegacy,
            getAdeptGroup: getAdeptGroup,
            getExecutions: getExecutions,
            getExecutionDetails: getExecutionDetails,
            getTankExclusions: getTankExclusions
        };
    }
])