angular.module('angus.services').service('lastDeliveryDateService', ['$rootScope', '$http', '$q', '_', 'moment',
	function($rootScope, $http, $q, _, moment) {

		'use strict';

		var lastDeliveryDates = {};
		var lastDeliveryDatesForDivisions = {};		

		function queryLastDeliveryDate(nodeIds, comparisonDate, useDivisions){
			var params = {
				nodeIds 		: nodeIds,				
				useDivisions 	: useDivisions,
				comparisonDate  : comparisonDate.toDate()
			};
			
			return $http({
				url: ('api/subscribers/{0}/batches/deliveries/lastDeliveryDate').format($rootScope.user.subscriberId),
				method: 'GET',
				params: params
			})
			.then(function(response) {						
				return response.data.date;				
			});
		}

		function getKey(nodeIds, comparisonDate) {
			var key = _.reduce(nodeIds, function(result, nodeId) {
				return result += nodeId + ';';				
			}, '');
			return key + '_' + (comparisonDate !== undefined ? '_' + moment(comparisonDate).format('L') : '');
		}

		function getLastDeliveryDate(nodeIds, comparisonDate, useDivisions) {
			
			if (!_.isArray(nodeIds)) nodeIds = [nodeIds];

			if (!comparisonDate || !moment(comparisonDate).isValid()) comparisonDate = moment.utc().subtract(1, 'days');								

			var cacheLocation =  useDivisions ? lastDeliveryDatesForDivisions : lastDeliveryDates;

			var key = getKey(nodeIds, comparisonDate);

			return $q
				.when(cacheLocation[key])
				.then(function(lastDeliveryDate){
					if(lastDeliveryDate) return lastDeliveryDate;

					return queryLastDeliveryDate(nodeIds, comparisonDate, useDivisions)					
						.then(function(date) {						
							cacheLocation[key] = date;
							return cacheLocation[key];
						});
				})
				.then(function(lastDeliveryDate){
					return lastDeliveryDate ? moment.utc(lastDeliveryDate) : null;
				});
		}

		return {
			getLastDeliveryDate: function(deliveryCenterIds, comparisonDate) {
				return getLastDeliveryDate(deliveryCenterIds, comparisonDate);
			},
			getLastDeliveryDateForDivisions: function(divisionIds, comparisonDate) {
				return getLastDeliveryDate(divisionIds, comparisonDate, true);
			}
		};
	}
]);