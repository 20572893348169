'use strict';
angular.module('angus.controllers').controller('subRegEsysDivisionCtrl', [
	'$scope', '$http', '$q', 'moment', '$timeout', '_', 'Paging', 'modalService', 'actionViewTemplates',
	function($scope, $http, $q, moment, $timeout, _, Paging, modalService, actionViewTemplates) {

		$scope.pageSize = 25;
		$scope.paging = new Paging($scope.pageSize);
		$scope.sortProp = 'id';
		$scope.sortDir = 'asc';

		$scope.data = {};

		$scope.disableSave = false;
		var stagedDivisionChanges = [];
		var existingDivisionCodes = [];

		$scope.months =  moment.months();
		$scope.dates = [];

		$scope.setDates = function() {
			if(!$scope.data.month) {
				$scope.dates = [];
				return;
			}

			// use lodash range to add number of days in month from 1 to the number of days in each corresponding month
			// so that when ng-change is called on the model view after the month is selected, the number of days for
			// that month is determined, and displays from 1 to the enf of the month in the select dropdown
			$scope.dates = _.range(1, moment().set('month', $scope.data.month).daysInMonth() + 1);
		}

		$scope.$watch(function() {
      		return $scope.activeSourceSystem;
    		},
    		function(newVal) {
     			if (newVal) {
        			getEsysDivision();
      			}
    		}, true);

		function getEsysDivision(pageNumber) {

			var params = {
				sortDir               : $scope.sortDir,
				sortProp              : $scope.sortProp,
				stagedDivisionChanges : stagedDivisionChanges.join(',')
			};

			$scope.codesLoaded = false;

			$scope.paging.getPage(('api/subscribers/{0}/esysDivision/{1}').format($scope.subscriberId,$scope.activeSourceSystem.id),
				pageNumber,
				params)
				.then(function(esysDivision) {
                    $scope.esysDivision = esysDivision.items;
                    existingDivisionCodes = esysDivision.extraInfo.existingDivisionCodes ? esysDivision.extraInfo.existingDivisionCodes.split(',') : [];

                    syncWithStagedChanges();

                    $scope.codesLoaded  = true;
                    $scope.data.selectedDivision = existingDivisionCodes[0] || '1';

                    $scope.data.month = moment().month(esysDivision.extraInfo.fdsDegreeDayResetDate.substring(0,2) - 1).format("MMMM");
                    $scope.data.date = moment().date(esysDivision.extraInfo.fdsDegreeDayResetDate.substring(2,5)).format("D");

                    $scope.setDates();

                    if($scope.data.selectedDivision!='1'){
                        $scope.disableSave = true;
                    }
				})
				.catch(function(error) {
					console.error('ERRRORRR', error);
				});
		}

		$scope.getPage = function(pageNumber) {
			getEsysDivision(pageNumber);
		};

		$scope.sort = function(propName) {
			if (propName != $scope.sortProp)
				$scope.sortDir = 'asc';
			else
				$scope.sortDir = $scope.sortDir == 'asc' ?
									'desc' : 'asc';

			$scope.sortProp = propName;
			getEsysDivision($scope.paging.currentPage);
		};

		function syncWithStagedChanges(){
			_.forEach($scope.esysDivision, function(code){
					code.isDivision  = code.isDivisionLocked || _.includes(stagedDivisionChanges, code.id);
				});
		}

		$scope.updateStagedDivisionChanges = function(id){
			// $scope.data.selectedDivision = id;
		};

		$scope.updateEsysDivision = function() {

			var confirmScope = {
					title: 'Confirm Save?',
					message: 'Integration Settings codes cannot be deselected after they have been saved! Please confirm you have made the necessary changes. ' +
						'If selections need to be changed you will need to contact your system administrator.'
				};

			return modalService
				.openTopBarModal(actionViewTemplates.confirmDialog, confirmScope)
				.then(function(response) {
					if(response){

						$scope.updating = true;

						stagedDivisionChanges  = [$scope.data.selectedDivision];


						$http.post(('api/subscribers/{0}/esysDivision/{1}').format($scope.subscriberId,$scope.activeSourceSystem.id),
							{ esysDivision: stagedDivisionChanges })
							.then(function() {
									 getEsysDivision();
									$scope.updating           = false;
								});
					}
			});
		};

		$scope.saveDegreeDayResetDate = function() {
		    var confirmScope = {
		        title: 'Confirm Save?',
                message: 'Are you sure you want to save a new Degree Day Reset Date, and override the currently saved one?'
            };

			var data = {
				month: moment().month($scope.data.month).format("MM"),
				date: moment().date($scope.data.date).format("DD")
			};

			return modalService
                .openTopBarModal(actionViewTemplates.confirmDialog, confirmScope)
                    .then(function(response) {
                        if(response) {
                            $scope.updating = true;

                            $http.post('api/subscribers/{0}/esysDivision/{1}'.format($scope.subscriberId, $scope.activeSourceSystem.id), data)
                                .then(function(res) {

                                    $scope.updating = false;
                                    return res.data;
                                });
                        }
                    });
		};

		getEsysDivision();
	}
]);

