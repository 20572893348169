'use strict';

angular.module('angus.controllers').controller('subRegBudgetsCtrl', [
    '$scope', '$rootScope', '$http', '$q', 'actionViewTemplates', 'saveAs', '_', 'budgetsService', 'modalService', 'hierarchyService', 'fiscalYearService', 'alertsService', 'promiseMonitor',
    function($scope, $rootScope, $http, $q, actionViewTemplates, saveAs, _, budgetsService,  modalService, hierarchyService, fiscalYearService, alertsService, promiseMonitor) {

        var subscriberId = $scope.subscriberId;

        $scope.alerts = new alertsService();

        var budgets = new budgetsService(subscriberId);

        $scope.sortBy = 'name';
        $scope.ascending = true;

        function toArrayBuffer(data) {
            var buffer = new ArrayBuffer(data.length);
            var view = new Uint8Array(buffer);
            for(var i = 0, length = data.length; i < length; i++) {
                view[i] = data[i];
            }

            return buffer;
        }

        //subRegDownloadFinanceBudget

        $scope.uploadFinanceBudgets = function() {
            var scope = {
                years: $scope.years,
                divisionNodes: $scope.divisionNodes,
                model: {}
            };

            return modalService
                .openTopBarModal(actionViewTemplates.subRegImportBudgets, scope)
                .spread(function(fiscalYear, hierarchyNodeId, file) {
                    if(!file) return;

                    var fileName = file.name;

                    var fd = new FormData();
                    fd.append('file', file);

                    var url = ('/api/subscribers/{0}/budgets/finance/import?fiscalYear={1}&hierarchyNodeId={2}').format(subscriberId, fiscalYear, hierarchyNodeId);

                    var promise = $http.post(url, fd, {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': undefined}
                    })
                    .success(function(result) {
                        if(result.attachment) {
                            saveAs(
                                new Blob( [toArrayBuffer(result.attachment.buffer.data)], result.attachment.options ),
                                result.attachment.name
                            );
                        }

                        $scope.alerts['add' + result.status](result.message);

                        if(result.imported > 0) {
                            return $scope
                                .getExistingYears()
                                .then(function() {
                                    return $scope.getBudgets();
                                });
                        }

                    })
                    .error(function(data, status) {
                        $scope.alerts.addError('Error while importing budget. ' + data);
                        console.error(status, data);
                    });

                    $scope.monitor = new promiseMonitor(promise);
                });
        };

        $scope.sortBudgets = function(sortBy) {
            if($scope.sortBy == sortBy) $scope.ascending = !$scope.ascending;
            else $scope.sortBy = sortBy;

            return $scope.getBudgets();
        };

        $scope.getBudgets = function(pageNumber) {
            var params = {
                hierarchyNodeId: $scope.selected.hierarchyNodeId,
                fiscalYear: $scope.selected.year,
                p: $scope.selected.products,
                tc: $scope.selected.tradeClasses,
                pp: $scope.selected.pricePlans,
                name: $scope.selected.searchTerm,
                sortBy: $scope.sortBy,
                ascending: $scope.ascending
            };

            var promise = budgets
                .get(params)(pageNumber || 1, $scope.paging)
                .spread(function(budgets, paging) {
                    $scope.paging = paging;
                    $scope.budgets = budgets;

                    _.forEach($scope.budgets, function(budget) {
                        budget.selected = _.some($scope.selectedBudgets, function(selectedBudget) { return selectedBudget.id == budget.id });
                    });

                    return $scope.budgets;
                });

            $scope.budgetMonitor = new promiseMonitor(promise);

            return promise;
        };

        $scope.getExistingYears = function() {
            return $q.all([
                fiscalYearService.getFiscalYearStartMonth(subscriberId),
                budgets.getExistingYears($scope.selected.hierarchyNodeId)
            ])
            .spread(function(startMonth, years) {
                $scope.existingYears = years;
                if(!$scope.selected.year || !_.some($scope.existingYears, function(year) { return year == $scope.selected.year }) ) {
                    var currentFiscalYear = moment().year();

                    // Advance to the next year as the prior fiscalYear year is over
                    if(startMonth && moment().month() >= startMonth) currentFiscalYear += 1;

                    $scope.selected.year = _.find($scope.existingYears, function(year) { return year == currentFiscalYear }) || $scope.existingYears[0];
                }

                return $scope.existingYears;
            });
        };

        $scope.switchTab = function(tab) {
            $scope.tab = tab;

            if(tab == $scope.tabs.budget) {
                return $q.all([
                    getProducts(),
                    getTradeClasses(),
                    $scope.getBudgets()
                ]);
            }
        };

        $scope.tabs = {
            budget: {
                name: 'Budgets',
                templateUrl: actionViewTemplates.subRegMainBudgets
            },
            product: {
                name: 'Budget Products',
                templateUrl: actionViewTemplates.subRegBudgetProductCategories
            },
            tradeClass: {
                name: 'Budget Trade Classes',
                templateUrl: actionViewTemplates.subRegBudgetTradeClassCategories
            },
            finance: {
                name: 'Finance Standards',
                templateUrl: actionViewTemplates.subRegBudgetFinanceStandards
            }
        };

        // budget tank gain loss tab for SPES (using clyde walton dev / qa abos keys for dev / qa testing)
        // (clyde in dev) || (clyde in qa) || (SPES in prod)
        // if($scope.subscriber.abosKey == 3042 || $scope.subscriber.abosKey == 3031 || $scope.subscriber.abosKey == 87) {
        // the below is the subscriber code for SPES, which is the same across all environments
        if($scope.subscriber.code === 'C-00608') {
            $scope.tabs.tankGainLoss = {
                name: 'Tank Gains & Loss Budgets',
                templateUrl: actionViewTemplates.subRegTankGainLossBudgets
            };
        }

        $scope.tab = $scope.tabs.budget;

        $scope.modes = {
            gross: 'gross',
            unit: 'unit'
        };

        $scope.mode = $scope.modes.unit;
        $scope.setMode = function(mode) { $scope.mode = mode };

        function getProducts() {
            return budgets
                .getProducts()
                .then(function(products) {
                    $scope.products = products;
                });
        }

        function getTradeClasses() {
            return budgets
                .getTradeClasses()
                .then(function(tradeClasses) {
                    $scope.tradeClasses = tradeClasses;
                });
        }

        var init = $q
            .when($rootScope.user.role == 'sysadmin')
            .then(function(isAdmin) {
                return isAdmin
                    ? hierarchyService.getDivisionHierarchy(subscriberId)
                    : $rootScope.user.hierarchy.division.nodes;
            })
            .then(function(divisionNodes) {
                var removeUnknownNode = function(div) { return div.entityId != '-1' };

                $scope.divisionNodes = divisionNodes.filter(removeUnknownNode);
                $scope.divisionNodes.forEach(function(node) { if(node.children) node.children = node.children.filter(removeUnknownNode) });

                //shame


                var root = _.find(divisionNodes, function(node) { return node.isRoot });

                if (root && root.id) {
                    $scope.selected = {
                        hierarchyNodeId: root.id
                    };
                } else {
                    divisionNodes[0].isRoot = true;
                    var userRoot = _.find(divisionNodes, function(node) { return node.isRoot });
                    $scope.selected = {
                        hierarchyNodeId: userRoot.id
                    };
                }

                //


                $scope.selectedBudgets = [];

                $scope.getExistingYears();
            })
            .then(function() {
                return $q.all([
                    fiscalYearService
                        .getFiscalYearMonthsOrdered(subscriberId),
                    getProducts(),
                    getTradeClasses(),
                    budgets
                        .getPricePlans()
                        .then(function(pricePlans) {
                            $scope.pricePlans = pricePlans;
                        }),
                    $scope
                        .getBudgets()
                ])
                .spread(function(months) {
                    $scope.months = months;
                    $scope.years = budgets.getYears();

                    $scope.displayOptions = {
                        headers: 'Headers',
                        details: 'Details'
                    };

                    $scope.getBudgets();
                    $scope.display = $scope.displayOptions.headers;
                });
            });

        $scope.monitor = new promiseMonitor(init);

        $scope.cancel = function() {
            delete $scope.budget;
            $scope.alerts.clearAll();
        };

        $scope.addBudget = function() {
            $scope.alerts.clearAll();

            var monthlyValues = _.reduce($scope.months, function(result, monthName) {
                result[monthName.toLowerCase()] = 0;
                return result;
            }, {});

            $scope.budget = {
                grossMargin: _.cloneDeep(monthlyValues),
                unitMargin: _.cloneDeep(monthlyValues),
                units: _.cloneDeep(monthlyValues),
                mode: $scope.mode
            };
        };

        $scope.editBudget = function(budget) {
            $scope.alerts.clearAll();

            $scope.budget = {
                id: budget.id,
                name: budget.name,
                fiscalYear: budget.fiscalYear,
                productCategoryId: budget.productCategoryId,
                tradeClassCategoryId: budget.tradeClassCategoryId,
                pricePlanCategoryId: budget.pricePlanCategoryId,
                hierarchyNodeId: budget.hierarchyNodeId,
                grossMargin: _.cloneDeep(budget.grossMargin),
                unitMargin: _.cloneDeep(budget.unitMargin),
                units: _.cloneDeep(budget.units),
                mode: budget.fromUnitMargin ? $scope.modes.unit : $scope.modes.gross
            };
        };

        $scope.saveBudget = function(budget) {
            if(!budget) return;

            if(!budget.monitor) budget.monitor = new promiseMonitor();

            if(budget.mode == $scope.modes.unit) {
                budget.fromUnitMargin = true;
                delete budget.grossMargin;
            }

            if(budget.mode == $scope.modes.gross) {
                budget.fromUnitMargin = false;
                delete budget.unitMargin;
            }

            var promise = budgets
                .save(budget)
                .then(function() {
                    $scope.selected.year = budget.fiscalYear;
                    return $scope.getExistingYears();
                })
                .then(function() {
                    return $scope.getBudgets();
                })
                .then(function() {
                    $scope.cancel();
                })
                .catch(function(err) {
                    $scope.alerts.addError(err.msg || 'Unable to save budget.');
                });

            budget.monitor.monitor(promise);

            return promise;
        };

        $scope.deleteBudget = function(budget) {
            modalService
                .getConfirmation('Delete Budget', 'Are you sure you want to delete this ' + budget.name + '?')
                .then(function(result) {
                    if(result)
                        return budgets
                            .delete(budget.id)
                            .then(function() {
                                return $scope.getExistingYears();
                            })
                            .then(function() {
                                return $scope.getBudgets();
                            })
                            .catch(function(err) {
                                $scope.alerts.addError(err.msg || 'Unable to delete budget.');
                            });

                });
        };

        $scope.copyBudget = function(budget) {
            var scope = {
                currentFiscalYear: budget.fiscalYear,
                budgets: budget.name,
                years: $scope.years
            };

            modalService
                .openTopBarModal(actionViewTemplates.subRegCopyBudgets, scope)
                .then(function(toYear) {
                    if(!toYear) return;

                    return budgets
                        .copy(budget.id, toYear)
                        .then(function() {
                            $scope.selected.year = toYear;
                            return $scope.getExistingYears();
                        })
                        .then(function() {
                            return $scope.getBudgets();
                        })
                        .catch(function(err) {
                            $scope.alerts.addError(budget.name + '. ' + (err.msg || 'Unable to copy budget.'));
                        });
                });
        };

        $scope.deleteBudgets = function() {
            modalService
                .getConfirmation('Delete Budgets', 'Are you sure you want to delete the ' + $scope.selectedBudgets.length + ' selected budgets ?')
                .then(function(result) {
                    if(result) {
                        var failCount = 0;
                        return $q.all([_.map($scope.selectedBudgets, function(selectedBudget) {
                            return budgets
                                .delete(selectedBudget.id)
                                .catch(function(err) {
                                    $scope.alerts.addError(err.msg || 'Unable to delete all the budgets.');
                                    failCount++;
                                });
                            })
                        ])
                        .then(function() {
                            $scope.clearSelecetedBudgets();
                            return $scope.getExistingYears();
                        })
                        .then(function() {
                            return $scope.getBudgets();
                        })
                        .then(function() {
                            $scope.clearSelecetedBudgets();
                        });
                    }
                });
        };

        $scope.fiscalYearChange = function() {
            $scope.selectedBudgets = [];

            _.forEach($scope.budgets, function(budget) { budget.selected = false });
        };

        $scope.selectBudget = function(budget, selected) {
            budget.selected = selected;

            if(!budget.selected) _.remove($scope.selectedBudgets, function(selectedBudget) { return selectedBudget.id == budget.id } );
            else
                if(!_.some($scope.selectedBudgets, function(selectedBudget) { return selectedBudget.id == budget.id } ))
                    $scope.selectedBudgets.push({name: budget.name, id: budget.id});
        };

        $scope.copySelectedBudgets = function() {
            var scope = {
                currentFiscalYear: $scope.selected.year,
                budgets: _.map($scope.selectedBudgets, 'name').join(' , '),
                years: $scope.years
            };

            modalService
                .openTopBarModal(actionViewTemplates.subRegCopyBudgets, scope)
                .then(function(toYear) {
                    if(!toYear) return;

                    var failCount = 0;

                    return $q.all([_.map($scope.selectedBudgets, function(budget) {
                        return budgets
                            .copy(budget.id, toYear)
                            .catch(function(err) {
                                $scope.alerts.addError(err.msg || 'Unable to copy the budget.');
                                failCount++;
                            });
                        })
                    ])
                    .then(function() {
                        $scope.clearSelecetedBudgets();
                        $scope.selected.year = toYear;

                        return $scope.getExistingYears();
                    })
                    .then(function() {
                        return $scope.getBudgets();
                    })
                    .catch(function(err) {
                        $scope.alerts.addError(err.msg || 'Unable to copy all the budgets.');
                    });
                });

        };

        $scope.clearSelecetedBudgets = function() {
            $scope.checkNone();
            $scope.selectedBudgets = [];
        };

        $scope.checkAll = function() {
            _.forEach($scope.budgets, function(budget) { $scope.selectBudget(budget, true) });
        };

        $scope.checkNone = function() {
            _.forEach($scope.budgets, function(budget) { $scope.selectBudget(budget, false) });
        };

        $scope.generateBudgets = function() {
            var scope = {
                alerts: new alertsService(),
                generateMonitor: new promiseMonitor(),
                products: $scope.products,
                tradeClasses: $scope.tradeClasses,
                pricePlans: $scope.pricePlans,
                divisionNodes: $scope.divisionNodes,
                moreYears: _.range(moment().subtract(10, 'years').year(), moment().add(1, 'years').year(), 1),
                years: $scope.years,
                clearCategories: function() {
                    if(!this.model.productCategories || this.model.productCategories.length > 1) this.model.productCategories = [];
                    if(!this.model.tradeClassCategories || this.model.tradeClassCategories.length > 1) this.model.tradeClassCategories = [];
                    if(!this.model.pricePlanCategories || this.model.pricePlanCategories.length > 1) this.model.pricePlanCategories = [];
                },
                generate: function(data) {
                    if(!data) return;
                    var that = this;

                    var h = data.hierarchyNodeId;
                    var ty = data.toYear;
                    var fy = data.fromYear;
                    var p = _.map(data.productCategories, 'id');
                    var tc = _.map(data.tradeClassCategories, 'id');
                    var pp = _.map(data.pricePlanCategories, 'id');
                    var pc = data.percentChange;
                    var r = data.rollup;

                    var promise = budgets
                        .generateFromActuals(h, fy, ty, p, tc, pp, pc, r)
                        .then(function(data) {
                            if(data.numberOfBudgetsCreated > 0)
                                that.close(data);
                            else if(data.numberOfBudgetsAttempted === 0)
                                that.alerts.addError('Could not find any actuals with the selected parameters. No budgets were created.');
                            else
                                that.alerts.addError(data.numberOfBudgetsAttempted + ' budget' + (data.numberOfBudgetsAttempted > 1 ? 's' : '') + ' but there were overlapping products, trade classes, and price plans within the division ancestry that were already assigned to a budget.');
                        })
                        .catch(function(err) {
                            that.alerts.addError(err.msg || 'Unable to generate budgets from actuals.');
                        });

                    that.generateMonitor.monitor(promise);

                    return promise;
                }
            };

            modalService
                .openTopBarModal(actionViewTemplates.subRegGenerateBudgets, scope)
                .then(function(data) {
                    if(data) {
                        if(data.numberOfBudgetsCreated > 0)
                            $scope.alerts.addSuccess(data.numberOfBudgetsCreated + ' budget' + (data.numberOfBudgetsCreated > 1 ? 's were' : ' was') + ' created from actuals.');

                        var failures = data.numberOfBudgetsAttempted - data.numberOfBudgetsCreated;

                        if(failures > 0)
                            $scope.alerts.addError('Attempted to create ' + failures + ' budget' + (failures > 1 ? 's' : '') + ' but there were overlapping products, trade classes, and price plans within the division ancestry that were already assigned to a budget.');

                        $scope.getBudgets();
                        $scope.getExistingYears();
                    }
                });
        };

    }
]).filter('abosKeysFilter', ['_',
    function(_) {
        return function(list, selectedList) {
            var selectedAbosKeys = _
                .chain(selectedList)
                .map(function(selectedItem) {
                    return selectedItem.abosKeys;
                })
                .flatten()
                .uniq()
                .value();

            return _.filter(list, function(listItem) {
                var intersection = _.intersection(listItem.abosKeys, selectedAbosKeys);
                return intersection.length == 0;
            });
        };
    }
]).filter('noYearOverlap', ['_',
    function(_) {
        return function(list, noOverlapList) {
            var out = _.xor(list, noOverlapList);
            out = _.intersection(list, out);

            return out;
        };
    }
]);
