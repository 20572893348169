angular.module('angus.controllers').controller('callbackAnalysisCtrl', [
    '$scope', '$rootScope', '$http', 'modalService', 'productsService', 'hierarchyService', 'actionViewTemplates',
    'constantsService', '_', 'moment', 'datePickerService', 'lastServiceCallDate', 'dateCalculator', 'fiscalYearService',
    function($scope, $rootScope, $http, modalService, productsService, hierarchyService, actionViewTemplates,
             constantsService, _, moment, datePickerService, lastServiceCallDate, dateCalculator, fiscalYearService) {

        'use strict';

        $scope.periodIndicators = constantsService.analysisPeriodIndicators.enums;
        $scope.periodIndicator = _.find(constantsService.analysisPeriodIndicators, function(indicator) {
            return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
        });

        var scSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.serviceCenter.nodes, $scope.widget.instance.settings.hierarchyNodeId);
        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        function loadWidget() {
            $scope.widget.noContentFlag = false;

            var promise = lastServiceCallDate.getLastServiceCallDate(scSetting)
                .then(function(lastPostingDate) {

                    if (!$scope.dates){
                        $scope.dates = {};
                        var dates = dateCalculator.calculatePeriod($scope.dates.periodStartDate, lastPostingDate || moment.utc(), $scope.periodIndicator.key);

                        if($scope.periodIndicator.key === 'fiscalYearToDate')
                            fiscalYearService
                                .getFiscalYearStartMonth(subscriberId)
                                .then(function(fiscalMonth) {
                                    if(moment().month() >= fiscalMonth)
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month');
                                    else
                                        $scope.dates.periodStartDate = moment().month(fiscalMonth).startOf('month').subtract(1, 'year');

                                    $scope.widget.defaultInitialization();
                                });

                        $scope.dates.periodStartDate   = !$scope.widget.instance.settings.customDate ? dates.startDate : $scope.widget.instance.settings.customDate.startDate;
                        $scope.dates.lastPostingDate   = !$scope.widget.instance.settings.customDate ? dates.endDate : $scope.widget.instance.settings.customDate.endDate;
                    }

                    return queryServiceCallsSummary();
                })
                .then(function(response) {
                    if (!response || !response.data) {
                        $scope.widget.noContentFlag = true;
                        $scope.widget.instance.settings.customDate = null;
                        return null;
                    }

                    var callbacks = _.reduce(response.data, function(total,tech){
                        total.calls += tech.calls;
                        total.callbacks += tech.chargeable;

                        return total;
                    }, {calls:0, callbacks:0})

                    callbacks.callbackRatio = callbacks.calls ? callbacks.callbacks * 100 / callbacks.calls : 0;

                    $scope.callbacks = callbacks;
                    setDateTooltipText();
                    $scope.widget.setDefaultActionModel($scope.dates);
                });
            return $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(loadWidget);

        function queryServiceCallsSummary() {
            return $http({
                url: ('api/subscribers/{0}/service/callbacks/techs').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    serviceCenterId: $scope.widget.instance.settings.hierarchyNodeId,
                    sd: $scope.dates.periodStartDate ? $scope.dates.periodStartDate.format() : null,
                    ed: $scope.dates.lastPostingDate.format(),
                    t : $scope.widget.instance.settings.techs ? $scope.widget.instance.settings.techs.value : null
                }
            });
        }


        $scope.$on('widgetSettingsChanged', function() {
            scSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.serviceCenter.nodes, $scope.widget.instance.settings.hierarchyNodeId);
            //analysisPeriodIndicators
            $scope.periodIndicator = _.find(constantsService.analysisPeriodIndicators, function(indicator) {
                return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
            });

            delete $scope.dates;
            loadWidget();
        });


        $scope.openDatePicker = function() {
            return lastServiceCallDate.getLastServiceCallDate(scSetting)
                .then(function(lastPostingDate) {
                    return datePickerService.openDatePickerModal(
                        $scope.dates.periodStartDate,
                        $scope.dates.lastPostingDate,
                        lastPostingDate);
                })
                .then(function(resultDates) {
                    if (resultDates) {
                        $scope.dates.periodStartDate = moment(resultDates[0]).isValid() ? resultDates[0] : resultDates[1];
                        $scope.dates.lastPostingDate = resultDates[1];

                        loadWidget();

                        $scope.periodIndicator = constantsService.analysisPeriodIndicators.customDateRange;
                    }
                });
        };

        function setDateTooltipText() {
            $scope.dateTooltipText = $scope.dates.periodStartDate.format('L') + ' - ' + $scope.dates.lastPostingDate.format('L');
        }
    }
]);