angular.module('angus.controllers').controller('paygoExceptionsActionViewCtrl', [
    '$rootScope', '$scope', '$q', 'drillService', 'Paging', 'modalService', 'actionViewTemplates', 'Throttler', 'fluentRest', 'promiseMonitor', 'constantsService', '$filter', '_', 'windowsService', 'subscriberService',
    function($rootScope, $scope, $q, drillService, paging, modalService, actionViewTemplates, Throttler, fluentRest, promiseMonitor, constantsService, $filter, _, windowsService, subscriberService) {
        'use strict'; 

        $scope.selected = {};

        windowsService.setCallingScopeCallBack($scope.windowId, function(newCallingScope){

            const newModel = newCallingScope.model;

            if (newModel.activeSubscriberId) {
                $scope.selected.activeSubscriberId = newModel.activeSubscriberId;
                getDealers(newModel.activeSubscriberId)
                    .then(function() {
                        $scope.selected.dealerIds = selectAllDealers();
                        $scope.getExceptions();
                    });
            }
            else if (newModel.activeDealerIds) {
                $scope.selected.activeSubscriberId = null;
                $scope.selected.dealerIds = newModel.activeDealerIds;
                $scope.getExceptions();
            }
            else {
                $scope.selected.activeSubscriberId = null;
                $scope.selected.dealerIds = selectAllDealers();
                $scope.getExceptions();
            }
        });


        function getDealers(subscriberId) {
            return fluentRest
                .api()
                .subscribers(subscriberId)
                .dealers()
                .get()
                .then(function(dealers) {
                    $scope.dealers = dealers;
                });
        }

        function selectAllDealers() {
            return _.map($scope.dealers, function(dealer) {
                return dealer.id;
            });
        }

        function ExceptionStatus(status) {
            this.name = status.value.display;
            this.id = status.value.id;
            this.paging = new paging(100);
            this.sortBy = 'date';
            this.ascending = true;

            this.setExceptions = function(exceptions) {
                this.exceptions = exceptions;
            };

            this.clearExceptions = function() {
                delete this.exceptions;
            };

            this.setTotal = function(total) {
                this.total = total;
            };

            this.clearTotal = function() {
                delete this.total;
            };

            this.clear = function() {
                this.clearExceptions();
                this.clearTotal();
            };

        }

        function ExceptionAction(name, exceptionStatus, buttonDisplay, buttonIcon, modalHeading, modalVerb){
            this.name = name;
            this.exceptionStatus = exceptionStatus;
            this.button = {
                display: buttonDisplay,
                icon: buttonIcon
            };
            this.modal = {
                heading: modalHeading,
                verb: modalVerb
            };
            this.enabled = true;

            this.setEnabled = function(enabled){ 
                this.enabled = enabled; 
            };
        }

        function getExceptionTypes(group) {

            return fluentRest
                .api()
                .subscribers()
                .dealers()
                .exceptions()
                .types()
                .get({eg : group})
                .then(function(exceptionTypes) {
                    return $scope.exceptionTypes = exceptionTypes;
                });
        }

        function getExceptions(exceptionStatus, pageNumber) {
            var params = {
                sortBy: exceptionStatus.sortBy,
                ascending: exceptionStatus.ascending ? 1 : 0,
                s: exceptionStatus.id,
                d: $scope.selected.dealerIds,
                t: $scope.selected.types.length == 0 ? _.map($scope.exceptionTypes, 'id') : $scope.selected.types,
                sd: $scope.dateRange.start,
                ed: $scope.dateRange.end, 
                searchString : $scope.searchString || null
            };

            var url = fluentRest
                .api()
                .subscribers()
                .dealers()
                .exceptions()
                .toString();

            var promise = exceptionStatus.paging.postGetPage(
                url + "/list",
                pageNumber,
                params,
                'POST'
            )
                .then(function(exceptions) {
                    exceptionStatus.setExceptions(exceptions.items);
                }, function(err) {
                    exceptionStatus.setExceptions([]);
                });

            $scope.pageMonitor.monitor(promise);
        }

        function getExceptionTotals() {

            var params = {
                d: $scope.selected.dealerIds,
                t: $scope.selected.types.length == 0 ? _.map($scope.exceptionTypes, 'id') : $scope.selected.types,
                sd: $scope.dateRange.start,
                ed: $scope.dateRange.end,
                searchString : $scope.searchString || null
            };

            return fluentRest
                .api()
                .subscribers()
                .dealers()
                .exceptions()
                .totals()
                .post(params)
                .then(function(data) {
                    var totals = _.reduce(data, function(result, row){
                        result.new += row.new.total;
                        result.pending += row.pending.total;
                        return result;
                    }, {
                        new : 0, 
                        pending: 0
                    })
                    $scope.unresolved.setTotal(totals.new);
                    $scope.pending.setTotal(totals.pending);
                })
                .catch(function(err) {
                    $scope.unresolved.setTotal(0);
                    $scope.pending.setTotal(0);
                });

        }

        function clearExceptions() {
            _.forEach($scope.exceptionStatuses, function(exceptionStatus) {
                exceptionStatus.clear();
            })
        }


        function clearFilters() {
            $scope.dateRange = {
                start: null,
                end: null,
            };

            $scope.bos = {
                customerId: "",
                tankId: ""
            }
            $scope.paygo = {
                customerId: "",
                tankId: ""
            }
            $scope.customerName = {
                first: "",
                last: ""
            };
            $scope.searchString = "";
        }

        $scope.clearFilters = function() {
            clearFilters();
            $scope.getExceptions();
        };

        $scope.searchThrottler = new Throttler(250);
        $scope.searchStringChange = function(term){
            $scope.searchThrottler
                .throttle()
                .then(function(takeAction) {
                    if(takeAction){
                        $scope.searchString = term;
                    }
                });
        };

        function init() {
            $scope.promiseMonitor = new promiseMonitor();
            $scope.pageMonitor = new promiseMonitor();
            $scope.exceptionMonitor = new promiseMonitor();
            $scope.today = moment.utc().toDate();

            $scope.unresolved = new ExceptionStatus(constantsService.exceptionStatus.new);
            $scope.resolved = new ExceptionStatus(constantsService.exceptionStatus.resolved);
            $scope.ignored = new ExceptionStatus(constantsService.exceptionStatus.ignore);
            $scope.pending = new ExceptionStatus(constantsService.exceptionStatus.pending);
            $scope.exceptionStatuses = [$scope.unresolved, $scope.resolved, $scope.ignored];
            if ($scope.actionModel.exceptionGroups == 'tms'){
                $scope.exceptionStatuses.push($scope.pending);
            }
            $scope.activeExceptionStatus = $scope.unresolved;

            $scope.exceptionActions = {
                new: new ExceptionAction('new', $scope.unresolved, 'Mark as new', '', 'Mark exceptions as new', 'marked as new'),
                resolve: new ExceptionAction('resolve', $scope.resolved, 'Resolve', 'fa fa-check', 'Resolve exceptions', 'resolved'),
                ignore: new ExceptionAction('ignore', $scope.ignored, 'Ignore', 'fa fa-times', 'Ignore exceptions', 'ignored'),
                pending: new ExceptionAction('pending', $scope.pending, 'Mark as pending', 'fa fa-ellipsis-h', 'Mark exceptions as pending', 'marked as pending')
            }
            if ($scope.actionModel.exceptionGroups != 'tms'){
                $scope.exceptionActions.pending.setEnabled(false);
            }

            clearFilters();

            var promise = $q.all([
                    getDealers($scope.actionModel.activeSubscriberId),
                    getExceptionTypes($scope.actionModel.exceptionGroups)
                ])
                .then(function() {
                    $scope.selected = {
                        dealerIds: $scope.activeDealerIds || selectAllDealers(),
                        types: []
                    };

                    return;
                })
                .then(function() {
                    if ($scope.activeDealerIds || $scope.actionModel.activeSubscriberId) {
                        return $q.all([
                            getExceptionTotals(),
                            getExceptions($scope.activeExceptionStatus)
                        ]);
                    }

                    return;
                });

            $scope.promiseMonitor.monitor(promise);
        }

        init();

        $scope.getExceptions = function() {
            clearExceptions();
            return $q.all([
                getExceptionTotals(),
                getExceptions($scope.activeExceptionStatus)
            ]);

        }

        $scope.getExceptionsPage = function(pageNumber) {
            getExceptions($scope.activeExceptionStatus, pageNumber);
        };

        $scope.changeExceptionStatus = function(exceptionStatus) {
            $scope.activeExceptionStatus = exceptionStatus;
            if (!exceptionStatus.exceptions) {
                getExceptions(exceptionStatus);
            }
        };

        $scope.sortBy = function(sortBy) {
            if ($scope.activeExceptionStatus.sortBy == sortBy)
                $scope.activeExceptionStatus.ascending = !$scope.activeExceptionStatus.ascending;
            else {
                $scope.activeExceptionStatus.sortBy = sortBy;
                $scope.activeExceptionStatus.ascending = true;
            }

            getExceptions($scope.activeExceptionStatus);
        };


        function stopEvent($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }


        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        $scope.startDateOpened = false;
        $scope.endDateOpened = false;

        $scope.openStartDate = function($event) {
            stopEvent($event);
            this.endDateOpened = false;
            this.startDateOpened = true;
        };
        $scope.openEndDate = function($event) {
            stopEvent($event);
            this.startDateOpened = false;
            this.endDateOpened = true;
        };

        $scope.resetEndDate = function() {
            if (moment(this.dateRange.end).isBefore(moment(this.dateRange.start)))
                this.dateRange.end = this.dateRange.start;
        };

        $scope.checkAll = function(exceptions) {
            _(exceptions).forEach(function(e) {
                e.selected = true;
            });
        };

        $scope.checkNone = function(exceptions) {
            _(exceptions).forEach(function(e) {
                e.selected = false;
            });
        };

        $scope.anyChecked = function(exceptions) {
            if (exceptions) {
                return _(exceptions).some(function(e) {
                    return e.selected;
                });
            }
            return false;
        };


        $scope.addComment = function(exception) {

            var scope = {
                container: {
                    id: exception.containerId,
                    name: exception.bosTankId
                },
                customer: {
                    name: exception.customerName.first + ' ' + exception.customerName.last
                },
                exception: exception,
                comment: null
            };

            return modalService
                .openTopBarModal(actionViewTemplates.paygoCustRecAddComment, scope)
                .spread(function(comment) {
                    if (!comment)
                        return;


                    return fluentRest
                        .api()
                        .subscribers(exception.dealer.subscriberId)
                        .customerAccounts(exception.customerId)
                        .containers(exception.containerId)
                        .comments()
                        .post({
                            comment: comment, 
                            exception: exception
                        })
                        .then(function() {
                            exception.comment = {
                                body: comment,
                                user: $rootScope.user.firstName + ' ' + $rootScope.user.lastName,
                                date: moment().format()
                            }
                            exception.numberOfComments = exception.numberOfComments + 1;
                        });
                });
        };


        $scope.openCommentsModal = function(exception) {
            var scope = $scope.$new();
            //scope.addComment = $scope.addComment;
            scope.close = $scope.closeCommentsModal;
            scope.exception = exception;

            $scope.commentsModalInstance = modalService.open({
                templateUrl: actionViewTemplates.paygoExceptionsComments,
                scope: scope
            }, modalService.types.topBar);
        };

        function openConfirmModal(exceptions, status, heading, verb) {


            var exceptions = _.filter(exceptions, function(ex) {
                return (ex.selected);
            });

            if (exceptions.length == 0)
                return;

            var scope = {
                exceptions: _.filter(exceptions, function(ex) {
                    return (ex.selected);
                }),
                heading: heading,
                verb: verb
            };


            return modalService
                .openTopBarModal(actionViewTemplates.paygoExceptionsConfirm, scope)
                .spread(function(exceptions, comment) {

                    if (!exceptions)
                        return;

                    var postData = {
                        exceptionsByDealer: _.groupBy(exceptions, function(exception) {
                            return exception.dealer.id;
                        }),
                        dealerIds: _.map(exceptions, function(exception) {
                            return exception.dealer.id;
                        }),
                        comment: comment
                    };

                    return fluentRest
                        .api()
                        .subscribers()
                        .dealers()
                        .exceptions()[status.toLowerCase()]()
                        .post(postData)
                        .then($scope.getExceptions);
                });
        }

        $scope.confirmChecked = function(exceptions, exceptionAction) {
            openConfirmModal(exceptions, exceptionAction.name, exceptionAction.modal.heading, exceptionAction.modal.verb);
        }

        $scope.viewComments = function(exception) {

            $scope.retrievingComments = true;

            var url = fluentRest
                .api()
                .subscribers(exception.dealer.subscriberId)
                .dealers(exception.dealer.id)
                .exceptions(exception.id)
                .comments()
                .toString();

            var commentPaging = new paging(10);
 
                    return commentPaging
                        .getPage(url)
                        .then(function(comments) {

                            var scope = {
                                subscriberId: exception.dealer.subscriberId,
                                exceptionId: exception.id,
                                exception: exception,
                                dealerId: exception.dealer.id,
                                gettingComments: false,
                                numberDeleted: 0,
                                display: ('Exception on {0}').format($filter('utcDate')(exception.date)),
                                paging: commentPaging,
                                comments: comments.items, 
                                selected: { 
                                },
                                getCommentsPage: function(pageNumber, that) {
                                    that = that || this;
                                    that.gettingComments = true;
                                    return commentPaging
                                        .getPage(url, pageNumber, { 
                                        })
                                        .then(function(comments) {
                                            that.comments = comments.items;
                                            that.gettingComments = false;
                                        });
                                },
                                deleteComment: function(comment) {
                                    var that = this.$parent;
                                    var scope = {
                                        title: 'Delete Comment?',
                                        message: ('Are you sure you want to delete the comment on {0} by {1}?').format($filter('utcDate')(comment.timestamp), comment.user)
                                    };

                                    modalService
                                        .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                                        .then(function(response) {
                                            if (!response)
                                                return;

                                            return fluentRest
                                                .api()
                                                .subscribers(that.subscriberId)
                                                .dealers(that.dealerId)
                                                .exceptions(that.exceptionId)
                                                .comments(comment.id)
                                                .delete()
                                                .then(that.getCommentsPage(null, that))
                                                .then(function() {
                                                    that.numberDeleted = that.numberDeleted + 1;
                                                });
                                        });
                                }

                            };

                            return modalService
                                .openTopBarModal(actionViewTemplates.paygoCustRecViewComments, scope)
                                .then(function(numberDeleted) {
                                    if (numberDeleted)
                                        exception.numberOfComments = exception.numberOfComments - numberDeleted;
                                    $scope.retrievingComments = false;
                                });

                        }); 
        };

        function getCustomerById(subscriberId, customerId) {
            return fluentRest
                .api()
                .subscribers(subscriberId)
                .customerAccounts(customerId)
                .paygo()
                .get();
        }

        function getMonitor(subscriberId, containerKey){

            return fluentRest
                .api()
                .subscribers(subscriberId)
                .containers()
                .monitors()
                .get().then(function(monitors) {
                  for(var i = 0, length = monitors.length; i < length; i++) {
                    if(monitors[i].tankId === containerKey) {
                      return monitors[i];
                    }
                  }
                });
        }

        function openPaygoCustomerAdmin (dealer, customerId, containerId){

            getCustomerById(dealer.subscriberId, customerId)
                .then(function(customer) {
                    if (customer) {
                        var scope = {
                            widgetName: 'Paygo Customer Records - Administrator',
                            widgetIcon: 'group',
                            widgetId: constants.widgets.paygoAdminCustRec.value.id,
                            widgetCode: constants.widgets.paygoAdminCustRec.value.code,
                            model: {
                                customer: customer,
                                containerId: containerId
                            }
                        };

                        modalService
                            .openActionView(actionViewTemplates.paygoAdminCustRec, scope, constants.widgets.paygoAdminCustRec.value.id);
                    }
                });
        }

        function openTMSMonitorAdmin (dealer, customerId, containerId){
            subscriberService.setAdminSubscriberId(dealer.subscriberId);

            drillService.openTmsAdminCustomer(containerId);
        }

        $scope.openCustomer = function(dealer, customerId, containerId, containerKey, exception) {

            $scope.highlightedException = exception;

            if ($scope.actionModel.exceptionGroups == 'tms'){
                openTMSMonitorAdmin(dealer, customerId, containerKey)
            } else {
                openPaygoCustomerAdmin(dealer, customerId, containerId)
            }
        };
    }
]);
