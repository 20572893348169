'use strict';

angular.module('angus.controllers').controller('registrationCtrl', [
    '$rootScope', '$scope', '$q', 'fluentRest', '$http', 'modalService', 'actionViewTemplates', 'constantsService', '_', 'promiseMonitor', 'registrationStatusService',
    function($rootScope, $scope, $q, fluentRest, $http, modalService, actionViewTemplates, constantsService, _, promiseMonitor, registrationStatusService) {
        var activeEditContactIndex = -1;
        var activeEditAddressIndex = -1;
        //handle date UI error
        $scope.subscriber = {
            monitorCreditStartDate: new Date(),
            monitorCreditEndDate: new Date()
        }
        $scope.openAddressModal = function(addressToEdit) {

            function getStateProvinceByCountryKey(countryKey) {
                return _.filter(constantsService.stateProvinces.enums, function(stateProv) {
                    return stateProv.value.country == countryKey;
                });
            }

            if($scope.subscriber.addresses && $scope.subscriber.addresses.length > 0)
                activeEditAddressIndex = _.indexOf($scope.subscriber.addresses, addressToEdit);
            else
                activeEditAddressIndex = -1;

            var address = addressToEdit ? _.cloneDeep(addressToEdit) : {
                country: constantsService.countries.unitedStates.key,
                editMode: true
            };

            var country = constantsService.countries.get(address.country);

            var scope = {
                address: address,
                subscriberName: $scope.subscriber.name,
                addressTypes: constantsService.addressType.enums,
                countries: constantsService.countries.enums,
                stateProvinces: getStateProvinceByCountryKey(country.key),
                stateProvinceLabel: country.value.stateProvinceLabel,
                postalPattern: country.value.postalPattern,
                countrySwitch: function(address, countryKey) {
                    this.stateProvinces = getStateProvinceByCountryKey(countryKey);
                    var country = constantsService.countries.get(countryKey);
                    this.stateProvinceLabel = country.value.stateProvinceLabel;
                    this.postalPattern = country.value.postalPattern;
                    address.stateProvince = null;
                    address.postalCode = null;
                }
            };

            modalService
                .openTopBarModal(actionViewTemplates.subRegEditAddresses, scope)
                .then(saveAddress);
        };

        $scope.openTMSContactModal = function() {
            var scope = {
                contact: $scope.subscriber.tmsContact ? _.cloneDeep($scope.subscriber.tmsContact) : {},
                subscriberName: $scope.subscriber.name
            };

            modalService
                .openTopBarModal(actionViewTemplates.subRegEditTMSContact, scope)
                .then(function(contact) {
                    if(!contact) return;

                    var promise = $http({
                        method: 'PUT',
                        url: '/api/subscribers/{0}/tmsContacts'.format($scope.subscriberId),
                        data: contact
                    })
                        .then(function(result) {
                            $scope.subscriber.tmsContact = result.data;
                        });

                    $scope.tmsContactMonitor.monitor(promise);
                });
        };

        $scope.openContactModal = function(contactToEdit) {
            if($scope.subscriber.contacts && $scope.subscriber.contacts.length > 0)
                activeEditContactIndex = _.indexOf($scope.subscriber.contacts, contactToEdit);
            else
                activeEditContactIndex = -1;

            var contact = {
                types: [],
                phones: [{}]
            };

            if(contactToEdit) {
                contact = _.cloneDeep(contactToEdit);
                contact.phones = _.map(contact.phones, function(phone) {
                    return phone;
                });
            }

            var scope = {
                contact: contact,
                contactTypes: constantsService.contactType.enums,
                subscriberName: $scope.subscriber.name,
                businessPhoneKey: constantsService.phoneType.business,
                phoneTypes: constantsService.phoneType.enums,
                allPhones: function(contact) {
                    return (contact.phones.length > 2);
                },
                addPhone: function(contact) {
                    contact.phones.push({});
                },
                removePhone: function(contact, phone) {
                    if(contact.phones.length > 1) {
                        _.remove(contact.phones, function(p) {
                            return p == phone;
                        });
                    }
                },
                phoneTypeChanged: function(contact, phone) {
                    if(phone.type != constantsService.phoneType.business) {
                        phone.extension = null;
                    }
                }
            };

            modalService
                .openTopBarModal(actionViewTemplates.subRegEditContacts, scope)
                .then(saveContact);
        };

        $scope.confirmRemoveContact = function(contact) {
            modalService
                .openTopBarModal(actionViewTemplates.subRegConfirmDeleteContact, {
                    contact: contact
                })
                .then(removeContact);
        };

        var toDate = function(date) {  
            var updatedDate = date ? date.toString().slice(0, -1) : null;
            updatedDate =  updatedDate ? moment(updatedDate).toDate() : null;  
            return updatedDate
        }

        function init() {

            if (!$scope.registrationStatuses) {
                registrationStatusService.getRegistrationStatuses()
                    .then (function (registrationStatuses) {
                        $scope.registrationStatuses = registrationStatuses;
                    });
            }

            if ($scope.subscriberId)
            {
                var promise = $q.when(fluentRest.api().subscribers($scope.subscriberId).get());

                return promise
                    .then(function(subscriber) {
                        $scope.subscriber = subscriber;
                        $scope.subscriber.monitorCreditStartDate = toDate(subscriber.monitorCreditStartDate);
                        $scope.subscriber.monitorCreditEndDate = toDate(subscriber.monitorCreditEndDate);

                        if(subscriber.registrationStatusKey == constantsService.registrationStatus.live)
                            $scope.subscriber.isLive = true;

                        var tmsPromise = $http({
                            method: 'GET',
                            url: '/api/subscribers/{0}/tmsContacts'.format($scope.subscriberId)
                        })
                            .then(function(result) {
                                $scope.subscriber.tmsContact = result.data;
                            });

                        $scope.tmsContactMonitor = new promiseMonitor(tmsPromise);
                    });
            }
            else {
                $scope.subscriber.registrationStatusKey = constantsService.registrationStatus.connectionSetup
            } 
        }

        //Contacts
        function removeContact(contact) {
            if(contact)
                $http.delete('api/subscribers/' + $scope.subscriber.id + '/contacts/' + contact.id)
                    .success(function() {
                        $scope.subscriber.contacts.splice($scope.subscriber.contacts.indexOf(contact), 1);
                        $scope.isLoading = false;
                    });
        }

        function saveContact(contact) {
            $scope.isLoading = true;
            if(contact) {
                if($scope.subscriber.id) {
                    if(contact.id)
                        $http.put('api/subscribers/' + $scope.subscriber.id + '/contacts/' + contact.id, contact)
                            .success(function(contact) {
                                $scope.subscriber.contacts = _($scope.subscriber.contacts).map(function(oldContact) {
                                    return (oldContact.id == contact.id) ? contact : oldContact;
                                }).value();

                                $scope.isLoading = false;
                            });
                    else {
                        $http.post('api/subscribers/' + $scope.subscriber.id + '/contacts', contact)
                            .success(function(contact) {
                                if(!$scope.subscriber.contacts) $scope.subscriber.contacts = [];
                                $scope.subscriber.contacts.push(contact);
                            });
                    }
                } else {
                    //TODO: This is a hack because i don't want to start rewriting code.  Be careful how you guys initialize stuff.....
                    if(!$scope.subscriber.contacts) $scope.subscriber.contacts = [];

                    if($scope.subscriber.contacts.length === 0 || activeEditContactIndex === -1)
                        $scope.subscriber.contacts.push(contact);
                    else
                        _.extend($scope.subscriber.contacts[activeEditContactIndex], contact);
                }
            }
            $scope.isLoading = false;
        }

        //Addresses
        $scope.confirmRemoveAddress = function(address) {
            modalService
                .openTopBarModal(actionViewTemplates.subRegConfirmDeleteAddress, {
                    address: address
                })
                .then(removeAddress);
        };

        function removeAddress(address) {
            if(address)
                $http
                    .delete('api/subscribers/' + $scope.subscriber.id + '/addresses/' + address.id)
                    .success(function() {
                        $scope.subscriber.addresses.splice($scope.subscriber.addresses.indexOf(address), 1);
                    });

        }

        function saveAddress(address) {
            $scope.isLoading = true;
            if(address) {
                if($scope.subscriber.id) {
                    if(address.id)
                        $http.put('api/subscribers/' + $scope.subscriber.id + '/addresses/' + address.id, address)
                            .success(function() {
                                $scope.subscriber.addresses = _($scope.subscriber.addresses).map(function(oldAddress) {
                                    return (oldAddress.id == address.id) ? address : oldAddress;
                                }).value();
                                $scope.isLoading = false;
                            });
                    else {
                        $http.post('api/subscribers/' + $scope.subscriber.id + '/addresses', address)
                            .success(function(addressId) {
                                address.id = addressId;
                                if(!$scope.subscriber.addresses) $scope.subscriber.addresses = [];
                                $scope.subscriber.addresses.push(address);
                            });
                    }
                } else {
                    if(!$scope.subscriber.addresses) $scope.subscriber.addresses = [];

                    if($scope.subscriber.addresses.length === 0 || activeEditAddressIndex === -1)
                        $scope.subscriber.addresses.push(address);
                    else
                        _.extend($scope.subscriber.addresses[activeEditAddressIndex], address);
                }
            }
            $scope.isLoading = false;
        }

        //Subscriber
        $scope.saveSubscriber = function() {
            $scope.isLoading = true;

            const widgetPackageKeys = $scope.subscriber.widgetPackageKeys.map(widgetPackageKey => parseInt(widgetPackageKey, 10));

            var postData = {
                subscriberKey: $scope.subscriber.abosKey,
                subscriberCd: $scope.subscriber.code,
                subscriberName: $scope.subscriber.name,
                subscriberDba: $scope.subscriber.dba,
                widgetPackageKeys: widgetPackageKeys,
                isAdept: $scope.subscriber.isAdept,
                isAms: $scope.subscriber.isPaygo,
                isBrite: $scope.subscriber.isBrite,
                registrationStatusKey: $scope.subscriber.registrationStatusKey,
                updateUser: $rootScope.user.username,
                monitorCreditStartDate: $scope.subscriber.monitorCreditStartDate,
                monitorCreditEndDate: $scope.subscriber.monitorCreditEndDate
            };

            var savePromise =
                $scope.subscriber.id
                    ? $http.put(('api/subscribers/{0}').format($scope.subscriber.id), postData)
                    : $http.post('api/subscribers', postData);

            savePromise
                .then(function (res) {
                    if (!!res.data) {
                        $scope.subscriberId = res.data.id;

                        $scope.setActiveTabs($scope.subscriber.isPaygo, $scope.subscriber.widgetPackageKeys, $scope.subscriber.registrationStatusKey);

                        init()
                            .then(function () {
                                $scope.isLoading = false;
                            });
                    }
                    else {
                        $scope.isLoading = false;
                    }
                });
        };

        init();

        $scope.isContactOfType = function(contact, typeToCheck) {
            return _(contact.types).some(function(type) {
                return typeToCheck.is(type);
            });
        };

        $scope.isContactPrimary = function(contact) {
            return _(contact.types).some(function(type) {
                return type == constantsService.contactType.primary;
            });
        };

        $scope.isContactTechnical = function(contact) {
            return _(contact.types).some(function(type) {
                return type == constantsService.contactType.technical;
            });
        };

        $scope.isAddressHeadquarters = function(address) {
            return _(address.types).some(function(type) {
                return type == constantsService.addressType.headquarters;
            });
        };

        $scope.isAddressBilling = function(address) {
            return _(address.types).some(function(type) {
                return type == constantsService.addressType.billing;
            });
        };

        $scope.isAddressShipping = function(address) {
            return _(address.types).some(function(type) {
                return type == constantsService.addressType.shipping;
            });
        };

        $scope.isAddressVPN = function(address) {
            return _(address.types).some(function(type) {
                return type == constantsService.addressType.vpn;
            });
        };

    }
]);
