'use strict';

angular.module('angus.controllers').controller('budgetsTankGainLossController', [
    '$scope', '$rootScope', '$http', '$q', 'moment', 'actionViewTemplates', 'saveAs', '_', 'budgetsTankGainLossService', 'modalService', 'hierarchyService', 'fiscalYearService', 'alertsService', 'promiseMonitor', 'Paging',
    function($scope, $rootScope, $http, $q, moment, actionViewTemplates, saveAs, _, budgetsTankGainLossService, modalService, hierarchyService, fiscalYearService, alertsService, promiseMonitor, Paging) {

        var subscriberId = $scope.subscriberId;

        $scope.alerts = new alertsService();
        $scope.paging = new Paging($scope.pageSize || 10);

        var budgets = new budgetsTankGainLossService(subscriberId);

        $scope.sortBy = 'name';
        $scope.ascending = true;

        $scope.sortBudgetsTankGainLoss = function(sortBy) {
            if($scope.sortBy == sortBy) $scope.ascending = !$scope.ascending;
            else $scope.sortBy = sortBy;

            return $scope.getBudgetsTankGainLoss();
        };

        $scope.getBudgetsTankGainLoss = function(pageNumber) {
            var params = {
                hierarchyNodeId: $scope.selectedTGL.hierarchyNodeId,
                fiscalYear: $scope.selectedTGL.yearTGL,
                p: $scope.selectedTGL.products,
                name: $scope.selectedTGL.searchTerm,
                sortBy: $scope.sortBy,
                ascending: $scope.ascending
            };

            var promise = $scope.paging.getPage(('api/subscribers/{0}/budgetsTankGainLoss').format(subscriberId), pageNumber, params)
                .then(function(TGLbudgets) {
                    $scope.budgetsTankGainLoss = TGLbudgets.items;

                    _.forEach($scope.budgetsTankGainLoss, function(budget) {
                        budget.selected = _.some($scope.selectedBudgetsTankGainLoss, function(selectedBudget) { return selectedBudget.id == budget.id });
                    });

                    return $scope.budgetsTankGainLoss;
                });

            $scope.budgetMonitorTGL = new promiseMonitor(promise);

            return promise;
        };

        $scope.getExistingYearsTankGainLoss = function() {
            return $q.all([
                fiscalYearService.getFiscalYearStartMonth(subscriberId),
                // budgets.getExistingYears($scope.selectedTGL.hierarchyNodeId)
                getExistingYears()
            ])
            .spread(function(startMonth, years) {
                $scope.existingYearsTankGainLoss = years.data;
                if(!$scope.selectedTGL.yearTGL || !_.some($scope.existingYearsTankGainLoss, function(year) { return year == $scope.selectedTGL.yearTGL }) ) {
                    var currentFiscalYear = moment().year();

                    // Advance to the next year as the prior fiscalYear year is over
                    if(startMonth && moment().month() >= startMonth) currentFiscalYear += 1;

                    $scope.selectedTGL.yearTGL = _.find($scope.existingYearsTankGainLoss, function(year) { return year == currentFiscalYear }) || $scope.existingYearsTankGainLoss[0];
                }

                return $scope.existingYearsTankGainLoss;
            });
        };

        function getProducts() {
            return budgets
                .getProducts()
                .then(function(products) {
                    $scope.products = products;
                });
        }

        function getExistingYears() {
            return $http.get(('api/subscribers/{0}/budgetsTankGainLoss/years').format(subscriberId));
        }

        var init = $q
            .when($rootScope.user.role == 'sysadmin')
            .then(function(isAdmin) {
                return isAdmin
                    ? hierarchyService.getDivisionHierarchy(subscriberId)
                    : $rootScope.user.hierarchy.division.nodes;
            })
            .then(function(divisionNodes) {
                var removeUnknownNode = function(div) { return div.entityId != '-1' };

                $scope.divisionNodes = divisionNodes.filter(removeUnknownNode);
                $scope.divisionNodes.forEach(function(node) { if(node.children) node.children = node.children.filter(removeUnknownNode) });

                $scope.selectedTGL = {
                    hierarchyNodeId: _.find(divisionNodes, function(node) { return node.isRoot }).id
                };

                $scope.selectedBudgetsTankGainLoss = [];

                $scope.getExistingYearsTankGainLoss();
            })
            .then(function() {
                return $q.all([
                    fiscalYearService
                        .getFiscalYearMonthsOrdered(subscriberId),
                    getProducts(),
                    $scope
                        .getBudgetsTankGainLoss()
                ])
                .spread(function(months) {
                    $scope.monthsTGL = months;
                    $scope.yearsTGL = budgets.getYears();
                    // $scope.yearsTGL = _.range(moment().subtract(2, 'years').year(), moment().add(5, 'years').year(), 1);

                    $scope.displayOptions = {
                        headers: 'Headers',
                        details: 'Details'
                    };

                    $scope.getBudgetsTankGainLoss();
                    $scope.display = $scope.displayOptions.headers;
                });
            });

        $scope.budgetMonitorTGL = new promiseMonitor(init);

        $scope.cancel = function() {
            delete $scope.budgetTGL;
            $scope.alerts.clearAll();
        };

        $scope.addBudgetTankGainLoss = function() {
            $scope.alerts.clearAll();

            var monthlyValues = _.reduce($scope.monthsTGL, function(result, monthName) {
                result[monthName.toLowerCase()] = 0;
                return result;
            }, {});

            $scope.budgetTGL = {
                units: _.cloneDeep(monthlyValues)
            };
        };

        $scope.editBudgetTankGainLoss = function(budget) {
            $scope.alerts.clearAll();

            $scope.budgetTGL = {
                id: budget.id,
                name: budget.name,
                fiscalYear: budget.fiscalYear,
                productCategoryId: budget.productCategoryId,
                hierarchyNodeId: budget.hierarchyNodeId,
                units: _.cloneDeep(budget.units)
            };
        };

        $scope.saveBudgetTankGainLoss = function(budget) {
            if(!budget) return;
            if(!budget.monitor) budget.monitor = new promiseMonitor();

            var promise =
                budget.id
                    ? $http.put(('api/subscribers/{0}/budgetsTankGainLoss/{1}').format(subscriberId, budget.id), budget)
                        .then(function() {
                            return $scope.getBudgetsTankGainLoss();
                        })
                        .then(function() {
                            $scope.cancel();
                        })
                        .catch(function(err) {
                            $scope.alerts.addError(err.msg || 'Unable to update budget.');
                        })

                    : $http.post(('api/subscribers/{0}/budgetsTankGainLoss').format(subscriberId), budget)
                        .then(function() {
                            $scope.selectedTGL.yearTGL = budget.fiscalYear;
                            return $scope.getExistingYearsTankGainLoss();
                        })
                        .then(function() {
                            return $scope.getBudgetsTankGainLoss();
                        })
                        .then(function() {
                            $scope.cancel();
                        })
                        .catch(function(err) {
                            $scope.alerts.addError(err.msg || 'Unable to save budget.');
                        });

            budget.monitor.monitor(promise);

            return promise;
        };

        $scope.deleteBudgetTankGainLoss = function(budget) {
            modalService
                .getConfirmation('Delete Budget', 'Are you sure you want to delete this ' + budget.name + ' budget?')
                .then(function(result) {
                    if(result)
                        return $http.delete(('api/subscribers/{0}/budgetsTankGainLoss/{1}').format(subscriberId, budget.id))
                            .then(function() {
                                return $scope.getExistingYearsTankGainLoss();
                            })
                            .then(function() {
                                return $scope.getBudgetsTankGainLoss();
                            })
                            .catch(function(err) {
                                $scope.alerts.addError(err.msg || 'Unable to delete budget.');
                            });

                });
        };

        $scope.deleteBudgetsTankGainLoss = function() {
            modalService
                .getConfirmation('Delete Budgets', 'Are you sure you want to delete the ' + $scope.selectedBudgetsTankGainLoss.length + ' selected budgets ?')
                .then(function(result) {
                    if(result) {
                        var failCount = 0;

                        return $q.all([_.map($scope.selectedBudgetsTankGainLoss, function(selectedBudget) {
                            return $http.delete(('api/subscribers/{0}/budgetsTankGainLoss/{1}').format(subscriberId, selectedBudget.id))
                                .then(function() {
                                    return $scope.getExistingYearsTankGainLoss();
                                })
                                .then(function() {
                                    return $scope.getBudgetsTankGainLoss();
                                })
                                .catch(function(err) {
                                    $scope.alerts.addError(err.msg || 'Unable to delete budget.');
                                    failCount++;
                                });
                            })
                        ])
                        .then(function() {
                            $scope.clearSelectedBudgetsTankGainLoss();

                            return $scope.getExistingYearsTankGainLoss();
                        })
                        .then(function() {
                            return $scope.getBudgetsTankGainLoss();
                        })
                        .then(function() {
                            $scope.clearSelectedBudgetsTankGainLoss();
                        });
                    }
                });
        };

        $scope.copyBudgetTankGainLoss = function(budget) {
            var scope = {
                currentFiscalYearTGL: budget.fiscalYear,
                budgetsTGL: budget.name,
                yearsTGL: $scope.yearsTGL
            };

            modalService
                .openTopBarModal(actionViewTemplates.subRegCopyBudgetsTGL, scope)
                .then(function(toYear) {
                    if(!toYear) return;

                    return $http({
                        method: 'POST',
                        url: (('api/subscribers/{0}/budgetsTankGainLoss/{1}/copy').format(subscriberId, budget.id)),
                        data: {
                            fiscalYear: toYear
                        }
                    })
                    .then(function() {
                        $scope.selectedTGL.yearTGL = toYear;

                        return $scope.getExistingYearsTankGainLoss();
                    })
                    .then(function() {
                        return $scope.getBudgetsTankGainLoss();
                    })
                    .catch(function(err) {
                        $scope.alerts.addError('TGL Budget Name: ' + budget.name + ' --> ' + (err.msg || 'Unable to copy budget.'));
                    });
                });
        };

        $scope.copySelectedBudgetsTankGainLoss = function() {
            var scope = {
                currentFiscalYearTGL: $scope.selectedTGL.yearTGL,
                budgetsTGL: _.map($scope.selectedBudgetsTankGainLoss, 'name').join(' , '),
                yearsTGL: $scope.yearsTGL
            };

            modalService
                .openTopBarModal(actionViewTemplates.subRegCopyBudgetsTGL, scope)
                .then(function(toYear) {
                    if(!toYear) return;

                    var failCount = 0;

                    return $q.all([
                        _.map($scope.selectedBudgetsTankGainLoss, function(budget) {
                            return $http({
                                method: 'POST',
                                url: (('api/subscribers/{0}/budgetsTankGainLoss/{1}/copy').format(subscriberId, budget.id)),
                                data: {
                                    fiscalYear: toYear
                                }
                            })
                            .catch(function(err) {
                                $scope.alerts.addError(err.msg || 'Unable to copy the budget.');
                                failCount++;
                            });
                        })
                    ])
                    .then(function() {
                        $scope.clearSelectedBudgetsTankGainLoss();
                        $scope.selectedTGL.yearTGL = toYear;

                        return $scope.getExistingYearsTankGainLoss();
                    })
                    .then(function() {
                        return $scope.getBudgetsTankGainLoss();
                    })
                    .catch(function(err) {
                        $scope.alerts.addError(err.msg || 'Unable to copy all the budgets.');
                    });
                });
        };

        $scope.fiscalYearChangeTGL = function() {
            $scope.selectedBudgetsTankGainLoss = [];

            _.forEach($scope.budgetsTankGainLoss, function(budget) { budget.selectedTGL = false });
        };

        $scope.selectBudgetTankGainLoss = function(budget, selected) {
            budget.selectedTGL = selected;

            if(!budget.selectedTGL) _.remove($scope.selectedBudgetsTankGainLoss, function(selectedBudget) { return selectedBudget.id == budget.id } );
            else
                if(!_.some($scope.selectedBudgetsTankGainLoss, function(selectedBudget) { return selectedBudget.id == budget.id } ))
                    $scope.selectedBudgetsTankGainLoss.push({ name: budget.name, id: budget.id });
        };

        $scope.clearSelectedBudgetsTankGainLoss = function() {
            $scope.checkNoneTGL();
            $scope.selectedBudgetsTankGainLoss = [];
        };

        $scope.checkNoneTGL = function() {
            _.forEach($scope.budgetsTankGainLoss, function(budget) { $scope.selectBudgetTankGainLoss(budget, false) });
        };

        $scope.checkAllTGL = function() {
            _.forEach($scope.budgetsTankGainLoss, function(budget) { $scope.selectBudgetTankGainLoss(budget, true) });
        };
    }
]);
