'use strict';

angular.module('angus.directives')
    .directive('dateRange', [
        '$parse', 'moment',
        function($parse, moment) {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function(scope, element, attrs, ctrl) {

                    scope.$watch( function() {
                        return ctrl.$modelValue;
                    }, function( currentValue ) {
                        performCheck(currentValue);
                    });

                    scope.$watch(attrs.dateRange, function(value){
                        performCheck(value);
                    });

                    function performCheck(value){
                        ctrl.$setValidity( 'max', true);
                        ctrl.$setValidity( 'min', true);

                        if(value){

                            var currentDate = moment(value);
                            var maxDate     = moment($parse( attrs.maxDate )( scope ));
                            var minDate     = moment($parse( attrs.minDate )( scope ));

                            if(attrs.maxDate && currentDate.isAfter(maxDate))
                                ctrl.$setValidity( 'max', false);

                            if(attrs.minDate && currentDate.isBefore(minDate))
                                ctrl.$setValidity( 'min', false);
                        }
                    }
                }
            };
        }
    ]).directive('yearMonthDateRange', [
        '$parse', 'moment',
        function($parse, moment) {
            return {
                require: 'ngModel',
                restrict: 'A',
                link: function(scope, element, attrs, ctrl) {

                    scope.$watch( function() {
                        return ctrl.$modelValue;
                    }, function( ) {
                        performCheck();
                    });

                    scope.$watch(attrs.yearMonthDateRange, function(){
                        performCheck();
                    });

                    function performCheck(){
                        ctrl.$setValidity( 'max', true);
                        ctrl.$setValidity( 'min', true);

                        var stDate = $parse( attrs.startDate )( scope );
                        var enDate = $parse( attrs.endDate )( scope );

                        if(stDate && enDate){

                            stDate = stDate.split('/');
                            enDate = enDate.split('/');

                            var startDate = moment().year(Number(stDate[2])).month(Number(stDate[0])).startOf('month');
                            var endDate   = moment().year(Number(enDate[2])).month(Number(enDate[0])).endOf('month');

                            if(startDate.isAfter(endDate))
                                ctrl.$setValidity( 'max', false);

                            if(endDate.isBefore(startDate))
                                ctrl.$setValidity( 'min', false);
                        }
                    }
                }
            };
        }
    ]);