angular.module('angus.controllers').controller('driverPerfSubRegSettingsCtrl', [
    '$scope', '$http', 'constantsService', 'productsService', 'hierarchyService', 'promiseMonitor',
    function($scope, $http, constantsService, productsService, hierarchyService, promiseMonitor) {
        'use strict';
        $scope.promiseMonitor = new promiseMonitor();

        $scope.periodIndicators = constantsService.periodIndicators.enums;

        var promise = productsService.getProducts($scope.subscriberId)
            .then(function(products) {
                $scope.products = products;

                return $http({
                    url: ('api/subscribers/{0}/drivers').format($scope.subscriberId),
                    method: 'POST',
                    data: {
                        deliveryCenter: hierarchyService.getLeafNodeEntityIds($scope.hierarchyNodes, $scope.hierarchyNodeId),
                        excludeDrivers : true
                    }
                });
            })
            .then(function(drivers) {
                $scope.drivers = drivers.data;
            });

        $scope.promiseMonitor.monitor(promise);

    }
]);