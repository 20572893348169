angular.module('angus.controllers').controller('serviceHistoryReportContainerCtrl', [
    '$scope', '$rootScope', '$http', 'periodIndicators', 'productsService', 'actionViewTemplates', 'tradeClassService', 'constantsService', '_', 'Paging', 'restrictedAccessService',
    function($scope, $rootScope, $http, periodIndicators, productsService, actionViewTemplates, tradeClassService, constantsService, _, Paging, restrictedAccessService) {

        'use strict';

        $scope.sideNavTemplate   = actionViewTemplates.sideNav;
        $scope.items             = [];
        $scope.pageSize          = 50;

        $scope.$watch(function() {
            return $scope.model;
        }, function(newVal) {
            if (newVal) {
                addNewOpenReport(newVal);
            }
        }, true);

        $scope.switchActiveItem = function(item) {
            if (item.active) {
                $scope.activeItem = item;
            }
        };

        function addNewOpenReport(model) {
            var openReport = _.find($scope.items, function(item) {
                return item.params == model;
            });
            if (!openReport) {
                openReport = {
                    name        : 'Acct: ' + model.accountNumber,
                    icon        : 'table',
                    templateUrl : actionViewTemplates.serviceHistoryReportContent,
                    active      : true,
                    params      : model,
                    closeable   : true,
                    paging      : new Paging($scope.pageSize),
                    sorting     : {
                        sortProp : 'date',
                        sortDir  : 'desc'
                    },
                    table       : []
                };
                $scope.items.push(openReport);
            }
            $scope.activeItem = openReport;
        }

        $scope.addNewOpenReport = function(model){
            addNewOpenReport(model);
        };

        $scope.closeItem = function(report) {
            if (report) {
                var openReport = _.find($scope.items, function(item) {
                    return item.name == report.name;
                });
                var index = $scope.items.indexOf(openReport);

                if ($scope.activeItem == openReport)
                    $scope.activeItem = $scope.items[index - 1];

                $scope.items.splice(index, 1);
            }
        };
        var restrictedAccessPromise;
          function getRestrictedAccess() {
            if(!restrictedAccessPromise){
              restrictedAccessPromise = restrictedAccessService.getAccess().then(function (access) {
                return $scope.restrictedAccess = access.restrictedAccess;
              });
            }
            return restrictedAccessPromise;
          }

        var headingCenterClass = 'text-center clickable';

   getRestrictedAccess()
            .then(function(access) {

          var aggregateHeadings = [{
              name: 'Date',
              isHidable: true,
              propClass: headingCenterClass,
              propName: 'date',
              isSortable: true
          }, {
              name: 'Work Order #',
              isHidable: true,
              propClass: headingCenterClass,
              propName: 'workOrderNumber',
              isSortable: true
          }, {
              name: 'Call Reason',
              isHidable: true,
              propClass: headingCenterClass,
              propName: 'callReason',
              isSortable: true
          }, {
              name: 'Primary Tech',
              isHidable: true,
              propClass: headingCenterClass,
              propName: 'primaryTech',
              isSortable: true
          }, {
              name: 'Billed Hours',
              isHidable: true,
              propClass: headingCenterClass,
              propName: 'laborHoursBilled',
              isSortable: true
          }, {
              name: 'Contract Description',
              isHidable: true,
              propClass: headingCenterClass,
              propName: 'contractDescription',
              isSortable: true
          }, {
              name: 'Contract Renewal Date',
              isHidable: true,
              propClass: headingCenterClass,
              propName: 'contractRenewalDate',
              isSortable: true
          }, {
              name: 'Flat Rate',
              isHidable: true,
              propClass: headingCenterClass,
              propName: 'flatRateFlag',
              isSortable: true
          },{
                  name: 'Parts Margin',
                  isHidable: true,
                  propClass: headingCenterClass,
                  propName: 'partsMargin',
                  isSortable: true
            },{
                  name: 'Labor Margin',
                  isHidable: true,
                  propClass: headingCenterClass,
                  propName: 'laborMargin',
                  isSortable: true
              },{
                  name: 'Revenue',
                  isHidable: true,
                  propClass: headingCenterClass,
                  propName: 'callBilled',
                  isSortable: true
              },{
                  name: 'Call Margin',
                  isHidable: true,
                  propClass: headingCenterClass,
                  propName: 'callMargin',
                  isSortable: true
              }];

          $scope.aggregateHeadings = [];

          _.forEach(aggregateHeadings, function(heading){
            if((access.costAndMargin && (heading.propName == 'partsMargin' || heading.propName == 'laborMargin' || heading.propName == 'callMargin'))
              ||(access.revenue && (heading.propName == 'callBilled' || heading.propName == 'laborHoursBilled')))
              return;
              $scope.aggregateHeadings.push(heading);
            })
        });
    }
]);