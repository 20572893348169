angular
  .module('angus.controllers')
  .controller('tmsMonitorSettingsCtrl', [

    '$scope', '$rootScope',
    function($scope, $rootScope) {
      'use strict';

      $scope.widget.instance.settings.hierarchyNodeId = $scope.widget.instance.settings.hierarchyNodeId || $rootScope.user.hierarchy.dealer.nodeId;

    }
  ]);
