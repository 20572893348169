angular.module('angus.controllers').controller('acctGainLossByTankSubRegSettingsCtrl', [
    '$scope', '$http', 'promiseMonitor','productsService', 'tradeClassService', 'constantsService','$q','salesPersonService','deliveryTypeService',
    function($scope, $http, promiseMonitor,productsService,tradeClassService, constantsService, $q, salesPersonService, deliveryTypeService) {
        'use strict';
        $scope.promiseMonitor = new promiseMonitor();

        $scope.periodIndicators = constantsService.marginPeriodIndicators.enums;
        $scope.ieoOptions = constantsService.includeExcludeOnly.enums;

 var promise = $q.all([productsService.getProducts($scope.subscriberId)
            .then(function(products) {
                $scope.products = products;
            }),
            tradeClassService.getTradeClasses($scope.subscriberId)            
            .then(function(classes) {
                $scope.tradeClasses = classes;
            }),
           salesPersonService.getSalesPerson($scope.subscriberId)            
            .then(function(classes) {
                $scope.salespersons = classes;
            }),
            deliveryTypeService.getDeliveryTypes($scope.subscriberId)            
            .then(function(deliveryTypes) {
                $scope.deliveryTypes = deliveryTypes;
            })

        ]);

        $scope.promiseMonitor.monitor(promise);
    }
]);