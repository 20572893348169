angular.module('angus.controllers').controller('deliveryHistoryReportContentCtrl', [
     '$scope', '$http', 'drillService', 'gridService2', 'gridState', 'subscriberService',
    function($scope, $http, drillService, gridService2, gridState, subscriberService) {
        'use strict';

        const subscriberId =  subscriberService.getSubscriberId();

        $scope.drillService = drillService;

        $scope.$watch(function() {
            return $scope.activeItem;
        }, function(newVal) {
            if (!newVal.grid)
                initDeliveryHistory();
        });


        function getColDefs(){
            return [
                gridService2.colDef.createText('customerName', 'Customer Name', 'customerName', { hide : true }),
                gridService2.colDef.createDate('deliveryDate','Delivery Date','deliveryDate', {sort: 'desc'}),
                gridService2.colDef.createText('deliveryTypeName', 'Delivery Type', 'deliveryTypeName', { hide : true }),
                gridService2.colDef.createNumber('units', 'Units Delivered', 'units'),
                gridService2.colDef.createPercentage('percentageOfIdealDrop', '% Ideal Drop Delivered', 'percentageOfIdealDrop', {}, {decimalPlaces: 1 }),
                gridService2.colDef.createPercentage('adjIdealDrop', 'Adjusted % Ideal Drop', 'adjIdealDrop', {}, {decimalPlaces: 1 }),
                gridService2.colDef.createPercentage('preDeliveryPercentFull','Start %','preDeliveryPercentFull', {}, {decimalPlaces: 1 }),
                gridService2.colDef.createPercentage('postDeliveryPercentFull','End %','postDeliveryPercentFull', {}, {decimalPlaces: 1 }),
                gridService2.colDef.createBoolean('preDeliveryUnitsCalculatedFlag','Start % Est','preDeliveryUnitsCalculatedFlag', {}, {decimalPlaces: 1 }),
                gridService2.colDef.createBoolean('postDeliveryUnitsCalculatedFlag','End % Est','postDeliveryUnitsCalculatedFlag', {}, {decimalPlaces: 1 }),
                gridService2.colDef.createNumber('usableVolume','Tank Usable','usableVolume', {}, {decimalPlaces: 0 }),
                gridService2.colDef.createNumber('containerUnitsAvailableClosestToDelivery','Prior In Tank','containerUnitsAvailableClosestToDelivery', {}, {decimalPlaces: 1 }),
                gridService2.colDef.createNumber('kFactorPrior', 'Prior WK', 'kFactorPrior', {}, {decimalPlaces: 2 }),
                gridService2.colDef.createNumber('kFactorDelivery', 'Delivery K', 'kFactorDelivery', {}, {decimalPlaces: 2 }),
                gridService2.colDef.createPercentage('kFactorVariance', 'K-Factor Variance', 'kFactorVariance', {}, {decimalPlaces: 1 }),
                gridService2.colDef.createNumber('baseLoadUnits','Baseload','baseLoadUnits', {}, {decimalPlaces: 2 }),
                gridService2.colDef.createBoolean('partialFillFlag', 'Partial Fill', 'partialFillFlag'),
                gridService2.colDef.createBoolean('runOutFlag', 'Run Out', 'runOutFlag'),
                gridService2.colDef.createBoolean('potentialRunOutFlag','Potential Run Out','potentialRunOutFlag'),
                gridService2.colDef.createBoolean('monitored', 'Tank Monitor', 'monitored'),
                gridService2.colDef.createText('deliveryGroup', 'Delivery Group', 'deliveryGroup'),
                gridService2.colDef.createText('driverName', 'Driver', 'driverName'),
                gridService2.colDef.createText('deliveryCenterName', 'Delivery Center', 'deliveryCenterName'),
            ];
        }

        function initDeliveryHistory() {
             var gridParams = {
                gridOptions : {
                    groupAggFunction : function(rows){
                        var percentageOfIdealDropCount  = 0;
                        var adjIdealDropCount           = 0;
                        var kFactorVarianceCount        = 0;

                        var retVal =  _.reduce(rows, function(result, row){
                            var data = row.data;
                            result.units += data.units;

                            if(data.percentageOfIdealDrop || data.percentageOfIdealDrop == 0){
                                if(!result.percentageOfIdealDrop) result.percentageOfIdealDrop = 0;
                                result.percentageOfIdealDrop += data.percentageOfIdealDrop;
                                percentageOfIdealDropCount++;
                            }

                            if(data.adjIdealDrop || data.adjIdealDrop == 0){
                                if(!result.adjIdealDrop) result.adjIdealDrop = 0;
                                result.adjIdealDrop += data.adjIdealDrop;
                                adjIdealDropCount++;
                            }

                            if(data.kFactorVariance || data.kFactorVariance == 0){
                                if(!result.kFactorVariance) result.kFactorVariance = 0;
                                result.kFactorVariance += data.kFactorVariance;
                                kFactorVarianceCount++;
                            }

                            return result;
                        }, {
                            units :0
                        });


                        if(percentageOfIdealDropCount) retVal.percentageOfIdealDrop = retVal.percentageOfIdealDrop / percentageOfIdealDropCount;
                        if(adjIdealDropCount) retVal.adjIdealDrop = retVal.adjIdealDrop / adjIdealDropCount;
                        if(kFactorVarianceCount) retVal.kFactorVariance = retVal.kFactorVariance / kFactorVarianceCount;

                        return retVal;
                    }
                },
                gridState     : gridState(subscriberId, 'deliveryHistory'),
                defs          : getColDefs(),
                exportOptions : { fileName: 'Delivery History' }
            };

            return gridService2
                .createGrid(gridParams)
                .then(function(grid){
                    $scope.activeItem.grid = grid;
                    $scope.getDeliveries();
                });
        }

        $scope.getDeliveries = function() {
            var rowPromise = $http
                .get('api/subscribers/{0}/batches/deliveries/deliveryLines/{1}'.format(subscriberId, $scope.activeItem.params.containerKey))
                .then(function(result){
                    return result.data;
                });

            var promise = $scope.activeItem.grid.setRows(rowPromise);
            return promise;
        };
    }
]);
