angular.module('angus.controllers').controller('pricePlanProfAnalysisCtrl', [
    '$scope', '$rootScope', '$q', '$http', 'hierarchyService',
    function($scope, $rootScope, $q, $http, hierarchyService) {
      'use strict';

      function getPricePlanCounts() {

          $scope.isProductSelected = function(){
            return !!$scope.widget.instance.settings.productIds.value.length;
          };

          var promise =  $http({
                method: 'POST',
                url: ('api/subscribers/{0}/pricingPlans/counts').format($rootScope.user.subscriberId),
                data: {
                  totals      : true,
                  useBrite    : $scope.widget.instance.settings.useBrite.value,
                  divisionId  : $scope.widget.instance.settings.hierarchyNodeId,
                  productIds  : $scope.widget.instance.settings.productIds.value
                }
              });

          return promise
              .then(function(counts){
                  return counts.data;
              });
      }

      function init() {

        var promise = getPricePlanCounts()
            .then(function(counts){
                $scope.counts = counts;
                $scope.data = [
                  { label: 'Fixed',  data: [[1, $scope.counts.fixed.tanks]]},
                  { label: 'Cap',  data: [[1, $scope.counts.cap.tanks]]},
                  { label: 'Variable',  data: [[1, $scope.counts.variable.tanks]]}
                ];
            })
        return $scope.widget.promises.monitor(promise);
      }

      $scope.widget.setDefaultInitialization(init);


      $scope.$on('widgetSettingsChanged', function(){
        init();
      });
    }
]);
