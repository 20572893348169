angular.module('angus.directives').directive('snapShotAggRow', [
    'actionViewTemplates', '$parse', '$compile', '$templateCache', '_',
    function(actionViewTemplates, $parse, $compile, $templateCache, _) {

        'use strict';

        return {
            link: function(scope, element, attr) {

                scope.data = $parse(attr.snapShotAggRow)(scope);

                //NOTE: Angular doesn't let you ng-repeat a specific number of times.  This is a workaround
                scope.level = new Array($parse(attr.level)(scope) || 0);

                if (scope.data.nodes) {
                    _.forEach(Object.keys(scope.data.nodes), function(nodeKey) {
                        var key = $parse(attr.parentKey)(scope);
                        scope.parentKeys[nodeKey] = key;
                    });
                    element.after($compile($templateCache.get('templates/widgets/snapShots/templates/' + attr.templateName + '.html'))(scope));
                }
            }
        };
    }
]);
