angular.module('angus.controllers').controller('serviceReworkReportContentCtrl', [
    '$scope', '$rootScope', '$document', '$http', 'dateToString', 'modalService', '$timeout', '$q', 'hierarchyService',
    'actionViewTemplates', 'csvService', '_', 'constantsService', 'moment', 'datePickerService', 'lastServiceCallDate', 'drillService', 'fluentRest',
    'promiseMonitor',
    function ($scope, $rootScope, $document, $http, dateToString, modalService, $timeout, $q, hierarchyService,
    actionViewTemplates, csvService, _, constantsService, moment, datePickerService, lastServiceCallDate, drillService, fluentRest, PromiseMonitor) {

    'use strict';

    $scope.drillOptions1 = constantsService.reworkDrillOptions.enums;
    $scope.ieoOptions = constantsService.includeExcludeOnly.enums;
    $scope.seoOptions = constantsService.serviceCallCategory.enums;

    $scope.promiseMonitor = new PromiseMonitor();


    $scope.popover = {
      marginPopover: actionViewTemplates.serviceMarginPopover,
      serviceLocationPopover: actionViewTemplates.serviceLocationPopover,
      customerPopover: actionViewTemplates.customerPopover,
      contractPopover: actionViewTemplates.contractPopover
    };

    $scope.retrieveAccountInfo = drillService.retrieveAccountInfo;
    $scope.retrieveData = drillService.retrieveData;
    $scope.openServiceHistoryReport = drillService.openServiceHistoryReport;


    var serviceCenters = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.serviceCenter.nodes, $scope.settings.hierarchyNodeId);

    function getAllCallCategories(){
      return [
        constantsService.serviceCallCategory.get('installation').value.id,
        constantsService.serviceCallCategory.get('a/c').value.id,
        constantsService.serviceCallCategory.get('oil').value.id
      ];
    }

    function init() {
      return {
        hierarchyNodeId: $scope.settings.hierarchyNodeId,
        techs: $scope.settings.techs.value || [],
        includeNonCallbacks: constantsService.includeExcludeOnly.include.value.id,
        serviceCallCategoryList: $scope.settings.serviceCallList ? $scope.settings.serviceCallList.value : getAllCallCategories(),
        // includeNonCallbacks: constantsService.includeExcludeOnly.exclude.value.id,
        table: [],
        detailTable: []
      };
    }

    $scope.$watch(function () {
      return $scope.activeItem;
    }, function (newVal) {

      $scope.initComplete = true;
      $scope.showAggregates = true;
      $scope.noDefaultDatesFlag = false;

      if (newVal != $scope.listReport) {
        if (!newVal.filter) {
          $scope.activeItem.filter = init();
        }

        serviceCenters = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.serviceCenter.nodes, $scope.activeItem.filter.hierarchyNodeId);

        lastServiceCallDate.getLastServiceCallDate(serviceCenters)
          .then(function (lastPostingDate) {
            if (!lastPostingDate) {
              $scope.noDefaultDatesFlag = true;
              $scope.renderComplete = true;
            } else {
              if (!newVal.aggregateBackup)
                queryServiceAnalysisReport();
              else {
                newVal.aggregates = newVal.aggregateBackup;
                delete newVal.aggregateBackup;
              }
            }
          });
      }
    });


    $scope.closeTechs = function () {
      $scope.activeItem.filter.techs = angular.copy($scope.filters.techs);
    };

    $scope.retrieveServiceLabor = function (item) {
      modalService.openTopBarModal(actionViewTemplates.laborMarginPopover, item);
    };

    $scope.retrieveServiceParts = function (item) {
      modalService.openTopBarModal(actionViewTemplates.partsMarginPopover, item);
    };

    $scope.openHierarchyView = function () {
      hierarchyService
        .openServiceCenterModal($scope.activeItem.filter.hierarchyNodeId)
        .then(function (nodeId) {
          $scope.activeItem.filter.hierarchyNodeId = nodeId;
          serviceCenters = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.serviceCenter.nodes, $scope.activeItem.filter.hierarchyNodeId);
        });
    };

    $scope.updateHierarchyNode = function () {
      serviceCenters = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.serviceCenter.nodes, $scope.activeItem.filter.hierarchyNodeId);
    };

    $scope.updateFilters = function () {
      $scope.noDefaultDatesFlag = false;
      lastServiceCallDate.getLastServiceCallDate(serviceCenters)
        .then(function (lastPostingDate) {
          if (!lastPostingDate) {
            $scope.noDefaultDatesFlag = true;
          } else
            queryServiceAnalysisReport();
        });
    };


    function queryServiceAnalysisReport() {
      $scope.showAggregates = true;
      $scope.reinit = false;

      delete $scope.activeItem.aggregates;

      getServiceRevenueAggregates()
        .then(function (results) {
          $scope.activeItem.aggregates = results;
        });
    }

    function getServiceRevenueAggregates() {
      var params = {
        sc: serviceCenters,
        t: $scope.activeItem.filter.techs,
        ccb: $scope.activeItem.filter.includeNonCallbacks,
        sd: $scope.activeItem.dates.periodStartDate.format(),
        ed: $scope.activeItem.dates.lastPostingDate.format(),
        scl: $scope.activeItem.filter.serviceCallCategoryList
      };

      var promise = fluentRest
        .api()
        .subscribers($rootScope.user.subscriberId)
        .service()
        .serviceRework()
        .aggregates()
        .post(params);

      $scope.promiseMonitor.monitor(promise);
      return promise;

    }

    function adjustFilterInclusions(drillOption1) {
      $scope.activeItem.ccb = constantsService.includeExcludeOnly.include.value.id; //includeNonCallbacks;
      switch (drillOption1.key) {
      case constantsService.reworkDrillOptions.reworks.key:
        $scope.activeItem.ccb = constantsService.includeExcludeOnly.exclude.value.id; //false;
        break;
      }
    }

    $scope.drillIntoDetails = function (drillOption1, details, level) {
      delete $scope.activeItem.techId;
      delete $scope.activeItem.tech;
      delete $scope.activeItem.serviceCenterId;
      delete $scope.activeItem.serviceCenter;
      delete $scope.activeItem.techIds;

      $scope.activeItem.drillOption1 = drillOption1.value.display;

      adjustFilterInclusions(drillOption1);

      if (level === 0) {
        //only want the techs that are valid based on filters
        $scope.activeItem.techIds = _.map($scope.activeItem.aggregates.serviceCenters, function (serviceCenter) {
          return _.map(serviceCenter.techs, function (tech) {
            return tech.aggregates.primaryTechId;
          });
        });
      } else if (level == 1) {
        $scope.activeItem.serviceCenter = details.serviceCenterName;
        $scope.activeItem.serviceCenterId = details.serviceCenterId;

        //only want the techs that are valid based on filters
        $scope.activeItem.techIds = _.map($scope.activeItem.aggregates.serviceCenters[details.serviceCenterName].techs, function (tech) {
          return tech.aggregates.primaryTechId;
        });
      } else if (level == 2) {
        $scope.activeItem.serviceCenter = details.serviceCenterName;
        $scope.activeItem.serviceCenterId = details.serviceCenterId;
        $scope.activeItem.tech = details.primaryTech;
        $scope.activeItem.techId = details.primaryTechId;
      }

      $scope.activeItem.paging.currentPage = 1; // reset to 1st page
      $scope.queryServiceCallsDetails($scope.activeItem.paging.currentPage);
    }

    $scope.queryServiceCallsDetails = function (pageNumber) {
      $scope.showAggregates = false;
      $scope.reinit = false;

      delete $scope.activeItem.detailReport;

      $scope.promiseMonitor.monitor(
        $scope.activeItem.paging.postGetPage(
          ('api/subscribers/{0}/service/serviceRework/list').format($rootScope.user.subscriberId),
          pageNumber || $scope.activeItem.paging.currentPage, {
            sc: $scope.activeItem.serviceCenterId || serviceCenters,
            t: $scope.activeItem.techIds || $scope.activeItem.techId || $scope.activeItem.filter.techs,
            ccb: $scope.activeItem.ccb,
            sd: $scope.activeItem.dates.periodStartDate.format(),
            ed: $scope.activeItem.dates.lastPostingDate.format(),
            scl: $scope.activeItem.filter.serviceCallCategoryList,
            sortProp: $scope.activeItem.sorting.sortProp,
            sortDir: $scope.activeItem.sorting.sortDir
          })
        .then(function (results) {
          $scope.activeItem.detailReport = results.items;

        }));
    };

    $scope.sortFn = function (propName) {
      if (propName != $scope.activeItem.sorting.sortProp)
        $scope.activeItem.sorting.sortDir = 'asc';
      else
        $scope.activeItem.sorting.sortDir = $scope.activeItem.sorting.sortDir == 'asc' ? 'desc' : 'asc';

      $scope.activeItem.sorting.sortProp = propName;
      $scope.queryServiceCallsDetails($scope.activeItem.paging.currentPage);
    };

    $scope.openDatePicker = function () {
      lastServiceCallDate.getLastServiceCallDate(serviceCenters)
        .then(function (lastServiceCall) {
          return datePickerService.openDatePickerModal(
            $scope.activeItem.dates.periodStartDate,
            $scope.activeItem.dates.lastPostingDate,
            lastServiceCall);
        })
        .then(function (resultDates) {
          if (resultDates) {
            $scope.activeItem.dates.periodStartDate = resultDates[0];
            $scope.activeItem.dates.lastPostingDate = resultDates[1];
            queryServiceAnalysisReport(1);
          }
        });
    };

    $scope.saveReportFilters = function () {
      if (!$scope.activeItem.filter.id)
        $scope.openSaveReportFilterModal();
      else {
        $scope.savingFilter = true;
        $http.put(('api/subscribers/{0}/reports/{1}/{2}')
            .format($rootScope.user.subscriberId, $scope.widgetCode, $scope.activeItem.filter.id), $scope.activeItem.filter)
          .success(function () {
            $timeout(function () {
              $scope.savingFilter = false;
            }, 250);
          });
      }
    };

    $scope.openSaveReportFilterModal = function () {
      var scope = {
        validationUrl: ('api/subscribers/{0}/reports/' + $scope.widgetCode + '/nameAvailable?name={1}'),
        validationKey: 'name',
        modalTitle: 'Service Calls Report'
      };

      modalService
        .openTopBarModal(actionViewTemplates.saveReportFilter, scope)
        .then(function (name) {
          if (name) {
            $scope.isLoading = true;
            var previousId = $scope.activeItem.filter.id;
            $scope.activeItem.filter.name = name;
            $scope.activeItem.filter.id = null;

            $http.post(('api/subscribers/{0}/reports/{1}').format($rootScope.user.subscriberId, $scope.widgetCode), $scope.activeItem.filter)
              .success(function (response) {
                $scope.activeItem.filter.id = response.id;
                if (previousId) {
                  $scope.renameActiveReportTab($scope.activeItem.filter.name);
                } else {
                  $scope.addNewOpenReport($scope.activeItem.filter, true, false);
                }

                $scope.isLoading = false;
              });
          }
        });
    };

    $scope.validateFilterState = function () {
      $scope.disableNonChargeable = (constantsService.includeExcludeOnly.only.value.id === $scope.activeItem.filter.includeNonCallbacks);
      if ($scope.disableNonChargeable)
        $scope.activeItem.filter.includeNonChargeable = null;
    }

    $scope.expandAll = function (isExpanded) {
      _.forEach($scope.activeItem.groupCollapse, function (group) {
        group.isExpanded = isExpanded;
      });
    };

    $scope.returnToSummary = function () {
      $scope.showAggregates = true;

      delete $scope.activeItem.techId;
      delete $scope.activeItem.tech;
      delete $scope.activeItem.serviceCenterId;
      delete $scope.activeItem.serviceCenter;
      delete $scope.activeItem.techIds;
      delete $scope.activeItem.ccb;
      delete $scope.activeItem.ncb;
    }

    var hitLastServiceCenter = false;

    $scope.serviceCenterComplete = function () {
      hitLastServiceCenter = true;
    };

    $scope.techComplete = function () {
      if (hitLastServiceCenter) {
        $scope.reinit = true;
        hitLastServiceCenter = false;
      }
    };

    $scope.reinitComplete = function () {
      $scope.reinit = true;
    };

    $scope.downloadInProgress = function () {
      return csvService.downloadInProgress();
    };

    $scope.updateTechs = function (model) {
      if (model && $scope.activeItem.filter)
        $scope.activeItem.filter.techs = angular.copy(model);
    };

    $scope.getCsv = function () {
      var params = {
        sc: $scope.activeItem.serviceCenterId || serviceCenters,
        t: $scope.activeItem.techIds || $scope.activeItem.techId || $scope.activeItem.filter.techs,
        sd: $scope.activeItem.dates.periodStartDate.format(),
        ed: $scope.activeItem.dates.lastPostingDate.format(),
        ccb: $scope.activeItem.ccb || $scope.activeItem.filter.includeNonCallbacks,
        scl: $scope.activeItem.filter.serviceCallCategoryList,
        agg: $scope.showAggregates
      };

      csvService.postGetCsv(('api/subscribers/{0}/service/serviceRework/toCsv').format($rootScope.user.subscriberId), params);
    };
    }
]);
