angular
  .module('angus.services')
  .service('etlStatusService', ['$rootScope', 'fluentRest',
    function($rootScope, fluentRest) {
      return {
        get: function(subscriberId){
          return fluentRest.api().subscribers(subscriberId || $rootScope.user.subscriberId).etl().get();
        }
      };
    }
  ]);
