angular.module('angus.controllers').controller('acctGainsLossesBySrcContentCtrl', [
    '$scope', '$rootScope', '$document', '$http', 'constantsService', 'hierarchyService',  'lastDeliveryDateService',
    'datePickerService', 'moment', '_', 'csvService', 'drillService',
    function($scope, $rootScope, $document, $http, constantsService, hierarchyService, lastDeliveryDateService,
        datePickerService, moment, _, csvService, drillService) {

        $scope.renderComplete = false;

        $scope.$watch('activeItem.params.hierarchyNodeId', function(newVal, oldVal){
            if(newVal != oldVal){
                dcSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, newVal);
                queryGainsLossesReport();
            }
        })


        var headingCenterClass = "text-center clickable";

        $scope.tableHeadings = [{
            name: $scope.model.isLosses ? "Reason" : "Source",
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'reason',
            isSortable: true
        }, {
            name: "Total Customers",
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'accounts',
            isSortable: true
        }, {
            name: "% Of Total",
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'percentage',
            isSortable: true
        }, {
            name: "# of Tanks",
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'tanks',
            isSortable: true
        }, {
            name: "# of Service Locations",
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'serviceLocations',
            isSortable: true
        }];

        $scope.$watch(function() {
            return $scope.activeItem;
        }, function(newVal) {
            $scope.renderComplete = false;

            if (!newVal.report) {
                queryGainsLossesReport();
            } else {
                $scope.renderComplete = true;
            }
        });

        var dcSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.activeItem.params.hierarchyNodeId);

        function getGainsLosses() {
            var data= {
                divisionIds       : dcSetting,
                startDate         : $scope.activeItem.dates.periodStartDate,
                endDate           : $scope.activeItem.dates.lastDeliveryDate,
                sortProp          : $scope.activeItem.sorting.sortProp,
                sortDir           : $scope.activeItem.sorting.sortDir
            };

            $scope.displayDates = {
                startDate: moment.utc(data.startDate).format('L'),
                endDate: moment.utc(data.endDate).format('L')
            };

            if($scope.model.isLosses) data.groupByReason = 1;
            else                     data.groupBySource = 1;

            return $http({
                url: $scope.model.isLosses ?
                    ('api/subscribers/{0}/customerAccounts/losses').format($rootScope.user.subscriberId) : ('api/subscribers/{0}/customerAccounts/gains').format($rootScope.user.subscriberId),
                method: 'POST',
                data: data
            });
        }

        function queryGainsLossesReport() {
            $scope.renderComplete = false;
            $scope.reinit = false;

            delete $scope.activeItem.report;

            getGainsLosses()
                .then(function(result) {
                    $scope.activeItem.report = result.data ?
                        _.map(result.data, function(item) {
                            return _.extend(item, {
                                label: item.reason,
                                data: [
                                    [1, item.accounts]
                                ]
                            });
                        }) : [];

                    $scope.activeItem.report.aggregates = _.reduce($scope.activeItem.report, function(result, change) {
                        result.accounts += change.accounts ? change.accounts : 0;
                        result.percentage += change.percentage ? change.percentage : 0;
                        result.tanks += change.tanks ? change.tanks : 0;
                        result.serviceLocations += change.serviceLocations ? change.serviceLocations : 0;
                        return result;
                    }, {
                        accounts: 0,
                        percentage: 0,
                        tanks: 0,
                        serviceLocations: 0
                    });

                    $scope.renderComplete = true;
                });
        }

        function refreshData() {
            $scope.renderComplete = false;
            queryGainsLossesReport();
        }

        $scope.sort = function(propName) {
            if (propName != $scope.activeItem.sorting.sortProp)
                $scope.activeItem.sorting.sortDir = 'asc';
            else
                $scope.activeItem.sorting.sortDir = $scope.activeItem.sorting.sortDir == 'asc' ? 'desc' : 'asc';

            $scope.activeItem.sorting.sortProp = propName;
            refreshData();
        };

        $scope.openDeliveryHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.activeItem.params.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.activeItem.params.hierarchyNodeId = nodeId;
                    dcSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.activeItem.params.hierarchyNodeId);
                    queryGainsLossesReport();
                });
        };

        $scope.updateDeliveryHierarchyNode = function() {
            dcSetting = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.activeItem.params.hierarchyNodeId);
            queryGainsLossesReport();
        };

        $scope.openDatePicker = function() {
            return datePickerService.openDatePickerModal(
                $scope.activeItem.dates.periodStartDate,
                $scope.activeItem.dates.lastDeliveryDate,
                moment())

            .then(function(resultDates) {
                if (resultDates) {
                    $scope.activeItem.dates.periodStartDate = resultDates[0];
                    $scope.activeItem.dates.lastDeliveryDate = resultDates[1];
                    queryGainsLossesReport();
                }
            });
        };

        $scope.getSourceReasonDetails = function(change) {

            var keys = [];
            if (!change) {
                _.forEach($scope.activeItem.report, function(item) {
                    _.forEach(item.keys, function(key) {
                        keys.push(key);
                    });
                });
            }

            drillService
                .openAcctGainLossCustomersReport($scope.model.isLosses,
                    change ? change.reason : null,
                    change ? change.keys : keys,
                    $scope.activeItem.dates,
                    dcSetting);
        };

        $scope.downloadInProgress = function() {
            return csvService.downloadInProgress();
        };

        $scope.getCsv = function() {

            var data= {
                divisionIds       : dcSetting,                
                startDate         : $scope.activeItem.dates.periodStartDate.format(),
                endDate           : $scope.activeItem.dates.lastDeliveryDate.format()
            };

            if($scope.model.isLosses) data.groupByReason = 1;
            else                     data.groupBySource = 1;

            var url, title;

            if ($scope.model.isLosses) {
                url = ('api/subscribers/{0}/customerAccounts/losses').format($rootScope.user.subscriberId);
                title = 'Account Losses By Reason';
            } else {
                url = ('api/subscribers/{0}/customerAccounts/gains').format($rootScope.user.subscriberId);
                title = 'Account Gains By Source';
            }

            csvService.postGetCsv(url, data, title);
        };

        $scope.reinitComplete = function() {
            $scope.reinit = true;
        };
    }
]);
