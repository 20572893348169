angular.module('angus.controllers').controller('deliveryForecastingReportContainerCtrl', [
    '$scope', '$rootScope', '$http', '$q', '_', 'constantsService', 'dateCalculator', 'datePickerService', 'drillService', 'forecastingService', 'gridService2', 'gridState', 'hierarchyService', 'nodeType',
    function ($scope, $rootScope, $http, $q, _, constantsService, dateCalculator, datePickerService, drillService, forecastingService, gridService2, gridState, hierarchyService, nodeType) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        var nodeColDefs;

        $scope.periodIndicators = constantsService.forecastingPeriodIndicators.enums;

        /* Attach Start and End Dates for each Period Indicator. Will be used to identify if a Custom Date Range matches a Period */
        _.forEach($scope.periodIndicators, function (periodIndicator) {
            periodIndicator.dates = dateCalculator.calculatePeriod(null, null, periodIndicator.key);
        });

        $scope.filter = {
            periodIndicator: $scope.settings.periodIndicatorId.value,
            hierarchyNodeId: $scope.settings.hierarchyNodeId,
            productIds: $scope.settings.productIds.value,
            tradeClassIds: $scope.settings.tradeClassIds.value,
            deliveryCategoryIds: $scope.model ? $scope.model.deliveryCategoryIds : []
        }

        /* Dates may come from the Value that was clicked or it may come from the settings */        
        if ($scope.model) {
            $scope.dates = {
                startDate: moment.utc($scope.model.startDate || $scope.settings.startDate) || moment(),
                endDate: moment.utc($scope.model.endDate || $scope.settings.endDate) || moment()
            };

            /* Update the Period Indicator to match the dates and not what came from the Period Indicator dropdown from the Dashboard */
            $scope.filter.periodIndicator = getPeriodIndicatorFromDates($scope.dates.startDate, $scope.dates.endDate);
        } else {
            $scope.dates = {
                startDate: moment.utc($scope.settings.startDate) || moment(),
                endDate: moment.utc($scope.settings.endDate) || moment()
            };
        }

        $scope.deliveryCategories = forecastingService.getDeliveryCategories();
        $scope.deliveryCategoriesFiltered = _.filter($scope.deliveryCategories, 'isReportCategory');

        /* Functions */
        $scope.periodIndicatorChanged = periodIndicatorChanged;
        $scope.customDateRangeClicked = customDateRangeClicked;
        $scope.onDateChange = onDateChange;
        $scope.runReport = runReport;

        nodeType.getDivisionColDefs(subscriberId)
            .then(function (colDefs) {
                nodeColDefs = colDefs;

                var gridParams = {
                    gridOptions: {
                        groupAggFunction: function (rows) {
                             return _.reduce(rows, function(result, row){
                                 var data = row.data;

                                 result.estimatedDeliveryUnits += data.estimatedDeliveryUnits;

                                 return result;
                                },{
                                    estimatedDeliveryUnits: 0
                                });
                        }
                    },
                    gridState: gridState(subscriberId, $scope.widgetCode),
                    defs: getColDefs(),
                    clicks: getColClicks(),
                    exportOptions: { fileName: 'Delivery Forecasting' }
                };

                return gridService2.createGrid(gridParams);
            })
            .then(function (grid) {
                $scope.grid = grid;
                runReport();
            });


        function getColClicks() {
            return {
                accountNumber: {
                    onClick: function(row) {                        
                        drillService.openAccountInfo(row, drillService.context.delivery);
                    }
                },
                tankNumber: {
                    onClick: function(row) {
                    drillService.openTankInfo(row, drillService.context.delivery);
                    }
                }
            };
        }

        function getColDefs() {
            var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];

            colDefs.push(gridService2.colDef.createText('deliveryCategoryName', 'Delivery Category', 'deliveryCategoryName', { rowGroupIndex: nextIndex + 1, hide: true}));
            colDefs.push(gridService2.colDef.createText('productName', 'Product', 'productName', { rowGroupIndex: nextIndex + 1, hide: true }));
            colDefs.push(gridService2.colDef.createText('tradeClassName', 'Trade Class', 'tradeClassName', { rowGroupIndex: nextIndex + 1, hide: true }));
            colDefs.push(gridService2.colDef.createText('deliveryTypeName', 'Brite Delivery Type', 'deliveryTypeName', { rowGroupIndex: nextIndex + 1, hide: true }));


            colDefs.push(gridService2.colDef.createText('customerName', 'Customer Name', 'customerName', {hide: true}));
            colDefs.push(gridService2.colDef.createText('accountNumber', 'Account Number', 'customerId', { cellClass: ['center'], hide: true}));
            colDefs.push(gridService2.colDef.createText('city', 'City', 'city', {hide: true}));
            colDefs.push(gridService2.colDef.createText('postalCode', 'Postal Code', 'postalCode', {hide: true}));
            colDefs.push(gridService2.colDef.createText('tankNumber', 'Tank Number', 'containerId', { cellClass: ['center'], hide: true }));

            colDefs.push(gridService2.colDef.createDate('deliveryDate', 'Forecast Date', 'deliveryDate', { sort: 'asc' }));
            colDefs.push(gridService2.colDef.createNumber('estimatedDeliveryUnits', 'Estimated Delivery Units', 'estimatedDeliveryUnits', { aggFunc: 'sum' }, {decimalPlaces: 1 }));

            colDefs.push(gridService2.colDef.createNumber('tankSize', 'Tank Size', 'capacity')); 
            colDefs.push(gridService2.colDef.createNumber('tankUsable', 'Tank Usable', 'usableVolume')); 
            colDefs.push(gridService2.colDef.createNumber('containerUnitsAvailable', 'In-Tank Remaining', 'containerUnitsAvailable', {}, {decimalPlaces: 1}));
            colDefs.push(gridService2.colDef.createNumber('kFactor', 'K-Factor', 'kFactor', {}, {decimalPlaces: 2}));
            colDefs.push(gridService2.colDef.createNumber('baseLoad', 'Base Load', 'baseLoadUnits', {}, {decimalPlaces: 2}));
            colDefs.push(gridService2.colDef.createBoolean('tankMonitorFlag', 'Tank Monitor', 'tankMonitorFlag'));
            colDefs.push(gridService2.colDef.createBoolean('tmsTankMonitorFlag', 'Gremlin', 'tmsTankMonitorFlag'));

            colDefs.push(gridService2.colDef.createText('sourceDeliveryZoneID', 'Delivery Zone', 'sourceDeliveryZoneID', { hide: false }));
            colDefs.push(gridService2.colDef.createText('sourceDeliveryZoneName', 'Delivery Zone Name', 'sourceDeliveryZoneName', { hide: false }));
            colDefs.push(gridService2.colDef.createText('sourceDeliveryZoneDescription', 'Delivery Zone Description', 'sourceDeliveryZoneDescription', { hide: false }));

            colDefs.push(gridService2.colDef.createBoolean('deliveryDateMovedEarlyFlag', 'Optimized', 'deliveryDateMovedEarlyFlag', {}, { hide: true }));
            colDefs.push(gridService2.colDef.createNumber('optimizedDays', 'Days Optimized', 'optimizedDays', { hide: true }));
            
            return colDefs;
        }

        function periodIndicatorChanged() {
            $scope.dates = dateCalculator.calculatePeriod(null, null, $scope.filter.periodIndicator);
        }

        function customDateRangeClicked() {
            if ($scope.filter.periodIndicator === 'customDateRange') {
                datePickerService
                    .openDatePickerModal($scope.dates.startDate, $scope.dates.endDate, null)
                    .then(function (resultDates) {
                        if (!resultDates) {
                            return;
                        }

                        $scope.dates.startDate = resultDates[0];
                        $scope.dates.endDate = resultDates[1];

                        onDateChange();
                    });
            }
        }

        function onDateChange() {
            $scope.filter.periodIndicator = getPeriodIndicatorFromDates($scope.dates.startDate, $scope.dates.endDate);
        }

        function getPeriodIndicatorFromDates(startDate, endDate) {

            var periodIndicator = _.find($scope.periodIndicators, function (periodIndicator) {
                return moment(periodIndicator.dates.startDate).startOf('day').isSame(moment(startDate).startOf('day')) &&
                    moment(periodIndicator.dates.endDate).endOf('day').isSame(moment(endDate).endOf('day'))
            });

            return periodIndicator ? periodIndicator.key : 'customDateRange';            
        }

        function runReport() {
            /* Make sure the dates are correct for the Selected Period Indicator. Mainly useful when selecting a saved filter */
            if ($scope.filter.periodIndicator !== 'customDateRange')
            {
                $scope.dates = dateCalculator.calculatePeriod(null, null, $scope.filter.periodIndicator);
            }

            return queryDeliveryForecasting();
        }

        function queryDeliveryForecasting() {

            var divisionIds = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.filter.hierarchyNodeId)
            
            var params = {
                startDate: $scope.dates.startDate ? $scope.dates.startDate.format() : null,
                endDate: $scope.dates.endDate ? $scope.dates.endDate.format() : null,
                divisionId: _.isArray(divisionIds) ? divisionIds : [divisionIds],
                productIds: _.isArray($scope.filter.productIds) ? $scope.filter.productIds : [$scope.filter.productIds],
                tradeClassIds: _.isArray($scope.filter.tradeClassIds) ? $scope.filter.tradeClassIds : [$scope.filter.tradeClassIds],
                deliveryCategoryIds: _.isArray($scope.filter.deliveryCategoryIds) ? $scope.filter.deliveryCategoryIds : [$scope.filter.deliveryCategoryIds]
            }

            var rowPromise = $http({
                url: ('api/subscribers/{0}/deliveryForecasting/details').format($scope.subscriberId),
                method: 'POST',
                data: params
            }).then(function (result) {
                return result.data;
            });

            var promise = $scope.grid.setRows(rowPromise);

            return promise;
        }
    }
]);