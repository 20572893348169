angular.module( 'angus.controllers' ).controller( 'profileCtrl', [
	'$rootScope', '$scope', 'modalService',
	function( $rootScope, $scope, modalService ) {

		$scope.editProfileUrl = 'templates/profile/html/editProfileModalContainer.html';

		$scope.closeEditProfile = function() {
			if ( $scope.modalInstance ) $scope.modalInstance.close();
		};

		$scope.openEditProfile = function() {
			var scope = $scope.$new();
			scope.userProfile = $rootScope.user;
			scope.closeProfileModalCb = $scope.closeEditProfile;

			$scope.modalInstance = modalService.open( {
				templateUrl: $scope.editProfileUrl,
				scope: scope
			}, modalService.types.topBar );
		};
	}
] );