angular.module('angus.services').factory("generateCsvService", ['_',
	function(_) {
		return {
			generateCsv: function(twoDeeArray) {
				var commaSeperated = "";
				_(twoDeeArray).forEach(function(firstDee) {
					_(firstDee).forEach(function(secondDee) {
						commaSeperated += secondDee ? secondDee.toString() + ',' : ',';
					});
					commaSeperated += '\n';
				});
				return commaSeperated;
			},

			getNamesFromList: function(list, lookupFn) {
				var str = "";
				_(list).forEach(function(item) {
					str += lookupFn(item) + ", ";
				});
				return str.substring(0, str.length - 2);
			}
		};
	}
]);