angular.module('angus.directives').directive('adeptInquiry', [
    'actionViewTemplates', 
    function(actionViewTemplates) {
        return {
            scope: {
                model: "=",
                settings: "=",
                windowId: "="
            },
            restrict: 'A',
            templateUrl: actionViewTemplates.adeptInquiry
        }
    }
])