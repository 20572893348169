angular
    .module('angus.directives')
    .directive('dealerSelect', [ function(){
    	'use strict';

        return {
            restrict: 'A',
            scope: {
                ngModel: '='                
            },
            controller : ['$scope', 'fluentRest', function($scope, fluentRest){   
                fluentRest
                    .api()
                    .subscribers()
                    .dealers()
                    .get()
                    .then(function(dealers){
                        $scope.dealers = dealers;                        
                    });         	            	
            }],            
            template : '<select class="form-control" ng-options="dealer.id as dealer.name for dealer in dealers" ng-model="ngModel" ></select>',            
        };
    }]);
