angular.module('angus.controllers').controller('weatherSettingsCtrl', [
    '$scope', '$http', 'modalService', 'dateToString', '$q', 'actionViewTemplates', '_', 'constantsService',
    function($scope, $http, modalService, dateToString, $q, actionViewTemplates, _, constantsService) {
        'use strict';

        $scope.days = 14;
        $scope.units = 0; //hardcoded to USA. TODO: get the country from the subsciber
        $scope.tempBase = 65;
        $scope.forecast = {
            current: null,
            daily: null
        };
        $scope.allMax = [];
        $scope.allMin = [];
        $scope.tempMaximum = [];
        $scope.tempMinimum = [];
        $scope.Datetime = [];
        $scope.WeatherSummary = [];
        $scope.Object = [];
        $scope.weatherIcon = 'weather-clear';
        $scope.geocoding = {};
        var geocoder = new google.maps.Geocoder();

        $scope.filterDailyForecast = function(skip, take) {
            return _.take(_.slice($scope.forecast.days, skip), take);
        };

        function getGeocoding(address, suggestionKey, bypassCache){
            if(!!$scope.widget.instance.settings.geocoding && !bypassCache) return $q.when([$scope.widget.instance.settings.geocoding, true]);
            return new Promise( function (resolve, rej){
                    geocoder.geocode({'address': address},
                    function (results, status) {
                        resolve( [{longitude: results[0].geometry.location.lng(), latitude: results[0].geometry.location.lat()}]);
                    })
            })
        }

        $scope.getSearchLocation = function(address) {
                geocoder.geocode({'address': address},
                    function (results, status) {
                        $scope.addresses = _.map(results, function(suggestion) {
                            var zipcode = suggestion.address_components[0].long_name;
                           for(var i = 0 ; i < suggestion.address_components.length; i++){
                               if(suggestion.address_components[i].types.includes("postal_code")){
                                   zipcode = suggestion.address_components[i].long_name;
                                   break;
                               }
                           }
                            return {
                                id: suggestion.place_id,
                                text: suggestion.formatted_address,
                               zipcode: zipcode
                            };
                        });
                        $scope.$apply()
                    });
        };

        $scope.toFahrenheit = function(value) {
            if (value) {
                return (value * 1.8) + 32;
            }
            else {
                return 0;
            }
        }

        $scope.getHDD = function(high, low) { 
            var hdd = (65 - (($scope.toFahrenheit(high) + $scope.toFahrenheit(low)) / 2)); 
            return hdd > 0 ? hdd : 0;  // when answer is negative, then 0     
        }

        $scope.splitCamelCase = function(str) {
            return str ? str.replace(/([A-Z])/g, ' $1').trim() : '';
        } 

        $scope.getForecast = function(address, suggestionKey) {

            var bypassCache = true;
            if(!address || !suggestionKey){
                address       = $scope.widget.instance.settings.addressResult.text;
                suggestionKey = $scope.widget.instance.settings.addressResult.id;
                bypassCache   = false;
            }
            var promise = getGeocoding(address, suggestionKey, bypassCache)
                .then(function(result) {
                    var geocoding = result[0];
                    var cached    = result[1];
                    if(!cached){
                        $scope.widget.instance.settings.geocoding = geocoding;
                    }                        
                    return geocoding;
                })
                .then(function(geocoding){
                    $scope.geocoding = geocoding;  
                    $scope.forecast = {};
                    $scope.forecast.current = {};
                    $scope.forecast.current.hdd = 0;
                    $scope.forecast.current.temperature = 0;
                    $scope.forecast.current.conditionCode = '';

                    try {
                        //get current temperature
                        var currentDateTime = getDateFormat(new Date()); 
                        getHourlyForecast($scope.geocoding.latitude, $scope.geocoding.longitude, currentDateTime)
                        .then(function(results) {    
                            if (results && results.data && results.data.forecastHourly) {
                                var hours = results.data.forecastHourly.hours;
                                var currentHour = _.find(hours, function(hour) {
                                    return moment(hour.forecastStart).format("YYYY-MM-DD HH") == moment(currentDateTime).format("YYYY-MM-DD HH");
                                }); 
                                $scope.forecast.current.temperature = currentHour.temperature;
                                $scope.forecast.current.conditionCode = currentHour.conditionCode;
                            } 
                        });
                    } catch (error) {
                        console.error("getHourlyForecast ~ error:", error);
                    }
 
                    try {
                        getWeatherForecast(geocoding.latitude, geocoding.longitude)
                            .then(function(results) {   
                                if (results && results.data && results.data.forecastDaily) {
                                    $scope.forecast = results.data.forecastDaily; 
                                    $scope.forecast.current = $scope.forecast.days[0];  
                                    $scope.forecast.current.hdd = $scope.getHDD($scope.forecast.current.temperatureMax, $scope.forecast.current.temperatureMin);
        
                                    $scope.lastUpdatedText = getLastUpdatedText($scope.forecast.metadata.readTime);
                                    for (let i = 0; i < $scope.forecast.days.length; i++) {
                                        $scope.allMin.push($scope.forecast.days[i].temperatureMin);
                                        $scope.allMax.push($scope.forecast.days[i].temperatureMax);
                                    } 
                                } 
                            }); 
                    } catch (error) {
                        console.error("getWeatherForecast ~ error:", error); 
                    }
                });
            return $scope.widget.promises.monitor(promise);
        };

        function getLastUpdatedText(readTime) {
            const timestamp = moment(readTime);
            const today = moment().utc();

            var date = (today.isSame(timestamp, 'day') ? 'today' : timestamp.format('MMM D'));
            var time = timestamp.format('LT');
            return `Updated ${date} at ${time}`;

        }

        function getDateFormat(datetime) {
            return moment(datetime).format("YYYY-MM-DDTHH:mm:ss") + 'Z'; 
        }

        function getHourlyForecast(lat, lng, datetime) { 
            var startDate = getDateFormat(datetime);   
            return getWeatherHourlyForecast(lat, lng, startDate)
            .then(function(results) {  
                if (results && results.data) {
                    $scope.forecast.hourly = results.data.forecastHourly;   
                }
                return results;
            });
        }

        function getWeatherForecast(lat, lng) {
            return $http({
                url: ('api/weather/forecast'),
                method: 'GET',
                params: { 
                    latitude: lat,
                    longitude: lng
                }
            });
        } 

        function getWeatherHourlyForecast(lat, lng, startDate) {
            return $http({
                url: ('api/weather/forecast/hourly'),
                method: 'GET',
                params: { 
                    latitude: lat,
                    longitude: lng,
                    startDate: startDate
                }
            });
        }  

        function settingsChanged() {
            var promise;
            if ($scope.widget.instance.settings.addressResult && $scope.widget.instance.settings.addressResult.zipcode) {
                $scope.address = $scope.widget.instance.settings.addressResult.text;
                $scope.zipcode =  $scope.widget.instance.settings.addressResult.zipcode;
                promise = $scope.getForecast($scope.address, $scope.widget.instance.settings.addressResult.id);
            } else
                promise = $q.when();
            return $scope.widget.promises.monitor(promise);
        }

        function init() {
            var promise;
            if ($scope.widget.instance.settings.addressResult) {
                $scope.address = $scope.widget.instance.settings.addressResult.text;
                $scope.zipcode = $scope.widget.instance.settings.addressResult.zipcode;
                promise = $scope.getForecast();
            } else promise = $q.when();
            return $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(init);

        $scope.$on('widgetSettingsChanged', function() {            
            settingsChanged();
        });

        $scope.openHDDGraphView = function(forecastdate, i) {
            $scope.forecast.selected = _.find($scope.forecast.days, function(day) {
                return moment(day.forecastStart).format("YYYY-MM-DD") == moment(forecastdate).format("YYYY-MM-DD");
            });
            $scope.forecast.selected.hdd = $scope.getHDD($scope.forecast.selected.temperatureMax, $scope.forecast.selected.temperatureMin);
 
            getHourlyForecast($scope.geocoding.latitude, $scope.geocoding.longitude, forecastdate)
            .then(function(results) {  

                var scope = $scope.$new();
                scope.propertyName = 'Weather Detail';

                $scope.idx = i;
                $scope.forecastdates = forecastdate;
                scope.forecastDate = forecastdate;
                scope.selectedDate = new Date(forecastdate).toDateString().slice(4);
                scope.selectedDay = new Date(forecastdate).toDateString().slice(0, 3);
 
                scope.getGraphValues = function() {

                    var deferred = $q.defer();

                    deferred.resolve({
                        data: [{
                            result: [
                                [0, 3]
                            ]
                        }, {
                            result: [
                                [1, 3]
                            ]
                        }]
                    });

                    return deferred.promise;
                };

                scope.closeGraphViewCb = function() {
                    if ($scope.modalInstance) {
                        $scope.modalInstance.close();
                    }
                };
 
                $scope.modalInstance = modalService.open({
                    templateUrl: actionViewTemplates.weatherHourlyDetailContainer,
                    scope: scope
                }, modalService.types.topBar, true);
            });

        };

    }
]);
