angular.module('angus.controllers').controller('acctGainsLossesBySrcSettingsCtrl', [
    '$scope', 'hierarchyService', 'constantsService',
    function($scope, hierarchyService, constantsService) {

        'use strict';

        $scope.periodIndicators = $scope.widget.code == 'acctLossByRsn' ? constantsService.terminationIndicators.enums : constantsService.acquisitionIndicators.enums;

        $scope.openDivisionHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };
    }
]);
