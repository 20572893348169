angular.module('angus.directives')
    .directive('lightIndicator', [
        'indicatorService',
        function(indicatorService) {
            return {
                restrict: 'A',
                scope: {
                    comparisonValue: '=lightIndicator',
                    comparisonAvg: '=',
                    isLower: '='
                },
                link: function(scope, elem) {
                    scope.$watch(function() {
                        return scope.comparisonAvg;
                    }, function(newVal, oldVal) {
                        if (newVal != oldVal || oldVal != null) {
                            switch (indicatorService.getIndicatorId(newVal, scope.comparisonValue, scope.isLower)) {
                            case 1:
                                updateClasses(elem, true, false, false);
                                break;
                            case 2:
                                updateClasses(elem, false, true, false);
                                break;
                            case 3:
                                updateClasses(elem, false, false, true);
                                break;
                            }
                        }
                    }, true);

                    function updateClasses(element, green, yellow, red) {
                        if (green)
                            element.addClass('text-success');
                        else
                            element.removeClass('text-success');

                        if (yellow)
                            element.addClass('text-warning');
                        else
                            element.removeClass('text-warning');

                        if (red)
                            element.addClass('text-danger');
                        else
                            element.removeClass('text-danger');
                    }

                }
            };
        }
    ]);