 
  angular.module('angus.controllers').component('riderOrder', {
    templateUrl: 'templates/tankMonitor/inventoryManagement/orders/orderView.html',  
    bindings: {
        model: '='
    },
    controller: function RiderOrderController(_, orderService, inventoryManagementService) {
        var vm = this; 
  
        this.areCarriersLoading = false; 
        this.isLoading = false;
        this.simCardCarriers = [];
        this.riderOrderModel = {};  
        this.defaultRiderOrderModel = {};  
        this.rowStatus = {
            active: 1,
            delete: 3
        }  

        this.init = function() {   
            vm.isLoading = true;
            Promise.all([
                this.getSimCardCarriers()
            ])
            .then(function(results) { 
                newOrderModel(vm.model.riderOrders);
                vm.model.riderOrders.riderOrderLine.forEach(riderLine => {
                    newOrderLineModel(riderLine);
                });   
                vm.defaultRiderOrderModel = _.cloneDeep(vm.riderOrderModel); 
                vm.isLoading = false; 
            }); 
        }; 

        this.calculateQuantity = function(formOrder, riderLineKey, riderOrderLineKey, riderOrderLineQuantity, index) {   
            if (!riderOrderLineQuantity && riderOrderLineQuantity != 0) return;
            
            var selectedRiderLine = _.find(vm.model.rider.riderLine, function (riderLine) {
                return riderLine.riderLineKey == riderLineKey;
            });   
            var calulatedTotal = vm.model.calculateTotalQuantity(riderLineKey, riderOrderLineKey, riderOrderLineQuantity);
            var valid = (selectedRiderLine.riderUnitPhysicalQuantity - calulatedTotal) >= 0; 
            if (!valid) { 
                formOrder.$setPristine();
                vm.model.alerts.push({type: 'danger', msg: 'Your order value exceeded available quantity!'}); 
                vm.riderOrderModel.riderOrderLine[index].hasError = true;
                return;
            } 
            else {
                vm.riderOrderModel.riderOrderLine[index].hasError = false;
            }

            //check all rows for errors
            var hasError = _.filter(vm.riderOrderModel.riderOrderLine, function(ol) {
                return ol.hasError == true;
            }) 
            if (hasError && hasError.length) { 
                formOrder.$setPristine();
                vm.model.alerts.push({type: 'danger', msg: 'Your order value exceeded available quantity!'}); 
            } 
        }

        this.getSimCardCarriers = function() {   
            vm.areCarriersLoading = true; 
            inventoryManagementService.getSIMCardCarriers()
            .then(function(result) { 
                vm.simCardCarriers = result.data;  
                vm.areCarriersLoading = false; 
            }); 
        }  

        this.clickDeleteOrder = function(form) {  
            if (vm.riderOrderModel.riderOrderKey) {
                if(confirm("Are you sure to delete the order?")) {
                    vm.submitHandler(form, vm.rowStatus.delete); 
                }
            }
            else {
                vm.riderOrderModel = null;
                vm.model.deleteVM(vm.model.index);
                vm.model.alerts.push({ type: 'success', msg: 'Successfully Deleted' }); 
            }  
        }

        this.cancelHandler = function() {
            vm.riderOrderModel = _.cloneDeep(this.defaultRiderOrderModel);
        }

        this.submitHandler = function(form, rowStatusKey) { 
            vm.orderSubmitLoading = true;   
            vm.riderOrderModel.updateUser = vm.model.username;  
            vm.riderOrderModel.rowStatusKey = rowStatusKey;   

            try {
                orderService.postOrder(vm.riderOrderModel)
                .then(function(result) {
                    var riderOrderKey = result[0].RiderOrderKey;
                    if (riderOrderKey > 0) {  
                        if (rowStatusKey == vm.rowStatus.active) {  
                            vm.model.alerts.push({ type: 'success', msg: 'Successfully Saved' });   
                        }
                        else { 
                            vm.model.alerts.push({ type: 'success', msg: 'Successfully Deleted' }); 
                            vm.model.deleteVM(vm.model.index);
                        }
                        vm.model.getRider(vm.model.rider.riderKey);
                        if (form) {
                            form.$setPristine();  
                        }  
                        vm.riderOrderModel.riderOrderKey = riderOrderKey;
                        vm.getRiderOrderLines(riderOrderKey);
                    }
                    else { 
                        vm.model.alerts.push({type: 'danger', msg: 'Failed to Submit, Please check your data!'});  
                    }
                }); 
            } catch (error) { 
                vm.model.alerts.push({type: 'danger', msg: 'Failed to Submit, Please check your data!'}); 
            } 
            vm.orderSubmitLoading = false; 
        }

        this.getRiderOrderLines = function(riderOrderKey) {
            orderService.getOrder(null, riderOrderKey, vm.model.includeDeleted)
            .then(function(result) {
                vm.riderOrderModel.riderOrderLine = vm.mapOrderLines(vm.riderOrderModel.riderOrderLine, result.riderOrderLine); 
            });
        }

        this.mapOrderLines = function(riderLine, riderLines) {
            var newOrderLines = _.map(riderLine, function(rol,index){
                rol.riderOrderLineKey = riderLines[index].riderOrderLineKey;
                rol.riderOrderLineQuantity = riderLines[index].riderOrderLineQuantity || 0; 
                rol.shipmentQuantity =  riderLines[index].shipmentQuantity;
                rol.riderOrderLineNotes =  riderLines[index].riderOrderLineNotes;
                return rol;
            }); 

            return newOrderLines;
        }

        this.init();

        /* Private *******************************************************************************/
  
        var newOrderModel = function(model) { 
            vm.riderOrderModel = {  
                riderKey                : model.riderKey ? model.riderKey : 0,
                riderOrderKey           : model.riderOrderKey ? model.riderOrderKey : null,
                riderOrderDate          : model.riderOrderDate ? model.riderOrderDate : new Date(),
                riderOrderShippingNotes : model.riderOrderShippingNotes ? model.riderOrderShippingNotes : '',
                updateUser              : model.updateUser ? model.updateUser : '',
                rowStatusKey            : model.rowStatusKey ? model.rowStatusKey : 1,
                riderOrderLine          : []
            } 
        } 

        var newOrderLineModel = function(riderLine) {  
            var orderLine = {
                riderLineKey                    : riderLine.riderLineKey ? riderLine.riderLineKey : null,
                riderOrderLineKey               : riderLine.riderOrderLineKey ? riderLine.riderOrderLineKey : null,
                riderOrderItem                  : riderLine.riderOrderItem ? riderLine.riderOrderItem : null,
                riderOrderCarrier               : riderLine.riderOrderCarrier ? riderLine.riderOrderCarrier : null, 
                riderOrderLineQuantity          : riderLine.riderOrderLineQuantity ? riderLine.riderOrderLineQuantity : 0,
                shipmentQuantity                : riderLine.shipmentQuantity ? riderLine.shipmentQuantity : null,
                riderOrderLineNotes             : riderLine.riderOrderLineNotes ? riderLine.riderOrderLineNotes : '', 
                rowStatusKey                    : riderLine.rowStatusKey ? riderLine.rowStatusKey : 1
            }  
            vm.riderOrderModel.riderOrderLine.push(orderLine); 
        }
    }
});