angular
    .module('angus.directives')
    .directive('monitorStatusSelect', [ function(){
    	'use strict';

        return {
            restrict: 'A',
            scope: {
                ngModel: '='                
            },
            controller : ['$scope', function($scope){   
                $scope.statuses = [{
                	id : 1, 
                	name : 'Good'
                },{
                	id : 2, 
                	name : 'Eh'
                },{
                	id : 3, 
                	name : 'Bad'
                }];                        
                             	            	
            }],            
            template: '<div multi-select-ok-wrapped="statuses" ng-model="ngModel" display-prop="\'name\'" selection-prop="\'id\'"></div>',           
        };
    }]);
