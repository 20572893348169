angular.module('angus.services')
    .factory('brandingService', ['$location','moment',
        function($location, moment ) {

            var isBranded = function(brand){
                var location = $location.host().toLowerCase();

                var isBranded = location.indexOf(brand.toLowerCase()) > -1;

                return isBranded;
            };

            var isPaygo = function(){
                return isBranded('paygo') || isBranded('8.39.160.53');
            };

            var isGremlin = function() {
                return isBranded('gremlinmonitors') || isBranded('104.20.27.213') || isBranded('104.20.28.213');
            };

            var isBrite = function(){
                return !isPaygo() && !isGremlin(); //isBranded('brite') || isBranded('localhost') || isBranded('ive.petroheat.com');
            };

            return {
                isBrite: isBrite,
                isPaygo: isPaygo,
                isGremlin: isGremlin,

                companyName : function(){
                    return isBrite()
                        ? 'Angus Performance Advisors, LLC'
                        : isPaygo()
                            ? 'Paygo, LLC'
                            : 'Angus Monitoring Service, LLC';
                    // return isBrite() ? 'Angus Performance Advisors, LLC' : isPaygo() ? 'Paygo, LLC' : 'Angus Energy, LLC';
                },

                logoName : function(){
                    return isBrite()
                        ? 'BRITELogo.png'
                        : isPaygo()
                            ? 'PaygoLogo.png'
                            : 'GREMLINLogo.png';
                    // return isBrite() ? 'BRITELogo.png' : isPaygo() ? 'PaygoLogo.png' : '';
                },

                copyright : function(){
                    return '2013-' + moment().format('YYYY');
                }
            };
        }
    ]);
