angular.module('angus.controllers').controller('windowController', [
    '$scope', 'actionViewTemplates', 'brandingService', 'modalService', 'windowsService',
    function ($scope, actionViewTemplates, brandingService, modalService, windowsService) {
        const window = $scope.window;

        $scope.handleMinimize = function () {
            windowsService.minimizeWindow(window.id);
        }

        $scope.handleClose = function () {
            windowsService.closeWindow(window.id);
        }
    }
]);