angular.module('angus.services').service('divisionService', [
    '$http',
    function($http) {

        function getDivisions(subscriberId, includeDeleted, includeUnknown) {
            const config = {
                params: {
                    includeDeleted: includeDeleted ? 1 : 0,
                    includeUnknown: includeUnknown ? 1 : 0
                }
            };

            const url = ('api/subscribers/{0}/divisions/').format(subscriberId)

            return $http.get(url, config)
                .then(function(res) {
                    return res.data;
                });
        }

        return { 
            getDivisions: getDivisions
        }
    }
]);