angular.module('angus.services').factory('reportService', [
    '$http', function ($http) {
  
        const postActiveReport = function(data) { 
            return $http.post(('api/tankMonitors/reports/activeTankMonitors'), data) 
            .then(function (res) {
                return res.data;
            });
        };  
            
        return {
            postActiveReport
        };
     }
 ]);
 
 