angular.module('angus.controllers').controller('paygoExceptionsSubRegSettingsCtrl', [
    '$scope', '$q', 'fluentRest', 'promiseMonitor',
    function($scope, $q, fluentRest, promiseMonitor) {

        $scope.promiseMonitor = new promiseMonitor();

        function getDealers(){}

        function getExceptionTypes(){}

        var promise = $q.all([
        	getDealers(),
        	getExceptionTypes()
        ]);

        $scope.promiseMonitor.monitor(promise);
    }
]);