angular.module('angus.directives').directive('addPopoverContent', [
	'$compile', '$templateCache', '$document', '$', '$timeout', '$window', 'modalService',
	function($compile, $templateCache, $document, $, $timeout, $window, modalService) {
		'use strict';

		return {
			scope: {
				queryObj         : '=addPopoverContent',
				getDataFn        : '&popoverContentFn',
				dataTemplate     : '=popoverTemplate',
				resizeOffset     : '=',
				callBackFn       : '&callbackFn',
				isDeliveryWidget : '='
			},
			link: function(scope, element) {
				element.bind('click', function() {
					scope.getDataFn({
						item: scope.queryObj
					})
						.then(function(result, errors) {
							if(errors){
								modalService
								.openTopBarModal(scope.noRecordFoundTemplate);
							} else {
							modalService
								.openTopBarModal(scope.dataTemplate, {item: result.data, queryObj: scope.queryObj })
								.then(function(obj) {
									if (scope.callBackFn && obj) {
										$timeout(function() { // evalAsync gave errors.. this is working.
											scope.callBackFn({ value: obj });
										});
									}
								});
							}
						});
				});
			}
		};
	}
]);