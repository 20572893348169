angular.module('angus.controllers').controller('tankProfAnalysisContainerCtrl', [
    '$scope', '$rootScope', '$q', '_', 'drillService', 'promiseMonitor', 'fluentRest', 'gridService2', 'gridState', 'nodeType', 'constantsService', 'deliveryTypeService',
    function($scope, $rootScope, $q, _,  drillService, PromiseMonitor, fluentRest, gridService2, gridState, nodeType, constantsService,deliveryTypeService) {
        'use strict';

        deliveryTypeService.getDeliveryTypes()
          .then(function(deliveryTypes) {
              $scope.deliveryTypes = deliveryTypes.map(function(item){return item.id});
          });

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;
        $scope.ieoOptions = constantsService.includeExcludeOnly.enums;

        function initFilter(){
            return {
                    deliveryTypeIds      : $scope.deliveryTypes,
                    productIds           : $scope.settings.productIds.value,
                    hierarchyNodeId      : $scope.settings.hierarchyNodeId,
                    useBRITECalculations : $scope.settings.useBrite.value,
                    customerOwned        : $scope.settings.customerOwned.value,
                    inactiveTanks        : $scope.settings.inactiveTanks.value,
                    monitored            : $scope.settings.monitored.value
                };
        }

        $scope.filterChanged = function(filter){
            $scope.filter = filter || initFilter();
            $scope.getCompanyProfileTanks();
        };

         $scope.filter = {
            deliveryTypeIds      : $scope.deliveryTypes,
            productIds           : $scope.settings.productIds.value,
            hierarchyNodeId      : $scope.settings.hierarchyNodeId,
            useBRITECalculations : $scope.settings.useBrite.value,
            customerOwned        : $scope.settings.customerOwned.value,
            inactiveTanks        : $scope.settings.inactiveTanks.value,
            monitored            : $scope.settings.monitored.value
          };

        $scope.getCompanyProfileTanks = function(){
            $scope.grid.setColDefs(getColDefs());

            var rowPromise = fluentRest
                .api()
                .subscribers(subscriberId)
                .tanks()
                .counts()
                    .post($scope.filter)
                    .then(function(rows){
                        // filter 'Include Inactive Tanks' when 'Only' is selected
                        if($scope.filter.inactiveTanks === 'O') {
                          rows.filter(function(row) {
                              // displays the rows which contain only inactive tanks
                              return row.inactiveTanks = 0;
                          })
                        }

                        $scope.currentNodeId = $scope.filter.hierarchyNodeId;
                        return rows;
                    });

            var promise = $scope.grid.setRows(rowPromise);

            $scope.monitor = new PromiseMonitor(promise);

            return promise;
        };

        function getColClicks(){
            return{
                bosCustomerId : {
                    onClick : function(row){
                        drillService.openAccountInfo(row, drillService.context.delivery);
                    }
                },
                bosTankId     : {
                    onClick : function(row){
                        drillService.openTankInfo(row, drillService.context.delivery);
                    }
                }
            };
        }

        function getColDefs(){
            var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];

            colDefs.push(gridService2.colDef.createText('deliveryTypeName', 'Delivery Type', 'deliveryTypeName', { rowGroupIndex : nextIndex, hide : true }));
            colDefs.push(gridService2.colDef.createText('customerName', 'Customer Name', 'customerName'));
            colDefs.push(gridService2.colDef.createText('bosCustomerId', 'Account Number', 'bosCustomerId', { cellClass : ['center']}));
            colDefs.push(gridService2.colDef.createText('bosTankId', 'Tank Number', 'bosTankId', { cellClass : ['center']}));
            colDefs.push(gridService2.colDef.createText('tankSize','Tank Size','tankSize', { cellClass : ['center']}));
            colDefs.push(gridService2.colDef.createText('productName','Product','productName'));
            colDefs.push(gridService2.colDef.createDate('lastDeliveryDate', 'Last Delivery Date', 'lastDeliveryDate'));
            colDefs.push(gridService2.colDef.createNumber('gallons', 'Gallons (Rolling 12 months)', 'unitsYearlyRunningTotal', 0));
            colDefs.push(gridService2.colDef.createBoolean('monitored', 'Tank Monitor', 'monitored',{}, {hide : true}));
            colDefs.push(gridService2.colDef.createDate('acquisitionDate', 'Acquisition Date', 'acquisitionDate', {hide : true}));

            if($scope.filter.customerOwned) {
                colDefs.push(gridService2.colDef.createBoolean('customerOwned', 'Customer Owned', 'customerOwned'));
            }

            if($scope.filter.inactiveTanks) {
                colDefs.push(gridService2.colDef.createBoolean('inactiveTanks', 'Active', 'inactiveTanks'));
            }

            if($scope.filter.monitored) {
                colDefs.push(gridService2.colDef.createBoolean('monitored', 'Monitored Tanks', 'monitored'));
            }

            return colDefs;
        }

        var nodeColDefs;
        nodeType
            .getDivisionColDefs(subscriberId)
            .then(function(colDefs){
                nodeColDefs = colDefs;
                var gridParams = {
                    gridState     : gridState(subscriberId, $scope.widgetCode),
                    defs          : getColDefs(),
                    clicks        : getColClicks(),
                    exportOptions : { fileName: 'Company Profile - Tanks' }
                };
                return gridService2.createGrid(gridParams);
            })
            .then(function(grid){
                $scope.grid = grid;
                $scope.filter = initFilter();
                $scope.getCompanyProfileTanks();
            });
    }
]);
