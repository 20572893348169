angular.module( 'angus.directives' ).directive( 'pricePlanProfAnalysis', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		'use strict';
		
		return {
			scope: {
				model: '=actionModel',
				settings: '=pricePlanProfAnalysisWidgetSettings',
				widgetCode : '='
			},
			templateUrl: actionViewTemplates.pricePlanProfAnalysisContainer
		};
	}
] );
