angular.module('angus.controllers').controller('arSummaryCtrl', [
    '$scope', '$rootScope','_', '$http', '$q', 'actionViews', 'constantsService',
    function($scope, $rootScope, _, $http, $q, actionViews, constantsService) {
    	'use strict';
    	
		const includeExcludeOnlyOptions = constantsService.includeExcludeOnlyOptions;

		function getArSummary() {
			return $http({
				url: ('api/subscribers/{0}/accountsReceivable/summary').format($rootScope.user.subscriberId),
				method: 'POST',
				data: {
					divisionId: $scope.widget.instance.settings.hierarchyNodeId,
					includeBudgetCustomers: includeExcludeOnlyOptions[$scope.widget.instance.settings.budgetCustomers] || includeExcludeOnlyOptions.include,
					tradeClassIds: $scope.widget.instance.settings.tradeClassIds,
				}
			});
		}

		function getArAgingPeriods(){
			return $http.post(('api/subscribers/{0}/accountsReceivable/agingPeriods').format($rootScope.user.subscriberId));
		}

		function init() {
			$scope.widget.noContentFlag = false;

			if (!$scope.widget.instance.settings.ageAgainst) $scope.widget.instance.settings.ageAgainst = 'dueDate'

   			var promises = $q.all([getArAgingPeriods(), getArSummary()])
   				.spread(function(agingPeriods, summary){
   					$scope.agingPeriods = _.sortBy(agingPeriods.data, function(agingPeriod){ return agingPeriod.sequence});

   					if (!summary) {
						 $scope.widget.noContentFlag = true;
					}
   					else {
						$scope.summary = summary.data;
					}
   				});

			return $scope.widget.promises.monitor(promises);
		}

		$scope.getAgingPeriodLabel = function(id){
			return _.find($scope.agingPeriods, {'id': id}).label;	
		}

		$scope.agingPeriodSelected = function(agingPeriodId){
			$scope.widget.openDetailView(actionViews.getActionView($scope.widget.code),{
				agingPeriodId : agingPeriodId
			});
		}

        $scope.widget.setDefaultInitialization(init);		

		$scope.$on('widgetSettingsChanged', function() {
			init();
        });		
    }
]);