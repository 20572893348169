angular.module('angus.services').factory('riderService', [
    '$http', function ($http) {
 
        const getRiderHeader = function(riderID, riderKey, subscriberKey, includeDeleted) {   
            let url =  'api/tankMonitors/rider/riderHeaders';   
            const hasDeletedValue = (includeDeleted == 1 || includeDeleted == 0);
            if (riderID || riderKey || subscriberKey || hasDeletedValue) {
                url += '?';
            }
            if (riderID) {
                url += 'riderID=' + riderID + '&';
            }
            if (riderKey) {
                url += 'riderKey=' + riderKey + '&';
            }
            if (subscriberKey) {
                url += 'subscriberKey=' + subscriberKey + '&';
            }
            const includeDeletedValue = includeDeleted ? 1 : 0; 
            url += 'includeDeleted=' + includeDeletedValue;  
            
            return $http.get((url))
               .then(function (res) {
               return res.data;
           });
        };  
 
        const getRider = function(riderID, riderKey, subscriberKey, includeDeleted) { 
            let url =  'api/tankMonitors/rider';             
            const hasDeletedValue = (includeDeleted == 1 || includeDeleted == 0);
            if (riderID || riderKey || subscriberKey || hasDeletedValue) {
                url += '?';
            }
            if (riderID) {
                url += 'riderID=' + riderID + '&';
            }
            if (riderKey) {
                url += 'riderKey=' + riderKey + '&';
            }
            if (subscriberKey) {
                url += 'subscriberKey=' + subscriberKey + '&';
            } 
            const includeDeletedValue = includeDeleted ? 1 : 0; 
            url += 'includeDeleted=' + includeDeletedValue; 

            return $http.get((url))
            .then(function (res) { 
                return res.data;
            });
        };   
         
         const postRider = function(data) {
            return $http.post(('api/tankMonitors/rider'), data, {headers: {'Content-Type': 'application/json'}})
                   .then(function (res) {
                       return res.data;
                   });
        };

         return { 
            getRiderHeader,
            getRider,
            postRider
         };
     }
 ]);
 
 