angular.module('angus.directives')
.directive('csvReader', ['$','FileReader', '_', '$q', function($, FileReader, _, $q) {
	'use strict';

  	return {    	
    	require: '?ngModel',
    	link: function(scope, element, attrs, ngModel) {   		
    		if (!ngModel) return;

    		function fileChange(e){
    			var csv;
    			var deferred = $q.defer();    			
    			var file = e.target.files[0];	        	
	        	
	        	if(!file || !file.name || !file.name.endsWith('.csv'))    					            		            		            
	            	deferred.reject('Not a CSV');
    			else 
    				deferred.resolve(readFile(file));    			

    			return deferred
    				.promise
    				.then(function(result){
    					csv = result;
    				})
    				.catch(function(){
    					csv = undefined;
    				})
    				.finally(function(){    				
                    	ngModel.$setViewValue(csv);
    				});
    		}

			function readFile(file){
				var deferred = $q.defer();
				var reader = new FileReader();

				reader.onload = function(e){
					var rows = e.target.result.replace(/\"/g, '').replace(/\r\n/g, '\n').replace(/\r/g, '\n').split('\n');	          		
          						
					var headers = _.remove(rows, function(row, index){
      					return index === 0;
      				})[0].split(',');

      				var content = _.reduce(rows, function(setResult, row){      
      					var emptyCount = 0;				      					
  						var data =  _.reduce(row.split(','), function(rowResult, value, index){
  							rowResult[headers[index]] = value.trim();
  							if(!value) emptyCount++;
							return rowResult;
						}, {});

						if(emptyCount < Object.keys(data).length)
							setResult.push(data);

  						return setResult;
					}, []);
					 
      				var data = headers && content ? { headers : headers, content : content } : undefined;

					deferred.resolve(data);
				};

				reader.onerror = function(e) {
                    deferred.reject(e);
                };

                reader.readAsText(file);

                return deferred.promise;      							
			}

    		ngModel.$render = function() {};

            element.bind('change', fileChange);  		
      	
    	}    	
  	};
}])
.directive('csvReaderNoHeader', ['$','FileReader', '_', '$q', function($, FileReader, _, $q) {
	'use strict';

  	return {    	
    	require: '?ngModel',
    	link: function(scope, element, attrs, ngModel) {   		
    		if (!ngModel) return;

    		function fileChange(e){
    			var csv;
    			var deferred = $q.defer();    			
    			var file = e.target.files[0];	        	
	        	
	        	if(!file || !file.name || !file.name.endsWith('.csv'))    					            		            		            
	            	deferred.reject('Not a CSV');
    			else 
    				deferred.resolve(readFile(file));    			

    			return deferred
    				.promise
    				.then(function(result){
    					csv = result;
    				})
    				.catch(function(){
    					csv = undefined;
    				})
    				.finally(function(){    				
                    	ngModel.$setViewValue(csv);
    				});
    		}

			function readFile(file){
				var deferred = $q.defer();
				var reader = new FileReader();

				reader.onload = function(e){
					var content = [];
					var rows = e.target.result.replace(/\"/g, '').replace(/\r\n/g, '\n').replace(/\r/g, '\n').split('\n'); 
					 _.forEach(rows, function(row, index){
						if (row !== '') content.push(row);
					}); 
					var data = rows ? { content : content } : undefined; 

				    deferred.resolve(data);
				};

				reader.onerror = function(e) {
                    deferred.reject(e);
                };

                reader.readAsText(file);

                return deferred.promise;      							
			}

    		ngModel.$render = function() {};

            element.bind('change', fileChange);  		
      	
    	}    	
  	};
}])