angular.module('angus.utils')
    .factory('dateRange', ['moment' , function(moment) {
        'use strict';

    	function stopEvent($event){
    		$event.preventDefault();
            $event.stopPropagation();
    	}

    	function dateRange(start, end, max){
    		this.startOpened = true;
    		this.endOpened 	 = false;
    		this.start 		 = start ? moment(start).utc().toDate() : moment().utc().toDate();
			this.end   		 = end ? moment(end).utc().toDate() : moment().utc().toDate();
			this.max 		 = moment(max).utc().toDate();

			this.dateOptions = {
        		formatYear: 'yy',
        		startingDay: 1
    		};
    	}

    	dateRange.prototype.openStart 	= function($event){
    		stopEvent($event);
		    this.endOpened = false;
		    this.startOpened = true;
    	};

    	dateRange.prototype.openEnd 	= function($event){
    		stopEvent($event);
		    this.startOpened = false;
		    this.endOpened = true;
    	};

    	dateRange.prototype.resetEnd	= function(){
		     var start = moment(this.start);
		    var end = moment(this.end);

            if(end.isBefore(start))
            	this.end = start.toDate();
        };


        return dateRange;
   }]);