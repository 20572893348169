angular.module('angus.services').service('sideMenuService', [
    '$', '$document', '$rootScope', '$timeout',
    function($, $document, $rootScope, $timeout) {
        const sideMenuStatuses = {
            closed: 0,
            open: 1
        }

        let sideMenuStatus = sideMenuStatuses.closed;
        let service = this;

        this.isSideMenuOpen = function() {
            return sideMenuStatus == sideMenuStatuses.open;
        }

        this.toggleSideMenu = function() {
            if (sideMenuStatus == sideMenuStatuses.closed) {
                service.open();
            }
            else {
                service.close();
            }
        }

        
        this.open = function() {
            if (!sideMenuStatus == sideMenuStatuses.open) {
                sideMenuStatus = sideMenuStatuses.open;
            }
        }

        this.close = function() {
            sideMenuStatus = sideMenuStatuses.closed;
        }
    }
]);