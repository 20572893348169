angular.module('angus.controllers').controller('acctGainsLossesBySrcContainerCtrl', [
	'$scope', 'actionViewTemplates', 
	function($scope, actionViewTemplates) {

		$scope.sideNavTemplate = actionViewTemplates.sideNav;
		$scope.items = [];

		function addNewOpenReport() {
			var openReport = {
				name        : $scope.model.isLosses ? 'Account Losses by Reason' : 'Account Gains by Source',
				icon        : 'user',
				templateUrl : actionViewTemplates.acctGainBySrcContent,
				active      : true,
				params      : $scope.settings,
				dates       : $scope.model.dates,
				isLosses    : $scope.model.isLosses,
				sorting     : {
						sortProp : 'accounts',
						sortDir  : 'desc'
					},
				table       : []
			};
			$scope.items.push(openReport);
			$scope.activeItem = openReport;
		}

		$scope.$watch(function() {
			return $scope.model.isLosses;
		}, function(newVal) {
			if (!$scope.activeItem || (newVal != $scope.activeItem.isLosses))
				addNewOpenReport();
		}, true);

		$scope.switchActiveItem = function(item) {
			if (item.active) {
				$scope.activeItem = item;
			}
		};
	}
]);