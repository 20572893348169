angular.module('angus.services').service('lastInvoiceDateService', ['$rootScope', '$http', '$q', '_', 'moment', 'hierarchyService',
    function ($rootScope, $http, $q, _, moment, hierarchyService) {

        'use strict';

        var lastInvoiceDates = {};

        function getKey(divisions, comparisonDate, nonFuel) {
            var divisionsKey = '';
            _.forEach(divisions, function (division) {
                divisionsKey += division + ';';
            });
            if (nonFuel) {
	            divisionsKey = divisionsKey  + "nonFuel";
            }
            return divisionsKey +
                (comparisonDate !== undefined ? '_' + moment(comparisonDate).format('L') : '');
        }

        function getLastInvoiceDate(divisions, comparisonDate, nonFuel) {

            var key = getKey(divisions, comparisonDate, nonFuel);

            if (lastInvoiceDates[key] !== undefined) {
                return lastInvoiceDates[key];
            } else
                return queryLastInvoiceDate(divisions, comparisonDate, key, nonFuel);
        }


        function queryLastInvoiceDate(divisions, comparisonDate, key, nonFuel) {

            if (!_.isArray(divisions))
                divisions = [divisions];

            if (!comparisonDate || !moment(comparisonDate).isValid())
                comparisonDate = moment().subtract(1, 'days');
            return $http({
                url: ('api/subscribers/{0}/margins/lastInvoiceDate').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    d: divisions,
                    c: comparisonDate.format(),
                    nonFuel: nonFuel
                }
            })
                .then(function (response) {

                    lastInvoiceDates[key] = response.data.date;
                    return response.data.date;
                });
        }

        var nodeIdSlashDivisionKeys = {};
        return {
            getLastInvoiceDate: function (divisions, comparisonDate, nonFuel) {

                return $q.when(getLastInvoiceDate(divisions, comparisonDate, nonFuel))
                    .then(function (lastInvoiceDate) {
                        return lastInvoiceDate ? moment.utc(lastInvoiceDate) : null;
                    });
            },
            getLastInvoiceDateByNodeId: function (nodeId, comparisonDate) {
                var divisions;
                if (nodeIdSlashDivisionKeys[nodeId])
                    divisions = nodeIdSlashDivisionKeys[nodeId];
                else {
                    nodeIdSlashDivisionKeys[nodeId] = divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, nodeId);
                }

                return $q.when(getLastInvoiceDate(divisions, comparisonDate))
                    .then(function (lastInvoiceDate) {
                        return lastInvoiceDate ? moment.utc(lastInvoiceDate) : null;
                    });
            }
        };
    }
]);
