angular.module('angus.directives')
    .directive("fadeToggle", [
        function() {
            return {
                scope: {
                    fadeToggle: "="
                },
                link: function(scope, element, attrs) {
                    var initialized;

                    scope.$watch(function() {
                        return scope.fadeToggle;
                    }, function(newVal) {
                        if (initialized) {
                            if (newVal) {
                                element.stop(true, false).fadeOut('fast');
                                element.fadeIn('fast');
                            } else {
                                element.stop(true, false).fadeIn('fast');
                                element.fadeOut('fast');
                            }
                        } else {
                            if (newVal) {
                                element.show();
                                initialized = true;
                            } else {
                                element.hide();
                                initialized = true;
                            }
                        }
                    });
                }
            };
        }
    ]).directive("slideToggle", [
        function() {
            return {
     
                link: function(scope, element, attrs) {
                    var initialized;
                    var up   = scope.$eval(attrs.up);
                    var down = scope.$eval(attrs.down);

                    scope.$watch(attrs.slideToggle, function(newVal) {
                        if (initialized) {
                            if (newVal) {
                                element.stop(true, false).slideUp(500);
                                element.slideDown(500, function(){
                                    if(down) down.call(scope);   
                                });
                                
                            } else {
                                element.stop(true, false).slideDown(500);
                                element.slideUp(500, function(){
                                    if(up) up.call(scope);
                                });
                                
                            }
                        } else {
                            if (newVal) {
                                element.show(0, function(){
                                    scope.visible = true;    
                                    initialized = true;
                                    if(down) down.call(scope);   
                                });
                                
                                
                            } else {
                                element.hide(0, function(){
                                    scope.visible = false;    
                                    initialized = true;
                                    if(up) up.call(scope);
                                });
                            }
                        }
                    });
                }
            };
        }
    ])
    .directive("hide", [
        function() {
            return {
                scope: {
                    hide: "=",
                    delay: "="
                },
                link: function(scope, element, attrs) {
                    var initialized;

                    scope.$watch(function() {
                        return scope.hide;
                    }, function(newVal) {
                        if (initialized) {
                            if (newVal) {
                                setTimeout(function() {
                                    element.hide();
                                }, scope.delay || 0);
                            } else {
                                element.show();
                            }
                        } else {
                            if (newVal) {
                                element.hide();
                                initialized = true;
                            } else {
                                element.show();
                                initialized = true;
                            }
                        }
                    });
                }
            };
        }
    ]).directive("show", [
        function() {
            return {
                scope: {
                    show: "=",
                    delayIn: "=",
                    delayOut: "="
                },
                link: function(scope, element, attrs) {
                    var initialized;

                    scope.$watch(function() {
                        return scope.show;
                    }, function(newVal) {
                        if (initialized) {
                            if (newVal) {
                                setTimeout(function() {
                                    element.show();
                                }, scope.delayIn || 0);
                            } else {
                                setTimeout(function() {
                                    element.hide();
                                }, scope.delayOut || 0);
                            }
                        } else {
                            if (newVal) {
                                element.show();
                                initialized = true;
                            } else {
                                element.hide();
                                initialized = true;
                            }
                        }
                    });
                }
            };
        }
    ]);