angular.module('angus.controllers').controller('paygoCustRecCtrl', [
    '$scope', '$rootScope', '$q', 'Paging', 'actionViews', 'fluentRest', '$timeout', 'constantsService', '_',
    function($scope, $rootScope, $q, Paging, actionViews, fluentRest, $timeout, constants, _) {
        'use strict';

        function Throttler(delay) {

            var timeout = null;
            var currentDeferred = null;

            this.throttle = function() {

                $timeout.cancel(timeout);

                timeout = $timeout(function() {
                    currentDeferred.resolve(true);
                    currentDeferred = null;
                }, delay);

                if (currentDeferred)
                    currentDeferred.resolve(false);

                currentDeferred = $q.defer();
                return currentDeferred.promise;

            }
        }

        var searchThrottler = new Throttler(500);

        function setDealers(dealers) {
            $scope.dealers = dealers;
        }

        function getDealers(subscriberId) {
            return fluentRest.api().subscribers(subscriberId).dealers().get();
        }

        $scope.paging = new Paging($scope.pageSize || 5);
        $scope.searchTerm = $scope.lastSearchTerm || '';
        $scope.sortBy = 'name';
        $scope.ascending = true;


        //change start


        function init() {
          $scope.isSysAdmin = $rootScope.user.role == 'sysadmin';

          var promise = getDealers($scope.isAdmin || ($scope.widget && $scope.widget.code == constants.widgets.paygoAdminCustRec.value.code) ? null : $rootScope.user.subscriberId)
              .then(setDealers)
              .then(function() {
                  $scope.selectedDealers = _.map($scope.dealers, 'id');
                  return query();
              });

          if ($scope.widget) $scope.widget.promises.monitor(promise);

          return promise;
        }

        $scope.widget.setDefaultInitialization(init);



        $scope.clearSearch = function() {
            $scope.searchTerm = '';
            query();
        };

        $scope.customerSelected = function(customer) {

            $scope.widget.openDetailView(actionViews.getActionView($scope.widget.code), {
                customer: customer
            });
        };


        function query(pageNumber) {
            return getCustomers($scope.searchTerm, pageNumber);
        }


        function getCustomers(searchTerm, pageNumber) {

            var params = {
                searchTerm: searchTerm,
                sortBy: $scope.sortBy,
                ascending: $scope.ascending,
                dealerIds: $scope.selectedDealers
            };

            var url = fluentRest
                .api()
                .subscribers()
                .dealers()
                .customerAccounts()
                .toString();

            return $scope.paging.postGetPage(url, pageNumber, params)
                .then(function(customers) {
                    $scope.customers = customers.items;
                });

        }

        $scope.query = query;

        $scope.$watch(function() {
            return $scope.searchTerm;
        }, function(newVal) {
            searchThrottler
                .throttle()
                .then(function(takeAction) {
                    if (takeAction)
                        getCustomers(newVal, 1);
                });
        });

        $scope.sort = function(sortBy) {
            if ($scope.sortBy == sortBy) $scope.ascending = !$scope.ascending;
            else {
                $scope.sortBy = sortBy;
                $scope.ascending = true;
            }
            query();
        };
    }
]).controller('paygoCustRecCustomersCtrl', [
    '$scope', '$rootScope', '$q', 'Paging', 'actionViews', 'fluentRest', '$timeout', 'constantsService', 'promiseMonitor', '_', 'csvService', 'modalService', 'actionViewTemplates',
    function($scope, $rootScope, $q, Paging, actionViews, fluentRest, $timeout, constants, PromiseMonitor, _, csvService, modalService, actionViewTemplates) {
        'use strict';

        function getTankStatuses() {
            return fluentRest
                .api()
                .containers()
                .statuses()
                .get()
                .then(function(statuses) {
                    $scope.customersItem.statuses = statuses;
                    $scope.customersItem.selectedStatuses = _.map($scope.customersItem.statuses, 'id');
                });
        }



        function getDealers(subscriberId) {
            return fluentRest
                .api()
                .subscribers(subscriberId)
                .dealers()
                .get()
                .then(function(dealers) {
                    $scope.customersItem.dealers = dealers;
                    $scope.customersItem.selectedDealers = _.map($scope.customersItem.dealers, 'id');
                });
        }




        function getParamsAndUrl() {
            var deferred = $q.defer();


            var params = {
                searchTerm: $scope.customersItem.searchTerm,
                sortBy: $scope.customersItem.sortBy,
                ascending: $scope.customersItem.ascending,
                dealerIds: $scope.customersItem.selectedDealers,
                statusIds: $scope.customersItem.selectedStatuses,
            };

            var url = fluentRest.api().subscribers().dealers().customerAccounts().containers().toString();

            deferred.resolve([url, params]);

            return deferred.promise;
        }

        $scope.export = function() {
            return getParamsAndUrl()
                .spread(function(url, params) {
                    return csvService.getCsv(url, params, 'Customers');
                });
        };


        $scope.importCustomers = function(){
          return modalService
            .openTopBarModal(actionViewTemplates.paygoCustRecImport, {});
        };


        $scope.getCustomersTanks = function(pageNumber) {

            var promise = getParamsAndUrl()
                .spread(function(url, params) {
                    return $scope
                        .customersItem
                        .paging
                        .getPage(url, pageNumber, params);
                })
                .then(function(tanks) {
                    $scope.customersItem.tanks = tanks.items;
                });

            $scope.pagingMonitor.monitor(promise);

            return promise;
        }

        $scope.clearSearch = function() {
            $scope.customersItem.searchTerm = '';
        }

        $scope.sort = function(sortBy) {
            if ($scope.customersItem.sortBy == sortBy) $scope.customersItem.ascending = !$scope.customersItem.ascending;
            else {
                $scope.customersItem.sortBy = sortBy;
                $scope.customersItem.ascending = true;
            }
            $scope.getCustomersTanks();
        };



      function init() {
        $scope.promiseMonitor = $scope.widget ? $scope.widget.promises.monitor : new PromiseMonitor();
        $scope.pagingMonitor = new PromiseMonitor();

        if (!$scope.customersItem.tanks) {
          $scope.customersItem.paging = new Paging($scope.pageSize || 25);
          $scope.customersItem.searchTerm = $scope.lastSearchTerm || '';
          $scope.customersItem.sortBy = null;
          $scope.customersItem.ascending = true;


          var promise = $q.all([
            getDealers($scope.isAdmin || ($scope.widget && $scope.widget.code == constants.widgets.paygoAdminCustRec.value.code) ? null : $rootScope.user.subscriberId),
            getTankStatuses()
          ])
            .then(function() {
              return;
            });

          $scope.promiseMonitor.monitor(promise);
        }

      }

      init();
    }
]);

angular.module('angus.controllers').controller('paygoCustRecContainerCtrl', [
    '$scope', '$rootScope', 'actionViewTemplates', 'fluentRest', 'modalService', '_',
    function($scope, $rootScope, actionViewTemplates, fluentRest, modalService, _) {
        'use strict';

        $scope.sideNavTemplate = actionViewTemplates.sideNav;

        $scope.customersItem = {
            name: 'Customer Tanks',
            icon: 'users',
            templateUrl: actionViewTemplates.paygoCustRecCustomers,
            active: true
        };


        function init() {
            $scope.lastSearchTerm = null;
            $scope.pageSize = 20;
            $scope.items = [$scope.customersItem];
            if ($scope.customer) openCustomer($scope.customer, $scope.containerId);
            else $scope.activeItem = $scope.customersItem;
        }
        init();

        $scope.switchActiveItem = function(item) {
            if (item.active) {
                $scope.activeItem = item;
            }
        };

        $scope.removeCustomer = function(item) {
            var index = _($scope.items).findIndex(item);
            _.remove($scope.items, item);
            if (index <= $scope.items.length - 1) $scope.activeItem = $scope.items[index];
            else $scope.activeItem = $scope.items[index - 1];
        };



        $scope.isCustomerOpen = function(customer) {
            return isCustomerAlreadyOpen(customer);
        };


        function isCustomerAlreadyOpen(customer) {
            return _($scope.items).some(function(item) {
                return item.customer ? item.customer.id == customer.id : false;
            });
        }

        function openCustomer(customer, containerId) {
            var item;



            if (isCustomerAlreadyOpen(customer)) {
                _($scope.items).forEach(function(i) {
                    if (i.customer && i.customer.id == customer.id) {
                        item = i;
                        item.containerId = containerId;
                    }
                });
            } else {
                item = {
                    icon: 'user',
                    templateUrl: actionViewTemplates.paygoCustRecCustomer,
                    active: true,
                    customer: customer,
                    containerId: containerId,
                    name: customer.name
                };
                $scope.items.push(item);
            }



            $scope.activeItem = item;
        }

        $scope.openCustomer = function(customer, tank, searchTerm) {
            $scope.lastSearchTerm = searchTerm;
            openCustomer(customer, tank.id);
        };

        $scope.getContainers = function(customer) {
            fluentRest
                .api()
                .subscribers(customer.subscriberId)
                .customerAccounts(customer.id)
                .containers()
                .get({
                    onlyPaygo: true
                })
                .then(function(containers) {
                    modalService
                        .openTopBarModal(actionViewTemplates.paygoCustRecViewCustomerTanks, {
                            containers: containers,
                            customer: customer
                        })
                        .then(function(containerId) {
                            if (containerId)
                                openCustomer(customer, containerId);
                        });
                });
        };

        $scope.$watch(function() {
            return $scope.actionModel;
        }, function(newVal) {
            if (newVal.isAdmin) $scope.isAdmin = true;

            if (newVal.customer) openCustomer(newVal.customer, newVal.containerId);
            else $scope.activeItem = $scope.customersItem;
        });


        function openReport(name, icon, code, templateUrl) {
            var scope = {
                widgetName: name,
                widgetIcon: icon,
                widgetCode: code,
                model: {
                    subscriberId: !$scope.isAdmin ? $rootScope.user.subscriberId : null
                }
            };

            modalService
                .openActionView(templateUrl, scope, code);
        }

        $scope.openBillingHistoryReport = function() {
            openReport('Consumer Billing History', 'clock-o', 'paygoBillingHistory', actionViewTemplates.paygoBillingHistory);
        };

        $scope.openDeliveryHistoryReport = function() {
            openReport('Consumer Delivery History', 'clock-o', 'paygoDeliveryHistory', actionViewTemplates.paygoDeliveryHistory);
        };

        $scope.openUsageHistoryReport = function() {
            openReport('Consumer Usage History', 'clock-o', 'paygoUsageHistory', actionViewTemplates.paygoUsageHistory);
        };

        $scope.openUsageAdjustmentReport = function() {
            openReport('Consumer Usage Adjustment', 'edit', 'paygoUsageAdjustment', actionViewTemplates.paygoUsageAdjustment);
        };

        $scope.openMonthlyAccountingReport = function() {
            openReport('Monthly Accounting', 'calendar', 'paygoMonthlyAccounting', actionViewTemplates.paygoMonthlyAccounting);
        };

        $scope.openPeriodAccountingReport = function() {
            openReport('Period Accounting', 'calendar', 'paygoPeriodAccounting', actionViewTemplates.paygoPeriodAccounting);
        };
        $scope.openDealerPriceEntry = function(){
            openReport('Dealer Price Entry', 'usd', 'paygoDealerPriceEntry', actionViewTemplates.paygoDealerPriceEntry);
        }

    }
]);
