angular.module('angus.controllers').controller('liquidFuelsHistoryReportContainerCtrl', [
    '$scope', '$rootScope', '$http', 'periodIndicators', 'productsService', 'actionViewTemplates', 'tradeClassService', 'constantsService', '_', 'Paging',
    function($scope, $rootScope, $http, periodIndicators, productsService, actionViewTemplates, tradeClassService, constantsService, _, paging) {

        'use strict';

        $scope.sideNavTemplate   = actionViewTemplates.sideNav;
        $scope.productCategories = productsService.getProducts();
        $scope.items             = [];
        $scope.pageSize          = 50;

        $scope.$watch(function() {
            return $scope.model;
        }, function(newVal) {
            if (newVal) {
                addNewOpenReport(newVal);
            }
        }, true);

        $scope.switchActiveItem = function(item) {
            if (item.active) {
                $scope.activeItem = item;
            }
        };

        function addNewOpenReport(model) {
            var openReport = _.find($scope.items, function(item) {
                return item.params == model;
            });
            if (!openReport) {
                openReport = {
                    name        : 'Acct: ' + model.accountNumber,
                    icon        : 'table',
                    templateUrl : actionViewTemplates.liquidFuelsHistoryReportContent,
                    active      : true,
                    params      : model,
                    closeable   : true,
                    paging      : new paging($scope.pageSize),
                    sorting     : {
                        sortProp : 'date',
                        sortDir  : 'asc'
                    },
                    table       : []
                };
                $scope.items.push(openReport);
            }
            $scope.activeItem = openReport;
        }

        $scope.addNewOpenReport = function(model){
            addNewOpenReport(model);
        };

        $scope.closeItem = function(report) {
            if (report) {
                var openReport = _.find($scope.items, function(item) {
                    return item.name == report.name;
                });
                var index = $scope.items.indexOf(openReport);

                if ($scope.activeItem == openReport)
                    $scope.activeItem = $scope.items[index - 1];

                $scope.items.splice(index, 1);
            }
        };

        $scope.getProductCategoryDisplay = productsService.formatProductsList; 
    }
]);