angular.module('angus.controllers').controller('marginAnalysisReportContainerCtrl', [
  '$scope', '$rootScope', '$q', '$http', 'periodIndicators', 'productsService', 'actionViewTemplates', '_', 'constantsService', 'lastDeliveryDateService', 'hierarchyService', 'dateCalculator', 'drillService', 'deliveryTypeService', 'Paging', 'moment', 'gridService2', 'nodeType', 'gridState', 'restrictedAccessService',
    function($scope, $rootScope, $q, $http, periodIndicators, productsService, actionViewTemplates, _, constantsService, lastDeliveryDateService, hierarchyService, dateCalculator, drillService, deliveryTypeService, paging, moment, gridService2, nodeType, gridState, restrictedAccessService) {

        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.dates = {
            startDate        : moment.utc($scope.settings.startDate).format('L') || moment(),
            endDate          : moment.utc($scope.settings.endDate).format('L') || moment(),
            lastInvoiceDate  : moment.utc($scope.settings.endDate).format('L')
        };

        $scope.filter =  {
            productIds      : $scope.settings.productIds.value,
            hierarchyNodeId : $scope.settings.hierarchyNodeId,
            tradeClassIds   : $scope.settings.tradeClassIds.value,
            zeroPriceSales  : $scope.settings.zeroPriceSales.value,
            usePostingDate: $scope.settings.usePostingDate.value
        };

        $scope.getMarginAnalysis = function(){

            var params = {
                p           : $scope.filter.productIds,
                s           : $scope.dates.startDate.format(),
                e           : $scope.dates.endDate.format(),
                t           : $scope.filter.tradeClassIds,
                z           : $scope.filter.zeroPriceSales,
                divisionId  : $scope.filter.hierarchyNodeId,
                usePostingDate: $scope.filter.usePostingDate
            };

            var rowPromise = $http({
                    url: ('api/subscribers/{0}/margins').format($scope.subscriberId),
                    method: 'POST',
                    data: params
            }).then(function(result) {
                const data = result.data;

                data.forEach(row => {
					/* A bug with AG Grid appears to force us to use the same ColId and Field names. So duplicate the Date field here. */
					row.dateMonth = row.date;
					row.dateYear = row.date;
				});

                return data;
            });

            var promise = $scope.grid.setRows(rowPromise);

            //$scope.promiseMonitor = new PromiseMonitor(promise);
            return promise;
        }



       function getColClicks(){
            return {
                accountNumber   : {
                    onClick : function(row){ drillService.openAccountInfo(row, drillService.context.margin); }
                },
                tankNumber      : {
                    onClick : function(row){ drillService.openTankInfo(row, drillService.context.margin); }
                }
            };
        }

        function getColDefs(){

            var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];

            colDefs.push(gridService2.colDef.createText('productName','Product','productName', { rowGroupIndex : nextIndex, hide : true }));
            colDefs.push(gridService2.colDef.createText('tradeClassName','Trade Class','tradeClassName', { rowGroupIndex : nextIndex + 1, hide : true }));
            colDefs.push(gridService2.colDef.createText('pricePlanName','Price Plan','pricePlanName', { rowGroupIndex : nextIndex + 2, hide : true }));


            colDefs.push(gridService2.colDef.createDate('date','Date','date', { sort: 'asc' }));
            colDefs.push(gridService2.colDef.createMonth('dateMonth','Month','dateMonth'));
            colDefs.push(gridService2.colDef.createYear('dateYear','Year','dateYear'));
            colDefs.push(gridService2.colDef.createDate('useAccountingDate','Accounting Date','useAccountingDate', { hide: true, sort: 'asc' }));
            colDefs.push(gridService2.colDef.createDate('postingDate','Posting Date','postingDate', { hide: true, sort: 'asc' }));
            colDefs.push(gridService2.colDef.createText('accountNumber','Account Number','accountNumber', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('customerName','Customer Name','customerName'));
            colDefs.push(gridService2.colDef.createText('tankNumber','Tank Number','tankNumber', { cellClass : ['center']} ));

            colDefs.push(gridService2.colDef.createText('salesperson','Salesperson','salesperson', {}, {}));
            colDefs.push(gridService2.colDef.createBoolean('preBuyFlag','PreBuy','preBuyFlag'));

            colDefs.push(gridService2.colDef.createNumber('units','Units Delivered','units', {}, {decimalPlaces: 2 }));

            if(!$scope.restrictedAccess.costAndMargin)
              colDefs.push(gridService2.colDef.createCurrency('unitPrice','Unit Price','unitPrice',{},  { decimalPlaces: 4 }));

            if(!$scope.restrictedAccess.costAndMargin)
              colDefs.push(gridService2.colDef.createCurrency('unitCost','Unit Cost','unitCost',{},  { decimalPlaces: 4 }));colDefs.push(gridService2.colDef.createCurrency('unitCost','Unit Cost','unitCost',{},  { decimalPlaces: 4 }));

            if(!$scope.restrictedAccess.costAndMargin)
              colDefs.push(gridService2.colDef.createCurrency('unitMargin','Unit Margin','unitMargin',{},  { decimalPlaces: 4 }));

            colDefs.push(gridService2.colDef.createText('priceCode','Pricing Code','priceCode',{hide: true}));
            colDefs.push(gridService2.colDef.createText('priceCodeDescription','Price Code Description','priceCodeDescription',{hide: true}));
            colDefs.push(gridService2.colDef.createCurrency('baseUnitPrice','Base Unit Price','baseUnitPrice',{hide: true},  { decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createCurrency('baseUnitPriceVariance','Unit price Variance','baseUnitPriceVariance',{hide: true},  { decimalPlaces: 2 }));
            
            if(!$scope.restrictedAccess.revenue)
              colDefs.push(gridService2.colDef.createCurrency('totalRevenue','Total Revenue','totalRevenue',{},  { decimalPlaces: 2 }));

            if(!$scope.restrictedAccess.costAndMargin)
              colDefs.push(gridService2.colDef.createCurrency('totalCost','Total Cost','totalCost',{},  { decimalPlaces: 2 }));

            if(!$scope.restrictedAccess.costAndMargin)
              colDefs.push(gridService2.colDef.createCurrency('totalMargin','Total Margin','totalMargin',{},  { decimalPlaces: 2 }));

            colDefs.push(gridService2.colDef.createDate('acquisitionDate', 'Acquisition Date', 'acquisitionDate', {hide : true}));

            colDefs.push(gridService2.colDef.createText('emailAddress', 'Email Address', 'emailAddress', {hide: true}));

          return colDefs;
        }

        var nodeColDefs;


        $q.all([
              nodeType.getDivisionColDefs(subscriberId),
              restrictedAccessService.getAccess()
            ])
            .spread(function(colDefs, access){
                $scope.restrictedAccess = access.restrictedAccess;
                nodeColDefs = colDefs;
                var gridParams = {
                    gridOptions   : {
                        groupAggFunction : function(rows){

                            return _.reduce(rows, function(result, row){
                                var data = row.data;

                                result.units        += +data.units.toFixed(3);
                                result.totalRevenue += data.totalRevenue;
                                result.totalCost    += data.totalCost;
                                result.totalMargin  += data.totalMargin;
                          
                                //display data  
                                result.unitPrice     = result.units ? result.totalRevenue / result.units : null;  
                                result.unitCost      = result.units ? result.totalCost / result.units : null; 
                                result.unitMargin    = result.units ? result.totalMargin / result.units : null;  

                                return result;
                            }, {
                                units: 0,
                                unitPrice: 0,
                                unitCost: 0,
                                unitMargin: 0,
                                totalRevenue: 0,
                                totalCost: 0,
                                totalMargin: 0,
                            });
                        }
                    },
                    gridState     : gridState(subscriberId, $scope.widgetCode),
                    defs          : getColDefs(),
                    clicks        : getColClicks(),
                    exportOptions : { fileName: 'Margin Analysis' }
                };
                return gridService2.createGrid(gridParams);
            })
            .then(function(grid){
                $scope.grid = grid;
                $scope.getMarginAnalysis();
            });

    }
]);
