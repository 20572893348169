angular.module('angus.controllers').controller('deliveryPerfSettingsCtrl', [
    '$scope', 'productsService', 'hierarchyService', 'constantsService', 'deliveryTypeService',
    function($scope, productsService, hierarchyService, constantsService, deliveryTypeService) {

        'use strict';
        $scope.periodIndicators = constantsService.periodIndicators.enums;

        $scope.widget.instance.settings.toggleViewOptions = [{
            value: 'Avg. Ideal Drop',
            id: 1
        }, {
            value: 'Tank Size Delivered',
            id: 2
            }];


        deliveryTypeService.getDeliveryTypeUsedOptions()
            .then(function (deliveryTypeUsedOptions) {
                $scope.deliveryTypeUsedOptions = deliveryTypeUsedOptions;
            });

        productsService.getProducts()
            .then(function(products) {
                $scope.products = products;
            });

        deliveryTypeService.getDeliveryTypes()
            .then(function(deliveryTypes) {
                $scope.deliveryTypes = deliveryTypes;
            });

        $scope.openDivisionHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };
    }
]);
