angular.module('angus.services').service('userService', [
    '$http', '$rootScope', '$window', 'authenticationService', 'modalService',
    function($http, $rootScope, $window, authenticationService, modalService) {

        this.impersonateUser = function(subscriberId, userId) {
            const modalOptions = {
                newWindow: 'newWindow',
                currentWindow: 'currentWindow'
            };

            $http.get(`identity/impersonate-legacy/${userId}`, {apiV2: true})
                .then(function(res){
                    const user = res.data;

                    var scope = {
                        currentWindow: modalOptions.currentWindow,
                        newWindow: modalOptions.newWindow,
                        userName: user.userName
                    };

                    return modalService.openTopBarModal('templates/global/html/impersonationSelection.html', scope)
                        .then(function (selection){
                           if (selection === modalOptions.newWindow) {
                                $window.open(('/#/Impersonate?subscriberId={0}&userId={1}').format(subscriberId, user.userId), '_blank');
                           }
                           else if (selection === modalOptions.currentWindow) {
                                authenticationService.impersonateUser($rootScope.user.username, user);
                                $window.location.reload(true);
                           }
                        });
                });
        };
    }
]);