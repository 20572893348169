'use strict';
angular.module('angus.directives').directive('widget', [
    '$compile', '$templateCache', '$timeout', 'promiseMonitor', '$', '_', 'modalService', 'actionViewTemplates', 'actionViews',
    function($compile, $templateCache, $timeout, PromiseMonitor, $, _, modalService, actionViewTemplates, actionViews) {
        return {
            scope: {
                widget: '=',
                onDelete: '=',
                onSave: '=',
                isLocked: '=',
                promiseQueue: '='
            },
            link: function(scope, element) {

                /*  The widgets have buttons on the header and if the widget's width is less than 300px then the buttons will be replaced by a hamburger menu. 
                    This is done by evaluating the min-width attribute that Gridster adds to the gridster item that the widget is in. When a new widget is added the Gridster item does not apply the min-width attribute
                    so the new widget will always have the hamburger menu until the page is refreshed.
                    The following will apply that attribute to new widgets as workaround for this issue. This is needed to be done in a timeout otherwise the parent isn't found when the directive is initialized. 
                */
                $timeout(function(){
                    const width = element[0].clientWidth;
                    const widgetInstanceId = scope.widget.instance.id;

                    const gridsterElement = element.parent().parent().find('#' + widgetInstanceId);

                    /* Parent found and the initial width of the widget is at least 300px */
                    if (gridsterElement.length > 0 && width >= 300) {

                        /* Only apply the attribute if it's not already there. */
                        if(!gridsterElement.attr('min-width')) {
                            gridsterElement.attr('min-width', `300px`);
                        }
                    }
                })
                
                scope.widget.promises = new PromiseMonitor();

                scope.widget.getName = function() {
                    return scope.widget.instance.settings && scope.widget.instance.settings.title ? scope.widget.instance.settings.title : scope.widget.name;
                };

                function generateWidget(cb) {
                    scope.widgetView = ('templates/widgets/{0}/html/views/{1}.html').format(scope.widget.code, scope.widget.instance.viewCode);
                    scope.widgetSettingsView = ('templates/widgets/{0}/html/views/{1}-settings.html').format(scope.widget.code, scope.widget.instance.viewCode);
                    var templateUrl = 'templates/authenticated/dashboard/widget/widgetTemplate.html';

                    cb($($templateCache.get(templateUrl)));
                }

                scope.widget.generated = function() {
                    return true;
                };


                scope.updateRefreshContents = function(display) {
                    if(display)
                        element.show();
                    else
                        element.hide();
                };


                scope.widget.setDefaultInitialization = function(initFn) {
               
                    scope.widget.waiting = true;

                    if(initFn) {
                        scope.defaultInit = initFn;
                        scope.widget.defaultInitialization();
                    }
                    else
                        throw scope.widget.getName() + ' is missing an initFn when calling setDefaultInitialization';
                };

                scope.widget.defaultInitialization = function() {
                    if(scope.defaultInit) {
                        scope.updateRefreshContents(true);
                        if(!scope.promiseQueue) {
                            scope.defaultInit();
                            scope.widget.waiting = false;
                        } else {
                            scope.promiseQueue.enqueue(scope.defaultInit, scope.widget.getName()).then(function() {
                                scope.widget.waiting = false;
                            });
                        }
                    }
                };

                scope.widget.setDefaultActionModel = function(model) {
                    scope.defaultModel = model;
                };

                scope.widget.uploadView = function(model) {
                    // scope.defaultModel = model;
                    console.log('test');
                };
                
                scope.widget.openDetailView = function(url, model) {
                    if(!url) url = actionViews.getActionView(scope.widget.code);

                    function minimized(deferredMaximize) {
                        deferredMaximize
                            .promise
                            .then(maximized);
                    }

                    function maximized(deferredMinimize) {
                        deferredMinimize
                            .promise
                            .then(minimized);
                    }

                    var newModel = {};
                    _.assign(newModel, scope.defaultModel || {});
                    _.assign(newModel, model || {});

                    var modalScope = {
                        widgetName: (scope.widget.instance.settings && scope.widget.instance.settings.title) ? scope.widget.instance.settings.title : scope.widget.name,
                        widgetIcon: scope.widget.icon,
                        widgetClass: scope.widget.instance.widgetClass,
                        widgetId: scope.widget.id,
                        widgetCode: scope.widget.code,
                        settings: scope.widget.instance.settings,
                        model: newModel
                    };

                    //$analytics.eventTrack('widget:expand', { category: 'widget', label: modalScope.widgetName });
                    // $analytics.pageTrack('/widgets/' + modalScope.widgetCode); 
                    modalService.openActionView(url, modalScope, scope.widget.instance.id);
                };

                scope.widget.delete = function() {
                    if(scope.widget.deleted) return;

                    var modalScope = {
                        title: 'Delete ' + scope.widget.getName() + '?',
                        message: 'Are you sure you want to delete the widget \'' + scope.widget.getName() + '\'? By doing so you will lose all saved settings associated with this widget.'
                    };

                    modalService
                        .openTopBarModal(actionViewTemplates.confirmDialog, modalScope)
                        .then(function(result) {
                            if(!result) return;
                            scope.widget.deleted = true;

                            if(scope.onDelete) {
                                scope.onDelete(scope.widget);
                            }

                            scope.$emit('widgetWantsToBeDeleted', scope.widget.instance.id);
                        });
                };

                scope.widget.saveSettings = function () {
                    scope.$emit('widgetSettingsUpdated', scope.widget.instance.id, scope.widget.instance.settings, scope.widget.instance.widgetCode);
                };

                scope.widget.saveFromPicker = function(settings, save) {
                    scope.widget.instance.settings = settings;

                    scope.$broadcast('widgetSettingsChanged');
                    if(save) {
                        if(scope.onSave) {
                            scope.onSave(scope.widget);
                        }

                        scope.widget.saveSettings();
                    }
                };

                scope.widget.editSettings = function() {
                    scope.widget.settingsMode = true;

                    var modalScope = {
                        widget: scope.widget,
                        widgetSettingsView: scope.widgetSettingsView
                    };

                    modalService
                        .openTopBarModal(actionViewTemplates.widgetSettingsModal, modalScope, function(modelContainer) {
                            if(scope.isLocked) {
                                $(modelContainer)
                                    .find("input,select,button")
                                    .not(":contains(Cancel)")
                                    .attr("disabled", true);

                                setTimeout(function() {
                                    $(modelContainer)
                                        .find("input,select,button,div.custom-select")
                                        .not(":contains(Cancel)")
                                        .attr("disabled", true);

                                    $(modelContainer)
                                        .find(".custom-select-list")
                                        .remove();

                                }, 750);
                            }
                        })
                        .spread(function(settings, save) {
                            scope.widget.settingsMode = false;

                            if(!settings) return;

                            scope.widget.instance.settings = settings;
                            scope.$broadcast('widgetSettingsChanged');

                            if(save) {
                                if(scope.onSave) {
                                    scope.onSave(scope.widget);
                                }

                                scope.widget.saveSettings();
                            }
                        });
                };

                generateWidget(function(container) {
                    $compile(angular.element(container))(scope);
                    element.html($(container));
                    scope.widget.generated = true;
                });
            }
        };
    }
]);
