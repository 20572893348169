function Window(id, name, icon, template, callingScope) {
    this.id = id;
    this.name = name;
    this.icon = icon;
    this.template = template;
    this.isMaximized = false;

    /* Not a fan of "Calling Scope"... But it'll do since that's what was already used */
    this.callingScope = callingScope; /* Leaving this as "Calling Scope" since this is how the old way was done. That way I shouldn't have to update all the existing views/widgets */
    this.callingScopeCallback = null;
}


Window.prototype.maximize = function () {
    this.isMaximized = true;
}

Window.prototype.minimize = function () {
    this.isMaximized = false;
}

Window.prototype.updateCallingScope = function (newCallingScope) {
    this.callingScope = newCallingScope;

    if (this.callingScopeCallback) {
        this.callingScopeCallback(newCallingScope);
    }
}




angular.module('angus.services').service('windowsService',[ 
    '$rootScope', 
    function ($rootScope) {
        let windows = [];
        let activeWindow = null;    
        let maximizedWindowsStack = [];

        /*  Exposed Service Methods will interface using the Id. 
            This helper function will convert the Id to the Windows Index in the array. */
        function getIndexForId(id) {
            return windows.findIndex(function (window) {
                return window.id === id;
            });
        }

        function getWindowForId(id) {
            return windows.find(function (window) {
                return window.id === id;
            })
        }

        


        /* Activates a Window. Since the active window must be maximized, it'll maximize the newly active window. */
        function activate(window) {
            activeWindow = window;

            /* Make sure Window is maximized. */
            if (activeWindow) {
                maximize(window);
            }

            $rootScope.$broadcast('activeWindowChanged', activeWindow);
        }


        /* Pushes the window to the top of the Maximized Window Stack and Maximizes the window */
        function maximize(window) {
            /* Remove the window from the Stack if it already is in there */
            deleteWindowFromMaximizedStack(window);

            maximizedWindowsStack.push(window);

            window.maximize();
        }


        /* Activate the next window in the Maximized Window Stack */
        function activateNextMaximizedWindow() {
            if (maximizedWindowsStack.length > 0) {
                const windowToActivate = maximizedWindowsStack.pop();

                activate(windowToActivate);
            }
            else {
                activate(null);
            }
        }


        /* Remove window from the Maximized Window Stack. Parameter is the index of the window in the Windows array. */
        function deleteWindowFromMaximizedStack(window) {

            /* Find all references of the window in the Maximized Window Stack and remove them. 
                Should only be one, but this will make sure we get them all in the case that more than one exist. */
            maximizedWindowsStack.forEach(function (maximizedWindow, stackIndex) {
                if (maximizedWindow.id === window.id) {
                    maximizedWindowsStack.splice(stackIndex, 1);
                }
            });
        }



        /* Return Windows */
        this.getWindows = function () {
            return windows;
        }

        this.getWindow = function (id) {
            return getWindowForId(id);
        }

        this.getActiveWindow = function () {
            return activeWindow;            
        }


        this.isActive = function (id) {

            if (!activeWindow) {
                return false;
            }

            return id === activeWindow.id;
        }


        this.isMaximized = function (id) {
            const window = getWindowForId(id);

            return window.isMaximized;
        }


        this.addWindow = function (id, name, icon, template, callingScope) {
            const window = new Window(id, name, icon, template, callingScope);

            windows.push(window);

            activate(window);
        }


        this.activateWindow = function (id) {
            const window = getWindowForId(id);

            activate(window);
        }


        this.maximizeWindow = function (id) {
            const window = getWindowForId(id);

            maximize(window);
        }


        this.minimizeWindow = function (id) {
            const window = getWindowForId(id);

            deleteWindowFromMaximizedStack(window);

            window.minimize();

            activateNextMaximizedWindow();
        }

        this.minimizeAllWindows = function() {
            /* Set all Windows to Minimized */
            windows.forEach(function (window) {
                window.minimize();
            });

            maximizedWindowsStack = [];

            activate(null);

        }


        this.closeWindow = function (id) {
            const window = getWindowForId(id);

            /* Remove from Stack */
            deleteWindowFromMaximizedStack(window);

            /* Remove from Array */
            const index = getIndexForId(id);
            windows.splice(index, 1)[0];

            /* If the window that was removed was Active then try to activate the next window in the Maximized Window Stack */
            if (this.isActive(id)) {
                activateNextMaximizedWindow();
            }
        }

        this.doesWindowExist = function(id) {
            if (getIndexForId(id) >= 0) {
                return true;
            }

            return false;
        }

        this.setCallingScopeCallBack = function(id, callback) {
            const window = getWindowForId(id);

            if (window) {
                window.callingScopeCallback = callback;
            }
        }
    }
]);

