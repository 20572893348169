angular.module( 'angus.directives' ).directive( 'deliveryPerf', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		return {
			scope: {
				closeCb: "=",
				model: "=actionModel",
				settings: "=",
				widgetCode : "="
			},
			templateUrl: actionViewTemplates.deliveryPerfReportContainer
		};
	}
] );