angular.module('angus.filters')
    .filter('nullDash', [ '$filter', function($filter) {

    	'use strict';

        return function(value, filterName) {
        	var nameSplit = filterName.split(':');

            return !!value && isFinite(value) ? $filter(nameSplit[0])(value, nameSplit[1]) : '--';
        };
    }
]);