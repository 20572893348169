angular.module('angus.directives')
    .directive('clickSpinner', ['$q', function($q) {
        return {
            restrict: 'A',
            scope: {
                onClick: '&',
                monitor: '=' //Promise monitor
            },
            link: function($scope, $element, $attr) {
                var spinner = angular.element('<div class="spinner-container clearfix"><i class="fa fa-spinner fa-spin"></i></div>');


                function addSpinner() {
                    $attr.$set('disabled', true);
                    $element.append(spinner);
                }

                function removeSpinner() {
                    $attr.$set('disabled', false);
                    $element.children('.spinner-container').remove();
                }


                //If theres promise monitor, we'll change the state if it is pending

                $scope.$watch(function() {
                    return $scope.monitor ? $scope.monitor.isPending() : false;
                }, function(isPending) {
                    if (isPending) addSpinner();
                    else removeSpinner();

                })

                function handler() {
                    addSpinner();
                    $q.when($scope.onClick()).finally(removeSpinner);
                }

                //If theres a click function, we'll add an event to change to state on click
                if ($scope.onClick) {

                    $element.on('click', handler);

                    $scope.$on('$destroy', function() {
                        $element.off('click', handler);
                    });
                }
            }
        };
    }]);
