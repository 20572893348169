angular.module( 'angus.directives' ).directive( 'arFuelPakSummary', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		'use strict'
		return {
			scope: {
				model: "=actionModel",
				settings: "=arSummaryFuelPakWidgetSettings",
				widgetCode : "="
			},
			templateUrl: actionViewTemplates.arSummaryFuelPakReportContainer
		};
	}
] );