angular
	.module('angus.controllers')
	.controller('tmsMonitorAdminReadingsController', ['$scope', '$http', '$q', 'moment', 'constantsService', 'promiseMonitor', 'Paging', 'csvService', tmsMonitorAdminMonitorReadingsController]);

function tmsMonitorAdminMonitorReadingsController($scope, $http, $q, moment, constants, PromiseMonitor, Paging, csvService){
	'use strict';

	function getParams(){
		return {
			sortBy    : $scope.activeItem.sortBy,
			ascending : $scope.activeItem.ascending,
			startDate : $scope.activeItem.filters.startDate,
			endDate   : $scope.activeItem.filters.endDate,
		};
	}

	function getUrl(){
		return 'api/monitors/{0}/readings'.format($scope.activeItem.monitor.serialNumber);
	}

	$scope.getMonitorReadings = function(pageNumber){

		 return $scope
		 	.activeItem
		 	.readingsPaging
		 	.getPage(getUrl(), pageNumber, getParams())
		 	.then(function(result) {
		 		$scope.activeItem.readings = result.items;
      		});
	};

	$scope.exportToCsv = function(){
		return csvService.getCsv(getUrl(), getParams(), 'Monitor Messages');
	};


	$scope.sort = function(sortBy){
		if($scope.activeItem.sortBy == sortBy)
			$scope.activeItem.ascending = !$scope.activeItem.ascending;
		else
			$scope.activeItem.sortBy = sortBy;

		return $scope.getMonitorReadings();
	};

    function stopEvent($event) {
        $event.preventDefault();
        $event.stopPropagation();
    }

    $scope.openStartDate = function($event) {
        stopEvent($event);
        $scope.activeItem.endDateOpened = false;
        $scope.activeItem.startDateOpened = true;
    };
    $scope.openEndDate = function($event) {
        stopEvent($event);
        $scope.activeItem.startDateOpened = false;
        $scope.activeItem.endDateOpened = true;
    };

    $scope.resetEndDate = function() {
        if (moment($scope.activeItem.filters.endDate).isBefore(moment($scope.activeItem.filters.startDate)))
            $scope.activeItem.filters.endDate = $scope.activeItem.filters.startDate;
    };



	function init(){

		$scope.activeItem.readingsPaging   = new Paging(20);

		$scope.activeItem.sortBy    = 'sensorTime';
		$scope.activeItem.ascending = false;

		$scope.dateOptions = {
        	formatYear: 'yy',
        	startingDay: 1
    	};

    	$scope.activeItem.startDateOpened  = false;
    	$scope.activeItem.endDateOpened    = false;

		$scope.activeItem.filters = {
			startDate : moment().subtract(7, 'days').toDate(),
			endDate  : moment().toDate()
		};


		$scope.activeItem.readingsMonitor = new PromiseMonitor($scope.getMonitorReadings());
	}

	init();
	
}
