angular.module('angus.services').factory("deliveryTypeService", [
	'$rootScope', '$http', '$q', '_',
	function($rootScope, $http, $q, _) {

		var subscriberDeliveryTypes = [];
		var deliveryTypes = [];

		function getDeliveryTypes(subscriberId) {
			if (subscriberId) {
				if (subscriberDeliveryTypes[subscriberId] && subscriberDeliveryTypes[subscriberId].length > 0)
					return subscriberDeliveryTypes[subscriberId];
				else {
					return queryDeliveryTypes(subscriberId)
						.then(function(deliveryTypesList) {
							subscriberDeliveryTypes[subscriberId] = deliveryTypesList.data;
							return subscriberDeliveryTypes[subscriberId];
						});
				}
			} else {
				if (deliveryTypes && deliveryTypes.length > 0)
					return deliveryTypes;
				else {
					return queryDeliveryTypes()
						.then(function(deliveryTypesList) {
							deliveryTypes = deliveryTypesList.data;
							return deliveryTypes;
						});
				}
			}
		}

		function queryDeliveryTypes(subscriberId) {
			return $http.get(('api/subscribers/{0}/deliveryTypes/').format(subscriberId || $rootScope.user.subscriberId));
		}

		function getDeliveryTypeUsedOptions() {
			return [{
				value: 'Transaction',
				id: 1
			}, {
				value: 'Tank',
				id: 2
			}];
        }

		return {
			getDeliveryTypes: function(subscriberId) {
				return $q.when(getDeliveryTypes(subscriberId))
					.then(function(deliveryTypes) {
						return deliveryTypes;
					});
			},
			formatDeliveryTypesList: function(deliveryTypeIds) {
				var result = '';
				var deliveryTypesList = deliveryTypes;

				if (!(deliveryTypeIds instanceof Array))
					deliveryTypeIds = [deliveryTypeIds.toString()];

				if (deliveryTypeIds.length > 0) {
					_.forEach(deliveryTypesList, function(deliveryType) {
						if (_.includes(deliveryTypeIds, deliveryType.id.toString()))
							result += deliveryType.name + ', ';
					});
				}

				return result.substring(0, result.length - 2);
			},
			getDeliveryTypeUsedOptions: function () {
				return $q.when(getDeliveryTypeUsedOptions())
					.then(function (deliveryTypeUsedOptions) {
						return deliveryTypeUsedOptions;
					});
            }
		};
	}
]);
