'use strict';

angular.module('angus.controllers').controller('deliveryForecastingCtrl', [
    '$scope', '$rootScope', '$http', 'constantsService', 'hierarchyService', 'forecastingService', 'moment', 'actionViewTemplates', '_',
    'dateCalculator',
    function ($scope, $rootScope, $http, constantsService, hierarchyService, forecastingService, moment, actionViewTemplates, _, dateCalculator) {

        /* Reference of main objects used by this widget
        - $scope.DeliveryCategories: Constant Delivery Categories from the forecastingService. Includes ADEPT, Automatic (Non-ADEPT), Will-Call (Non-ADEPT)

        - $scope.periodIndicators: Periods available in the Date Period drop down: This Week, Next Week, etc...

        - $scope.forecastingPeriod: Object that represents the Period that was selected from the Date Period Drop Down. Has details about the forecasting period which include
                                    Start/End Dates, Additional display info, and an array of Periods. Each Period in the array also has it's own Id, Start/End Dates and Display info.
                                    
        - $scope.categoryPeriods: JS Map with one entry per Delivery Category. The Key is the DeliveryCategoryId and the Value is a map of Periods.
                                  Period Map: 
                                    - The Key is the PeriodId from the Forecasting Periods. 
                                    - Value is an Object that contains: 
                                        - Array of Delivery Counts
                                        - Sum and Average of Delivery Counts
            
        - $scope.periodTotals:  JS Map with one entry per Forecasting Periods. Key is the PeriodId from the Forecasting Period. Value is an Object that contains the Sum and Average of Delivery Counts from the  categoryPeriods Periods.
        */

        $scope.deliveryCategories = forecastingService.getDeliveryCategories();

        /* Remove Custom Date Range period indicator. Should not be available to users in the drop down on the Dashboard. */
        $scope.periodIndicators = constantsService.forecastingPeriodIndicators.enums.filter(function (periodIndicator) {
            return periodIndicator.key != 'customDateRange';
        });

        /* Initialize categoryPeriods Map */
        $scope.categoryPeriods = new Map();
        _.forEach($scope.deliveryCategories, function (deliveryCategory) {
            $scope.categoryPeriods.set(deliveryCategory.id, { periods: new Map() });
        });

        $scope.periodTotals = new Map();

        $scope.grandTotal = {
            sum: 0, 
            average: 0
        };

        $scope.openActionView = openActionView;

        $scope.$on('widgetSettingsChanged', loadWidget);

        $scope.widget.setDefaultInitialization(loadWidget);

        

        function loadWidget() {
            $scope.widget.noContentFlag = false

            $scope.periodIndicator = _.find(constantsService.forecastingPeriodIndicators, function (indicator) {
                return $scope.widget.instance.settings.periodIndicatorId.value == indicator;
            });

            $scope.forecastingPeriod = getForecastingPeriod($scope.periodIndicator);

            if (!$scope.dates) {
                $scope.dates = {};
            }

            $scope.dates.startDate = $scope.forecastingPeriod.startDate;
            $scope.dates.endDate = $scope.forecastingPeriod.endDate;

            var promise = retrieveDeliveryForecastingData();

            return $scope.widget.promises.monitor(promise);
        }

        function getForecastingPeriod(periodEnum) {

            /* Start and End Date */
            var dates = dateCalculator.calculatePeriod(null, null, periodEnum.key);

            /* Build Periods based on Period Type */
            switch (periodEnum.value.periodType) {
                case ('week'): {
                    return {
                        startDate: dates.startDate,
                        endDate: dates.endDate,
                        periods: getWeekPeriods(dates.startDate, dates.endDate),
                        periodDescription: 'Week Starting',
                        ticketsColumnHeader: 'Total (Daily\u00A0Avg)',
                        periodName: periodEnum.value.display,
                        showAverage: true
                    };
                }
                case ('month'): {
                    return {
                        startDate: dates.startDate,
                        endDate: dates.endDate,
                        periods: getMonthPeriods(dates.startDate, dates.endDate),
                        periodDescription: 'Month of',
                        ticketsColumnHeader: 'Total (Daily\u00A0Avg)',
                        periodName: periodEnum.value.display,
                        showAverage: true
                    };
                }
                default: { //Day
                    return {
                        startDate: dates.startDate,
                        endDate: dates.endDate,
                        periods: getDayPeriods(dates.startDate, dates.endDate),
                        periodDescription: '',
                        ticketsColumnHeader: 'Total',
                        periodName: periodEnum.value.display,
                        showAverage: false
                    };
                }
            }
        }

        function getDayPeriods(startDate, endDate) {
            var periods = [];

            var loopDate = moment(startDate);

            while (loopDate.isBefore(endDate)) {
                var loopStartDate = moment(loopDate).startOf('day');
                var loopEndDate = moment(loopDate).endOf('day');

                periods.push({
                    id: periods.length + 1,
                    display: loopDate.format('ddd'),
                    startDate: loopStartDate,
                    endDate: loopEndDate,
                    tooltipDisplay: loopStartDate.format('MMM DD')
                });

                loopDate = moment(loopDate).add(1, 'day');
            }

            return periods;
        }

        function getWeekPeriods(startDate, endDate) {
            var periods = [];

            var loopDate = moment(startDate).startOf('week');

            while (loopDate.isBefore(endDate)) {
                var loopStartDate = moment(loopDate).startOf('week');
                var loopEndDate = moment(loopDate).endOf('week')

                periods.push({
                    id: periods.length + 1,
                    display: loopDate.format('MMM DD'),
                    startDate: loopStartDate,
                    endDate: loopEndDate,
                    tooltipDisplay: loopStartDate.format('MMM DD') + ' to ' + loopEndDate.format('MMM DD')
                });

                loopDate = moment(loopDate).add(1, 'week');
            }

            return periods;
        }

        function getMonthPeriods(startDate, endDate) {
            var periods = [];

            var loopDate = moment(startDate);

            while (loopDate.isBefore(endDate)) {
                var loopStartDate = moment(loopDate).startOf('month');
                var loopEndDate = moment(loopDate).endOf('month')

                periods.push({
                    id: periods.length + 1,
                    display: loopDate.format('MMM-YY'),
                    startDate: loopStartDate,
                    endDate: loopEndDate,
                    tooltipDisplay: loopStartDate.format('MMM DD') + ' to ' + loopEndDate.format('MMM DD')
                });

                loopDate = moment(loopDate).add(1, 'month');
            };

            return periods;
        }

        function retrieveDeliveryForecastingData() {
            $scope.widget.noContentFlag = false;

            /* Initialize categoryPeriods Map */
            $scope.categoryPeriods = new Map();
            _.forEach($scope.deliveryCategories, function (deliveryCategory) {
                $scope.categoryPeriods.set(deliveryCategory.id, { periods: new Map(), sum: 0, average: 0 });
            });

            /* Initialize Totals */
            $scope.periodTotals = new Map();

            $scope.grandTotal = {
                sum: 0,
                average: 0
            };


            return queryDeliveryForecastingData()
                .then(function (response) {

                    if (!response || !response.data) {
                        $scope.widget.noContentFlag = true;

                        return null;
                    }

                    /* Separate the data into their proper Categories and Periods */
                    _.forEach(response.data, function (row) {

                        var deliveryCategory = _.find($scope.deliveryCategories, function (deliveryCategory) {
                            return deliveryCategory.id == row.deliveryCategoryKey
                        });

                        var deliveryDate = moment(row.deliveryDate).utc();
                        var deliveryCount = row.deliveryCount;
                        var periodId = getPeriodIdForDeliveryDate($scope.forecastingPeriod.periods, deliveryDate);

                        var categoryPeriod = $scope.categoryPeriods.get(deliveryCategory.id);

                        if (periodId) {
                            if (!categoryPeriod.periods.get(periodId)) {
                                categoryPeriod.periods.set(periodId, { sum: 0, average: 0, deliveryCounts: [deliveryCount] })
                            } else {
                                categoryPeriod.periods.get(periodId).deliveryCounts.push(deliveryCount);
                            }
                        }
                    });

                    /* For Each Category */
                    $scope.categoryPeriods.forEach(function(categoryPeriod){

                    /* Capture how many Periods there are. Used for getting the Grand Total Average */
                    var categoryPeriodCount = 0;

                        /* For Each Period */
                        categoryPeriod.periods.forEach(function (period, periodId) {

                            /* Get Sum of Period */
                            period.sum = _.reduce(period.deliveryCounts, function (sum, n) {
                                return sum + n;
                            }, 0);

                            period.average = period.sum / period.deliveryCounts.length;

                            /* Add Category Period Sum to Category Sum. Keep track of entries for the Total Average. */
                            categoryPeriod.sum += period.sum;
                            categoryPeriodCount+= period.deliveryCounts.length;

                            /* Period Totals */
                            if (!$scope.periodTotals.get(periodId)) {
                                $scope.periodTotals.set(periodId, { sum: period.sum, averageSum: period.average });
                            } else {
                                $scope.periodTotals.get(periodId).sum += period.sum;
                                $scope.periodTotals.get(periodId).averageSum += period.average;
                            }
                        });

                        /* Calculate Category Average */
                        if (categoryPeriodCount){
                            categoryPeriod.average = categoryPeriod.sum / categoryPeriodCount;
                        }
                    });

                    /* Calculate Grand Totals */
                    $scope.categoryPeriods.forEach(function(categoryPeriod) {
                        $scope.grandTotal.sum += categoryPeriod.sum;;
                        $scope.grandTotal.average += categoryPeriod.average;
                    });
                });
        }

        function getPeriodIdForDeliveryDate(periods, deliveryDate) {
            var period = {};

            period = _.find(periods, function (period) {
                return (period.startDate.isSame(deliveryDate) || period.startDate.isBefore(deliveryDate))
                    && (period.endDate.isSame(deliveryDate) || period.endDate.isAfter(deliveryDate))
            });

            if (period) {
                return period.id;
            }
        }

        function queryDeliveryForecastingData() {
            var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

            var divisionIds = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId)

            var divisionIdsParam = _.isArray(divisionIds) ? divisionIds : [divisionIds];
            var productIds = _.isArray($scope.widget.instance.settings.productIds.value) ? $scope.widget.instance.settings.productIds.value : [$scope.widget.instance.settings.productIds.value];
            var tradeClassIds = _.isArray($scope.widget.instance.settings.tradeClassIds.value) ? $scope.widget.instance.settings.tradeClassIds.value : [$scope.widget.instance.settings.tradeClassIds.value];

            return $http({
                url: ('api/subscribers/{0}/deliveryForecasting/summary').format(subscriberId),
                method: 'POST',
                data: {
                    startDate: $scope.dates.startDate ? $scope.dates.startDate.format() : null,
                    endDate: $scope.dates.endDate ? $scope.dates.endDate.format() : null,
                    divisionIds: divisionIdsParam,
                    productIds: productIds,
                    tradeClassIds: tradeClassIds
                }
            });
        }

        function openActionView(deliveryCategoryIds, startDate, endDate) {
            var selectedFilter = {
                deliveryCategoryIds: deliveryCategoryIds,
                startDate: startDate,
                endDate: endDate
            };

            $scope.widget.openDetailView(actionViewTemplates.deliveryForecastingReportContainer, selectedFilter);
        }

    }
]);