angular.module( 'angus.directives' ).directive( 'marginAnalysis', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		return {
			scope: {
				model: "=actionModel",
				settings: "=marginAnalysisWidgetSettings",
				widgetCode : "="
			},
			templateUrl: actionViewTemplates.marginAnalysisReportContainer
		};
	}
] );