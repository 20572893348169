angular.module('angus.services').factory('storageService', ['hierarchyHelperService', '$window',
    function(hierarchyHelperService, $window) {
        'use strict'; 

        var userKey = 'angus_user',
            tokenKey = 'angus_token',
            expiresKey = 'angus_expires',
            impersonationKey = 'angus_impersonationUser';

        var originalUser, impersonatedUser;

        function Storage() {

            this.setUser = function(user) {
                if (!user) return;
                localStorage.setItem(userKey, JSON.stringify(user));
            };

            this.setImpersontationUser = function(user) {
                console.log("this userr", user)
                if (!user) return;
                $window.sessionStorage.setItem(impersonationKey, JSON.stringify(user));
            };

            this.removeImpersontationUser = function() {
                $window.sessionStorage.removeItem(impersonationKey);
            };

            this.getImpersonatedUser = function(){
                impersonatedUser = impersonatedUser || $window.sessionStorage.getItem(impersonationKey);
                return !!impersonatedUser;
            };

            this.getUser = function() {
                impersonatedUser = impersonatedUser || $window.sessionStorage.getItem(impersonationKey);
                originalUser     = originalUser || localStorage.getItem(userKey);
                var user = JSON.parse(impersonatedUser || originalUser);

                user.hierarchy.division.nodes = hierarchyHelperService.convertDataForEaseOfUse(user.hierarchy.division.nodes);
                user.hierarchy.deliveryCenter.nodes = hierarchyHelperService.convertDataForEaseOfUse(user.hierarchy.deliveryCenter.nodes);
                user.hierarchy.serviceCenter.nodes = hierarchyHelperService.convertDataForEaseOfUse(user.hierarchy.serviceCenter.nodes);
                user.hierarchy.dealer.nodes = hierarchyHelperService.convertDataForEaseOfUse(user.hierarchy.dealer.nodes);
                return user;
            };

            this.setToken = function(token, expires) {
                localStorage.setItem(tokenKey, token);
                localStorage.setItem(expiresKey, expires);
                // $window.sessionStorage[tokenKey] = token;
                // $window.sessionStorage[expiresKey] = expires;				
            };

            this.clear = function() {
                localStorage.clear();
                $window.sessionStorage.clear();
            };

            this.getToken = function() {
                var token = localStorage.getItem(tokenKey);
                return token || null;
            };

            this.hasToken = function() {
                var token = localStorage.getItem(tokenKey);

                if (token) {
                    var expires = moment(localStorage.getItem(expiresKey)).utc();
                    var now = moment().utc();

                    if (expires.isAfter(now))
                        return true;
                }

                return false;
            };

            this.getTokenExpiration = function() {
                var expires = localStorage.getItem(expiresKey);
                return expires || null;
            };


        }
        return {
            create: function() {
                return new Storage();
            },
            getCurrentStorage: function() {
                return localStorage.getItem(tokenKey) ? new Storage() : null;
            }
        };

    }
]);


angular.module('angus.services').factory('authenticationService', ['storageService', 'moment', '$rootScope',
    function(storageService, moment, $rootScope) {

        var storage = storageService.getCurrentStorage();

        var signOutMessageKey = 'angus_signOutMessage';

        function getTimeUntilExpiration(expires, units) {
            return moment(expires).utc().diff(moment().utc(), units || 'minutes');
        }
        var tokenExpiresIn = null;
        var previousTokenExpiresIn = null;

        var interval = setInterval(function() {
            if (storage) {
                if (storage.hasToken()) {
                    previousTokenExpiresIn = tokenExpiresIn;
                    tokenExpiresIn = getTimeUntilExpiration(storage.getTokenExpiration(), 'seconds');

                    if (tokenExpiresIn != null && previousTokenExpiresIn != null && tokenExpiresIn > previousTokenExpiresIn)
                        $rootScope.$broadcast('tokenReset');

                    if (tokenExpiresIn < 600)
                        $rootScope.$broadcast('tokenExpiresSoon', tokenExpiresIn);

                } else
                    $rootScope.$broadcast('tokenExpired');
            }

        }, 1000);

        return {
            setUserInfo: function(user, token, expires) {
                if (!storage)
                    storage = storageService.create();

                storage.setUser(user);
                storage.setToken(token, expires);
                return storage.getUser();
            },
            impersonateUser: function(currentUsername, impersonatedUser) {
                impersonatedUser.impersonatedBy = currentUsername;
                storage.setImpersontationUser(impersonatedUser);

                return storage.getUser();
            },
            stopImpersonating: function() {
                storage.removeImpersontationUser();
                return storage.getUser();
            },
            clearUserInfo: function(signOutMessage) {
                if (!storage) return;
                storage.clear();
                storage = null;
            },
            getUser: function() {
                return storage ? storage.getUser() : null;
            },
            hasImpersonatedUser: function() {
                return storage ? storage.getImpersonatedUser() : null;
            },
            hasToken: function() {
                if (storage) {
                    if (storage.hasToken())
                        return true;
                    else
                        storage.clear();
                }
                return false;
            },
            getToken: function() {
                return storage ? storage.getToken() : null;
            },
            getTokenExpiration: function() {
                return storage ? storage.getTokenExpiration() : null;
            },
            getTimeUntilExpiration: function() {
                return tokenExpiresIn;
            }

        };
    }
]);
