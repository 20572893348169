angular.module('angus.services').factory('fiscalYearService', [
    '$http', '$rootScope', 'moment', '$q',
    function($http, $rootScope, moment, $q) {
        'use strict';

        var _cache = {};

        function getFiscalYear(subscriberId) {

            function queryYear(){
                return $http.get(('api/subscribers/{0}/settings/fiscalYearStartMonth/').format(subscriberId)).then(function(month) {
                    //moment is 0 based so we force our start months to be 0 based
                    var startMonth = month.data.startMonth - 1;
                    _cache[subscriberId] = startMonth;
                    return startMonth;
                });
            }

            return $q.when(_cache[subscriberId] || queryYear());
        }

        function getMonths(startMonth) {
            var months = moment.months();

            if (startMonth !== 0) {
                var endMonths = months.splice(0, startMonth);
                months = months.concat(endMonths);
            }
            return months;
        }

        return {
            getFiscalYearStartMonth: function(subscriberId) {
                return getFiscalYear(subscriberId || $rootScope.user.subscriberId);
            },
            getFiscalYearMonthsOrdered: function(subscriberId) {
                return getFiscalYear(subscriberId || $rootScope.user.subscriberId)
                    .then(function(startMonth) {
                        return getMonths(startMonth);
                    });
            }

        };
    }
]);
