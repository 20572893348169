angular.module('angus.controllers').controller('acctGainLossByTankSettingsCtrl', [
    '$scope', '$rootScope', '$http', 'periodIndicators', 'productsService',  'hierarchyService', 'tradeClassService', 'constantsService', 'salesPersonService', 'deliveryTypeService',
    function($scope, $rootScope, $http, periodIndicators, productsService,  hierarchyService, tradeClassService, constantsService, salesPersonService, deliveryTypeService) {
        'use strict';
        $scope.periodIndicators = constantsService.marginPeriodIndicators.enums;
        $scope.ieoOptions       = constantsService.includeExcludeOnly.enums;


        productsService.getProducts()
            .then(function(products) {
                $scope.products = products;
            });

        salesPersonService.getSalesPerson()            
            .then(function(salesPeople) {
                $scope.salespersons = salesPeople;
            });

        tradeClassService.getTradeClasses()
            .then(function(classes) {
                $scope.tradeClasses = classes;
            });

        deliveryTypeService.getDeliveryTypes()            
            .then(function(deliveryTypes) {
                $scope.deliveryTypes = deliveryTypes;
            })

        $scope.openDivisionHierarchyView = function() {
            hierarchyService
                .openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };
    }
]);
