angular.module('angus.services').service('gridState', 
	['$filter', 'moment', '_', 'fluentRest',
	function($filter, moment, _, fluentRest) {
		'use strict';

		return function(subscriberId, widgetCode){
			return{
				get: function(){
					return fluentRest.api().subscribers(subscriberId).gridState(widgetCode).get();
				},
				save : function(state){	        	
		        	return fluentRest
		        		.api()
		        		.subscribers(subscriberId)
		        		.gridState(widgetCode)
		        		.put(state)
		        		.then(function(){
		        			return state;
		        		});
		        },
		        remove : function(){					        	
		        	return fluentRest
		        		.api()
		        		.subscribers(subscriberId)
		        		.gridState(widgetCode)
		        		.delete();
		        } 		
	        }			  
		};	
	}
]);