angular.module('angus.directives')
  .directive('budgetTradeClassesSelect', [function () {
    'use strict';

    return {
      restrict: 'A',
      scope: {
        ngModel: '=',
        subscriberId: '=',
        isFinance : '='
      },
      controller: ['$rootScope', '$scope', 'budgetsService', function ($rootScope, $scope, BudgetsService) {
      	var service = new BudgetsService($scope.subscriberId || $rootScope.user.subscriberId);        

        var params = { sortBy: 'name', ascending: 1 };

        $scope.$watch(function(){
          return $scope.isFinance;
        }, function(isFinance){
          if(isFinance)
            params.onlyFinance = 1;
          else 
            delete params.onlyFinance;

            service
              .getTradeClasses(params)
              .then(function (tradeClasses) {
                  $scope.tradeClasses = tradeClasses;
              });
        });        
      }],
      template: '<div multi-select-ok-wrapped="tradeClasses" ng-model="ngModel" display-prop="\'name\'" selection-prop="\'id\'"></div>',
    };
  }]);