angular.module('angus.controllers').controller('deliveryPerfReportContainerCtrl', [
	'$scope', '$rootScope', '$http', 'periodIndicators', 'productsService', 'actionViewTemplates', '_', 'constantsService', 'lastDeliveryDateService', 'hierarchyService', 'dateCalculator', 'drillService',
	'deliveryTypeService', 'Paging', 'moment', 'gridService2', 'nodeType', 'gridState',
	function($scope, $rootScope, $http, periodIndicators, productsService, actionViewTemplates, _, constantsService, lastDeliveryDateService, hierarchyService, dateCalculator, drillService,
		deliveryTypeService, paging, moment, gridService2, nodeType, gridState) {

		'use strict';

		var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.dates = {
            startDate 		 : moment.utc($scope.settings.startDate).format('L') || moment(),
            endDate 		 : moment.utc($scope.settings.endDate).format('L') || moment(),
            lastDeliveryDate : moment.utc($scope.settings.endDate).format('L'),
        };

		deliveryTypeService.getDeliveryTypes()
			.then(function(deliveryTypes) {
				$scope.deliveryTypes = deliveryTypes;
			});

		deliveryTypeService.getDeliveryTypeUsedOptions()
			.then(function (deliveryTypeUsedOptions) {
				$scope.deliveryTypeUsedOptions = deliveryTypeUsedOptions
			});

	    $scope.filter =  {
	    	productIds 			: $scope.settings.productIds.value,
	    	hierarchyNodeId 	: $scope.settings.hierarchyNodeId,
	    	automaticDeliveries	: $scope.settings.automaticDeliveries.value,
	    	willCallDeliveries	: $scope.settings.willCallDeliveries.value,
	    	deliveryTypeIds 	: $scope.settings.deliveryTypeIds.value,
	    	minTankSize 		: $scope.settings.minTankSize.value,
	    	maxTankSize 		: $scope.settings.maxTankSize.value,
	    	minUnits 			: $scope.settings.minUnits.value,
	    	maxUnits 			: $scope.settings.maxUnits.value,
	    	runouts 			: ($scope.model && $scope.model.metricCategoryId == 3),
	    	partials 			: ($scope.model && $scope.model.metricCategoryId == 2),
            optimalDrop         : ($scope.model && $scope.model.metricCategoryId == 1),
	    	deliveryGroup   	: constantsService.includeExcludeOnly.include.key,
			usePostingDate	: $scope.settings.usePostingDate.value,
			deliveryTypeUsed	: $scope.settings.deliveryTypeUsed.value,
			monitoredTanks	    : $scope.settings.monitoredTanks.value
	    };

	    $scope.getDeliveryPerformance = function(){

	      	var params = {
	      	    divisionId 		: $scope.filter.hierarchyNodeId,
                productIds      : $scope.filter.productIds,
                startDate       : $scope.dates.startDate.format(),
                endDate         : $scope.dates.endDate.format(),
                deliveryTypeIds : $scope.filter.deliveryTypeIds,
                minTankSize     : $scope.filter.minTankSize,
                maxTankSize     : $scope.filter.maxTankSize,
                minUnits        : $scope.filter.minUnits,
                maxUnits        : $scope.filter.maxUnits,
                deliveryGroupKey: $scope.filter.deliveryGroup,
                runOuts         : $scope.filter.runouts,
                partialFills    : $scope.filter.partials,
                optimalDrop     : $scope.filter.optimalDrop,
				monitoredTanks  : constantsService.includeExcludeOnlyOptions[$scope.filter.monitoredTanks],
				usePostingDate: $scope.filter.usePostingDate,
				deliveryTypeUsed: $scope.filter.deliveryTypeUsed
	      	};

	    	var rowPromise = $http({
	        	  	url: ('api/subscribers/{0}/batches/deliveries').format(subscriberId),
	          		method: 'POST',
	          		data: params
	        }).then(function(result){ 
                var optimalDropContainer = [];
                if($scope.filter.optimalDrop) {
                    _.forEach(result.data, function(row) {
                        if(row.percentageOfIdealDrop >= 100 || row.percentageOfIdealDrop == null || row.percentageOfIdealDrop == undefined) {
                            optimalDropContainer.push(row);
                        }
                    });

                    return optimalDropContainer;
                }

	        	return result.data;
	        })
			 .then(function(data) {
				data.forEach(row => {
					/* A bug with AG Grid appears to force us to use the same ColId and Field names. So duplicate the Date field here. */
					row.deliveryDateMonth = row.deliveryDate;
					row.deliveryDateYear = row.deliveryDate;
				});

				return data;
			});

	        var promise = $scope.grid ? $scope.grid.setRows(rowPromise) : null;

			//$scope.promiseMonitor = new PromiseMonitor(promise);
			return promise;
	    };

	   function getColClicks(){
	        return {
	            accountNumber 	: {
	            	onClick : function(row){ drillService.openAccountInfo(row, drillService.context.delivery); }
	            },
	            tankNumber 		: {
	            	onClick : function(row){ drillService.openTankInfo(row, drillService.context.delivery); }
	            },
	            kFactorVariance : {
	            	onClick : function(row){ if(row.deliveryKey) drillService.openKFactorInfo(row); },
	            	isNotClickable : function(params){
	            		return !params.data.deliveryKey;
	            	}
	            },
                adjIdealDrop    : {
                	onClick : function(row){ if(row.deliveryKey) drillService.openAdjustedPercentInfo(row); },
                	isNotClickable : function(params){
                		return !params.data.deliveryKey;
	            	}
                }
	        };
	    }

	    function getColDefs() {

            var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];

            colDefs.push(gridService2.colDef.createText('productName','Product','productName', { rowGroupIndex : nextIndex, hide : true }));
            colDefs.push(gridService2.colDef.createText('deliveryTypeName','BRITE Delivery Type','deliveryTypeName', { rowGroupIndex : nextIndex + 1, hide : true }));
            colDefs.push(gridService2.colDef.createText('sourceDeliveryTypeName','Source Delivery Type','sourceDeliveryTypeName', { hide : true }));
            colDefs.push(gridService2.colDef.createText('deliveryTypeNameTank','BRITE Tank Delivery Type','deliveryTypeNameTank', { hide : true }));
            colDefs.push(gridService2.colDef.createText('sourceDeliveryTypeNameTank','Source Tank Delivery Type','sourceDeliveryTypeNameTank', { hide : true }));
            colDefs.push(gridService2.colDef.createDate('deliveryDate','Delivery Date','deliveryDate', { sort : 'asc'}));
            colDefs.push(gridService2.colDef.createDate('useAccountingDate','Accounting Date','useAccountingDate', { hide: true }));
			colDefs.push(gridService2.colDef.createDate('postingDate','Posting Date','postingDate', { hide: true }));
			colDefs.push(gridService2.colDef.createMonth('deliveryDateMonth','Month','deliveryDateMonth', {}));
			colDefs.push(gridService2.colDef.createYear('deliveryDateYear','Year','deliveryDateYear',  {}));

            colDefs.push(gridService2.colDef.createText('accountNumber','Account Number','accountNumber', { cellClass : ['center'], sort : 'asc'} ));
            colDefs.push(gridService2.colDef.createText('customerName','Customer Name','customerName'));
            colDefs.push(gridService2.colDef.createText('emailAddressCustomerLocation','E-mail','emailAddressCustomerLocation'));
            colDefs.push(gridService2.colDef.createText('tankNumber','Tank Number','tankNumber', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createNumber('tankSize','Tank Size','tankSize'));
            colDefs.push(gridService2.colDef.createText('truckNumber','Truck Number','truckNumber', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('truckName','Truck Name','truckName', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('truckDesc','Truck Description','truckDesc', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('driverName','Driver Name','driverName', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('salesRep','Sales Rep','salesRep', { hide: true, cellClass : ['center']}));
            colDefs.push(gridService2.colDef.createNumber('units','Units Delivered','units', { aggFunc : 'sum'}, {decimalPlaces: 1 }));

            colDefs.push(gridService2.colDef.createNumber('deliveryRevenue','Delivery Revenue','deliveryRevenue', { hide: true}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createNumber('deliveryMargin','Delivery Margin','deliveryMargin', { hide: true}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createNumber('projectedMargin','Projected Margin','projectedMargin', { hide: true}, {decimalPlaces: 4 }));

            colDefs.push(gridService2.colDef.createPercentage('preDeliveryPercentFull','Start %','preDeliveryPercentFull', {}, {decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createPercentage('postDeliveryPercentFull','End %','postDeliveryPercentFull', {}, {decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createBoolean('preDeliveryUnitsCalculatedFlag','Start % Est','preDeliveryUnitsCalculatedFlag'));
            colDefs.push(gridService2.colDef.createBoolean('postDeliveryUnitsCalculatedFlag','End % Est','postDeliveryUnitsCalculatedFlag'));

            colDefs.push(gridService2.colDef.createNumber('transportationRate','Transportation Rate','transportationRate'));
            colDefs.push(gridService2.colDef.createNumber('idealDrop','Ideal Drop','idealDrop'));
            colDefs.push(gridService2.colDef.createPercentage('percentageOfIdealDrop','% Ideal Drop Delivered','percentageOfIdealDrop', {}, { decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createPercentage('adjIdealDrop','Adjusted % Ideal Drop','adjIdealDrop', {}, {decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createPercentage('percentTankDelivered','% of Tank Size Delivered','percentTankDelivered', {}, {decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createPercentage('kFactorVariance','K-Factor Variance','kFactorVariance', {}, {decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createBoolean('partialFillFlag','Partial Fill','partialFillFlag'));
            colDefs.push(gridService2.colDef.createBoolean('runOutFlag','Run Out','runOutFlag'));
            colDefs.push(gridService2.colDef.createBoolean('potentialRunOutFlag','Potential Run Out','potentialRunOutFlag'));
            colDefs.push(gridService2.colDef.createBoolean('monitored','Tank Monitor','monitored'));
            colDefs.push(gridService2.colDef.createText('deliveryGroup','Delivery Group','deliveryGroup'));

            colDefs.push(gridService2.colDef.createDate('dynamicReserveExecutionDate','Adept Run Date','dynamicReserveExecutionDate', { hide: true} ));
		    colDefs.push(gridService2.colDef.createDate('expectedDeliveryDate','Expected Delivery Date','expectedDeliveryDate', { hide: true} ));
            colDefs.push(gridService2.colDef.createNumber('expectedDeliveryUnits','Expected Delivery Units','expectedDeliveryUnits', { hide: true}, {decimalPlaces: 1 }));
            colDefs.push(gridService2.colDef.createBoolean('deliveryDateMovedEarlyFlag','Optimized','deliveryDateMovedEarlyFlag', {}, { hide: true} ));
			colDefs.push(gridService2.colDef.createNumber('optimizedDays', 'Days Optimized', 'optimizedDays', {hide: true}));
            colDefs.push(gridService2.colDef.createText('deliveryDateToExpectedDateDaysDifference','Adept Date Variance','deliveryDateToExpectedDateDaysDifference', { cellClass : ['center'], hide: true} ));
            colDefs.push(gridService2.colDef.createNumber('containerUnitsAvailableClosestToDelivery','Prior In-tank','containerUnitsAvailableClosestToDelivery', { hide: true}, {decimalPlaces: 1 } )); 
			colDefs.push(gridService2.colDef.createNumber('kFactorPrior','Prior WK','kFactorPrior', { hide: true}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createNumber('kFactorDelivery','Delivery K','kFactorDelivery', { hide: true} ));
            colDefs.push(gridService2.colDef.createNumber('baseLoadForDelivery','Baseload','baseLoadForDelivery', { hide: true} ));
            colDefs.push(gridService2.colDef.createNumber('usableVolume','Tank Usable','usableVolume', { hide: true} )); 
			colDefs.push(gridService2.colDef.createDate('acquisitionDateTank ','Tank Acquisition Date','acquisitionDateTank', { hide: true} ));
            colDefs.push(gridService2.colDef.createDate('monitorInstallDate','Monitor Install Date','monitorInstallDate', { hide: true} )); 
            colDefs.push(gridService2.colDef.createNumber('deliveredUnitsToExpectedUnitsVariance','Units Variance','deliveredUnitsToExpectedUnitsVariance', { hide: true}, {decimalPlaces: 1 } ));  
			colDefs.push(gridService2.colDef.createBoolean('customerOwnedFlag', 'Customer Owned Tank', 'customerOwnedFlag', {}, { hide: true }));
			 
            return colDefs;
        }

        var nodeColDefs;

	    nodeType
		    .getDivisionColDefs(subscriberId)
		    .then(function(colDefs){
		        nodeColDefs = colDefs;
		        var gridParams = {
		        	gridOptions : {
		        		groupAggFunction : function(rows){
					   		return _.reduce(rows, function(result, row){
					   			var data = row.data; 
								
					   			result.units += data.units;
					   			result.tankSizes += data.tankSizes;
					   			result.numberOfRows = 0;

					   			if(!data.deliveryKey){
					   				result.numberOfDrops 			         += data.numberOfDrops;
					   				result.numberOfAdjustedDrops 	         += data.numberOfAdjustedDrops;
					   				result.numberOfPercentTankDeliveredDrops += data.numberOfPercentTankDeliveredDrops;

					   				result.percentageOfIdealDropSum += data.percentageOfIdealDropSum;
					   				result.adjIdealDropSum 			+= data.adjIdealDropSum;

					   				result.numberOfKFactorDrops 	+= data.numberOfKFactorDrops;
				   			 		result.kFactorSum 				+= data.kFactorSum;

				   			 		result.percentTankDeliveredSum  += data.percentTankDeliveredSum;

					   			} else {
					   				if(data.percentageOfIdealDrop || data.percentageOfIdealDrop == 0){
				   			 			result.numberOfDrops++;
				   			 			result.percentageOfIdealDropSum += data.percentageOfIdealDrop;
				   			 		}

                                    if(data.percentTankDelivered || data.percentTankDelivered == 0){
                                        result.numberOfPercentTankDeliveredDrops++;
                                        result.percentTankDeliveredSum += data.percentTankDelivered;
                                    }

				   			 		if(data.adjIdealDrop || data.adjIdealDrop == 0){
				   			 			result.numberOfAdjustedDrops++;
				   			 			result.adjIdealDropSum += data.adjIdealDrop;
				   			 		}

				   			 		if(data.kFactorVariance || data.kFactorVariance == 0){
				   			 			result.numberOfKFactorDrops++;
				   			 			result.kFactorSum += data.kFactorVariance;
					   				}
				   				}
					   			result.percentageOfIdealDrop = !!result.numberOfDrops ? result.percentageOfIdealDropSum / result.numberOfDrops : null;
					   			result.adjIdealDrop 		 = !!result.numberOfAdjustedDrops ? result.adjIdealDropSum / result.numberOfAdjustedDrops : null;
					   			result.kFactorVariance 		 = !!result.numberOfKFactorDrops ? result.kFactorSum / result.numberOfKFactorDrops : null;
					   			
                                result.percentTankDelivered = !!result.numberOfPercentTankDeliveredDrops ? result.percentTankDeliveredSum / result.numberOfPercentTankDeliveredDrops : null;
					   			return result;
				   			}, {
				   				units : 0,
                                tankSizes: 0,
				   				numberOfDrops : 0,
                                numberOfPercentTankDeliveredDrops: 0,
				   				numberOfAdjustedDrops : 0,
				   				numberOfKFactorDrops : 0,
				   				percentageOfIdealDropSum : 0,
                                percentTankDeliveredSum: 0,
				   				adjIdealDropSum : 0,
				   				kFactorSum : 0
	   						});
					    }
			        },
		            gridState     : gridState(subscriberId, $scope.widgetCode),
		            defs          : getColDefs(),
		            clicks        : getColClicks(),
		            exportOptions : { fileName: 'Delivery Performance' }
		        };
		        return gridService2.createGrid(gridParams);
		    })
		    .then(function(grid){
		        $scope.grid = grid;
		        $scope.getDeliveryPerformance();
		    });

	}
]);
