
angular
  .module('angus.controllers')
  .controller('financeStandardsCtrl', ['$scope', '$http', '$q', '_', 'productsService', 'tradeClassService', 'pricePlanService', 'promiseMonitor', FinanceStandardsCtrl])


function FinanceStandardsCtrl($scope, $http, $q, _,  productsService, tradeClassService, pricePlanService, PromiseMonitor) {
  'use strict';  

  	function Group(name, getStandardsFn, getCategoriesFn, getBriteFn, saveFn){
      var that = this;
  		this.monitor = new PromiseMonitor();
  		
  		this.name 			= name;
  		this.initialized 	= false;

  		this.getStandardsFn   = getStandardsFn;
  		this.getCategoriesFn 	= function(){
          return getCategoriesFn()
            .then(function(categories){
                that.categories = _.reduce(categories, function(result, category){
                    if(!result[category.financeStandard])
                        result[category.financeStandard] = category;
                    return result;
                  }, {});
            })
      };
  		this.getBriteFn 		  = getBriteFn;
  		this.saveFn           = saveFn;

  		return this;
  	}

  	Group.prototype.init = function() {      
  		if(this.initialized)
  			return;

  		var that = this;
  		var promise =  $q.all([
  			this.getStandardsFn(),
        this.getBriteFn(),
  			this.getCategoriesFn()
  		])
  		.spread(function(standards, items){
  			that.standards 	  = standards;
  			that.items 		    = items;
  			that.initialized  = true;
  		});  	

  		this.monitor.monitor(promise);
  	};

  	Group.prototype.isWorking = function() {
  		return this.monitor.isPending();
  	};

    Group.prototype.itemsNotInUse = function(excludeCategory){
      var that = this;

      var items =  _.filter(that.items, function(item){
          return !_.some(that.categories, function(category){
              if(category == excludeCategory)
                  return false;
              return _.some(category.abosKeys, function(abosKey){
                  return abosKey == item.id;
              });
          });
      });

      return items;
    }

  	Group.prototype.edit = function(standard){  
      var that = this;
  		
  		this.editStandard = {
  			standard : standard,
  			category : that.categories[standard.id] || {},
  			items    : that.itemsNotInUse(that.categories[standard.id])
  		};
  	};

  	Group.prototype.save = function(obj){    		
        var that = this;
  		  return this
            .saveFn($scope.subscriber.id, obj.standard.id, obj.category.abosKeys)
            .then(function(){
                var promise = that.getCategoriesFn()
                    .then(function(){
                        that.clearEditStandard();
                    });
                that.monitor.monitor(promise);
            });
  	};

  	Group.prototype.clearEditStandard = function(){
  		delete this.editStandard;
  	}

  	function getProductStandards(){
  		return productsService.getFinanceStandards();
  	}

  	function getProductCategories(){
  		return productsService.getBudgetCategories($scope.subscriberId, { sortBy: $scope.sortBy, ascending: $scope.ascending, onlyFinance : 1 })();
  	}

  	function getProducts(){
      return productsService.getBriteProducts($scope.subscriberId);
    }

    function saveProductStandard(subscriberId, standardId, abosKeys){
      return productsService.saveStandardMapping(subscriberId, standardId, abosKeys);
    }

  	function getTradeClassStandards(){
  		return tradeClassService.getFinanceStandards();
  	}

  	function getTradeClassCategories(){
  		return tradeClassService.getBudgetCategories($scope.subscriberId, { sortBy: $scope.sortBy, ascending: $scope.ascending, onlyFinance : 1 })();
  	}

  	function getTradeClasses(){
  		return tradeClassService.getTradeClasses($scope.subscriberId);
  	}

    function saveTradeClassStandard(subscriberId, standardId, abosKeys){
      return tradeClassService.saveStandardMapping(subscriberId, standardId, abosKeys);
    }

  	function getPricePlanStandards(){
  		return pricePlanService.getFinanceStandards();
  	}

  	function getPricePlanCategories(){
  		return pricePlanService.getBudgetCategories($scope.subscriberId, { sortBy: $scope.sortBy, ascending: $scope.ascending, onlyFinance : 1 })();
  	}

  	function getPricePlans(){
        return $http.get(('api/subscribers/{0}/pricingPlans/categories').format($scope.subscriberId)).then(function(result){ return result.data; });          
  	}

    function savePricePlanStandard(subscriberId, standardId, abosKeys){
      return pricePlanService.saveStandardMapping(subscriberId, standardId, abosKeys);
    }

  	$scope.groups = {
  		products 		  : new Group('Products', getProductStandards, getProductCategories, getProducts, saveProductStandard),
  		tradeClasses 	: new Group('Trade Classes', getTradeClassStandards, getTradeClassCategories, getTradeClasses, saveTradeClassStandard),
  		pricePlans 		: new Group('Price Plans', getPricePlanStandards, getPricePlanCategories, getPricePlans,savePricePlanStandard)
  	};

  	$scope.switchGroup = function(group){            
      if($scope.activeGroup == group)
        return;
      
  		$scope.activeGroup = group;
  		if(!$scope.activeGroup.initialized && !$scope.activeGroup.isWorking())
  			$scope.activeGroup.init();
  	};

  	$scope.switchGroup($scope.groups.products);
  	
}

