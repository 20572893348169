angular.module( 'angus.directives' ).directive( 'santaBudgetVar', [
  'actionViewTemplates',
  function(actionViewTemplates) {
    return {
      scope: {
        model      : "=actionModel",
        settings   : "=santaBudgetVarWidgetSettings",
        widgetCode : "="
      },
      templateUrl: actionViewTemplates.santaBudgetVarContainer
    };
  }
] );
