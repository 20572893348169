angular.module('angus.directives').directive('tmsMonitorAdmin', [ function () {
    return {
        scope: {
            closeCb: '=',
            actionModel: '=',
            windowId: '='
        },
        controller: function ($scope) {
            $scope.subscriber    = $scope.actionModel.subscriber || null;
            $scope.searchTerm    = $scope.actionModel.searchTerm || null;                 
            $scope.monitor    = $scope.actionModel.monitor || null;                
            $scope.close = function () {
                if ($scope.modalClosedCb) $scope.modalClosedCb();
                if ($scope.closeCb) $scope.closeCb();
            };


        },
        templateUrl: 'templates/widgets/tmsMonitorAdmin/html/actionViews/tmsMonitorAdminActionView.html',
        // link: function (scope, element) {
        //     if(element.data('isAdmin')) scope.actionModel.isAdmin = true;
        // }
    };
}]);