'use strict';

angular.module( 'angus.directives' ).directive( 'fdsServiceMargin', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		return {
			scope: {
				model: '=actionModel',
				settings: '=fdsServiceMarginWidgetSettings',
				widgetCode : '='
			},
			templateUrl: actionViewTemplates.fdsServiceMarginActionView
		};
	}
] );
