angular.module('angus.directives')
  .directive('pricePlanCategoriesSelect', [function () {
    'use strict';

    return {
      restrict: 'A',
      scope: {
        ngModel: '=',
        subscriberId: '='
      },
      controller: ['$scope', '$rootScope', '$http', function ($scope, $rootScope, $http) {
			$http
				.get(('api/subscribers/{0}/pricingPlans/categories')
				.format($scope.subscriberId || $rootScope.user.subscriberId))
				.then(function(pricePlanCategories){ 
					$scope.pricePlanCategories = pricePlanCategories.data; 
				});
      }],
      template: '<div multi-select-ok-wrapped="pricePlanCategories" ng-model="ngModel" display-prop="\'name\'" selection-prop="\'id\'"></div>',
    };
  }]);
