angular.module('angus.filters')
    .filter('range', [
        function() {
            return function(input, min, max) {
                min = parseInt(min); //Make string input int
                max = parseInt(max);
                for (var i = min; i <= max; i++)
                    input.push(i);
                return input;
            };
        }
    ])
.filter('cuteDate', ['$filter', function($filter) {
    return function(input) {
        return $filter('date')(input, "yyyy-MM-dd HH:mm:ss");
    }
}]);