angular.module( 'angus.controllers' ).controller( 'tankUtilCtrl', [
	'$scope', '$rootScope',  'fluentRest', 'actionViews', 'lastDeliveryDateService', 'hierarchyService', 'moment',
	function( $scope, $rootScope, fluentRest, actionViews, lastDeliveryDateService, hierarchyService, moment) {
		'use strict';

		function init() {

			var divisionIds = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);

			var promise = lastDeliveryDateService
                .getLastDeliveryDateForDivisions(divisionIds)
                .then(function(lastDeliveryDate){
                	$scope.endDate 	= moment(lastDeliveryDate);
                	$scope.startDate 	= moment(lastDeliveryDate).subtract(3, 'years');

                	$scope.widget.setDefaultActionModel({
						hierarchyNodeId 	   : $scope.widget.instance.settings.hierarchyNodeId,
						minimumYearsHistory    : $scope.widget.instance.settings.minimumYearsHistory.value,
						includeCustomerOwned   : $scope.widget.instance.settings.includeCustomerOwned.value,
						includeInactive   	   : $scope.widget.instance.settings.includeInactive.value,
						productIds 			   : $scope.widget.instance.settings.productIds.value,
                        deliveryTypeIds        : $scope.widget.instance.settings.deliveryTypeIds,
						periodStartDate		   : $scope.startDate,
						lastDeliveryDate       : $scope.endDate
					});

                	return fluentRest
						.api()
						.subscribers($rootScope.user.subscriberId)
						.batches()
						.deliveries()
						.containers()
						.turns()
							.post({
								hierarchyNodeId : $scope.widget.instance.settings.hierarchyNodeId,
								minActivityYrs  : $scope.widget.instance.settings.minimumYearsHistory.value,
								customerOwned   : $scope.widget.instance.settings.includeCustomerOwned.value,
								inactiveTanks   : $scope.widget.instance.settings.includeInactive.value,
								productIds 		: $scope.widget.instance.settings.productIds.value,
                                deliveryTypeIds : $scope.widget.instance.settings.deliveryTypeIds,
								tankSizes 		: $scope.widget.instance.settings.tankSizes ? $scope.widget.instance.settings.tankSizes.value : [],
								startDate 		: $scope.startDate.format(),
								endDate 		: $scope.endDate.format()
							});
                })
				.then(function(turns){
					$scope.turns = turns;
				});

			if($scope.widget) $scope.widget.promises.monitor(promise);
			return promise;
		}

        $scope.widget.setDefaultInitialization(init);

		$scope.getDateTooltipText= function(){
			var start =  $scope.startDate ? $scope.startDate.format('L') : '';
			var end =  $scope.endDate ? $scope.endDate.format('L') : '';
			return start + (!!start && !!end ? ' - ' : '') + end;
		}

		$scope.openActionView = function(minTankSize, maxTankSize){
			$scope.widget.openDetailView( actionViews.getActionView( $scope.widget.code ), {
				minTankSize: minTankSize,
				maxTankSize: maxTankSize
			});
		};

		$scope.$on('widgetSettingsChanged', function() {
            init();
        });

	}
]);
