angular.module( 'angus.directives' )
	.directive( 'widgetLibrary', [
	'modalService',
		function(modalService) {
			'use strict';

			return {
				scope: {
					close: '='
				},
				controller: [ '$scope', 'dashboardsService', 'widgetLibraryService', '$q', '_', 'actionViewTemplates', '$mdToast', '$mdDialog', 'widgetPackageService',
					function( $scope, dashboardsService, widgetLibraryService, $q, _ , actionViewTemplates, $mdToast, $mdDialog, widgetPackageService) {
						function init() {


							$scope.dataLoaded = false;

							$scope.dashboards = dashboardsService.dashboards;
							var activeDashboard =  dashboardsService.getActiveDashboard();

							$q.all([
								widgetPackageService.get(false),
								widgetLibraryService.getWidgets()
							]).then(function(results){
								if(results[0].length == 0)
									$scope.widgetPackages = null;
								else{
									$scope.widgetPackages =_.reduce(results[0], function(result, widgetPackage){
										widgetPackage.widgets =[];
										result[widgetPackage.widgetPackageKey] = widgetPackage;
										return result;
									}, {});

									$scope.widgets = results[1];
									_.forEach($scope.widgets, function(widget){
										_.forEach(widget.widgetPackageKeys, function(widgetPackageKey){
											$scope.widgetPackages[widgetPackageKey].widgets.push(widget);
										});
										widget.dashboardToAddTo = activeDashboard.id;
									});
								}

								$scope.dataLoaded = true;
							});

							$scope.alerts=[];
						}

						init();


						function addToDashboard(widget, view){
							var dash = _.find($scope.dashboards, function(dashboard){
								return dashboard.id == widget.dashboardToAddTo;
							});

							return dashboardsService
								.addWidget( dash, widget, view)
								.then(function(widget){
									 var toast = $mdToast
									 	.simple()
      									.textContent('{0} was added to {1}.'.format(widget.name, dash.name))
      									.action('UNDO')
                        .highlightAction(true)
      									.hideDelay(5000);

      								$mdToast
      									.show(toast)
      									.then(function(response) {
      										if ( response == 'ok' )
      											return dashboardsService.deleteWidget( dash, widget.instance.id);
    									});
								});
						}


						$scope.addDashboard = function(event) {
						    $mdDialog
						    	.show({
							      	controller: function($scope, $mdDialog){
							      		$scope.add = function(name) {
	    									$mdDialog.hide(name);
	  									};

	  									$scope.cancel = function() {
	    									$mdDialog.cancel();
	  									};
							      	},
							      	templateUrl: 'templates/widgetLibrary/html/addDashboard.html',
							      	parent: angular.element(document.body),
							      	targetEvent: event,
							      	clickOutsideToClose:true
							    })
							    .then(function(name) {
							    	if(!name) return;
									$scope.dataLoaded = false;

									dashboardsService
										.addDashboard(name)
										.then(function( newDashboard ) {
											_.forEach($scope.widgets, function(widget){
												widget.dashboardToAddTo = newDashboard.id;
											});


					                        var toast = $mdToast
											 	.simple()
		      									.textContent('{0} was added.'.format(newDashboard.name));

		      								$mdToast.show(toast);

											$scope.dataLoaded = true;
										});
							    });
						};

						$scope.addWidget = function( widgetPackageKey, widget ) {
							$scope.dataLoaded = false;

							widgetLibraryService
								.getViews(widgetPackageKey, widget.id)
								.then(function(views){
									if(views && views.length !== 0){
										if(views.length > 1){
											var scope = {
												selectedView : null,
												views : views,
												widgetName : widget.name,
												widgetIcon : widget.icon,
												selectView : function(view){
													if(this.selectedView == view)
														this.selectedView = null;
													else
														this.selectedView = view;
												}
											};

											modalService
												.openTopBarModal(actionViewTemplates.chooseWidgetView, scope)
												.then(function(view){
													if(view)
														addToDashboard(widget, view)
															.then(function(){
																$scope.dataLoaded = true;
															});

													else
														$scope.dataLoaded = true;
												});
										} else
											addToDashboard(widget, views[0])
												.then(function(){
													$scope.dataLoaded = true;
												});
									}
									else{
										$scope.alerts.push({type: 'danger', msg : ('Can not retrieve views for <strong>{0}</strong>.').format(widget.name)});
										$scope.dataLoaded=true;
									}
								});
						};

						$scope.closeAlert = function(index) {
			                $scope.alerts.splice(index, 1);
			            };
					}
				],
				templateUrl: 'templates/widgetLibrary/html/widgetLibraryContent.html',
				link: function() {
				}
			};
		}
	] );
