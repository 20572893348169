angular.module('angus.controllers').controller('marginAnalysisSubRegSettingsCtrl', [
    '$scope', 'productsService',  'tradeClassService', 'constantsService', 'promiseMonitor', '$q',
    function($scope, productsService, tradeClassService, constantsService, promiseMonitor, $q) {

        $scope.promiseMonitor = new promiseMonitor();

        $scope.periodIndicators = constantsService.invoicePeriodIndicators.enums;
        $scope.zeroPriceSalesOptions = constantsService.includeExcludeOnly.enums;

        var promise = $q.all([productsService.getProducts($scope.subscriberId)
            .then(function(products) {
                $scope.products = products;
            }),
            tradeClassService.getTradeClasses($scope.subscriberId)            
            .then(function(classes) {
                $scope.tradeClasses = classes;
            })
        ]);

        $scope.promiseMonitor.monitor(promise);
    }
]);