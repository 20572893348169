angular.module('angus.services').factory('productsService', [
    '$http', '$rootScope', '_', '$q', 'fluentRest', 'serviceHelper',
    function($http, $rootScope, _, $q, fluentRest, serviceHelper) {

        'use strict';

        var subscriberProducts = [];
        var products = [];

        function getProducts(subscriberId) {
            if (subscriberId) {
                if (subscriberProducts[subscriberId] && subscriberProducts[subscriberId].length > 0)
                    return subscriberProducts[subscriberId];
                else {
                    return queryProducts(subscriberId)
                        .then(function(productList) {
                            subscriberProducts[subscriberId] = productList.data;
                            return subscriberProducts[subscriberId];
                        });
                }
            } else {
                if (products && products.length > 0)
                    return products;
                else {
                    return queryProducts()
                        .then(function(productList) {
                            products = productList.data;
                            return products;
                        });
                }
            }
        }

        function queryProducts(subscriberId) {
            return $http.get(('api/subscribers/{0}/reportingProducts/').format(subscriberId || $rootScope.user.subscriberId));
        }

        return {
            getProducts: function(subscriberId) {
                return $q.when(getProducts(subscriberId))
                    .then(function(products) {
                        return _.cloneDeep(products);
                    });
            },
            getBriteProducts: function(subscriberId){
                return $http.get( ('api/subscribers/{0}/products/').format(subscriberId) ).then(function(res){ return _.cloneDeep(res.data); });
            },
            getPaygoProducts: function(subscriberId){
              var params = {isPaygo: true};
              return fluentRest.api().subscribers(subscriberId || $rootScope.user.subscriberId).products().get(params)
                .then(function(res){
                  return _.cloneDeep(res);
                });
                //return $http.get(('api/subscribers/{0}/products/').format(subscriberId)).then(function(res){return _.cloneDeep(res.data); });
            },
            formatProductsList: function(productIds, subscriberId) {
                var result = '';
                var productList = products;

                if(!_.isArray(productIds))
                    productIds = [productIds.toString()];

                if (subscriberId)
                    productList = subscriberProducts[subscriberId];

                if (productIds.length > 0) {
                    _.forEach(productList, function(product) {
                        if (_.includes(productIds, product.id.toString()) || _.includes(productIds, product.id))
                            result += product.name + ', ';
                    });
                }

                return result.substring(0, result.length - 2);
            },
            getBudgetCategories: function (subscriberId, params) {
                var req = fluentRest.api().subscribers(subscriberId).budgets().categories().products();
                return serviceHelper.listOrPage(req, params);
            },
            getFinanceStandards: function () {
                return fluentRest.api().finance().standards().products().get();
            },
            saveStandardMapping : function(subscriberId, standardId, abosKeys){
                return fluentRest.api().subscribers(subscriberId).finance().standards().products(standardId).post({ abosKeys : abosKeys});
            },
            saveBudgetCategory : function(subscriberId, category){
                var req = fluentRest.api().subscribers(subscriberId).budgets().categories().products(category.id);
                return !!category.id  ? req.put(category) : req.post(category);
            },
            deleteBudgetCategory : function(subscriberId, categoryId){
                return fluentRest.api().subscribers(subscriberId).budgets().categories().products(categoryId).delete();
            },
            getProductCategories: function(subscriberId) {
                return $http.get(('api/subscribers/{0}/products/productCategories').format(subscriberId))
                    .then(function (res){
                        return res.data;
                    })
            }
        };
    }
]);
