angular.module('angus.services').service('logoService', [
    '$http',
    function($http) {

        function queryLogo(subscriberId) {
            return $http.get(('api/subscribers/{0}/logo').format(subscriberId));
        }

        this.getLogo = function(subscriberId) {
            return queryLogo(subscriberId)
                .then(function(res){
                    return res.data;
                });
        }
    }
]);