angular.module('angus.services').service('budgetsService', ['$rootScope', '$q', '_', 'moment', 'fluentRest', 'productsService', 'tradeClassService', 'pricePlanService', 'serviceHelper', BudgetService]);
	


function BudgetService($rootScope, $q, _,  moment, fluentRest, productsService, tradeClassService, pricePlanService, serviceHelper) {	
	'use strict';

	var fns = {		
		get : function(subscriberId, params) {
			var req = fluentRest.api().subscribers(subscriberId).budgets();     	   									
			return serviceHelper.listOrPage(req, params);			
		},
		save : function(subscriberId, budget){		
			var url = fluentRest.api().subscribers(subscriberId).budgets(budget.id);
	        return (budget.id) ? url.put(budget) : url.post(budget);
		},
		delete : function(subscriberId, budgetId){
			return fluentRest.api().subscribers(subscriberId).budgets(budgetId).delete();
		},
		copy : function(subscriberId, budgetId, fiscalYear){
			return fluentRest.api().subscribers(subscriberId).budgets(budgetId).copy().post( { fiscalYear: fiscalYear});
		},
		generateFromActuals: function(subscriberId, hierarchyNodeId, fromYear, toYear, productCategoryIds, tradeClassCategoryIds, pricePlanCategoryIds, percentChange, rollup){

			 var data = {
                hierarchyNodeId       : hierarchyNodeId,
                productCategoryIds    : productCategoryIds,
                tradeClassCategoryIds : tradeClassCategoryIds,
                pricePlanCategoryIds  : pricePlanCategoryIds,
                toYear                : toYear,
                fromYear              : fromYear,
                percentChange         : percentChange,
                rollup				  : rollup
            };

			return fluentRest.api().subscribers(subscriberId).budgets().generate().actuals().post(data);
		},
		getExistingYears: function(subscriberId) {
			return fluentRest.api().subscribers(subscriberId).budgets().years().get();                                			
		},
		getProducts: function(subscriberId, params){
			subscriberId = subscriberId || $rootScope.user.subscriberId;
			return productsService.getBudgetCategories(subscriberId, params)();
		},

		getTradeClasses: function(subscriberId, params){
			subscriberId = subscriberId || $rootScope.user.subscriberId;
			return tradeClassService.getBudgetCategories(subscriberId, params)();
		},
		getPricePlans: function(subscriberId, params){
			subscriberId = subscriberId || $rootScope.user.subscriberId;
			return pricePlanService.getBudgetCategories(subscriberId, params)();
		},		
		getYears : function(){
			return _.range(moment().subtract(2, 'years').year(), moment().add(5, 'years').year(), 1);
		}
	};
	

	return function(subscriberId){		
		return subscriberId ? serviceHelper.transformFnsForSubscriberId(subscriberId, fns) : fns;
	};
}
