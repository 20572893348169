angular.module('angus.services').service('gridService', 
	['$filter', 'moment', '_', 
	function($filter, moment, _) {
		'use strict';

		function renderer(value, decimalPlaces){			
			var display = $filter('number')(Math.abs(value), decimalPlaces);

			function negativeCheck(newDisplay){				
				var d = newDisplay || display;
				return (value < 0) ? '('+ d +')' : d;			
			}

			return {
				prepend : function(prefix){
					return display || display === 0 ? negativeCheck(prefix + display) : '';
				},
				append : function(suffix){
					return display || display === 0 ? negativeCheck(display + suffix) : '';
				},
				toString : function(){
					return negativeCheck();
				}
			};			
		}

    function createNumberRenderer(renderOpts){
  		return function(params) { 	
  			return renderer(params.value, renderOpts.decimalPlaces).toString();
  		}
    }

    function createCurrrencyRenderer(renderOpts){
  		return function(params) { 		
  			return renderer(params.value, renderOpts.decimalPlaces).prepend(renderOpts.symbol || '$');
  		}
    }

    function createPercentRenderer(renderOpts){
  		return function(params) { 	
  			return renderer(params.value, renderOpts.decimalPlaces).append(renderOpts.symbol || '%');
  		}
    }

    function creaeteBooleanRenderer(params) { 	
		switch(booleanCleaner(params.value)){
			case true:
				return '<i class="fa fa-check text-success"></i>';				
			default : 
				return '';
		}  /// Make check icon after you find out if it works			
	}

  function booleanComparator(value1, value2) {
      var value1Cleaned = booleanCleaner(value1);
      var value2Cleaned = booleanCleaner(value2);
      var value1Ordinal = value1Cleaned===true ? 0 : (value1Cleaned===false ? 1 : 2);
      var value2Ordinal = value2Cleaned===true ? 0 : (value2Cleaned===false ? 1 : 2);
      return value1Ordinal - value2Ordinal;
  }
  function booleanCleaner(value) {
      if (value==='true' || value===true || value===1) {
          return true;
      } else if (value==='false' || value===false || value===0) {
          return false;
      } else {
          return null;
      }
  }

  function booleanFilterCellRenderer(params) {
      var valueCleaned = booleanCleaner(params.value);
      if (valueCleaned===true) {
          //this is the unicode for tick character
          return '<i class="fa fa-check text-success"></i> Yes';
      } else if (valueCleaned === false) {
          //this is the unicode for cross character
          return '&#10006; No';
      } else {
          return '(empty)';
      }
  }



	function createDateRenderer(format) { 	
		return function(params){
			return params.value ? moment.utc(params.value).format(format) : '';				
		};
	}

  function createDateFilterRenderer(format) {   
    return function(params){
      return params.value ? moment.utc(params.value).format(format) : '';       
    };
  }

    function createNumberClass(renderOpts){
  		return function(params) { 
  			var classes = ['text-right'];			
  			if ( params.value < 0 ) 	
  				classes.push('text-danger');
        else if(renderOpts.colorPositive && params.value > 0){
          classes.push('text-success');
        }
  			return classes;
  		}
    }
		
		function createColDef (colId, headerName, field, colOpts){
			var colDef = _.assign({}, colOpts);
			colDef.colId = colId;
			colDef.headerName = headerName;
			colDef.field = field;			
			return colDef;
		}

		return {
			createCol : createColDef,
			createNumberCol : function(colId, headerName, field, colOpts, renderOpts){
		        renderOpts           = renderOpts || {};
		        colOpts              = _.assign({}, colOpts);					
		        colOpts.cellRenderer = createNumberRenderer(renderOpts);					
		        colOpts.cellClass    = createNumberClass(renderOpts);
		        colOpts.filter       = 'number';
		        return createColDef(colId, headerName, field, colOpts);
			},
			createCurrenyCol : function(colId, headerName, field, colOpts, renderOpts){
		        renderOpts           = renderOpts || {};

		        colOpts              = _.assign({}, colOpts);					
		        colOpts.cellRenderer = createCurrrencyRenderer(renderOpts);					
		        colOpts.cellClass    = createNumberClass(renderOpts);
		        colOpts.filter       = 'number';
				return createColDef(colId, headerName, field, colOpts);
			},
			createPercentageCol : function(colId, headerName, field, colOpts, renderOpts){
		        renderOpts           = renderOpts || {};

		        colOpts              = _.assign({}, colOpts);					
		        colOpts.cellRenderer = createPercentRenderer(renderOpts);					
		        colOpts.cellClass    = createNumberClass(renderOpts);
		        colOpts.filter       = 'number';
				return createColDef(colId, headerName, field, colOpts);
			},

			createDateCol : function(colId, headerName, field, format, colOpts){
				colOpts = _.assign({}, colOpts);					
				colOpts.cellRenderer = createDateRenderer(format);			
		        colOpts.filter       = 'set';     
		        colOpts.filterParams = {newRowsAction: 'keep', cellRenderer: createDateFilterRenderer(format)};						
				colOpts.cellClass = function(){
					return  ['text-right'];
				};
				return createColDef(colId, headerName, field, colOpts);
			},

			createBooleanCol : function(colId, headerName, field, colOpts){
		        colOpts              = _.assign({}, colOpts);					
		        colOpts.cellRenderer = creaeteBooleanRenderer;		
		        colOpts.comparator = booleanComparator;		
		        colOpts.filter       = 'set';			
		        colOpts.filterParams = {newRowsAction: 'keep', cellRenderer: booleanFilterCellRenderer};	
				colOpts.cellClass = function(){
					return  ['text-center'];
				};
				return createColDef(colId, headerName, field, colOpts);
			},
			createPreGroupedGridOptions : function(customOptions){				
				var gridOptions = {
		            columnDefs               : null,        
		            rowData                  : null,
		            rowSelection             : 'single',
		            suppressRowClickSelection: false,            
		            enableFilter             : true,   
		            rowsAlreadyGrouped       : true,
		            enableColResize          : true,
		            enableSorting            : true,
		            rowHeight                : 20,    
		            showToolPanel            : true,  
		            toolPanelSuppressGroups   : true,
		            suppressSizeToFit        : true,    
		            icons                    : {
		              menu                     : '<i class="fa fa-bars clickable"/>',
		              filter                   : '<i class="fa fa-filter clickable"/>',
		              sortAscending            : '<i class="fa fa-sort-amount-asc clickable"/>',
		              sortDescending           : '<i class="fa fa-sort-amount-desc clickable"/>',
		              groupExpanded            : '<i class="fa fa-caret-down clickable"/>',
		              groupContracted          : '<i class="fa fa-caret-right clickable"/>',
		              columnGroupOpened        : '<i class="fa fa-minus-square-o clickable"/>',
		              columnGroupClosed        : '<i class="fa fa-plus-square-o clickable"/>',
		            }
		        };

		        _.assign(gridOptions, customOptions);

		        return gridOptions;
			},
			createGridOptions : function(customOptions){
				var gridOptions = {
		            columnDefs: null,
		            groupHideGroupColumns : true,
		            rowData: null,
		            rowSelection: 'single',
		            enableFilter: true,
		            toolPanelSuppressValues : true,
		            groupHeaders: true,                     
		            enableColResize: true,
		            enableSorting: true,
		            rowHeight: 20,    
		            showToolPanel : true,  
		            suppressSizeToFit : true,     
		                   
		            icons: {
		                menu: '<i class="fa fa-bars"/>',
		                filter: '<i class="fa fa-filter"/>',
		                sortAscending: '<i class="fa fa-sort-amount-asc clickable"/>',
		                sortDescending: '<i class="fa fa-sort-amount-desc clickable"/>',
		                groupExpanded: '<i class="fa fa-caret-down clickable"/>',
		                groupContracted: '<i class="fa fa-caret-right clickable"/>',
		                columnGroupOpened        : '<i class="fa fa-minus-square-o clickable"/>',
		                columnGroupClosed        : '<i class="fa fa-plus-square-o clickable"/>',
		            },                      
		            groupUseEntireRow: false                       
		        };
		     	_.assign(gridOptions, customOptions);

		     	return gridOptions;
		 	},
		 	createColDefs : function(){
				var colDefs = [];				
				this.col = function(colId, headerName, field, customOptions){
					var options = {};
					_.assign(options, customOptions);					
					colDefs.push(createColDef(colId, headerName, field, options));
					return this;
				};

				this.numberCol = function(colId, headerName, field, decimalPlaces, customOptions){
					var options = {
						cellRenderer : createNumberRenderer({decimalPlaces: decimalPlaces}),					
						cellClass 	 : createNumberClass
					};
					_.assign(options, customOptions);					
					colDefs.push(createColDef(colId, headerName, field, options));
					return this;
				};

				this.currenyCol = function(colId, headerName, field, customOptions){
					var options = {
						cellRenderer : createCurrrencyRenderer,					
						cellClass 	 : createNumberClass
					};
					_.assign(options, customOptions);											
					colDefs.push(createColDef(colId, headerName, field, options));
					return this;
				};
				this.percentageCol = function(colId, headerName, field, customOptions){
					var options = {
						cellRenderer : createPercentRenderer,					
						cellClass 	 : createNumberClass
					};
					_.assign(options, customOptions);												
					colDefs.push(createColDef(colId, headerName, field, options));
					return this;
				};
				this.dateCol = function(colId, headerName, field, format, customOptions){
					var options = {
						cellRenderer : createDateRenderer(format),					
						cellClass 	 : function(){
							return  ['text-right'];
						}
					};
					_.assign(options, customOptions);										
					colDefs.push(createColDef(colId, headerName, field, options));
					return this;
				};

				this.booleanCol = function(colId, headerName, field, customOptions){
					var options = {
						cellRenderer : creaeteBooleanRenderer,					
						cellClass 	 : function(){
							return  ['text-right'];
						}
					};
					_.assign(options, customOptions);					
					colDefs.push(createColDef(colId, headerName, field, options));
					return this;
				};
				this.groupCol = function(colId, headerName, field, customOptions){
					var options = {  
						cellRenderer : {  
							renderer : 'group',  innerRenderer : function innerCellRenderer(params) { 
	                        	return  params.data.isHierarchy ? ('<i class="fa fa-sitemap"></i> '+ params.data.name) : params.data.name; 
	                    	} 
	                	} 
	                };
	                _.assign(options, customOptions);				

	            	colDefs.push(createColDef(colId, headerName, field, options));
	            	return this;
				};

				this.toArray = function(){
					return colDefs;
				};

				return this;
			}
		};
	
	}
]);