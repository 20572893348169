angular.module('angus.controllers').controller('appCtrl', [
    '$rootScope', '$http', 'authenticationService',
    function($rootScope, $http, authenticationService) {
        'use strict';


        this.layoutType = localStorage.getItem('angus-layout-status');

        // log user out of app after timeout inactivity
        (function () {
            // global timer of 4 hours - if there is 4 hours of inactivity, user will be logged out
            var globalTimer = 14400;

            function resetGlobalTimer() {
                globalTimer = 14400;
            }

            function signOut() {
                $http({
                    method: 'POST',
                    url: '/signOut'
                })
                .then(function() {
                    authenticationService.clearUserInfo();
                    $rootScope.user = null;

                    window.location.reload(true);
                });
            }

            function noScreenActivity() {
                if(globalTimer == 0) {
                    signOut();
                    resetGlobalTimer();
                } else {
                    globalTimer--;
                }
            }

            $('html').mousemove(function() {
                resetGlobalTimer();
            }).keypress(function() {
                resetGlobalTimer();
            });

            setInterval(function() {
                noScreenActivity();
            }, 1000);
        })();
    }
]);