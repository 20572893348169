angular.module('angus.services').service('problemDetailsService', [
    function() {
        function getErrorMessages(problemDetails)
        {
            const errorMessages = [];

            if (problemDetails.detail) {
                errorMessages.push(problemDetails.detail);
            }

            /* Capture all of the validation errors */
            const validationErrors =  problemDetails.errors;
            if (validationErrors){
                for (const property in validationErrors)
                    {
                        const validationError = validationErrors[property];
                        validationError.forEach(function(errorMessage) {
                            errorMessages.push(errorMessage);
                        });
                    }
            }

            return errorMessages;
        }

        return {
            getErrorMessages: getErrorMessages
        }
    }
]);