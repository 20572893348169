angular.module('angus.controllers').controller('adeptInquiryController', [
    '$rootScope', '$scope', '$timeout', 'adeptInquiryService', 'windowsService',
    function ($rootScope, $scope, $timeout, adeptInquiryService, windowsService) {
        // console.log("🚀 ~ file: adeptInquiryController.js:6 ~ windowsService.setCallingScopeCallBack ~ $scope.windowId:", $scope.windowId)
        windowsService.setCallingScopeCallBack($scope.windowId, function (newCallingScope) {
            /* TODO: I believe I'll need to control the adeptInquiry params from here. That way if the page is loaded and someone clicks something on another page
                    then this callback will see that they want to see something in the inquiry, and the data to be used will be specified in the newCallingScope */
            // console.log("🚀 ~ file: adeptInquiryController.js:5 ~ windowsService.setCallingScopeCallBack ~ newCallingScope:", newCallingScope);            
        });

        $scope.tabs = adeptInquiryService.tabs;

        $scope.activateTab = activateTab;

        $scope.isTabLoaded = isTabLoaded;

        $scope.$on('adeptInquiryActiveTabChanged',
            function (event, newActiveTab, params) {
                $scope.activeTab = newActiveTab
            });

        $scope.$on('$destroy', function () {
            // Remove event listener when the tab is closed
            adeptInquiryService.unloadTabs();
        });

        /* Default Executions tab as the active tab */
        adeptInquiryService.activateTab($scope.tabs.executions);

        function activateTab(tabId) {
            adeptInquiryService.activateTab(tabId);
        }

        function isTabLoaded(tabId) {
            return adeptInquiryService.isTabLoaded(tabId);
        }
    }
            
])