angular.module('angus.services').service('tmsMonitorComplianceService', [
    '$http',
    function($http) {

        function getComplianceReport(subscriberId, startDate, endDate, dealerIds, productCategoryIds, monitorReportingThresholdPercent, containerVolumeVariancePercent) {
            return queryComplianceReport(subscriberId, startDate, endDate, dealerIds, productCategoryIds, monitorReportingThresholdPercent, containerVolumeVariancePercent)
                .then(res => res.data);
        }


        function queryComplianceReport(subscriberId, startDate, endDate, dealerIds, productCategoryIds, monitorReportingThresholdPercent, containerVolumeVariancePercent) {
            const startDateString = startDate.toISOString();
            const endDateString = endDate.toISOString();

            return $http({
                url: ('api/subscribers/{0}/tms/monitorComplianceReport').format(subscriberId),
                method: 'POST',
                data: {
                    startDate: startDateString,
                    endDate: endDateString,
                    dealerIds,
                    productCategoryIds,
                    monitorReportingThresholdPercent,
                    containerVolumeVariancePercent
                }
            });
        }

        return {
            getComplianceReport: getComplianceReport
        };
    }
]);