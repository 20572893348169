'use strict';

angular.module('angus.services').service('budgetsTankGainLossService', [
    '$rootScope', '$q', '_', '$http', 'moment', 'fluentRest', 'productsService', 'serviceHelper',
    function($rootScope, $q, _, $http,  moment, fluentRest, productsService, serviceHelper) {
        var fns = {
            get: function(subscriberId, params) {
                var req = fluentRest.api().subscribers(subscriberId).budgetsTankGainLoss();

                return serviceHelper.listOrPage(req, params);
            },

            save: function(subscriberId, budget) {
                var url = fluentRest.api().subscribers(subscriberId).budgets().budgetsTankGainLoss(budget.id);

                return (budget.id) ? url.put(budget) : url.post(budget);
            },

            delete: function(subscriberId, budgetTankGainLossId) {
                return fluentRest.api().subscribers(subscriberId).budgets().budgetsTankGainLoss(budgetTankGainLossId).delete();
            },

            copy: function(subscriberId, budgetTankGainLossId, fiscalYear) {
                return fluentRest.api().subscribers(subscriberId).budgets().budgetsTankGainLoss(budgetTankGainLossId).copy().post({ fiscalYear: fiscalYear });
            },

            getExistingYearsTankGainLoss: function(subscriberId) {
                return fluentRest.api().subscribers(subscriberId).budgets().budgetsTankGainLoss().years().get();
                // return $http.get(('api/subscribers/{0}/budgetsTankGainLoss/years').format(subscriberId));
            },

            getProducts: function(subscriberId, params) {
                subscriberId = subscriberId || $rootScope.user.subscriberId;

                return productsService.getBudgetCategories(subscriberId, params)();
            },

            getYears: function() {
                return _.range(moment().subtract(2, 'years').year(), moment().add(5, 'years').year(), 1);
            }
        };

        return function(subscriberId) {
            return subscriberId ? serviceHelper.transformFnsForSubscriberId(subscriberId, fns) : fns;
        }
    }
]);