angular.module('angus.controllers').controller('snapShotsCtrl', [
    '$scope', '$rootScope', '$document', '$http', 'constantsService', 'hierarchyService', 'datePickerService', 'moment', '_', 'promiseMonitor', 'hierarchyHelperService', 'lastInvoiceDateService', 'yearMonthSelectService', '$q', 'fiscalYearService', 'fluentRest', 'productsService', 'budgetsService', 'gridState', 'gridService2',
    function($scope, $rootScope, $document, $http, constantsService, hierarchyService, datePickerService, moment, _, PromiseMonitor, hierarchyHelperService, lastInvoiceDateService, yearMonthSelectService, $q, fiscalYearService, fluentRest, productsService, budgetsService, gridState, gridService2) {
        'use strict';
        $scope.parentKeys = {};
        $scope.constants = constantsService;

        var lastCtoDate = null;

        $scope.groupBy = {
            glsr      : 'glsr',
            hierarchy : 'hierarchy'
        };

        $scope.snapShotGainLossProductCategories = _.map(constantsService.snapShotGainLossProductCategories.enums, function(category) {
            return {
                name: category.value.display,
                id: category.key,
                // selected: categosry.value.default
            };
        });

        function init() {

            $scope.divisions = $rootScope.user.hierarchy.division.nodes;
            $scope.deliveryCenters = $rootScope.user.hierarchy.deliveryCenter.nodes;
            $scope.serviceCenters = $rootScope.user.hierarchy.serviceCenter.nodes;
            $scope.inclusionOptions = constantsService.includeExcludeOnly.enums;

            $scope.selected = {};

            $scope.distanceFromLeaf = {
                division: 0,
                deliveryCenter: 0,
                serviceCenter: 0
            };

            var budgets = new budgetsService($rootScope.user.subscriberId)

            function innerCellRenderer(params) {
                return params.data.name;
            }


            function getColDefs(){


                function gainLossValueGetter(objkey, key){
                    return function (params){
                        if(!params.data || !params.data[objkey]) return '-';
                        else return params.data[objkey][key];
                    }
                }

                function gainCellStyle(params){
                    return { 'background-color': '#e8f5e9'};
                }
                function lossCellStyle(params){
                    return { 'background-color': '#ffebee'};
                }


                var gainsGroup = {
                    colId: 'gainGroup',
                    headerName: 'Gains',
                    children: [
                        //Gains
                        gridService2.colDef.createNumber('gain.budgeted', 'Gain Budget', 'gain.budgeted', { valueGetter : gainLossValueGetter('gain', 'budgeted'), cellStyle: gainCellStyle}, {decimalPlaces: 0,  center : true}),     
                        gridService2.colDef.createNumber('gain.numberOfAccounts', 'Gain MTD', 'gain.numberOfAccounts', { valueGetter : gainLossValueGetter('gain', 'numberOfAccounts'), cellStyle: gainCellStyle}, { decimalPlaces: 0,   center : true }),
                        gridService2.colDef.createPercentage('gain.percentageGoal', '% Gain Goal', 'gain.percentageGoal', {valueGetter : gainLossValueGetter('gain', 'percentageGoal'), cellStyle: gainCellStyle}, {decimalPlaces: 1,  center : true}),                 
                        gridService2.colDef.createPercentage('gain.runRatePercentage', 'Gain Run Rate %', 'gain.runRatePercentage', {valueGetter : gainLossValueGetter('gain', 'runRatePercentage'), cellStyle: gainCellStyle}, {decimalPlaces: 1,  center : true}),     
                    ]
                };
                _.forEach(gainsGroup.children, function(col){ col.suppressMenu  = true; });

                var lossesGroup = {
                    colId: 'lossesGroup',
                    headerName: 'Losses',
                    children: [
                        //Losses
                        gridService2.colDef.createNumber('loss.budgeted', 'Loss Budget', 'loss.budgeted', {valueGetter : gainLossValueGetter('loss', 'budgeted'), cellStyle: lossCellStyle}, {decimalPlaces: 0,  center : true}),     
                        gridService2.colDef.createNumber('loss.numberOfAccounts', 'Loss MTD', 'loss.numberOfAccounts', {valueGetter : gainLossValueGetter('loss', 'numberOfAccounts'), cellStyle: lossCellStyle}, {decimalPlaces: 0,  center : true}),                  
                        gridService2.colDef.createPercentage('loss.percentageGoal', '% Loss Goal', 'loss.percentageGoal', {valueGetter : gainLossValueGetter('loss', 'percentageGoal'), cellStyle: lossCellStyle}, {decimalPlaces: 1,  center : true}),                 
                        gridService2.colDef.createPercentage('loss.runRatePercentage', 'Loss Run Rate %', 'loss.runRatePercentage', {valueGetter : gainLossValueGetter('loss', 'runRatePercentage'), cellStyle: lossCellStyle}, {decimalPlaces: 1,  center : true}),     
                    ]
                };
                _.forEach(lossesGroup.children, function(col){ col.suppressMenu  = true; });

                return [
                    gridService2.colDef.createText('name', 'Group', 'name', {
                        suppressMenu: true, 
                        pinned: 'left', 
                        cellRenderer: { renderer: 'group', innerRenderer : innerCellRenderer }
                    }),       

                    gainsGroup,
                    lossesGroup,
                
                    
                    //Details
                    gridService2.colDef.createNumber('numberOfAccounts', 'Net MTD', 'numberOfAccounts', {suppressMenu : true}, {decimalPlaces: 0,  center : true}),                  
                    gridService2.colDef.createDate('changeDate', 'Change Date', 'changeDate'),
                    gridService2.colDef.createText('accountNumber', 'Account Number', 'accountNumber', { cellClass : ['center'] }),
                    gridService2.colDef.createText('customerName', 'Customer Name', 'customerName'),                    
                    gridService2.colDef.createText('tankNumber', 'Tank Number', 'tankNumber', { cellClass : ['center']})                    
                ]
            }

            var gridOptions = {                
                rowData: null,
                rowSelection: 'multiple',
                rowsAlreadyGrouped: true,
                enableColResize: true,
                enableSorting: true,
                rowHeight: 20,
                toolPanelSuppressGroups : true,
                enableFilter              : true,
                showToolPanel             : false,
                toolPanelSuppressValues   : true,
                suppressCellSelection     : true,
                suppressMenuHide          : true,
                overlayLoadingTemplate    : '<strong class = "ag-overlay-loading-center"><i class="fa fa-spinner fa-spin"></i>&nbsp;Loading...</strong>',
                overlayNoRowsTemplate     : '<strong class = "alert alert-danger center">No results found!  Please change your filters and try again.</strong>',
                icons                     : {
                  menu                     : '<i class="fa fa-bars clickable"/>',
                  filter                   : '<i class="fa fa-filter clickable"/>',
                  sortAscending            : '<i class="fa fa-sort-amount-asc clickable"/>',
                  sortDescending           : '<i class="fa fa-sort-amount-desc clickable"/>',
                  groupExpanded            : '<i class="fa fa-minus-square-o clickable"/>',
                  groupContracted          : '<i class="fa fa-plus-square-o clickable"/>',
                  columnGroupOpened        : '<i class="fa fa-minus-square-o clickable"/>',
                  columnGroupClosed        : '<i class="fa fa-plus-square-o clickable"/>',
                }                     
            };

                       



            var gridParams = { 
                gridOptions   : gridOptions,
                overwriteOptions : true,
                suppressAutoExpand : true,
                gridState     : gridState($rootScope.user.subscriberId, 'snapshotGainLoss'),                    
                defs          : getColDefs(),                
                //exportOptions : { fileName: 'Company Profile - Tanks' }
            };            

            return $q.all([
                    fiscalYearService.getFiscalYearStartMonth(),
                    getLastCtoDate(),
                    budgets.getProducts(),
                    gridService2.createGrid(gridParams),
                    retrieveFilters()
                ])
                .spread(function(startMonth, ctoDate, budgetProductCategories, grid, filters) {

                    $scope.gainLossGrid = grid;

                    $scope.products = budgetProductCategories;
                    $scope.selected.product = $scope.products && $scope.products.length > 0 ? $scope.products[0] : null;

                    $scope.budgetProductCategories = budgetProductCategories;


                    setFilters(filters.data);


                    var heatingOil = _.find($scope.budgetProductCategories, function(product){
                        return product.id == '56b4d20e8be645a863dcf012';
                    });

                    var propane = _.find($scope.budgetProductCategories, function(product){
                        return product.id == '56b4d2488be645a863dcf013';
                    });

                    //hardcoded cause Petro wants dynamic but static at the same time somehow, included fallbacks when it inevitably fails
                    $scope.pageManager.volume.filter.product = {
                        '0': heatingOil || $scope.budgetProductCategories[0] || null,
                        '1': propane    || $scope.budgetProductCategories[1] || $scope.budgetProductCategories[0] || null
                    };


                    lastCtoDate = ctoDate;
                    $scope.lastCtoDate = ctoDate;

                    var dates = {
                        periodStartDate: moment().startOf('month'),
                        lastCtoDate: lastCtoDate
                    };

                    var selectedMonth = dates.lastCtoDate.month() + 1;
                    var selectedYear = dates.lastCtoDate.year();
                    // $scope.months = yearMonthSelectService.getMonths($scope.selectedYear);
                    // Only use the months that are up to the last cto date month (i.e. don't give a month option for a month that is beyond the last month we have data for..)
                    var months = _.filter(yearMonthSelectService.getMonths(selectedYear), function(item) {
                        return item.value.number <= selectedMonth;
                    });


                    $scope.years = yearMonthSelectService.getYears(selectedYear);

                    dates.startMonth = startMonth;

                    // $scope.defaultProduct = defaultProduct;

                    _.forEach(Object.keys($scope.distanceFromLeaf), function(hierarchyType) {
                        setDistanceFromLeaf(hierarchyType);
                    });

                    $scope.filters.dates = _.cloneDeep(dates);
                    $scope.filters.selectedMonth = selectedMonth;
                    $scope.filters.selectedYear = selectedYear;
                    $scope.filters.months = months;
                    updateDates($scope.filters);

                    _.forEach(Object.keys($scope.pageManager), function(tab) {
                        $scope.pageManager[tab].filter.dates = _.cloneDeep(dates);
                        $scope.pageManager[tab].filter.selectedMonth = selectedMonth;
                        $scope.pageManager[tab].filter.selectedYear = selectedYear;
                        updateDates($scope.pageManager[tab].filter);
                        if ($scope.pageManager[tab].hierarchyType)
                            updateActiveSectionPlaceholderColumns($scope.pageManager[tab].items, $scope.pageManager[tab].hierarchyType);
                        $scope.pageManager[tab].showFilter = true;
                    });

                    $scope.activeTab = $scope.model && $scope.model.report ? $scope.pageManager[$scope.model.report] : $scope.pageManager.gainsLosses;
                    return retrieveData($scope.activeTab.uriName);

                });
        }
        $scope.promiseMonitor = new PromiseMonitor(init());

        $scope.openDivisionHierarchyModal = function(filter) {
            hierarchyService.openDivisionModal(filter.divisionNodeId)
                .then(function(nodeId) {
                    filter.divisionNodeId = nodeId;
                });
        };

        $scope.openDeliveryCenterHierarchyModal = function(filter) {
            hierarchyService.openDeliveryCenterModal(filter.deliveryCenterNodeId)
                .then(function(nodeId) {
                    filter.deliveryCenterNodeId = nodeId;
                });
        };

        $scope.openServiceCenterHierarchyModal = function(filter) {
            hierarchyService.openServiceCenterModal(filter.serviceCenterNodeId)
                .then(function(nodeId) {
                    filter.serviceCenterNodeId = nodeId;
                });
        };

        $scope.yearChanged = function(filter) {
            yearMonthSelectService
                .yearChanged(filter.selectedYear, filter.selectedMonth)
                .then(function(results) {
                    var months = results[0];
                    var month = results[1];

                    //If the user switches back to the current year then refilter the available months based
                    //on the cto date
                    if (filter.selectedYear == lastCtoDate.year()) {
                        var ctoMonth = lastCtoDate.month() + 1;
                        month = Math.min(month, ctoMonth);
                        months = _.filter(months, function(item) {
                            return item.value.number <= ctoMonth;
                        });
                    }
                    filter.months = months;

                    if (month) filter.selectedMonth = month;
                });

        };

        function setFilters(filters){
            if(!filters){
                $scope.pageManager.gainsLosses.filter.productIds =
                 _.reduce($scope.snapShotGainLossProductCategories, function(result, productCategory){
                    if(constantsService.snapShotGainLossProductCategories[productCategory.id].value.default){
                        result.push(productCategory.id);
                    }
                        return result;
                },[]);
            }
            else{
                $scope.pageManager.gainsLosses.filter.productIds =
                _.reduce($scope.snapShotGainLossProductCategories, function(result, productCategory){
                    if (_.some(filters.gainloss.productIds, function(filter){
                        return filter == productCategory.id
                    })){
                        result.push(productCategory.id);
                    }
                        return result;
                },[]);
            }
        }

        function updateDates(filter) {
            var results = yearMonthSelectService.
            calculateDates(filter.selectedYear,
                filter.selectedMonth,
                filter.dates.startMonth,
                lastCtoDate,
                false);

            filter.dates.periodStartDate = moment.utc(results.periodStartDate);
            filter.dates.lastCtoDate = moment.utc(moment.min(lastCtoDate, results.periodEndDate));
        }

        $scope.containsKeys = function(object) {
            if (!object)
                return false;

            return Object.keys(object).length > 0;
        }

        var nodes = null;

        $scope.aggRowAction = function(key, childrenNodes, uriName, collapsePropName) {
            if ($scope.containsKeys(childrenNodes)) {

                nodes = hierarchyService.convertDataForEaseOfUse($rootScope.user.hierarchy[$scope.activeTab.hierarchyType].nodes);

                var node = _.find(nodes, function(node) {
                    return node.id.toString() == key || node.id == key;
                });

                $scope.pageManager[uriName][collapsePropName ? collapsePropName : 'collapse'][key].isExpanded = !$scope.pageManager[uriName][collapsePropName ? collapsePropName : 'collapse'][key].isExpanded;

                if (!$scope.pageManager[uriName][collapsePropName ? collapsePropName : 'collapse'][key].isExpanded) {
                    _.forEach(hierarchyService.getDescendants(nodes, node), function(node) {
                        if ($scope.pageManager[uriName][collapsePropName ? collapsePropName : 'collapse'][node.id])
                            $scope.pageManager[uriName][collapsePropName ? collapsePropName : 'collapse'][node.id].isExpanded = false;
                    });
                }
            }
        };

        $scope.drillIntoDetails = function(key, childrenNodes, uriName, aggregates) {
            if (!$scope.containsKeys(childrenNodes)) {
                $scope.pageManager[uriName].showDetails = true;
                $scope.pageManager[$scope.activeTab.uriName].details = aggregates;

                retrieveDetailData(uriName, key);
            } else
                $scope.aggRowAction(key, childrenNodes, uriName);
        };

        $scope.updateActiveTab = function(tab) {
            $scope.activeTab = tab;
            retrieveData($scope.activeTab.uriName);
        };

        $scope.aggregatesExpandAll = function(isExpanded, uriName, collapsePropName) {
            _.forEach(Object.keys($scope.pageManager[uriName][collapsePropName ? collapsePropName : 'collapse']), function(key) {
                $scope.pageManager[uriName][collapsePropName ? collapsePropName : 'collapse'][key].isExpanded = isExpanded;
            });
        };

        $scope.expandAll = function(isExpanded, uriName) {
            _.forEach($scope.pageManager[uriName].detailCollapse, function(group) {
                group.isExpanded = isExpanded;
            });
        };

        $scope.applyDate = function() {
            updateDates($scope.filters);
            retrieveData($scope.activeTab.uriName);
        };

        function setDistanceFromLeaf(hType) {
            var hierarchyType = hType || $scope.activeTab.hierarchyType;

            $scope.distanceFromLeaf[hierarchyType] = hierarchyService.getDistanceFromLeaf($rootScope.user.hierarchy[hierarchyType].nodes, $scope.filters[hierarchyType + 'NodeId']);
        }

        function updateActiveSectionPlaceholderColumns(listItems, hType) {
            var items = listItems || $scope.activeTab.items;
            var hierarchyType = hType || $scope.activeTab.hierarchyType;

            _.forEach(items, function(uriKey) {
                updateHierarchyPlaceholderColumns(uriKey, hierarchyType);
            });
        }

        function updateHierarchyPlaceholderColumns(uriKey, hierarchyType) {
            _.remove($scope.pageManager[uriKey].headings, function(heading) {
                return heading.name === '';
            });

            var dis = $scope.distanceFromLeaf[hierarchyType] || 1;

            for (var i = 0; i < dis; i++) {
                $scope.pageManager[uriKey].headings.unshift({
                    name: '',
                    isHidable: false,
                    propClass: '',
                    propName: '',
                    isSortable: false
                });
            }
        }

        function updateServiceRevenueCategoryColumns() {
            _.forEach($scope.pageManager.serviceRevenue.headings, function(heading) {
                if (heading && heading.revenueColumn)
                    $scope.pageManager.serviceRevenue.headings.splice($scope.pageManager.serviceRevenue.headings.indexOf(heading), 2);
            });
            
            if($scope.pageManager.serviceRevenue.data.aggregates)
                _.forEach($scope.pageManager.serviceRevenue.data.aggregates.serviceCategories, function(category) {
                    $scope.pageManager.serviceRevenue.headings.splice($scope.pageManager.serviceRevenue.headings.length - 3, 0, {
                        name: category.name,
                        isHidable: false,
                        propClass: '',
                        propName: category.propName,
                        isSortable: false,
                        revenueColumn: true
                    });
                });
        }


        $scope.backToSummary = function(uriName) {
            $scope.pageManager[uriName].showDetails = !$scope.pageManager[uriName].showDetails;
            $scope.pageManager[uriName].noData = false;
        };

        $scope.saveFilters = function() {
            saveFilters($scope.activeTab.uriName);
        }

        function saveFilters(uriName){

            var promise =  $http({
                url: ('api/subscribers/{0}/snapShot/filters').format($rootScope.user.subscriberId),
                method: 'PUT',
                data: {
                    gainloss: {
                        productIds: $scope.pageManager.gainsLosses.filter.productIds
                    }
                }
            });

            $scope.pageManager[uriName].promiseMonitor.monitor(promise);
            return promise;
        }

        function retrieveFilters(){

            var promise =  $http({
                url: ('api/subscribers/{0}/snapShot/filters').format($rootScope.user.subscriberId),
                method: 'GET',
            });

            return promise;
        }

        $scope.retrieveData = function() {
            retrieveData($scope.activeTab.uriName, true);
        };

        function retrieveData(uriName, forceOverwrite) {
            if ($scope.activeTab.filter.selectedMonth != $scope.filters.selectedMonth || $scope.activeTab.filter.selectedYear != $scope.filters.selectedYear) {
                $scope.activeTab.filter.selectedMonth = $scope.filters.selectedMonth;
                $scope.activeTab.filter.selectedYear = $scope.filters.selectedYear;

                updateDates($scope.activeTab.filter);
                forceOverwrite = true;
            }

            if ($scope.pageManager[uriName].data && !forceOverwrite) return;


            delete $scope.pageManager[uriName].data;
            $scope.pageManager[uriName].noData = false;

            var promise = $scope.pageManager[uriName].query()
                .then(function(response) {
                    if (!response.data || Object.keys(response.data).length === 0)
                        $scope.pageManager[uriName].noData = true;

                    $scope.pageManager[uriName].data = response.data;

                    setDistanceFromLeaf();
                    updateActiveSectionPlaceholderColumns($scope.activeTab.items, $scope.activeTab.hierarchyType);

                    if ($scope.pageManager[uriName].postColUpdate)
                        $scope.pageManager[uriName].postColUpdate();
                });

            $scope.pageManager[uriName].promiseMonitor.monitor(promise);
            return promise;
        }

        function retrieveDetailData(uriName, key) {
            delete $scope.pageManager[uriName].detailData;
            $scope.pageManager[uriName].noData = false;

            var promise = $scope.pageManager[uriName].queryDetails(key)
                .then(function(response) {
                    if (Object.keys(response.data.gainsLosses).length === 0)
                        $scope.pageManager[uriName].noData = true;

                    $scope.pageManager[uriName].detailData = response.data;
                });

            $scope.pageManager[uriName].promiseMonitor.monitor(promise);
        }

        function getDefaultProduct() {
            return fluentRest
                .api()
                .subscribers($rootScope.user.subscriberId)
                .goals()
                .product()
                .get();
        }


        function getLastCtoDate() {
            return $http({
                    url: ('api/subscribers/{0}/snapShot/lastCtoDate').format($rootScope.user.subscriberId),
                    method: 'GET'
                })
                .then(function(result) {
                    return result.data ? moment.utc(result.data.date) : null;
                });
        }


        function getGainLossInfo() {

            var retVal; 
            var rowPromise = $http({
                url: ('api/subscribers/{0}/snapShot/gainsLosses').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    groupByGLSR :  $scope.pageManager.gainsLosses.filter.groupBy == $scope.groupBy.glsr ? 1 : 0,
                    divisionIds: hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.pageManager.gainsLosses.filter.divisionNodeId),
                    nodeId: $scope.pageManager.gainsLosses.filter.divisionNodeId,
                    productIds: $scope.pageManager.gainsLosses.filter.productIds,
                    startDate: $scope.pageManager.gainsLosses.filter.dates.periodStartDate.format(),
                    endDate: $scope.pageManager.gainsLosses.filter.dates.lastCtoDate.format()
                }
            }).then(function(result){
                retVal = result;
                return result.data;
            });
            
            return $scope.gainLossGrid
                .setRows(rowPromise)
                .then(function(){
                    $scope.gainLossGrid.options.api.sizeColumnsToFit();
                    return retVal;
                });
        }


        function getVolumeInfo() {

            function getVolume(productCategoryId) {
                if (!productCategoryId) return;

                var params = {
                    hs: 1,
                    h: $scope.pageManager.volume.filter.divisionNodeId,
                    fy: $scope.pageManager.volume.filter.selectedYear,
                    m: $scope.pageManager.volume.filter.selectedMonth - 1,
                    pc: [productCategoryId],
                    dtd: $scope.pageManager.volume.filter.dates.lastCtoDate.format(),
                    //product: $scope.activeProduct,                    
                };

                return fluentRest
                    .api()
                    .subscribers($rootScope.user.subscriberId)
                    .budgets()
                    .variance()
                    .get(params)
                    .then(function(response) {
                        return response || {};
                    });
            }

            return $q.all([
                    getVolume($scope.pageManager.volume.filter.product && $scope.pageManager.volume.filter.product[0] ? $scope.pageManager.volume.filter.product[0].id : null),
                    getVolume($scope.pageManager.volume.filter.product && $scope.pageManager.volume.filter.product[1] ? $scope.pageManager.volume.filter.product[1].id : null),
                ])
                .spread(function(budgetVar1, budgetVar2) {
                    return {
                        data: [budgetVar1 || {}, budgetVar2 || {}]
                    };
                });
        }

        function getDeliveryInfo() {

            return $http({
                url: ('api/subscribers/{0}/snapShot/delivery').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    deliveryCenterIds: hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.deliveryCenter.nodes, $scope.pageManager.delivery.filter.deliveryCenterNodeId),
                    nodeId: $scope.pageManager.delivery.filter.deliveryCenterNodeId,
                    startDate: $scope.pageManager.delivery.filter.dates.periodStartDate.format(),
                    endDate: $scope.pageManager.delivery.filter.dates.lastCtoDate.format(),
                    productId: $scope.selected.product.id,
                    propane: $scope.pageManager.delivery.filter.propane
                }
            });
        }

        function getServiceInfo() {

            return $http({
                url: ('api/subscribers/{0}/snapShot/service').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    serviceCenterIds: hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.serviceCenter.nodes, $scope.pageManager.service.filter.serviceCenterNodeId),
                    nodeId: $scope.pageManager.service.filter.serviceCenterNodeId,
                    startDate: $scope.pageManager.service.filter.dates.periodStartDate.format(),
                    endDate: $scope.pageManager.service.filter.dates.lastCtoDate.format(),
                }
            });
        }

        function getDegreeDayInfo() {
            return $http({
                url: ('api/subscribers/{0}/snapShot/degreeDay').format($rootScope.user.subscriberId),
                method: 'GET',
                params: {
                    nodeId: $scope.pageManager.degreeDays.filter.divisionNodeId,
                    startDate: $scope.pageManager.degreeDays.filter.dates.periodStartDate.format(),
                    endDate: $scope.pageManager.degreeDays.filter.dates.lastCtoDate.format(),
                }
            });
        }

        function getGainLossDetailInfo(hierarchyNodeId) {
            return $http({
                url: ('api/subscribers/{0}/snapShot/gainsLosses/details').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    divisionIds: hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, hierarchyNodeId),
                    startDate: $scope.pageManager.gainsLosses.filter.dates.periodStartDate.format(),
                    endDate: $scope.pageManager.gainsLosses.filter.dates.lastCtoDate.format(),
                    productIds: $scope.pageManager.gainsLosses.filter.productIds,
                }
            });
        }

        function getServiceRevenueInfo() {
            return $http({
                url: ('api/subscribers/{0}/snapShot/serviceRevenue').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    divisionIds: hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.pageManager.serviceRevenue.filter.divisionNodeId),
                    nodeId: $scope.pageManager.serviceRevenue.filter.divisionNodeId,
                    startDate: $scope.pageManager.serviceRevenue.filter.dates.periodStartDate.format(),
                    endDate: $scope.pageManager.serviceRevenue.filter.dates.lastCtoDate.format(),
                }
            });
        }
        // end retrieval functions

        // begin page setup
        var headingCenterClass = 'text-center clickable';
        var headingLeftCenter = 'text-center clickable colDividerLeft';
        var headingRightCenter = 'text-center clickable colDividerRight';
        var headingLeftRightCenter = 'text-center clickable colDividerLeft colDividerRight';

        $scope.propaneCollapse = 'propaneCollapse';
        $scope.defaultCollapse = 'defaultCollapse';


        $scope.filters = {
            divisionNodeId: $rootScope.user.hierarchy.division.nodeId,
            deliveryCenterNodeId: $rootScope.user.hierarchy.deliveryCenter.nodeId,
            serviceCenterNodeId: $rootScope.user.hierarchy.serviceCenter.nodeId,
            productIds: [],
            gainLoss: [],
            delivery: [],
            volume: [],
            service: [],
            serviceRevenue: [],
            degreeDays: [],
            gainLossDetails: []
        };

        $scope.pageManager = {
            gainsLosses: {
                filter: {
                    divisionNodeId: $rootScope.user.hierarchy.division.nodeId,
                    groupBy : $scope.groupBy.hierarchy
                },
                collapse: {},
                uriName: 'gainsLosses',
                collapseProp: 'nodeId',
                query: getGainLossInfo,
                save: saveFilters,
                promiseMonitor: new PromiseMonitor(),
                showDetails: false,
                noData: true,
                items: ['gainsLosses', ],
                hierarchyType: 'division',
                title: 'Gains & Losses',
                queryDetails: getGainLossDetailInfo,
                headings: [{
                    name: '',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'divisionName',
                    isSortable: false
                }, {
                    name: 'Budget',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: constantsService.snapshotGoalTypes.gainLoss + '.gains',
                    isSortable: false
                }, {
                    name: 'MTD',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'gainsMonthToDate',
                    isSortable: false
                }, {
                    name: '% Goal',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'gainsGoalPercentage',
                    isSortable: false
                }, {
                    name: 'Run Rate',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'gainsRunRate',
                    isSortable: false
                }, {
                    name: 'Budget',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: constantsService.snapshotGoalTypes.gainLoss + '.losses',
                    isSortable: false
                }, {
                    name: 'MTD',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'lossesMonthToDate',
                    isSortable: false
                }, {
                    name: '% Goal',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'lossesGoalPercentage',
                    isSortable: false
                }, {
                    name: 'Run Rate',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'lossesRunRate',
                    isSortable: false
                }, {
                    name: 'Net',
                    isHidable: false,
                    propClass: headingLeftRightCenter,
                    propName: 'gainLossNet',
                    isSortable: false
                }],
                aggregateHeadings: [{
                    name: 'Gain/Loss',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: 'gainLoss',
                    isSortable: false
                }, {
                    name: 'Source/Reason',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'sourceReason',
                    isSortable: false
                }, {
                    name: 'Budget',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'budgeted',
                    isSortable: false
                }, {
                    name: 'MTD',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'accounts',
                    isSortable: false
                },{
                    name: '% Goal',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'percentageGoal',
                    isSortable: false
                }, {
                    name: 'Change Date',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: 'changeDate',
                    isSortable: false
                }, {
                    name: 'Account Number',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'accountNumber',
                    isSortable: false
                }, {
                    name: 'Customer Name',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'customerName',
                    isSortable: false
                }, {
                    name: 'Tank Number',
                    isHidable: false,
                    propClass: headingRightCenter,
                    propName: 'tankNumber',
                    isSortable: false
                }],
            },
            delivery: {
                filter: {
                    deliveryCenterNodeId: $rootScope.user.hierarchy.deliveryCenter.nodeId,
                    propane : false
                },
                collapse: {},
                collapseProp: 'nodeId',
                uriName: 'delivery',
                query: getDeliveryInfo,
                promiseMonitor: new PromiseMonitor(),
                noData: true,
                items: ['delivery'],
                hierarchyType: 'deliveryCenter',
                title: 'Delivery',
                headings: [{
                    name: '',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'deliveryCenterName',
                    isSortable: false,
                }, {
                    name: 'Budget',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: 'dropSizeBudget',
                    isSortable: false
                }, {
                    name: 'MTD',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'dropSizeMonthToDate',
                    isSortable: false
                }, {
                    name: '% Goal',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'dropSizeGoalPercent',
                    isSortable: false
                }, {
                    name: 'Budget',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: 'runoutsBudget',
                    isSortable: false
                }, {
                    name: '# MTD',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'accountRunoutsNumberMonthToDate',
                    isSortable: false
                }, {
                    name: '% Goal',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'accountRunoutsGoalPercent',
                    isSortable: false
                }, {
                    name: 'Budget',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: 'gallonsPerHourBudget',
                    isSortable: false
                }, {
                    name: 'MTD',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'gallonsPerHourMonthToDate',
                    isSortable: false
                }, {
                    name: '% Goal',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'gallonsPerHourGoalPercent',
                    isSortable: false
                }, {
                    name: 'Budget',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: 'stopsPerDayBudget',
                    isSortable: false
                }, {
                    name: 'MTD',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'stopsPerDayMonthToDate',
                    isSortable: false
                }, {
                    name: '% Goal',
                    isHidable: false,
                    propClass: headingRightCenter,
                    propName: 'stopsPerDayGoalPercent',
                    isSortable: false
                }],
            },
            volume: {
                filter: {
                    divisionNodeId: $rootScope.user.hierarchy.division.nodeId,
                    product: {}
                },
                defaultCollapse: {},
                propaneCollapse: {},
                collapseProp: 'divisionId',
                uriName: 'volume',
                query: getVolumeInfo,
                promiseMonitor: new PromiseMonitor(),
                noData: true,
                items: ['volume', ],
                hierarchyType: 'division',
                title: 'Volume',
                headings: [{
                    name: '',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'deliveryCenterName',
                    isSortable: false
                }, {
                    name: 'Budget',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: 'budget',
                    isSortable: false
                }, {
                    name: 'MTD',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'monthToDate',
                    isSortable: false
                }, {
                    name: '% Goal',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'goalPercentage',
                    isSortable: false
                }, {
                    name: 'Est EOM',
                    isHidable: false,
                    propClass: headingRightCenter,
                    propName: 'endOfMonth',
                    isSortable: false
                }],

            },
            service: {
                filter: {
                    serviceCenterNodeId: $rootScope.user.hierarchy.serviceCenter.nodeId,
                },
                collapse: {},
                collapseProp: 'nodeId',
                uriName: 'service',
                query: getServiceInfo,
                promiseMonitor: new PromiseMonitor(),
                noData: true,
                items: ['service'],
                hierarchyType: 'serviceCenter',
                title: 'Service',
                headings: [{
                    name: '',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'serviceCenterName',
                    isSortable: false
                }, {
                    name: 'Budget',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: 'serviceCallsBudget',
                    isSortable: false
                }, {
                    name: 'MTD',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'serviceCallsMonthToDate',
                    isSortable: false
                }, {
                    name: '% Goal',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'serviceCallsGoalPercent',
                    isSortable: false
                }, {
                    name: 'Service Call/Tune-Ups',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: 'serviceTuneUpsMonthToDate',
                    isSortable: false
                }, {
                    name: 'Tune-Ups',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'productionActual',
                    isSortable: false
                }, {
                    name: 'Total Tune-Ups',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'totalTuneUps',
                    isSortable: false
                }, {
                    name: 'Budget',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'serviceCallsPerDayBudget',
                    isSortable: false
                }, {
                    name: '% Goal',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'productionGoalPercent',
                    isSortable: false
                }, {
                    name: 'Total Calls',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: 'totalMonthToDate',
                    isSortable: false
                }, {
                    name: 'Budget',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: 'serviceTuneupsBudget',
                    isSortable: false
                }, {
                    name: 'MTD',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'tuneUpsMonthToDate',
                    isSortable: false
                }, {
                    name: '% Goal',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'tuneUpsGoalPercent',
                    isSortable: false
                }, {
                    name: 'Total Hours',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: 'techHours',
                    isSortable: false
                }],
            },
            serviceRevenue: {
                filter: {
                    divisionNodeId: $rootScope.user.hierarchy.division.nodeId,

                },
                collapse: {},
                collapseProp: 'nodeId',
                uriName: 'serviceRevenue',
                query: getServiceRevenueInfo,
                promiseMonitor: new PromiseMonitor(),
                noData: true,
                items: ['serviceRevenue'],
                hierarchyType: 'division',
                title: 'Service Revenue',
                postColUpdate: updateServiceRevenueCategoryColumns,
                headings: [{
                    name: '',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'serviceCenterName',
                    isSortable: false
                }, {
                    name: 'Budget $',
                    isHidable: false,
                    propClass: headingLeftRightCenter,
                    propName: 'serviceRevenueBudget',
                    isSortable: false
                }, {
                    name: 'Total',
                    isHidable: false,
                    propClass: headingRightCenter,
                    propName: 'revenueTotal',
                    isSortable: false
                }, {
                    name: 'Variance ', // + $scope.dates.lastCtoDate.format('MMM, YYYY'),
                    isHidable: false,
                    propClass: headingRightCenter,
                    propName: 'variance',
                    isSortable: false
                }, {
                    name: '% Variance ', // + $scope.dates.lastCtoDate.format('MMM, YYYY'),
                    isHidable: false,
                    propClass: headingRightCenter,
                    propName: 'percentVariance',
                    isSortable: false
                }],
            },
            degreeDays: {
                filter: {
                    divisionNodeId: $rootScope.user.hierarchy.division.nodeId,
                },
                collapse: {},
                collapseProp: 'nodeId',
                uriName: 'degreeDays',
                hierarchyType: 'division',
                query: getDegreeDayInfo,
                promiseMonitor: new PromiseMonitor(),
                noData: true,
                items: ['degreeDays'],
                title: 'Degree Day',
                headings: [{
                    name: '',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'nodeName',
                    isSortable: false
                }, {
                    name: 'Budget',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: 'goal',
                    isSortable: false
                }, {
                    name: 'MTD',
                    isHidable: false,
                    propClass: headingCenterClass,
                    propName: 'hddActual',
                    isSortable: false
                }, {
                    name: '% of Goal',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: 'percentageOfGoal',
                    isSortable: false
                }, {
                    name: 'Normal',
                    isHidable: false,
                    propClass: headingLeftCenter,
                    propName: 'normal',
                    isSortable: false
                }, {
                    name: '% Normal',
                    isHidable: false,
                    propClass: headingRightCenter,
                    propName: 'percentageNormal',
                    isSortable: false
                }],
            }
        };

        // end page setup



        $scope.$watch(function() {
            return $scope.model;
        }, function(newVal, oldVal) {

            if (newVal != oldVal) $scope.updateActiveTab($scope.pageManager[newVal.report]);

        })
    }
]);
