angular.module('angus.directives')
  .directive('tradeClassesSelect', [function () {
    'use strict';

    return {
      restrict: 'A',
      scope: {
        ngModel: '=',
        subscriberId: '='
      },
      controller: ['$scope', 'tradeClassService', function ($scope, tradeClassService) {
        tradeClassService
          .getTradeClasses($scope.subscriberId)
          .then(function (tradeClasses) {
            $scope.tradeClasses = tradeClasses;
          });
      }],
      template: '<div multi-select-ok-wrapped="tradeClasses" ng-model="ngModel" subscriber-id="subscriberId" display-prop="\'name\'" selection-prop="\'id\'"></div>',
    };
  }]);