angular.module('angus.directives')
  .directive('includeExcludeOnlySelect', [function () {
    'use strict';
    return {
      restrict: 'A',
      scope: {
        ngModel: '=',
        subscriberId: '=',
        selectDisabled: '=',
        selectChanged: '&'
      },
      controller: ['$scope', 'constantsService', function ($scope, constantsService) {
        $scope.includeExcludeOnly = constantsService.includeExcludeOnly.enums;
      }],
      template: `
        <select 
          class="form-control" 
          data-ng-model="ngModel"  
          ng-change="selectChanged()"
          ng-disabled="selectDisabled"
          data-ng-options="options.key as options.value.display for options in includeExcludeOnly">
        </select>`,
    };
  }]);