angular.module('angus.directives')
    .directive("progressBar", [
        function() {
            return {
                scope: {
                    progressBar: "="
                },
                link: function(scope, element, attrs) {
                    element.addClass("progress-bar");

                    scope.$watch(function() {
                        return scope.progressBar;
                    }, function(newVal) {
                        element.animate({
                            width: newVal + "%"
                        }, 250, function() {
                            element.finish();
                        });
                    });
                }
            };
        }
    ]);