angular.module('angus.directives').directive('tmsMonitorCompliance', [
    'actionViewTemplates', 
    function (actionViewTemplates) {
        return {
            scope: {
                model: "=actionModel",
                settings: "=",
                widgetCode: "=",
            },
            templateUrl: actionViewTemplates.tmsMonitorComplianceReportContainer
        }
    }
])