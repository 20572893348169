'use strict';

angular.module('angus.controllers').controller('subRegCargasIntegrationSettingsCtrl', [
    '$scope', '$http', '_', 'Paging', 'modalService', 'actionViewTemplates',
    function($scope, $http, _, Paging, modalService, actionViewTemplates) {

        $scope.pageSize = $scope.pageSize || 25;
        $scope.paging = new Paging($scope.pageSize);
        $scope.sortProp = 'name';
        $scope.sortDir = 'asc';

        function getSourceDeliveryTypes(pageNumber) {
            $scope.paging.getPage( ('api/subscribers/{0}/sourceDeliveryTypes').format($scope.subscriberId), pageNumber )
                            .then(function(sourceDeliveryTypes) {
                                $scope.sourceDeliveryTypes = _.forEach(sourceDeliveryTypes.items, function(sourceDeliveryType) {
									return sourceDeliveryType;
                                });
                            });
        }

        // retrieve the currently saved source delivery type from database (source-system-integration-setting-select sp)
        function getCurrentSourceDeliveryTypes(pageNumber) {
            var params = {
                sortDir: $scope.sortDir,
                sortProp: $scope.sortProp,
                sourceDeliveryTypeName: $scope.sourceDeliveryTypeName
            };

            $scope.paging.getPage( ('api/subscribers/{0}/postingCodes/{1}').format($scope.subscriberId, $scope.activeSourceSystem.id), pageNumber, params )
                            .then(function(sourceDeliveryTypes) {
                                $scope.sourceDeliveryTypeName = sourceDeliveryTypes.extraInfo.sourceDeliveryTypeName;
                                $scope.currentSourceDeliveryType = sourceDeliveryTypes.extraInfo.sourceDeliveryTypeName;
                            });
        }

        $scope.getPage = function(pageNumber) {
            getSourceDeliveryTypes(pageNumber);
            getCurrentSourceDeliveryTypes(pageNumber);
        };

        getSourceDeliveryTypes();
        getCurrentSourceDeliveryTypes();


		// save user selected new source delivery type to database (source-system-integration-setting-update sp)
		$scope.saveNewSourceDeliveryType = function () {
            var confirmScope = {
                title: 'Confirm Save?',
                message: 'Are you sure you want to save a new Source Delivery Type, and override the currently saved one?'
            };

		    var params = {
		        sourceDeliveryTypeName: $scope.sourceDeliveryTypeName
            };

            return modalService
                .openTopBarModal(actionViewTemplates.confirmDialog, confirmScope)
                .then(function (response) {
                    if(response) {
                        $scope.updating = true;

                        $http.post( ('api/subscribers/{0}/postingCodes/{1}').format($scope.subscriberId, $scope.activeSourceSystem.id), params)
                            .then(function(res) {
                                $scope.currentSourceDeliveryType = res.config.data.sourceDeliveryTypeName;
                                $scope.updating = false;
                                return res.data;
                            });
                    }
                });
		};
    }
]);
