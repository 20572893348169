angular.module('angus.controllers').controller('consumerUsersCtrl', [
    '$scope', '$rootScope', '$http', '$window', '$location', '$timeout', 'modalService', 'hierarchyHelperService', 'hierarchyService', 'actionViewTemplates', '_', 'authenticationService', 'fluentRest', 'Paging', '$q', 'constantsService', 'promiseMonitor',
    function($scope, $rootScope, $http, $window, $location, $timeout, modalService, hierarchyHelperService, hierarchyService, actionViewTemplates, _, authenticationService, fluentRest, Paging, $q, constantsService, promiseMonitor) {

        'use strict';
        $scope.filterOptions = [{
            name: "All",
            value: null,
        },{
            name: "Active",
            value: false,
        },{
            name: "Locked",
            value: true,
        }];
        $scope.filterSelect = null;
        // $scope.getUsers= function(pageNumber){
        $scope.getUsers = function () {

            var params = {
                sortBy: $scope.sortBy,
                ascending: $scope.ascending,
                searchTerm: $scope.searchTerm,
                dealerId: $scope.dealerId
            };

            // var url = fluentRest.api().subscribers($scope.subscriberId).users().toString();
            // return $scope.paging.getPage( url , pageNumber, params ).then( function( users ) { $scope.users = users.items; $scope.usersLoaded = true; });
            return $http({
                url: 'api/subscribers/{0}/consumerUser'.format($scope.subscriberId),
                method: 'GET',
                params: params
            })
                .then(function (result) {
                    if($scope.filterSelect != null) {
                        $scope.users = result.data.filter(function (data) {
                            return data.isLocked === $scope.filterSelect;
                        });
                    }else{
                        $scope.users = result.data;
                    }
                    $scope.usersLoaded = true;
                });
        }

        function init() {
            $scope.isLoading = false;

            $scope.sortBy = 'username';
            $scope.ascending = true;
            // $scope.paging = new Paging(25);

            $scope.getUsers();
        }

        init();


        $scope.openUserModal = function (user) {
            user = _.cloneDeep(user); 
            if (user) {
                user.password = undefined;
                user.confirmPassword = undefined;
            }

            var scope = {
                editUser: user !== undefined ? user : {},
                alerts: [],
                monitor: new promiseMonitor(),
                closeAlert: function (index) {
                    this.alerts.splice(index, 1);
                },
                saveUser: function (user) {
                    var that = this;
                    if (user) {
                        var promise = $http({
                            url: 'api/subscribers/{0}/consumerUser/{1}'.format($scope.subscriberId, user.userId),
                            method: 'PUT',
                            data: user
                        })
                            .then(function (result) {
                                that.close(user);
                            });

                        promise
                            .catch(function (err) {
                                var alerts = _.map(err, function (e) {
                                    return {
                                        type: 'danger',
                                        msg: '<strong>Error!</strong> ' + e.msg
                                    };
                                });

                                [].push.apply(that.alerts, alerts);
                            });

                        that.monitor.monitor(promise);

                        return promise;
                    } else
                        that.close();
                },


            };

            modalService
                .openTopBarModal(actionViewTemplates.subRegEditConsumerUser, scope)
                .then(function () {
                    $scope.getUsers();
                })
                .finally(function () {
                    $scope.isLoading = false;
                });
        };


        $scope.toggleLock = function (user) {
            $http.put(('api/subscribers/{0}/consumerUser/{1}/lock').format($scope.subscriberId, user.userId), {
                lockUser: user.isLocked ? 0 : 1
            })
                .success(function (isLocked) {
                    $scope.getUsers();
                });
        };
        $scope.deleteUser = function (user) {
            user = _.cloneDeep(user);
            if (user) {
                user.password = undefined;
                user.confirmPassword = undefined;
                user.userName = user.username;
                user.firstName = user.name;
                user.lastName = "";
            }

            var scope = {
                user : user,
                alerts: [],
                monitor: new promiseMonitor(),
                closeAlert: function (index) {
                    this.alerts.splice(index, 1);
                },
                confirm: function (user) {
                    var that = this;
                    if (user) {
                        var promise = $http({
                            url: '/consumer/delete',
                            method: 'POST',
                            data: {username: user.username}
                        })
                            .then(function (result) {
                                that.close(user);
                            });

                        promise
                            .catch(function (err) {
                                var alerts = _.map(err, function (e) {
                                    return {
                                        type: 'danger',
                                        msg: '<strong>Error!</strong> ' + e.msg
                                    };
                                });

                                [].push.apply(that.alerts, alerts);
                            });

                        that.monitor.monitor(promise);

                        return promise;
                    } else
                        that.close();
                },


            };

            modalService
                .openTopBarModal(actionViewTemplates.subRegConfirmDeleteUser, scope)
                .then(function () {
                    $scope.getUsers();
                })
                .finally(function () {
                    $scope.isLoading = false;
                });

        }
    }
]);
