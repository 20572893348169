'use strict';
angular.module( 'angus.directives' ).directive( 'regex', function() {
	return {
		require: 'ngModel',
		link: function( scope, elem, attrs, ctrl ) {
			scope.$watch( function() {
				return ctrl.$modelValue;
			}, function( value ) {
				//Does not test regex if the watch value is "empty"...
				//If your form requires an input use the required attribute!
				ctrl.$setValidity( 'regex', ctrl.$isEmpty( value ) || ( new RegExp( attrs.regex ) ).test( value ) );
			} );
		}
	};
} ).directive( 'regexView', function() {
	return {
		require: 'ngModel',
		link: function( scope, elem, attrs, ctrl ) {
			scope.$watch( function() {
				return ctrl.$viewValue;
			}, function( value ) {
				//Does not test regex if the watch value is "empty"...
				//If your form requires an input use the required attribute!
				ctrl.$setValidity( 'regex', ctrl.$isEmpty( value ) || ( new RegExp( attrs.regexView ) ).test( value ) );
			} );
		}
	};
} );