angular.module('angus.controllers').controller('hddTrackerReportContentCtrl', [
    '$http', '$q', '$rootScope', '$scope', '$timeout', '_', 'actionViewTemplates', 'constantsService', 'degreeDayService', 'modalService',  'promiseMonitor', 
    function($http, $q, $rootScope, $scope, $timeout, _, actionViewTemplates, constantsService, degreeDayService, modalService, PromiseMonitor) {

        'use strict';
        $scope.promiseMonitor = new PromiseMonitor();

        $scope.averageperiods = [
            { id: 3, name: '3-Yr Avg' },
            { id: 5, name: '5-Yr Avg' },
            { id: 10, name: '10-Yr Avg' }
        ];
        $scope.months = constantsService.months.enums;

        degreeDayService.getDegreeDaySources($rootScope.user.subscriberId)
            .then(function(sources) {
                $scope.sources = sources;
            });


        function getHDDTrackerData(oilStartMonthKey, getPrevious) {

            $scope.JSONsources = $scope.settings.sources ? JSON.parse($scope.settings.sources) : null;

            var promise = $scope.activeItem.paging.getPage(
                ('api/subscribers/{0}/degreeDays/variance/details').format($rootScope.user.subscriberId),
                $scope.activeItem.paging.currentPage, {
                    degreeDayHeaderKey: $scope.JSONsources.degreeDayHeaderKey,
                    yearsHistory: $scope.activeItem.filter.hddAverageResult,
                    oilStartMonthKey: oilStartMonthKey,
                    selectedSeasonMonth: $scope.settings.selectedSeasonMonth || 7,
                    degreeDayHeaderSources: $scope.activeItem.filter.degreeDayHeaderSources,
                    getPrevious: getPrevious
                });

            $scope.promiseMonitor.monitor(promise);

            return promise;
        }

        function init() {
            var filter = {
                hddAverageResult: $scope.settings.hddAverageResult.value,
                selectedSeasonMonth: $scope.settings.selectedSeasonMonth,
                degreeDayHeaderSources: JSON.parse($scope.settings.sources).degreeDaySourceTypeKey,
                degreeDayHeaderKey: JSON.parse($scope.settings.sources).degreeDayHeaderKey,
                table: []
            };

            return filter;
        }

        $scope.$watch(function() {
            return $scope.activeItem;
        }, function(newVal) {

            if (newVal != $scope.listReport) {
                if (!newVal.filter)
                    $scope.activeItem.filter = init();

                if (!$scope.activeItem.report) {
                    $q.all([
                        getHDDTrackerData()
                            .then(function(degreeDayYearDetails) {
                                $scope.activeItem.report = degreeDayYearDetails;
                                if (!$scope.activeItem.report || Object.keys($scope.activeItem.report).length === 0)
                                    $scope.noDefaultDatesFlag = true;
                            }),

                        getHDDTrackerData(new Date().getMonth() + 1, true)
                            .then(function(degreeDayYearDetails) {
                                $scope.activeItem.report1 = degreeDayYearDetails;
                                if (!$scope.activeItem.report1 || Object.keys($scope.activeItem.report1).length === 0)
                                    $scope.noDefaultDatesFlag = true;
                            })
                    ]);
                }
                $scope.reinit = false;
            }
        });

        $scope.saveReportFilters = function() {
            if (!$scope.activeItem.filter.id)
                $scope.openSaveReportFilterModal();
            else {
                $scope.savingFilter = true;

                // testing for saved filter problem

                // saved filter does not apply all 3 options, only does the middle option

                $http.put(('api/subscribers/{0}/reports/{1}/{2}')
                    .format($rootScope.user.subscriberId, $scope.widgetCode, $scope.activeItem.filter.id), $scope.activeItem.filter)
                    .success(function() {
                        $timeout(function() {
                            $scope.savingFilter = false;
                        }, 250);
                    });
            }
        };

        $scope.openSaveReportFilterModal = function() {
            var scope = {
                validationUrl: ('api/subscribers/{0}/reports/' + $scope.widgetCode + '/nameAvailable?name={1}'),
                validationKey: 'name',
                modalTitle: 'HDD Tracker Report'
            };

            modalService
                .openTopBarModal(actionViewTemplates.saveReportFilter, scope)
                .then(function(name) {
                    if (name) {
                        $scope.isLoading = true;
                        var previousId = $scope.activeItem.filter.id;
                        $scope.activeItem.filter.name = name;
                        $scope.activeItem.filter.id = null;

                        $http.post(('api/subscribers/{0}/reports/{1}').format($rootScope.user.subscriberId, $scope.widgetCode), $scope.activeItem.filter)
                            .success(function(response) {
                                $scope.activeItem.filter.id = response.id;

                                if (previousId)
                                    $scope.renameActiveReportTab($scope.activeItem.filter.name);
                                else
                                    $scope.addNewOpenReport($scope.activeItem.filter, true, false);

                                $scope.isLoading = false;
                            });
                    }
                });
        };

        $scope.queryHDDTrackerReport = function() {
            $scope.noDefaultDatesFlag = false;

            $q.all([
                getHDDTrackerData()
                    .then(function(degreeDayYearDetails) {
                        $scope.activeItem.report = degreeDayYearDetails;
                        if (!$scope.activeItem.report || Object.keys($scope.activeItem.report).length === 0)
                            $scope.noDefaultDatesFlag = true;
                    }),

                getHDDTrackerData(new Date().getMonth() + 1, true)
                    .then(function(degreeDayYearDetails) {
                        $scope.activeItem.report1 = degreeDayYearDetails;
                        if (!$scope.activeItem.report1 || Object.keys($scope.activeItem.report1).length === 0)
                            $scope.noDefaultDatesFlag = true;
                    })
            ]);
        };

        $scope.hddReports = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        $scope.year = new Date().getFullYear();
        $scope.Math = window.Math;

        var headingCenterClass = 'text-center';

        $scope.tableHeadings = [{
            name: 'Month',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'YearMonthKey'
        }, {
            name: 'Actual HDD (thru month)',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'Actual'
        }, {
            name: 'Normal HDD (thru month)',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'Normal'
        }, {
            name: '% Variance from Normal',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'VariancePercent'
        }, {
            name: 'HDD Prior Year',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'SameMonthPriorYearActual'
        }];

        $scope.tableHeadingsForRollingMonth = [{
            name: 'Month',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'MonthKey'
        }, {
            name: 'Actual HDD (thru month)',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'Actual'
        }, {
            name: 'Normal HDD (thru month)',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'Normal'
        }, {
            name: '% Variance from Normal',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'VariancePercent'
        }, {
            name: 'HDD Prior Year',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'SameMonthPriorYearActual'
        }, {
            name: 'Prior Year MTD',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'SameMonthPriorYearActualToDate',
        }];
    }
]);
