angular.module('angus.controllers').controller('custProfAnalysisSettingsCtrl', [
    '$scope', 'hierarchyService', 'constantsService',
    function($scope, hierarchyService, constantsService) {
        'use strict';

        $scope.ieoOptions = constantsService.includeExcludeOnly.enums;

        $scope.openDivisionHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };
    }
]);
