angular.module('angus.controllers').controller('arSummaryFuelPakSettingsCtrl', [
    '$scope', 'hierarchyService',
    function($scope, hierarchyService) {
		'use strict'
        $scope.openDivisionHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };
    }
]);
