angular.module('angus.services')
    .service('indicatorService', function() {

        function getIndicatorId(avg, value, lowerIsBetter) {
            var upperThreshold = lowerIsBetter ? (avg * 1.2) : (avg * .9),
                lowerThreshold = lowerIsBetter ? (avg * 1.1) : (avg * .8);

            if ((lowerIsBetter && value <= lowerThreshold) || (!lowerIsBetter && value >= upperThreshold))
                return 1;
            else if (((lowerIsBetter && value > upperThreshold) || (!lowerIsBetter && value < lowerThreshold)))
                return 3;
            else
                return 2;
        }

        return {
            getIndicatorId: function(avg, value, lowerIsBetter) {
                return getIndicatorId(avg, value, lowerIsBetter);
            }
        };
    });