angular.module( 'angus.controllers' ).controller( 'subRegMappingCtrl', [
	'$scope', '$q', 'actionViewTemplates','$http',
	function( $scope, $q, actionViewTemplates,$http) {

		'use strict';

		$scope.mappingTabs = [
			{
				name : 'Products',
				url : actionViewTemplates.subRegProducts
			},
			{
				name : 'Trade Classes',
				url : actionViewTemplates.subRegTradeClass
			},
			{
				name : 'Price Plans',
				url : actionViewTemplates.subRegPricePlan
			},
			{
				name : 'Delivery Types',
				url : actionViewTemplates.subRegDeliveryType
			},
			{
				name : 'Call Reasons',
				url : actionViewTemplates.subRegCallReason
			}	
		];
		
		$scope.selected = {sourceSystemKey : null};
		
		function getSourceSystem() {
            if ($scope.selected.sourceSystemKey && $scope.selected.sourceSystemKey.length > 0)
                return $q.when ($scope.selected.sourceSystemKey);
            else   
        		return $http
               		.get (('api/subscribers/{0}/sourceSystem').format($scope.subscriberId))
        	   		.then(function(sourceSystemKeys){
        	   			$scope.sourcesystem = { sourceSystemKeys:sourceSystemKeys.data };						
					});	
        		
       	}

		getSourceSystem();

		$scope.switchTab = function(mappingTab){
			$scope.activeMappingTab = mappingTab;
		};
	}
] );