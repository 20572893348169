angular.module('angus.controllers').controller('sideMenuController', [
    '$rootScope', '$scope', 'actionViewTemplates', 'constantsService', 'dashboardsService', 'logoService', 'modalService', 'restrictedAccessService', 'sideMenuService', 'subscriberService', 'userService', 'widgetPackageService',
    function ($rootScope, $scope, actionViewTemplates, constantsService, dashboardsService, logoService, modalService, restrictedAccessService, sideMenuService, subscriberService, userService, widgetPackageService) {

        /* Side Menu Status */
        $scope.isSideMenuOpen = sideMenuService.isSideMenuOpen();



        /* Toggles */
        $scope.toggles = {
            adept: false,
            admin: false,
            ams: false,
            dashboards: true,
            impersonation: false,
            imports: false,
            snapshots: false,
        };


        /*** Main Menu Access ***/
        const isSysAdmin = $rootScope.user && $rootScope.user.isSysAdmin;

        if (isSysAdmin) {
            $scope.recentImpersonations = $rootScope.user.profile.recentImpersonations;
        }

        $scope.access = {
            /* Admin */
            admin: false,
            sysAdmin: isSysAdmin, //Since this property is used in a lot of places wil l revisit for refactor
            /* ADEPT */
            adept: false,
            adeptRuns: isSysAdmin,

            /* AMS */
            ams: isSysAdmin, /* Right now the AMS menu is only available to SysAdmins. We'll need to expand on this later if we're giving access to any items under TMS to clients */
            monitorInventory: isSysAdmin,
            monitorCompliance: isSysAdmin,

            /* Impersonation */
            impersonation: $scope.recentImpersonations && $scope.recentImpersonations.length > 0,

            /* Imports */
            imports: false,
            closingQuotes: isSysAdmin,
            timesheet: false,
            monitorInventoryImport: isSysAdmin,

            /* Snaphot */
            snapshots: false,

        }

        /* Import menu is available if the user has access to any of the imports */
        function setImportsAccess() {
            $scope.access.imports = $scope.access.closingQuotes || $scope.access.timesheet;
        }

        setImportsAccess();


        /* ADEPT Menu is available if the user has access to any of the items within the menu */
        function setAdeptAccess() {
            $scope.access.adept = $scope.access.adeptRuns
        }

        setAdeptAccess();


        /* Admin or sysadmin */
        restrictedAccessService.getAccess()
            .then(function (access) {
                $scope.access.admin = _.some(access.adminAccess, function (value) {
                    return value;
                }) || access.role === "sysadmin";
            });

        /* Timesheet - Right now it's for One Client Only. Not ideal, but this will work for now...*/
        subscriberService.get($rootScope.user.subscriberId)
            .then(function (subscriber) {
                $scope.access.timesheet = subscriber.code === "C-00530"; 

                setImportsAccess();
            });

        
        /* Access based on Widget Packages */
        widgetPackageService.get(false, $rootScope.user.securityGroupId)
            .then(function (userWidgetPackages) {

                const widgetPackageKeys = userWidgetPackages.map(function(widgetPackage) {
                    return widgetPackage.widgetPackageKey;
                });
                
                /* Snaphot - Access is granted if they have access to the Snapshot widget package
                     NOTE: From what I can tell no Subscribers have this WidgetPackage in Production. If a Subscriber is given access to the Package than this may not work still. 
                     The Services Get method only returns Widget Packages that are associated with the Subscriber, and the Package must have at least one widget that the Security Group has access too. 
                     This Package has no widgets under it, so that logic will never work. */
                $scope.access.snapshots = widgetPackageKeys.includes(constantsService.widgetPackages.snapShots);

               
            });

        /* Access based on Widgets */            
        widgetPackageService.getWidgets()
            .then(function(widgets){
                /* ADEPT Runs - Access is granted if they are a sysadmin or have access to an ADEPT widget */
                if (isSysAdmin) {
                    $scope.access.adeptRuns = true;
                }
                else {
                    $scope.access.adeptRuns = widgets.find(function(widget) {
                        return widget.widgetPackageKeys.includes(constantsService.widgetPackages.adept);
                    });
                }
                setAdeptAccess();
            });


        $scope.closeSideMenu = function () {
            sideMenuService.close();
        }

        $scope.toggleItem = function (item) {
            $scope.toggles[item] = !$scope.toggles[item];
        }

        $scope.$watch(
            function () {
                return sideMenuService.isSideMenuOpen();
            },
            function (newSideMenuStatus, oldSideMenuStatus) {
                $scope.isSideMenuOpen = newSideMenuStatus;
            }
        );

        $scope.$on('sideMenuStatusChanged', function (event, status) {
            $scope.issideMenuOpen = status;
            $scope.$apply();
        });


        /* Logo */
        logoService.getLogo($rootScope.user.subscriberId)
            .then(function (logo) {
                if (logo) {
                    $scope.logoImg = logo;
                }
            });


        /* Dashboards */
        $scope.dashboards = dashboardsService.dashboards;
        $scope.activeDashboard = dashboardsService.activeDashboard;

        $scope.$watch(
            function () {
                return dashboardsService.dashboards;
            },
            function (newDashboards, oldDashboards) {
                $scope.dashboards = newDashboards;
            }
        );

        $scope.$watch(
            function () {
                return dashboardsService.activeDashboard;
            },
            function (newActiveDashboard, oldActiveDashboard) {
                $scope.activeDashboard = newActiveDashboard;
            }
        );

        $scope.renameDashboard = function (dashboard) {

            sideMenuService.close();

            const scope = {
                name: dashboard.name
            };

            const template = 'templates/authenticated/layout/sideMenu/renameDashboard.html';

            modalService.openTopBarModal(template, scope)
                .then(function (newName) {
                    if (newName) {
                        dashboardsService.renameDashboard(dashboard, newName);
                    }
                });
        };

        $scope.deleteDashboard = function (dashboard) {
            sideMenuService.close();

            var scope = {
                title: 'Confirm Deletion',
                message: `Delete dashboard "${dashboard.name}"? This will result in the loss of all widgets on that dashboard.`
            };

            modalService.openTopBarModal(actionViewTemplates.confirmDialog, scope)
                .then(function (deleteConfirmed) {
                    if (deleteConfirmed) {
                        dashboardsService.deleteDashboard(dashboard);
                    }
                })
        }

        $scope.switchDashboards = function (dashboard) {
            sideMenuService.close();

            dashboardsService.switchDashboard(dashboard)
                .then(function (dashboard) {
                    $scope.activeDashboard = dashboard;
                });
        }




        /* Admin */
        $scope.openAdmin = function () {
            sideMenuService.close();

            var scope = {
                widgetName: 'Administration',
                widgetIcon: 'gear',
                widget: {
                    instance: {
                        id: 'subReg'
                    }
                },
                model: {
                    subscriberId: $rootScope.user.subscriberId,
                    itemName: 'Users',
                    status: constants.registrationStatus.live,
                    adminMode: true
                }
            };

            modalService
                .openActionView(actionViewTemplates.subRegDefault, scope, 'subReg');
        };


        /* User Impersonation */
        $scope.impersonateUser = function (user) {
            sideMenuService.close();

            userService.impersonateUser(user.subscriber.id, user.userId);
        }

        /* ADEPT */
        $scope.openAdeptInquiry = function () {

            sideMenuService.close();

            const scope = {
                widgetName: 'ADEPT Runs',
                widgetIcon: 'search',
                widgetId: 'AdeptInquiry',
                widgetCode: 'AdeptInquiry',
                settings: {},
                model: {
                    test: 'Hello'
                }
            }

            modalService.openActionView(actionViewTemplates.adeptInquiryDefault, scope, 'AdeptInquiry');
        }


        /* AMS */
        function openAmsReport(name, icon, code, templateUrl) {
            sideMenuService.close();

            var scope = {
                widgetId: code,
                widgetName: name,
                widgetIcon: icon,
                widgetCode: code,
                model: {
                    subscriberId: $rootScope.user.role != "sysadmin" ? $rootScope.user.subscriberId : null
                }
            };

            modalService.openActionView(templateUrl, scope, code);
        } 

        $scope.openMonitorInventory = function () {
            openAmsReport('Monitor Inventory', 'television', 'inventoryManagementController', actionViewTemplates.inventoryManagementController);
        };

        $scope.openMonitorComplianceReport = function () {
            openAmsReport('Monitor Compliance', 'table', 'tmsMonitorCompliance', actionViewTemplates.tmsMonitorComplianceReportContainer);
        }; 
 
        $scope.openActiveMonitorReport = function () {
            openAmsReport('Active Monitors', 'table', 'activeTankMonitorsController', actionViewTemplates.activeTankMonitors);
        };

        $scope.openWeeklyTankMonitoringUsageReport = function () {
            openAmsReport('Weekly Tank Monitoring Usage', 'calendar', 'tmsWeeklyTankMonitoringUsage', actionViewTemplates.tmsWeeklyTankMonitoringUsage);
        };




        /* Imports */
        function openImport(name, icon, code, templateUrl, gridHeaders, fileImportDataType, subscriber) {
            sideMenuService.close();

            var scope = {
                widgetId: code,
                widgetName: name,
                widgetIcon: icon,
                code,
                model: {
                    subscriberId: $rootScope.user.subscriberId,
                    gridHeaders,
                    dataTypeKey: fileImportDataType.dataTypeKey,
                    templateURL: fileImportDataType.templateURL,
                    product: fileImportDataType.product,
                    operation: fileImportDataType.operation,
                    subscriber: subscriber ? subscriber : $rootScope.user.subscriberId,
                }
            };

            modalService.openActionView(templateUrl, scope, code);
        }

        $scope.openImportTimeSheet = function () {
            openImport('Timesheet Import', 'cloud-upload', 'timesheetImport', actionViewTemplates.importFile, constantsService.importHeaders,
                constantsService.fileImportDataTypes.timesheetRobison);
        };

        $scope.openImportClosingQuote = function () {
            openImport('Closing Quotes Import', 'cloud-upload', 'closingQuotesImport', actionViewTemplates.importFile, constantsService.importHeaders,
                constantsService.fileImportDataTypes.closingQuotes, 'common');
        };

        $scope.openImportMonitorInventory = function() {
            openImport('Monitor Inventory Import', 'cloud-upload', 'monitorInventoryimport', actionViewTemplates.importFile, constantsService.importHeaders,
            constantsService.fileImportDataTypes.monitorInventory, 'common');
        };

        /* ADMIN */
        $scope.openUserReport = function () {

            sideMenuService.close();

            const scope = {
                widgetName: 'Users Report',
                widgetIcon: 'search',
                widgetId: 'UsersReport',
                widgetCode: 'UsersReport',
                settings: {},
                model: {
                }
            }

            modalService.openActionView(actionViewTemplates.usersReportDefault, scope, 'UsersReport');
        }
        /* Snapshot */
        $scope.loadSnapShot = function(report) {
            sideMenuService.close();

            var scope = {
                widgetId: report,
                widgetName: 'Snapshots',
                widgetIcon: 'camera',
                widgetCode: 'snapShots',
                model: { report: report }
            };

            modalService
                .openActionView(actionViewTemplates.snapShotsDefault, scope, 'snapShot');
        };
    }]);