
angular
  .module('angus.controllers')
  .controller('productCategoryCtrl', ['$scope', '$q', '_', 'moment', 'productsService', 'promiseMonitor', 'modalService', ProductCategoryCtrl])
  .controller('tradeClassCategoryCtrl', ['$scope', '$q', '_', 'moment', 'tradeClassService', 'promiseMonitor', 'modalService', TradeClassCategoryCtrl]);



function TradeClassCategoryCtrl($scope, $q, _, moment, tradeClassService, PromiseMonitor, modalService) {
  'use strict';

  $scope.alerts = [];
  $scope.sortBy             = 'name';
  $scope.ascending          = true;
  $scope.years              = _.range(moment().year(), moment().add(10, 'years').year(), 1);


  $scope.addAlert = function(msg){
    $scope.alerts.push({
        type: 'danger',
        msg: '<strong>Error!</strong> ' + msg
    });
  };

  $scope.closeAlert = function(index) {
        $scope.alerts.splice(index, 1);
    };

  $scope.sortTradeClassCategories = function(sortBy){
    if($scope.sortBy == sortBy) $scope.ascending = !$scope.ascending;
    else $scope.sortBy = sortBy;

    return $scope.getTradeClassCategories();
  }


  $scope.getTradeClassCategories = function (pageNumber) {
    return tradeClassService
      .getBudgetCategories($scope.subscriberId, { sortBy: $scope.sortBy, ascending: $scope.ascending, searchTerm : $scope.searchTerm })(pageNumber || 1, $scope.paging)
      .spread(function (tradeClassCategories, paging) {
        $scope.tradeClassCategories = tradeClassCategories;
        $scope.paging = paging;
      });
  }

  $scope.editTradeClassCategory = function(tradeClassCategory){
    $scope.alerts = [];
    $scope.tradeClassCategory = { id : tradeClassCategory.id, name : tradeClassCategory.name,  abosKeys : tradeClassCategory.abosKeys};
  }

  $scope.addTradeClassCategory = function(){
    $scope.alerts = [];
    $scope.tradeClassCategory = { name : '',  abosKeys : []};
  }
  $scope.copyTradeClassCategory = function(tradeClassCategory){
    $scope.alerts = [];
    $scope.tradeClassCategory = { name : tradeClassCategory.name,  abosKeys : tradeClassCategory.abosKeys};
  }

  $scope.back = function(){
    $scope.alerts = [];
    $scope.tradeClassCategory = null;
  }

  $scope.saveTradeClassCategory = function(tradeClassCategory){
    tradeClassService
      .saveBudgetCategory($scope.subscriberId, tradeClassCategory)
      .then(function(){
        return $scope.getTradeClassCategories();
      })
      .then(function(){
        $scope.back();
      })
      .catch(function(err){
        $scope.addAlert(err.msg || 'Unable to save the trade class category');
      });
  }

  $scope.deleteTradeClassCategory= function(category){
      modalService
        .getConfirmation('Delete?', 'Are you sure you want to delete this budget trade class?')
        .then(function(confirmed){
          if(confirmed)
            return tradeClassService
              .deleteBudgetCategory($scope.subscriberId, category.id)
              .then(function(){
                $scope.getTradeClassCategories();
              })
              .catch(function(err){
                $scope.addAlert(err.msg || 'Unable to delete the trade class category.');
              });
        });
  };

  //Load up the products and the first page of budget groups
  var init = $q.all([
    tradeClassService.getTradeClasses($scope.subscriberId),
    $scope.getTradeClassCategories()
  ])
  .spread(function(tradeClasses){
    $scope.tradeClasses = tradeClasses;
  })
  .catch(function(err){
    console.log('getTradeClasses', err);
  });

  $scope.tradeClassCategoryInitMonitor = new PromiseMonitor(init);
}



function ProductCategoryCtrl($scope, $q, _, moment, productsService, PromiseMonitor, modalService) {
  'use strict';
  $scope.alerts = [];
  $scope.sortBy             = 'name';
  $scope.ascending          = true;
  $scope.years              = _.range(moment().year(), moment().add(10, 'years').year(), 1);


  $scope.addAlert = function(msg){
    $scope.alerts.push({
        type: 'danger',
        msg: '<strong>Error!</strong> ' + msg
    });
  };

  $scope.closeAlert = function(index) {
      $scope.alerts.splice(index, 1);
  };

  $scope.sortProductCategories = function(sortBy){
    if($scope.sortBy == sortBy) $scope.ascending = !$scope.ascending;
    else $scope.sortBy = sortBy;

    return $scope.getProductCategories();
  }


  $scope.getProductCategories = function (pageNumber) {
    return productsService
      .getBudgetCategories($scope.subscriberId, { sortBy: $scope.sortBy, ascending: $scope.ascending, searchTerm : $scope.searchTerm })(pageNumber || 1, $scope.paging)
      .spread(function (productCategories, paging) {

        $scope.productCategories = productCategories
        $scope.paging = paging;
      });
  }

  $scope.editProductCategory = function(productCategory){
    $scope.alerts = [];
    $scope.productCategory = { id : productCategory.id, name : productCategory.name,  abosKeys : productCategory.abosKeys};
  }

  $scope.addProductCategory = function(){
    $scope.alerts = [];
    $scope.productCategory = { name : '',  abosKeys : []};
  }

  $scope.copyProductCategory = function(productCategory){
    $scope.alerts = [];
    $scope.productCategory = { name : productCategory.name,  abosKeys : productCategory.abosKeys};
  }

  $scope.back = function(){
    $scope.alerts = [];
    $scope.productCategory = null;
  }

  $scope.saveProductCategory = function(productCategory){
    productsService
      .saveBudgetCategory($scope.subscriberId, productCategory)
      .then(function(){
        return $scope.getProductCategories();
      })
      .then(function(){
        $scope.back();
      })
      .catch(function(err){
        $scope.addAlert(err.msg || 'Unable to save the product category');
      });
  }

  $scope.deleteProductCategory= function(category){
      modalService
        .getConfirmation('Delete?', 'Are you sure you want to delete this budget product?')
        .then(function(confirmed){
          if(confirmed)
            return productsService
              .deleteBudgetCategory($scope.subscriberId, category.id)
              .then(function(){
                $scope.getProductCategories();
              })
              .catch(function(err){
                $scope.addAlert(err.msg || 'Unable to delete the budget product');
              });
        });
  };

  //Load up the products and the first page of budget groups
  var init = $q.all([
    productsService.getBriteProducts($scope.subscriberId),
    $scope.getProductCategories()
  ])
  .spread(function(products){
    $scope.alerts = [];
    $scope.products = products.filter(function(pro){return pro.id != '-1'});
  })
  .catch(function(err){
    console.log('getBriteProducts', err);
  });

  $scope.productCategoryInitMonitor = new PromiseMonitor(init);
}
