angular.module('angus.controllers').controller('callbackAnalysisReportContainerCtrl', [
  '$scope', '$rootScope', '$http',  '$q', '_', 'constantsService', 'drillService', 'moment', 'gridService2', 'nodeType', 'gridState', 'restrictedAccessService',
  function($scope, $rootScope, $http, $q,  _, constantsService,  drillService, moment, gridService2, nodeType, gridState, restrictedAccessService) {

    'use strict';

    var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

      $scope.dates = {
          startDate        : moment($scope.settings.startDate).utc().format('L') || moment(),
          endDate          : moment($scope.settings.endDate).utc().format('L') || moment(),
          lastInvoiceDate  : moment($scope.settings.endDate).utc().format('L')
      };

      $scope.filter =  {
        hierarchyNodeId: $scope.settings.hierarchyNodeId,
        techs: $scope.settings.techs.value || []
      };

      function getParams(){
        return {
            serviceCenterId  : $scope.filter.hierarchyNodeId,
            sd  : $scope.dates.startDate.format(),
            ed  : $scope.dates.endDate.format(),
            t   : $scope.filter.techs,
        };
      }

      $scope.closeCallbackGrid = function(){
        $scope.gridHeight = '600px';
        $scope.callbackGrid.setRows($q.when([]));
      };

      $scope.callbackGridButtons =[{
          display     : 'Close',
          action      : 'closeCallbackGrid',
          buttonClass : 'btn-default',
          scope       : $scope,
      }];


      $scope.getCallbackAnalysisByTech = function(){
        var rowPromise = $http({
                url: 'api/subscribers/{0}/service/callbacks/techs'.format($rootScope.user.subscriberId),
                method: 'POST',
                data: getParams()
          }).then(function(result){
              return result.data;
          });
        var promise = $scope.grid.setRows(rowPromise);
        return promise;
      }

      function getCallbackAnalysis(techId, chargeable, nonChargeable){
        var params                    = getParams();
        params.t                      = [techId];
        params.chargeableCallbacks    = chargeable;
        params.nonChargeableCallbacks = nonChargeable;

        var rowPromise = $http({
                url: 'api/subscribers/{0}/service/callbacks/list'.format($rootScope.user.subscriberId),
                method: 'POST',
                data: params
          }).then(function(result){
              $scope.gridHeight = '300px';
              var records = result.data;
              _.forEach(records, function(record) {
                record.percentOfMargin = (function(margin, sales) {
                  if(margin == 0 || sales == 0 || margin > sales) {
                    return 0;
                  }
                  return margin / sales * 100;
                })(record.callMargin, record.callBilled);
              });
              return records;
          });
        var promise = $scope.callbackGrid.setRows(rowPromise);
        return promise;
      }


     function getColClicks(){
          return {
              callbacks   : {
                onClick : function(row){ if(row.techId) getCallbackAnalysis(row.techId, 1, 1); },
                isNotClickable : function(params){
                  return !params.data.techId || params.data.callbacks == 0;
                }
              },
              chargeable    : {
                onClick : function(row){ if(row.techId) getCallbackAnalysis(row.techId, 1, 0); },
                isNotClickable : function(params){
                  return !params.data.techId || params.data.chargeable == 0;
                }
              },
              nonChargeable : {
                onClick : function(row){ if(row.techId) getCallbackAnalysis(row.techId, 0, 1 ); },
                isNotClickable : function(params){
                  return !params.data.techId || params.data.nonChargeable == 0;
                }
              }
          };
      }

      function getCallbackColClicks(){
          return {
              accountNumber   : {
                onClick : function(row){ drillService.openAccountInfo(row); }
              },
              locationNumber    : {
                onClick : function(row){ drillService.openServiceLocationInfo(row); }
              },
              partsMargin : {
                onClick : function(row){ if(row.id) drillService.openServicePartsMargin(row); },
                isNotClickable : function(params){
                  return !params.data.id || params.data.partsMargin == 0;
                }
              },
              laborMargin : {
                onClick : function(row){ if(row.id) drillService.openServiceLaborMargin(row); },
                isNotClickable : function(params){
                  return !params.data.id || params.data.laborMargin == 0;
                }
              },
              callMargin : {
                onClick : function(row){ if(row.id) drillService.openServiceCallMargin(row); },
                isNotClickable : function(params){
                  return !params.data.id || params.data.callMargin == 0;
                }
              }

          };
      }

      function getColDefs(){

          var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];

          colDefs.push(gridService2.colDef.createText('tech','Technician','tech' , { width : 110 }));

          colDefs.push(gridService2.colDef.createNumber('calls','Calls','calls', { width : 90 }));

          colDefs.push(gridService2.colDef.createNumber('callbacks','Callbacks','callbacks', {width : 110 }));

          colDefs.push(gridService2.colDef.createPercentage('percentCallBack','Callback Ratio','percentCallBack', {
                valueGetter : function(params){
                    return params.data.calls ? (params.data.callbacks / params.data.calls) * 100 : undefined;
                },
                width : 120
           }, { decimalPlaces: 1 }));

          colDefs.push(gridService2.colDef.createNumber('chargeable','Chargeable','chargeable', { width : 110 }));

          colDefs.push(gridService2.colDef.createPercentage('percentChargeable','Chargeable Ratio','percentChargeable', {
                valueGetter : function(params){
                    return params.data.calls ? (params.data.chargeable / params.data.calls) * 100 : undefined;
                },width : 150
           }, {decimalPlaces: 1 }));

          colDefs.push(gridService2.colDef.createNumber('nonChargeable','Non-Chargeable','nonChargeable', { width : 110 }));

          colDefs.push(gridService2.colDef.createPercentage('percentNonChargeable','Non-Chargeable Ratio','percentNonChargeable', {
                valueGetter : function(params){
                    return params.data.calls ? (params.data.nonChargeable / params.data.calls) * 100 : undefined;
                }, width : 150
           }, {decimalPlaces: 1 }));

          return colDefs;
      }

       function updateChargeable(params, chargeable) {
        if ($scope.restrictedAccess.inlineCallbackEditing) return;

        var id = params.data.id;

        return $http
            .post('api/subscribers/{0}/service/callbacks/{1}/updateChargeable'.format($rootScope.user.subscriberId, id), {
                chargeable: chargeable
            })
            .then(function() {
                $scope.getCallbackAnalysisByTech();
            })
            .catch(function(){
                params.data[params.colDef.field] = !chargeable;
            });
      }


      function getCallbackColDefs(){

          var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
          var colDefs   = [];

          colDefs.push(gridService2.colDef.createBoolean('chargeableCallback','Chargeable','chargeableCallback', {
            count : true,
            edit : true,
            hide : function(params){
                return !params.data.callback;
            },
            onChange : function(params, checked){
                if(params.data.id) updateChargeable(params, checked); }
           }, { width : 130 }));

          colDefs.push(gridService2.colDef.createDate('date','Date','date'));
          colDefs.push(gridService2.colDef.createText('workOrderNumber','Work Order Number','workOrderNumber', { cellClass : ['center']} ));
          colDefs.push(gridService2.colDef.createText('accountNumber','Account Number','accountNumber', { cellClass : ['center']} ));
          colDefs.push(gridService2.colDef.createText('customerName','Customer Name','customerName'));

          colDefs.push(gridService2.colDef.createText('locationNumber','Service Location','locationNumber', { cellClass : ['center']} ));
          colDefs.push(gridService2.colDef.createText('callReason','Call Reason','callReason' ));
          colDefs.push(gridService2.colDef.createText('priorTech','Original Tech','priorTech', { rowGroupIndex : nextIndex, hide : true} ));
          colDefs.push(gridService2.colDef.createText('primaryTech','Call Tech','primaryTech', { hide : true} ));
          colDefs.push(gridService2.colDef.createBoolean('underContractFlag','Contract Coverage','underContractFlag', { cellClass : ['center']}));

          colDefs.push(gridService2.colDef.createBoolean('flatRateFlag','Flat Rate','flatRateFlag'));


          if(!$scope.restrictedAccess.revenue)
            colDefs.push(gridService2.colDef.createNumber('laborHoursBilled','Bill Hours','laborHoursBilled', { aggFunc : 'sum'}, {decimalPlaces: 2 }));

          if(!$scope.restrictedAccess.costAndMargin)
            colDefs.push(gridService2.colDef.createCurrency('partsMargin','Parts Margin','partsMargin', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

          if(!$scope.restrictedAccess.costAndMargin)
            colDefs.push(gridService2.colDef.createCurrency('laborMargin','Labor Margin','laborMargin', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

          if(!$scope.restrictedAccess.revenue)
            colDefs.push(gridService2.colDef.createCurrency('callBilled','Revenue','callBilled', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

          if(!$scope.restrictedAccess.costAndMargin)
            colDefs.push(gridService2.colDef.createCurrency('callMargin','Call Margin','callMargin', {aggFunc : 'sum'}, {decimalPlaces: 2 }));

          if(!$scope.restrictedAccess.revenue && !$scope.restrictedAccess.costAndMargin) {
            colDefs.push(gridService2.colDef.createPercentage('percentOfMargin','% Margin','percentOfMargin', {aggFunc : 'none'}, {decimalPlaces: 1 }));
          }

          return colDefs;

      }

      var nodeColDefs;

      $q.all([
          nodeType.getServiceCenterColDefs(subscriberId),
          restrictedAccessService.getAccess(),
        ])
        .spread(function(colDefs, access){
            nodeColDefs = colDefs;
            $scope.restrictedAccess = access.restrictedAccess;
            var gridParams = {
                gridOptions  :{
                    groupAggFunction : function(rows){
                        return _.reduce(rows, function(result, row){
                            var data = row.data;

                            result.calls         += data.calls;
                            result.callbacks     += data.callbacks;
                            result.chargeable    += data.chargeable;
                            result.nonChargeable += data.nonChargeable;

                            return result;
                        }, {
                            calls : 0,
                            callbacks : 0,
                            chargeable: 0,
                            nonChargeable : 0
                        });
                    }
                },
                gridState     : gridState(subscriberId, $scope.widgetCode),
                defs          : getColDefs(),
                clicks        : getColClicks(),
                exportOptions : { fileName: 'Callback Analysis' }
            };

            var callbackGridParams = {
                gridOptions  :{
                    groupAggFunction : function(rows){
                        return _.reduce(rows, function(result, row){
                            var data = row.data;

                            result.laborHoursBilled += data.laborHoursBilled;
                            result.partsMargin      += data.partsMargin;
                            result.laborMargin      += data.laborMargin;
                            result.callBilled       += data.callBilled;
                            result.callMargin       += data.callMargin;
                            return result;
                        }, {
                            laborHoursBilled : 0,
                            partsMargin : 0,
                            laborMargin : 0,
                            callBilled : 0,
                            callMargin : 0
                        });
                    }
                },
                gridState     : gridState(subscriberId, $scope.widgetCode),
                defs          : getCallbackColDefs(),
                clicks        : getCallbackColClicks(),
                exportOptions : { fileName: 'Callback Analysis' }
            };

            return $q.all([
              gridService2.createGrid(gridParams),
              gridService2.createGrid(callbackGridParams)
            ])
            .spread(function(grid, callbackGrid){
                $scope.grid         = grid;
                $scope.callbackGrid = callbackGrid;
                $scope.getCallbackAnalysisByTech();
            });
        });
  }
]);
