angular.module('angus.controllers').controller('acctGainLossActionViewCtrl', [
    '$http', '$scope', '$rootScope', '_', 'moment', 'lastGainLossDateService', 'gridService2', 'drillService', 'fluentRest', 'datePickerService', 'hierarchyService', 'gridState', 'nodeType', 'promiseMonitor', 'tradeClassService',
    function($http, $scope, $rootScope, _, moment, lastGainLossDateService, gridService2, drillService, fluentRest, datePickerService, hierarchyService, gridState, nodeType, PromiseMonitor, tradeClassService) {

        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.dates = {
            startDate: moment.utc($scope.settings.startDate).format('L') || moment(),
            endDate: moment.utc($scope.settings.endDate).format('L') || moment()
        };

        function initFilter() {

            tradeClassService.getAllTradeClasses($scope.subscriberId)
                .then(function (tradeClasses) {
                    $scope.tradeClasses = tradeClasses;
                });
            
            return {
                hierarchyNodeId: $scope.settings.hierarchyNodeId,
                salesPersonIds: $scope.settings.salesperson ? $scope.settings.salesperson.value : [],
                isGains: $scope.model.isGains === false ? false : true,
                acquisitionSourceIds: $scope.settings.acquisitionSourceIds,
                terminationReasonIds: $scope.settings.terminationReasonIds,
                tradeClassIds: $scope.settings.tradeClassIds
            };
        }

        $scope.filter = initFilter();

        $scope.filterChanged = function(filter) {
            $scope.filter = filter || initFilter();
            $scope.getGainsLosses();

        };

        $scope.openDatePicker = function() {
            var divisionIds = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.filter.hierarchyNodeId);
            lastGainLossDateService
                .getLastContainerAcquisitionOrTermiationDate(divisionIds)
                .then(function(lastAcquisitionOrTermiationDate) {
                    return datePickerService
                        .openDatePickerModal(
                            $scope.dates.startDate,
                            $scope.dates.endDate,
                            lastAcquisitionOrTermiationDate
                        );
                })
                .then(function(resultDates) {
                    if(resultDates) {
                        $scope.dates.startDate = moment(resultDates[0]).isValid() ? resultDates[0] : resultDates[1];
                        $scope.dates.endDate = resultDates[1];
                        $scope.getGainsLosses();
                    }
                });
        };

        var wasLastCallGains;

        $scope.getGainsLosses = function() {
            if($scope.monitor && $scope.monitor.isPending()) return;

            $scope.grid.setColDefs(getColDefs($scope.filter.isGains));
            var filter = _.cloneDeep($scope.filter);

            filter.startDate = $scope.dates.startDate.format();
            filter.endDate = $scope.dates.endDate.format();
            filter.tradeClassKeys = filter.tradeClassIds;

            var switchingBetweenGainsAndLosses = ((!wasLastCallGains && $scope.filter.isGains) || (wasLastCallGains && !$scope.filter.isGains));

            let url;
            if ($scope.filter.isGains) {
                url = 'api/subscribers/{0}/customerAccounts/gains/'.format(subscriberId);
            }
            else {
                url = 'api/subscribers/{0}/customerAccounts/losses/'.format(subscriberId);
            }

            const rowPromise = $http({
                url: url,
                method: 'POST',
                data: filter
            }).then(function (res) {
                let rows = res.data;

                rows.sort(function(a, b) {
                    return (moment().month(a.month).month() - moment().month(b.month).month()) || (moment(a.date) - moment(b.date));
                });

                wasLastCallGains = $scope.filter.isGains;

                return rows;
            });

            var promise = $scope.grid.setRows(rowPromise, switchingBetweenGainsAndLosses).then(function(){
                if( $scope.filter &&  $scope.filter.gridState){
                    $scope.grid.setState( $scope.filter.gridState);
                }
            });
            $scope.monitor = new PromiseMonitor(promise);
            return promise;
        };

        function getColClicks() {
            return {
                accountNumber: {
                    onClick: function(row) {
                        drillService.openAccountInfo(row);
                    }
                },
                tank: {
                    onClick: function(row) {
                        drillService.openTankInfo(row);
                    }
                }
            };
        }

        function getColDefs(isGains) {
            var gainLoss,
                sourceReason,
                projectedLost;

            if(isGains) {
                gainLoss = 'Gain';
                projectedLost = 'Projected';
            } else {
                gainLoss = 'Loss';
                projectedLost = 'Lost';
            }

            var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];

            colDefs.push(gridService2.colDef.createNumber('accountGainLossCount', 'Number of Accounts', 'accountGainLossCount', { aggFunc: 'sum' }, {
                decimalPlaces: 0,
                center: true
            }));
            colDefs.push(gridService2.colDef.createNumber('tankGainLossCount', 'Number of Tanks', 'tankGainLossCount', { aggFunc: 'sum' }, {
                decimalPlaces: 0,
                center: true
            }));
            colDefs.push(gridService2.colDef.createDate('date', (gainLoss + ' Date'), 'date', {
                rowGroupIndex: nextIndex,
                hide: true,
                sort: 'asc'
            }));
            colDefs.push(gridService2.colDef.createDate('month', (gainLoss + ' Month'), 'month', {
                sort: 'asc',
                hide: true
            }));
            colDefs.push(gridService2.colDef.createDate('year', (gainLoss + ' Year'), 'year', {
                sort: 'asc',
                hide: true
            }));
            colDefs.push(gridService2.colDef.createText('customerName', 'Customer Name', 'customerName', {
                rowGroupIndex: nextIndex + 2,
                hide: true
            }));
            colDefs.push(gridService2.colDef.createText('accountNumber', 'Account Number', 'accountNumber', {
                cellClass: ['center'],
                rowGroupIndex: nextIndex + 1,
                hide: true
            }));

            if(isGains)
                colDefs.push(gridService2.colDef.createText('source', 'Source', 'source'));

            if(!isGains)
                colDefs.push(gridService2.colDef.createText('reason', 'Reason', 'reason'));

            colDefs.push(gridService2.colDef.createText('salesRep', 'Sales Rep', 'salesRep'));
            colDefs.push(gridService2.colDef.createText('tradeClassName', 'Trade Class', 'tradeClassName'));
            colDefs.push(gridService2.colDef.createNumber('units', (projectedLost + ' Units'), (projectedLost.toLowerCase() + 'Units'), {
                aggFunc: 'sum',
                hide: true
            }));

            if(!isGains)
                colDefs.push(gridService2.colDef.createNumber('margin', (projectedLost + ' Margin'), (projectedLost.toLowerCase() + 'Margin'), { aggFunc: 'sum' }));

            return colDefs;
        }

        var nodeColDefs;
        nodeType
            .getDivisionColDefs(subscriberId)
            .then(function(colDefs) {
                nodeColDefs = colDefs;
                var gridParams = {
                    gridState: gridState(subscriberId, $scope.widgetCode),
                    defs: getColDefs(true),
                    clicks: getColClicks(),
                    exportOptions: { fileName: 'Company Profile - Tanks' }
                };
                return gridService2.createGrid(gridParams);
            })
            .then(function(grid) {
                $scope.grid = grid;
                $scope.getGainsLosses();
            });
    }
]);