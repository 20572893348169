
angular.module('angus.directives')
    .directive('techniciansSelect', [function () {
        'use strict';

        return {
            restrict: 'A',
            scope: {
                ngModel: '=',
                subscriberId: '=',
            },
            controller: ['$scope', '$rootScope', '$http', function ($scope, $rootScope, $http) {
                $http.get(('api/subscribers/{0}/service/techs').format($scope.subscriberId || $rootScope.user.subscriberId))
                    .then(function (list) {
                        $scope.techs = list.data;
                    });
            }],
            template: '<div multi-select-ok-wrapped="techs" ng-model="ngModel" display-prop="\'name\'" selection-prop="\'id\'"></div>',
        };
    }]);
