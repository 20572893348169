angular.module('angus.services').service('nodeType', 
	['_', 'fluentRest', 'colDefService',
	function(_, fluentRest, colDefService) {
		'use strict';

		var types = ['Division', 'DeliveryCenter', 'ServiceCenter' ];

		function getTypes(subscriberId, type){
			return fluentRest.api().subscribers(subscriberId).hierarchy().types().get({ hierarchyType  : type })
		}

		return _.reduce(types, function(result, type){
			result['get' + type] = function(subscriberId){
				return getTypes(subscriberId, type.camelize())
			};

			result['get' + type+'ColDefs'] = function(subscriberId, flatHierarchy){
				return getTypes(subscriberId, type.camelize())
					.then(function(nodeTypes){ //By default always group by hierarchy. Return the next index for any other groups to use								
						return _.reduce(nodeTypes, function(result, type, index){		    
							var colId = ('hierarchy.'  + type.name.camelize());                		                    	                    
		                    var camelName = type.name.camelize();  
		                    var opts = {
		                    	valueGetter : function(params){
	        						return params.data && params.data.hierarchy ? params.data.hierarchy[camelName] : params.field == colId ? params.key : '';		
	        					},
	        					
		                    };

		                    if(!flatHierarchy){
		                    	opts.rowGroupIndex 	= index;
	        					opts.hide 			= true;
		                    }

		                    result.push(colDefService.createText(colId, type.name, colId, opts ));
		                    return result;
		                }, []);
					})
			};

			return result;
		}, {});
	}
]);