angular.module('angus.controllers').controller('acctGainLossByTankActionViewCtrl', [
  '$scope', '$rootScope',  '$http',  '$q', 'drillService', 'moment',  '_',  'promiseMonitor', 'gridService2','constantsService', 'gridState', 'nodeType',
  function ($scope, $rootScope,  $http, $q, drillService, moment, _,  PromiseMonitor, gridService2,constantsService, gridState, nodeType) {
  	'use strict';

  	var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;
      $scope.ieoOptions = constantsService.includeExcludeOnly.enums;
    $scope.filter =  {
    	productIds 			: $scope.settings.productIds.value,
    	tradeClassIds 	: $scope.settings.tradeClassIds.value,
    	hierarchyNodeId : $scope.settings.hierarchyNodeId,
    	meteredTanks 		: $scope.settings.meteredTanks.value,
    	deliveryTypes   : $scope.settings.deliveryTypes.value,
        salesPersonIds  : $scope.settings.salesPersonIds.value,
        customerOwned        : $scope.settings.customerOwned.value,
        minTankSize     : $scope.settings.minTankSize ? $scope.settings.minTankSize.value : undefined,
        maxTankSize     : $scope.settings.maxTankSize ? $scope.settings.maxTankSize.value : undefined,
        deliveryTypeIds : $scope.settings.deliveryTypeIds ? $scope.settings.deliveryTypeIds.value : undefined
    };

      $scope.dates = {
          startDate: moment.utc($scope.settings.startDate).format('L'),
          endDate: moment.utc($scope.settings.endDate).format('L'),
          lastDeliveryDate: moment.utc($scope.settings.endDate).format('L'),
      };

    $scope.getTankGainLoss = function(){

      const startDate = moment.utc($scope.dates.startDate);
      const endDate = moment.utc($scope.dates.endDate);

      var params = {
          hierarchyNodeId : $scope.filter.hierarchyNodeId,
          productIds 	    : $scope.filter.productIds,
          tradeClassIds 	: $scope.filter.tradeClassIds,
          startDate 		  : startDate.format('YYYY-MM-DD'),
          endDate			    : endDate.format('YYYY-MM-DD'),
          meteredTanks 	  : $scope.filter.meteredTanks,
          deliveryTypes   : $scope.filter.deliveryTypes,
          salesPersonIds  : $scope.filter.salesPersonIds,
          customerOwned        : $scope.filter.customerOwned,
          maxTankSize     : $scope.filter.maxTankSize,
          minTankSize     : $scope.filter.minTankSize,
          deliveryTypeIds : $scope.filter.deliveryTypeIds
      };

    	var rowPromise = $http({
        	  url: ('api/subscribers/{0}/customerAccounts/gainsLosses/ByTank').format($rootScope.user.subscriberId),
          	method: 'POST',
          	data: params
        }).then(function(result){
          /* A bug with AG Grid appears to force us to use the same ColId and Field names. So duplicate the Date field here. */
          result.data.forEach(row => {
            row.changeDateMonth = row.changeDate;
            row.changeDateYear = row.changeDate;
          })
          
          return result.data;
        });

        var promise = $scope.grid.setRows(rowPromise);

		    $scope.promiseMonitor = new PromiseMonitor(promise);
		    return promise;
    }

   function getColClicks(){
        return {
              accountNumber   : {
                onClick : function(row){ drillService.openAccountInfo(row); }
              },
              tankNumber    : {
                onClick : function(row){ drillService.openTankInfo(row, drillService.context.delivery); }
              },
        };
    }

    function getColDefs() {
        var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
        var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];
        colDefs.push(gridService2.colDef.createNumber('gainLossCount','Number of Tanks','gainLossCount',{ aggFunc : 'sum'}, {decimalPlaces: 0,  center : true}));
      	colDefs.push(gridService2.colDef.createText('productName','Product','productName', { rowGroupIndex : nextIndex, hide : true }));
      	colDefs.push(gridService2.colDef.createText('gainLossTypeName','Gain / Loss Type','gainLossTypeName', { rowGroupIndex : nextIndex + 1, hide : true }));
      	colDefs.push(gridService2.colDef.createText('sourceReason','Source / Reason','sourceReason', { rowGroupIndex : nextIndex + 2, hide : true }));
        colDefs.push(gridService2.colDef.createDate('changeDate','Change Date','changeDate', { sort: 'asc' }));
        colDefs.push(gridService2.colDef.createMonth('changeDateMonth','Month','changeDateMonth', { sort: 'asc' }));
        colDefs.push(gridService2.colDef.createYear('changeDateYear','Year','changeDateYear', { sort: 'asc' }));
        colDefs.push(gridService2.colDef.createText('accountName','Customer Name','accountName'));
        colDefs.push(gridService2.colDef.createText('accountNumber','Account Number','accountNumber', { cellClass : ['center']} ));
        colDefs.push(gridService2.colDef.createText('tankNumber','Tank Number','tankNumber', { cellClass : ['center']} ));
        colDefs.push(gridService2.colDef.createNumber('capacity','Tank Size','capacity',{}, {center : true}));
        colDefs.push(gridService2.colDef.createText('containerAddressLine1','Address Line 1','containerAddressLine1'));
        colDefs.push(gridService2.colDef.createText('containerAddressLine2','Address Line 2','containerAddressLine2'));
        colDefs.push(gridService2.colDef.createText('containerCity','City','containerCity'));
        colDefs.push(gridService2.colDef.createText('containerState','State','containerState'));
        colDefs.push(gridService2.colDef.createText('containerPostalCode','ZIP Code','containerPostalCode', { cellClass : ['center']} ));
        colDefs.push(gridService2.colDef.createText('containerEmailAddress','E-mail','containerEmailAddress'));
        colDefs.push(gridService2.colDef.createText('containerPhoneNumber1','Phone Number','containerPhoneNumber1', { cellClass : ['center']} ));
        colDefs.push(gridService2.colDef.createText('salesRep','Sales Rep','salesRep'));
        colDefs.push(gridService2.colDef.createNumber('projectedLossUnits','Projected / Lost Units','projectedLossUnits',{ aggFunc : 'sum'}, {decimalPlaces: 1, colorPositive: true}));
        colDefs.push(gridService2.colDef.createCurrency('projectedLossMargin','Projected / Lost Margin','projectedLossMargin',{aggFunc : 'sum'}, {decimalPlaces: 2, colorPositive: true} ));
        colDefs.push(gridService2.colDef.createText('deliveryType','Brite Delivery Type','deliveryType'));
        colDefs.push(gridService2.colDef.createText('sourceDeliveryType','Source Delivery Type','sourceDeliveryType'));
        colDefs.push(gridService2.colDef.createBoolean('activeOrInactive','Active', 'activeOrInactive'));
        colDefs.push(gridService2.colDef.createText('tradeClassName','Trade Class','tradeClassName'));
        colDefs.push(gridService2.colDef.createText('sourceTradeClassName','Source Trade Class','sourceTradeClassName', {hide: true}));

        if($scope.filter.customerOwned) {
            colDefs.push(gridService2.colDef.createBoolean('customerOwned', 'Customer Owned', 'customerOwned'));
        }

      return colDefs;
   	}

   	var nodeColDefs;

    nodeType
	    .getDivisionColDefs(subscriberId)
	    .then(function(colDefs){

	        nodeColDefs = colDefs;
	        var gridParams = {
	            gridState     : gridState(subscriberId, $scope.widgetCode),
	            defs          : getColDefs(),
	            clicks        : getColClicks(),
	            exportOptions : { fileName: 'Tank Gains & Losess' }
	        };
	        return gridService2.createGrid(gridParams);
	    })
	    .then(function(grid){
	        $scope.grid = grid;
	        $scope.getTankGainLoss()
	    });
  }
]);
