angular.module('angus.controllers').controller('taskbarController', [
    '$scope', 'actionViewTemplates', 'brandingService', 'modalService', 'windowsService',
    function($scope, actionViewTemplates, brandingService, modalService, windowsService) {

        $scope.isPaygo = brandingService.isPaygo();

        $scope.windows = windowsService.getWindows();
    
        $scope.activeWindow = windowsService.getActiveWindow();

        $scope.$on('activeWindowChanged', function(event, newActiveWindow) {
            $scope.activeWindow = newActiveWindow;
        });

        $scope.handleClose = function (window) {
            windowsService.closeWindow(window.id);
        }

        $scope.handleDashboardClick = function () {
            windowsService.minimizeAllWindows();
        }

        $scope.handleClick = function (window) {

            const id = window.id;

            /* If the clicked tab is already active, then Minimize it. */
            if (windowsService.isActive(id)) { 
                windowsService.minimizeWindow(id);
            }
            else {
                windowsService.activateWindow(id);
            }
        }

        $scope.openETLStatus = function() {
            modalService.openTopBarModal(actionViewTemplates.etlStatusPopover);
        }
    }
]);