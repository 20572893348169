angular.module('angus.controllers')
  .controller('paygoCustRecCustomerImportController', [
    '$rootScope', '$scope', '$http', '$sce', '$window', 'fluentRest', 'divisionService', 'constantsService', 'hierarchyService', 'saveAs',
    function($rootScope, $scope, $http, $sce, $window, fluentRest,  divisionService, constantsService, hierarchyService, saveAs) {
      'use strict'; 
      
      $scope.importedData = []; 
      $scope.divisionKey = ''; 
      $scope.selectedSubscriber = null;
      $scope.invalidData = [];
      $scope.validationErrors = [];
      $scope.criticalErrors = [];
      $scope.errors = []; 
      $scope.message = '';
      $scope.customers = [];
      $scope.enabledEdit = [];
      $scope.ifInvalidData = false; 
      $scope.canSubmit = false;
      $scope.divisions = [];
      var dataValidType = {
        ok:'ok',
        duplicate:'duplicate',
        wrongDataType:'wrongDataType'
      } 
      
      function init() {
        $scope.errors = [];
        $scope.selectedSubscriber = $rootScope.selectedSubscriber;
        $scope.isSysAdmin = $rootScope.user.role == 'sysadmin'; 
        loadDivisions();
      };

      init();  
 
      $scope.uploadFile = function() {
        $scope.clear();
 
        if ($scope.selectedSubscriber && $scope.divisionKey) {
          var file = document.getElementById('file').files[0],
              fileReader = new FileReader();
      
          fileReader.onloadend = function(event) {
            var data = event.target.result; 
            $scope.importedData = data;  
            fileUpload(file);
          }
      
          fileReader.readAsBinaryString(file);
        }
        else { 
          $scope.errors.push(`Please select a subscriber and a division.`);
        }

        evaluateRequired(); 
    } 
 
    $scope.addCustomer = function() {
      var cust ={ CustomerTankID:"",CustomerFirstName:"",CustomerLastName:"", CustomerAddressLine1:"", CustomerAddressLine2:"",
                  CustomerID:"", CustomerPhoneNumber:"",CustomerPostalCode:"",CustomerState:"",CustomerCity:"",hasError:false};
      $scope.customers.push(cust);
      $scope.enabledEdit[$scope.customers.length-1]=true;
    }

    $scope.editCustomer = function(index, event) { 
      event.preventDefault(); 

      $scope.enabledEdit[index + 1] = true;
      $scope.editMode = true;
    }

    $scope.deleteCustomer = function(index, event) { 
      event.preventDefault();
      
      $scope.customers.splice(index,1); 
      $scope.reEvaluate(index);
    }
    
    $scope.submitCustomer = function(event) { 
      $scope.message = '';
      event.preventDefault();
      
      var params = { 
       divisionKey: $scope.divisionKey,
       records: $scope.customers,
       insertUser: $rootScope.user.role
      }
      fluentRest
          .api()
          .subscribers($scope.selectedSubscriber.id)
          .customerAccounts()
          .import()
          .post(params)
      .then(function (results) {
        if (results) {
          $scope.message = results.result;
        }
        else {
          $scope.message = 'Fail to upload customer data!!';
        }
      });
    }

    $scope.clearAll = function() {
      $scope.clear();
      document.getElementById('file').value = null;
    }

    $scope.clear = function() {
      $scope.importedData = [];  
      $scope.customers = [];
      $scope.invalidData = [];
      $scope.errors = [];
      $scope.validationErrors = [];
      $scope.criticalErrors = [];
      $scope.canSubmit = false;
      $scope.message = '';
    }
    
    $scope.showDataType = function() {
      var dataType = document.getElementById("datatype");
      if (dataType.style.display == "none") {
        dataType.style.display = "block";
      } else {
        dataType.style.display = "none";
      }
    } 

    $scope.trustSrc = function(src) {
      return $sce.trustAsResourceUrl(src);
    }  

    $scope.onDivisionKeySelected = function(divisionKey) {
      if (divisionKey) {  
        $scope.divisionKey = divisionKey; 
      } 
    };

    $scope.headers = [].map(function(header) {
      header.reset = function() {
        header.processed = false;
        header.status = header.required ? 'Required' : 'Optional';
      };
      header.reset();

      return header;
    }); 

    $scope.hasErrorColumn = function(rowData, columnName, i) {   
      try {     
        if (!rowData[columnName]) {  
          var errorMsg = `${columnName} is missing on line ${i + 1}`; 
          const foundDup = _.find($scope.criticalErrors, function(x) { return x == errorMsg });     
          if (!foundDup) {  
            $scope.errors.push(errorMsg);  
            $scope.customers[i].hasError = true; 
          }   
          return !rowData[columnName];
        } 
  
        $scope.canSubmit = $scope.customers.length > 0 && 
                           $scope.criticalErrors.length == 0 && 
                           $scope.errors.length == 0 && 
                           $scope.validationErrors.length == 0
      }
      catch(err) {
        return false;
      }
    } 

    $scope.downloadDoc = function(){       
      getStorageFile('customers-import-template.xlsx');
    } 
 
    $scope.downloadTutorial = function(){       
      getStorageFile('GREMLIN-Customer_Import_Directions-Gremlin_Portal.pdf');
    } 
      
    function getStorageFile(filename) {
      var url = ('api/storage'); 
      var data = { filename };
      var promise = $http.post(url, data)
      .success(function(result) {
        if (result) { 
          $window.open(result, '_blank');
        }
        else {
          $window.alert('File not found!');
        }
      })
      .error(function(data, status) {  
          $window.alert('File not found!');
      }); 
    }

    function loadDivisions() { 
      $scope.areDivisionsLoading = true;
      divisionService.getDivisions($scope.selectedSubscriber.id, false, false)
        .then(function (divisions) { 
          $scope.divisions = divisions;
            //Non-Integrated Divisions from the ABOS non-integrated source system
            const filteredDivisions = _.filter(divisions, { 'nonIntegratedSourceSystemFlag': 1 });  
            $scope.divisions = filteredDivisions; 
            $scope.areDivisionsLoading = false;
        });
    }

    $scope.reEvaluate = function(index) {   
      reset(index); 
      hasDuplicates($scope.customers);   
      validateColumn();
      evaluateRequired();  
    }

    $scope.removeDuplicates = function(arr) { 
      if (arr) {
        return arr.filter((item, index) => arr.indexOf(item) === index);
      }
    }

    function validateColumn() {
      for (let index = 0; index < $scope.customers.length; index++) {
        validateData(index, $scope.customers[index], '', $scope.customers[index], ''); 
      }
    }

    function evaluateRequired() {  
        $scope.canSubmit = true;
      var requiredFields = ['CustomerID', 'CustomerTankID', 'CustomerFirstName']; 
 
      $scope.criticalErrors = [];
      for (let index = 0; index < requiredFields.length; index++) {
        var column = requiredFields[index];
        var record = $scope.customers[index];  
        $scope.hasErrorColumn(record, column, index);  
      }  
    }

    function reset(index) {  
      var customers = _.map($scope.customers, function( n, i ) { 
        if (i == index) {
          var temp = n;
          temp.hasError =false
          temp.errorColumn = ''; 
          return temp;
        }
        else {
          return n;
        }
      });  
      $scope.validationErrors = [];
      $scope.errors = [];
    }

    function fileUpload(file) {   
      var url = ('api/subscribers/{0}/customerAccounts/import/parse').format($scope.selectedSubscriber.id);
      var formData = new FormData();
      formData.append('file', file);
 
      var promise = $http.post(url, formData, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      })
      .success(function(result) { 
          if (result && result.length) {
            $scope.clear();
            $scope.importedData = result;
            $scope.customers = angular.copy($scope.importedData); 
            hasDuplicates($scope.customers);
            $scope.reEvaluate(0);
          }
      })
      .error(function(data, status) {
          console.error(status, data);
      }); 
    }
    
    function hasDuplicates(array) {   
      let index = 0; 
      for (let i = 0; i < array.length; i++) { 
        for (let j = i + 1; j < array.length; j++) { 
          var tempA = angular.copy(array[i]); 
          var tempB = angular.copy(array[j]);
          delete tempA.hasError; delete tempA.errorColumn;
          delete tempB.hasError; delete tempB.errorColumn; 
          if (JSON.stringify(tempA) == JSON.stringify(tempB)) { 
            $scope.errors.push(`Duplicate data found in line ${index}`);
          }
          index++;
        }
      }  
    } 
    
    function isValidEmail(email) { 
        const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reg.test(String(email).toLowerCase());       
    }
  
    function isValidPhone(phone) { 
        const reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        return reg.test(String(phone).toLowerCase()); 
    }
  
    function isValidZipcode(zipcode) { 
        const reg = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
        return reg.test(String(zipcode).toLowerCase());    
    } 

    function isString(value) {
      const reg = /^[a-zA-Z]*$/;
      return reg.test(String(value).toLowerCase());
    } 

    function invalidEmail(CustomerEmailAddress, index) {
      var invalid = false;
      if (CustomerEmailAddress === '') return invalid;

      if (CustomerEmailAddress) {
        CustomerEmailAddress = CustomerEmailAddress.trim();
        invalid = !isValidEmail(CustomerEmailAddress);
      }
      if (invalid) {
        const errorMsg = `${CustomerEmailAddress}, email is invalid on line ${index + 1}`; 
        $scope.customers[index].hasError = true;
        $scope.customers[index].errorColumn = 'CustomerEmailAddress';
        const foundDup = _.find($scope.validationErrors, function(x) { return x == errorMsg });
        if (!foundDup) {
          $scope.validationErrors.push(errorMsg);
        }
      }
      return invalid;
    }
  
    function invalidPhone(CustomerPhoneNumber, index) {
      var invalid = false;
      if (CustomerPhoneNumber === '') return invalid;

      if (CustomerPhoneNumber) {
        invalid = !isValidPhone(CustomerPhoneNumber); 
        if (invalid) {
          const errorMsg = `${CustomerPhoneNumber}, phone number is invalid on line ${index + 1}`;
          $scope.customers[index].hasError = true;
          $scope.customers[index].errorColumn = 'CustomerPhoneNumber'; 
          const foundDup = _.find($scope.validationErrors, function(x) { return x == errorMsg }); 
          if (!foundDup) {
            $scope.validationErrors.push(errorMsg); 
          }
        }
      }
      return invalid;
    }
  
    function invalidZipcode(CustomerPostalCode, index) {
      var invalid = false;
      if (CustomerPostalCode === '') return invalid; 
  
      const zipString = CustomerPostalCode.toString();
      invalid = !isValidZipcode(zipString);
  
      if (invalid) {
        const errorMsg = `${CustomerPostalCode}, zipcode is invalid on line ${index + 1}`; 
        $scope.customers[index].hasError = true;
        $scope.customers[index].errorColumn = 'CustomerPostalCode';
        const foundDup = _.find($scope.validationErrors, function(x) { return x == errorMsg }); 
        if (!foundDup) {
          $scope.validationErrors.push(errorMsg);
        }
      }
  
      return invalid;
    } 
      
    function invalidState(CustomerState, index) { 
      var invalid = false;
      if (CustomerState === '') return invalid;

      const stateString = CustomerState ? CustomerState.toString().trim() : '';  
      invalid = (CustomerState.length != 2) && isString(stateString);
  
      if (invalid) {
        const errorMsg = `${CustomerState}, state is invalid on line ${index + 1}`; 
        $scope.customers[index].hasError = true;
        $scope.customers[index].errorColumn = 'CustomerState';
        const foundDup = _.find($scope.validationErrors, function(x) { return x == errorMsg });  
        if (!foundDup) {
          $scope.validationErrors.push(errorMsg);
        }
      }
  
      return invalid;
    }

    function validateData(index, rowData, validType, currentCustomer, columnColor) {  

      if (
        invalidZipcode(rowData['CustomerPostalCode'], index) ||
        invalidEmail(rowData['CustomerEmailAddress'], index) ||
        invalidPhone(rowData['CustomerPhoneNumber'], index) ||
        invalidState(rowData['CustomerState'], index))
        {
          validType = dataValidType.wrongDataType;
          $scope.invalidData.push(currentCustomer); 
          columnColor = "orange";
       }
  
      return { validType, columnColor };
    } 

  }
]);


