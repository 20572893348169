angular.module( 'angus.controllers' ).controller( 'paygoExceptionsCtrl', [
	'$scope', '$rootScope', 'Paging', 'actionViewTemplates', 'fluentRest', 'datePickerService', 'moment',
	function( $scope, $rootScope, paging, actionViewTemplates, fluentRest, datePickerService, moment ) {
		'use strict';

		$scope.openDatePicker = function() {
				datePickerService
				.openDatePickerModal($scope.startDate,$scope.endDate, moment())
				.spread(function(startDate, endDate) {
					if (startDate && endDate) {
						$scope.startDate = startDate;
						$scope.endDate = endDate;

						$scope.getDealers();
					}
				});
		};
		$scope.getDealers = function( ) {
			var params ={
				showExceptions 	: 1,
				searchTerm 		: $scope.searchTerm,
				sortBy 			: $scope.sortBy,
				ascending		: $scope.ascending ? 1 : 0,
				// d 				: $scope.widget.instance.settings.dealerIds.value,
				// et 				: $scope.widget.instance.settings.exceptionTypeIds.value,
				//s 				: $scope.startDate.format(),
				//e 				: $scope.endDate.format()
			};

			var promise =  fluentRest
				.api()
				.subscribers()
				.dealers()
					.get(params)
					.then(function(dealers){
						$scope.widget.setDefaultActionModel({
							// activeDealerIds : $scope.widget.instance.settings.dealerIds.value
						});
						$scope.dealers = dealers;
					});

			if($scope.widget) $scope.widget.promises.monitor(promise);
			return promise;
		}

		$scope.clearSearch = function() {
			$scope.searchTerm = '';
			$scope.getDealers();
		};

		$scope.dealerSelected = function( dealer ) {
			$scope.widget.openDetailView( actionViewTemplates.paygoExceptionsActionView, {	activeDealerIds : [dealer.id]} );
		};

		$scope.sort = function( sortBy ) {
			if ( $scope.sortBy == sortBy )
				$scope.ascending = !$scope.ascending;
			else {
				$scope.sortBy = sortBy;
				$scope.ascending = true;
			}
			$scope.getDealers();
		};


		function init() {
			$scope.paging = new paging( $scope.pageSize || 5 );
			$scope.searchTerm ='';
			$scope.sortBy = 'name';
			$scope.ascending = true;
			return $scope.getDealers();			
		}

        $scope.widget.setDefaultInitialization(init);

        $scope.$on('widgetSettingsChanged', function() {
           init();
        });

		$scope.$watch(function(){
			return $scope.searchTerm;
		}, function(newVal, oldVal){
			if(newVal != oldVal)
				$scope.getDealers();
		});

	}
]);

angular.module( 'angus.controllers' ).controller( 'paygoExceptionsContainerCtrl', [
	'$scope', 'actionViewTemplates',
	function( $scope, actionViewTemplates ) {
		'use strict';
		$scope.sideNavTemplate = actionViewTemplates.sideNav;

		$scope.subscribersItem = {
			name: 'Subscribers',
			icon: 'users',
			templateUrl: actionViewTemplates.paygoExceptionsSubscribers,
			active: true
		};



		function init() {
			$scope.pageSize = 20;
			$scope.items = [ $scope.subscribersItem ];
			if ( $scope.subscriber ) switchToSubscriber( $scope.subscriber );
			else $scope.activeItem = $scope.subscribersItem;
		}

		init();

		$scope.switchActiveItem = function( item ) {
			if ( item.active ) {
				$scope.activeItem = item;
			}
		};

		$scope.removeSubscriber = function( item ) {
			var index = _( $scope.items ).findIndex( item );
			_.remove($scope.items,  item );
			if ( index <= $scope.items.length - 1 ) $scope.activeItem = $scope.items[ index ];
			else $scope.activeItem = $scope.items[ index - 1 ];
		};

		$scope.isSubscriberOpen = function( subscriber ) {
			return isSubscriberAlreadyOpen( subscriber );
		};


		function isSubscriberAlreadyOpen( subscriber ) {
			return _( $scope.items ).some( function( item ) {
				return ( item.subscriber ) ? item.subscriber.id == subscriber.id : false;
			} );
		}

		function switchToSubscriber( subscriber ) {
			var item;
			if ( isSubscriberAlreadyOpen( subscriber ) ) {
				_( $scope.items ).forEach( function( i ) {
					if ( i.subscriber && i.subscriber.id == subscriber.id ) item = i;
				} );
			} else {
				item = {
					icon: 'user',
					templateUrl: actionViewTemplates.paygoExceptionsSubscriber,
					active: true,
					subscriber: subscriber,
					name: subscriber.name
				};
				$scope.items.push( item );
			}
			$scope.activeItem = item;
		}

		$scope.switchToSubscriber = function( subscriber ) {
			switchToSubscriber( subscriber );
		};

		$scope.$watch( function() {
			return $scope.model;
		}, function( newVal ) {
			if ( newVal.subscriber ) switchToSubscriber( newVal.subscriber );
			else $scope.activeItem = $scope.subscribersItem;
		} );

	}
] );
