angular.module( 'angus.directives' ).directive( 'subReg', [
	'$document', 'actionViewTemplates',
	function($document, actionViewTemplates) {
		return {
			scope: {
				closeCb     : "=",
				actionModel : "=",
				widgetClass : "="
			},
			restrict : 'A',
			controller: function( $scope ) {
				$scope.subscriberId = $scope.actionModel ? $scope.actionModel.subscriberId : null;
				$scope.subscriberIsPaygo = $scope.actionModel ? $scope.actionModel.subscriberIsPaygo : null;
				$scope.startItemName = $scope.actionModel ? $scope.actionModel.itemName : null;
				$scope.adminMode = $scope.actionModel ? $scope.actionModel.adminMode : false;
				$scope.close = function() {
					if ( $scope.modalClosedCb ) $scope.modalClosedCb();
					if ( $scope.closeCb ) $scope.closeCb();
				};
			},
			templateUrl: actionViewTemplates.subRegForm,
			link: function( scope, element, attrs, ctrl ) {}
		};
	}
] );