angular.module('angus.controllers').controller('serviceRevenueSettingsCtrl', [
    '$scope', '$rootScope', '$http', 'moment', '_', 'constantsService', 'hierarchyService',
    function($scope, $rootScope, $http, moment, _, constantsService, hierarchyService) {

        'use strict';

        $scope.periodIndicators = constantsService.servicePeriodIndicators.enums;
        $scope.serviceRevenueOptions = constantsService.serviceRevenueCategories.enums;

        $scope.openHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };

        $scope.widget.instance.settings.techs = $scope.widget.instance.settings.techs || {};
        $http.get( ('api/subscribers/{0}/service/techs').format($scope.subscriberId || $rootScope.user.subscriberId) )
            .then(function(list) {
                $scope.techs = list.data;

                _.forEach($scope.widget.instance.settings.techs.value, function(selection) {
                    var update = _.findIndex($scope.techs, function(tech) {
                        return tech.id == selection;
                    });
                    if (update >= 0) $scope.techs[update].excludeTicked = true;
                });
        });

        $scope.updateTechs = function(model) {
            if (model)
                $scope.widget.instance.settings.techs.value = angular.copy(model);
        };

        // add call reasons
        $scope.widget.instance.settings.callReasons = $scope.widget.instance.settings.callReasons || {};
        $http.get(('api/subscribers/{0}/service/callReasons/list').format($scope.subscriberId || $rootScope.user.subscriberId))
            .then(function(list) {
                $scope.callReasons = list.data;

                _.forEach($scope.widget.instance.settings.callReasons.value, function(selection) {
                    var update = _.findIndex($scope.callReasons, function(callReason) {
                        return callReason.id == selection;
                    });
                    if (update >= 0) $scope.callReasons[update].excludeTicked = true;
                });
            });

        $scope.updateCallReasons = function(model) {
            if (model)
                $scope.widget.instance.settings.callReasons.value = angular.copy(model);
        };
    }
]);
