'use strict';

angular.module('angus.controllers').controller('excessCallsSettingsCtrl', [
    '$scope', 'constantsService', 'hierarchyService',
    function($scope, constantsService, hierarchyService) {

        $scope.periodIndicators = constantsService.serviceExtendedPeriodIndicators.enums;
        $scope.ieoOptions = constantsService.includeExcludeOnly.enums;

        $scope.openHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };

    }
]);
