angular.module('angus.directives').directive('formSubmissionFailure', [
    '$parse', function ($parse) {
        return {
            require:'ngModel',
            link: function (scope, elem, attrs, ctrl) {

                scope.$watch(function() {
                    return ($parse(attrs.formSubmissionFailure)(scope) != null);
                }, function (currentValue) {
                    if (currentValue) {
                        ctrl.$setValidity('showError', false);
                        $(elem).focus();
                    } else {
                        ctrl.$setValidity('showError', true);
                    }
                });
            }

            
        };
    }
]);