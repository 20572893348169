'use strict';

angular.module('angus.directives').directive('serviceRework', [
    'actionViewTemplates',
    function(actionViewTemplates) {
        return {
            scope: {
                model: '=actionModel',
                settings: '=serviceReworkWidgetSettings',
                widgetCode: '='
            },
            templateUrl: actionViewTemplates.serviceReworkReportContainer
            //templateUrl: actionViewTemplates.callbackAnalysisReportContainer
        };
    }
]);
