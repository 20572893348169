angular.module('angus.controllers').controller('arSummaryFuelPakReportContentCtrl', [
    '$scope', '$rootScope', '$document', '$http', 'dateToString', 'modalService', '$timeout', '$q',   'hierarchyService', 'actionViewTemplates', 'csvService', '_', 'Paging', 'moment',
    function($scope, $rootScope, $document, $http, dateToString, modalService, $timeout, $q, hierarchyService, actionViewTemplates, csvService, _, paging, moment) {
    	'use strict';
        $scope.distanceFromLeaf = 0;
        $scope.activeItem.groupCollapse = {};
        $scope.parentKeys = {};

         $scope.$watch('activeItem.filter.hierarchyNodeId', function(newVal, oldVal){
            if(newVal !=oldVal) $scope.updateDivisionHierarchyNode();  
        })

        function getDivisions(hierarchyNodeId) {
            return hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, hierarchyNodeId || $scope.settings.hierarchyNodeId);
        }

        function createNodeSummaryHeadings() {
            $scope.nodeSummaryHeadings = [];
            for (var i = 1; i < $scope.distanceFromLeaf; i++) {
                $scope.nodeSummaryHeadings.push({
                    name: '',
                    isHidable: false,
                    propClass: 'text-center',
                    isSortable: false
                });
            }

            _.forEach(standardHeadings, function(heading) {
                $scope.nodeSummaryHeadings.push(heading);
            });
        }

        function init() {
            return {
                hierarchyNodeId: $scope.settings.hierarchyNodeId,
                table: [],
                summaryTable: []
            };
        }

        $scope.$watch(function() {
            return $scope.activeItem;
        }, function(newVal) {
            $scope.renderComplete = false;

            if (newVal != $scope.listReport) {

                if (!newVal.filter) $scope.activeItem.filter = init();

                $scope.distanceFromLeaf = hierarchyService.getDistanceFromLeaf($rootScope.user.hierarchy.division.nodes, $scope.activeItem.filter.hierarchyNodeId || $scope.settings.hierarchyNodeId);

                if ($scope.distanceFromLeaf > 1) {
                    if (!newVal.aggregates) getNodeSummary(getDivisions());
                    else $scope.renderComplete = true;
                } else {
                    if (!newVal.report) getDetails(0, getDivisions());
                    else $scope.renderComplete = true;
                }
            }
        });

        $scope.openDivisionHierarchyView = function() {
            $scope.activeItem.detailNodeId = null;

            hierarchyService.openDivisionModal($scope.activeItem.filter.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.activeItem.filter.hierarchyNodeId = nodeId;
                    $scope.distanceFromLeaf = hierarchyService.getDistanceFromLeaf($rootScope.user.hierarchy.division.nodes, $scope.activeItem.filter.hierarchyNodeId || $scope.settings.hierarchyNodeId);

                    if ($scope.distanceFromLeaf > 1)
                        getNodeSummary(getDivisions($scope.activeItem.filter.hierarchyNodeId));
                    else
                        getDetails(0, getDivisions($scope.activeItem.filter.hierarchyNodeId));
                });
        };

        $scope.updateDivisionHierarchyNode = function() {
            $scope.activeItem.detailNodeId = null;

            $scope.distanceFromLeaf = hierarchyService.getDistanceFromLeaf($rootScope.user.hierarchy.division.nodes, $scope.activeItem.filter.hierarchyNodeId || $scope.settings.hierarchyNodeId);

            if ($scope.distanceFromLeaf > 1)
                getNodeSummary(getDivisions($scope.activeItem.filter.hierarchyNodeId));
            else
                getDetails(0, getDivisions($scope.activeItem.filter.hierarchyNodeId));
        };


        $scope.getPage = function(pageNumber) {
            getDetails(pageNumber, getDivisions($scope.activeItem.detailNodeId || $scope.activeItem.filter.hierarchyNodeId));
        };


        function getArSummary(divisions) {
            return $http({
                url: ('api/subscribers/{0}/accountsReceivable/summary').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    divisions: divisions,
                    arDate: moment().format()
                }
            });
        }

        function getArNodeSummary(divisions) {
            return $http({
                url: ('api/subscribers/{0}/accountsReceivable/nodeSummary').format($rootScope.user.subscriberId),
                method: 'POST',
                data: {
                    divisions: divisions,
                    arDate: moment().format(),
                    nodeId: $scope.activeItem.filter.hierarchyNodeId
                }
            });
        }

        $scope.reinitComplete = function() {
            $scope.reinit = true;
        };

        $scope.summaryReinitComplete = function() {
            $scope.summaryReinit = true;
        };

        function drillIntoDetails(nodeId) {
            $scope.activeItem.detailNodeId = nodeId;
            getDetails(1, getDivisions($scope.activeItem.detailNodeId));
        }

        function getNodeSummary(divisions) {
            $scope.summaryReinit = false;
            $scope.showAggregates = true;
            $scope.renderComplete = false;

            delete $scope.activeItem.nodeSummary;

            createNodeSummaryHeadings($scope.distanceFromLeaf);
            $scope.activeItem.filter.nodeSummaryTable = [];

            getArNodeSummary(divisions)
                .then(function(summary) {
                    $scope.activeItem.nodeSummary = summary.data;
                    $scope.renderComplete = true;
                });
        }

        function getDetails(pageNumber, divisions) {
            $scope.renderComplete = false;
            $scope.showAggregates = false;
            $scope.reinit = false;


            getArSummary(divisions)
                .then(function(summary) {
                    $scope.activeItem.summary = summary.data;

                    $scope.activeItem.paging.postGetPage(
                            ('api/subscribers/{0}/accountsReceivable/details').format($rootScope.user.subscriberId),
                            pageNumber, {
                                divisions: divisions,
                                sortProp: $scope.activeItem.sorting.sortProp,
                                sortDir: $scope.activeItem.sorting.sortDir
                            })
                        .then(function(results) {
                            $scope.activeItem.report = results.items;
                            $scope.renderComplete = true;
                        });

                });

        }


        $scope.sort = function(propName) {
            if (propName != $scope.activeItem.sorting.sortProp)
                $scope.activeItem.sorting.sortDir = 'asc';
            else
                $scope.activeItem.sorting.sortDir = $scope.activeItem.sorting.sortDir == 'asc' ? 'desc' : 'asc';

            $scope.activeItem.sorting.sortProp = propName;
            getDetails($scope.activeItem.paging.currentPage, getDivisions($scope.activeItem.detailNodeId || $scope.activeItem.filter.hierarchyNodeId));
        };

        $scope.saveReportFilters = function() {
            if (!$scope.activeItem.filter.id)
                $scope.openSaveReportFilterModal();
            else {
                $scope.savingFilter = true;
                $http.put(('api/subscribers/{0}/reports/{1}/{2}').format($rootScope.user.subscriberId, $scope.widgetCode, $scope.activeItem.filter.id), $scope.activeItem.filter)
                    .success(function() {
                        $timeout(function() {
                            $scope.savingFilter = false;
                        }, 250);
                    });
            }
        };

        $scope.openSaveReportFilterModal = function() {
            var scope = {
                validationUrl: ('api/subscribers/{0}/reports/' + $scope.widgetCode + '/nameAvailable?name={1}'),
                validationKey: 'name',
                modalTitle: 'A/R Summary Report'
            };

            modalService
                .openTopBarModal(actionViewTemplates.saveReportFilter, scope)
                .then(function(name) {
                    if (name) {
                        $scope.isLoading = true;
                        var previousId = $scope.activeItem.filter.id;
                        $scope.activeItem.filter.name = name;
                        $scope.activeItem.filter.id = null;

                        $http.post(('api/subscribers/{0}/reports/{1}').format($rootScope.user.subscriberId, $scope.widgetCode), $scope.activeItem.filter)
                            .success(function(response) {
                                $scope.activeItem.filter.id = response.id;
                                if (previousId) {
                                    $scope.renameActiveReportTab($scope.activeItem.filter.name);
                                } else {
                                    $scope.addNewOpenReport($scope.activeItem.filter, true, false);
                                }

                                $scope.isLoading = false;
                            });
                    }
                });
        };

        $scope.aggRowAction = function(key, depth, isExpanded) {
            if (depth <= 2)
                drillIntoDetails(key);
            else
                updateExpandedRows(key, isExpanded);
        };

        var nodes = null;

        function updateExpandedRows(key, isExpanded) {
            if (!nodes) nodes = hierarchyService.convertDataForEaseOfUse($rootScope.user.hierarchy.division.nodes);

            var node = _.find(nodes, function(node) {
                return node.id.toString() == key;
            });

            $scope.activeItem.groupCollapse[key].isExpanded = isExpanded;

            if (!isExpanded) {
                _.forEach(hierarchyService.getDescendants(nodes, node), function(node) {
                    if ($scope.activeItem.groupCollapse[node.id])
                        $scope.activeItem.groupCollapse[node.id].isExpanded = isExpanded;
                });
            }
        }

        $scope.action = function() {
            $scope.showAggregates = true;
        };

        $scope.expandAll = function(isExpanded) {
            _.forEach($scope.activeItem.groupCollapse, function(group) {
                group.isExpanded = isExpanded;
            });
        };

        $scope.downloadInProgress = function() {
            return csvService.downloadInProgress();
        };

        $scope.getCsv = function() {
            var params = {
                divisions: getDivisions($scope.activeItem.filter.hierarchyNodeId)
            };

            csvService.postGetCsv(('api/subscribers/{0}/accountsReceivable/toCsv').format($rootScope.user.subscriberId), params, 'AR Summary');
        };


        var headingCenterClass = 'text-center clickable';

        var standardHeadings = [{
            name: 'Total Debit Balance',
            isHidable: false,
            propClass: headingCenterClass,
            propName: 'debitTotal',
            isSortable: true
        }, {
            name: 'Total Credit Balance',
            isHidable: false,
            propClass: headingCenterClass,
            propName: 'credits',
            isSortable: true
        }, {
            name: 'A/R Total',
            isHidable: false,
            propClass: headingCenterClass,
            propName: 'total',
            isSortable: true
        }, {
            name: 'Current',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'debitCurrent',
            isSortable: true
        }, {
            name: 'Late 1-30',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'debitLate30',
            isSortable: true
        }, {
            name: 'Late 31-60',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'debitLate60',
            isSortable: true
        }, {
            name: 'Late 61-90',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'debitLate90',
            isSortable: true
        }, {
            name: 'Late 91-120',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'debitLate120',
            isSortable: true
        }, {
            name: 'Late 121-150',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'debitLate150',
            isSortable: true
        }, {
            name: 'Late 151-180',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'debitLate180',
            isSortable: true
        }, {
            name: 'Late Over 180',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'debitLate180Beyond',
            isSortable: true
        }];


        $scope.tableHeadings = [{
            name: 'Division',
            isHidable: false,
            propClass: headingCenterClass,
            propName: 'divisionName',
            isSortable: true
        }];

        _.forEach(standardHeadings, function(heading) {
            $scope.tableHeadings.push(heading);
        });
    }
]);
