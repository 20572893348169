angular.module('angus.controllers').controller('arSummaryBsgReportContentCtrl', [
    '$scope', '$rootScope', '$document', '$http', 'dateToString', 'modalService', '$timeout', '$q',   'hierarchyService', 'actionViewTemplates', '_', 'Paging', 'moment','gridService2', 'nodeType', 'gridState',
    function($scope, $rootScope, $document, $http, dateToString, modalService, $timeout, $q, hierarchyService, actionViewTemplates, _, paging, moment, gridService2, nodeType, gridState) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;


        $scope.filter =  {
            hierarchyNodeId     : $scope.settings.hierarchyNodeId
        }; 

        $scope.getAR = function(){  
                            
            var params = {
                divisionId      : $scope.filter.hierarchyNodeId
            };

            var rowPromise = $http({            
                    url: ('api/subscribers/{0}/accountsReceivable/bsg/').format(subscriberId),
                    method: 'POST',
                    data: params
            }).then(function(result){                 
                return result.data;
            });        

            var promise = $scope.grid.setRows(rowPromise);

            //$scope.promiseMonitor = new PromiseMonitor(promise);
            return promise;
        }



        function getColDefs(){
            
            // var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];                 

            colDefs.push(gridService2.colDef.createText('customerNumber','Account Number','customer.number', { cellClass : ['center']}));
            colDefs.push(gridService2.colDef.createText('customerName','Customer Name','customer.name'));
            colDefs.push(gridService2.colDef.createText('creditStatus','Credit Status','creditStatus'));
            colDefs.push(gridService2.colDef.createText('termsDesc','Terms','terms.desc'));
            colDefs.push(gridService2.colDef.createNumber('termsDiscountDays','Discount Days','terms.discountDays'));
            colDefs.push(gridService2.colDef.createPercentage('termsDiscountPercent','Discount Percent','terms.discountPercent', {}, { decimalPlaces: 2 }));      
            colDefs.push(gridService2.colDef.createCurrency('termsCreditLimit','Credit Limit','terms.creditLimit', { aggFunc : 'sum'}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createCurrency('agingCurrent','Current','aging.current', {}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createCurrency('aging30','30-59 Days','aging.b30', {}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createCurrency('aging60','60-89 Days','aging.b60', {}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createCurrency('aging90','90-119 Days','aging.b90', {}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createCurrency('aging120','120 Days and Over','aging.b120', {}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createCurrency('balanceAmount','Balance','balance.amount', {}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createDate('balanceDate','Balance Date','balance.date'));
            colDefs.push(gridService2.colDef.createCurrency('balanceHighestAmount','Highest Balance','balance.highestAmount', {}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createCurrency('lastPaymentAmount','Last Payment','lastPayment.amount', {}, {decimalPlaces: 2 }));
            colDefs.push(gridService2.colDef.createDate('lastPaymentDate','Last Payment Date','lastPayment.date'));

            return colDefs;                
        } 

        var nodeColDefs;
        
        nodeType
            .getDivisionColDefs(subscriberId)           
            .then(function(colDefs){
                nodeColDefs = colDefs;          
                var gridParams = { 
                    gridOptions : {
                        groupAggFunction : function(rows){
                            return _.reduce(rows, function(result, row){
                                var data = row.data;

                                result.aging.b30  += data.aging.b30;
                                result.aging.b60 += data.aging.b60;
                                result.aging.b90 += data.aging.b90;
                                result.aging.b120 += data.aging.b120;
                                result.aging.current += data.aging.current;
                                result.balance.amount += data.balance.amount;
                                result.balance.highestAmount += data.balance.highestAmount;
                                result.terms.creditLimit += data.terms.creditLimit;
                                result.lastPayment.amount += data.lastPayment.amount;

                                return result;
                            }, {
                                aging: {
                                    b30: 0,
                                    b60: 0,
                                    b90: 0,
                                    b120: 0,
                                    current: 0
                                },
                                terms: {
                                    creditLimit: 0
                                },
                                balance: {
                                    amount: 0,
                                    highestAmount: 0
                                },
                                lastPayment: {
                                    amount: 0
                                }
                            });
                        }
                    },
                    gridState     : gridState(subscriberId, $scope.widgetCode),                    
                    defs          : getColDefs(),
                    // clicks        : getColClicks(),
                    exportOptions : { fileName: 'Accounts Receivable' }
                };
                return gridService2.createGrid(gridParams);
            })
            .then(function(grid){       
                $scope.grid = grid;        
                $scope.getAR();
            });        
    }
]);
