'use strict';

angular.module('angus.filters')
	.filter('keyDate', [ 'moment', function(moment) {
		return function(val, format) {
			var out = '';
			if(val){
				var value = val.toString();
				var year  = value.substring(0, 4);
				var month = value.substring(4, 6);
				var day   = value.substring(6, 8) || '1';

				var date = moment().year(Number(year)).month(Number(month) - 1 ).date(Number(day));

				return date.format(format);
			}
			else
				return out;
		};
	}
]);