'use strict';

angular.module('angus.controllers').controller('laborMarginPopoverCtrl', [
	'$scope', '$http', '$rootScope', 'Paging', 'promiseMonitor', '_',
	function($scope, $http, $rootScope, Paging, PromiseMonitor, _) {

		$scope.pageSize = 10;
		$scope.paging   = new Paging($scope.pageSize);
		$scope.sorting  = {
			sortProp : 'techCode',
			sortDir  : 'asc'
		};

		$scope.table    = [];
		$scope.colCount = 9;

		$scope.promiseMonitor = new PromiseMonitor();

		$scope.sortFn = function(propName){
			if (propName != $scope.sorting.sortProp)
                $scope.sorting.sortDir = 'asc';
            else
                $scope.sorting.sortDir = $scope.sorting.sortDir == 'asc' ? 'desc' : 'asc';

            $scope.sorting.sortProp = propName;
            $scope.getData($scope.paging.currentPage);
		};

		$scope.getData = function (pageNumber) {
			$scope.promiseMonitor.monitor($scope.paging.getPage(('api/subscribers/{0}/service/labor').format($rootScope.user.subscriberId),
				pageNumber || $scope.paging.currentPage,
				{
					serviceId : $scope.id,
					sortProp  : $scope.sorting.sortProp,
					sortDir   : $scope.sorting.sortDir
			 	})
			.then(function(result){
				$scope.item = result;

				if(!_.some(result.labors, function(labor){
						return labor.usingOnsiteHours;
					})){
						$scope.table[5] = false;
						$scope.reinit   = true;
            $scope.noOnsiteHours = true;
						$scope.colCount = 8;
					}
			}));
		};

		var headingCenterClass = 'text-center clickable';

		$scope.tableHeader = [
			{
	            name: 'Tech Code',
	            isHidable: true,
	            propClass: headingCenterClass,
	            propName: 'techCode',
	            isSortable: true
	        }, {
	            name: 'Tech Name',
	            isHidable: true,
	            propClass: headingCenterClass,
	            propName: 'techName',
	            isSortable: true
	        }, {
	            name: 'Hours Billed',
	            isHidable: true,
	            propClass: headingCenterClass,
	            propName: 'laborHours',
	            isSortable: true
	        }, {
              name: 'Hourly Rate Billed',
              isHidable: true,
              propClass: headingCenterClass,
              propName: 'billedHourlyRate',
              isSortable: true
          }, {
	            name: 'Labor Billed',
	            isHidable: true,
	            propClass: headingCenterClass,
	            propName: 'billedAmount',
	            isSortable: true
	        }, {
            name: 'On-Site Time',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'onsiteLaborHours',
            isSortable: true
          },
          {
            name: 'Hourly Cost',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'costHourlyRate',
            isSortable: true
          },
	        {
	            name: 'Labor Cost',
	            isHidable: true,
	            propClass: headingCenterClass,
	            propName: 'cost',
	            isSortable: true
	        }
		];

		$scope.getData();
	}
]);
