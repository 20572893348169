angular.module('angus.directives')
    .directive('focusWhen', [
        '$timeout', '$parse',
        function($timeout, $parse) {

            'use strict';

            return {
                link: function(scope, element, attrs) {
                    var model = $parse(attrs.focusWhen);
                    scope.$watch(model, function(value) {
                        if (value === true) {
                            $timeout(function() {
                                element[0].focus();
                                element[0].select();
                            });
                        }
                    });
                }
            };
        }
    ])
    .directive('focusMe', [
        '$timeout',
        function($timeout) {

            'use strict';

            return {

                link: function(scope, element) {
                    $timeout(function() {
                        element[0].focus();
                    }, 250);
                }
            };
        }
    ]);
