angular.module('angus.controllers').controller('custProfAnalysisContainerCtrl', [
    '$scope', '$rootScope', '$q', '_', 'drillService', 'promiseMonitor', 'fluentRest', 'gridService2', 'gridState', 'nodeType', 'constantsService', 'hierarchyService',
    function($scope, $rootScope, $q, _,  drillService, PromiseMonitor, fluentRest, gridService2, gridState, nodeType, constantsService, hierarchyService) {
        'use strict';

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;
        $scope.ieoOptions = constantsService.includeExcludeOnly.enums;

        $scope.filter = {
            useBrite: $scope.settings.useBrite.value,
            divisionId: $scope.settings.hierarchyNodeId,
            inactiveTanks: $scope.settings.inactiveTanks.value,
        };

        $scope.getCompanyProfileAccounts = function() {
            $scope.grid.setColDefs(getColDefs());

            var rowPromise = fluentRest
                .api()
                .subscribers(subscriberId)
                .customerAccounts()
                .counts()
                    .post($scope.filter)
                    .then(function(rows) {
                        return rows;
                    });

            var promise = $scope.grid.setRows(rowPromise);
            $scope.monitor = new PromiseMonitor(promise);

            return promise;
        };

        function getColDefs() {
            var nextIndex = nodeColDefs ? nodeColDefs.length : 0;
            var colDefs   = nodeColDefs ? _.cloneDeep(nodeColDefs) : [];

            colDefs.push(gridService2.colDef.createBoolean('budget', 'Budget', 'budget', {}, { rowGroupIndex: nextIndex, hide: true, cellClass: ['center'] }));

            colDefs.push(gridService2.colDef.createText('bosCustomerId', 'Account Number', 'bosCustomerId', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createText('customerName', 'Customer Name', 'customerName'));
            colDefs.push(gridService2.colDef.createText('billingAddress', 'Address', 'billingAddress', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createText('billingCity', 'Billing City', 'billingCity', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createText('billingState', 'Billing State', 'billingState', { cellClass: ['center'] }));
            colDefs.push(gridService2.colDef.createDate('acquisitionDate', 'Acquisition Date', 'acquisitionDate', { hide: true }));

            if($scope.filter.inactiveTanks) {
                colDefs.push(gridService2.colDef.createBoolean('active', 'Active', 'active'));
            }

            return colDefs;
        }

        var nodeColDefs;
        nodeType
            .getDivisionColDefs(subscriberId)
            .then(function(colDefs){
                nodeColDefs = colDefs;
                var gridParams = {
                    gridState     : gridState(subscriberId, $scope.widgetCode),
                    defs          : getColDefs(),
                    // clicks        : getColClicks(),
                    exportOptions : { fileName: 'Company Profile - Accounts' }
                };
                return gridService2.createGrid(gridParams);
            })
            .then(function(grid){
                $scope.grid = grid;
                $scope.getCompanyProfileAccounts();
            });
    }
]);