'use strict';

angular
.module('angus.controllers')
.controller('santaPriorPeriodVarContainerCtrl',
    ['$scope', '$rootScope', '$q', 'fluentRest', 'moment', 'fiscalYearService', 'constantsService', '_', 'promiseMonitor', 'gridService2','gridState', 'nodeType', 'restrictedAccessService', 'dateCalculator', santaPriorPeriodVarianceController]);

function santaPriorPeriodVarianceController($scope, $rootScope, $q, fluentRest, moment, fiscalYearService,  constantsService, _, PromiseMonitor, gridService2, gridState, nodeType, restrictedAccessService, dateCalculator) {

    var  subscriberId = $scope.subscriberId = $rootScope.user.subscriberId;
    var fiscalYearOfToday = null;


    function initFilter(){
        return {
            showEntireFiscalYear    : _.get($scope, ['settings', 'periodIndicatorKey', 'value'], false),
            fiscalYear            : $scope.model ? $scope.model.year : moment().year(),
            month                 : $scope.model ? $scope.model.month : moment().month(),
            workingDayOffset      :  _.get($scope, ['settings', 'workingDaySubtraction', 'value'], false) || 0,
            divisionId            : $scope.settings.hierarchyNodeId || $rootScope.user.hierarchy.division.nodeId,
            productCategoryIds    : $scope.settings.productCategoryIds ? $scope.settings.productCategoryIds.value : [],
            tradeClassCategoryIds : $scope.settings.tradeClassCategoryIds ? $scope.settings.tradeClassCategoryIds.value : [],
            pricePlanCategoryIds  : $scope.settings.pricePlanCategoryIds ? $scope.settings.pricePlanCategoryIds.value : [],
            isFinance  			  : !!$scope.settings.isFinance ? !!$scope.settings.isFinance.value : false
        };
    }
    $scope.filter = initFilter();


    $scope.filterChanged = function(filter){
        $scope.filter = filter || initFilter();
    };

    function addYearsToMonths(){
        var year = $scope.fiscalYear;
        if(moment().month(months[0]).month() > 0 )
            year --;
        $scope.months = _.map(months, function(month, index){
            var monthNumber = moment().month(month).month();
            if(monthNumber == 0 && index !== 0)
                year++;
            return {
                display : month, // + ' - ' + year,
                value : monthNumber
            }
        });
    }

    $scope.yearChange = function(){
        fiscalYearService
        .getFiscalYearMonthsOrdered()
        .then(function(months){
            var startMonth   = moment().month(months[0]).month();
            var currentMonth = moment().month();


            $scope.months = _(months)
            .map(function(m){
                return {
                    value: moment().month(m).month(),
                    display : m
                };
            })
            .filter(function(m){
                return fiscalYearOfToday > $scope.fiscalYear || m.value <= currentMonth || m.value >= startMonth;
            })
            .value();

            var lastMonth    = $scope.months[$scope.months.length-1].value;

            if(!$scope.month || (fiscalYearOfToday == $scope.fiscalYear &&  $scope.month > lastMonth && $scope.month < startMonth) )
                $scope.month = currentMonth;
        });

    };

    // start - current year
    $scope.queryMonthlyBudgetVariance = function(m) {
        $scope.grid.setColDefs(getColDefs());

        var params = {
            fy: $scope.filter.fiscalYear,
            h: $scope.filter.divisionId,
            wdo: $scope.filter.workingDayOffset,
            pc: $scope.filter.productCategoryIds,
            tc: $scope.filter.tradeClassCategoryIds,
            ppc: $scope.filter.pricePlanCategoryIds
        };

        if(m) {
            params.m = $scope.filter.month;
        }

        // for some reason January would not work in widget or detail view, unless explicitly set
        if(m === 0) {
            params.m = $scope.filter.month;
        }

        return fluentRest
            .api()
            .subscribers(subscriberId)
            .budgets()
            .variance()
            //api/subscribers/23443342432432/budgets/variance
            .get(params)
                .then(function(data) {
                    $scope.lastTrxDate = data.lastTrxDate ? moment.utc(data.lastTrxDate).format('l') : '-';
                    $scope.dataThroughDate = data.dataThroughDate ? moment.utc(data.dataThroughDate).format('l') : '-';
                    $scope.monthlyWorkingDaysElapsed = data.workingDays.elapsed;
                    $scope.monthlyWorkingDaysTotal = data.workingDays.total;

                    return data.budgets;
                });

    };

    $scope.queryBudgetVarianceYear = function() {
        $scope.grid.setColDefs(getColDefs());

        var params = {
            fy: $scope.filter.fiscalYear,
            h: $scope.filter.divisionId,
            wdo: $scope.filter.workingDayOffset,
            pc: $scope.filter.productCategoryIds,
            tc: $scope.filter.tradeClassCategoryIds,
            ppc: $scope.filter.pricePlanCategoryIds,
            m: $scope.filter.month,
            monthToYear: true
        };

        return fluentRest
            .api()
            .subscribers(subscriberId)
            .budgets()
            .variance()
            //api/subscribers/23443342432432/budgets/variance
            .get(params)
            .then(function(data) {
                $scope.yearlyWorkingDaysElapsed = data.workingDays.elapsed;
                $scope.yearlyWorkingDaysTotal = data.workingDays.total;

                return data.budgets;
            });

    };
    // end - current year

    // start - prior year
    $scope.queryPriorYearMonthlyBudgetVariance = function(m) {
        $scope.grid.setColDefs(getColDefs());

        var params = {
            fy: $scope.filter.fiscalYear - 1,
            h: $scope.filter.divisionId,
            wdo: $scope.filter.workingDayOffset,
            pc: $scope.filter.productCategoryIds,
            tc: $scope.filter.tradeClassCategoryIds,
            ppc: $scope.filter.pricePlanCategoryIds
        };

        if(m) {
            params.m = $scope.filter.month;
        }

        // for some reason January would not work in widget or detail view, unless explicitly set
        if(m === 0) {
            params.m = $scope.filter.month;
        }

        return fluentRest
            .api()
            .subscribers(subscriberId)
            .budgets()
            .variance()
            //api/subscribers/23443342432432/budgets/variance
            .get(params)
                .then(function(data) {
                    return data.budgets;
                });
    };

    $scope.queryBudgetVariancePriorYear = function() {
        $scope.grid.setColDefs(getColDefs());

        var params = {
            fy: $scope.filter.fiscalYear - 1,
            h: $scope.filter.divisionId,
            wdo: $scope.filter.workingDayOffset,
            pc: $scope.filter.productCategoryIds,
            tc: $scope.filter.tradeClassCategoryIds,
            ppc: $scope.filter.pricePlanCategoryIds,
            m: $scope.filter.month,
            monthToYear: true
        };

        return fluentRest
            .api()
            .subscribers(subscriberId)
            .budgets()
            .variance()
            //api/subscribers/23443342432432/budgets/variance
            .get(params)
            .then(function(data) {
                return data.budgets;
            });
    };
    // end - prior year

    function getFiscalYearWorkingDays() {
        var params = {
            fy: $scope.filter.fiscalYear,
            // wdo: $scope.filter.workingDayOffset
        };

        return fluentRest
            .api()
            .subscribers(subscriberId)
            .budgets()
            .variance()
            .get(params)
            .then(function (data) {
                $scope.fiscalYearStartDate = moment.utc(data.startDate).format('l');
                $scope.fiscalYearEndDate = moment.utc(data.endDate).format('l');
                $scope.fiscalYearWorkingDaysElapsed = data.workingDays.elapsed;
                $scope.fiscalYearWorkingDaysTotal = data.workingDays.total;

                return data;
            })
    }

    var gridOptions = {

        groupAggFunction: function(rows) {

            return _.reduce(rows, function(result, row){
                var data = row.data;

                if(data){
                    var keys = ['current', 'currentYTD', 'priorYearCurrentMTD', 'priorYearCurrentYTD', 'variance', 'varianceYTD', 'percentVariance', 'percentVarianceYTD'];
                    _.forEach(keys, function(key){

                        result[key].units       += data[key].units;
                        result[key].grossMargin += data[key].units * data[key].unitMargin;
                        result[key].unitMargin   = data[key].units ? (result[key].grossMargin / result[key].units) : 0;
                    });

                    result.current = {
                        units 			: result.current.units,
                        grossMargin : result.current.grossMargin,
                        unitMargin  : result.current.unitMargin
                    };

                    result.priorYearCurrentMTD = {
                        units           : result.priorYearCurrentMTD.units,
                        grossMargin     : result.priorYearCurrentMTD.grossMargin,
                        unitMargin      : result.priorYearCurrentMTD.unitMargin
                    };

                    result.currentYTD = {
                        units 			: result.currentYTD.units,
                        grossMargin : result.currentYTD.grossMargin,
                        unitMargin  : result.currentYTD.unitMargin
                    };

                    result.priorYearCurrentYTD = {
                        units           : result.priorYearCurrentYTD.units,
                        grossMargin     : result.priorYearCurrentYTD.grossMargin,
                        unitMargin      : result.priorYearCurrentYTD.unitMargin
                    };

                    result.variance = {
                        units 			: result.current.units - result.priorYearCurrentMTD.units,
                        grossMargin : result.current.grossMargin - result.priorYearCurrentMTD.grossMargin,
                        unitMargin  : result.current.unitMargin - result.priorYearCurrentMTD.unitMargin
                    };

                    result.varianceYTD = {
                        units 			: result.currentYTD.units - result.priorYearCurrentYTD.units,
                        grossMargin : result.currentYTD.grossMargin - result.priorYearCurrentYTD.grossMargin,
                        unitMargin  : result.currentYTD.unitMargin - result.priorYearCurrentYTD.unitMargin
                    };

                    result.percentVariance = {
                        units 			: result.current.units ? (result.variance.units / result.priorYearCurrentMTD.units) * 100 : 100,
                        grossMargin : result.current.grossMargin ? (result.variance.grossMargin / result.priorYearCurrentMTD.grossMargin) * 100 : 100,
                        unitMargin  : result.current.unitMargin ? (result.variance.unitMargin / result.priorYearCurrentMTD.unitMargin) * 100 : 100
                    };

                    result.percentVarianceYTD = {
                        units 			: result.currentYTD.units ? (result.varianceYTD.units / result.priorYearCurrentYTD.units) * 100 : 100,
                        grossMargin : result.currentYTD.grossMargin ? (result.varianceYTD.grossMargin / result.priorYearCurrentYTD.grossMargin) * 100 : 100,
                        unitMargin  : result.currentYTD.unitMargin ? (result.varianceYTD.unitMargin / result.priorYearCurrentYTD.unitMargin) * 100 : 100
                    };

                    _.forIn(result.variance, function(value, key) {
                        if(!value) {
                            result.percentVariance[key] = null;
                        }
                    });

                }

                return result;
            },{
                current: {
                    units: 0,
                    grossMargin: 0,
                    unitMargin: 0
                },
                currentYTD: {
                    units: 0,
                    grossMargin: 0,
                    unitMargin: 0
                },
                priorYearCurrentMTD: {
                    units: 0,
                    grossMargin: 0,
                    unitMargin: 0
                },
                priorYearCurrentYTD: {
                    units: 0,
                    grossMargin: 0,
                    unitMargin: 0
                },
                variance: {
                    units: 0,
                    grossMargin: 0,
                    unitMargin: 0
                },
                varianceYTD: {
                    units: 0,
                    grossMargin: 0,
                    unitMargin: 0
                },
                percentVariance: {
                    units: 0,
                    grossMargin: 0,
                    unitMargin: 0
                },
                percentVarianceYTD: {
                    units: 0,
                    grossMargin: 0,
                    unitMargin: 0
                }
            });
        }

    };

    function getColDefs() {
        var nextIndex = $scope.nodeColDefs ? $scope.nodeColDefs.length : 0;
        var colDefs   = $scope.nodeColDefs ? _.cloneDeep($scope.nodeColDefs) : [];
        var colOpts = {};


        colDefs.push(gridService2.colDef.createText('productCategory','Products', 'productCategory', { rowGroupIndex: nextIndex, hide : true }));
        // colDefs.push(gridService2.colDef.createText('tradeClassCategory','Budget Trade Classes', 'tradeClassCategory', { rowGroupIndex: nextIndex+1, hide : true } ));
        // colDefs.push(gridService2.colDef.createText('pricePlanCategory','Budget Price Plans', 'pricePlanCategory', { rowGroupIndex: nextIndex+2, hide : true } ));

        colDefs.push( gridService2.colDef.createNumber('current_units', 'MTD Units', 'current.units', colOpts, {decimalPlaces: 1}) );
        colDefs.push( gridService2.colDef.createNumber('prior_year_current_mtd_units', 'MTD Units - Prior Year', 'priorYearCurrentMTD.units', colOpts, {decimalPlaces: 1}) );
        colDefs.push( gridService2.colDef.createNumber('variance_units', 'MTD Variance', 'variance.units', colOpts, {decimalPlaces: 1}) );
        colDefs.push( gridService2.colDef.createPercentage('percentVariance_units', 'MTD Variance Percent', 'percentVariance.units', colOpts, {decimalPlaces: 0}) );

        colDefs.push( gridService2.colDef.createNumber('ytd_units', 'YTD Units', 'currentYTD.units', colOpts, {decimalPlaces: 1}) );
        colDefs.push( gridService2.colDef.createNumber('prior_year_current_ytd_units', 'YTD Units - Prior Year', 'priorYearCurrentYTD.units', colOpts, {decimalPlaces: 1}) );
        colDefs.push( gridService2.colDef.createNumber('ytd_variance_units', 'YTD Variance', 'varianceYTD.units', colOpts, {decimalPlaces: 1}) );
        colDefs.push( gridService2.colDef.createPercentage('ytd_percentVariance_units', 'YTD Variance Percent', 'percentVarianceYTD.units', colOpts, {decimalPlaces: 0}) );
        return colDefs;
    }

    // reloads the grid with data when selecting new filter
    $scope.loadGridData = function(){
        $scope.variancePromises = null;
        getFiscalYearWorkingDays();
        $scope.load();
    };

    function getYears() {
        return fluentRest
        .api()
        .subscribers($rootScope.user.subscriberId)
        .budgets()
        .years()
        .get()
        .then(function(years) {
            $scope.years = years
        })
    }
    getYears();
    var months;
    $q.all([
        restrictedAccessService
        .getAccess(),
        nodeType
        .getDivisionColDefs(subscriberId),
        fiscalYearService
        .getFiscalYearMonthsOrdered()
    ])
    .spread(function(access, nodeColDefs, m){
        $scope.restrictedAccess = access.restrictedAccess;
        $scope.nodeColDefs 		= nodeColDefs;

        months = m;
        $scope.years = _.range(moment().subtract(5, 'years').year(), moment().add(2, 'years').year(), 1);
        fiscalYearOfToday = dateCalculator.getFiscalYear(moment(), moment().month(m[0]).month());
        addYearsToMonths();
        $scope.yearChange();

        var gridParams = {
            gridOptions	  : gridOptions,
            gridState     : gridState(subscriberId, $scope.widgetCode),
            defs          : getColDefs(),
            exportOptions : { fileName: 'Santa Fuel - Budget Variance' }
        };
        return gridService2.createGrid(gridParams);
    })
    .then(function(grid){
        $scope.grid = grid;
        $scope.load = function() {
            getFiscalYearWorkingDays();

            return Promise.all([$scope.queryBudgetVarianceYear(), $scope.queryMonthlyBudgetVariance($scope.filter.month), $scope.queryBudgetVariancePriorYear(), $scope.queryPriorYearMonthlyBudgetVariance($scope.filter.month)])
                .then(function(result) {
                    var selectedMonth = moment().month($scope.filter.month);
                    // this displays the date range of the currently selected month
                    $scope.currentMonthStartDate = moment(selectedMonth).year($scope.filter.fiscalYear).startOf('month').format('l');
                    $scope.currentMonthEndDate = moment(selectedMonth).year($scope.filter.fiscalYear).endOf('month').format('l');

                    var YTDRecords = result[0];
                    var MTDRecords = result[1];
                    var priorYearYTDRecords = result[2];
                    var priorYearMTDRecords = result[3];

                    MTDRecords = MTDRecords.map(function(record, index) {
                        record.currentYTD = YTDRecords[index].current;
                        record.varianceYTD = YTDRecords[index].variance;
                        record.percentVarianceYTD = YTDRecords[index].percentVariance;

                        record.priorYearCurrentYTD = priorYearYTDRecords[index] ? priorYearYTDRecords[index].current : {units : 0, grossMargin: 0, unitMargin: 0};

                        record.priorYearCurrentMTD = priorYearMTDRecords[index] ? priorYearMTDRecords[index].current : {units : 0, grossMargin: 0, unitMargin: 0};

                        return record;
                    });

                    $scope.variancePromises = new PromiseMonitor($scope.grid.setRows($q.resolve(MTDRecords)));
                })
        };

        $scope.load();
    })
}