angular.module('angus.services').service('subscriberService', [
    '$http', '$rootScope', 'constantsService',
    function ($http,  $rootScope, constantsService) {

        
        let adminSelectedSubscriberId;

        function get (subscriberId) {
            return querySubscriber(subscriberId)
                .then(function (response) {
                    return response.data;
                });
        }

        function listV2 () {
            return  $http.get("api/test/subscribers", {apiV2: true})
                .then(function(response){
                    return response.data;
                })
        }

        function setAdminSubscriberId(subscriberId) {
            const isSysAdmin =  $rootScope.user.role == "sysadmin";

            adminSelectedSubscriberId = isSysAdmin ? subscriberId : undefined;
        }

        function getAdminSubscriberId() {
            const isSysAdmin =  $rootScope.user.role == "sysadmin";

            if (!isSysAdmin){
                return;
            }

            return adminSelectedSubscriberId;
        }

        function getSubscriberId() {
            const adminSelectedSubscriberId = getAdminSubscriberId();

            return adminSelectedSubscriberId || $rootScope.user.subscriberId;
        }

        function getAdeptSubscribers () {
            var registrationStatusKeys = [constantsService.registrationStatus.live]

            return querySubscribers('', registrationStatusKeys, true, false, false)
                .then(subscribers => subscribers.data);
        }

        function getAmsSubscribers(){
            return getAmsSubscribersPaging()
                .then(subscribers => subscribers);
        }

        function getAmsSubscribersPaging(){
            // var statuses = ["live"]
            var registrationStatusKeys = [constantsService.registrationStatus.live]

            return querySubscribers('', registrationStatusKeys, false, false, true)
                .then(subscribers => subscribers.data);
        }

        function querySubscriber(subscriberId) {
            return  $http.get(("api/subscribers/{0}").format(subscriberId));
        }

        function querySubscribers(searchTerm, registrationStatusKeys, isAdept, isBrite, isAms) {
            var params = {
                registrationStatusKeys: registrationStatusKeys,
                isAdept: isAdept,
                isBrite, isBrite,
                isAms, isAms
            };

            var config = {
                params: params
            };

            return $http.get('api/subscribers', config);
        }

        return {
            get: get,
            getAdeptSubscribers: getAdeptSubscribers,
            getAmsSubscribers: getAmsSubscribers,
            getAmsSubscribersPaging: getAmsSubscribersPaging,
            setAdminSubscriberId: setAdminSubscriberId,
            getAdminSubscriberId: getAdminSubscriberId,
            getSubscriberId: getSubscriberId,
            listV2: listV2
        }
    }
]);