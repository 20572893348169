angular.module('angus.controllers')
    .controller('signInCtrl', [
        '$scope', '$rootScope', '$location', '$http', '$timeout', '$window', 'authenticationService', 'moment', 'brandingService', '$q', 'modalService', 'fluentRest', '$mdToast', '$analytics',
        function($scope, $rootScope, $location, $http, $timeout, $window, authenticationService, moment, brandingService, $q, modalService, fluentRest, $mdToast) {

            'use strict';

            $scope.$broadcast('show-errors-check-validity');
            $scope.alerts = [];

            $scope.branding = brandingService;

            $scope.showTerms = false;

            $scope.toggleTerms = function(){
                $scope.showTerms = !$scope.showTerms;
            }

            $scope.signIn = function() {
                var deferred = $q.defer();
                $scope.alerts = [];

                $http.post('/identity/login-legacy', $scope.model, {apiV2: true})
                    .success(function(data) {
                        $rootScope.user = authenticationService.setUserInfo(data.user, data.token, data.expires);

                        $location.path('/');

                        return deferred.resolve();
                    }).error(function(data) {
                        delete $scope.model.password;
                        authenticationService.clearUserInfo();

                        $mdToast.show($mdToast.simple().textContent(((data && data.message) || 'Invalid username or password.')));

                        return deferred.reject();
                    });

                    return deferred.promise;
            };
                

            $scope.closeAlert = function(index) {
                $scope.alerts.splice(index, 1);
            };
        }
    ]);
