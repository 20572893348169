angular
	.module('angus.controllers')
	.controller('paygoTMSAdminTankGeometryController', ['$scope', '$http', '$q', 'constantsService', 'promiseMonitor', 'alertsService', 'paygoTMSAdminScopeService', PaygoTMSAdminTankGeometryController]);

function PaygoTMSAdminTankGeometryController($scope, $http, $q, constants, PromiseMonitor, AlertsService, paygoTMSAdminScopeService){
	'use strict';

	function createModel(result){
		$scope.activeItem.calculatedTankSize = result.data.calculatedTankSize;
		$scope.activeItem.model = {
			shapeId					: result.data.shape.id,
            monitorTypeKey          : result.data.monitorTypeKey,
			monitorAntennaTypeId	: result.data.monitorAntennaType.id,
			locationId				: result.data.location.id,
			length 					: result.data.dimensions.length,
			height   				: constants.containerShape.shapeRequiresHeight(result.data.shape.id)   ? result.data.dimensions.height : undefined,
			width   				: constants.containerShape.shapeRequiresWidth(result.data.shape.id)    ? result.data.dimensions.width : undefined,
			diameter   				: constants.containerShape.shapeRequiresDiameter(result.data.shape.id) ? result.data.dimensions.width : undefined,
			sensorOffset			: result.data.sensorOffset,
			safeCapacity			: result.data.safeCapacity || undefined,
			outletHeight			: result.data.outletHeight || undefined
		};
	}

	function getContainer(){
		var opts = { method : 'GET', url: 'api/subscribers/{0}/containers/{1}/monitors'.format($scope.activeItem.monitor.subscriberId, $scope.activeItem.monitor.tankId)};
		return $http(opts).then(createModel);
	}

	/* Create Directives */
	function getShapeTypes(){
		var opts = { method : 'GET', url : 'api/subscribers/{0}/containers/shapeTypes'.format($scope.activeItem.monitor.subscriberId)};
		return $http(opts).then(function(result){ $scope.shapes =  result.data; });
	}

	function getLocationTypes(){
		var opts = { method : 'GET', url: 'api/subscribers/{0}/containers/locationTypes'.format($scope.activeItem.monitor.subscriberId)};
		return $http(opts).then(function(result){ $scope.locations =  result.data; });
	}

    function getMonitorTypes() {
        var opts = { method : 'GET', url : 'api/subscribers/{0}/containers/monitors/monitorTypes'.format($scope.activeItem.monitor.subscriberId) };
        return $http(opts)
            .then(function(result) {
                $scope.monitorTypes =  result.data;
            });
    }

	function getMonitorAntennaTypes(){
		var opts = { method : 'GET', url : 'api/subscribers/{0}/containers/monitors/antennaTypes'.format($scope.activeItem.monitor.subscriberId) };
		return $http(opts).then(function(result){ $scope.antennaTypes =  result.data; });
	}
	/* END : Create Directives */

	$scope.shapeRequiresHeight 		= constants.containerShape.shapeRequiresHeight;
	$scope.shapeRequiresWidth 		= constants.containerShape.shapeRequiresWidth;
  $scope.shapeRequiresDiameter 	= constants.containerShape.shapeRequiresDiameter;
  $scope.shapeWidthIsHeight    	= constants.containerShape.shapeWidthIsHeight;

  $scope.updateContainer = function(model){
		var width = model.width, height = model.height;

		if($scope.shapeRequiresDiameter(model.shapeId)){
			width 	= model.diameter;
			height  = model.length;
		}

    if($scope.shapeWidthIsHeight(model.shapeId)){
      height = width;
    }

		var data = {
			containerId 			: $scope.activeItem.monitor.tankId,
			locationId 				: model.locationId,
            monitorTypeKey          : model.monitorTypeKey,
			monitorAntennaTypeId 	: model.monitorAntennaTypeId,
			shapeId 				: model.shapeId,
			height 					: height,
			width 					: width,
			length 					: model.length,
			sensorOffset 			: model.sensorOffset,
			safeCapacity 			: model.safeCapacity || 0,
			outletHeight 			: model.outletHeight || 0,
		};

		var opts = { method : 'PUT', url : 'api/subscribers/{0}/containers/{1}/monitors'.format($scope.activeItem.monitor.subscriberId, $scope.activeItem.monitor.tankId), data : data};
		var promise =  $http(opts)
			.then(createModel)
			.catch(function(){
				$scope.alerts.addError('Unable to save. Invalid tank geometry configuration.');
				return getContainer();
			});

		return $scope.containerMonitor.monitor(promise);
	};

	function init(){

		$scope.alerts = new AlertsService();

		var promises = [
			getContainer(),
			getShapeTypes(),
			getLocationTypes(),
            getMonitorTypes(),
			getMonitorAntennaTypes()
		];

		var allPromise = $q.all(promises);

		$scope.containerMonitor = new PromiseMonitor(allPromise);
	}

	init();
}
