angular.module( 'angus.directives' ).directive( 'acctGainLossCustomers', [
	'actionViewTemplates',
	function( actionViewTemplates ) {
		return {
			scope: {
				closeCb    : "=",
				model      : "=actionModel",
				settings   : "=acctGainLossCustomersSettings",
				widgetCode : "="
			},
			templateUrl: actionViewTemplates.acctGainLossCustomersContainer
		};
	}
] );