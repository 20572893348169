angular.module('angus.controllers').controller('performanceSettingsCtrl', [
    '$scope',
    function($scope) {
        'use strict';

        $scope.widget.instance.settings.year  = $scope.widget.instance.settings.year  || new Date().getFullYear();
        $scope.widget.instance.settings.month = $scope.widget.instance.settings.month || new Date().getMonth();
        $scope.widget.instance.settings.workingDaySubtraction = $scope.widget.instance.settings.workingDaySubtraction || 2;
        $scope.widget.instance.settings.displayEmpty = $scope.widget.instance.settings.displayEmpty || false;
        var yearsContainer = [];
        for(var i = 0; i < 11; i++){
            yearsContainer.push(new Date().getFullYear() - i);
        }

        $scope.years = yearsContainer;

        $scope.months = [{
            value: 1,
            name: 'January'
        }, {
            value: 2,
            name: 'February'
        }, {
            value: 3,
            name: 'March'
        }, {
            value: 4,
            name: 'April'
        }, {
            value: 5,
            name: 'May'
        }, {
            value: 6,
            name: 'June'
        }, {
            value: 7,
            name: 'July'
        }, {
            value: 8,
            name: 'August'
        }, {
            value: 9,
            name: 'September'
        }, {
            value: 10,
            name: 'October'
        }, {
            value: 11,
            name: 'November'
        }, {
            value: 12,
            name: 'December'
        }];

    }
]);