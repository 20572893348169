angular.module('angus.controllers').controller('serviceReworkReportContainerCtrl', [
    '$scope', '$rootScope', '$q', '$http', 'periodIndicators', 'productsService', 'actionViewTemplates', 'constantsService', '_', 'Paging', 'moment', 'restrictedAccessService',
    function($scope, $rootScope, $q, $http, periodIndicators, productsService, actionViewTemplates, constantsService, _, Paging, moment, restrictedAccessService) {

        'use strict';

        $scope.pageSize = 25;
        $scope.sideNavTemplate = actionViewTemplates.sideNav;
        $scope.startItemName = 'Report 1';

        $scope.listReport = {
            name: 'Saved Filters',
            icon: 'tasks',
            templateUrl: actionViewTemplates.serviceReworkReportList,
            active: true,
        };
        $scope.report = {
            name: 'Report 1',
            icon: 'table',
            templateUrl: actionViewTemplates.serviceReworkReportContent,
            active: true,
            paging: new Paging($scope.pageSize),
            sorting: {
                sortProp: 'date',
                sortDir: 'asc'
            },
            report: [],
        };

        $scope.items = [$scope.listReport];

        function init() {
            if ($scope.model) {
                $scope.addNewOpenReport(true, false, true);

                $scope.activeItem.dates = {
                    lastPostingDate: $scope.model.lastPostingDate,
                    periodStartDate: $scope.model.periodStartDate
                };
            } else {
                $scope.activeItem = $scope.report;

                $scope.activeItem.dates = {
                    lastPostingDate: $scope.model.lastPostingDate || moment(),
                    periodStartDate: $scope.model.periodStartDate || moment()
                };

                $scope.items.push($scope.activeItem);
            }
        }

        $scope.switchActiveItem = function(item) {
            if (item.active) {
                $scope.activeItem.aggregateBackup = $scope.activeItem.aggregates;
                delete $scope.activeItem.aggregates;

                $scope.activeItem = item;
            }
        };

        $scope.$watch(function() {
            return $scope.model;
        }, function(newVal, oldVal) {
            if (oldVal != newVal)
                init();
        });

        $scope.addNewOpenReport = function(filter, resetDetailedReport, addNewMetricReport) {
            if (filter) {
                var openReport = _.find($scope.items, function(item) {
                    return item.name == filter.name;
                });
                if (!openReport) {
                    if (addNewMetricReport) {
                        openReport = {
                            name: 'Report ' + $scope.items.length,
                            icon: 'table',
                            templateUrl: actionViewTemplates.serviceReworkReportContent,
                            active: true,
                            dates: {
                                lastPostingDate: $scope.model.lastPostingDate,
                                periodStartDate: $scope.model.periodStartDate
                            },
                            closeable: $scope.items.length > 1,
                            paging: new Paging($scope.pageSize),
                            sorting: {
                                sortProp: 'date',
                                sortDir: 'asc'
                            },
                            report: [],
                        };
                    } else {
                        openReport = {
                            name: filter.name,
                            icon: 'user',
                            templateUrl: actionViewTemplates.serviceReworkReportContent,
                            active: true,
                            filter: filter,
                            closeable: true,
                            dates: {
                                lastPostingDate: $scope.model.lastPostingDate,
                                periodStartDate: $scope.model.periodStartDate
                            },
                            paging: new Paging($scope.pageSize),
                            sorting: {
                                sortProp: 'date',
                                sortDir: 'asc'
                            },
                            report: [],
                        };
                    }
                    $scope.items.push(openReport);
                }
                $scope.activeItem = openReport;
            }

            if (resetDetailedReport) $scope.resetDetailedReport();
        };

        $scope.resetDetailedReport = function() {
            var detailedReport = _($scope.items).find({
                name: 'Report 1'
            });

            detailedReport.aggregates = null;
            detailedReport.filter = null;
        };

        $scope.renameActiveReportTab = function(newName) {
            $scope.activeItem.name = newName;
        };

        $scope.closeItem = function(report) {
            if (report) {
                var openReport = _.find($scope.items, function(item) {
                    return item.name == report.name;
                });
                var index = $scope.items.indexOf(openReport);

                if ($scope.activeItem == openReport)
                    $scope.activeItem = $scope.items[index - 1];

                $scope.items.splice(index, 1);
            }
        };

        $http.get(('api/subscribers/{0}/service/techs').format($rootScope.user.subscriberId))
            .then(function(list) {
                $scope.techs = list.data;
            });

        init();


        var headingCenterClass = 'text-center clickable';


        function getRestrictedAccess() {
            return $q.when($scope.restrictedAccess || restrictedAccessService
              .getAccess()
              .then(function (access) {
                return $scope.restrictedAccess = access.restrictedAccess;
              }));
        }

        $scope.aggregateHeadings = [{
            name: 'Service Center',
            isHidable: false,
            propClass: headingCenterClass,
            propName: 'serviceCenterName',
            isSortable: false
        }, {
            name: 'Tech',
            isHidable: false,
            propClass: headingCenterClass,
            propName: 'primaryTech',
            isSortable: false
        }, {
            name: 'Calls',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'totalCalls',
            isSortable: false
        }, {
            name: 'Rework',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'totalCallbacks',
            isSortable: false
        }, {
            name: '% Rework',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'percentCallbacks',
            isSortable: false
        }];

        var tableHeadings = [{
            name: 'Date',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'date',
            isSortable: true
        }, {
            name: 'Work Order #',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'workOrderNumber',
            isSortable: true
        }, {
            name: 'Account #',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'accountNumber',
            isSortable: true
        }, {
            name: 'Customer Name',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'customerName',
            isSortable: true
        }, {
            name: 'Svc Loc',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'locationNumber',
            isSortable: true
        }, {
            name: 'Call Reason',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'callReason',
            isSortable: true
        }, {
            name: 'Primary Tech',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'primaryTech',
            isSortable: true
        }, {
            name: 'Billed Hours',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'laborHoursBilled',
            isSortable: true
        }, {
            name: 'Contract Description',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'contractDescription',
            isSortable: true
        }, {
            name: 'Contract Renewal Date',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'contractRenewalDate',
            isSortable: true
        }, {
            name: 'Parts Margin',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'partsMargin',
            isSortable: true
        }, {
            name: 'Labor Margin',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'laborMargin',
            isSortable: true
        }, {
            name: 'Call Margin',
            isHidable: true,
            propClass: headingCenterClass,
            propName: 'callMargin',
            isSortable: true
        }];

        $scope.tableHeadings = [];

        getRestrictedAccess()
            .then(function (access) {
                _.forEach(tableHeadings, function (heading) {            
                  if ( (access.revenue && (heading.propName == 'laborHoursBilled')) 
                    || (access.costAndMargin && (heading.propName == 'partsMargin' || heading.propName == 'laborMargin' || heading.propName == 'callMargin')))
                    return;

                    $scope.tableHeadings.push(heading);
                });
        });
    }
]);
