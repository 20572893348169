angular
  .module('angus.utils')
  .factory('promiseMonitor', ['$q', function ($q) {
    'use strict';

    function makePromise(promise) {
      if (typeof promise == 'function') promise = promise();
      return $q.when(promise);
    }

    function promiseMonitor(promise) {
      if(promise) this.reset(promise);
    }

    promiseMonitor.prototype.isPending = function () {
      return this._monitored ? this._monitored.length > 0 : undefined;
    }

    promiseMonitor.prototype.reset = function (promise) {
      this._monitored = [];
      this.completedPromises = 0;
      this.failedPromises    = 0;
      if (promise) return this.monitor(promise);
    }

    promiseMonitor.prototype.monitor = function (promise) {
      if(!this._monitored) this.reset();
      if(!promise) return promiseMonitor;

      promise = makePromise(promise);

      this._monitored.push(promise);

      function onComplete() {
        var idx = this._monitored.indexOf(promise);
        this._monitored.splice(idx, 1);
        this.completedPromises++;
      }

      function onFail() {
        var idx = this._monitored.indexOf(promise);
        this._monitored.splice(idx, 1);
        this.failedPromises++;
      }

      promise.then(angular.bind(this, onComplete), angular.bind(this, onFail));
      return promise;
    }

    return promiseMonitor;
  }])
  .factory('promiseQueue', ['moment', '$q', function (moment, $q) {
    'use strict';


    function promiseQueue() {
      this._concurrentPromiseLimit  = 3;
      this.totalPromises            = 0;
      this.completedPromises        = 0;
      this.executingPromises        = 0;
      this._queue                   = [];
    }
    var index = 0;
    promiseQueue.prototype.enqueue = function(promiseFunc, name){
      var deferred = $q.defer();

      var queueItem = {
        promiseFunc : promiseFunc,
        name        : name,
        index       : index++,
        d           : deferred
      };

      if(this.executingPromises < this._concurrentPromiseLimit)
        this._execute(queueItem);
      else{
        queueItem.enteredQueue = moment();
        this._queue.push(queueItem);
      }

      return deferred.promise;
    }

    promiseQueue.prototype._dequeue = function(){
      return this._queue.length !== 0 ? this._queue.shift() : undefined;
    }


    function promiseRan(that, queueItem){
        return function(){
          // var output = queueItem.index + ' ' + queueItem.name;
          // output += ', Queued: ';
          // output+=queueItem.enteredQueue ? queueItem.beganExecute.diff(queueItem.enteredQueue) : 0;
          // output+='ms, Executed: ';
          // output+=queueItem.beganExecute ? moment().diff(queueItem.beganExecute) : 0;
          // output+='ms';
          // console.log(output);


          that.completedPromises++;
          that.executingPromises--;
          that._execute(that._dequeue(), true);
        }
    }

    function functionError(that, queueItem, reason){
          var output = queueItem.index + ' ' + queueItem.name;
          output += ', Queued: ';
          output+= queueItem.enteredQueue ? queueItem.beganExecute.diff(queueItem.enteredQueue) : 0;
          output+='ms, Executed: Failure - ';
          output+= reason;
          console.log(output);
          that._execute(that._dequeue(), true);
    }

    promiseQueue.prototype._execute = function(queueItem){
      if(!queueItem || !queueItem.promiseFunc) return;
      queueItem.beganExecute = moment();
      var that = this;
      var promise;
      try {
        promise = queueItem.promiseFunc();
      }
      catch(err) {
        functionError(that, queueItem, 'Didn\'t handle it\'s errors -')
        throw err;
        return;
      }

      if(!promise || !promise.then){
        functionError(that, queueItem, 'Not a promise');
        return;
      } else {
          this.executingPromises++;
          promise
              .then(promiseRan(that, queueItem))
              .catch(promiseRan(that, queueItem, true))
              .then(function(){
                queueItem.d.resolve();
              });
      }
    }

    return promiseQueue;
  }]);
