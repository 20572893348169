 angular.module('angus.services').factory('maintenanceUpdateService', [
    '$http', function ($http) {
 
         const getMaintenanceUpdate = function(maintenanceUpdateHeaderKey, maintenanceUpdateID) { 
             let url =  'api/tankMonitors/maintenanceUpdate';
             if (maintenanceUpdateID || maintenanceUpdateHeaderKey) {
                url += '?';
             }
             if (maintenanceUpdateID) {
                url += 'maintenanceUpdateID=' + maintenanceUpdateID;
             }
             if (maintenanceUpdateHeaderKey) {
                url += 'maintenanceUpdateHeaderKey=' + maintenanceUpdateHeaderKey;
             } 
             return $http.get((url)).then(function (res) {
                        return res.data;
                    });
         }; 
 
         const postMaintenanceUpdate = function(data) {
            return $http.post('api/tankMonitors/monitors/maintenanceUpdate', data);
         }; 

        return {
            getMaintenanceUpdate,
            postMaintenanceUpdate 
        };
     }
 ]);
 
 