angular.module('angus.controllers').controller('degreeDayMetricsSettingsCtrl', [
    '$http', '$q', '$rootScope', '$scope', 'containerService', 'degreeDayService', 'deliveryTypeService', 'productsService', 'promiseMonitor', 'tradeClassService',
    function ($http, $q, $rootScope, $scope, containerService, degreeDayService, deliveryTypeService, productsService, promiseMonitor, tradeClassService) {

        $scope.promiseMonitor = new promiseMonitor();

        $scope.subscriberId = $rootScope.user.subscriberId;

        $scope.persistedSettings = {
            containerTypeIds: $scope.widget.instance.settings.containerTypeIds.value
        };


        /* Functions */
        $scope.containerTypesChanged = containerTypesChanged;
        $scope.productCategoriesChanged = function () { loadContainerTypes($scope.subscriberId, $scope.widget.instance.settings.productCategoryIds.value); };


        /* Watches */
        $scope.$watch('widget.instance.settings.deliveryTypeIds.value', updateHourBasedMetrics);
        $scope.$watch('widget.instance.settings.tradeClassIds.value', updateHourBasedMetrics);
        $scope.$watch('widget.instance.settings.includeMonitoredTanks.value', updateHourBasedMetrics);
        $scope.$watch('widget.instance.settings.includeAdeptTransactions.value', updateHourBasedMetrics);
        $scope.$watch('widget.instance.settings.includeGroupOptimizedTransactions.value', updateHourBasedMetrics);
        

        $scope.updateAvailableMetrics = updateAvailableMetrics; 

        $scope.containerTypeSort = function(a, b) {
            if (a.id < b.id) {
                return -1;
            }

            if (a.id > b.id) {
                return 1; 
            }

            return 0;
        }


        /* Load Data */
        var promise = $q.all([
            productsService.getProducts($scope.subscriberId)
                .then(function(productCategories) {
                    $scope.productCategories = productCategories;
                }),
            deliveryTypeService.getDeliveryTypes($scope.subscriberId)
                .then(function(deliveryTypes) {
                    $scope.deliveryTypes = deliveryTypes;
                }),
            tradeClassService.getTradeClasses($scope.subscriberId)
                .then(function(tradeClasses) {
                    $scope.tradeClasses = tradeClasses
                }),
            degreeDayService.getDegreeDaySources($scope.subscriberId)
                .then(function(sources){
                    $scope.sources = sources;
                }),
            querySubscriber($scope.subscriberId)
                .then(function(subscriber) {
                    $scope.subscriber = subscriber
                }),
            loadContainerTypes($scope.subscriberId, $scope.widget.instance.settings.productCategoryIds.value)
        ]);

        $scope.metrics = degreeDayService.getMetrics();

        $scope.metrics.forEach(metric => {
            var index = $scope.widget.instance.settings.availableMetricIds.value.indexOf(metric.id);

            if (index >=0) {
                metric.isAvailable = true;
            } else {
                metric.isAvailable = false;
                metric.isActive = false;
            }
        });

        /* Evaluate settings and disable any Hour Based Metrics if needed */
        updateHourBasedMetrics();
    
        $scope.promiseMonitor.monitor(promise);

        function loadContainerTypes(subscriberId, productCategoryIds) {

            $scope.areContainerTypesLoading = true;

            $scope.containerTypes = [];
            $scope.widget.instance.settings.containerTypeIds.value = [];

            return containerService.getContainerTypes(subscriberId, undefined, productCategoryIds, undefined, undefined, undefined)
                .then(function (containerTypes) {
                    $scope.containerTypes = containerTypes;

                    const persistedContainerTypeIds = $scope.persistedSettings.containerTypeIds

                    if (persistedContainerTypeIds && persistedContainerTypeIds.length > 0) {
                        const dropDownContainerTypeIds = _.map($scope.containerTypes, function (containerType) {
                            return containerType.id;
                        });

                        $scope.widget.instance.settings.containerTypeIds.value = _.intersection(dropDownContainerTypeIds, persistedContainerTypeIds);
                    }

                    $scope.areContainerTypesLoading = false;
                });
        }

        function querySubscriber(subscriberId) {
            return $http.get(("api/subscribers/{0}").format(subscriberId))
                .then(response => response.data);
        }

        function containerTypesChanged() {
            $scope.persistedSettings.containerTypeIds = $scope.widget.instance.settings.containerTypeIds.value;

            updateHourBasedMetrics();
        }

        function updateAvailableMetrics (metric) {

            var availableMetricIds = $scope.widget.instance.settings.availableMetricIds.value

            if (metric.isAvailable) {
                availableMetricIds.push(metric.id);
            } else {
                var index = availableMetricIds.indexOf(metric.id);

                if (index >= 0) {
                    availableMetricIds.splice(index, 1);
                }

                /* Make sure setting is not active */
                /* Line Graph can only have a single metric selected. If they disable the selected metric then set the setting to null */
                if ($scope.widget.instance.settings.lineGraphSelectedMetricId.value === metric.id) {
                    $scope.widget.instance.settings.lineGraphSelectedMetricId.value = null;
                }

                /* Bar Graph has an array of selected metrics. If the one being disabled is one of them then remove it. */
                var barGraphSelectedMetricIds = $scope.widget.instance.settings.barGraphSelectedMetricIds.value;

                var barGraphIndex = barGraphSelectedMetricIds.indexOf(metric.id);
                if (barGraphIndex >= 0) {
                    barGraphSelectedMetricIds.splice(barGraphIndex, 1);
                }
            }
        }

        function updateHourBasedMetrics() {
            var areHoursBasedMetricsDisabled = false;

            const settings = $scope.widget.instance.settings;

            /* Any Delivery Type or Any Tank Size have been selected */
            if (settings.deliveryTypeIds.value.length > 0 || settings.containerTypeIds.value.length > 0) {
                areHoursBasedMetricsDisabled = true;
            } 
            /* Any Trade Classes have been selected */
            else if (settings.tradeClassIds.value.length > 0) {
                areHoursBasedMetricsDisabled = true;
            }
            /* Include Monitored, Adept Transactions, or Optimized Transactions are Exclude or Only */
            else if (settings.includeMonitoredTanks.value !== 'include' || settings.includeAdeptTransactions.value !== 'include' || settings.includeGroupOptimizedTransactions.value !== 'include') {
                areHoursBasedMetricsDisabled = true;
            }


            /* Set Disabled State */
            $scope.metrics.forEach(metric => {
                if (!areHoursBasedMetricsDisabled) {
                    /* Hours Metrics do not need to be disabled */
                    metric.isDisabled = false;
                }
                else if (metric.isHoursMetric && areHoursBasedMetricsDisabled) {
                    /* Hours Metrics need to be disabled. If any are available, then also mark it as not available. */
                    metric.isDisabled = true;

                   if (metric.isAvailable) {
                        metric.isAvailable = false;

                        /* Remove it from the list of available metrics */
                        updateAvailableMetrics(metric);
                    }
                }
                else {
                    metric.isDisabled = false;
                }
            });
        }

    }
]);