angular.module('angus.directives')
    .directive('excludedDriversSelect', [function() {
        'use strict';

        return {
            restrict: 'A',
            scope: {
                ngModel: '=',
                subscriberId: '=',
                deliveryCenterId: '='
            },
            controller: ['$scope', '$rootScope', '$http', function($scope, $rootScope, $http) {

                $scope.getDrivers = function() {
                    $http({
                        url: ('api/subscribers/{0}/drivers').format($scope.subscriberId || $rootScope.user.subscriberId),
                        method: 'POST',
                        data: {
                            deliveryCenterId: $scope.deliveryCenterId,
                            excludeDrivers: true
                        }
                    }).then(function(drivers) {
                        $scope.drivers = drivers.data;
                    });
                };

                $scope.getDrivers();
            }],
            template: '<div multi-select-ok-wrapped="drivers" ng-model="ngModel" display-prop="\'name\'" selection-prop="\'id\'"></div>',
            link: function(scope) {
                scope.$watch(function() {
                    return scope.deliveryCenterId;
                }, function(newVal, oldVal) {
                    if(newVal && newVal != oldVal) scope.getDrivers();
                });
            }
        };
    }]);
