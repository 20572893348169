angular.module('angus.controllers').controller('serviceContractsProfileCtrl', [
    '$scope', '$rootScope', '$q', '$http', 'hierarchyService',
    function($scope, $rootScope, $q, $http, hierarchyService) {
        'use strict';

        var divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);

        $scope.widget.instance.settings.activeContractsCount = $scope.widget.instance.settings.activeContractsCount || false;
        $scope.widget.instance.settings.coveredSystemsCount = $scope.widget.instance.settings.coveredSystemsCount || false;

        function getServiceContractCounts() {
            var promise =  $http({
                method: 'POST',
                url: ('api/subscribers/{0}/serviceContracts/counts').format($rootScope.user.subscriberId),
                data: {
                    divisionId  : divisions,
                    serviceContractTypes: $scope.widget.instance.settings.serviceContractTypes ? $scope.widget.instance.settings.serviceContractTypes.value : null
                }
            });

            return promise
                .then(function(counts) {
                    return counts.data;
                });
        }

        function init() {
            var promise = getServiceContractCounts()
                .then(function(counts){
                    $scope.counts = counts[0];
                });

            return $scope.widget.promises.monitor(promise);
        }

        $scope.widget.setDefaultInitialization(init);

        $scope.$on('widgetSettingsChanged', function() {
            divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
            init();
        });
    }
]);