angular.module('angus.controllers')
    .controller('footerCtrl', [
        '$scope', 'brandingService', 'modalService', 'actionViewTemplates',
        function($scope, brandingService, modalService, actionViewTemplates) {
            'use strict';

            $scope.isPaygo = brandingService.isPaygo();

            $scope.openETLStatus = function() {
              modalService.openTopBarModal(actionViewTemplates.etlStatusPopover);
            };
        }
    ]);
