angular
.module('angus.directives')
.directive('widgetSettings', [function() {

        return {
            link: function (scope, element) {
                
                function click(event) {
                    event.stopPropagation();
                }

                function mousedown(event) {
                    if (event.which == 1) {
                        event.stopPropagation();
                        scope.widget.editSettings();
                    }
                }

                element.on('click', click).on('mousedown', mousedown);

                element.on('contextmenu', function (e) {
                    e.stopPropagation();
                });

            }
        };
    }
])
.directive('widgetDelete', [function() {
        return {
            link: function (scope, element) {
                
                function click(event) {
                    event.stopPropagation();
                }

                function mousedown(event) {
                    if (event.which == 1) {
                        event.stopPropagation();
                        scope.widget.delete();
                    }
                }

                element.on('click', click).on('mousedown', mousedown);

                element.on('contextmenu', function (e) {
                    e.stopPropagation();
                });

            }
        };
    }
])