angular.module('angus.services').service('dealerService', [
    '$http',
    function($http) {
        function getDealers(subscriberId) {
            return queryDealers(subscriberId, null)
                .then(function(res) {
                    return res.data;
                });
        }

        function getDealersForHierarchyNode(subscriberId, nodeId) {
            return queryDealers(subscriberId, nodeId)
                .then(function(res) {
                    return res.data;
                });
        }

        function queryDealers(subscriberId, nodeId) {
            var url = ('api/subscribers/{0}/dealers').format(subscriberId);

            if (nodeId){
                (url + '/{0}').format(nodeId);
            }

            return $http.get(url);
        }

        return {
            getDealers: getDealers,
            getDealersForHierarchyNode: getDealersForHierarchyNode
        };
    }
]);