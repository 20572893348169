angular.module('angus.controllers').controller('tankProfAnalysisCtrl', [
    '$scope', '$rootScope', '$http', '_', 'hierarchyService',
    function($scope, $rootScope, $http, _, hierarchyService) {
        'use strict';

        // on initial widget load, set default values for dropdowns in settings view
        if(typeof $scope.widget.instance.settings.inactiveTanks === "undefined") {
            $scope.widget.instance.settings.inactiveTanks = {value : 'N' };
        }
        if(typeof $scope.widget.instance.settings.customerOwned === "undefined") {
            $scope.widget.instance.settings.customerOwned = { value : 'Y' };
        }
        if(typeof $scope.widget.instance.settings.monitored === "undefined") {
            $scope.widget.instance.settings.monitored = { value : 'Y' };
        }

        function getTotals(){
            return $http({
                method: 'POST',
                url: ('api/subscribers/{0}/tanks/counts').format($rootScope.user.subscriberId),
                data: {
                    productIds  : $scope.widget.instance.settings.productIds.value,
                    customerOwned : $scope.widget.instance.settings.customerOwned.value,
                    useBrite    : $scope.widget.instance.settings.useBrite.value,
                    monitored   : $scope.widget.instance.settings.monitored.value,
                    inactiveTanks : $scope.widget.instance.settings.inactiveTanks.value,
                    totals      : true,
                    leafIds     : hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes,  $scope.widget.instance.settings.hierarchyNodeId)
                }
            })
              .then(function(counts){
                  return counts.data;
              });
        }

        function init() {
            return $scope.widget.promises.monitor(
                getTotals()
                    .then(function(counts){
                        $scope.deliveryTypes = counts.active.deliveryTypes;
                        $scope.active = counts.active.total;
                        $scope.inactive = counts.inactive.total;
                        $scope.total = counts.total;

                        $scope.data =_.map($scope.deliveryTypes, function(deliveryType){
                            return { label: deliveryType.name,  data: [[1, deliveryType.total]]};
                        });
                    })
            );
        }

        $scope.widget.setDefaultInitialization(init);

        $scope.$on('widgetSettingsChanged', function() {
            init();
        });
    }
]);
