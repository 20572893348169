angular.module( 'angus.directives' ).directive( 'checkboxRequired', [

	function() {
		return {
			link: function( scope, elem, ctrl ) {

				scope.$watch( function() {
					var checkboxes = elem.find( 'input' );
					_( checkboxes ).forEach( function( checkbox ) {
						if ( checkbox.value != null ) {
							return true;
						}
					} );
					return false;
				}, function( currentValue ) {
					ctrl.$setValidity( 'checkbox', currentValue );
				} );
			}
		};
	}
] );