angular.module('angus.services').service('csvService', ['$rootScope', '$http', 'saveAs',
    function($rootScope, $http, saveAs) {

        'use strict';

        var downloadInProgress = false;

        function prepareDownload(filename, data, type) {
            saveAs(
                new Blob([data], { type: type }),
                filename
            );
        }

        function getCsv(url, data, title, usePOST) {
            downloadInProgress = true;
            data.csv = true;

            var request = {
                url: url,
                method: 'GET',
                params: data
            };

            if(!!usePOST) {
                request.method = 'POST';
                request.data = request.params;
                delete request.params;
            }

            return $http(request)
                .then(function(response) {
                    var headers = response.headers();
                    var filename = title ? (title + '.csv') : headers['content-disposition'].match(/filename[^;=\n]*=((['']).*?\2|[^;\n]*)/)[1];
                    if(!filename) throw new Error('Filename not specified on client or server');

                    prepareDownload(filename, response.data, { type: headers['content-type'] });
                })
                .finally(function() {
                    downloadInProgress = false;
                });
        }

        return {
            downloadInProgress: function() {
                return downloadInProgress;
            },
            getCsv: function(url, data, title) {
                return getCsv(url, data, title, false);
            },
            postGetCsv: function(url, data, title) {
                return getCsv(url, data, title, true);
            },
            prepareDownload: prepareDownload
        };
    }
]);