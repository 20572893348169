angular.module('angus.controllers').controller('defaultDashboardsCtrl', [
    '$scope', '$http', 'widgetLibraryService', 'modalService', 'actionViewTemplates', '_', '$q', 'defaultDashboardsService', '$mdToast', 'widgetPackageService',
    function($scope, $http, widgetLibraryService, modalService, actionViewTemplates, _, $q, defaultDashboardsService, $mdToast, widgetPackageService) {

        'use strict';

        var originalCol, originalRow;

        function dragStart(event, $element, widget) {
            originalCol = widget.col;
            originalRow = widget.row;
        }

        function dragStop(event, $element, widget) {
            defaultDashboardsService
                .updateWidgets($scope.selected)
                .then(function() {
                    originalCol = undefined;
                    originalRow = undefined;
                });
        }

        var originalX, originalY;

        function resizeStart(event, $element, widget) {
            originalX = widget.sizeX;
            originalY = widget.sizeY;
        }

        function resizeStop(event, $element, widget) {
            defaultDashboardsService
                .updateWidgets($scope.selected)
                .then(function() {
                    originalX = undefined;
                    originalY = undefined;
                });
        }
        $scope.$on('widgetSettingsUpdated', function(event, widgetId, settings, widgetCode) {

            if( $scope && !$scope.activeDashboard){
                $scope.activeDashboard = $scope.selected;
            }
        
            defaultDashboardsService.updateWidgetSettings($scope.activeDashboard, widgetId, settings, widgetCode);
        });

        $scope.customItemMap = {
            sizeX: 'widget.instance.size.columns',
            sizeY: 'widget.instance.size.rows',
            row: 'widget.instance.location.row',
            col: 'widget.instance.location.column'
        };
        $scope.gridsterOptions = {
            columns: 12, // the width of the grid, in columns
            pushing: true, // whether to push other items out of the way on move or resize
            floating: true, // whether to automatically float items up so they stack (you can temporarily disable if you are adding unsorted items with ng-repeat)
            swapping: false, // whether or not to have items of the same size switch places instead of pushing down if they are the same size
            width: 'auto', // can be an integer or 'auto'. 'auto' scales gridster to be the full width of its containing element
            colWidth: 'auto', // can be an integer or 'auto'.  'auto' uses the pixel width of the element divided by 'columns'
            rowHeight: 'match', // can be an integer or 'match'.  Match uses the colWidth, giving you square widgets.
            margins: [20, 20], // the pixel distance between each widget
            outerMargin: true, // whether margins apply to outer edges of the grid
            isMobile: false, // stacks the grid items if delete
            mobileBreakPoint: 800, // if the screen is not wider that this, remove the grid layout and stack the items
            mobileModeEnabled: true, // whether or not to toggle mobile mode when screen width is less than mobileBreakPoint
            minColumns: 1, // the minimum columns the grid must have
            minRows: 2, // the minimum height of the grid, in rows
            maxRows: 100,
            defaultSizeX: 2, // the default width of a gridster item, if not specifed
            defaultSizeY: 1, // the default height of a gridster item, if not specified
            minSizeX: 2, // minimum column width of an item
            maxSizeX: null, // maximum column width of an item
            minSizeY: 2, // minumum row height of an item
            maxSizeY: null, // maximum row height of an item
            resizable: {
                enabled: true,
                handles: ['sw', 'se'],
                start: resizeStart, // optional callback fired when resize is started,
                // resize            : function(event, $element, widget) {}, // optional callback fired when item is resized,
                stop: resizeStop // optional callback fired when item is finished resizing
            },
            draggable: {
                enabled: true, // whether dragging items is supported
                handle: '.widget-handle', // optional selector for resize handle
                start: dragStart, // optional callback fired when drag is started,
                // drag              : function(event, $element, widget) {}, // optional callback fired when item is moved,
                stop: dragStop // optional callback fired when item is finished dragging
            }
        };

        function getSecurityGroups() {
            return $http
                .get(('api/subscribers/{0}/securityGroups').format($scope.subscriberId))
                .then(function(securityGroups) {
                    return securityGroups.data;
                });
        }

        function init() {
            $scope.isLoading = true;
            $scope.dashboardsLoaded = false;
            getSecurityGroups()
                .then(function(securityGroups) {
                    $scope.securityGroups = securityGroups;
                    $scope.isLoading = false;
                });
        }

        $scope.openWidgetLibrary = function() {
            $scope.dataLoaded = false;

            $q.all([
                widgetPackageService.get(false, $scope.selected.securityGroupId),
                widgetLibraryService.getWidgets($scope.selected.securityGroupId)
            ])
                .then(function(results) {
                    if(results[0].length == 0)
                        $scope.widgetPackages = null;
                    else {
                        $scope.widgetPackages = _.reduce(results[0], function(result, widgetPackage) {
                            widgetPackage.widgets = [];
                            result[widgetPackage.widgetPackageKey] = widgetPackage;
                            return result;
                        }, {});

                        $scope.widgets = results[1];
                        _.forEach($scope.widgets, function(widget) {
                            _.forEach(widget.widgetPackageKeys, function(widgetPackageKey) {
                                $scope.widgetPackages[widgetPackageKey].widgets.push(widget);
                            });
                            widget.dashboardToAddTo = $scope.selected.id;
                        });
                    }

                    $scope.showWidgets = true;
                    $scope.dataLoaded = true;
                });
        };

        $scope.closeLibrary = function() {
            $scope.showWidgets = false;
        };

        function addToDashboard(widget, view) {
            return defaultDashboardsService
                .addWidget($scope.selected, widget, view)
                .then(function(widget) {
                    var toast = $mdToast
                        .simple()
                        .textContent('{0} was added to {1}.'.format(widget.name, $scope.selected.name))
                        .action('UNDO')
                        .highlightAction(true)
                        .hideDelay(5000);

                    $mdToast
                        .show(toast)
                        .then(function(response) {
                            if(response == 'ok')
                                return defaultDashboardsService.deleteWidget($scope.selected, widget.instance.id);
                        });
                });
        }

        $scope.removeWidgetFromDashboard = function(widget) {
            return defaultDashboardsService.deleteWidget($scope.selected, widget.instance.id);
        };

        $scope.saveEditSettings = function(widget) {
            return defaultDashboardsService.updateWidgetSettings($scope.selected, widget.instance.id, widget.instance.settings);
        };

        $scope.lockdownSettings = false;

        $scope.addWidgetToDashboard = function(widgetPackageKey, widget) {
            $scope.dataLoaded = false;

            widgetLibraryService
                .getViews(widgetPackageKey, widget.id, $scope.selected.securityGroupId)
                .then(function(views) {
                    if(views && views.length !== 0) {
                        if(views.length > 1) {
                            var scope = {
                                selectedView: null,
                                views: views,
                                widgetName: widget.name,
                                widgetIcon: widget.icon,
                                selectView: function(view) {
                                    if(this.selectedView == view)
                                        this.selectedView = null;
                                    else
                                        this.selectedView = view;
                                }
                            };

                            modalService
                                .openTopBarModal(actionViewTemplates.chooseWidgetView, scope)
                                .then(function(view) {
                                    if(view)
                                        addToDashboard(widget, view)
                                            .then(function() {
                                                $scope.dataLoaded = true;
                                            });

                                    else
                                        $scope.dataLoaded = true;
                                });
                        } else
                            addToDashboard(widget, views[0])
                                .then(function() {
                                    $scope.dataLoaded = true;
                                });
                    } else {
                        $scope.alerts.push({
                            type: 'danger',
                            msg: ('Can not retrieve views for <strong>{0}</strong>.').format(widget.name)
                        });
                        $scope.dataLoaded = true;
                    }
                });
        };


        $scope.getDashboards = function(securityGroupId) {
            if(!securityGroupId) return;
            $scope.dashboardsLoaded = false;

            //Initiialize the dashboard serice for default dashboards...
            defaultDashboardsService
                .init($scope.subscriberId, securityGroupId)
                .then(function(data) {
                    $scope.dashboards = data.dashboards;
                    $scope.dashboardsLoaded = true;
                });
        };


        $scope.addDashboard = function() {
            defaultDashboardsService
                .addDashboard()
                .then(function(dashboard) {
                    $scope.dashboards = defaultDashboardsService.dashboards;
                    $scope.selected = dashboard[0];
                });
        };

        $scope.removeDashboard = function(dashboard) {
            var scope = {
                title: 'Delete ' + dashboard.name + '?',
                message: 'Are you sure you would like to delete the dashboard \'' + dashboard.name + '\' from this security group?'
            };

            modalService
                .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                .then(function(result) {
                    if(result)
                    defaultDashboardsService
                            .deleteDashboard(dashboard)
                            .then(function() {
                                $scope.dashboards = defaultDashboardsService.dashboards;
                            });
                });
        };

        $scope.publishDashboard = function(dashboard) {
            var scope = {
                title: 'Publish ' + dashboard.name + '?',
                message: 'Are you sure you would like to publish the dashboard \'' + dashboard.name + '\'?  Doing so will allow users to add it to their own dashboard profile.'
            };

            modalService
                .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                .then(function(result) {
                    if(result)
                    defaultDashboardsService
                            .publishDashboard(dashboard)
                            .then(function() {
                                $scope.dashboards = defaultDashboardsService.dashboards;

                                var toast = $mdToast
                                    .simple()
                                    .textContent('{0} was published.'.format(dashboard.name))
                                    .action('UNDO')
                                    .highlightAction(true)
                                    .hideDelay(5000);

                                $mdToast
                                    .show(toast)
                                    .then(function(response) {
                                        if(response == 'ok')
                                            return defaultDashboardsService.unpublishDashboard(dashboard).then(function() {
                                                $scope.dashboards = defaultDashboardsService.dashboards;
                                            });
                                    });
                            });
                });
        };

        $scope.unpublishDashboard = function(dashboard) {
            var scope = {
                title: 'Publish ' + dashboard.name + '?',
                message: 'Are you sure you would like to unpublish the dashboard \'' + dashboard.name + '\'?  Doing so will prevent users from adding it to their dashboard profile.'
            };

            modalService
                .openTopBarModal(actionViewTemplates.confirmDialog, scope)
                .then(function(result) {
                    if(result)
                    defaultDashboardsService
                            .unpublishDashboard(dashboard)
                            .then(function() {
                                $scope.dashboards = defaultDashboardsService.dashboards;

                                var toast = $mdToast
                                    .simple()
                                    .textContent('{0} was unpublished.'.format(dashboard.name))
                                    .action('UNDO')
                                    .highlightAction(true)
                                    .hideDelay(5000);

                                $mdToast
                                    .show(toast)
                                    .then(function(response) {
                                        if(response == 'ok')
                                            return defaultDashboardsService.publishDashboard(dashboard).then(function() {
                                                $scope.dashboards = defaultDashboardsService.dashboards;
                                            });
                                    });
                            });
                });
        };

        $scope.editDashboard = function(dashboard) {
            defaultDashboardsService
                .switchDashboard(dashboard)
                .then(function(dashboard) {
                    $scope.selected = dashboard;
                });
        };
        $scope.cancelEdit = function() {
            $scope.selected = null;
        };

        $scope.renameDashboard = function(dashboard) {
            var oldName = dashboard.name;
            return defaultDashboardsService
                .renameDashboard(dashboard, dashboard.name)
                .then(function(dashboard) {
                    var toast = $mdToast
                        .simple()
                        .textContent('The dashboard was renamed to {0}.'.format(dashboard.name))
                        .hideDelay(5000);

                    $mdToast.show(toast);
                });
        };

        $scope.pushDashboards = function(securityGroupId) {
            return $http
                .get(('api/subscribers/{0}/users?pageSize=0&pageNumber=0').format($scope.subscriberId))
                .then(function(users) {
                    var scope = {
                        users: _.filter(users.data, function(user) {
                            return user.securityGroupId == securityGroupId;
                        }),
                        dashboards: $scope.dashboards,
                        selectedUsers: _.filter(users.data, function(user) {
                            return user.userId;
                        }),
                        selectedDashboards: [],
                        lockdownSettings: null
                    };

                    return modalService
                        .openTopBarModal(actionViewTemplates.subRegPushDefaultDashboards, scope)
                        .spread(function(selectedUsers, selectedDashboards, isLocked) {
                            if(!selectedUsers || !selectedDashboards) return;

                            var pushes = _.map(selectedUsers, function(userId) {
                                return $http.get(('api/dashboards/{0}/userDashboards/{1}').format(selectedDashboards, userId))
                                    .then(function(userDashboards) {
                                        $scope.userDashboards = userDashboards.data;

                                        return userDashboards.data;
                                    })
                                    .then(function() {
                                        return $http
                                            .post('api/dashboards/', {
                                                ids: selectedDashboards,
                                                order: 0,
                                                userId: userId,
                                                isLocked: isLocked,
                                                isDefaultDashboard: true
                                            });
                                    })
                                    .then(function(response) {
                                        _.forEach(response.data, function(dashboards) {
                                            _.forEach($scope.userDashboards, function(userDashboards) {
                                                if((dashboards.name.toLowerCase() === userDashboards.name.toLowerCase()) && dashboards.isLocked) {
                                                    $http.delete(('api/dashboards/{0}/userDashboards/{1}').format(selectedDashboards, userDashboards.id))
                                                        .then(function() {
                                                            _.remove(userDashboards, function(dashboard) { return dashboard.id });
                                                        });
                                                }
                                            });
                                        });
                                    });
                            });

                            return $q
                                .all(pushes)
                                .then(function() {
                                    var toast = $mdToast
                                        .simple()
                                        .textContent('The dashboards have been pushed.')
                                        .hideDelay(5000);

                                    $mdToast.show(toast);
                                });
                        });
                });
        };

        init();
    }
]);
