angular.module('angus.controllers').controller('arSummaryBsgCtrl', [
    '$scope', '$rootScope', '$http',
    function($scope, $rootScope, $http) {
    	'use strict';
    	
		function getArSummary() {
			return $http({
				url: ('api/subscribers/{0}/accountsReceivable/bsg/summary').format($rootScope.user.subscriberId),
				method: 'POST',
				data: {
					divisionId: $scope.widget.instance.settings.hierarchyNodeId
				}
			});
		}

		function init() {
			$scope.widget.noContentFlag = false;

			return $scope.widget.promises.monitor(
				getArSummary()
				.then(function(summary) {
					if (!summary) $scope.widget.noContentFlag = true;
					else $scope.summary = summary.data;
				}));
		}

        $scope.widget.setDefaultInitialization(init);		

		$scope.$on('widgetSettingsChanged', function() {
			init();
        });		
    }
]);