angular.module('angus.directives')
.directive('csvReaderImproved', ['$','FileReader', '_', '$q', function($, FileReader, _, $q) {
	'use strict';

  	return {    	
    	require: '?ngModel',
    	link: function(scope, element, attrs, ngModel) {   		
    		if (!ngModel) return;

    		function fileChange(e){
    			var csv;
    			var deferred = $q.defer();    			
    			var file = e.target.files[0];	        	
	        	
	        	if(!file || !file.name || !file.name.endsWith('.csv'))    					            		            		            
	            	deferred.reject('Not a CSV');
    			else 
    				deferred.resolve(readFile(file));    			

    			return deferred
    				.promise
    				.then(function(result){
    					csv = result;
    				})
    				.catch(function(){
    					csv = undefined;
    				})
    				.finally(function(){    				
                    	ngModel.$setViewValue(csv);
    				});
    		}

			function splitCSVButIgnoreCommasInDoublequotes(str) {    
				//split the str first  
				//then merge the elments between two double quotes  
				var delimiter = ',';  
				var quotes = '"';  
				var elements = str.split(delimiter);  
				var newElements = [];  
				for (var i = 0; i < elements.length; ++i) {  
					if (elements[i].indexOf(quotes) >= 0) {//the left double quotes is found  
						var indexOfRightQuotes = -1;  
						var tmp = elements[i];  
						//find the right double quotes  
						for (var j = i + 1; j < elements.length; ++j) {  
							if (elements[j].indexOf(quotes) >= 0) {  
								indexOfRightQuotes = j; 
								break;
							}  
						}  
						//found the right double quotes  
						//merge all the elements between double quotes  
						if (-1 != indexOfRightQuotes) {   
							for (var j = i + 1; j <= indexOfRightQuotes; ++j) {  
								tmp = tmp + delimiter + elements[j];  
							}  
							newElements.push(tmp);  
							i = indexOfRightQuotes;  
						}  
						else { //right double quotes is not found  
							newElements.push(elements[i]);  
						}  
					}  
					else {//no left double quotes is found  
						newElements.push(elements[i]);  
					}  
				}  
			 	     
				return newElements;  
			}  

			function readFile(file){
				var deferred = $q.defer();
				var reader = new FileReader();

				reader.onload = function(e){      
					var uploadedContents = e.target.result.replace(/\r\n/g, '\n').replace(/\r/g, '\n').split('\n');	  
					var skipHeader = 2; //skip just for SNAPSHOT UPLOAD only
					var skippedContents = uploadedContents.slice(skipHeader);  
					var headers = _.remove(skippedContents, function(row, index){
      					return index === 0;
      				})[0].split(',');

      				var content = _.reduce(skippedContents, function(setResult, row){      
      					var emptyCount = 0;
					    // var splitedRow = row.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g);	
						var splitedRow = splitCSVButIgnoreCommasInDoublequotes(row);   					
  						var data =  _.reduce(splitedRow, function(rowResult, value, index){ 
  							rowResult[headers[index]] = value.trim(); 
  							if(!value) emptyCount++; 
							return rowResult;
						}, {});		    
						if(emptyCount < Object.keys(data).length)
							setResult.push(data);

  						return setResult;
					}, []);
					 
      				var data = headers && content ? { headers : headers, content : content } : undefined;

					deferred.resolve(data);
				};

				reader.onerror = function(e) {
                    deferred.reject(e);
                };

                reader.readAsText(file);

                return deferred.promise;      							
			}

    		ngModel.$render = function() {};

            element.bind('change', fileChange);  		
      	
    	}    	
  	};
}]);