angular.module('angus.controllers').controller('hddTrackerReportContainerCtrl', [
    '$rootScope', '$scope', '_', 'actionViewTemplates', 'degreeDayService', 'moment', 'Paging','productsService',  
    function($rootScope, $scope, _, actionViewTemplates, degreeDayService, moment, Paging,  productsService) {

        'use strict';

        $scope.pageSize        = 25;
        $scope.sideNavTemplate = actionViewTemplates.sideNav;

        $scope.listReport = {
            name        : 'Saved Filters',
            icon        : 'tasks',
            templateUrl : actionViewTemplates.hddTrackerReportList,
            active      : true,
        };

        $scope.report = {
            name        : 'HDD Tracker',
            icon        : 'table',
            templateUrl : actionViewTemplates.hddTrackerReportContent,
            active      : true,
        };

        degreeDayService.getDegreeDayHeaders($rootScope.user.subscriberId)
            .then(function (degreeDayHeaders) {
                $scope.logTypes = degreeDayHeaders;
            });
       
        productsService.getProducts()
            .then(function(products) {
                $scope.products = products;
            });

        $scope.items = [$scope.listReport];


        function init() {           
                $scope.activeItem       = $scope.report;
                $scope.activeItem.dates = {
                    periodEndDate : $scope.model.periodEndDate || moment(),
                    periodStartDate  : $scope.model.periodStartDate || moment()
                };
                $scope.activeItem.paging  = new Paging($scope.pageSize);
                $scope.activeItem.sorting = {
                                sortProp : 'deliveryDate',
                                sortDir  : 'asc'
                            };
                $scope.items.push($scope.activeItem);
        }

        $scope.switchActiveItem = function(item) {
            if (item.active) {
                $scope.activeItem = item;
            }
        };

        $scope.$watch(function() {
            return $scope.model;
        }, function(newVal, oldVal) {
            if (oldVal != newVal)
                init();
        });

        $scope.addNewOpenReport = function(filter, resetDetailedReport, addNewMetricReport) {
            if (filter) {
                var openReport = _.find($scope.items, function(item) {
                    return item.name == filter.name;
                });
                if (!openReport) {
                    if (addNewMetricReport) {
                        openReport = {
                            name        : 'Report ' + $scope.items.length,
                            icon        : 'table',
                            templateUrl : actionViewTemplates.hddTrackerReportContent,
                            active      : true,
                            filter      : null,
                            dates       : {
                                periodEndDate : $scope.model.periodEndDate,
                                periodStartDate  : $scope.model.periodStartDate
                            },
                            closeable : $scope.items.length > 1,
                            paging    : new Paging($scope.pageSize),
                            sorting   : {
                                sortProp : 'deliveryDate',
                                sortDir  : 'asc'
                            },
                        };
                    } else {
                        openReport = {
                            name        : filter.name,
                            icon        : 'user',
                            templateUrl : actionViewTemplates.hddTrackerReportContent,
                            active      : true,
                            filter      : filter,
                            closeable   : true,
                            dates       : {
                                periodEndDate : $scope.model.periodEndDate,
                                periodStartDate  : $scope.model.periodStartDate
                            },
                            report              : null,
                            paging              : new Paging($scope.pageSize),
                            sorting             : {
                                sortProp : 'deliveryDate',
                                sortDir  : 'asc'
                            },
                        };
                    }
                    $scope.items.push(openReport);
                }
                $scope.activeItem = openReport;
            }

            if (resetDetailedReport) $scope.resetDetailedReport();
        };

        $scope.resetDetailedReport = function() {
            var detailedReport = _($scope.items).find({
                name: 'Report 1'
            });
            detailedReport.report = null;
            delete detailedReport.filter;
            };

        $scope.renameActiveReportTab = function(newName) {
            $scope.activeItem.name = newName;
        };

        $scope.closeItem = function(report) {
            if (report) {
                var openReport = _.find($scope.items, function(item) {
                    return item.name == report.name;
                });
                var index = $scope.items.indexOf(openReport);

                if ($scope.activeItem == openReport)
                    $scope.activeItem = $scope.items[index - 1];

                $scope.items.splice(index, 1);
            }
        };

        $scope.getLogFileDisplayName = function (hddLogId) {
            var item = _.find($scope.logTypes, function (logType) {
                return logType.id == hddLogId;
            });

            if (!item) {
                return;
            }

            return item.name;
        };

		init();
    
    }
]);