angular.module('angus.controllers').controller('serviceCallsSubRegSettingsCtrl', [
    '$scope', '$rootScope', '$http', '_', 'constantsService',
    function($scope, $rootScope, $http, _, constantsService) {

        'use strict';

        $scope.filter = {};
        
        $http.get(('api/subscribers/{0}/service/techs').format($scope.subscriberId))
            .then(function(list){
                $scope.techs = list.data;

                _.each($scope.settings.techs.value, function(selection){
                    var update = _.findIndex($scope.techs, function(tech){
                            return tech.id == selection;
                        });
                    if(update >= 0) $scope.techs[update].excludeTicked = true;
                });
            });

        $http.get(('api/subscribers/{0}/service/callReasons/list').format($scope.subscriberId))
            .then(function(list){
                $scope.callReasons = list.data;

                _.each($scope.settings.callReasons.value, function(selection){
                    var update = _.findIndex($scope.callReasons, function(callReasons){
                            return callReasons.id == selection;
                        });
                    if(update >= 0) $scope.callReasons[update].excludeTicked = true;
                });
            });

        $scope.periodIndicators      = constantsService.servicePeriodIndicators.enums;
        $scope.serviceRevenueOptions = constantsService.serviceRevenueCategories.enums;

        $scope.closeTechs = function(){
            $scope.settings.techs.value = angular.copy($scope.filter.techs);
        };
    }
]);