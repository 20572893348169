angular.module('angus.controllers').controller('serviceContractsProfileSettingsCtrl', [
    '$scope', '$rootScope', 'hierarchyService', '$http',
    function($scope, $rootScope, hierarchyService, $http) {

        'use strict';

        $scope.widget.instance.settings.serviceContractTypes = $scope.widget.instance.settings.serviceContractTypes || {};

        $http.get(('api/subscribers/{0}/serviceContracts/types').format($rootScope.user.subscriberId))
            .then(function(types) {
                $scope.contractTypes = types.data;

                _.forEach($scope.widget.instance.settings.serviceContractTypes.value, function(selection) {
                    var index = _.findIndex($scope.contractTypes, function(type) {
                        return type.id == selection;
                    });

                    if(index >= 0) $scope.contractTypes[index].excludeTicked = true;
                });
            });

        $scope.updateServiceContractTypes = function(model) {
            if(model) {
                $scope.widget.instance.settings.serviceContractTypes.value = angular.copy(model);
            }
        };

        $scope.openDivisionHierarchyView = function() {
            hierarchyService.openDivisionModal($scope.widget.instance.settings.hierarchyNodeId)
                .then(function(nodeId) {
                    $scope.widget.instance.settings.hierarchyNodeId = nodeId;
                });
        };
    }
]);
