'use strict';
angular.module('angus.controllers').controller('subRegAddsDivisionCtrl', [
	'$scope', '$http', '$q', '$timeout', '_', 'moment', 'promiseMonitor', 'modalService', 'actionViewTemplates',
	function($scope, $http, $q, $timeout, _, moment, PromiseMonitor, modalService, actionViewTemplates) {

		$scope.data = {}

		$scope.$watch(function() {
      		return $scope.activeSourceSystem;
		},
		function(newVal, oldVal) {
 			if (newVal != oldVal) getDivision();

		});

		function getDivision(){
			var promise = $http
				.get('api/subscribers/{0}/addsDivision/{1}'.format($scope.subscriberId, $scope.activeSourceSystem.id))
				.then(function(result) {
					$scope.data.tableOptions      = result.data.options;
					$scope.data.originalOptionId  = Number(result.data.selected);
					$scope.data.selectedOptionId  = Number(result.data.selected);
				});

			$scope.data.promises = new PromiseMonitor(promise);
		}

		$scope.updateEsysDivision = function() {
			var confirmScope = {
				title: 'Confirm Save?',
				message: 'Integration Settings codes cannot be deselected after they have been saved! Please confirm you have made the necessary changes. ' +
					'If selections need to be changed you will need to contact your system administrator.'
			};

			return modalService
				.openTopBarModal(actionViewTemplates.confirmDialog, confirmScope)
				.then(function(response) {
					if(!response) return;

					var data = { addsDivision : $scope.data.selectedOptionId };
					var promise = $http
						.post('api/subscribers/{0}/addsDivision/{1}'.format($scope.subscriberId,$scope.activeSourceSystem.id), data)
						.then(function() {
							return getDivision();
						});

					$scope.data.promises = new PromiseMonitor(promise);
			});
		};

		getDivision();
	}
]);

