angular.module('angus.services').factory('terminationReasonService', [
	'$rootScope', '$http', '$q', '_',
	function($rootScope, $http, $q, _) {
		'use strict';

		var subscriberTerminationReason = [];
		var terminationReason = [];

		function getTerminationReason(subscriberId) {
			if (subscriberId) {
				if (subscriberTerminationReason[subscriberId] && subscriberTerminationReason[subscriberId].length > 0)
					return subscriberTerminationReason[subscriberId];
				else {
					return queryTerminationReason(subscriberId)
						.then(function(terminationReasonList) {
							subscriberTerminationReason[subscriberId] = terminationReasonList.data;
							return subscriberTerminationReason[subscriberId];
						});
				}
			} else {
				if (terminationReason && terminationReason.length > 0)
					return terminationReason;
				else {
					return queryTerminationReason()
						.then(function(terminationReasonList) {
							terminationReason = terminationReasonList.data;
							return terminationReason;
						});
				}
			}
		}

		function queryTerminationReason(subscriberId) {
			return $http.get(('api/subscribers/{0}/terminationReason').format(subscriberId || $rootScope.user.subscriberId));
		}

		return {
			getTerminationReason: function(subscriberId) {
				return $q.when(getTerminationReason(subscriberId))
					.then(function(terminationReason) {
						return _.cloneDeep(terminationReason);
					});
			},
			formatDeliveryTypesList: function(deliveryTypeIds) {
				var result = '';
				var terminationReasonList = terminationReason;

				if (!(terminationReasonIds instanceof Array))
					terminationReasonIds = [terminationReasonIds.toString()];

				if (terminationReasonIds.length > 0) {
					_.forEach(terminationReasonList, function(terminationReason) {
						if (_.includes(terminationReasonIds, terminationReason.id.toString()))
							result += terminationReason.name + ', ';
					});
				}

				return result.substring(0, result.length - 2);
			}
		};
	}
]);
