angular.module('angus.directives').directive('editProfileDirective', [
	'$document',
	function ($document) {
		return {
			scope: {
				closeProfileModalCb: "="
			},
			controller: [
				'$scope', '$rootScope', 'editProfileService', '$timeout',
				function ($scope, $rootScope, editProfileService, $timeout) {
					$scope.closeAlert = function (index) {
						$scope.alerts.splice(index, 1);
					};
					$scope.alerts = [];
					$scope.userProfile = {
						email: $rootScope.user.email
					};

					$scope.updatePassword = function () {
						$scope.isLoading = true;
						editProfileService.updatePassword($scope.userProfile.curPassword, $scope.userProfile.newPassword, $scope.userProfile.confirmNewPassword
							,function () {

								$scope.alerts.push({
									type: 'success',
									msg: '<strong>Success!</strong> Your password has been changed.'
								});
								$timeout(function () {
									$scope.close();
									$scope.isLoading = false;
								}, 1500);
							}, function (problemDetails) {
								$scope.isLoading = false;
								$scope.userProfile.curPassword = "";

								var errors = problemDetails.errors;
								for (const error in errors){

									if (errors.hasOwnProperty(error)) {
										const errorMessages = errors[error];

										errorMessages.forEach(function(errorMessage) {
											$scope.alerts.push({
												type: 'danger',
												msg: `<strong>Error!</strong> ${errorMessage}` || 'Unable to change password.'
											});
										});
									}						
								}
							});
					};

					$scope.close = function () {
						if ($scope.closeProfileModalCb) $scope.closeProfileModalCb();
						if ($scope.modalClosedCb) $scope.modalClosedCb();

					};

					$scope.removeErrorMsg = function () {
						if ($scope.errorMessage) {
							$scope.errorMessage = null;
						}
					};
				}
			],
			templateUrl: 'templates/profile/html/editProfileContent.html',
			link: function (scope, element, attrs, ctrl) {

				// scope.modalClosedCb = function() {
				// 	$document.unbind( 'click' );
				// };

				// element.bind( 'click', function( e ) {
				// 	e.stopPropagation();
				// } );

				// $document.bind( 'click', function() {
				// 	scope.$apply( scope.closeProfileModalCb );
				// 	if ( scope.modalClosedCb ) scope.modalClosedCb();
				// } );

			}
		};
	}
]);