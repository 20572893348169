angular.module('angus.directives').directive('adeptExecutionDetails', [
    'actionViewTemplates',
    function(actionViewTemplates) {
        return {
            scope: {
                settings: "=",
            },
            restrict: 'E',
            templateUrl: actionViewTemplates.adeptExecutionDetails
        }
    }
]);