angular.module('angus.services')
    .factory('periodIndicators', [
        function() {

            var periodIndicators = [
                { name: "Last delivery date", id: 1 },
                { name: "Prior 15 Days", id: 2 },
                { name: "Prior 30 days", id: 3 },
                { name: "Current Month", id: 4 },
                { name: "Custom Date Range", id: 5 },
                { name: "Custom Date", id: 6 }
            ];

            return {
                getPeriodIndicators: function() {
                    return periodIndicators;
                }
            };
        }
    ]);