angular.module( 'angus.controllers' ).controller( 'weatherHDDSubRegSettingsCtrl', [
  '$scope', 'constantsService', 'degreeDayService', 'promiseMonitor',
	function( $scope, constantsService, degreeDayService, promiseMonitor) {

      $scope.logtype        = {};
      $scope.averageperiod  = {};
      $scope.averageperiods = [
          {id : 3, name : '3-Yr Avg'},
          {id : 5, name : '5-Yr Avg'},
          {id : 10, name : '10-Yr Avg'}
      ];
      $scope.months = constantsService.months.enums;
      $scope.promiseMonitor = new promiseMonitor();

        var promise = degreeDayService.getDegreeDaySources($scope.subscriberId)
            .then(function (sources) {
                $scope.sources = sources;
            });

      $scope.promiseMonitor.monitor(promise);
	}
] );
