'use strict';

// allow only integers (non-decimal) to be entered into text input box
angular.module('angus.directives').directive('onlyIntegersAllowed', ['_',
    function(_) {
        return {
            restrict: 'A',
            link: function(scope, ele, attrs, ctrl) {
                // key press events that will be allowed inside text input
                var keyPressEvents = [
                    8,  // backspace
                    9, // tab
                    13, // enter
                    16, // shift
                    17, // ctrl
                    18, // alt
                    27, // esc
                    35, // end
                    36, // home
                    37, // left arrow
                    39, // right arrow
                    46,  // delete
                    109, // numpad -
                    110, // numpad .
                    189, // -
                    190 // .
                ];

                ele.on('keydown', function(evt) {
                    // if(evt.shiftKey) {
                    //     evt.preventDefault();
                    //     return false;
                    // }

                    if(keyPressEvents.indexOf(evt.which) > -1) {
                        return true;
                    } else if(evt.which >= 48 && evt.which <= 57) { // allow numbers 0 - 9
                        return true;
                    } else if(evt.which >= 96 && evt.which <= 105) { // allow numpad numbers 0 - 9
                        return true;
                    } else {
                        evt.preventDefault();
                        return false;
                    }
                });
            }
        }
    }
])
// allow only integers (non-decimal) to be entered into number input box
.directive('noFloatValuesAllowed', [
    function() {
        return {
            restrict: 'A',
            link: function(scope, ele, attrs, ctrl) {
                // key press events that will be allowed inside number input
                var keyPressEvents = [
                    110, // numpad .
                    190  // .
                ];

                ele.on('keydown', function(evt) {
                    if(keyPressEvents.indexOf(evt.which) > -1) {
                        evt.preventDefault();
                        return false;
                    } else {
                        return true;
                    }
                });
            }
        }
    }
]);